import React, { useState, useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from 'react-router-dom'
import * as Yup from "yup";
import { Spin, Tooltip } from "antd";
import { Card, Table as BSTable } from "react-bootstrap";
import {
  Grid,
  Select,
  FormHelperText,
  Collapse,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@material-ui/core";
import { TimePicker, MuiPickersUtilsProvider, DatePicker, DateTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Formik } from "formik";
import moment from 'moment'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faClock } from "@fortawesome/free-solid-svg-icons";

import DashboardLayout from "../../layouts/dashboardLayout";
import { InputField, Navbar, AppBar, Button, ConfirmationModal } from "../../customComponents";
import { addWaitTimeInitiate, getWaitTimeInitiate } from "../../store/actions/terminalManagementActions"
import { useStyles } from '../../styles'
import { appConstants } from "../../themes/constants";

const ManageWaitTime = (props) => {

  const values = {
    terminalArea: "",
    terminalName: "",
    locationCategory: "",
    terminalCountry: "",
    terminalAddress: "",
    terminalLatitude: "",
    terminalLongitude: "",
    terminalCity: "",
    terminalState: "",
    zipCode: "",
    terminalRadius: "50",
    extraInformation: "",
    terminalUrl: "",
    waitingType: ""
    // about: ""
  };

  const classes = useStyles();
  const dispatch = useDispatch()
  const location = useLocation()

  const { isLoading, waitTimeData, waitTimeDataLoader, waitTimeDataSuccess } = useSelector((state) => state.terminalManagementReducer);

  const [waitingType, setWaitingType] = useState('automatic')
  const [selectedStartTime, setSelectedStartTime] = React.useState(null);
  const [startTimeError, setStartTimeError] = React.useState('');
  const [startTimePicker, setStartTimePicker] = useState(false);
  const [endTimePicker, setEndTimePicker] = useState(false);
  const [selectedEndTime, setSelectedEndTime] = React.useState(null);
  const [endTimeError, setEndTimeError] = React.useState('');
  const [waitingTime, setWaitingTime] = useState([
    { slot: '00:00 - 01:00', type: 'a', time: '', error: '' },
    { slot: '01:00 - 02:00', type: 'a', time: '', error: '' },
    { slot: '02:00 - 03:00', type: 'a', time: '', error: '' },
    { slot: '03:00 - 04:00', type: 'a', time: '', error: '' },
    { slot: '04:00 - 05:00', type: 'a', time: '', error: '' },
    { slot: '05:00 - 06:00', type: 'a', time: '', error: '' },
    { slot: '06:00 - 07:00', type: 'a', time: '', error: '' },
    { slot: '07:00 - 08:00', type: 'a', time: '', error: '' },
    { slot: '08:00 - 09:00', type: 'a', time: '', error: '' },
    { slot: '09:00 - 10:00', type: 'a', time: '', error: '' },
    { slot: '10:00 - 11:00', type: 'a', time: '', error: '' },
    { slot: '11:00 - 12:00', type: 'a', time: '', error: '' },
    { slot: '12:00 - 13:00', type: 'a', time: '', error: '' },
    { slot: '13:00 - 14:00', type: 'a', time: '', error: '' },
    { slot: '14:00 - 15:00', type: 'a', time: '', error: '' },
    { slot: '15:00 - 16:00', type: 'a', time: '', error: '' },
    { slot: '16:00 - 17:00', type: 'a', time: '', error: '' },
    { slot: '17:00 - 18:00', type: 'a', time: '', error: '' },
    { slot: '18:00 - 19:00', type: 'a', time: '', error: '' },
    { slot: '19:00 - 20:00', type: 'a', time: '', error: '' },
    { slot: '20:00 - 21:00', type: 'a', time: '', error: '' },
    { slot: '21:00 - 22:00', type: 'a', time: '', error: '' },
    { slot: '22:00 - 23:00', type: 'a', time: '', error: '' },
    { slot: '23:00 - 24:00', type: 'a', time: '', error: '' }
  ])

  useLayoutEffect(() => {
    document.title = appConstants.headerTitle.manageWaitTime;
    dispatch(getWaitTimeInitiate(location?.state?.terminalId))
  }, [])

  useEffect(() => {
    if (Object.keys(waitTimeData).length > 0 && waitTimeDataSuccess) {
      // console.log("this is tme = ", moment(waitTimeData?.manualStartTime).utc().format("YYYY-MM-DDTHH:mm:ss"));
      setWaitingType(waitTimeData?.isManual ? 'manual' : 'automatic')
      setSelectedStartTime(waitTimeData?.manualStartTime && moment(waitTimeData?.manualStartTime).utc().format("YYYY-MM-DDTHH:mm:ss"))
      setSelectedEndTime(waitTimeData?.manualEndTime && moment(waitTimeData?.manualEndTime).utc().format("YYYY-MM-DDTHH:mm:ss"))
      
      let arr = [...waitingTime]
      for(let i = 1; i <= Object.keys(waitTimeData?.manual_graph).length; i++){
        
        arr[i-1].time = waitTimeData?.manual_graph[i].value;
        arr[i-1].type = waitTimeData?.manual_graph[i].isManual ? 'm' : 'a';
      }
      console.log("this is tme = ", arr);
      setWaitingTime(arr)
    }
    
  }, [waitTimeData])

  const handleChangeWatingType = (e) => {
    setWaitingType(e.target.value)
  }

  const handleChangeWatingTime = (e, index) => {
    let arr = [...waitingTime]
    arr[index].type = e.target.value
    arr[index].time = ''
    if(e.target.value == 'm') {
      arr[index].error = 'Please enter waiting time.'
    } else {
      arr[index].error = ''
    }
    setWaitingTime(arr)
  }

  const onChangeTime = (value, index, type) => {
    let arr = [...waitingTime]
    value = Math.max(0, parseInt(value)).toString().slice(0, 3);
    let checkNumber = parseInt(arr[index].time)
    console.log("this is valyue = ", value, "value length = ", value.length)
    // value = value?.length === 0 || value === "NaN" ? "" : value;
    if (value?.length === 0 || value === "NaN") {
      arr[index].time = ''
      arr[index].error = "Please enter waiting time."
      setWaitingTime(arr)
    } else if (value < 1 || value === "NaN") {
      arr[index].time = value
      arr[index].error = "Please enter waiting time."
      setWaitingTime(arr)
    } else {
      arr[index].time = value
      arr[index].error = ""
      setWaitingTime(arr)
    }
  }

  const validationSchema = Yup.object().shape({
    
  });

  const isDatesSelected = (value, setError, msg, type) => {
    let res = false
    if (value) {
      if (type == 'end' && selectedStartTime) {
        let temp = moment(selectedStartTime).add(1, 'hour')
        let isValid = moment(value).isSameOrAfter(temp);
        if (isValid) {
          setError('')
          return false
        } else {
          setError('Please select valid end date time')
          return true
        }
      }
      //  else if (type == 'start' && selectedStartTime) {
      //   console.log(moment().utc().add(2, 'minute').format(),moment(selectedStartTime).format(),"selectedStartTime");
      //   let isValid = moment().utc().add(2, 'minute').isSameOrBefore(moment(selectedStartTime).utc())
      //   if (isValid) {
      //     setError('')
      //     return false
      //   } else {
      //     setError('Start date should be 2 minutes greater than current time.')
      //     return true
      //   }
      // } 
      else {
        setError('')
        return false
      }
      
    } else {
      setError(msg)
      return true
    }
  }

  const isTimeSelected = (arr) => {
    console.log("this is an error = ", arr)
    let res = false
    for(let index in arr) {
      if (arr[index].error) {
        res = true
      }
    }
    return res
  }

  const handleFormSubmit = (values, { setSubmitting }) => {
    
    let isErrors = []
    if (waitingType == "manual") {
      isErrors = [
        isDatesSelected(selectedStartTime, setStartTimeError, 'Please select start date.', 'start'),
        isDatesSelected(selectedEndTime, setEndTimeError, 'Please select end date.', 'end'),
        isTimeSelected(waitingTime)
      ]
    }
    console.log("this is handle submit = ", isErrors)
    if (isErrors.filter(Boolean).length == 0) {
      
      let obj = {}
      for(let index in waitingTime) {
        obj = {
          ...obj,
          [`${parseInt(index)+1}`]: {
            value: waitingTime[index].time,
            isManual: waitingTime[index].type == 'm' ? true : false
          }
        }
      }
      let data = {
        manual_graph: obj,
        isManual: waitingType == "manual" ? true : false,
        manualStartTime: waitingType == "manual" ? moment(selectedStartTime).format("YYYY-MM-DDTHH:mm:ss") : null,
        manualEndTime: waitingType == "manual" ? moment(selectedEndTime).format("YYYY-MM-DDTHH:mm:ss") : null,
        id: location?.state?.terminalId
      }
      console.log("this is next = ", data)
      dispatch(addWaitTimeInitiate(data))
    }
  }

  const yesterday = moment().subtract(1, 'day');

  return (
    <Spin
      size="large"
      spinning={waitTimeDataLoader}
      wrapperClassName={classes.tableSpinner}
      className={classes.antSpin}
    >
      <DashboardLayout>
        <AppBar
          breadcrumbs={[
            { route: "/terminals", name: "Terminal Listing" },
            { route: "/add-terminal", name: "Manage Wait Time" },
          ]}
        />
        <Navbar title="Manage Wait Time" href="/User Management" />
        <Card className={classes.card}>
          <Grid container>
            <Grid className={classes.tableContainerRow}>
              <Formik
                enableReinitialize
                initialValues={values}
                validationSchema={validationSchema}
                onSubmit={handleFormSubmit}
              >
                {({
                  values,
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <BSTable striped bordered className={classes.terminalTable}>
                      <tbody>
                        <tr>
                          <td className={classes.rowKey}>Waiting Type</td>
                          <td className={classes.rowValue}>
                            <div className={classes.inputWrapper}>
                              <RadioGroup aria-label="template" name="template" value={waitingType} onChange={handleChangeWatingType}>
                                <FormControlLabel value="manual" control={<Radio />}
                                  checked={waitingType == "manual"}
                                  label="Manual"
                                />
                                <FormControlLabel value="automatic" control={<Radio />}
                                  checked={waitingType == "automatic"}
                                  label="Automatic"
                                />
                              </RadioGroup>
                            </div>
                          </td>
                        </tr>
                       {waitingType == "manual" && <tr>
                          <td className={classes.rowKey}>Select Date (UTC)</td>
                          <td className={classes.rowValue}>
                            
                            <div style={{ width: '100%', display: 'flex'}}>
                            <div style={{ width: '300px'}}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils} >
                              <DateTimePicker

                                style={{ width: "250px" }}
                                ampm={true}
                                className='date'
                                placeholder="Select Start Date"
                                name="startDate"
                                value={selectedStartTime}
                                onAccept={(time) => {
                                    setStartTimePicker(false);
                                }}
                                maxDate={selectedEndTime && moment(selectedEndTime)}
                                // disablePast={!selectedStartTime}
                                format="yyyy-MM-dd HH:mm a"
                                onClose={() => setStartTimePicker(false)}
                                  onChange={(time) => {
                                    console.log("this is the exact date = ", time, "this is end date = ", moment(selectedEndTime))
                                    setSelectedStartTime(time)
                                    if (selectedEndTime) {
                                      let temp = moment(time).add(1, 'hour')
                                      let isValid = moment(selectedEndTime).isSameOrAfter(temp);
                                      if (isValid) {
                                        setEndTimeError('')
                                      } else {
                                        setEndTimeError('Please select valid end date time.')
                                      }
                                    }
                                    setStartTimeError('')
                                  }}
                                  open={startTimePicker}
                                  onOpen={() => setStartTimePicker(true)}
                                  error={true}
                                InputProps={{
                                  endAdornment: (
                                      <FontAwesomeIcon
                                          icon={faCalendar}
                                          className={classes.dropdownIcon}
                                      />
                                  )
                              }}
                              />
                              </MuiPickersUtilsProvider>
                              <div className="url-Error">{startTimeError}</div>
                            </div>
                            <div style={{ width: '300px'}}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} >
                              {console.log("this is new date = ", selectedEndTime)}
                              <DateTimePicker
                                style={{ width: "250px" }}
                                ampm={true}
                                placeholder="Select End Date"
                                name="endDate"
                                className='date'
                                error={true}
                                minDate={selectedStartTime ? moment(selectedStartTime) : moment()}
                                value={selectedEndTime}
                                onAccept={(time) => {
                                  console.log("this is accept = ", moment(selectedStartTime).add(1, "hours").format('yyyy-MM-dd HH:mm a'))
                                    setEndTimePicker(false);
                                }}
                                format="yyyy-MM-dd HH:mm a"
                                onClose={() => setEndTimePicker(false)}
                                // disablePast={!selectedEndTime}
                                onChange={(time) => {
                                  setSelectedEndTime(time)
                                  if (selectedStartTime) {
                                    let temp = moment(selectedStartTime).add(1, 'hour')
                                    let isValid = moment(time).isSameOrAfter(temp);
                                    if (isValid) {
                                      setEndTimeError('')
                                    } else {
                                      setEndTimeError('Please select valid end date.')
                                    }
                                  } else {
                                    setEndTimeError('')
                                  }
                                }}
                                open={endTimePicker}
                                onOpen={() => setEndTimePicker(true)}
                                InputProps={{
                                    endAdornment: (
                                        <FontAwesomeIcon
                                            icon={faCalendar}
                                            className={classes.dropdownIcon}
                                        />
                                    )
                                }}
                              />
                              </MuiPickersUtilsProvider>
                              <div className="url-Error">{endTimeError}</div>
                            </div>
                            </div>
                            
                          </td>
                        </tr>}
                       {waitingType == "manual" && <tr>
                          <td className={classes.rowKey}>Waiting Time</td>
                          <td className={classes.rowValue}>
                            <div style={{ width: '100%', display: 'flex' }}>
                              
                              <div>
                                      
                                {waitingTime.map((item, index) => {
                                  if ((index + 1) <= 12) {
                                    return (
                                      <>
                                        <div className={classes.waitTimeInputWrapper}>
                                          <div className={classes.waitTimeInputWrapperLeft}>
                                          <FontAwesomeIcon icon={faClock} className={classes.clockIcon} />
                                            {`${item?.slot} (UTC)`}
                                          </div>
                                          <RadioGroup aria-label="template" name="template" value={waitingTime} onChange={(e) => handleChangeWatingTime(e, index)}>
                                            <FormControlLabel value="m" control={<Radio />}
                                              checked={waitingTime[index].type == "m"}
                                              label="Manual"
                                            />
                                            <FormControlLabel value="a" control={<Radio />}
                                              checked={waitingTime[index].type == "a"}
                                              label="Automatic"
                                            />
                                          </RadioGroup>
                                        </div>
                                        <div style={{ width: "70%", marginBottom: "10px", flexDirection: "column", opacity: `${item.type == "a" ? '0' : '1'}` }}>
                                          <InputField
                                            adornment
                                            // disabled={fullTime}
                                            placeholder="Enter Waiting Time"
                                            name="value"
                                            onChange={(e) => {
                                              onChangeTime(e.target.value, index, "time")
                                              // onChangeWaitTime(e.target.value, index, "phone")
                                              // setPhnNumberValue(e.target.value)
                                            }}
                                            
                                            // onBlur={() => phnNumberValidations("phone")}
                                            // onFocus={() => phnNumberValidations("phone")}
                                            type="name"
                                            value={item.time}
                                            maxLength={15}
                                          />
                                        </div>
                                        <div className="url-Error" style={{ opacity: `${((item.time > 0 && item.type == 'm') || item.type == 'a') ? '0' : '1'}` }}>{item.error ? item.error : "dummy"}</div>
                                      </>
                                    )
                                  }
                                })}
                              </div>
                              <div>
                                {waitingTime.map((item, index) => {
                                  if ((index + 1) > 12) {
                                    console.log("this is mapped = ", waitingTime[index+1]?.type)
                                    return (
                                      <>
                                        <div className={classes.waitTimeInputWrapper}>
                                          <div className={classes.waitTimeInputWrapperLeft}>
                                          <FontAwesomeIcon icon={faClock} className={classes.clockIcon} />
                                          {`${item?.slot} (UTC)`}
                                          </div>
                                          <RadioGroup aria-label="template" name="template" value={waitingTime} onChange={(e) => handleChangeWatingTime(e, index)}>
                                            <FormControlLabel value="m" control={<Radio />}
                                              checked={waitingTime[index]?.type == "m"}
                                              label="Manual"
                                            />
                                            <FormControlLabel value="a" control={<Radio />}
                                              checked={waitingTime[index]?.type == "a"}
                                              label="Automatic"
                                            />
                                          </RadioGroup>
                                        </div>
                                        <div style={{ width: "70%", marginBottom: "10px", flexDirection: "column", opacity: `${item.type == "a" ? '0' : '1'}` }}>
                                          <InputField
                                            // disabled={fullTime}
                                            adornment
                                            placeholder="Enter Waiting Time"
                                            name="value"
                                            onChange={(e) => {
                                              onChangeTime(e.target.value, index, "time")
                                              // setPhnNumberValue(e.target.value)
                                            }}
                                            // onBlur={() => phnNumberValidations("phone")}
                                            // onFocus={() => phnNumberValidations("phone")}
                                            type="name"
                                            value={item.time}
                                            maxLength={15}
                                          />
                                        </div>
                                        <div className="url-Error" style={{ opacity: `${((item.time > 0 && item.type == 'm') || item.type == 'a') ? '0' : '1'}` }}>{item.error ? item.error : "dummy"}</div>
                                      </>
                                    )
                                  }

                                })}
                              </div>
                            </div>
                          </td>
                        </tr>}
                        <tr>
                          <td className={classes.rowKey}></td>
                          <td className={classes.rowValue}>
                            <Button title="Update" type="submit" mr />
                          </td>
                        </tr>
                      </tbody>
                    </BSTable>
                  </form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Card>
      </DashboardLayout>
    </Spin>
  )
}

export default ManageWaitTime;