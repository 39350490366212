import React, { useState, useEffect, useRef } from 'react';
// Navigation
import { useHistory, useLocation } from 'react-router-dom'
// Styles
import 'antd/dist/antd.css'
import '../dashboard.scss'
// Third party Components
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Spin, Tooltip } from 'antd'
import { Card, Table as BSTable, } from 'react-bootstrap';
// Mui Components
import { Grid, useTheme, Select, FormHelperText, } from '@material-ui/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
// Custom components
import DashboardLayout from '../../layouts/dashboardLayout'
import { InputField, Navbar, AppBar, Button } from '../../customComponents'
// Constants
import { AppImages } from '../../themes/appImages'
import { Colors } from '../../themes/colors'
import { appConstants, ValidationConstants } from '../../themes/constants'
// utils methods
import cl from '../../utils/cl'
import { useStyles } from '../../styles'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { SliderPicker, CompactPicker } from 'react-color'
import { toast } from "react-toastify";
import {
    validateImageRatioOneByOne,
} from "../../utils/helpers";
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { ColorPicker } from '../../customComponents/colorPicker';
import FullScreenDialog from "../../customComponents/previewModal";
import TerminalBoardWebsite from '../../customComponents/terminalBoardWebsite';
import { onChangeBackgroundColor } from '../../utils/helpers';
import { terminalBoardAction, defaultThemeDetailsAction,editDefaultThemeAction } from '../../store/actions/terminalBoardAction';
import { getTerminalAreaListAction, getTerminalAreaDetailsAction } from "../../store/actions";
import { getNewsListAction } from '../../store/actions/newsManagementActions';
import { isInternetConnected } from "../../InternetConnection/InternetConnection"
// Global constants

export const EditDefaultTheme = () => {
    // Hooks declarations
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const location = useLocation()
    const dispatch = useDispatch()
    const videoRef = useRef();
    const inputRef = useRef()
    const [templates, setTemplates] = useState("video")
    const [previewModal, setPreviewModal] = useState(false);
    const [fontPickerColor, setFontPickerColor] = useState("#ffffff");
    const [graphCurrentColor, setGraphCurrentColor] = useState("#00324B");
    const [graphAllBars, setGraphAllBars] = useState("#01648D");
    const [regionPickerColor, setRegionPickerColor] = useState("#009ce0");
    const [weatherColor, setWeatherColor] = useState("#000000");
    const [backgroundSingleColor, setBackgroundSingleColor] = useState("#009ce0");
    const [backgroundLinear1Color, setBackgroundLinear1Color] = useState("#009ce0");
    const [backgroundLinear2Color, setBackgroundLinear2Color] = useState("#009ce0");
    const [backgroundColor, setBackgroundColor] = useState("singleColor")
    const [selectCardBackground, setSelectCardBackground] = useState("singleColor")
    const [cardBackgroundColor, setCardBackgroundColor] = useState("#009ce0")
    const [cardBackgroundLinear1Color, setCardBackgroundLinear1Color] = useState("#009ce0")
    const [cardBackgroundLinear2Color, setCardBackgroundLinear2Color] = useState("#009ce0")
    const [backgroundColorError, setBackgroundColorError] = useState(null)
    const [cardBackgroundError, setCardBackgroundError] = useState(null)
    const [fontColorError, setFontColorError] = useState(null)
    const [graphCurrentError, setGraphCurrentError] = useState(null)
    const [graphAllBarError, setGraphAllBarError] = useState(null)
    const [regionColorError, setRegionColorError] = useState(null)
    const [weatherColorError, setWeatherColorError] = useState(null)
    const [cardLinear1Error, setCardLinear1Error] = useState(null)
    const [cardLinear2Error, setCardLinear2Error] = useState(null)
    const [backgroundLinear1Error, setBackgroundLinear1Error] = useState(null)
    const [backgroundLinear2Error, setBackgroundLinear2Error] = useState(null)
    const [selectRegion, setSelectRegion] = useState("")
    const [regionName, setRegionName] = useState(null)
    const [fontFamily, setFontFamily] = useState("")
    const [fontFamilyError, setFontFamilyError] = useState(null)
    const [image, setImage] = useState("")
    const [imageUrl, setImageUrl] = useState("");
    const [addVideo, setAddVideo] = useState(null)
    const [videoFile, setVideoFile] = useState(null)
    const [videoHeight, setVideoHeight] = useState(0)
    const [videoWidth, setVideoWidth] = useState(0)
    const { terminalAreaListing} = useSelector(
        (state) => state.terminalAreaManagementReducer
    );
    const { isLoading, isTableDataLoading } = useSelector((state) => state.terminalBoardReducer)
    const terminalBoardDetails1= useSelector((state) => state.terminalBoardReducer)
    const{terminalBoardDetails}=terminalBoardDetails1
    const state = useSelector((state) => state.newsManagementReducer);
    const { allNews } = state;
    // Lifecycle Hooks

    useEffect(() => {
        let url = (appConstants.baseURL + terminalBoardDetails?.background)
        setTemplates(terminalBoardDetails?.backgroundType)
        setSelectRegion(terminalBoardDetails?._id)
        setSelectCardBackground(terminalBoardDetails?.cardBackgroundType)
        setFontPickerColor(terminalBoardDetails?.fontColor)
        setGraphCurrentColor(terminalBoardDetails?.graphColor?.currentBar)
        setGraphAllBars(terminalBoardDetails?.graphColor?.allBars)
        setRegionPickerColor(terminalBoardDetails?.regionPickerColor)
        setWeatherColor(terminalBoardDetails?.weatherColor)
        setFontFamily(terminalBoardDetails?.fontFamily)
        setRegionName(terminalBoardDetails?.region_name)
        if (terminalBoardDetails?.backgroundType == "image") {
            setImage(url)
            setImageUrl(terminalBoardDetails?.background)
            setAddVideo(null)
            setVideoFile(null)
        }
        if (terminalBoardDetails?.backgroundType == "video") {
            setAddVideo(url)
            setVideoFile(terminalBoardDetails?.background)
            setImage(null)
            setImageUrl(null)
            console.log(addVideo, videoFile, terminalBoardDetails?.background, "sdhjasdksmdlasj")
        }
        if (terminalBoardDetails?.cardBackgroundType == "linearGradient") {
            setCardBackgroundLinear1Color(terminalBoardDetails?.cardBackground[0])
            setCardBackgroundLinear2Color(terminalBoardDetails?.cardBackground[1])
        }
        else if (terminalBoardDetails?.cardBackgroundType == "singleColor") {
            setCardBackgroundColor(terminalBoardDetails?.cardBackground[0])
        }
        if (terminalBoardDetails?.backgroundType == "linearGradient") {
            setTemplates("color")
            setBackgroundColor("linearGradient")
            setBackgroundLinear1Color(terminalBoardDetails?.backgroundColor[0])
            setBackgroundLinear2Color(terminalBoardDetails?.backgroundColor[1])
            setImage(null)
            setImageUrl(null)
            setAddVideo(null)
            setVideoFile(null)
        }
        else if (terminalBoardDetails?.backgroundType == "singleColor") {
            setTemplates("color")
            setBackgroundColor("singleColor")
            setBackgroundSingleColor(terminalBoardDetails?.backgroundColor[0])
            setImage(null)
            setImageUrl(null)
            setAddVideo(null)
            setVideoFile(null)
        }
    }, [terminalBoardDetails])

    useEffect(() => {
        document.title = appConstants.headerTitle.editDefaultTheme;
        isInternetConnected(history) &&
        dispatch(getTerminalAreaListAction("", null, null, "", ""));
        // dispatch(getNewsListAction("", null, null, "", "", location?.state
        //     ?.terminalName));
        dispatch(defaultThemeDetailsAction())

    }, [])

    //Callback methods
    const handleFormSubmit = () => {
        let backgroundColors = [backgroundLinear1Color, backgroundLinear2Color]
        let cardBackgroundColors = [cardBackgroundLinear1Color, cardBackgroundLinear2Color]
        let graphColor = {
            currentBar: graphCurrentColor,
            allBars: graphAllBars
        }
        if (backgroundSingleColor.length < 1) {
            setBackgroundColorError("Please enter color code or color name.")
        }
        else if (cardBackgroundColor.length < 1) {
            setCardBackgroundError("Please enter color code or color name.")
        }
        else if (fontPickerColor.length < 1) {
            setFontColorError("Please enter color code or color name.")
        }
        else if (graphCurrentColor.length < 1) {
            setGraphCurrentError("Please enter color code or color name.")
        }
        else if (graphAllBars.length < 1) {
            setGraphCurrentError("Please enter color code or color name.")
        }
        else if (regionPickerColor.length < 1) {
            setRegionColorError("Please enter color code or color name.")
        }
        else if (weatherColor.length < 1) {
            setWeatherColorError("Please enter color code or color name.")
        }
        else if (fontFamily == null) {
            setFontFamilyError("Please select font family.")
        }
        else if (cardBackgroundLinear1Color.length < 1) {
            setCardLinear1Error("Please enter color code or color name.")
        }
        else if (cardBackgroundLinear2Color.length < 1) {
            setCardLinear2Error("Please enter color code or color name.")
        }
        else if (backgroundLinear1Color.length < 1) {
            setBackgroundLinear1Error("Please enter color code or color name.")
        }
        else if (backgroundLinear2Color.length < 1) {
            setBackgroundLinear2Error("Please enter color code or color name.")
        }
        else if (backgroundColorError || cardBackgroundError || fontColorError || graphCurrentError || graphAllBarError || regionColorError || weatherColorError
            || (selectCardBackground == "linearGradient" && (cardLinear1Error || cardLinear2Error)) || (backgroundColor == "linearGradient"
                && (backgroundLinear1Error || backgroundLinear2Error))) {
        }
        else if (templates == "video" && (videoFile == null || addVideo == null)) {
            toast.error("Please upload video.")
        }
        else if (templates == "image" && (imageUrl == null || image == null)) {
            toast.error("Please upload image.")
        }
        else {
            isInternetConnected(history) &&
            dispatch(editDefaultThemeAction({
                backgroundType: templates,
                cardBackground: selectCardBackground == "singleColor" ? [cardBackgroundColor] : cardBackgroundColors,
                fontFamily: fontFamily,
                fontColor: fontPickerColor,
                graphColor: graphColor,
                regionPickerColor: regionPickerColor,
                background: templates == "image" ? imageUrl : videoFile,
                cardBackgroundType: selectCardBackground,
                weatherColor: weatherColor,
                typeOfBackgroundColor: backgroundColor,
                backgroundColor: backgroundColor == "singleColor" ? [backgroundSingleColor] : backgroundColors,
            }))
        }
    }

    const imageValidation = (value) => {
        let fileTypes = ["jpg", "jpeg", "svg", "png"]
        return (fileTypes.indexOf(value) > -1)
    }

    const selectOne = (e) => {
        setTemplates(e.target.value)
    }

    const videoValidation = (value) => {
        let fileTypes = ["mp4", "mpg"]
        return (fileTypes.indexOf(value) > -1)
    }

    const handleChange1 = (event) => {
        let fileSize = event?.target?.files[0]?.size
        if (event?.target?.files && event?.target?.files[0]) {
            let uploadFile = event.target.files[0]
            let splitName = uploadFile.name.split(".").pop().toLowerCase()
            let isSuccess = videoValidation(splitName)
            if (isSuccess) {
                let reader = new FileReader();
                reader.onload = (e) => {
                    var media = new Audio(reader.result);
                    media.onloadedmetadata = function () {
                        if (media.duration > 30.93666) {
                            toast.error(appConstants.videoLimit)
                            return
                        }
                        if (fileSize > (1024 * 1024 * 20)) {
                            toast.error("Video size should not be more then 20 MB.")
                            
                        }
                        else {
                            setAddVideo(e.target.result)
                            setVideoFile(event.target.files[0])
                        }
                    };
                };
                reader.readAsDataURL(event.target.files[0]);
                return
            }
            else {
                toast.error(appConstants.videoAllowed)
            }

        }
    };

    const handleImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            let uploadFile = event.target.files[0]
            let splitName = uploadFile.name.split(".").pop().toLowerCase()
            let isSuccess = imageValidation(splitName)
            if (isSuccess) {
                setImageUrl(event.target.files[0]);

                let reader = new FileReader();
                reader.onload = (e) => {
                    setImage(e.target.result);
                };
                reader.readAsDataURL(event.target.files[0]);
                return
            }
            else {
                toast.error(appConstants.imageValidation)
            }
        }
    };

   
    const previewOpen = () => {
        if (backgroundSingleColor?.length < 1) {
            setBackgroundColorError("Please enter color code or color name.")
        }
        else if (cardBackgroundColor?.length < 1) {
            setCardBackgroundError("Please enter color code or color name.")
        }
        else if (fontPickerColor?.length < 1) {
            setFontColorError("Please enter color code or color name.")
        }
        else if (graphCurrentColor?.length < 1) {
            setGraphCurrentError("Please enter color code or color name.")
        }
        else if (graphAllBars.length < 1) {
            setGraphCurrentError("Please enter color code or color name.")
        }
        else if (regionPickerColor?.length < 1) {
            setRegionColorError("Please enter color code or color name.")
        }
        else if (weatherColor?.length < 1) {
            setWeatherColorError("Please enter color code or color name.")
        }
        else if (fontFamily == null) {
            setFontFamilyError("Please select font family.")
        }
        else if (cardBackgroundLinear1Color?.length < 1) {
            setCardLinear1Error("Please enter color code or color name.")
        }
        else if (cardBackgroundLinear2Color?.length < 1) {
            setCardLinear2Error("Please enter color code or color name.")
        }
        else if (backgroundLinear1Color?.length < 1) {
            setBackgroundLinear1Error("Please enter color code or color name.")
        }
        else if (backgroundLinear2Color?.length < 1) {
            setBackgroundLinear2Error("Please enter color code or color name.")
        }
        else if (templates == "video" && (videoFile == null || addVideo == null)) {
            toast.error("Please upload video.")
        }
        else if (templates == "image" && (imageUrl == null || image == null)) {
            toast.error("Please upload image.")
        }
        else if (backgroundColorError || cardBackgroundError || fontColorError || graphCurrentError|| graphAllBarError ||  regionColorError || weatherColorError
            || (selectCardBackground == "linearGradient" && (cardLinear1Error || cardLinear2Error)) || (backgroundColor == "linearGradient"
                && (backgroundLinear1Error || backgroundLinear2Error))) {

        }
        else {
            setPreviewModal(true)
        }
    }

    const deleteVideo = () => {
        setAddVideo("")
        setVideoFile(null)
    }

    const handleKeyDown = (e) => {
        if (e.key === " ") {
            e.preventDefault();
        }
    };

    return (
        <Spin
            size="large"
            spinning={isLoading}
            wrapperClassName={classes.tableSpinner}
            className={classes.antSpin}
        >
            <DashboardLayout>
                {previewModal && (

                    <TerminalBoardWebsite
                        // title="Alert"
                        // text={appConstants.confirmDeleteNews}
                        visible={previewModal}
                        // imageUrl={image}
                        onCancel={() => {
                            setPreviewModal(false);
                        }}
                        regionPickerColor={regionPickerColor}
                        fontPickerColor={fontPickerColor}
                        graphCurrentColor={graphCurrentColor}
                        graphAllBars={graphAllBars}
                        typeOfBackground={templates}
                        backgroundImageWebsite={image}
                        backgroundVideo={addVideo}
                        typeOfBackgroundColor={backgroundColor}
                        backgroundColor={backgroundColor == "singleColor" ? backgroundSingleColor : { backgroundLinear1Color, backgroundLinear2Color }}
                        selectCardBackground={selectCardBackground}
                        cardBackground={selectCardBackground == "singleColor" ? cardBackgroundColor : { cardBackgroundLinear1Color, cardBackgroundLinear2Color }}
                        fontFamily={fontFamily}
                        weatherColor={weatherColor}
                        regionName={regionName}
                        allNews={allNews}
                    />
                )}
                <AppBar breadcrumbs={[{ route: '/terminal-board', name: "Terminal Board" },
                { route: '/edit-terminal-board', name: "Edit Default Theme" }]} />

                <Navbar title="Edit Default Theme" />
                <Card className={classes.card}>
                    <Grid container>
                        <Grid className={classes.tableContainerRow}>
                            <form >
                                <BSTable striped bordered style={{ minHeight: 125 }}>
                                    {
                                        isTableDataLoading ? <thead className={classes.bsHeaderTable}>
                                            <Spin className={classes.tableDataSpinner} />
                                        </thead>
                                            :
                                            <tbody>
                                                <tr>
                                                    <td className={classes.rowKey}>Background</td>
                                                    <td className={classes.rowValue}>
                                                        <div className={classes.inputWrapper}>
                                                            <RadioGroup aria-label="template" row name="template"
                                                                value={templates} onChange={selectOne}>
                                                                <FormControlLabel value="video" control={<Radio  onClick={()=>setImage(null)}/>}
                                                                    checked={templates == "video"}
                                                                    label="Video"
                                                                />
                                                                <FormControlLabel value="image" control={<Radio onClick={()=>setAddVideo(null)}/>}
                                                                    checked={templates == "image"}
                                                                    label="Image"
                                                                />
                                                                <FormControlLabel value="color" control={<Radio onClick={() => {
                                                                    setAddVideo(null)
                                                                    setImage(null)
                                                                }}/>}
                                                                    checked={templates == "color"}
                                                                    label="Color"
                                                                />
                                                            </RadioGroup>
                                                        </div>
                                                    </td>
                                                </tr>
                                                {
                                                    templates == "video" ?
                                                        <tr>
                                                            <td className={classes.rowKey}>

                                                            </td>
                                                            <td className={classes.rowValue}>
                                                                <div className={classes.inputWrapper} 
                                                                style={{ display: "-webkit-box", marginBottom: "-12px",  }}>
                                                                    {addVideo ?
                                                                        <div style={{ marginRight: "5%" }}>
                                                                            <div className='video-class'  style={{
                                                                                   top: videoHeight > 1500 ? "-5%" : videoHeight == 240 ? "-4%" : "6%",
                                                                                    left: "-3%"
                                                                                }}>
                                                                                <img src={AppImages.close}
                                                                                    style={{
                                                                                        position: "absolute",

                                                                                    }}
                                                                                    width="12px" height="12px" onClick={() => deleteVideo()}></img>
                                                                            </div>
                                                                            <video
                                                                             id='video_height'
                                                                                controls
                                                                                style={{
                                                                                    width: "100%",
                                                                                    objectFit: "contain",
                                                                                    height: "200px"
                                                                                }}
                                                                                onLoadedMetadata={(e) => {
                                                                                    const vid = document.getElementById("video_height");
                                                                                    const height = vid.videoHeight;
                                                                                    const width = vid.videoWidth;
                                                                                    setVideoHeight(height)
                                                                                    setVideoWidth(width)
                                                                                    console.log(height, width, "width")
                                                                                }}
                                                                            >
                                                                                <source src={addVideo}
                                                                                    className={classes.terminalImage}
                                                                                    type="video/mp4"
                                                                                ></source>
                                                                                <source src={addVideo}
                                                                                    className={classes.terminalImage}
                                                                                    type="video/mpeg"
                                                                                ></source>
                                                                            </video>
                                                                        </div>
                                                                        :
                                                                        <>
                                                                            <input
                                                                                style={{ zIndex: "999999", position: "absolute" }}
                                                                                accept="video/mp4,video/x-m4v,video/*,video/mov,video/mpeg"
                                                                                className={classes.uploaderInput}
                                                                                id="upload"
                                                                                type="file"
                                                                                onChange={(e) => {

                                                                                    handleChange1(e)
                                                                                }}
                                                                                onClick={(event)=> { 
                                                                                    event.target.value = null
                                                                               }}
                                                                                ref={videoRef}
                                                                            />
                                                                            <label
                                                                                id="upload"
                                                                                className={classes.imageManagementUploaderlabel}
                                                                            >
                                                                                <Tooltip
                                                                                    title={addVideo ? appConstants.tooltip.changeVideo : appConstants.tooltip.uploadVideo}
                                                                                    placement="right"
                                                                                >
                                                                                    <img
                                                                                        onClick={() => videoRef.current.click()}
                                                                                        src={AppImages.addVideo}
                                                                                        className={classes.terminalImage}

                                                                                    />

                                                                                </Tooltip>
                                                                            </label>
                                                                        </>}

                                                                </div>
                                                            </td>
                                                        </tr>
                                                        :
                                                        templates == "image" ?
                                                            <tr>
                                                                <td className={classes.rowKey}>

                                                                </td>
                                                                <td className={classes.rowValue}>
                                                                    <div className={classes.inputWrapper} style={{ marginBottom: "-12px" }}>
                                                                        <input
                                                                            accept=".png,.jpg,.jpeg"
                                                                            className={classes.uploaderInput}
                                                                            id="icon-button-file"
                                                                            type="file"
                                                                            onChange={(e) => {

                                                                                handleImageChange(e)

                                                                            }}
                                                                            onClick={(event)=> { 
                                                                                event.target.value = null
                                                                           }}
                                                                        />
                                                                        <label
                                                                            htmlFor="icon-button-file"
                                                                            className={classes.imageManagementUploaderlabel}
                                                                        >
                                                                            <Tooltip
                                                                                title={image ? appConstants.tooltip.changeImage : appConstants.tooltip.uploadImage}
                                                                                placement="right"
                                                                            >
                                                                                {image ? (
                                                                                    <img
                                                                                        src={image}
                                                                                        className={classes.terminalImage}
                                                                                        style={{
                                                                                            borderRadius: "50px",
                                                                                            border: "2px solid lightgray"
                                                                                        }}
                                                                                    />
                                                                                ) : (
                                                                                    <img
                                                                                        src={AppImages.addPhoto}
                                                                                        className={classes.terminalImage}
                                                                                        onChange={(e) => {
                                                                                            handleImageChange(e)

                                                                                        }}

                                                                                    />
                                                                                )}
                                                                            </Tooltip>
                                                                        </label>


                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            :
                                                            <tr>
                                                                <td className={classes.rowKey}></td>
                                                                <td className={classes.rowValue}>
                                                                    <div className={classes.inputWrapper} style={{ width: "400px" }}>
                                                                        <RadioGroup aria-label="backgroundColor" row name="backgroundColor"
                                                                            value={backgroundColor} onChange={(e) => setBackgroundColor(e.target.value)}>
                                                                            <FormControlLabel value="singleColor" control={<Radio />}
                                                                                checked={backgroundColor == "singleColor"}
                                                                                label="Single Color"
                                                                            />
                                                                            <FormControlLabel value="linearGradient" control={<Radio />}
                                                                                checked={backgroundColor == "linearGradient"}
                                                                                label="Linear Gradient Color"
                                                                            />

                                                                        </RadioGroup>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                }
                                                {
                                                    templates == "color" ?
                                                        (backgroundColor == "singleColor") ?
                                                            <tr>
                                                                <td className={classes.rowKey}></td>
                                                                <td className={classes.rowValue}>
                                                                    <div className={classes.inputWrapper} >
                                                                        <>
                                                                            <div style={{ marginBottom: "5px" }}>
                                                                                <InputField
                                                                                    id="color"
                                                                                    placeholder="Enter Background Color"
                                                                                    name="color"
                                                                                    onChange={(color) => {
                                                                                        // setBackgroundSingleColor(inputRef.current.state.hex);
                                                                                        onChangeBackgroundColor(color, setBackgroundSingleColor, setBackgroundColorError)
                                                                                    }}
                                                                                    type="name"
                                                                                    value={backgroundSingleColor?.trim()}
                                                                                    // maxLength={12}
                                                                                onKeyDown={handleKeyDown}
                                                                                />

                                                                            </div>
                                                                            <CompactPicker
                                                                                ref={inputRef}
                                                                                color={backgroundSingleColor}
                                                                                onChange={(color) => {
                                                                                    setBackgroundSingleColor(color.hex);
                                                                                    setBackgroundColorError(null)
                                                                                }}
                                                                            />
                                                                            {backgroundColorError !== null &&
                                                                                <div className="radio-error"
                                                                                    style={{ marginTop: "11px", marginLeft: "0px" }}
                                                                                >{backgroundColorError}</div>}
                                                                        </>
                                                                    </div>

                                                                </td>
                                                            </tr>
                                                            :
                                                            <tr>
                                                                <td className={classes.rowKey}></td>
                                                                <td className={classes.rowValue}>
                                                                    <div className={classes.inputWrapper} >
                                                                        <div className='linear_gradient_colors'>
                                                                            <div style={{ marginRight: "15px" }}>
                                                                                <div style={{ marginBottom: "5px" }}>
                                                                                    <InputField
                                                                                        id="color"
                                                                                        placeholder="Enter Background Color"
                                                                                        name="color"
                                                                                        // error={Boolean(touched.shortName && errors.shortName)}
                                                                                        // helperText={touched.shortName && errors.shortName}
                                                                                        // onBlur={handleBlur}
                                                                                        onChange={(color) => {
                                                                                            onChangeBackgroundColor(color, setBackgroundLinear1Color, setBackgroundLinear1Error)
                                                                                        }}
                                                                                        type="name"
                                                                                        value={backgroundLinear1Color?.trim()}
                                                                                        // maxLength={12}
                                                                                    onKeyDown={handleKeyDown}
                                                                                    />
                                                                                </div>
                                                                                <CompactPicker
                                                                                    color={backgroundLinear1Color}
                                                                                    onChange={(color) => {
                                                                                        setBackgroundLinear1Color(color.hex);
                                                                                        setBackgroundLinear1Error(null)
                                                                                    }}
                                                                                />
                                                                                {backgroundLinear1Error !== null &&
                                                                                    <div className="radio-error"
                                                                                        style={{ marginTop: "11px", marginLeft: "0px" }}
                                                                                    >{backgroundLinear1Error}</div>}
                                                                            </div>

                                                                            <div>
                                                                                <div style={{ marginBottom: "5px" }}>
                                                                                    <InputField
                                                                                        id="color"
                                                                                        placeholder="Enter Background Color"
                                                                                        name="color"
                                                                                        onChange={(color) => {
                                                                                            onChangeBackgroundColor(color, setBackgroundLinear2Color, setBackgroundLinear2Error)
                                                                                        }}
                                                                                        type="name"
                                                                                        value={backgroundLinear2Color?.trim()}
                                                                                        // maxLength={12}
                                                                                    onKeyDown={handleKeyDown}
                                                                                    />
                                                                                </div>
                                                                                <CompactPicker
                                                                                    color={backgroundLinear2Color}
                                                                                    onChange={(color) => {
                                                                                        setBackgroundLinear2Color(color.hex);
                                                                                        setBackgroundLinear2Error(null)
                                                                                    }}
                                                                                />
                                                                                {backgroundLinear2Error !== null &&
                                                                                    <div className="radio-error"
                                                                                        style={{ marginTop: "11px", marginLeft: "0px" }}
                                                                                    >{backgroundLinear2Error}</div>}
                                                                            </div>
                                                                        </div>
                                                                    </div>




                                                                </td>
                                                            </tr>
                                                        :
                                                        null
                                                }
                                                <tr>
                                                    <td className={classes.rowKey}>Card Background</td>
                                                    <td className={classes.rowValue}>
                                                        <div className={classes.inputWrapper} style={{ width: "400px" }}>
                                                            <RadioGroup aria-label="selectCardBackground" row={true}
                                                                name="selectCardBackground"
                                                                value={selectCardBackground} onChange={(e) => setSelectCardBackground(e.target.value)}>
                                                                <FormControlLabel value="singleColor" control={<Radio />}
                                                                    checked={selectCardBackground == "singleColor"}
                                                                    label="Single Color"
                                                                />
                                                                <FormControlLabel value="linearGradient" control={<Radio />}
                                                                    checked={selectCardBackground == "linearGradient"}
                                                                    label="Linear Gradient Color"
                                                                />

                                                            </RadioGroup>
                                                        </div>
                                                    </td>
                                                </tr>
                                                {
                                                    (selectCardBackground == "singleColor") ?
                                                        <tr>
                                                            <td className={classes.rowKey}></td>
                                                            <td className={classes.rowValue}>
                                                                <div className={classes.inputWrapper} >
                                                                    <ColorPicker
                                                                        value={cardBackgroundColor}
                                                                        // maxLength={12}
                                                                        placeholder="Enter Card Background Color"
                                                                        onChange={(color) => {
                                                                            onChangeBackgroundColor(color, setCardBackgroundColor, setCardBackgroundError)
                                                                        }}
                                                                        color={cardBackgroundColor}
                                                                        colorChange={(color) => {
                                                                            setCardBackgroundColor(color.hex);
                                                                            setCardBackgroundError(null)
                                                                        }}
                                                                        error={cardBackgroundError}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        :
                                                        <tr>
                                                            <td className={classes.rowKey}></td>
                                                            <td className={classes.rowValue}>
                                                                <div className={classes.inputWrapper} >
                                                                    <div className='linear_gradient_colors'>
                                                                        <div style={{ marginRight: "15px" }}>
                                                                            <div style={{ marginBottom: "5px" }}>
                                                                                <InputField
                                                                                    id="color"
                                                                                    placeholder="Enter Card Background Color"
                                                                                    name="color"
                                                                                    // error={Boolean(touched.shortName && errors.shortName)}
                                                                                    // helperText={touched.shortName && errors.shortName}
                                                                                    // onBlur={handleBlur}
                                                                                    onChange={(color) => {
                                                                                        onChangeBackgroundColor(color, setCardBackgroundLinear1Color, setCardLinear1Error)
                                                                                    }}
                                                                                    type="name"
                                                                                    value={cardBackgroundLinear1Color?.trim()}
                                                                                    // maxLength={12}
                                                                                onKeyDown={handleKeyDown}
                                                                                />
                                                                            </div>
                                                                            <CompactPicker
                                                                                color={cardBackgroundLinear1Color}
                                                                                onChange={(color) => {
                                                                                    setCardBackgroundLinear1Color(color.hex);
                                                                                    setCardLinear1Error(null)
                                                                                }}
                                                                            />
                                                                            {cardBackgroundLinear1Color !== null &&
                                                                                <div className="radio-error"
                                                                                    style={{ marginTop: "11px", marginLeft: "0px" }}
                                                                                >{cardLinear1Error}</div>}
                                                                        </div>

                                                                        <div>
                                                                            <div style={{ marginBottom: "5px" }}>
                                                                                <InputField
                                                                                    id="shortName"
                                                                                    placeholder="Enter Card Background Color"
                                                                                    name="shortName"
                                                                                    onChange={(color) => {
                                                                                        onChangeBackgroundColor(color, setCardBackgroundLinear2Color, setCardLinear2Error)
                                                                                    }}
                                                                                    type="name"
                                                                                    value={cardBackgroundLinear2Color?.trim()}
                                                                                    // maxLength={12}
                                                                                onKeyDown={handleKeyDown}
                                                                                />
                                                                            </div>
                                                                            <CompactPicker
                                                                                color={cardBackgroundLinear2Color}
                                                                                onChange={(color) => {
                                                                                    setCardBackgroundLinear2Color(color.hex);
                                                                                    setCardLinear2Error(null)
                                                                                }}
                                                                            />
                                                                            {cardBackgroundLinear2Color !== null &&
                                                                                <div className="radio-error"
                                                                                    style={{ marginTop: "11px", marginLeft: "0px" }}
                                                                                >{cardLinear2Error}</div>}
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                }
                                                
                                                <tr>
                                                    <td className={classes.rowKey}>
                                                        Font Family
                                                    </td>
                                                    <td className={classes.rowValue}>
                                                        <div className={classes.inputWrapper}>
                                                            <Select
                                                                native
                                                                style={{ fontFamily: fontFamily }}
                                                                className={classes.selectInput}
                                                                value={fontFamily}
                                                                onChange={(e) => {
                                                                   
                                                                    setFontFamily(e.target.value)
                                                                }}
                                                                inputProps={{
                                                                    name: "fontFamily",
                                                                    id: "fontFamily",
                                                                }}
                                                            >
                                                               
                                                                <option value="serif" style={{ fontFamily: "serif" }}>Serif</option>
                                                                <option value="sans-serif" style={{ fontFamily: "sans-serif" }}>Sans-serif</option>
                                                                <option value="cursive" style={{ fontFamily: "cursive" }}>Cursive</option>
                                                                <option value="monospace" style={{ fontFamily: "monospace" }}>Monospace</option>
                                                                <option value="timesNewRoman" style={{ fontFamily: "Times New Roman" }}>Times New Roman</option>
                                                                <option value="chilanka" style={{ fontFamily: "Chilanka" }}>Chilanka</option>
                                                                <option value="freeMono" style={{ fontFamily: "Free Mono" }}>Free Mono</option>
                                                                <option value="Georgia" style={{ fontFamily: "Georgia" }}>Georgia</option>
                                                                <option value="Garamond" style={{ fontFamily: "Garamond" }}>Garamond</option>
                                                                <option value="Keraleeyam" style={{ fontFamily: "Keraleeyam" }}>Keraleeyam</option>
                                                            </Select>
                                                            {fontFamily == null &&
                                                                <div className="radio-error"
                                                                    style={{ marginTop: "11px", marginLeft: "0px" }}
                                                                >{fontFamilyError}</div>}
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={classes.rowKey}>Font Color</td>
                                                    <td className={classes.rowValue}>
                                                        <div className={classes.inputWrapper} >
                                                            <ColorPicker
                                                                value={fontPickerColor}
                                                                // maxLength={12}
                                                                placeholder="Enter Font Color"
                                                                onChange={(color) => {
                                                                    onChangeBackgroundColor(color, setFontPickerColor, setFontColorError)
                                                                }}
                                                                color={fontPickerColor}
                                                                colorChange={(color) => {
                                                                    setFontPickerColor(color.hex);
                                                                    setFontColorError(null)
                                                                }}
                                                                error={fontColorError}
                                                            />

                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={classes.rowKey}>Graph Color</td>
                                                    <td className={classes.rowValue}>
                                                        <div className={classes.inputWrapper} >
                                                            <div className='linear_gradient_colors'>
                                                                <div style={{ marginRight: "15px" }}>
                                                                    <div style={{ marginBottom: "5px" }}>
                                                                        <p className='bar_colors'>Current Bar</p>
                                                                        <InputField
                                                                            id="color"
                                                                            placeholder="Enter Graph Color"
                                                                            name="color"
                                                                            // error={Boolean(touched.shortName && errors.shortName)}
                                                                            // helperText={touched.shortName && errors.shortName}
                                                                            // onBlur={handleBlur}
                                                                            onChange={(color) => {
                                                                                onChangeBackgroundColor(color, setGraphCurrentColor, setGraphCurrentError)
                                                                            }}
                                                                            type="name"
                                                                            value={graphCurrentColor?.trim()}
                                                                            // maxLength={12}
                                                                        onKeyDown={handleKeyDown}
                                                                        />
                                                                    </div>
                                                                    <CompactPicker
                                                                        color={graphCurrentColor}
                                                                        onChange={(color) => {
                                                                            setGraphCurrentColor(color.hex);
                                                                            setGraphCurrentError(null)
                                                                        }}
                                                                    />
                                                                    {graphCurrentError !== null &&
                                                                        <div className="radio-error"
                                                                            style={{ marginTop: "11px", marginLeft: "0px" }}
                                                                        >{graphCurrentError}</div>}
                                                                </div>

                                                                <div>
                                                                    <div style={{ marginBottom: "5px" }}>
                                                                        <p className='bar_colors'>All Bars</p>
                                                                        <InputField
                                                                            id="shortName"
                                                                            placeholder="Enter Graph Color"
                                                                            name="shortName"
                                                                            onChange={(color) => {
                                                                                onChangeBackgroundColor(color, setGraphAllBars, setGraphAllBarError)
                                                                            }}
                                                                            type="name"
                                                                            value={graphAllBars?.trim()}
                                                                            // maxLength={12}
                                                                        onKeyDown={handleKeyDown}
                                                                        />
                                                                    </div>
                                                                    <CompactPicker
                                                                        color={graphAllBars}
                                                                        onChange={(color) => {
                                                                            setGraphAllBars(color.hex);
                                                                            setGraphAllBarError(null)
                                                                        }}
                                                                    />
                                                                    {graphAllBarError !== null &&
                                                                        <div className="radio-error"
                                                                            style={{ marginTop: "11px", marginLeft: "0px" }}
                                                                        >{graphAllBarError}</div>}
                                                                </div>

                                                            </div>
                                                        </div>
                                                        {/* <div className={classes.inputWrapper} >
                                                            <ColorPicker
                                                                value={graphCurrentColor}
                                                                maxLength={12}
                                                                onChange={(color) => {
                                                                    onChangeBackgroundColor(color, setGraphCurrentColor, setGraphCurrentError)
                                                                }}
                                                                color={graphCurrentColor}
                                                                colorChange={(color) => {
                                                                    setGraphCurrentColor(color.hex);
                                                                    setGraphCurrentError(null)
                                                                }}
                                                                error={graphCurrentError}
                                                            />
                                                        </div> */}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={classes.rowKey}>Region Picker Color</td>
                                                    <td className={classes.rowValue}>
                                                        <div className={classes.inputWrapper} >
                                                            <ColorPicker
                                                                value={regionPickerColor}
                                                                // maxLength={12}
                                                                placeholder="Enter Region Picker Color"
                                                                onChange={(color) => {
                                                                    onChangeBackgroundColor(color, setRegionPickerColor, setRegionColorError)
                                                                }}
                                                                color={regionPickerColor}
                                                                colorChange={(color) => {
                                                                    setRegionPickerColor(color.hex);
                                                                    setRegionColorError(null)
                                                                }}
                                                                error={regionColorError}
                                                            />

                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={classes.rowKey}>Weather Color</td>
                                                    <td className={classes.rowValue}>
                                                        <div className={classes.inputWrapper} >
                                                            <ColorPicker
                                                                value={weatherColor}
                                                                // maxLength={12}
                                                                placeholder="Enter Weather Color"
                                                                onChange={(color) => {
                                                                    onChangeBackgroundColor(color, setWeatherColor, setWeatherColorError)
                                                                }}
                                                                color={weatherColor}
                                                                colorChange={(color) => {
                                                                    setWeatherColor(color.hex);
                                                                    setWeatherColorError(null)
                                                                }}
                                                                error={weatherColorError}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={classes.rowKey}></td>
                                                    <td className={classes.rowValue}>
                                                        <Button title={appConstants.update} mr
                                                            onClick={() => handleFormSubmit()}
                                                        />
                                                        <Button title="Preview"
                                                            // disabled={previewOpen()}
                                                            onClick={previewOpen} />
                                                    </td>
                                                </tr>
                                            </tbody>
                                    }
                                </BSTable>
                            </form>

                        </Grid>
                    </Grid>
                </Card>
            </DashboardLayout>
        </Spin>
    );
}
