import http from "./apiKit";
import { appMessages } from "../../themes/constants";
import cl from "../../utils/cl";
import history from "../../utils/history";
import { convertArrayToCSV } from "convert-array-to-csv";

const logout = () => {
  localStorage.clear();
  history.push("/");
};

export default {
  // ********** AUTHENTICATION **********
  // Login Api Call
  login(email, password) {
    let data = JSON.stringify({
      email: email,
      password: password,
      type: "admin",
    });
    return Method.POST("user/login", data);
  },

  // Forgot Password Api Call
  forgotPassword(email) {
    let data = JSON.stringify({
      email: email,
      type: "admin",
    });
    return Method.POST("user/forgot", data);
  },

  // Reset Password Api call
  resetPassword(newPassword, query) {
    let data = JSON.stringify({
      password: newPassword,
    });
    return Method.POST(`user/reset${query}`, data);
  },

  // Check reset Password
  checkResetPassword(id) {
    let data = JSON.stringify({
      id: id,
    });
    return Method.POST(`user/checkPassword`, data);
  },
  // ********** USER MANAGEMENT **********
  // Get user list
  async getUserList(search, offset, limit, sortBy, order, startDate, endDate, deviceType) {
    console.log(startDate,"startDate==")
    search = await searchFilter(search);
    let url = `user/list?search=${search}&offset=${offset}&limit=${limit}`;
    if (order) {
      url += `&order=${order}&sort_by=${sortBy}`;
    }

    if ((startDate)) {
      url += `&startDate=${startDate}`;
    }
    if ((endDate)) {
      url += `&endDate=${endDate}`;
    }
    
    if (deviceType) {
      url += `&deviceType=${deviceType}`
    }
    // url += `&user_id=${userId}`;
    return Method.GET(url);
  },
  // Get user details
  getUserDetails(userId) {
    return Method.GET(`user/details?user_id=${userId}`);
  },
  // Edit user details
  editUserDetails(
    userId,
    firstName,
    lastName,
    userName,
    email,
    phoneNumber,
    countryCode,
    userType,
    imageUrl
  ) {
    let formData = new FormData();
    formData.append("user_id", userId);
    formData.append("name", firstName);
    // formData.append("lastName", lastName);
    formData.append("userName", userName);
    formData.append("email", email);
    formData.append("phone_number", phoneNumber);
    formData.append("country_code", countryCode);
    // formData.append("user_type", userType);
    formData.append("image", imageUrl);
    // let data = JSON.stringify({
    //   user_id: userId,
    //   firstName: firstName,
    //   lastName: lastName,
    //   userName: userName,
    //   email: email,
    //   phone_number: phoneNumber,
    //   country_code: countryCode,
    //   user_type: userType,
    // });
    return Method.PUT("user/edit", formData);
  },
  // Block user
  blockUser(userId, isBlocked) {
    let data = JSON.stringify({
      user_id: userId,
      is_block: isBlocked,
    });
    return Method.PUT("user/block", data);
  },

  exportUserFile(fileType) {
    return Method.GETDATAUSER("user/export", "User listing");
  },

  importUserFile(file, fileType) {
    let formData = new FormData();
    formData.append("excelFile", file);
    return Method.POST("user/import", formData);
  },

  async getReportedUserList(payload) {
    let url = `user/reportList`;
    let body = JSON.stringify({
      "search": payload.search,
      "offset": payload.offset,
      "limit": payload.limit,
      "sortBy": payload.sortBy,
      "order": payload.order,
      "userId": payload.userId
    })

    return Method.POST(url, body);
  },

  // Get user details
  getReportedUserDetails(userId) {
    return Method.GET(`user/reportDetails/${userId}`);
  },

  //export reported users
  exportReportedUserFile(fileType) {
    return Method.GETREPORTEDDATAUSER(`user/exportReport?user_id=${fileType}`, "Reported User listing");
  },


  // ********** TERMINAL MANAGEMENT **********
  async getTerminalList(search, offset, limit, sortBy, order) {
    search = await searchFilter(search);
    let url;
    if (offset == null && limit == null) {
      url = `terminal/list?search=${search}`;
    }
    else {
      url = `terminal/list?search=${search}&offset=${offset}&limit=${limit}`;
    }
    if (order) {
      url += `&order=${order}&sort_by=${sortBy}`;
    }
    return Method.GET(url);
  },

  addTerminal(
    terminalImage,
    terminalUrlArray,
    terminalArea,
    terminalName,
    locationCategory,
    terminalCountry,
    terminalAddress,
    terminalLatitude,
    terminalLongitude,
    terminalCity,
    terminalState,
    zipCode,
    terminalRadius,
    terminalOpenTime,
    terminalCloseTime,
    fullTime,
    mapLogoFile,
    terminalBoardLogoFile,
    discription,
    showPolygonData,
    geoFence,
    zoom,
    templates,
    phoneNumbers,
    extraTimeArray,
    about,
    showTerminal
  ) {

    let formData = new FormData();
    if (terminalImage !== null) {
      formData.append("terminal_logo", terminalImage);
    }
    if (terminalUrlArray.length > 0) {
      formData.append('url', JSON.stringify(terminalUrlArray))
    }
    formData.append('type', templates)
    formData.append("terminal_name", terminalName);
    formData.append("terminal_category", locationCategory);
    formData.append("region_id", terminalArea);
    formData.append("country", terminalCountry);
    formData.append("terminal_location", terminalAddress);
    formData.append("city", terminalCity);
    formData.append("state_province", terminalState);
    formData.append("zipcode", zipCode);
    formData.append("latitude", terminalLatitude);
    formData.append("longitude", terminalLongitude);
    formData.append("open_time", terminalOpenTime);
    formData.append("close_time", terminalCloseTime);
    formData.append("radius", terminalRadius);
    formData.append("is24Available", fullTime);
    formData.append("map_logo", mapLogoFile);
    formData.append("terminal_board_logo", terminalBoardLogoFile);
    formData.append('terminal_description', discription)
    formData.append('geoFence', JSON.stringify(geoFence))
    formData.append('zoom', zoom)
    formData.append('phone', JSON.stringify(phoneNumbers))
    if(about.length>0){
    formData.append('aboutUs', about)
    }
    formData.append('isShow', showTerminal)
    // if (extraTimeArray.length > 0) {
    formData.append("extraTime", JSON.stringify(extraTimeArray));
    // }

    if (showPolygonData.length > 0) {
      formData.append("polygon_area", JSON.stringify(showPolygonData));
    }
    return Method.POST("terminal/add", formData);
  },

  getTerminalDetails(terminalId) {
    return Method.GET(`terminal/details/${terminalId}`);
  },

  editTerminal(
    terminalId,
    terminalImage,
    terminalUrlArray,
    terminalArea,
    terminalName,
    locationCategory,
    terminalCountry,
    terminalAddress,
    terminalLatitude,
    terminalLongitude,
    terminalCity,
    terminalState,
    zipCode,
    terminalRadius,
    terminalOpenTime,
    terminalCloseTime,
    fullTime,
    mapLogoFile,
    terminalPolygon,
    terminalBoardLogoFile,
    discription,
    geoFence,
    zoom,
    templates,
    phnNumberArray,
    extraTimeArray,
    about,
    showTerminal
  ) {

    let formData = new FormData();
    if (terminalImage !== null) {
      formData.append("terminal_logo", terminalImage);
    }
    if (terminalUrlArray.length > 0) {
      formData.append('url', JSON.stringify(terminalUrlArray))
    }
    formData.append('type', templates)
    formData.append("id", terminalId);
    formData.append("terminal_name", terminalName);
    formData.append("terminal_category", locationCategory);
    formData.append("region_id", terminalArea);
    formData.append("country", terminalCountry);
    formData.append("terminal_location", terminalAddress);
    formData.append("city", terminalCity);
    formData.append("state_province", terminalState);
    formData.append("zipcode", zipCode);
    formData.append("latitude", terminalLatitude);
    formData.append("longitude", terminalLongitude);
    formData.append("open_time", terminalOpenTime);
    formData.append("close_time", terminalCloseTime);
    formData.append("radius", terminalRadius);
    formData.append("is24Available", fullTime);
    formData.append("map_logo", mapLogoFile);
    formData.append("terminal_board_logo", terminalBoardLogoFile);
    formData.append('geoFence', JSON.stringify(geoFence))
    formData.append('zoom', zoom)
    formData.append('phone', JSON.stringify(phnNumberArray))
    formData.append('aboutUs', about)
    formData.append('isShow', showTerminal)
    if (extraTimeArray) {
      formData.append('extraTime', JSON.stringify(extraTimeArray))
    }

    // formData.append("terminal_image_type", templates)
    if (terminalPolygon) {
      formData.append("polygon_area", terminalPolygon);
    }
    formData.append('terminal_description', discription ? discription : null)

    return Method.PUT("terminal/edit", formData);
  },

  deleteTerminal(terminalId) {
    return Method.DELETE(`terminal/delete/${terminalId}`);
  },

  importFile(file, fileType) {
    let formData = new FormData();
    formData.append("excelFile", file);
    if (fileType === "terminal") {
      return Method.POST("terminal/import", formData);
    } else if (fileType === "terminalArea") {
      return Method.POST("region/import", formData);
    }
  },
  exportFile(fileType) {
    if (fileType === "terminal") {
      return Method.GETDATA("terminal/export", "Terminals");
    } else if (fileType === "terminalArea") {
      return Method.GETDATA("region/export", "Terminal Area");
    }
  },

  async getResultList(payload) {
    let url = `/terminal/ratingList`;
    let body = JSON.stringify({
      "search": payload.search,
      "offset": payload.offset,
      "limit": payload.limit,
      "sortBy": payload.sortBy,
      "order": payload.order,
      "terminal_id": payload.terminalId
    })
    return Method.POST(url, body);
  },

  async getRatingStatus(payload) {
    let url = 'rating/status'
    let body = JSON.stringify({
      type: payload.type,
      status: payload.status,
      id: payload.id,
    })
    return Method.POST(url, body);
  },
  // ********** TERMINAL AREA MANAGEMENT **********
  async getTerminalAreaList(search, offset, limit, sortBy, order) {
    search = await searchFilter(search);
    let url = `region/list`;
    if (offset !== null) {
      url += `?search=${search}&offset=${offset}&limit=${limit}&sort_by=${sortBy}`;
    }
    if (order) {
      url += `&order=${order}`;
    }
    return Method.GET(url);
  },

  addTerminalArea(terminalAreaName, stateName) {
    let data = JSON.stringify({
      region_name: terminalAreaName,
      state_id: stateName
    });
    return Method.POST("region/add", data);
  },

  getTerminalAreaDetails(terminalAreaId) {
    return Method.GET(`region/details/${terminalAreaId}`);
  },

  editTerminalArea(terminalAreaId, terminalAreaName, stateName) {
    let data = JSON.stringify({
      id: terminalAreaId,
      region_name: terminalAreaName,
      state_id: stateName
    });
    return Method.PUT("/region/edit", data);
  },

  deleteTerminalArea(terminalAreaId) {
    return Method.DELETE(`region/delete/${terminalAreaId}`);
  },
  // ********** STATE LISTING **********
  async getStateList(search, offset, limit, sortBy, order) {
    search = await searchFilter(search);
    let url = `state/list`;
    if (offset !== null) {
      url += `?search=${search}&offset=${offset}&limit=${limit}&sort_by=${sortBy}`;
    }
    if (order) {
      url += `&order=${order}`;
    }
    return Method.GET(url);
  },

  addList(stateName, shortName, timezone) {
    let data = JSON.stringify({
      state_name: stateName,
      short_name: shortName,
      timezone: timezone
    });
    return Method.POST("state/add", data);
  },

  getStateDetails(stateId) {
    return Method.GET(`/state/details/${stateId}`);
  },

  editStateListing(stateId, stateName, shortName, timezone) {
    let data = JSON.stringify({
      state_id: stateId,
      state_name: stateName,
      short_name: shortName,
      timezone: timezone
    });
    return Method.PUT("/state/edit", data);
  },

  deleteStateList(stateId) {
    return Method.DELETE(`state/delete/${stateId}`);
  },
  exportListFile(fileType) {
    return Method.GETDATALIST("state/export", "state");
  },
  importListFile(file, fileType) {
    let formData = new FormData();
    formData.append("excelFile", file);
    return Method.POST("state/import", formData);
  },

  //get timezone list
  getTimezoneList() {
    return Method.GET("timezone/list");
  },

  // ********** REPORT POST **********
  async getReportPostList(search, offset, limit, sortBy, order) {
    search = await searchFilter(search);
    let url = `post/listReported?search=${search}&offset=${offset}&limit=${limit}`;
    if (order) {
      url += `&order=${order}&sort_by=${sortBy}`;
    }
    return Method.GET(url);
  },

  getReportPostDetails(reportId) {
    return Method.GET(`post/reportDetails/${reportId}`);
  },

  revokeReportPost(reportPostId) {
    return Method.DELETE(`post/revoke/${reportPostId}`);
  },

  deleteReportPost(postId) {
    return Method.DELETE(`post/delete/${postId}`);
  },

  // ********** TERMINAL REPORT POST **********
  async getTerminalReportPostList(
    search,
    offset,
    limit,
    sortBy,
    order,
    terminalId
  ) {
    search = await searchFilter(search);
    let url = `post/list?search=${search}&offset=${offset}&limit=${limit}`;
    if (order) {
      url += `&order=${order}&sort_by=${sortBy}`;
    }
    url += `&terminal_id=${terminalId}`;
    return Method.GET(url);
  },

  getTerminalReportPostDetails(postId) {
    return Method.GET(`post/details/${postId}`);
  },

  deleteTerminalReportPost(postId) {
    return Method.DELETE(`post/delete/${postId}`);
  },

  // ********** PUSH NOTIFICATIONS **********

  postPushNotification(title, description,radioButton, users, terminal_id, region_id, selectedUser, notificationType, selectedOpenTime) {
console.log(selectedOpenTime,"selectedOpenTime")
    let data
    if (terminal_id == null) {
      data = JSON.stringify({
        title: title,
        description: description,
        type:radioButton,
        users: users,
        region_id: region_id,
        notifyType: notificationType,
        date: selectedOpenTime
      });
    }
    if (region_id == null) {
      console.log(selectedOpenTime,"selectedOpenTime")
      data = JSON.stringify({
        title: title,
        description: description,
        type:radioButton,
        users: users,
        terminal_id: terminal_id,
        notifyType: notificationType,
        date: selectedOpenTime
      });
    }
    if (terminal_id == null && region_id == null) {
      console.log(selectedOpenTime,"selectedOpenTime")
      data = JSON.stringify({
        title: title,
        description: description,
        type:radioButton,
        users: users,
        userList: selectedUser,
        notifyType: notificationType,
        date: selectedOpenTime
      });
    }
    return Method.POST("notification/create", data)
  },

  editPushNotification(notificationId,title, description,radioButton, users, terminal_id, region_id, selectedUser, notificationType, selectedOpenTime) {
    let data
    if (terminal_id == null) {
      data = JSON.stringify({
        notificationId:notificationId,
        title: title,
        description: description,
        type:radioButton,
        users: users,
        region_id: region_id,
        notifyType: notificationType,
        date: selectedOpenTime
      });
    }
    if (region_id == null) {
      data = JSON.stringify({
        notificationId:notificationId,
        title: title,
        description: description,
        type:radioButton,
        users: users,
        terminal_id: terminal_id,
        notifyType: notificationType,
        date: selectedOpenTime
      });
    }
    if (terminal_id == null && region_id == null) {
      data = JSON.stringify({
        notificationId:notificationId,
        title: title,
        description: description,
        type:radioButton,
        users: users,
        userList: selectedUser,
        notifyType: notificationType,
        date: selectedOpenTime
      });
    }
    return Method.POST("notification/edit", data)
  },

  async getNotificationList(payload) {
    // search = await searchFilter(search);
    let url = `notification/notificationList`;
    let body = JSON.stringify({
      "search": payload.search,
      "offset": payload.offset,
      "limit": payload.limit,
      "sortBy": payload.sortBy,
      "order": payload.order,
      "date": payload.date ? payload.date : null
    })

    return Method.POST(url, body);
  },

  getPushNotificationsDetails(id) {
    return Method.GET(`notification/detail/${id}`);
  },

  // ********** SAVED USER LOCATIONS **********
  async savedUserLocations(search, offset, limit, sortBy, order, userId) {
    // let url = `location/save?offset=${offset}&limit=${limit}`
    search = await searchFilter(search);
    let url = `location/save?&search=${search}&offset=${offset}&limit=${limit}`;
    if (order) {
      url += `&order=${order}&sort_by=${sortBy}`;
    }
    url += `&user_id=${userId}`;
    return Method.GET(url);
  },

  // ********** VIEW TIMELINE **********

  async viewTimelineFetch(payload) {
    // search = await searchFilter(search);
    let url = `route_post/listAdmin`;
    let body = JSON.stringify({
      "search": payload.search,
      "offset": payload.offset,
      "limit": payload.limit,
      "sortBy": payload.sortBy,
      "order": payload.order,
      "userId": payload.userId,
      "date": payload.date ? payload.date : null
    })
    return Method.POST(url, body);
  },

  getTimelineDetailsFetch(userId) {
    return Method.GET(`route_post/detail/${userId}`);
  },

  // ********** Image Management POST **********
  async getImageList(search, offset, limit, sortBy, order) {
    search = await searchFilter(search);
    let url = `image/list?search=${search}&offset=${offset}&limit=${limit}`;
    if (order) {
      url += `&order=${order}&sort_by=${sortBy}`;
    }
    return Method.GET(url);
  },

  async getNewsList(search, offset, limit, sortBy, order,terminalId) {
    search = await searchFilter(search);
    let url = `news/list`;
    if (offset !== null) {
      url += `?search=${search}&offset=${offset}&limit=${limit}&sort_by=${sortBy}`;
    }
    if (order) {
      url += `&order=${order}`;
    }
    if(terminalId){
      url += `?region_id=${terminalId}`;
    }
    return Method.GET(url);
  },

  addNews(region, image, description, heading) {
    let url = `news/create`;
    let formData = new FormData();
    // formData.append("media", image);
    formData.append("region", region);
    formData.append("description", description);
    formData.append("title", heading);
    return Method.POST(url, formData);
  },
  
  editNews(region, image, description, heading, id) {
    let url = `news/edit`;
    let formData = new FormData();
    // formData.append("media", image);
    formData.append("region", region);
    formData.append("description", description);
    formData.append("title", heading);
    formData.append("news_id", id);
    return Method.PUT(url, formData);
  },
  deleteNews(id) {
    return Method.DELETE(`news/delete/${id}`);
  },

  deleteImageFromEditor(key) {
    console.log(key,"++++++fetch")
    let url = `user/deleteFile`;
    let body = JSON.stringify({
      "key": key,
    })
    return Method.POST(url, body);
  },
  addImage(image, imageName) {
    let url = `image/add`;
    let formData = new FormData();
    formData.append("image_name", imageName);
    formData.append("image", image);
    return Method.POST(url, formData);
  },

  deleteImage(imageId) {
    return Method.DELETE(`image/delete/${imageId}`);
  },

  timelineDetails(id, postType) {
    let url = `timeline/details/${id}?type=${postType}`;
    return Method.GET(url);
  },
  // Get radar entry/exit list
  async getRadarEntryExitList(
    search,
    offset,
    limit,
    sortBy,
    order,
    userIdFilterArray,
    terminalIdFilterArray,
    selectedOpenTime,
    selectedCloseTime
  ) {
    search = await searchFilter(search);
    let url = `location/list?&search=${search}&offset=${offset}&limit=${limit}`;
    if (order) {
      url += `&order=${order}&sort_by=${sortBy}`;
    }
    if (userIdFilterArray?.length > 0) {
      url += `&userIdArray=${JSON.stringify(userIdFilterArray)}`;
    }
    if (terminalIdFilterArray?.length > 0) {
      url += `&terminalIdArray=${JSON.stringify(terminalIdFilterArray)}`;
    }
    if (selectedOpenTime) {
      url += `&startDate=${selectedOpenTime}`;
    }
    if (selectedCloseTime) {
      url += `&endDate=${selectedCloseTime}`;
    }
    // if (terminalIdArray) {
    // url += `&userIdArray=${JSON.stringify(["60d03bcb5dfbd16a6e51cd4d"])}`;
    // }
    // url += `&user_id=${userId}`;
    return Method.GET(url);
  },
  // Get radar export list
  getRadarExportList() {
    // let url = `user/list?search=${search}&offset=${offset}&limit=${limit}`
    // if(order != null){
    //   url +=`&order=${order}&sort_by=${sortBy}`
    // }
    //   return Method.GET(url)
  },

  // ********** Dashboard **********
  dashboardData(payload) {
    let url = `/user/dashboard`;

    let formData = new FormData();
    // formData.append("date", payload ? payload.date : null)
    let body = JSON.stringify({
      "date": payload ? payload.date : null
    })
    return Method.POST(url, body);
  },

  analyticsData(payload) {
    let url = `/graph/general`;
    let body = JSON.stringify({
      "type": payload.type,
      "month": payload.month,
      "year": payload.year
    })
    return Method.POST(url, body);
  },

  regionAnalyticsData(payload) {
    let url = `/graph/region`;
    let body = JSON.stringify({
      "regionId": payload.id,
      "type": payload.type,
      "month": payload.month,
      "year": payload.year
    })
    return Method.POST(url, payload);
  },

  terminalAnalyticsData(payload) {
    let url = `/graph/terminal`;
    let body = JSON.stringify({
      "terminalId": payload.id,
      "type": payload.type,
      "month": payload.month,
      "year": payload.year
    })
    return Method.POST(url, payload);
  },

  regionTerminalData(payload) {
    let body = JSON.stringify(
      {
        "regionId": payload
      }
    )
    let url = `/terminal/regionBasedList`;
    return Method.POST(url, payload);
  },

  // ********** EMPLOYEE MANAGEMENT **********
  async getEmployeeList(payload) {
    let url = `/employee/list`;
    let body = JSON.stringify({
      "search": payload.search,
      "offset": payload.offset,
      "limit": payload.limit,
      "sortBy": payload.sortBy,
      "order": payload.order,
      "userId": payload.userId,
      "startDate": payload.startDate ? payload.startDate : null,
      "endDate": payload.endDate ? payload.endDate : null,
      "roleId": payload.role ? payload.role : null
    })
    if (payload.role) {
      body = JSON.stringify({
        "search": payload.search,
        "offset": payload.offset,
        "limit": payload.limit,
        "sortBy": payload.sortBy,
        "order": payload.order,
        "userId": payload.userId,
        "startDate": payload.startDate ? payload.startDate : null,
        "endDate": payload.endDate ? payload.endDate : null,
        "roleId": payload.role ? payload.role : null
      })
    }
    else {
      body = JSON.stringify({
        "search": payload.search,
        "offset": payload.offset,
        "limit": payload.limit,
        "sortBy": payload.sortBy,
        "order": payload.order,
        "userId": payload.userId,
        "startDate": payload.startDate ? payload.startDate : null,
        "endDate": payload.endDate ? payload.endDate : null,
      })
    }

    return Method.POST(url, body);
  },
  roleList() {
    let url = `role/list`;
    return Method.GET(url);
  },
  addEmployeeList(firstName, lastName, email, phone_number, profile, role, permissions) {

    let formData = new FormData();
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("email", email);
    formData.append("phone_number", phone_number);
    formData.append("profile", profile);
    formData.append("role", role);
    formData.append("permissions", JSON.stringify(permissions));
    let data = JSON.stringify({
      firstName, lastName, email, phone_number, profile, role, permissions
    });
    return Method.POST("employee/add", formData);
  },
  getEmployeeDetails(id) {
    return Method.GET(`/employee/details/${id}`);
  },
  genearatePasswordApi(id) {
    return Method.GET(`/employee/regeneratePassword/${id}`);
  },

  editEmployeeList(userId, firstName, lastName, email, phone_number, profile, role, permissions) {

    let formData = new FormData();
    formData.append("userId", userId);
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("email", email);
    formData.append("phone_number", phone_number);
    formData.append("profile", profile);
    formData.append("role", role);
    formData.append("permissions", JSON.stringify(permissions));
    return Method.PUT("employee/edit", formData);
  },
  async getEventList(payload) {
    let url = `/activity/list`;
    let body = JSON.stringify({
      "search": payload.search,
      "offset": payload.offset,
      "limit": payload.limit,
      "sortBy": payload.sortBy,
      "order": payload.order,
    })
    return Method.POST(url, body);
  },

  // ********** POST MANAGEMENT **********

  async getPostList(payload) {
    let url = `/timeline/postList`;
    let body = JSON.stringify({
      "search": payload.search,
      "offset": payload.offset,
      "limit": payload.limit,
      "sortBy": payload.sortBy,
      "order": payload.order,
      "type": payload.value
    })
    return Method.POST(url, body);
  },

  getBotUsersList(payload) {
    let url = `/user/botUsers`;
    return Method.POST(url, payload);
  },

  addPost(payload) {

    let formData = new FormData();
    formData.append("userId", payload.selectUser);
    formData.append("description", payload.description);
    if (payload.image.length > 0) {
      let arr = [...payload.image];
      arr.map((i, k) => {
        formData.append("image", i.file, i.file.name);
      })
    }
    if (payload.video) {
      formData.append("video", payload.video);
    }
    if (payload.tagged_friends.length > 0) {
      formData.append("tag_friends", JSON.stringify(payload.tagged_friends));
    }
    if (payload.post_location) {
      formData.append("post_location", payload.post_location);
    }
    return Method.POST("timeline/create", formData);
  },
  getUsersList(userId, type) {

    let url = userId ? `/user/list?userId=${userId}&type=${type}` : `/user/list`;
    return Method.GET(url);
  },

  getPostDetails(payload) {

    let body = JSON.stringify({
      "post_id": payload.post_id,
      "user_id": payload.user_id,
    })
    return Method.POST(`/timeline/postDetail`, body);
  },
  addBotUser(payload) {

    let formData = new FormData();
    formData.append("userName", payload.userName);
    formData.append("name", payload.firstName);
    // formData.append("lastName", payload.lastName);
    formData.append("image", payload.imageUrl);
    // formData.append("video", payload.video);
    return Method.POST("user/addUser", formData);
  },
  deletePostList(postId) {

    return Method.DELETE(`timeline/delete/${postId}`);
  },
  deleteCommentList(commentId) {
    return Method.DELETE(`timeline/deleteComment/${commentId}`);
  },

  sharePost(payload) {
    let body = JSON.stringify({
      "post_id": payload.post_id,
      "description": payload.description,
      "userId": payload.userId,
    })
    return Method.POST(`timeline/share`, body);
  },

  likePost(payload) {

    let body = JSON.stringify({
      "post_id": payload.postId,
      "type": payload.type,
      "userId": payload.userId,
      "is_like": payload.is_like
    })
    return Method.POST(`timeline/like`, body);
  },

  commentOnPost(payload) {

    let formData = new FormData();
    formData.append("post_id", payload.post_id);
    formData.append("description", payload.description);
    formData.append("userId", payload.user_id);
    if (payload.commentId) {
      formData.append("commentId", payload.commentId);
    }
    return Method.POST(`timeline/comment`, formData);
  },
  likeComment(payload) {

    let body = JSON.stringify({
      "post_id": payload.post_id,
      "type": payload.likeType,
      "userId": payload.userId,
      "comment_id": payload.commentId,
      "is_like": payload.is_like,
    })
    return Method.POST(`timeline/commentLike`, body);
  },

  editComment(payload) {
    let formData = new FormData();
    formData.append("description", payload.description);
    formData.append("post_id", payload.post_id);
    formData.append("commentId", payload.commentId);
    return Method.PUT("timeline/editComment", formData);
  },
  //Edit Post
  editPost(payload) {

    let formData = new FormData();
    if (payload.shared) {
      formData.append("share_description", payload.shared);
    }
    formData.append("post_id", payload.post_id);
    formData.append("description", payload.description);
    formData.append("type", payload.type);
    formData.append("deleteImages", JSON.stringify(payload.deletedImages));
    if (payload.image.length > 0) {
      let arr = [...payload.image];
      arr.map((i, k) => {
        formData.append("image", i.file, i.file.name);
      })
    }
    if (payload.video) {
      formData.append("video", payload.video);
    }
    if (payload.tagged_friends.length > 0) {
      formData.append("tag_friends", JSON.stringify(payload.tagged_friends));
    }
    if (payload.post_location) {
      formData.append("post_location", payload.post_location);
    }
    return Method.PUT("timeline/edit", formData);
  },
  //Delete Post Image
  deletePostImage(payload) {

    return Method.POST("timeline/deleteMedia", payload);
  },

  // ********** TERMINAL BOARD **********
  editTerminalBoard(payload) {
    console.log(payload.graphColor,"payload.graphColor")
    let url = `region/updateTheme`;
    let formData = new FormData();
    formData.append("regionId", payload.regionId);
    formData.append("cardBackground",JSON.stringify(payload.cardBackground));
    formData.append("fontFamily", payload.fontFamily);
    formData.append("fontColor", payload.fontColor);
    formData.append("graphColor", JSON.stringify(payload.graphColor));
    formData.append("regionPickerColor", payload.regionPickerColor);
    if(payload.backgroundType=="image" || payload.backgroundType=="video"){
      formData.append("background", payload.background);
    }
    formData.append("cardBackgroundType", payload.cardBackgroundType);
    formData.append("weatherColor", payload.weatherColor);
    if(payload.backgroundType=="color"){
      if(payload.typeOfBackgroundColor=="singleColor"){
        formData.append("backgroundType", "singleColor");
        formData.append("backgroundColor", JSON.stringify(payload.backgroundColor));
      }
      else{
        formData.append("backgroundType", "linearGradient");
        formData.append("backgroundColor", JSON.stringify(payload.backgroundColor));
      }
    }
    else{
      formData.append("backgroundType", payload.backgroundType);
    }
    return Method.POST(url, formData);
  },

  applyDefaultTheme(payload){
    let url='region/applyTheme'
    let body = {
      regions: payload.regions,
    }
    return Method.POST(url,body)
  },

  editDefaultTheme(payload) {
    let url = `region/updateDefaultTheme`;
    let formData = new FormData();
    formData.append("cardBackground",JSON.stringify(payload.cardBackground));
    formData.append("fontFamily", payload.fontFamily);
    formData.append("fontColor", payload.fontColor);
    formData.append("graphColor", JSON.stringify(payload.graphColor));
    formData.append("regionPickerColor", payload.regionPickerColor);
    if(payload.backgroundType=="image" || payload.backgroundType=="video"){
      formData.append("background", payload.background);
    }
    formData.append("cardBackgroundType", payload.cardBackgroundType);
    formData.append("weatherColor", payload.weatherColor);
    if(payload.backgroundType=="color"){
      if(payload.typeOfBackgroundColor=="singleColor"){
        formData.append("backgroundType", "singleColor");
        formData.append("backgroundColor", JSON.stringify(payload.backgroundColor));
      }
      else{
        formData.append("backgroundType", "linearGradient");
        formData.append("backgroundColor", JSON.stringify(payload.backgroundColor));
      }
    }
    else{
      formData.append("backgroundType", payload.backgroundType);
    }
    return Method.POST(url, formData);
  },

  defaultThemeDetails(){
    let url='region/defaultThemeDetails'
    return Method.GET(url)
  },

  getTerminalWaitTime(terminalId) {
    return Method.GET(`terminal/manualAverageConfig/${terminalId}`);
  },

  updateTerminalWaitTime(payload) {
    let data = JSON.stringify(payload);
    return Method.PUT("terminal/manualAverageConfigUpdate", data);
  },

};

const searchFilter = (search) => {
  const t = search;
  const g = t.includes("#");

  if (g) {
    return search.replaceAll("#", "!");
  } else {
    return search;
  }
};

const Method = {
  // Get Method
  async GET(url) {
    cl("input values in GET Method", url);
    return await new Promise((resolve, reject) => {
      http
        .get(url, {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",

          },
        })
        .then((result) => {
          if (result.status === 200) {
            cl("result inside 200", result);
            return resolve({
              status: 1,
              result: result,
            });
          } else {
            if (result) {
              return reject({
                status: 3,
                error: result.data.message,
              });
            } else {
              return reject({
                status: 5,
                error: appMessages.messageStatus500,
              });
            }
          }
        })
        .catch((err) => {
          cl("error inside get", err);
          if (err.response) {
            if (
              err.response.status !== null &&
              err.response.status !== undefined
            ) {
              if (err.response.status == 500) {
                return reject({
                  status: 2,
                  error: err,
                });
              } else if (err.response.status == 403) {
                logout();
                return reject({
                  status: 4,
                  error: err?.response?.data?.message,
                });
              } else if (
                err.response.status == 400 ||
                err.response.status == 401 ||
                err.response.status == 404
              ) {
                return reject({
                  status: 4,
                  error: err?.response?.data?.message,
                });
              } else {
                return reject({
                  status: 8,
                  error: err,
                });
              }
            }
          } else {
            return reject({
              status: 6,
              error: appMessages.messageStatus500,
            });
          }
        });
    });
  },

  // Post Method
  async POST(url, body) {
    cl("input values in POST Method", url, body);
    return await new Promise((resolve, reject) => {
      http
        .post(url, body, {
          headers: {
            "access-control-allow-origin": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((result) => {
          if (result.status === 200) {
            cl("result inside 200", result);
            return resolve({
              status: 1,
              result: result,
            });
          } else {
            if (result) {
              return reject({
                status: 3,
                error: result.data.message,
              });
            } else {
              return reject({
                status: 5,
                error: appMessages.messageStatus500,
              });
            }
          }
        })
        .catch((err) => {
          cl("error inside post", err);
          if (err.response) {
            if (
              err.response.status !== null &&
              err.response.status !== undefined
            ) {
              if (err.response.status == 500) {
                return reject({
                  status: 2,
                  error: err,
                });
              } else if (err.response.status == 403) {
                logout();
                return reject({
                  status: 4,
                  error: err?.response?.data?.message,
                });
              } else if (
                err.response.status == 400 ||
                err.response.status == 401 ||
                err.response.status == 404
              ) {
                return reject({
                  status: 4,
                  error: err?.response?.data?.message,
                });
              } else {
                return reject({
                  status: 8,
                  error: err,
                });
              }
            }
          } else {
            return reject({
              status: 6,
              error: appMessages.messageStatus500,
            });
          }
        });
    });
  },


  // Put Method
  async PUT(url, body) {
    cl(" input values in PUT Method", url, body);
    return await new Promise((resolve, reject) => {
      http
        .put(url, body, {
          headers: {
            "access-control-allow-origin": "*",
            "Content-Type": "application/json",
            Accept: "application/json",

          },
        })
        .then((result) => {
          if (result.status === 200) {
            cl("result inside 200", result);
            return resolve({
              status: 1,
              result: result,
            });
          } else {
            if (result) {
              return reject({
                status: 3,
                error: result.data.message,
              });
            } else {
              return reject({
                status: 5,
                error: appMessages.messageStatus500,
              });
            }
          }
        })
        .catch((err) => {
          cl("error inside put", err);
          if (err.response) {
            if (
              err.response.status !== null &&
              err.response.status !== undefined
            ) {
              if (err.response.status == 500) {
                return reject({
                  status: 2,
                  error: err?.response?.data?.error?.message,
                });
              } else if (err.response.status == 403) {
                logout();
                return reject({
                  status: 4,
                  error: err?.response?.data?.message,
                });
              } else if (
                err.response.status == 400 ||
                err.response.status == 401 ||
                err.response.status == 404
              ) {

                return resolve({
                  status: 4,
                  error: err?.response?.data?.message,
                  result: err?.response?.data?.data
                });
              } else {
                return reject({
                  status: 8,
                  error: err,
                });
              }
            }
          } else {
            return reject({
              status: 6,
              error: appMessages.messageStatus500,
            });
          }
        });
    });
  },

  // Delete Method
  async DELETE(url) {
    return await new Promise((resolve, reject) => {
      http
        .delete(url, {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        })

        .then((result) => {
          if (result.status === 200) {
            return resolve({
              status: 1,
              result: result,
            });
          }
          // else if (result.status == 212) {
          //   return resolve({
          //     status: 4,
          //     result: result
          //   });
          // }
          else {
            if (result) {
              return reject({
                status: 3,
                error: result.data.message,
              });
            } else {
              return reject({
                status: 5,
                error: appMessages.messageStatus500,
              });
            }
          }
        })
        .catch((err) => {
          cl("error inside delete", err);
          if (err.response) {
            if (
              err.response.status !== null &&
              err.response.status !== undefined
            ) {
              if (err.response.status == 500) {
                return reject({
                  status: 2,
                  error: err,
                });
              } else if (err.response.status == 403) {
                logout();
                return reject({
                  status: 4,
                  error: err?.response?.data?.message,
                });
              } else if (
                err.response.status == 400 ||
                err.response.status == 401 ||
                err.response.status == 404
              ) {
                return reject({
                  status: 4,
                  error: err?.response?.data?.message,
                });
              } else {
                return reject({
                  status: 8,
                  error: err,
                });
              }
            }
          } else {
            return reject({
              status: 6,
              error: appMessages.messageStatus500,
            });
          }
        });
    });
  },

  GETDATA(url, fileName) {
    return new Promise((resolve, reject) => {
      http
        .get(url, {
          // responseType: 'arraybuffer',
          headers: {
            "Content-Type": "application/json",
            Accept: "application/csv",
          },
        })
        .then((result) => {
          if (result.status === 200) {
            const terminalAreaHeader = [
              "State ID",
              "Region ID",
              "Region Name",
            ];
            const terminalHeader = [
              "Terminal ID",
              "Region ID",
              "Radar External ID",
              "Terminal Image Name",
              "Terminal Name",
              "Terminal Location",
              "Terminal Category",
              "Terminal Country",
              "Terminal City",
              "State/Province Name",
              "Zip Code",
              "Latitude",
              "Longitude",
              "Radius",
              "Open Time",
              "Close Time",
              "24 Hours Availability",
              "Map Logo Name",
              "Terminal Board Logo Name",
              "Terminal Description",
              "Terminal Polygon Area",
              "Phone Number",
              "Show Terminal",
              "About Us",
              "Rating",
              "Extra Time"
            ];
            let header =
              fileName === "Terminal Area"
                ? terminalAreaHeader
                : terminalHeader;

            const csvFromArrayOfObjects = convertArrayToCSV(result.data, {
              header,
            });
            // CsvDataService.exportToCsv(`${fileName}.csv`, result.data)
            const url = window.URL.createObjectURL(
              new Blob([csvFromArrayOfObjects])
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", (fileName === "Terminal Area" ? "Region" : fileName) + ".csv"); //or any other extension
            document.body.appendChild(link);
            link.click();
            // const url = window.URL.createObjectURL(new Blob([result.data]));
            //   const link = document.createElement('a');
            //   link.href = url;
            //   link.setAttribute('download', fileName + '.csv'); //or any other extension
            //   document.body.appendChild(link);
            //   link.click();
            return resolve({
              status: 1,
              result: result,
            });
          } else {
            if (result) {
              return reject({
                status: 3,
                error: result.data.message,
              });
            } else {
              return reject({
                status: 4,
                error: "Something went wrong.",
              });
            }
          }
        })
        .catch((err) => {
          cl("error inside getdata", err);
          if (err.response) {
            if (
              err.response.status !== null &&
              err.response.status !== undefined
            ) {
              if (err.response.status == 500) {
                return reject({
                  status: 2,
                  error: err,
                });
              } else if (err.response.status == 403) {
                logout();
                return reject({
                  status: 4,
                  error: err?.response?.data?.message,
                });
              } else if (
                err.response.status == 400 ||
                err.response.status == 401 ||
                err.response.status == 404
              ) {
                return reject({
                  status: 4,
                  error: err?.response?.data?.message,
                });
              } else {
                return reject({
                  status: 8,
                  error: err,
                });
              }
            }
          } else {
            return reject({
              status: 6,
              error: appMessages.messageStatus500,
            });
          }
        });
    });
  },
  GETDATALIST(url, fileName) {
    return new Promise((resolve, reject) => {
      http
        .get(url, {
          // responseType: 'arraybuffer',
          headers: {
            "Content-Type": "application/json",
            Accept: "application/csv",
          },
        })
        .then((result) => {
          if (result.status === 200) {
            const stateHeader = [
              "State ID",
              "State Name",
              "Short Name",
              "Timezone"
            ];
            let header = stateHeader

            const csvFromArrayOfObjects = convertArrayToCSV(result.data, {
              header,
            });

            const url = window.URL.createObjectURL(
              new Blob([csvFromArrayOfObjects])
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName + ".csv"); //or any other extension
            document.body.appendChild(link);
            link.click();

            return resolve({
              status: 1,
              result: result,
            });
          } else {
            if (result) {
              return reject({
                status: 3,
                error: result.data.message,
              });
            } else {
              return reject({
                status: 4,
                error: "Something went wrong.",
              });
            }
          }
        })
        .catch((err) => {
          cl("error inside getdata", err);
          if (err.response) {
            if (
              err.response.status !== null &&
              err.response.status !== undefined
            ) {
              if (err.response.status == 500) {
                return reject({
                  status: 2,
                  error: err,
                });
              } else if (err.response.status == 403) {
                logout();
                return reject({
                  status: 4,
                  error: err?.response?.data?.message,
                });
              } else if (
                err.response.status == 400 ||
                err.response.status == 401 ||
                err.response.status == 404
              ) {
                return reject({
                  status: 4,
                  error: err?.response?.data?.message,
                });
              } else {
                return reject({
                  status: 8,
                  error: err,
                });
              }
            }
          } else {
            return reject({
              status: 6,
              error: appMessages.messageStatus500,
            });
          }
        });
    });
  },

  GETDATAUSER(url, fileName) {
    return new Promise((resolve, reject) => {
      http
        .get(url, {
          // responseType: 'arraybuffer',
          headers: {
            "Content-Type": "application/json",
            Accept: "application/csv",
          },
        })
        .then((result) => {
          if (result.status === 200) {
            const userHeader = [
              // "First Name",
              // "Last Name",
              "Username",
              "Bot User",
              "Email Address",
              "Report Count",
              "Phone Number",
              "User Type",
              "App Version",
              "Device Type",
              "Created At",
              "User Status"
            ];
            let header = userHeader
            const csvFromArrayOfObjects = convertArrayToCSV(result.data, {
              header,
            });

            const url = window.URL.createObjectURL(
              new Blob([csvFromArrayOfObjects])
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName + ".csv");
            document.body.appendChild(link);
            link.click();

            return resolve({
              status: 1,
              result: result,
            });
          } else {
            if (result) {
              return reject({
                status: 3,
                error: result.data.message,
              });
            } else {
              return reject({
                status: 4,
                error: "Something went wrong.",
              });
            }
          }
        })
        .catch((err) => {
          cl("error inside getdata", err);
          if (err.response) {
            if (
              err.response.status !== null &&
              err.response.status !== undefined
            ) {
              if (err.response.status == 500) {
                return reject({
                  status: 2,
                  error: err,
                });
              } else if (err.response.status == 403) {
                logout();
                return reject({
                  status: 4,
                  error: err?.response?.data?.message,
                });
              } else if (
                err.response.status == 400 ||
                err.response.status == 401 ||
                err.response.status == 404
              ) {
                return reject({
                  status: 4,
                  error: err?.response?.data?.message,
                });
              } else {
                return reject({
                  status: 8,
                  error: err,
                });
              }
            }
          } else {
            return reject({
              status: 6,
              error: appMessages.messageStatus500,
            });
          }
        });
    });
  },

  GETREPORTEDDATAUSER(url, fileName) {
    return new Promise((resolve, reject) => {
      http
        .get(url, {
          // responseType: 'arraybuffer',
          headers: {
            "Content-Type": "application/json",
            Accept: "application/csv",
          },
        })
        .then((result) => {
          if (result.status === 200) {
            const userHeader = [
              "User Reported",
              "Reported By",
              "Reported Date",
              "Reason Of Report",
            ];
            let header = userHeader
            const csvFromArrayOfObjects = convertArrayToCSV(result.data, {
              header,
            });

            const url = window.URL.createObjectURL(
              new Blob([csvFromArrayOfObjects])
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName + ".csv");
            document.body.appendChild(link);
            link.click();

            return resolve({
              status: 1,
              result: result,
            });
          } else {
            if (result) {
              return reject({
                status: 3,
                error: result.data.message,
              });
            } else {
              return reject({
                status: 4,
                error: "Something went wrong.",
              });
            }
          }
        })
        .catch((err) => {
          cl("error inside getdata", err);
          if (err.response) {
            if (
              err.response.status !== null &&
              err.response.status !== undefined
            ) {
              if (err.response.status == 500) {
                return reject({
                  status: 2,
                  error: err,
                });
              } else if (err.response.status == 403) {
                logout();
                return reject({
                  status: 4,
                  error: err?.response?.data?.message,
                });
              } else if (
                err.response.status == 400 ||
                err.response.status == 401 ||
                err.response.status == 404
              ) {
                return reject({
                  status: 4,
                  error: err?.response?.data?.message,
                });
              } else {
                return reject({
                  status: 8,
                  error: err,
                });
              }
            }
          } else {
            return reject({
              status: 6,
              error: appMessages.messageStatus500,
            });
          }
        });
    });
  },
};

