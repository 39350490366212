import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useStyles } from './styles'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  root: {
    margin: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1.4),
    paddingBottom: theme.spacing(1.4),
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1.4),
    paddingBottom: theme.spacing(1.4),
  },
}))(MuiDialogActions);

export default function ConfirmationModal(props) {
  const classes = useStyles()
  const { visible, onCancel, onDeleteConfirmed, title, text, button1Text, button2Text } = props

  return (
    <div>
      <Dialog onClose={onCancel} aria-labelledby="customized-dialog-title" open={visible} style={{ display: "flex", justifyContent: "center" }}>
        <DialogTitle id="customized-dialog-title" onClose={onCancel}>
          {title}
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText className={classes.confirmationText} >
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onCancel} variant="outlined" color="primary" size="small">
            {button2Text ? button2Text : 'Cancel'}
          </Button>
          <Button onClick={onDeleteConfirmed} disabled={props.disable} variant="contained" color="primary" size="small">
            {button1Text ? button1Text : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}