import { apiConstants as types } from '../../themes/constants'

// dashboard action
export const DashboardDataAction = (payload) => {
    return {
        type: types.API_DASHBOARD_LOAD,
        payload
    }
}

// show general data
export const AnalyticsDataAction = (payload) => {
    return {
        type: types.API_ANALYTICS_LOAD,
        payload
    }
}

// show region data
export const AnalyticsRegionDataAction = (payload) => {
    return {
        type: types.API_ANALYTICS_REGION_LOAD,
        payload
    }
}

// update region data
export const updateAnalyticsRegionDataAction = (payload) => {
    return {
        type: types.API_UPDATE_ANALYTICS_REGION_LOAD,
        payload
    }
}

// show terminal data
export const AnalyticsTerminalDataAction = (payload) => {
    return {
        type: types.API_ANALYTICS_TERMINAL_LOAD,
        payload
    }
}

// update terminal data
export const updateAnalyticsTerminalDataAction = (payload) => {
    return {
        type: types.API_UPDATE_ANALYTICS_TERMINAL_LOAD,
        payload
    }
}

// region-terminal data
export const regionTerminalDataAction = (payload) => ({
    type: types.API_REGION_TERMINAL_LOAD,
    payload
})

// region-terminal data show
export const regionTerminalDataShowAction = (payload) => ({
    type: types.API_REGION_TERMINAL_SHOW_LOAD,
    payload
})

// empty region-terminal data
export const emptyregionTerminalData = () => ({
    type: types.EMPTY_REGION_TERMINAL_DATA_LOAD
})