import { takeLatest, call, put } from 'redux-saga/effects'
import { message } from 'antd'
import { apiConstants as types, appMessages } from '../../themes/constants'
import axios from '../axios'
import cl from '../../utils/cl'
import history from '../../utils/history'
import { toast } from 'react-toastify';

function* employeeFailedSaga(result) {

    yield put({
        type: types.API_EMPLOYEE_LIST_MANAGEMENT_FAILED,
        errorState: result?.error,
    })
    toast.error(result?.error)
}

function* employeeErrorSaga(result) {
    console.log(result)
    yield put({
        type: types.API_EMPLOYEE_LIST_MANAGEMENT_ERROR,
        errorState: result?.error,
    })
    if (result.status === 2) {
        toast.error(appMessages.messageStatus500)
    } else {
        toast.error(result?.error)
    }
}


function* getEmployeeListSaga(action) {
    try {
        const result = yield call(axios.getEmployeeList, action.payload)
        if (result.status === 1) {
            yield put({
                type: types.API_GET_EMPLOYEE_LIST_SUCCESS,
                result: result.result.data.data,
            })
        }
        else {
            yield call(employeeFailedSaga, result)
        }
    }
    catch (error) {
        yield call(employeeErrorSaga, error)
    }
}

function* getRolesListSaga(action) {
    try {
        const result = yield call(axios.roleList)
        console.log(action, "action", result)
        if (result.status === 1) {
            cl('result inside get terminal area list saga', result)
            yield put({
                type: types.API_GET_ROLES_LIST_SUCCESS,
                result: result.result.data.data,
            })
        }
        else {
            yield call(employeeFailedSaga, result)
        }
    }
    catch (error) {
        yield call(employeeErrorSaga, error)
    }
}

function* addEmployeeSaga(action) {
    const { firstName, lastName, email, phone_number, profile, role, permissions } = action
    try {
        const result = yield call(axios.addEmployeeList, firstName, lastName, email, phone_number, profile, role, permissions)
        console.log(action, "action", result)
        if (result.status === 1) {
            cl('result inside get terminal area list saga', result)
            yield put({
                type: types.API_ADD_EMPLOYEE_LIST_SUCCESS,
                result: result.result.data.data,
            })
            toast.success(result?.result?.data?.message)
            history.push('/employee')
        }
        else {
            yield call(employeeFailedSaga, result)
        }
    }
    catch (error) {
        yield call(employeeErrorSaga, error)
    }
}

function* getEmployeeDetailsSaga(action) {
    const { id } = action;
    try {
        const result = yield call(axios.getEmployeeDetails, id)
        if (result.status === 1) {
            cl('result inside get terminal Details saga', result)
            yield put({
                type: types.API_EMPLOYEE_LIST_DETAILS_SUCCESS,
                result: result.result.data.data,
            })
        }
        else {
            yield call(employeeFailedSaga, result)
        }
    }
    catch (error) {
        yield call(employeeErrorSaga, error)
    }
}

function* editEmployeeSaga(action) {
    const { userId, firstName, lastName, email, phone_number, profile, role, permissions } = action
    try {
        const result = yield call(axios.editEmployeeList, userId, firstName, lastName, email, phone_number, profile, role, permissions)
        console.log(action, "action", result)
        if (result.status === 1) {
            cl('result inside get terminal area list saga', result)
            yield put({
                type: types.API_EDIT_EMPLOYEE_LIST_SUCCESS,
                result: result.result.data.data,
            })
            toast.success(result?.result?.data?.message)
            history.push('/employee')
        }
        else {
            yield call(employeeFailedSaga, result)
        }
    }
    catch (error) {
        yield call(employeeErrorSaga, error)
    }
}

function* generatePasswordSaga(action) {
    const { id } = action;
    try {
        const result = yield call(axios.genearatePasswordApi, id)
        if (result.status === 1) {
            cl('result inside get terminal Details saga', result)
            yield put({
                type: types.API_GENERATE_PASSWORD_SUCCESS,
                result: result.result.data.data,
            })
            toast.success(result?.result?.data?.message)
        }
        else {
            yield call(employeeFailedSaga, result)
        }
    }
    catch (error) {
        yield call(employeeErrorSaga, error)
    }
}

function* blockEmployeeSaga(action) {
    const { userId, isBlocked } = action;

    try {
        const result = yield call(axios.blockUser, userId, isBlocked)
        console.log(result, "result***")
        if (result.status === 1) {
            cl('result inside block user saga', result)
            yield put({
                type: types.API_BLOCK_EMPLOYEE_LIST_SUCCESS,
                result: result.result.data.data,
            })

            toast.success(result?.result?.data?.message, { toastId: result?.result?.data?.message })
        }
        else {
            yield call(employeeFailedSaga, result)
        }
    }
    catch (error) {
        console.log(error, "result***")
        yield call(employeeErrorSaga, error)
    }
}

function* getEventListSaga(action) {
    try {
        const result = yield call(axios.getEventList, action.payload)
        if (result.status === 1) {
            yield put({
                type: types.API_GET_EVENT_LIST_SUCCESS,
                result: result.result.data.data,
            })
        }
        else {
            yield call(employeeFailedSaga, result)
        }
    }
    catch (error) {
        yield call(employeeErrorSaga, error)
    }
}

export default function* rootEmployeeManagementSaga() {
    yield takeLatest(types.API_GET_EMPLOYEE_LIST_LOAD, getEmployeeListSaga)
    yield takeLatest(types.API_GET_ROLES_LIST_LOAD, getRolesListSaga)
    yield takeLatest(types.API_ADD_EMPLOYEE_LIST_LOAD, addEmployeeSaga)
    yield takeLatest(types.API_GET_EMPLOYEE_LIST_DETAILS_LOAD, getEmployeeDetailsSaga)
    yield takeLatest(types.API_EDIT_EMPLOYEE_LIST_LOAD, editEmployeeSaga)
    yield takeLatest(types.API_GENERATE_PASSWORD_LOAD, generatePasswordSaga)
    yield takeLatest(types.API_BLOCK_EMPLOYEE_LIST_LOAD, blockEmployeeSaga)
    yield takeLatest(types.API_GET_EVENT_LIST_LOAD, getEventListSaga)
}