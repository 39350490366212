import React, { useState, useEffect, useRef } from 'react';
// Navigation
import { useHistory, useLocation } from 'react-router-dom'
// Styles
import 'antd/dist/antd.css'
import '../dashboard.scss'
// Third party Components
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Spin } from 'antd'
import { Card, Table as BSTable, } from 'react-bootstrap';
// Mui Components
import { Grid, useTheme, Select, FormHelperText, } from '@material-ui/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
// Custom components
import DashboardLayout from '../../layouts/dashboardLayout'
import { InputField, Navbar, AppBar, Button } from '../../customComponents'
// Constants
import { AppImages } from '../../themes/appImages'
import { Colors } from '../../themes/colors'
import { appConstants, ValidationConstants } from '../../themes/constants'
// utils methods
import cl from '../../utils/cl'
import { useStyles } from '../../styles'
// Redux
import { useSelector, useDispatch } from 'react-redux'
import { handleClearStateAction, editTerminalAreaAction, getTerminalAreaDetailsAction } from '../../store/actions'
import { editStateAction, getStateDetailsAction, getTimeZoneListAction } from "../../store/actions/stateListingActions"
// Global constants

export const EditStateListing = () => {
    // Hooks declarations
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const location = useLocation()
    const dispatch = useDispatch()
    const { isLoading, isTableDataLoading, stateDetails } = useSelector(state => state.stateListingReducer)
    const { state_name, short_name } = stateDetails
    const validationSchema = Yup.object().shape({
        stateName: Yup.string().test('trim', ValidationConstants.stateName.empty, value => value?.trim()?.length > 0).min(2, ValidationConstants.stateName.short).max(50, ValidationConstants.stateName.long).required(ValidationConstants.stateName.empty),
        shortName: Yup.string().max(2, ValidationConstants.shortName.long).required(ValidationConstants.shortName.empty),
        timezone: Yup.string().required(ValidationConstants.timezone.empty),
    })

    // Lifecycle Hooks
    useEffect(() => {
        document.title = appConstants.headerTitle.editStateListing;
        dispatch(getTimeZoneListAction())
        let onEffect = async () => {
            await dispatch(handleClearStateAction("clearTerminalAreaDetailsState"))
            if (location?.state
                ?.stateId) {
                await dispatch(getStateDetailsAction(location?.state
                    ?.stateId))
            }
            else {
                history.push('/state-listing')
            }
        }
        onEffect();
    }, [])

    const [timezone, setTimezone] = useState("")

    useEffect(() => {
        setTimezone(stateDetails?.timezone?._id)
    }, [stateDetails])

    // Consoles 
    const values = {
        stateName: state_name,
        shortName: short_name,
        timezone
    }

    //Callback methods
    const handleFormSubmit = (values, { setSubmitting }) => {
        setSubmitting(false)
        dispatch(editStateAction(location?.state
            ?.stateId, values.stateName.trim(), values.shortName.toUpperCase(), values.timezone))
    }

    const handleKeyDown = (e) => {
        if (e.key === " ") {
            e.preventDefault();
        }
    }

    let timeZoneData = useSelector(state => state.stateListingReducer.timezoneList)

    return (
        <Spin
            size="large"
            spinning={isLoading}
            wrapperClassName={classes.tableSpinner}
            className={classes.antSpin}
        >
            <DashboardLayout>
                <AppBar breadcrumbs={[{ route: '/state-listing', name: "State Listing" }, { route: '/edit-state-listing', name: "Edit state" }]} />
                <Navbar title="Edit State" />
                <Card className={classes.card}>
                    <Grid container>
                        <Grid className={classes.tableContainerRow}>
                            <Formik
                                enableReinitialize
                                initialValues={values}
                                validationSchema={validationSchema}
                                onSubmit={handleFormSubmit}
                            >
                                {({
                                    values,
                                    errors,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                    isSubmitting,
                                    touched,
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <BSTable striped bordered style={{ minHeight: 125 }}>
                                            {
                                                isTableDataLoading ? <thead className={classes.bsHeaderTable}>
                                                    <Spin className={classes.tableDataSpinner} />
                                                </thead>
                                                    :
                                                    <tbody>
                                                        <tr>
                                                            <td className={classes.rowKey}>
                                                                State Name
                                                            </td>
                                                            <td className={classes.rowValue}>
                                                                <div className={classes.inputWrapper}>
                                                                    <InputField
                                                                        id="stateName"
                                                                        placeholder="Enter State Name"
                                                                        name="stateName"
                                                                        error={Boolean(touched.stateName && errors.stateName)}
                                                                        helperText={touched.stateName && errors.stateName}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        type="name"
                                                                        value={values?.stateName?.trimLeft()}
                                                                        maxLength={50}
                                                                    // onPaste={(e) => {
                                                                    //     e.preventDefault();
                                                                    //     return false;
                                                                    // }}
                                                                    // onContextMenu={(e) => e.preventDefault()}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className={classes.rowKey}>
                                                                Short Name
                                                            </td>
                                                            <td className={classes.rowValue}>
                                                                <div className={classes.inputWrapper}>
                                                                    <InputField
                                                                        id="shortName"
                                                                        placeholder="Enter Short Name"
                                                                        name="shortName"
                                                                        error={Boolean(touched.shortName && errors.shortName)}
                                                                        helperText={touched.shortName && errors.shortName}
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                        type="name"
                                                                        value={values?.shortName?.toUpperCase()}
                                                                        maxLength={2}
                                                                        onKeyDown={handleKeyDown}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className={classes.rowKey}>
                                                                Timezone
                                                            </td>
                                                            <td className={classes.rowValue}>
                                                                <div className={classes.inputWrapper}>
                                                                    <Select
                                                                        native
                                                                        className={classes.selectInput}
                                                                        defaultValue={values?.timezone}
                                                                        value={values?.timezone}
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                        inputProps={{
                                                                            name: "timezone",
                                                                            id: "timezone",
                                                                        }}
                                                                    // IconComponent={() => (
                                                                    //     <FontAwesomeIcon
                                                                    //         icon={faChevronDown}
                                                                    //         className={classes.dropdownIcon}
                                                                    //     />
                                                                    // )}
                                                                    >
                                                                        <option value="">
                                                                            Select Timezone
                                                                        </option>
                                                                        {timeZoneData?.map(
                                                                            (time, index) => {

                                                                                return (
                                                                                    <option value={time?._id}>
                                                                                        {time?.name}
                                                                                    </option>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </Select>
                                                                    {touched.timezone &&
                                                                        errors.timezone && (
                                                                            <FormHelperText
                                                                                error
                                                                                id="component-error-text"
                                                                            >
                                                                                {errors.timezone}
                                                                            </FormHelperText>
                                                                        )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className={classes.rowKey}></td>
                                                            <td className={classes.rowValue}>
                                                                <Button title={appConstants.update} type="submit" mr />
                                                                <Button title="Cancel" onClick={() => history.goBack()} />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                            }
                                        </BSTable>
                                    </form>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                </Card>
            </DashboardLayout>
        </Spin>
    );
}
