import React, { useState, useEffect, useRef } from "react";
// Navigation
import { useHistory, useLocation } from "react-router-dom";
// Styles
import "antd/dist/antd.css";
import "../dashboard.scss";
// antd
import { Tooltip, Spin, Input, Modal } from "antd";

import { Card, Table as BSTable } from "react-bootstrap";

// fontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faShare } from "@fortawesome/free-solid-svg-icons";
// Mui Components
// import { Grid, useTheme, useMediaQuery, Box, FormHelperText } from '@material-ui/core';
// Custom components
import DashboardLayout from "../../layouts/dashboardLayout";
import {
  AppBar,
  Button,
  PaginationBar,
  Navbar,
  NavbarSearch,
  ConfirmationModal,
  Table,
  InputField,
} from "../../customComponents";
// Constants
import { AppImages } from "../../themes/appImages";
import { Colors } from "../../themes/colors";
import { appConstants, ValidationConstants } from "../../themes/constants";
// utils methods
import cl from "../../utils/cl";
import { useStyles } from "../../styles";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  handleNavigationStateAction,
  getTerminalListAction,
  deleteTerminalAction,
  exportFileAction,
  importFileAction,
  getTerminalAreaListAction,
} from "../../store/actions";
import {
  validateImageRatio,
  validateImageRatioOneByOne,
} from "../../utils/helpers";
import { toast } from "react-toastify";
import {
  Grid,
  Select,
  useTheme,
  FormHelperText,
  Collapse,
  Fade,
} from "@material-ui/core";
import {
  convertFromRaw,
  convertToRaw,
  ContentState,
  Editor,
  convertFromHTML,
  EditorState,
  RichUtils,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draft";
import "draft-js/dist/Draft.css";
import RichEditorExample from "../../customComponents/textEditor";
import { addNewsAction } from "../../store/actions/newsManagementActions";

export const NewsView = () => {
  const styleProps = { searchable: true };

  const state = useSelector((state) => state.imageManagementReducer);
  const location = useLocation();
  const news = location?.state?.state?.news;
  const [isImageVisible, setImageVisible] = useState(false)
  const editorRef = useRef();
  const { isLoading } = state;
  // Lifecycle Hooks

  // Remove selected file on error state updation

  // Hooks declarations
  const classes = useStyles(styleProps);
  // const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  // const matches = useMediaQuery(theme.breakpoints.down('xs'));
  let editorState;
  useEffect(() => {
    document.title = appConstants.headerTitle.viewNews;
    const editorState = htmlToDraft(location?.state?.state?.news?.description);
  }, []);

  const _imageModalView = () => {
    return (
      <Modal
        title="Image"
        visible={isImageVisible}
        onCancel={() => setImageVisible(false)}
        footer={[]}

      >
        <img
          // src={appConstants.baseURL + news.media}
          src={appConstants.baseURL + news.media}
          alt="new"
          width={'100%'}
          height={'100%'}
          style={{
            borderRadius: 10,
            height: window.innerWidth <= 600 ? '200px' : '400px'
          }}
          onClick={() => setImageVisible(true)}
        />
      </Modal>
    )
  }

  return (
    <Spin
      size="large"
      spinning={false}
      wrapperClassName={classes.tableSpinner}
      className={classes.antSpin}
    >
      <DashboardLayout>
        <AppBar
          breadcrumbs={[
            { route: "/news-terminal", name: appConstants.newsManagement },
            { route: "/news-view", name: "News Details" },
          ]}
        />
        <Grid container className={classes.buttonBarWrapper}></Grid>
        <Navbar title={"News Details"} />
        <Card className={classes.card}>
          <Grid container>
            <Grid className={classes.tableContainerRow}>
              <form>
                <BSTable bordered>
                  <tbody>
                    <tr>
                      <td className={classes.rowKey}>Terminal Area</td>
                      <td className={classes.rowValue}>
                        <div className={classes.inputWrapper}>
                          <InputField
                            value={news?.region}
                            disabled={true}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className={classes.rowKey}>Title</td>
                      <td className={classes.rowValue}>
                      <div className={classes.inputWrapper}>
                        <div className="location_details">
                           {news?.description}
                          </div>
                          </div>
                      </td>
                    </tr>
                    <tr>
                      <td className={classes.rowKey}>News</td>
                      <td className={classes.rowValue}>
                        <div >
                        <div
                        style={{wordBreak:"break-word",overflowX:"auto",maxHeight:"500px"}}
                        // className="news_details"
                        className="ck-content"
                          dangerouslySetInnerHTML={{ __html: news.title }}
                        ></div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </BSTable>
              </form>
              {_imageModalView()}
            </Grid>
          </Grid>
        </Card>
      </DashboardLayout>
    </Spin>
  );
};
