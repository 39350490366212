export const Colors = {
    'white': '#ffffff',
    'black': '#000000',
    'primary': '#196EA1',
    'breadcrumb':"#222222",
    '--forgot-text': '#555',
    'title': '#3C4858',
    'greyIcon':'#a9afbb',
    'dashboardCards':"#333333",
    'coolBlue':"#f6f7fa",
}