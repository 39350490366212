import React, { useState, useEffect, useRef } from 'react';
// Navigation
import { useHistory, useLocation } from 'react-router-dom'
// Styles
import 'antd/dist/antd.css'
import '../dashboard.scss'
// Third party Components

// antd
import { Image, Spin, Tooltip } from 'antd';
import { Card, Table as BSTable, } from 'react-bootstrap';
// Mui Components
import { Grid, Select, FormHelperText } from '@material-ui/core';
// Custom components
import DashboardLayout from '../../layouts/dashboardLayout'
import { InputField, Navbar, AppBar, Button, ConfirmationModal } from '../../customComponents'
import { Select as AntdSelect } from 'antd';
// fontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import {
    validateImageRatioOneByOne,
} from "../../utils/helpers";
import { toast } from "react-toastify";
// Constants
import { AppImages } from '../../themes/appImages'
import { ValidationConstants, appConstants } from '../../themes/constants'
import { handleNavigationStateAction, } from '../../store/actions'
// utils methods
import { useStyles } from '../../styles'
// Redux
import { useSelector, useDispatch } from 'react-redux'
import { Checkbox } from '@material-ui/core';
import localStorage from "../../utils/localStorage";
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
import { editPostManagementAction, getUsersListAction, deletePostImageAction, getPostDetailsAction, updatePostData } from '../../store/actions/postManagementAction';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

// Global constants

export const EditPost = (props) => {
    // Hooks declarations
    const classes = useStyles();
    const history = useHistory();
    const videoRef = useRef();
    const [image, setImage] = useState("")
    const [searchValue, setSearchValue] = useState();
    const [description, setDescription] = useState();
    const [sharedPostDescription, setSharedPostDescription] = useState();
    const [addVideo, setAddVideo] = useState(null)
    const [mediaType, setMediaType] = useState(null)
    const [selectedUser, setSelectedUser] = useState([]);
    const [addimage, setAddImage] = useState([])
    const [imagesArr, setImagesArr] = useState([])
    const [imageDeleteArr, setImageDeleteArr] = useState([])
    const [postLocation, setPostLocation] = useState(null)
    const [deleteImagesArray, setDeleteImagesArray] = useState([])
    const postListingData = useSelector(state => state.PostManagementReducer)
    const { usersList, postDetails, isLoading } = postListingData
    const location = useLocation()
    const dispatch = useDispatch()
    const [imageId, setImageId] = useState(null)
    const [mediaIndex, setMediaIndex] = useState(null)
    const [videoFile, setVideoFile] = useState(null)

    const roleList = useSelector(state => state.EmployeeManagementReducer)
    // Object destructuring
    const { isTableDataLoading, roles } = roleList
    let siderIndex = localStorage.getLayoutArr()
    let index = siderIndex?.findIndex((x) => x.key == "postManagement")
    let permissionsData = localStorage?.getPermissions()
    let employeeIndex = permissionsData?.findIndex((x) => x.name == "postManagement")
    let postData = props?.location?.state?.postData

    useEffect(() => {
        document.title = appConstants.headerTitle.EdittPost;
        if (props?.location?.state?.postData) {
            dispatch(getPostDetailsAction({ post_id: postData._id, user_id: postData.user._id }))
        } else {
            history.push('/postListing')
        }

        dispatch(getUsersListAction(location?.state?.botUserId, 'tag'))
        if (permissionsData) {

            dispatch(handleNavigationStateAction(index, false))
        }
        else {
            dispatch(handleNavigationStateAction(3, false))
        }
        // dispatch(getRolesListAction())

        return () => {
            dispatch(updatePostData({}, 'postDetails'))
        }
    }, [])

    useEffect(() => {
        let allImage = []
        let tagFriendArr = []
        if (Object.keys(postDetails).length > 0) {
            postDetails?.images.map((item) => {
                allImage.push({ url: item.url, _id: item._id })
                setAddImage(allImage)
            })
            setDescription(postDetails?.description)
            setSharedPostDescription(postDetails?.share?.description)
            if (postDetails?.video) {
                setAddVideo(`${appConstants.baseURL}${postDetails?.video}`)
            }
            if (postDetails?.tag_friends) {
                postDetails?.tag_friends?.map((item) => {
                    tagFriendArr.push(item._id)
                    setSelectedUser(tagFriendArr)
                })
            }
            if (postDetails?.post_location) {
                setPostLocation(postDetails.post_location)
            }
        }
        setVideoFile(postDetails?.video)

    }, [postDetails])

    const imageValidation = (value) => {
        let fileTypes = ["jpg", "jpeg", "png"]

        return (fileTypes.indexOf(value) > -1)
    }

    const handleImageChange = (event) => {

        if (event.target.files && event.target.files[0]) {
            let uploadFile = event.target.files[0]
            let splitName = uploadFile.name.split(".").pop().toLowerCase()
            let isSuccess = imageValidation(splitName)
            if (isSuccess) {
                let reader = new FileReader();
                reader.onload = (e) => {
                    let allImage = [...addimage]
                    allImage.push({ url: e.target.result })
                    setAddImage(allImage)
                    let imgArr = [...imagesArr]
                    let obj = {
                        uri: e.target.result,
                        file: event.target.files[0]
                    }
                    imgArr.push(obj)
                    setImagesArr(imgArr)
                };
                reader.readAsDataURL(event.target.files[0]);
                return
            }
            else {
                toast.error("Only .jpg, .jpeg and .png format images are allowed.")
            }
        }
    };

    const handleUserSearch = (value) => {
        if (value.length < 100) {
            setSearchValue(value);
            // dispatch(getUserListAction(value))
        }
    }
    const handleChange1 = (value) => {
        setSelectedUser(value);
        // setSelectedTerminalError(value.length > 0 ? false : true)
    }

    const videoValidation = (value) => {
        let fileTypes = ["mp4",]
        return (fileTypes.indexOf(value) > -1)
    }

    const handleChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            let uploadFile = event.target.files[0]
            let splitName = uploadFile.name.split(".").pop().toLowerCase()
            let isSuccess = videoValidation(splitName)
            if (isSuccess) {
                let reader = new FileReader();
                reader.onload = (e) => {
                    var media = new Audio(reader.result);
                    media.onloadedmetadata = function () {
                        if (media.duration > 30.526667) {
                            toast.error("Video limit should be maximum 30 seconds.")
                        }
                        else {
                            setAddVideo(e.target.result)
                            setVideoFile(event.target.files[0])
                        } // this would give duration of the video/audio file
                    };
                };
                setVideoFile(event.target.files[0])
                reader.readAsDataURL(event.target.files[0]);
                return
            }
            else {
                toast.error("Only .mp4 format is allowed.")
            }
        }
    };

    const deleteVideo = () => {
        setAddVideo("")
        setMediaType('video')
        setVideoFile(null)
    }

    const _addImageIcon = () => {
        return (
            addimage.length < 4 &&
            <>
                <input
                    accept=".png,.jpg,.jpeg"
                    className={classes.uploaderInput}
                    id="icon-button-file"
                    type="file"
                    onChange={(e) => {

                        handleImageChange(e)
                    }}
                />
                <label
                    htmlFor="icon-button-file"
                    className={classes.imageManagementUploaderlabel}
                >
                    <Tooltip
                        title={image ? appConstants.tooltip.changeImage : appConstants.tooltip.uploadImage}
                        placement="right"
                    >
                        <img
                            src={AppImages.addPhoto}
                            className={classes.terminalImage}
                        />

                    </Tooltip>
                </label>
            </>
        )
    }

    const _onUpdate = () => {
        if (description?.trim().length == 0 && imagesArr.length == 0 && videoFile == null) {
            toast.error(appConstants.selectOneValidation)
        } else {
            let obj = {
                post_id: postData?._id,
                description: description?.trim(),
                image: imagesArr,
                video: videoFile,
                tagged_friends: selectedUser,
                post_location: postLocation,
                deletedImages: deleteImagesArray,
                type: mediaType,
                shared: sharedPostDescription?.trim()
            }
            dispatch(editPostManagementAction(obj))
        }
    }

    const deleteImageVideo = (type, item, indexx) => {
        let obj = {
            type: "image",
            post_id: postData?._id,
            imageId: imageId
        }
        let arr = [...addimage];
        arr = arr.filter((x, index) => index !== indexx)
        setAddImage(arr)
        dispatch(deletePostImageAction(obj))
        if (item._id) {
            deleteImagesArray.push(item?._id)
            setDeleteImagesArray(deleteImagesArray)

        }
    }

    return (
        <Spin
            size="large"
            spinning={isLoading}
            wrapperClassName={classes.tableSpinner}
            className={classes.antSpin}
        >
            <DashboardLayout>
                <AppBar breadcrumbs={[{ route: '/postListing', name: appConstants.postManagement }, { route: '/edit-post', name: appConstants.editPost, state: location?.state?.userId }]} />
                <Navbar title={appConstants.editPost} />
                <Card className={classes.card}>
                    <Grid container>
                        <Grid className={classes.tableContainerRow}>
                            <form >
                                <BSTable striped bordered className={classes.bsTableHeight}>
                                    {
                                        isTableDataLoading ? <thead className={classes.bsHeaderTable}>
                                            <Spin className={classes.tableDataSpinner} />
                                        </thead>
                                            :
                                            <tbody>
                                                {postDetails?.share && <tr>
                                                    <td className={classes.rowKey}>Shared Post Description</td>
                                                    <td className={classes.rowValue}>
                                                        <div className={classes.inputWrapper}>
                                                            <textarea
                                                                style={{ resize: "none" }}
                                                                className="textAreaNew"
                                                                placeholder="Shared Post Description"
                                                                name="description"
                                                                type="description"
                                                                value={sharedPostDescription?.trimLeft()}
                                                                onChange={(e) => setSharedPostDescription(e.target.value)}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>}

                                                {!postDetails?.share && <>
                                                    <tr>
                                                        <td className={classes.rowKey}>Description</td>
                                                        <td className={classes.rowValue}>
                                                            <div className={classes.inputWrapper}>
                                                                <textarea
                                                                    style={{ resize: "none" }}
                                                                    className="textAreaNew"
                                                                    placeholder="Description"
                                                                    name="description"
                                                                    type="description"
                                                                    value={description?.trimLeft()}
                                                                    onChange={(e) => setDescription(e.target.value)}
                                                                />

                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className={classes.rowKey}>
                                                            Images
                                                        </td>
                                                        <td className={classes.rowValue}>
                                                            <div className={classes.inputWrapper} style={{ display: "-webkit-box", marginBottom: "-10px", marginLeft: "4px" }}>
                                                                {
                                                                    addimage?.concat(1)?.map((item, index) => {
                                                                        return (
                                                                            <>
                                                                                {item?.url ? (<div style={{ marginRight: "5%" }}>
                                                                                    <Image
                                                                                        src={item?.url.includes('data:image') ? `${item?.url}` : `${appConstants.baseURL}${item?.url}`}
                                                                                        className={classes.terminalImage}
                                                                                        style={{ borderRadius: "50px", border: "2px solid lightgrey" }}
                                                                                    />
                                                                                    <div className='cross-icon' >
                                                                                        <img src={AppImages.close}
                                                                                            style={{ cursor: "pointer" }} width="12px" height="12px" onClick={() => {
                                                                                                setImageId(item._id)
                                                                                                setMediaIndex(index)
                                                                                                deleteImageVideo("image", item, index)
                                                                                            }
                                                                                                // if (item._id) {
                                                                                                //     setDeleteMediaType('image')
                                                                                                //     setImageId(item._id)
                                                                                                //     setMediaIndex(index)
                                                                                                //     setConfirm(true)
                                                                                                // } else {
                                                                                                //     setDeleteMediaType('image')
                                                                                                //     setImageId(null)
                                                                                                //     setMediaIndex(index)
                                                                                                //     setConfirm(true)
                                                                                                // }
                                                                                            }></img>
                                                                                    </div>
                                                                                </div>)
                                                                                    :
                                                                                    _addImageIcon()
                                                                                }
                                                                            </>
                                                                        )
                                                                    })
                                                                }

                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className={classes.rowKey}>
                                                            Video
                                                        </td>
                                                        <td className={classes.rowValue}>
                                                            <div className={classes.inputWrapper}
                                                                style={{ display: "-webkit-box", marginBottom: "-10px", marginLeft: "4px" }}>
                                                                {addVideo ?
                                                                    <div style={{ marginRight: "5%" }}>
                                                                        <div className='video-class' >
                                                                            <img src={AppImages.close}
                                                                                style={{
                                                                                    position: "absolute",
                                                                                }}
                                                                                width="12px" height="12px" onClick={() => {

                                                                                    deleteVideo()
                                                                                    // deleteImageVideo("video")
                                                                                    // setDeleteMediaType('Video')
                                                                                    // setConfirm(true)
                                                                                }}></img>
                                                                        </div>
                                                                        <video
                                                                            controls
                                                                            style={{
                                                                                width: "100%",
                                                                                objectFit: "contain",
                                                                                height: "200px"
                                                                            }}
                                                                        >
                                                                            <source src={`${addVideo}`}
                                                                                className={classes.terminalImage}
                                                                                type="video/mp4"
                                                                            ></source>
                                                                        </video>
                                                                    </div>
                                                                    :
                                                                    <>
                                                                        <input
                                                                            accept="video/mp4,video/x-m4v,video/*,video/mov,video/avi"
                                                                            className={classes.uploaderInput}
                                                                            id="icon-button1-file"
                                                                            type="file"
                                                                            onChange={(e) => {

                                                                                handleChange(e)
                                                                            }}
                                                                            ref={videoRef}
                                                                        // onClick={() => videoRef.current.click()}
                                                                        />
                                                                        <label
                                                                            id="icon-button1-file"
                                                                            className={classes.imageManagementUploaderlabel}
                                                                        >
                                                                            <Tooltip
                                                                                title={image ? appConstants.tooltip.changeVideo : appConstants.tooltip.uploadVideo}
                                                                                placement="right"
                                                                            >
                                                                                <img
                                                                                    onClick={() => videoRef.current.click()}
                                                                                    src={AppImages.addVideo}
                                                                                    className={classes.terminalImage}
                                                                                // onChange={(e) => {
                                                                                //     handleImageChange(e)

                                                                                // }}

                                                                                />

                                                                            </Tooltip>
                                                                        </label>
                                                                    </>}

                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className={classes.rowKey}>
                                                            Tagged Friends
                                                        </td>
                                                        <td className={classes.rowValue}>
                                                            <div className={classes.inputWrapper} style={{ marginTop: "10px" }}>
                                                                <div className="select-users" >
                                                                    <AntdSelect
                                                                        mode="multiple"
                                                                        allowClear
                                                                        style={{
                                                                            width: '100%',
                                                                            cursor: 'default'
                                                                        }}
                                                                        placeholder="Search Friends"
                                                                        optionFilterProp='children'
                                                                        showSearch
                                                                        value={selectedUser}
                                                                        // value={templates === 'customUser'}
                                                                        filterSort={(optionA, optionB) => {
                                                                            optionA.value.toLowerCase().localeCompare(optionB.value.toLowerCase())
                                                                        }
                                                                        }
                                                                        onSearch={handleUserSearch}
                                                                        onChange={handleChange1}

                                                                    >
                                                                        {usersList?.map(
                                                                            (data, index) => {
                                                                                return (
                                                                                    <option value={data?._id}>
                                                                                        {data?.userName}
                                                                                    </option>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </AntdSelect>

                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className={classes.rowKey}>
                                                            Check In
                                                        </td>
                                                        <td className={classes.rowValue}>
                                                            <div className={classes.inputWrapper}>

                                                                {/* <GooglePlacesAutocompleteComponent /> */}
                                                                <Autocomplete
                                                                    className='post-locations'
                                                                    apiKey="AIzaSyAId9HPoVrtc6rDn9O-tAWERRJEelhkARc"
                                                                    // selectProps={{ ...field, isClearable: true }}
                                                                    value={postLocation}
                                                                    onChange={(evt) => {
                                                                        setPostLocation(evt.target.value);
                                                                    }}
                                                                    onPlaceSelected={(place) => {

                                                                        setPostLocation(place.formatted_address)
                                                                    }}
                                                                />

                                                            </div>
                                                        </td>
                                                    </tr>
                                                </>}
                                                <tr>
                                                    <td className={classes.rowKey}></td>
                                                    <td className={classes.rowValue}>
                                                        <Button title="Update" onClick={_onUpdate} />
                                                    </td>
                                                </tr>


                                            </tbody>
                                    }
                                </BSTable>
                            </form>

                        </Grid>
                    </Grid>

                </Card>
            </DashboardLayout>
        </Spin>
    );
}
