import React, { useState, useEffect, useRef } from 'react';
// Navigation
import { useHistory, useLocation } from 'react-router-dom'
// Styles
import 'antd/dist/antd.css'
import '../dashboard.scss'
// Third party Components
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Spin } from 'antd'
import { Card, Table as BSTable, } from 'react-bootstrap';
// Mui Components
import { Grid, useTheme } from '@material-ui/core';
// Custom components
import DashboardLayout from '../../layouts/dashboardLayout'
import { InputField, Navbar, AppBar, Button } from '../../customComponents'
// Constants
import { AppImages } from '../../themes/appImages'
import { Colors } from '../../themes/colors'
import { appConstants, ValidationConstants } from '../../themes/constants'
// utils methods
import cl from '../../utils/cl'
import { useStyles } from '../../styles'
// Redux
import { useSelector, useDispatch } from 'react-redux'
import { handleClearStateAction, editTerminalAreaAction, getTerminalAreaDetailsAction } from '../../store/actions'
import { getStateListAction } from "../../store/actions/stateListingActions"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
// Mui Components
import {
    Select,
    FormHelperText,
    Collapse,
    Checkbox,
} from "@material-ui/core";
// Global constants

export const EditTerminalArea = () => {
    // Hooks declarations
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const location = useLocation()
    const dispatch = useDispatch()
    const { isLoading, isTableDataLoading, terminalAreaDetails } = useSelector(state => state.terminalAreaManagementReducer)
    const { region_name, state } = terminalAreaDetails

    const validationSchema = Yup.object().shape({
        terminalAreaName: Yup.string().test('trim', ValidationConstants.terminalAreaName.empty, value => value?.trim()?.length > 0).min(2, ValidationConstants.terminalAreaName.short).max(50, ValidationConstants.terminalAreaName.long).required(ValidationConstants.terminalAreaName.empty),
        // stateName: Yup.string().max(30, ValidationConstants.stateName.long).required(ValidationConstants.stateName.empty).test('trim', ValidationConstants.stateName.empty, value => value?.trim()?.length > 0),
        stateName: Yup.string().required(ValidationConstants.stateName.empty),
    })

    // Lifecycle Hooks
    useEffect(() => {
        document.title = appConstants.headerTitle.editTerminalArea;
        let onEffect = async () => {
            await dispatch(handleClearStateAction("clearTerminalAreaDetailsState"))
            await dispatch(getStateListAction("", null, null, "", ""));
            if (location?.state
                ?.terminalId) {
                await dispatch(getTerminalAreaDetailsAction(location?.state
                    ?.terminalId))
            } else {
                history.push('/terminal-area-list')
            }
        }

        onEffect();
    }, [])
    const { stateListing, stateDetails } = useSelector(
        (state) => state.stateListingReducer
    );

    const [stateName, setStateName] = useState("");

    useEffect(() => {
        setStateName(terminalAreaDetails?.state?._id)
    }, [terminalAreaDetails])



    // Consoles 
    const values = {
        terminalAreaName: region_name,
        stateName
    }
    //Callback methods
    const handleFormSubmit = (values, { setSubmitting }) => {
        setSubmitting(false)
        dispatch(editTerminalAreaAction(location?.state
            ?.terminalId, values.terminalAreaName.trim(), values.stateName))
    }

    return (
        <Spin
            size="large"
            spinning={isLoading}
            wrapperClassName={classes.tableSpinner}
            className={classes.antSpin}
        >
            <DashboardLayout>
                <AppBar breadcrumbs={[{ route: '/terminal-area-list', name: "Region Listing" }, { route: '/edit-terminal-area', name: "Edit Region" }]} />
                <Navbar title="Edit Region" />
                <Card className={classes.card}>
                    <Grid container>
                        <Grid className={classes.tableContainerRow}>
                            <Formik
                                enableReinitialize
                                initialValues={values}
                                validationSchema={validationSchema}
                                onSubmit={handleFormSubmit}
                            >
                                {({
                                    values,
                                    errors,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                    isSubmitting,
                                    touched,
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <BSTable striped bordered style={{ minHeight: 125 }}>
                                            {
                                                isTableDataLoading ? <thead className={classes.bsHeaderTable}>
                                                    <Spin className={classes.tableDataSpinner} />
                                                </thead>
                                                    :
                                                    <tbody>
                                                        <tr>
                                                            <td className={classes.rowKey}>State Name</td>
                                                            <td className={classes.rowValue}>
                                                                <div className={classes.inputWrapper}>
                                                                    <Select
                                                                        native
                                                                        className={classes.selectInput}
                                                                        // defaultValue={values.stateName}
                                                                        value={values.stateName}
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                        inputProps={{
                                                                            name: "stateName",
                                                                            id: "stateName",
                                                                        }}
                                                                    // IconComponent={() => (
                                                                    //     <FontAwesomeIcon
                                                                    //         icon={faChevronDown}
                                                                    //         className={classes.dropdownIcon}
                                                                    //     />
                                                                    // )}
                                                                    >
                                                                        <option value="">
                                                                            Select State Name
                                                                        </option>
                                                                        {stateListing.map(
                                                                            (stateName, index) => {
                                                                                return (
                                                                                    <option value={stateName._id}>
                                                                                        {stateName.state_name}
                                                                                    </option>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </Select>
                                                                    {touched.stateName && errors.stateName && (
                                                                        <FormHelperText
                                                                            error
                                                                            id="component-error-text"
                                                                        >
                                                                            {errors.stateName}
                                                                        </FormHelperText>
                                                                    )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className={classes.rowKey}>
                                                                {appConstants.terminalAreaName}
                                                            </td>
                                                            <td className={classes.rowValue}>
                                                                <div className={classes.inputWrapper}>
                                                                    <InputField
                                                                        id="terminalAreaName"
                                                                        placeholder={appConstants.enterTerminalAreaName}
                                                                        name="terminalAreaName"
                                                                        error={Boolean(touched.terminalAreaName && errors.terminalAreaName)}
                                                                        helperText={touched.terminalAreaName && errors.terminalAreaName}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        type="name"
                                                                        value={values?.terminalAreaName?.trimLeft()}
                                                                        maxLength={50}
                                                                    // onPaste={(e) => {
                                                                    //     e.preventDefault();
                                                                    //     return false;
                                                                    // }}
                                                                    // onContextMenu={(e) => e.preventDefault()}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className={classes.rowKey}></td>
                                                            <td className={classes.rowValue}>
                                                                <Button title={appConstants.update} type="submit" mr />
                                                                <Button title="Cancel" onClick={() => history.goBack()} />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                            }
                                        </BSTable>
                                    </form>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                </Card>
            </DashboardLayout>
        </Spin>
    );
}
