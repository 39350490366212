import { apiConstants as types } from '../../themes/constants'

// Get Employees Action
export const getEmployeeListAction = (payload) => {
    return {
        type: types.API_GET_EMPLOYEE_LIST_LOAD,
        payload
    }
}

//get role
export const getRolesListAction = () => ({
    type: types.API_GET_ROLES_LIST_LOAD,
})

// Add employee Action
export const addEmployeeAction = (firstName, lastName, email, phone_number, profile, role, permissions) => ({
    type: types.API_ADD_EMPLOYEE_LIST_LOAD,
    firstName, lastName, email, phone_number, profile, role, permissions
})

// Get employee details Action
export const getEmployeeDetailsAction = (id) => ({
    type: types.API_GET_EMPLOYEE_LIST_DETAILS_LOAD,
    id,
})

//  employee edit Action
export const employeeEditAction = (userId, firstName, lastName, email, phone_number, profile, role, permissions) => ({
    type: types.API_EDIT_EMPLOYEE_LIST_LOAD,
    userId, firstName, lastName, email, phone_number, profile, role, permissions
})

// Block employee
export const blockEmployeeAction = (userId, isBlocked) => ({
    type: types.API_BLOCK_EMPLOYEE_LIST_LOAD,
    userId,
    isBlocked,
})

//generate password Action
export const generatePasswordAction = (id) => ({
    type: types.API_GENERATE_PASSWORD_LOAD,
    id,
})

// Get Employees Action
export const getEventListAction = (payload) => {
    return {
        type: types.API_GET_EVENT_LIST_LOAD,
        payload
    }
}


