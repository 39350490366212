import React, { useState, useEffect } from 'react';
// Navigation
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom'
// Styles
import 'antd/dist/antd.css'
import '../dashboard.scss'
// antd
import { Card, } from 'react-bootstrap';
import { Select as AntdSelect, Modal } from 'antd';
// Mui Components
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
// Custom components
import DashboardLayout from '../../layouts/dashboardLayout'
import { AppBar, Button, PaginationBar, Navbar, NavbarSearch, ConfirmationModal, Table } from '../../customComponents'
// Constants
import { appConstants } from '../../themes/constants'
import localStorage from "../../utils/localStorage";
// utils methods
import { useStyles } from '../../styles'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// Redux
import { useSelector, useDispatch } from 'react-redux'
import { getTerminalAreaListAction, handleNavigationStateAction, } from '../../store/actions'
import { defaultThemeApplyAction } from '../../store/actions/terminalBoardAction';
import { isInternetConnected } from '../../InternetConnection/InternetConnection';
import { clearTerminalBoardDetails } from '../../store/actions/terminalAreaManagementActions';
import { toast } from 'react-toastify';
// Global constants


export const TerminalBoardListing = () => {
    const styleProps = { searchable: true }
    // Hooks declarations
    const classes = useStyles(styleProps);
    const history = useHistory();
    const location = useLocation()
    const dispatch = useDispatch()
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    const terminalAreaManagementState = useSelector(state => state.terminalAreaManagementReducer)
    // Global state initialization
    const { terminalAreaListing, terminalAreaPaging, isLoading, } = terminalAreaManagementState

    // local state initialization
    const [search, setSearch] = useState("")
    const [offset, setOffset] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sortBy, setSortBy] = useState("")
    const [order, setOrder] = useState(null)
    const [confirm, setConfirm] = useState(false)
    const [stateToDelete, setStateToDelete] = useState(null)
    const [disableButton, setDisableButton] = useState()
    const [templates, setTemplates] = useState("all")
    const [selectedRegion, setSelectedRegion] = useState([])
    let permissionsData = localStorage?.getPermissions()
    let employeeIndex = permissionsData?.findIndex((x) => x.name == "terminalBoard")
    // +++++++++++

    // Method to fetch listing
    const fetchDataListing = (search, offset, limit, sortBy, order) => {
        dispatch(getTerminalAreaListAction(search, offset, limit, sortBy, order))
    }
    //lifecycle hooks
    let siderIndex = localStorage.getLayoutArr()
    let siderNestedIndex = localStorage.getNestedLayoutArr()


    useEffect(() => {
        dispatch(clearTerminalBoardDetails())
        document.title = appConstants.headerTitle.terminalBoard;
        if (permissionsData) {
            let index = siderIndex?.findIndex((x) => x.key == "terminalBoard")
            dispatch(handleNavigationStateAction(index, false));
        }
        else {
            dispatch(handleNavigationStateAction(8, false));
        }
        fetchDataListing(search, offset, limit, sortBy, order)

    }, [])


    //Callback method to handle sorting 
    const handleChange = async (pagination, filters, sorter) => {
        //To fetch listing in ascending order
        if (sorter.order == "ascend") {
            setOrder(1)
            setSortBy(sorter.columnKey)
            fetchDataListing(search, offset, limit, sorter.columnKey, 1)
        } //To fetch listing in descending order
        else if (sorter.order === "descend") {
            setOrder(-1)
            setSortBy(sorter.columnKey)
            fetchDataListing(search, offset, limit, sorter.columnKey, -1)
        } //To fetch listing in normal order
        else {
            setOrder(null)
            setSortBy("")
            fetchDataListing(search, offset, limit, "", null)
        }
    }

    // Callback method to handle page chage
    const handlePaginationChange = (e, page) => {
        const offsetTo = (page - 1) * limit
        setOffset(offsetTo)
        fetchDataListing(search, offsetTo, limit, sortBy, order)
    }

    // Callback method to handle searching key
    const handleSearching = async (e) => {
        var tempText = e.target.value
        if (e.target.value.charAt(0) === " ") {
            tempText = e.target.value.replace(/^ +/gm, '');
        }
        setSearch(tempText)
        fetchDataListing(tempText, 0, limit, "", null)

    }
    // +++++++++++

    const columns = [
        {
            title: appConstants.sr,
            dataIndex: '',
            key: 'sr',
            render: (text, record, index) => {
                console.log(terminalAreaPaging?.page?.nextPage - limit, "bfdsb")
                return (
                    (terminalAreaPaging?.page?.nextPage - limit) >= 0 &&
                    (index + 1) + (terminalAreaPaging?.page?.nextPage - limit))
            },
            ellipsis: false,
            width: 100,
        },
        {
            title: 'Region Name',
            dataIndex: 'region_name',
            key: 'region_name',
            render: (region_name) => region_name,
            ellipsis: false,
            sorter: true,
        },
        {
            title: appConstants.action,
            dataIndex: '',
            key: 'x',
            width: 100,
            align: 'center',
            render: (text, record, index) => {
                return (
                    <Grid className={classes.buttons}>
                        <Button disabled={(permissionsData) && permissionsData[employeeIndex]?.edit == false} title={appConstants.edit}
                            onClick={() => history.push({
                                pathname: "/edit-terminal-board",
                                state: { terminalName: record.region_name, terminalId: record._id }
                            })} />

                    </Grid>
                )
            },
        },
    ];

    return (
        <DashboardLayout>
            <Modal
                onCancel={() => {
                    setConfirm(false)
                    setSelectedRegion([])
                    setTemplates("all")
                }}
                visible={confirm}
                title="Apply Default Theme?"
                footer={null}>
                <div>
                    <h6>Please Select One Option.</h6>
                    <RadioGroup aria-label="template" name="template" value={templates} onChange={(e) => setTemplates(e.target.value)}>
                        <FormControlLabel value="all" control={<Radio onClick={() => setSelectedRegion([])} />}
                            checked={templates == "all"} style={{ width: "28%" }}
                            label="All Regions"
                        />
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '-11px', marginTop: "-14px" }}>
                            <Radio style={{ width: 42, height: 42 }} value="region"
                                checked={templates == "region"} className="dropdown-radioButton" />
                            <div className="select-users" style={{
                                fontSize: '1rem',
                                fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                                fontWeight: '400',
                                lineHeight: '1.5',
                                width: '100%',
                                letterSpacing: '0.00938em',
                                marginTop: "20px"
                            }}>
                                <AntdSelect
                                    mode="multiple"
                                    value={selectedRegion}
                                    onChange={(e) => setSelectedRegion(e)}
                                    placeholder="Please select region"
                                    disabled={templates == "all"}
                                    style={{
                                        width: '100%',
                                        cursor: templates == "all" ? "not-allowed" : 'default'
                                    }}
                                >
                                    {terminalAreaListing?.map(
                                        (data, index) => {
                                            return (
                                                <option value={data?._id}>
                                                    {data?.region_name}
                                                </option>
                                            );
                                        }
                                    )}
                                </AntdSelect>
                            </div>
                        </div>
                    </RadioGroup>
                    <div
                        style={{
                            alignItems: "center",
                            justifyContent: "space-around",
                            display: "flex",
                            marginTop: "20px",
                        }}
                    >
                        <Button
                            title="Cancel"
                            type="reset"
                            className="mr-3"
                            width="108px"
                            onClick={() => {
                                setConfirm(false)
                                setSelectedRegion([])
                                setTemplates("all")
                            }}
                        />
                        <Button
                            title="Apply"
                            className="mr-3"
                            width="108px"
                            onClick={() => {
                                if (templates == "region") {
                                    if (selectedRegion.length == 0) {
                                        toast.error("Please select region.", { toastId: "est" })
                                    }
                                    else {
                                        dispatch(defaultThemeApplyAction({
                                            regions: templates == "all" ? [] : selectedRegion
                                        }))
                                        setConfirm(false)
                                    }
                                }
                                else {
                                    dispatch(defaultThemeApplyAction({
                                        regions: templates == "all" ? [] : selectedRegion
                                    }))
                                    setConfirm(false)
                                }


                            }}
                        />
                    </div>
                </div>
            </Modal>
            {/* <ConfirmationModal title="Alert" text={appConstants.defaultTheme} visible={confirm}

                button1Text="Yes"
                button2Text="No"
                onCancel={() => {
                    setConfirm(false)
                }}
                onDeleteConfirmed={() => {
                    isInternetConnected(history) &&
                        dispatch(defaultThemeApplyAction())

                    setConfirm(false)
                    setDisableButton(true)
                }}
            >

            </ConfirmationModal> */}
            <AppBar breadcrumbs={[{ route: '/terminal-board', name: "Terminal Board" }]} />
            <Grid container className={classes.buttonBarWrapper} >
                <Button title="Apply Default Theme"
                    onClick={() => {
                        setConfirm(true)
                    }}
                    mr mb />
                <Button
                    title="Edit Default Theme"
                    onClick={() => history.push({
                        pathname: "/edit-default-theme",
                        state: { terminalId: terminalAreaListing?._id }
                    })}
                    mr
                    mb
                />
            </Grid>
            <Navbar title="Terminal Board" searchable value={search} onChange={(e) => handleSearching(e)} />
            <Card className={classes.card}>
                {matches &&
                    <Box className={classes.searchBox}>
                        <NavbarSearch value={search} onValueChange={(e) => handleSearching(e)} />
                    </Box>
                }
                <Grid container>
                    <Grid className={classes.tableContainerRow}>
                        <Table
                            loading={isLoading}
                            columns={columns}
                            dataSource={terminalAreaListing}
                            onChange={handleChange}
                            searching={search.length > 0}
                        />
                    </Grid>
                </Grid>
                <PaginationBar
                    totalCount={terminalAreaPaging?.page?.totalCount}
                    count={Math.ceil(terminalAreaPaging?.page?.totalCount / limit)}
                    currentPage={terminalAreaPaging?.page?.currentPage}
                    nextPage={terminalAreaPaging?.page?.nextPage}
                    onChange={(e, page) => handlePaginationChange(e, page)}
                    prevPage={terminalAreaPaging?.page?.nextPage - limit}
                />
            </Card>
        </DashboardLayout>
    );
}
