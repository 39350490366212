import {
    faHome,
    faUsers,
    faTrophy,
    faLocationArrow,
    faSignOutAlt,
    faFileAlt,
    faImages,
    faBroadcastTower,
    faNewspaper,
    faUsersCog,
    faAtlas,
    faFlag,
    faAddressCard,
    faUserFriends,
    faParking,
    faTruckMoving
  } from "@fortawesome/free-solid-svg-icons";

export const tabs = [
    {
      icon: faHome,
      name: "Dashboard",
      path: "/dashboard",
      key: "dashboard",

    },
    {
      icon: faUsers,
      name: "User Management",
      path: "/users",
      key: "user",

    },
    {
      icon: faUserFriends,
      name: "Employee Management",
      path: "/employee",
      key: "employee",

    },
    {
      icon: faParking,
      name: "Post Management",
      path: "/postListing",
      key: "postManagement",
    },
    {
      icon: faTrophy,
      name: "Terminal Management",
      path: "/terminal-area-list",
      terminalArray: ['state', 'region', 'terminal'],
    },
    {
      icon: faImages,
      name: "Image Management",
      path: "/image-list",
      key: "image",

    },
    {
      icon: faUsersCog,
      name: "User Event Management",
      path: "/user-events",
      key: "location",

    },
    {
      icon: faNewspaper,
      name: "News Management",
      path: "/news-terminal",
      key: "news",

    },
    {
      icon: faTruckMoving,
      name: "Terminal Board",
      path: "/terminal-board",
      key: "terminalBoard",

    },
    {
      icon: faAddressCard,
      name: "Notification Management",
      path: "/notification-listing",
      key: "notification",
    },
    {
      icon: faFileAlt,
      name: "Report Post",
      path: "/report-list",
      key: "post",
    },
    {
      icon: faSignOutAlt,
      name: "Logout",
      path: "/login",
      key: "logout"
    },
  ];
