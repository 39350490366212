import React, { useState, useEffect, useRef } from 'react';
// Navigation
import { useHistory, useLocation } from 'react-router-dom'
// Styles
import 'antd/dist/antd.css'
import '../dashboard.scss'
import { Card } from 'react-bootstrap';
// Mui Components
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { TimePicker, MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
// Custom components
import DashboardLayout from '../../layouts/dashboardLayout'
import { AppBar, PaginationBar, Navbar, Table, NavbarSearch, Button } from '../../customComponents'
// Constants
import { AppImages } from '../../themes/appImages'
import { appConstants } from '../../themes/constants'
// utils methods
import { useStyles } from '../../styles'
// Redux
import { useSelector, useDispatch } from 'react-redux'
import { viewTimelineAction } from '../../store/actions/userManagementActions';
import moment from 'moment'
import { Modal } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";

var userIdFilterArray = [];
var terminalIdFilterArray = [];

export const ViewTimeline = () => {
    // Hooks declarations
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const location = useLocation()
    const dispatch = useDispatch()
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const userManagementState = useSelector(state => state.userManagementReducer)
    // Object destructuring
    const { savedUserLocationsResult, isLoading } = userManagementState
    // local state initialization
    const [search, setSearch] = useState("")
    const [offset, setOffset] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sortBy, setSortBy] = useState("")
    const [order, setOrder] = useState(null)
    const [selectedOpenTime, setSelectedOpenTime] = React.useState(null);
    const [selectedOpenTimeStore, setSelectedOpenTimeStore] = React.useState(null);
    const [openTimePicker, setOpenTimePicker] = useState(false);
    const [userIdFilterState, setUserIdFilterState] = useState("");
    const [terminalIdFilterState, setTerminalIdFilterState] = useState("");
    const [filterShow, setFilterShow] = useState(false);

    const reducerDetails = useSelector(state => state.userManagementReducer?.viewTimeline?.route_post)
    var pagingDetails = useSelector(state => state.userManagementReducer?.viewTimeline?.paging)

    // Method to fetch listing
    var userId = location?.state?.userId

    const fetchDataListing = (search, offset, limit, sortBy, order, key) => {
        if (location?.state?.userId) {
            dispatch(viewTimelineAction({
                search, offset, limit, sortBy, order, userId,
                date: selectedOpenTime ? key == "reset" ? null : moment(selectedOpenTime).format("YYYY-MM-DDT00:00:00") : null,
            }))
        } else {
            history.push('/users')
        }
    }

    //lifecycle hook
    useEffect(() => {
        userIdFilterArray = [];
        terminalIdFilterArray = [];
        document.title = appConstants.headerTitle.viewTimeline;
        fetchDataListing(search, offset, limit, sortBy, order)
    }, [])

    //Callback method to handle sorting 
    const handleChange = async (pagination, filters, sorter) => {
        //To fetch listing in ascending order
        if (sorter.order == "ascend") {
            setOrder(1)
            setSortBy(sorter.columnKey)
            fetchDataListing(search, offset, limit, sorter.columnKey, 1)
        } //To fetch listing in descending order
        else if (sorter.order === "descend") {
            setOrder(-1)
            setSortBy(sorter.columnKey)
            fetchDataListing(search, offset, limit, sorter.columnKey, -1)
        } //To fetch listing in normal order
        else {
            setOrder(null)
            setSortBy("")
            fetchDataListing(search, offset, limit, "", null)
        }
    }

    // Callback method to handle page chage
    const handlePaginationChange = (e, page) => {
        const offsetTo = (page - 1) * limit
        setOffset(offsetTo)
        fetchDataListing(search, offsetTo, limit, sortBy, order)
    }

    // Callback method to handle searching key
    const handleSearching = async (e) => {

        var tempText = e.target.value
        if (e.target.value.charAt(0) === " ") {
            tempText = e.target.value.replace(/^ +/gm, '');
        }
        setSearch(tempText)
        fetchDataListing(tempText, 0, limit, "", null)
    }

    const columns = [
        {
            title: appConstants.sr,
            dataIndex: '',
            key: 'sr',
            render: (sr, record, index) => {
                return (
                    <div >
                        {offset + index + 1}
                    </div>
                )
            },
            ellipsis: false,
        },
        {
            title: "Terminal Image",
            dataIndex: "image",
            render: (image, record) => {
                return (
                    <div className={classes.listingLogo}>
                        {
                            record?.image ?
                                <img src={appConstants.baseURL + record?.image} className={classes.imageManagementLogo} /> :
                                <img src={AppImages.logo} className={classes.imageManagementLogo} />
                        }
                    </div>
                )
            },
            ellipsis: false,
            width: 250,
        },
        {
            title: "Terminal Name",
            dataIndex: 'terminal_name',
            key: 'terminal_name',
            ellipsis: false,
            sorter: true,
            render: (terminal_name, record, index) => {
                return (
                    <div >
                        {record?.type == "manual" ? record?.name : record?.terminal_id?.terminal_name}
                    </div>
                )
            },
        },
        {
            title: appConstants.terminalType,
            dataIndex: 'type',
            key: 'type',
            ellipsis: false,
            sorter: true,
            render: (type, record, index) => {
                return (
                    <div >
                        {record?.type == "manual" ? "Manual" : "Automatic"}
                    </div>
                )
            },
        },
        {
            title: appConstants.distanceMi,
            dataIndex: 'distance',
            key: 'distance',
            ellipsis: false,
            sorter: true,
            render: (distance, record, index) => {
                return ((distance / 1000) * 0.621371).toFixed(2)
            },

            // ((distance / 1000)*0.621371).toFixed(2)
        },
        {
            title: "Created At",
            dataIndex: 'created_at',
            key: 'created_at',
            render: (created_at) => {
                return (
                    <div>
                        {moment(created_at).format('YYYY-MM-DD')}
                    </div>
                )
            },
            ellipsis: false,
            sorter: true,
        },
        // },
        {
            title: "In Time",
            dataIndex: 'start_time',
            key: 'start_time',
            render: (start_time) => {
                return (
                    <div>
                        {moment(start_time).format('HH:mm')}
                    </div>
                )
            },
            ellipsis: false,
            sorter: false,
        },
        // },
        {
            title: "Out Time",
            dataIndex: 'end_time',
            key: 'end_time',
            render: (end_time) => {
                return (
                    <div>
                        {moment(end_time).format('HH:mm')}
                    </div>
                )
            },
            ellipsis: false,
            sorter: false,
        },
        {
            key: 'x',
            title: appConstants.action,
            dataIndex: '',
            align: 'center',
            render: (record) => {
                return (
                    <Button disabled={isLoading} title={appConstants.view}
                        marginRight="0px"
                        onClick={() => history.push({
                            pathname: '/view-timeline-details',
                            state: {  // location state
                                userId: location?.state?.userId,
                                timelineID: record._id,
                            },
                        })}
                    />
                )
            },
        }

    ];

    const values = {
        userIdFilter: userIdFilterState,
        terminalIdFilter: terminalIdFilterState,
    };

    const validationSchema = Yup.object().shape({
        userIdFilter: Yup.string()

            .matches(/^[A-Za-z0-9, ]*$/, "Special characters not allowed except ','")
            .matches(/^(\S+$)/, "Space is not allowed"),
        terminalIdFilter: Yup.string()
            .matches(/^[A-Za-z0-9, ]*$/, "Special characters not allowed except ','")
            .matches(/^(\S+$)/, "Space is not allowed"),
    });

    const handleFormSubmit = (values, { setSubmitting }) => {
        setUserIdFilterState(values.userIdFilter);
        setTerminalIdFilterState(values.terminalIdFilter);
        setFilterShow(false);
        setSelectedOpenTimeStore(selectedOpenTime)
        const userIdArray = values.userIdFilter.split(",");
        userIdFilterArray = values.userIdFilter.length > 0 ? userIdArray : [];
        const terminalIdArray = values.terminalIdFilter.split(",");
        terminalIdFilterArray =
            values.terminalIdFilter.length > 0 ? terminalIdArray : [];
        // fetchDataListing(search, 0, limit, sortBy, order,);

    };

    const handleFormReset = (key) => {
        if (key == 'reset') {
            setSelectedOpenTime(null)
            setSelectedOpenTimeStore(null)
        }
        setUserIdFilterState("");
        setTerminalIdFilterState("");
        setFilterShow(false);
        userIdFilterArray = [];
        terminalIdFilterArray = [];
        fetchDataListing(search, 0, limit, sortBy, order, key);
        // setSubmitting(false)
        // dispatch(addTerminalAreaAction(values.terminalAreaName.trim()))
    };

    const closeModal = () => {
        setFilterShow(false)
    }

    return (
        <DashboardLayout>
            <AppBar breadcrumbs={[{ route: '/users', name: appConstants.userManagement }, { route: "/view-user-details", name: appConstants.userDetails, state: { userId: location?.state?.userId } }, { route: '/view-timeline', name: "View Timeline", state: { userId: location?.state?.userId } }]} />
            <Grid container className={classes.buttonBarWrapper} >
                <Button title="Filter"
                    onClick={() => {
                        setFilterShow(true);
                        setSelectedOpenTime(selectedOpenTimeStore)
                    }} mr mb
                />
            </Grid>
            <Modal
                onCancel={() => closeModal()} visible={filterShow} footer={null} centered maskClosable={false}>
                <Formik
                    enableReinitialize
                    initialValues={values}
                    validationSchema={validationSchema}
                    onSubmit={handleFormSubmit}
                >
                    {({
                        values,
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        setFieldValue,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <div style={{ zIndex: 999999 }}>
                                <span style={{ color: "#000", fontSize: 20 }}>Filter</span>
                                <div style={{ alignSelf: "center", marginTop: "15px" }}>
                                    <span style={{ color: "#000", fontSize: 14 }}>Date</span>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            style={{ width: "100%" }}
                                            ampm={true}
                                            className='date'
                                            placeholder="Select Date"
                                            name="startDate"
                                            value={selectedOpenTime}
                                            onAccept={(time) => {
                                                setOpenTimePicker(false);
                                            }}
                                            disableFuture
                                            format="yyyy-MM-dd"
                                            onClose={() => setOpenTimePicker(false)}
                                            minDate={moment('01-01-1980').add(1)}
                                            onChange={(time) =>
                                                setSelectedOpenTime(time)
                                            }
                                            open={openTimePicker}
                                            onOpen={() => setOpenTimePicker(true)}
                                            InputProps={{
                                                endAdornment: (
                                                    <FontAwesomeIcon
                                                        icon={faCalendar}
                                                        className={classes.dropdownIcon}
                                                    />
                                                )
                                            }}
                                        // minDate
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div style={{ marginTop: "20px" }}>
                                </div>
                                <div
                                    style={{
                                        alignItems: "center",
                                        justifyContent: "space-around",
                                        display: "flex",
                                        marginTop: "20px",
                                    }}
                                >
                                    <Button
                                        title="Remove"
                                        type="reset"
                                        className="mr-3"
                                        width="108px"
                                        onClick={() => {
                                            setFieldValue("userIdFilter", "");
                                            setFieldValue("terminalIdFilter", "");
                                            handleFormReset('reset');
                                        }}
                                    />
                                    <Button
                                        marginRight={"0px"}
                                        title="Apply"
                                        type="submit"
                                        // className="mr-3"
                                        width="108px"
                                        onClick={() => {
                                            setFieldValue("userIdFilter", "");
                                            setFieldValue("terminalIdFilter", "");
                                            handleFormReset('apply');
                                        }}
                                    />
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </Modal>
            {/* <Grid container className="mt-4" > */}
            <Navbar title="View Timeline" searchable value={search} onChange={(e) => handleSearching(e)} />
            <Card className={classes.card}>
                {matches &&
                    <Box className={classes.searchBox}>
                        <NavbarSearch value={search} onChange={e => handleSearching(e)} />
                    </Box>
                }
                <Grid container>
                    <Grid className={classes.tableContainerRow}>
                        <Table
                            className={classes.tableWrapper}
                            loading={isLoading}
                            columns={columns}
                            dataSource={reducerDetails}
                            onChange={handleChange}
                            searching={search.length > 0}
                        />
                    </Grid>
                </Grid>
                <PaginationBar
                    totalCount={pagingDetails?.page?.totalCount}
                    count={Math.ceil(pagingDetails?.page?.totalCount / limit)}
                    currentPage={pagingDetails?.page?.currentPage}
                    nextPage={pagingDetails?.page?.nextPage}
                    onChange={(e, page) => handlePaginationChange(e, page)}
                    prevPage={pagingDetails?.page?.nextPage - limit}
                />
            </Card>
            {/* </Grid> */}
        </DashboardLayout>
    );
}
