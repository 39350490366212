import { apiConstants as types } from '../../themes/constants'

export const getUserListAction = (search, offset, limit, sortBy, order,startDate=null,endDate=null,deviceType='') => ({
    type: types.API_GET_USER_LIST_LOAD,
    search,
    offset,
    limit,
    sortBy, 
    order,
    startDate,
    endDate,
    deviceType
})

export const getUserDetailsAction = (userId) => ({
    type: types.API_GET_USER_DETAILS_LOAD,
    userId,
})

export const editUserDetailsAction = (userId, firstName, lastName, userName, email, phoneNumber, countryCode, userType,imageUrl) => ({
    type: types.API_EDIT_USER_DETAILS_LOAD,
    userId,
    firstName,
    lastName,
    userName,
    email,
    phoneNumber,
    countryCode,
    userType,
    imageUrl
})

// View Saved locations by User Action
export const savedUserLocationsAction = (search, offset, limit, sortBy, order, userId) => ({
    type: types.API_SAVED_USER_LOCATIONS_LOAD,
    search,
    offset,
    limit,
    sortBy,
    order,
    userId,
})

// Block User Action
export const blockUserAction = (userId, isBlocked) => ({
    type: types.API_BLOCK_USER_LOAD,
    userId,
    isBlocked,
})

//timeline action
export const viewTimelineAction = (payload) => {
    return {
        type: types.API_VIEW_TIMELINE_DETAILS_LOAD,
        payload
    }
}

//timeline details action
export const getTimelineDetailsAction = (userId) => ({
    type: types.API_GET_TIMELINE_DETAILS_LOAD,
    userId
})

//  Export User
export const exportUserAction = (fileType) => ({
    type: types.API_EXPORT_USER_FILE_LOAD,
});

//  Import User
export const importUserAction = (file, fileType) => ({
    type: types.API_IMPORT_USER_FILE_LOAD,
    file,
    fileType,
});

export const getReportedUsersAction = (payload) => {
    console.log(payload)
    return {
        type: types.API_REPORTED_USERS_LIST_LOAD,
        payload
    }
}

export const getReportedUsersDetailsAction = (userId) => ({
    type: types.API_REPORTED_USERS_DETAILS_LOAD,
    userId,
})

//  Export reported User
export const exportReportedUserAction = (fileType) => ({
    type: types.API_EXPORT_REPORTED_USER_LOAD,
    fileType
});



