//React router navigation
import {
  Dashboard,
  UserListing,
  ViewSavedLocations,
  UserDetails,
  EditUserDetails,
  TerminalAreaListing,
  EditTerminalArea,
  AddTerminalArea,
  ImportTerminalArea,
  TerminalReportPost,
  TerminalReportPostDetails,
  TerminalListing,
  // AddTerminal,
  // EditTerminal,
  ImportTerminal,
  ReportPost,
  ReportPostDetails,
  ImageListing,
  AddImage
} from "../components";
import AddTerminal from "../components/terminalManagement/addTerminal"
import EditTerminal from "../components/terminalManagement/editTerminal"
import ManageWaitTime from "../components/terminalManagement/manageWaitTime"
import { Redirect } from "react-router-dom";
import { RadarEntry } from "../components/radarManagement/radarEntry";
import { TerminalNewsListing } from "../components/TerminalNewsManagement/TerminalNewsListing";
import { NewsAdd } from "../components/TerminalNewsManagement/NewsAdd";
import { NewsView } from "../components/TerminalNewsManagement/ViewNews";
import { EditNews } from "../components/TerminalNewsManagement/EditNews";
import { StateListing } from "../components/stateListing/stateListing";
import { AddStateListing } from "../components/stateListing/addStateListing";
import { ImportStateListing } from "../components/stateListing/importStateListing";
import { EditStateListing } from "../components/stateListing/editStateListing";
import { NotificationListing } from "../components/pushNotifications/notificationListing";
import { ViewPushNotifications } from "../components/pushNotifications/viewPushNotifications";
import { PushNotifications } from "../components/pushNotifications/pushNotifications";
import { ViewTimeline } from "../components/userManagement/viewTimeline";
import { ViewTimelineDetails } from "../components/userManagement/viewTimelineDetails";
import { ImportUser } from "../components/userManagement/importUser";
import { ReportedUsers } from "../components/userManagement/reportedUsers";
import { ReportedUserDetails } from "../components/userManagement/reportedUsersDetails";
import { Analytics } from "../components/dashboard/analytics";
import { EmployeeListing } from "../components/EmployeeManagement/employeeListing";
import { EmployeeDetails } from "../components/EmployeeManagement/employeeDetails";
import { EditEmployee } from "../components/EmployeeManagement/editEmployee";
import { AddEmployee } from "../components/EmployeeManagement/addEmployee";
import { EventManagement } from "../components/EmployeeManagement/eventManagement";
import localStorage from "../utils/localStorage";
import { RoutesAccess } from "./routesAccess";
import { PostListing } from "../components/Post Management/postListing";
import { AddPost } from "../components/Post Management/addPost";
import { PostDetails } from "../components/Post Management/postDetails"
import { EditPost } from "../components/Post Management/editPost";
import { CommentScreen } from "../components/Post Management/commentScreen";
import { RatingDetails } from "../components/terminalManagement/ratingDetails"
import {TerminalBoardListing} from "../components/terminalBoard/terminalBoardListing"
import {EditTerminalBoard} from "../components/terminalBoard/editTerminalBoard";
import { EditDefaultTheme } from "../components/terminalBoard/editDefaultTheme";

let permissionsData = localStorage?.getPermissions()

export default [
  {
    path: "/",
    component: () => <Redirect to="/dashboard" />,
    exact: true,
  },
  {
    path: "/dashboard",
    component: Dashboard,
  },
  {
    path: permissionsData ? RoutesAccess("user") ? "/users" : "//" : "/users",
    component: UserListing,
  },
  {
    // path:"/view-user-details/:id",
    path: "/view-saved-locations",
    component: ViewSavedLocations,
  },
  {
    // path:"/view-user-details/:id",
    path: "/view-user-details",
    component: UserDetails,
    key: "user"
  },
  {
    // path:"/view-saved-locations/:id",
    path: "/view-saved-locations",
    component: UserDetails,
    key: "user"
  },
  {
    // path:"/edit-user/:id",
    path: "/edit-user",
    component: EditUserDetails,
    key: "user"
  },
  {
    path: permissionsData ? RoutesAccess("region") ? "/terminal-area-list" : "//" : "/terminal-area-list",
    component: TerminalAreaListing,
    key: "region"
  },
  {
    // path:"/edit-terminal-area/:id",
    path: "/edit-terminal-area",
    component: EditTerminalArea,
    key: "region"
  },
  {
    path: "/add-terminal-area",
    component: AddTerminalArea,
    key: "region"
  },
  {
    path: "/import-terminal-area",
    component: ImportTerminalArea,
    key: "region"
  },
  {
    // path:"/post/:id",
    path: "/post",
    component: TerminalReportPost,
    key: "post"
  },
  {
    // path:"/post/:id",
    path: "/manageWaitTime",
    component: ManageWaitTime,
    key: "post"
  },
  {
    // path:"/view-manage-post/:id",
    path: "/view-manage-post",
    component: TerminalReportPostDetails,
    key: "post"
  },
  {
    path: permissionsData ? RoutesAccess("terminal") ? "/terminals" : "//" : "/terminals",
    component: TerminalListing,
    key: "terminal"
  },
  {
    path: "/add-terminal",
    component: AddTerminal,
    key: "terminal"
  },
  {
    // path:"/edit-terminal/:id",
    path: "/edit-terminal",
    component: EditTerminal,
    key: "terminal"
  },
  {
    path: "/import-terminal",
    component: ImportTerminal,
    key: "terminal"
  },
  {
    path: "/report-list",
    component: ReportPost,
  },
  {
    // path:"/view-report-list/:id",
    path: "/view-report-list",
    component: ReportPostDetails,
  },
  {
    path: permissionsData ? RoutesAccess("image") ?
      "/image-list" : "//" : "/image-list",
    component: ImageListing,
    key: "image"
  },
  {
    key: "image",
    path: permissionsData ? RoutesAccess("image") ?
      "/add-image" : "//" : "/add-image",
    component: AddImage
  },
  {
    path: "/user-events",
    component: RadarEntry,
    key: "location"
  },
  {
    path: permissionsData ? RoutesAccess("news") ? "/news-terminal" : "//" : "/news-terminal",
    component: TerminalNewsListing,
    key: "news"
  },
  {
    path: "/news-add",
    component: NewsAdd,
    key: "news"
  },
  {
    path: "/news-view",
    component: NewsView,
    key: "news"
  },
  {
    path: "/edit-news",
    component: EditNews,
    key: "news"
  },
  {
    path: permissionsData ? RoutesAccess("state") ? "/state-listing" : "//" : "/state-listing",
    component: StateListing,
    key: "state"
  },
  {
    path: "/add-listing",
    component: AddStateListing,
    key: "state"
  },
  {
    path: "/import-state-listing",
    component: ImportStateListing,
    key: "state"
  },
  {
    path: "/edit-state-listing",
    component: EditStateListing,
    key: "state"
  },
  {
    path: permissionsData ? RoutesAccess("notification") ? "/notification-listing" : "//" : "/notification-listing",
    component: NotificationListing,

  },
  {
    path: "/notification-view",
    component: ViewPushNotifications,

  },
  {
    path: "/push-notifications",
    component: PushNotifications,
    key: "notification"
  },
  {
    path: "/view-timeline",
    component: ViewTimeline,
    key: "user"
  },
  {
    path: "/view-timeline-details",
    component: ViewTimelineDetails,
    key: "user"
  },
  {
    path: "/import-user",
    component: ImportUser,
    key: "user"
  },
  {
    path: "/reported-users",
    component: ReportedUsers,
    key: "user"
  },
  {
    path: "/reported-users-details",
    component: ReportedUserDetails,
    key: "user"
  },
  {
    path: "/analytics",
    component: Analytics,
    key: "dashboard"
  },
  {
    path: permissionsData ? RoutesAccess("employee") ? "/employee" : "//" : "/employee",
    component: EmployeeListing,
    key: "employee"
  },
  {
    path: "/employee-details",
    component: EmployeeDetails,
    key: "employee"
  },
  {
    path: "/edit-employee",
    component: EditEmployee,
    key: "employee"
  },
  {
    path: "/add-employee",
    component: AddEmployee,
    key: "employee"
  },
  {
    path: "/event-management",
    component: EventManagement,
    key: "employee"
  },
  {
    path: "/postListing",
    component: PostListing,
    key: "post"
  },
  {
    path: "/add-post",
    component: AddPost,
    key: "post"
  },
  {
    path: "/post-details",
    component: PostDetails,
    key: "post"
  },
  {
    path: "/edit-post",
    component: EditPost,
    key: "post"
  },
  {
    path: "/comment-screen",
    component: CommentScreen,
    key: "post"
  },
  {
    path: "/rating-details",
    component: RatingDetails,
    key: "post"
  },
  {
    path: "/terminal-board",
    component: TerminalBoardListing,
    key: "terminal-board"
  },
  {
    path: "/terminal-board",
    component: TerminalBoardListing,
    key: "terminal-board"
  },
  {
    path: "/edit-terminal-board",
    component: EditTerminalBoard,
    key: "terminal-board"
  },
  {
    path: "/edit-default-theme",
    component: EditDefaultTheme,
    key: "terminal-board"
  },
];
