import React, { useState, useEffect } from 'react';
// Navigation
import { useHistory, useLocation } from 'react-router-dom'
// Styles
import 'antd/dist/antd.css'
import '../dashboard.scss'
import { Card } from 'react-bootstrap';
import { TimePicker, MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import DateFnsUtils from "@date-io/date-fns";

// Mui Components
import Grid from '@material-ui/core/Grid';
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, Select } from '@material-ui/core';
import { Modal } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import Box from '@material-ui/core/Box';
// Custom components
import DashboardLayout from '../../layouts/dashboardLayout'
import { AppBar, Button, PaginationBar, Navbar, NavbarSearch, ConfirmationModal, Table, InputField } from '../../customComponents'
// Constants
import { AppImages } from '../../themes/appImages'
import { appConstants } from '../../themes/constants'
// utils methods
import { useStyles } from '../../styles'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import localStorage from "../../utils/localStorage";
// Redux
import { useSelector, useDispatch } from 'react-redux'
import { handleNavigationStateAction, } from '../../store/actions'
import { getEmployeeListAction, getRolesListAction, generatePasswordAction, blockEmployeeAction } from '../../store/actions/employeeManagementAction';

// Global constants

const useStyles1 = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center"
    },
    datePicker: {
        '& .MuiDialog-scrollPaper': {
            display: "flex",
            justifyContent: "center"
        }
    }
}));

var selectRole1 = ""

export const EmployeeListing = () => {
    const styleProps = { searchable: true }
    // Hooks declarations
    const classes = useStyles(styleProps);
    const history = useHistory();
    const dispatch = useDispatch()
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const employeeManagementState = useSelector(state => state.EmployeeManagementReducer)

    // Global state initialization
    const { employeeListing, employeePaging, isTableDataLoading, deletedImage, roles, isLoading } = employeeManagementState
    // local state initialization

    const [search, setSearch] = useState("")
    const [offset, setOffset] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sortBy, setSortBy] = useState("")
    const [order, setOrder] = useState(null)
    const [confirm, setConfirm] = useState(false)
    const [userPassword, setUserPassword] = useState(null)
    const [selectedOpenTime, setSelectedOpenTime] = React.useState(null);
    const [openTimePicker, setOpenTimePicker] = useState(false);
    const [filterShow, setFilterShow] = useState(false);
    const [selectedCloseTime, setSelectedCloseTime] = React.useState(null);
    const [closeTimePicker, setCloseTimePicker] = useState(false);
    const [selectRole, setSelectRole] = useState("")

    const classes1 = useStyles1();
    // +++++++++++

    // Method to fetch listing
    const fetchDataListing = (search, offset, limit, sortBy, order, key) => {
        dispatch(getEmployeeListAction({ 
            search, offset, limit, sortBy, order,
            startDate: selectedOpenTime ? key == "reset" ? null : moment(selectedOpenTime).format("YYYY-MM-DDT00:00:00") : null,
            endDate: selectedCloseTime ? key == "reset" ? null : moment(selectedCloseTime).format("YYYY-MM-DDTHH:mm:ss") : null,
            role: selectRole1 && selectRole1
        }
        ))
    }

    const handleBlock = (_id, blocked) => {
        dispatch(blockEmployeeAction(_id, blocked))
    }

    let siderIndex = localStorage.getLayoutArr()
    let index = siderIndex?.findIndex((x) => x.key == "employee")
    let permissionsData = localStorage?.getPermissions()
    let employeeIndex = permissionsData?.findIndex((x) => x.name == "employee")

    //lifecycle hooks
    useEffect(() => {
        selectRole1 = ""
        document.title = appConstants.headerTitle.employeeList;
        if (permissionsData) {

            dispatch(handleNavigationStateAction(index, false))
        }
        else {
            dispatch(handleNavigationStateAction(2, false))
        }
        fetchDataListing(search, offset, limit, sortBy, order)
    }, [])

    //Callback method to handle sorting 
    const handleChange = async (pagination, filters, sorter) => {
        //To fetch listing in ascending order
        if (sorter.order == "ascend") {
            setOrder(1)
            setSortBy(sorter.columnKey)

            fetchDataListing(search, offset, limit, sorter.columnKey, 1)
        } //To fetch listing in descending order
        else if (sorter.order === "descend") {
            setOrder(-1)
            setSortBy(sorter.columnKey)
            fetchDataListing(search, offset, limit, sorter.columnKey, -1)
        } //To fetch listing in normal order
        else {
            setOrder(null)
            setSortBy("")
            fetchDataListing(search, offset, limit, "", null)
        }
    }

    // Callback method to handle page chage
    const handlePaginationChange = (e, page) => {
        const offsetTo = (page - 1) * limit
        setOffset(offsetTo)
        fetchDataListing(search, offsetTo, limit, sortBy, order)
    }

    // Callback method to handle searching key
    const handleSearching = async (e) => {
        var tempText = e.target.value
        if (e.target.value.charAt(0) === " ") {
            tempText = e.target.value.replace(/^ +/gm, '');
        }
        setSearch(tempText)
        fetchDataListing(tempText, 0, limit, "", null)
    }

    const columns = [
        {
            title: appConstants.sr,
            dataIndex: '',
            key: 'sr',
            render: (text, record, index) => (index + 1) + (employeePaging?.page?.nextPage - limit),
            ellipsis: false,
            width: 100,
        },
        {
            title: "Image",
            dataIndex: 'profile',
            key: 'profile',
            ellipsis: false,
            render: (profile) => {
                return (
                    <div className={classes.listingLogo}>
                        {
                            profile ?
                                <img src={appConstants.baseURL + profile} className={classes.imageManagementLogo} /> :
                                // <img src={image_url} className={classes.imageManagementLogo} /> :
                                <img src={AppImages.logo} className={classes.imageManagementLogo} />
                        }
                    </div>
                )
            }
        },
        {
            title: "Name",
            dataIndex: 'fullName',
            key: 'fullName',
            ellipsis: false,
            sorter: true,
            render: (fullName, record) => <div className='employee-list'>{fullName}</div>
        },
        {
            title: "Email Address",
            dataIndex: 'email',
            key: 'email',
            ellipsis: false,
            sorter: true,
        },
        {
            title: "Role Type",
            dataIndex: 'role',
            key: 'role',
            ellipsis: false,
            sorter: true,
            render: (role, record) => <div>{record?.role?.name}</div>
        },
        {
            key: "created_at",
            title: "Created Date/Time",
            dataIndex: "created_at",
            sorter: true,
            render: (created_at, record) => {
                return (
                    <div>
                        {moment(record?.created_at).format('YYYY-MM-DD hh:mm A')}
                    </div>
                )
            }
        },
        {
            title: appConstants.action,
            dataIndex: '',
            key: 'x',
            align: 'center',
            render: (text, record, index) => {
                return (
                    <Grid className={classes.buttons}>
                        <Button title={appConstants.view}
                            disabled={(permissionsData) && permissionsData[employeeIndex]?.view == false}
                            onClick={() => history.push({
                                pathname: '/employee-details',
                                state: {  // location state
                                    employeeId: record._id,
                                },
                            })}
                        />
                        <Button title={appConstants.edit}
                            disabled={(permissionsData) && permissionsData[employeeIndex]?.edit == false}
                            onClick={() => {
                                history.push({
                                    pathname: '/edit-employee',
                                    state: {  // location state
                                        employeeId: record
                                    },
                                })
                            }
                            }
                        />
                        <Button title={!record.blocked ? appConstants.block : appConstants.unblock} width="100px"
                            disabled={(permissionsData) && permissionsData[employeeIndex]?.block == false}
                            onClick={() => handleBlock(record._id, !record.blocked)}
                        />
                        <Button title={"Generate Password"}
                            disabled={(permissionsData) && permissionsData[employeeIndex]?.edit == false}
                            onClick={() => {
                                setUserPassword(record._id)
                                setConfirm(true)
                            }} />
                    </Grid>
                )
            },
        },
    ];

    const values = {
        selectRole: selectRole
    };

    const validationSchema = Yup.object().shape({
        selectRole: Yup.string()
    });

    const handleFormSubmit = (values, { setSubmitting }) => {
        setSelectRole(values.selectRole)
        setFilterShow(false);
        setSelectRole(selectRole)
        selectRole1 = values.selectRole
        fetchDataListing(search, 0, limit, sortBy, order);
    };

    const handleFormReset = (key) => {
        setSelectRole("")
        selectRole1 = ""
        setFilterShow(false);
        fetchDataListing(search, 0, limit, sortBy, order, "reset");
    };

    const closeModal = () => {
        setFilterShow(false)
    }

    return (
        <DashboardLayout>
            <ConfirmationModal title={appConstants.alert} text={appConstants.generatePassword} visible={confirm}
                button1Text='Yes'
                button2Text='No'
                onCancel={() => {
                    setConfirm(false)
                }}
                onDeleteConfirmed={() => {
                    dispatch(generatePasswordAction(userPassword))
                    setConfirm(false)
                }}
            />
            <AppBar breadcrumbs={[{ route: '/employee', name: appConstants.employeeManagement }]} />
            <Grid container className={classes.buttonBarWrapper} >
                <Button title={appConstants.addEmployee} disabled={(permissionsData) && permissionsData[employeeIndex]?.add == false} onClick={() => history.push('/add-employee')} mr mb />
                <Button
                    title="Filter"
                    onClick={() => {
                        setFilterShow(true);
                        dispatch(getRolesListAction())
                    }}
                    mr
                    mb
                />{
                    !permissionsData &&
                    <Button
                        title="Event"
                        onClick={() => history.push('/event-management')}
                        mr
                        mb
                    />
                }
            </Grid>
            <Modal
                onCancel={() => closeModal()} visible={filterShow} footer={null} centered maskClosable={false}>
                <Formik
                    enableReinitialize
                    initialValues={values}
                    validationSchema={validationSchema}
                    onSubmit={handleFormSubmit}
                >
                    {({
                        values,
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <div style={{ zIndex: 999999 }}>
                                <span style={{ color: "#000", fontSize: 20 }}>{appConstants.filter}</span>
                                <div
                                    style={{ alignSelf: "center", marginTop: "15px" }}
                                >
                                    <span style={{ color: "#000", fontSize: 14 }}>
                                        {appConstants.startDate}
                                    </span>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} classes={classes1.root}>
                                        <DatePicker
                                            style={{ width: "100%" }}
                                            ampm={true}
                                            className='date'
                                            placeholder="Select Start Date"
                                            name="startDate"
                                            value={selectedOpenTime}
                                            onAccept={(time) => {
                                                setOpenTimePicker(false);
                                            }}
                                            disableFuture
                                            format="yyyy-MM-dd"
                                            onClose={() => setOpenTimePicker(false)}
                                            onChange={(time) =>
                                                setSelectedOpenTime(time)
                                            }
                                            open={openTimePicker}
                                            onOpen={() => setOpenTimePicker(true)}
                                            InputProps={{
                                                endAdornment: (
                                                    <FontAwesomeIcon
                                                        icon={faCalendar}
                                                        className={classes.dropdownIcon}
                                                    />
                                                )
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div
                                    style={{ alignSelf: "center", marginTop: "15px" }}
                                >
                                    <span style={{ color: "#000", fontSize: 14 }}>
                                        {appConstants.endDate}
                                    </span>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} classes={classes1.root}>
                                        <DatePicker
                                            style={{ width: "100%" }}
                                            ampm={true}
                                            placeholder="Select End Date"
                                            name="endDate"
                                            className='date'
                                            minDate={moment(selectedOpenTime).add(0, "days")}
                                            value={selectedCloseTime}
                                            onAccept={(time) => {
                                                setCloseTimePicker(false);
                                            }}
                                            format="yyyy-MM-dd"
                                            onClose={() => setCloseTimePicker(false)}
                                            disableFuture
                                            onChange={(time) =>
                                                setSelectedCloseTime(time)
                                            }
                                            open={closeTimePicker}
                                            onOpen={() => setCloseTimePicker(true)}
                                            InputProps={{
                                                endAdornment: (
                                                    <FontAwesomeIcon
                                                        icon={faCalendar}
                                                        className={classes.dropdownIcon}
                                                    />
                                                )
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                {/* <div
                                    style={{ alignSelf: "center", marginTop: "15px" }}
                                >
                                    <span style={{ color: "#000", fontSize: 14 }}>
                                        {appConstants.role}
                                    </span>
                                    <Select
                                        native
                                        id="selectRole"
                                        className={classes.selectInput}
                                        value={selectRole}
                                        onChange={(e) => setSelectRole(e.target.value)}
                                        inputProps={{
                                            name: 'selectRole',
                                            id: 'selectRole',
                                        }}
                                        IconComponent={() => <FontAwesomeIcon icon={faChevronDown} className={classes.dropdownIcon} />}
                                    >
                                        <option value="">{appConstants.selectType}</option>

                                        {roles.length > 0 && roles?.map(
                                            (time, index) => {
                                                return (
                                                    <option value={time?._id}>
                                                        {time?.name}
                                                    </option>
                                                );
                                            }
                                        )}
                                    </Select>
                                </div> */}
                                <div style={{ marginTop: "20px" }}>
                                </div>
                                <div
                                    style={{
                                        alignItems: "center",
                                        justifyContent: "space-around",
                                        display: "flex",
                                        marginTop: "20px",
                                    }}
                                >
                                    <Button
                                        title="Remove"
                                        type="reset"
                                        className="mr-3"
                                        width="108px"
                                        onClick={() => {
                                            setSelectedCloseTime(null)
                                            setSelectedOpenTime(null)
                                            handleFormReset();
                                        }}
                                    />
                                    <Button
                                        title="Apply"
                                        type="submit"
                                        className="mr-3"
                                        width="108px"
                                    />
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </Modal>
            <Navbar title={appConstants.employeeManagement} searchable value={search} onChange={(e) => handleSearching(e)} />
            <Card className={classes.card}>
                {matches &&
                    <Box className={classes.searchBox}>
                        <NavbarSearch value={search} onValueChange={(e) => handleSearching(e)} />
                    </Box>
                }
                <Grid container>
                    <Grid className={classes.tableContainerRow}>
                        <Table
                            loading={isLoading}
                            columns={columns}
                            dataSource={employeeListing}
                            onChange={handleChange}
                            searching={search.length > 0}
                        />
                    </Grid>
                </Grid>
                <PaginationBar
                    totalCount={employeePaging?.page?.totalCount}
                    count={Math.ceil(employeePaging?.page?.totalCount / limit)}
                    currentPage={employeePaging?.page?.currentPage}
                    nextPage={employeePaging?.page?.nextPage}
                    onChange={(e, page) => handlePaginationChange(e, page)}
                    prevPage={employeePaging?.page?.nextPage - limit}
                />
            </Card>
        </DashboardLayout>
    );
}
