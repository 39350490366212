import { apiConstants as types } from '../../themes/constants'
import localStorage from '../../utils/localStorage'
const initialState = {
    isLoading: false,
    isTableDataLoading: false,
    result: {},
    terminalPagination: [],
    terminalListing: [],
    terminalDetails: {},
    deletedTerminal: null,
    errorState: null,
    centerPoint: null,
    circlezoom: 64,
    success: 0,
    ratingListing: [],
    ratingPaging: [],
    ratingStatus: {},
    ratingStatusNew: false,
    waitTimeData: {},
    waitTimeDataLoader: false,
    waitTimeDataSuccess: false,
    waitTimeUpdateLoader: false,
    waitTimeUpdateSuccess: false
}

export const terminalManagementReducer = (state = initialState, action) => {
    switch (action.type) {

        // Terminal Area Management Cases
        // Clear State Case 
        case types.CLEAR_STATE:
            if (action.key === "clearTerminalDetailsState") {
                return {
                    ...state, terminalDetails: {},
                }
            } else {
                return { ...state }
            }

        // Get user list
        case types.API_GET_TERMINAL_LIST_LOAD:
            return { ...state, isLoading: true, errorState: null, }
        case types.API_GET_TERMINAL_LIST_SUCCESS:
            return { ...state, isLoading: false, terminalListing: action.result.list_terminals, terminalPaging: action.result.paging, terminalId: action.result.list_terminals[0]._id }

        // Add Terminal Area list
        case types.API_ADD_TERMINAL_LOAD:
            return { ...state, isLoading: true, errorState: null, }
        case types.API_ADD_TERMINAL_SUCCESS:
            return { ...state, isLoading: false }

        // Get terminal details
        case types.API_GET_TERMINAL_DETAILS_LOAD:
            return { ...state, isTableDataLoading: true, errorState: null }
        case types.API_GET_TERMINAL_DETAILS_SUCCESS:
            const polygonDataString = action.result.terminal_details.polygon_area
            var centerPoint = null
            if (polygonDataString === "undefined" || polygonDataString === undefined || polygonDataString === null || polygonDataString === "null") {
                centerPoint = { lat: 41.3557, lng: 2.1666 }
            }
            else {
                const polygonData = JSON.parse(polygonDataString)
                centerPoint = { lat: polygonData[0].latitude.toFixed(3), lng: polygonData[0].longitude.toFixed(3) }
            }
            return { ...state, isTableDataLoading: false, terminalDetails: action.result.terminal_details, centerPoint: centerPoint }
        case types.HANDLE_CLEAR_MAP_CENTER_POINT:
            return { ...state, centerPoint: null }

        // Edit terminal area
        case types.API_EDIT_TERMINAL_LOAD:
            return { ...state, isLoading: true, errorState: null, }
        case types.API_EDIT_TERMINAL_SUCCESS:
            return { ...state, isLoading: false, success: 1 }

        // Delete terminal area
        case types.API_DELETE_TERMINAL_LOAD:
            return { ...state, isLoading: true, errorState: null, }
        case types.API_DELETE_TERMINAL_SUCCESS:
            let indexToDelete = state.terminalListing.findIndex(value => value._id === action.terminalId)
            state.terminalListing.splice(indexToDelete, 1)
            return { ...state, isLoading: false, terminalListing: [...state.terminalListing], deletedTerminal: action.terminalId }
        //Delete geofence area
        case types.API_DELETE_GEOFENCE1_LOAD:
            state.terminalDetails.geoFence = action.payload
            return { ...state, isLoading: false, errorState: null }

        // Import file area
        case types.API_IMPORT_FILE_LOAD:
            return { ...state, isLoading: true, errorState: null, }
        case types.API_IMPORT_FILE_SUCCESS:
            return { ...state, isLoading: false }

        // Export file area
        case types.API_EXPORT_FILE_LOAD:
            return { ...state, isLoading: true, errorState: null, }
        case types.API_EXPORT_FILE_SUCCESS:
            return { ...state, isLoading: false }

        // ratings list
        case types.API_GET_RATING_LIST_LOAD:
            return { ...state, isLoading: true, errorState: null, }
        case types.API_GET_RATING_LIST_SUCCESS:
            return {
                ...state, isLoading: false, ratingListing:
                    action.result.list, ratingPaging: action.result.paging
            }

        // hide and delete ratings
        case types.API_HIDE_DELETE_RATINGS_LOAD:
            return { ...state, isLoading: true, errorState: null, }
        case types.API_HIDE_DELETE_RATINGS_SUCCESS:
            let ratingListingValue = state.ratingListing[action.index].is_hide
            ratingListingValue = action.status
            return { ...state, isLoading: false, ratingStatus: action.result, ratingStatusNew: ratingListingValue }

        //Terminal area management failed cases
        case types.API_TERMINAL_MANAGEMENT_FAILED:
            return { ...state, isLoading: false, isTableDataLoading: false, terminalListing: [], terminalPaging: {}, errorState: action.errorState, }
        case types.API_TERMINAL_MANAGEMENT_ERROR:
            return { ...state, isLoading: false, isTableDataLoading: false, terminalListing: [], terminalPaging: {}, errorState: action.errorState, }

        case types.UPDATE_WAIT_TIME_INITIATE:
            return {
                ...state,
                waitTimeUpdateLoader: true,
            }
        
        case types.UPDATE_WAIT_TIME_SUCCESS:
            return {
                ...state,
                waitTimeUpdateLoader: false,
                waitTimeUpdateSuccess: true
            }

        case types.UPDATE_WAIT_TIME_FAILURE:
            return {
                ...state,
                waitTimeUpdateLoader: false
            }

        case types.GET_WAIT_TIME_INITIATE:
            return {
                ...state,
                waitTimeDataLoader: true,
            }
        
        case types.GET_WAIT_TIME_SUCCESS:
            return {
                ...state,
                waitTimeData: action.payload,
                waitTimeDataLoader: false,
                waitTimeDataSuccess: true
            }

        case types.GET_WAIT_TIME_FAILURE:
            return {
                ...state,
                waitTimeDataLoader: false
            }

        //clear success
        case 'CLEAR_SUCCESS':
            return {
                ...state, success: 0, isLoading: false
            }
        //Default case
        default:
            return { ...state }

    }
}