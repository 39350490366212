import { createStore, applyMiddleware, compose } from "redux";
import { createLogger } from 'redux-logger'
import rootReducer from "./reducers";
// Dev tools
import { composeWithDevTools } from 'redux-devtools-extension';

import createSagaMiddleware from "redux-saga";
import rootSaga from "./saga";
const sagaMiddleware = createSagaMiddleware();

const logger = createLogger({
  duration: true,
  timestamp: true,
  // diff: true,
})

export const configureStore = () => {

  const store = createStore(rootReducer, composeWithDevTools(
    applyMiddleware(sagaMiddleware, logger),
  ));

  sagaMiddleware.run(rootSaga);
  return store;
}
