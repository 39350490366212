import React from "react";
import { InputAdornment, makeStyles, TextField } from "@material-ui/core";
import clsx from "clsx";
import { useStyles } from "./styles";

const InputField = (props) => {
  const classes = useStyles();

  const {
    id,
    error,
    helperText,
    placeholder,
    name,
    onBlur,
    onChange,
    type,
    value,
    className,
    maxLength,
    autoComplete,
    disabled,
    multiline,
    my,
    textArea,
    onKeyDown,
    inputComponent,
    adornment
  } = props;

  return (
    <TextField
      id={id}
      placeholder={placeholder}
      error={error}
      noValidate
      helperText={helperText}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      onKeyDown={onKeyDown}
      type={type}
      value={value}
      fullWidth
      onPaste={props.onPaste}
      onCopy={props.onCopy}
      onContextMenu={props.onContextMenu}
      // classes={{root:classes.my}}
      className={clsx([classes.input, my && classes.my])}
      multiline={(textArea || multiline) && true}
      rows={textArea && 3}
      autoComplete={autoComplete ? autoComplete : ""}
      disabled={disabled}
      inputProps={{ maxLength: maxLength && maxLength }}
      inputComponent={inputComponent}
      InputProps={{
        startAdornment: adornment && <InputAdornment className={classes.adornment} position="end">m</InputAdornment>,
      }}
    />
  );
};

export default InputField;
