import React, { useState, useEffect } from 'react';
// Styles
import 'antd/dist/antd.css'
import '../dashboard.scss'
// antd
import { Tooltip, Spin } from 'antd';
import { Card, Table as BSTable } from 'react-bootstrap';
// Mui ComponentsfaUpload
import { Grid, FormHelperText, Input } from '@material-ui/core';
// Custom components
import DashboardLayout from '../../layouts/dashboardLayout'
import { Navbar, AppBar, Button } from '../../customComponents'
// Constants
import { AppImages } from '../../themes/appImages'
import { validateImageRatio } from '../../utils/helpers'
import { appConstants, ValidationConstants } from '../../themes/constants'
// utils methods
import { useStyles } from '../../styles'
// Redux
import { useSelector, useDispatch } from 'react-redux'
import { addImageAction } from '../../store/actions'
// Global constants
import { toast } from 'react-toastify';

export const AddImage = () => {
    // Hooks declarations
    const classes = useStyles();
    const dispatch = useDispatch()
    const [image, setImage] = useState('')
    const [imageName, setImageName] = useState('')
    const [imageUrl, setImageUrl] = useState('')
    const [errors, setErrors] = useState(null)
    const [errorName, setErrorName] = useState(null)
    const state = useSelector(state => state.imageManagementReducer)
    const { isLoading } = state
    // Lifecycle Hooks  
    useEffect(() => {
        document.title = appConstants.headerTitle.addImage;
    }, [])

    // Remove selected file on error state updation
    useEffect(() => {
        setImage('')
        setImageName('')
        setImageUrl(null)
    }, [state.errorState])

    const imageValidation = (value) => {
        let fileTypes = ["jpg", "jpeg", "svg", "png"]
       
        return (fileTypes.indexOf(value) > -1)
    }

    const handleImageChange = (event) => {
      
        // setErrorTrue(true)
        if (event.target.files && event.target.files[0]) {
            let uploadFile = event.target.files[0]
            let splitName1 = uploadFile.name.split(".").pop().toLowerCase()
            let isSuccess = imageValidation(splitName1)
            if (isSuccess) {
                validateImageRatio(event.target.files[0])
                    .then(() => {
                        let splitName = event.target.files[0]?.name.split('.')
                        setImageUrl(event.target.files[0]);
                        setImageName(splitName[0])
                        // setImageName(splitName[0]);
                        let reader = new FileReader();
                        reader.onload = (e) => {
                            setImage(e.target.result);
                        };
                        reader.readAsDataURL(event.target.files[0]);
                        return
                    })
                    .catch(() => {
                        toast.error(ValidationConstants.image.invalidRatioOneByOne)
                        return false
                    }
                    );
            }
            else {
                toast.error("Only .jpg, .jpeg, .png and .svg format images are allowed.")
            }
        }
    };

    const handleImageNameChange = (e) => {
        if (image.length <= 0) {
            setErrors(ValidationConstants.image.empty)
        }
        else if (e.target.value.trim().length === 0) {
            setErrorName(ValidationConstants.imageName.empty)
        }
        setImageName(e.target.value)
    }
    const handleSubmit = () => {
        if (!imageUrl) {
            setErrors(ValidationConstants.image.empty)
        } else if (imageName.trim().length === 0) {
            toast.error(ValidationConstants.imageName.invalid)
            setErrorName(ValidationConstants.imageName.empty)
        } else {
            setErrors(null)
            setErrorName(null)
            dispatch(addImageAction(imageUrl, imageName.trim()))
        }
    }

    return (
        <Spin
            size="large"
            spinning={isLoading}
            wrapperClassName={classes.tableSpinner}
            className={classes.antSpin}
        >
            <DashboardLayout>
                <AppBar breadcrumbs={[{ route: '/image-list', name: appConstants.imageManagement }, { route: '/add-image', name: appConstants.addImage }]} />
                <Navbar title={appConstants.addImage} />
                <Card className={classes.card}>
                    <Grid container>
                        <Grid className={classes.tableContainerRow}>
                            <form>
                                <BSTable striped bordered >
                                    <tbody>
                                        <tr>
                                            <td className={classes.rowKey}>
                                                {appConstants.uploadImage}
                                            </td>
                                            <td className={classes.rowValue}>
                                                <div className={classes.inputWrapper}>
                                                    <input accept=".png,.jpg,.jpeg" className={classes.uploaderInput} 
                                                    id="icon-button-file" 
                                                    type="file"
                                                     onChange={(e)=>handleImageChange(e)}
                                                    onClick={(event)=> { 
                                                        event.target.value = null
                                                   }}
                                                    /> 
                                                    <label htmlFor="icon-button-file" className={classes.imageManagementUploaderlabel}>
                                                        <Tooltip title={appConstants.tooltip.uploadImage} placement="right"> 
                                                            {image ? <img src={image} className={classes.terminalImage} /> :
                                                                <img
                                                                onChange={handleImageChange}
                                                                src={AppImages.addPhoto} className={classes.terminalImage} />}
                                                        </Tooltip>
                                                    </label>
                                                    {image.length <= 0 && errors !== null ? <FormHelperText style={{ marginBottom: 12, }} error id="component-error-text">{errors}</FormHelperText> : true}
                                                    <Input
                                                        className={classes.fileSuccess}
                                                        defaultValue={imageName}
                                                        value={imageName}
                                                        onChange={(e) => handleImageNameChange(e)}
                                                        fullWidth inputProps={{ 'aria-label': 'imageName' }}
                                                    />
                                                    {imageName.length <= 0 && errorName !== null ? <FormHelperText error id="component-error-text">{errorName}</FormHelperText> : true}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={classes.rowKey}></td>
                                            <td className={classes.rowValue}>
                                                <Button title="Upload" onClick={() => handleSubmit()} />
                                            </td>
                                        </tr>
                                    </tbody>
                                </BSTable>
                            </form>
                        </Grid>
                    </Grid>
                </Card>
            </DashboardLayout>
        </Spin>
    );
}

