import React, { useState, useEffect } from 'react';
// Navigation
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom'
// Styles
import 'antd/dist/antd.css'
import '../dashboard.scss'
// antd
import { Breadcrumb, Input, Space } from 'antd';
import { Card, Container, Row, Col } from 'react-bootstrap';
import localStorage from "../../utils/localStorage";
// Mui Components
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
// Custom components
import DashboardLayout from '../../layouts/dashboardLayout'
import { AppBar, Button, PaginationBar, Navbar, NavbarSearch, ConfirmationModal, Table } from '../../customComponents'
// Constants
import { AppImages } from '../../themes/appImages'
import { Colors } from '../../themes/colors'
import { appConstants } from '../../themes/constants'
// utils methods
import cl from '../../utils/cl'
import { useStyles } from '../../styles'
// Redux
import { useSelector, useDispatch } from 'react-redux'
import { getTerminalAreaListAction, handleNavigationStateAction, deleteTerminalAreaAction, importFileAction, exportFileAction, } from '../../store/actions'
// Global constants


export const TerminalAreaListing = () => {
    const styleProps = { searchable: true }
    // Hooks declarations
    const classes = useStyles(styleProps);
    const history = useHistory();
    const location = useLocation()
    const dispatch = useDispatch()
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const terminalAreaManagementState = useSelector(state => state.terminalAreaManagementReducer)
    // Global state initialization
    const { terminalAreaListing, terminalAreaPaging, isLoading, deletedTerminal } = terminalAreaManagementState

    // local state initialization
    const [search, setSearch] = useState("")
    const [offset, setOffset] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sortBy, setSortBy] = useState("")
    const [order, setOrder] = useState(null)
    const [confirm, setConfirm] = useState(false)
    const [terminalAreaToDelete, setTerminalAreaToDelete] = useState(null)
    const [disableButton, setDisableButton] = useState()
    let permissionsData = localStorage?.getPermissions()
    let employeeIndex = permissionsData?.findIndex((x) => x.name == "region")
    let siderIndex = localStorage.getLayoutArr()
    let siderNestedIndex = localStorage.getNestedLayoutArr()

    // Method to fetch listing
    const fetchDataListing = (search, offset, limit, sortBy, order) => {
        dispatch(getTerminalAreaListAction(search, offset, limit, sortBy, order))
    }

    //lifecycle hooks
    useEffect(() => {
        document.title = appConstants.headerTitle.terminalAreaListing;
        if (permissionsData) {
            let index = siderIndex?.findIndex((x) => x.name == "Terminal Management")
            let nestedIndex = siderNestedIndex?.findIndex((x) => x.key == "region")
            dispatch(handleNavigationStateAction(index, false))
            setTimeout(() => {
                dispatch(handleNavigationStateAction(nestedIndex, true))
            }, 1000);
        }
        else {
            dispatch(handleNavigationStateAction(1, true))
        }
        fetchDataListing(search, offset, limit, sortBy, order)
    }, [])

    useEffect(() => {
        if (deletedTerminal !== null) {
            fetchDataListing(search, 0, limit, sortBy, order)
        }
    }, [deletedTerminal])

    //Callback method to handle sorting 
    const handleChange = async (pagination, filters, sorter) => {
        //To fetch listing in ascending order
        if (sorter.order == "ascend") {
            setOrder(1)
            setSortBy(sorter.columnKey)
            fetchDataListing(search, offset, limit, sorter.columnKey, 1)
        } //To fetch listing in descending order
        else if (sorter.order === "descend") {
            setOrder(-1)
            setSortBy(sorter.columnKey)
            fetchDataListing(search, offset, limit, sorter.columnKey, -1)
        } //To fetch listing in normal order
        else {
            setOrder(null)
            setSortBy("")
            fetchDataListing(search, offset, limit, "", null)
        }
    }

    // Callback method to handle page chage
    const handlePaginationChange = (e, page) => {
        const offsetTo = (page - 1) * limit
        setOffset(offsetTo)
        fetchDataListing(search, offsetTo, limit, sortBy, order)
    }

    // Callback method to handle searching key
    const handleSearching = async (e) => {
        var tempText = e.target.value
        if (e.target.value.charAt(0) === " ") {
            tempText = e.target.value.replace(/^ +/gm, '');
        }
        setSearch(tempText)
        fetchDataListing(tempText, 0, limit, "", null)
    }
    // +++++++++++
    
    const columns = [
        {
            title: appConstants.sr,
            dataIndex: '',
            key: 'sr',
            render: (text, record, index) => (index + 1) + (terminalAreaPaging?.page?.nextPage - limit),
            ellipsis: false,
            width: 100,
        },
        {
            title: 'State Name',
            dataIndex: 'state',
            key: 'state',
            ellipsis: false,
            sorter: true,
            render: (state, record) => {
                return (
                    <div>
                        {record?.state?.state_name}
                    </div>
                )
            }
        },
        {
            title: 'Region Name',
            dataIndex: 'region_name',
            key: 'region_name',

            ellipsis: false,
            sorter: true,
            render: (region_name, record) => {
                return (
                    <div>
                        {record?.region_name}
                    </div>
                )
            }
        },
        {
            title: appConstants.action,
            dataIndex: '',
            key: 'x',
            width: 100,
            align: 'center',
            render: (text, record, index) => {
                return (
                    <Grid className={classes.buttons}>
                        <Button title={appConstants.edit} disabled={(permissionsData) && permissionsData[employeeIndex]?.edit == false} onClick={() => history.push({
                            pathname: "/edit-terminal-area",
                            state: { terminalName: record.region_name, terminalId: record._id }
                        })} />
                        <Button title={appConstants.delete}
                            disabled={(permissionsData) && permissionsData[employeeIndex]?.delete == false}
                            onClick={() => {
                                setConfirm(true)
                                setTerminalAreaToDelete(record._id)
                                setDisableButton(false)
                            }} />
                    </Grid>
                )
            },
        },
    ];

    return (
        <DashboardLayout>
            <ConfirmationModal title="Alert" text={appConstants.deleteRegionArea} visible={confirm}
                disable={disableButton}
                onCancel={() => {
                    setConfirm(false)
                }}
                onDeleteConfirmed={() => {
                    dispatch(deleteTerminalAreaAction(terminalAreaToDelete))
                    setConfirm(false)
                    setDisableButton(true)
                }}
            />
            <AppBar breadcrumbs={[{ route: '/terminal-area-list', name: "Region Listing" }]} />
            <Grid container className={classes.buttonBarWrapper} >
                <Button title="Add Region" disabled={(permissionsData) && permissionsData[employeeIndex]?.add == false} onClick={() => history.push('/add-terminal-area')} mr mb />
                <Button title="Export Region" onClick={() => {
                    dispatch(exportFileAction("terminalArea"))
                }} mr mb />
                <Button title="Import Region" disabled={(permissionsData) && permissionsData[employeeIndex]?.edit == false} onClick={() => history.push('/import-terminal-area')} mr mb />
            </Grid>
            <Navbar title="Region Listing" searchable value={search} onChange={(e) => handleSearching(e)} />
            <Card className={classes.card}>
                {matches &&
                    <Box className={classes.searchBox}>
                        <NavbarSearch value={search} onValueChange={(e) => handleSearching(e)} />
                    </Box>
                }
                <Grid container>
                    <Grid className={classes.tableContainerRow}>
                        <Table
                            loading={isLoading}
                            columns={columns}
                            dataSource={terminalAreaListing}
                            onChange={handleChange}
                            searching={search.length > 0}
                        />
                    </Grid>
                </Grid>
                <PaginationBar
                    totalCount={terminalAreaPaging?.page?.totalCount}
                    count={Math.ceil(terminalAreaPaging?.page?.totalCount / limit)}
                    currentPage={terminalAreaPaging?.page?.currentPage}
                    nextPage={terminalAreaPaging?.page?.nextPage}
                    onChange={(e, page) => handlePaginationChange(e, page)}
                    prevPage={terminalAreaPaging?.page?.nextPage - limit}
                />
            </Card>
        </DashboardLayout>
    );
}
