import { apiConstants as types } from "../../themes/constants";

// Get terminal Action
export const getTerminalListAction = (
  search,
  offset,
  limit,
  sortBy,
  order
) => ({
  type: types.API_GET_TERMINAL_LIST_LOAD,
  search,
  offset,
  limit,
  sortBy,
  order,
});

// Add terminal Action
export const addTerminalAction = (
  terminalImage,
  terminalUrlArray,
  terminalArea,
  terminalName,
  locationCategory,
  terminalCountry,
  terminalAddress,
  terminalLatitude,
  terminalLongitude,
  terminalCity,
  terminalState,
  zipCode,
  terminalRadius,
  terminalOpenTime,
  terminalCloseTime,
  fullTime,
  mapLogoFile,
  terminalBoardLogoFile,
  discription,
  showPolygonData,
  geoFence,
  zoom,
  templates,
  phoneNumbers,
  extraTimeArray,
  about,
  showTerminal
) => ({
  type: types.API_ADD_TERMINAL_LOAD,
  terminalImage,
  terminalUrlArray,
  terminalArea,
  terminalName,
  locationCategory,
  terminalCountry,
  terminalAddress,
  terminalLatitude,
  terminalLongitude,
  terminalCity,
  terminalState,
  zipCode,
  terminalRadius,
  terminalOpenTime,
  terminalCloseTime,
  fullTime,
  mapLogoFile,
  terminalBoardLogoFile,
  discription,
  showPolygonData,
  geoFence,
  zoom,
  templates,
  phoneNumbers,
  extraTimeArray,
  about,
  showTerminal
});

// Get terminal details Action
export const getTerminalDetailsAction = (terminalId) => ({
  type: types.API_GET_TERMINAL_DETAILS_LOAD,
  terminalId,
});

// Get terminal details Action
export const clearMapCenterPointAction = () => ({
  type: types.HANDLE_CLEAR_MAP_CENTER_POINT,
});

// Edit terminal Action
export const editTerminalAction = (
  terminalId,
  terminalImage,
  terminalUrlArray,
  terminalArea,
  terminalName,
  locationCategory,
  terminalCountry,
  terminalAddress,
  terminalLatitude,
  terminalLongitude,
  terminalCity,
  terminalState,
  zipCode,
  terminalRadius,
  terminalOpenTime,
  terminalCloseTime,
  fullTime,
  mapLogoFile,
  terminalPolygon,
  terminalBoardLogoFile,
  discription,
  geoFence,
  zoom,
  templates,
  phnNumberArray,
  extraTimeArray,
  about,
  showTerminal
) => ({
  type: types.API_EDIT_TERMINAL_LOAD,
  terminalId,
  terminalImage,
  terminalUrlArray,
  terminalArea,
  terminalName,
  locationCategory,
  terminalCountry,
  terminalAddress,
  terminalLatitude,
  terminalLongitude,
  terminalCity,
  terminalState,
  zipCode,
  terminalRadius,
  terminalOpenTime,
  terminalCloseTime,
  fullTime,
  mapLogoFile,
  terminalPolygon,
  terminalBoardLogoFile,
  discription,
  geoFence,
  zoom,
  templates,
  phnNumberArray,
  extraTimeArray,
  about,
  showTerminal
});

//  Delete terminal Action
export const deleteTerminalAction = (terminalId) => ({
  type: types.API_DELETE_TERMINAL_LOAD,
  terminalId,
});

//  Import terminal Action
export const importFileAction = (file, fileType) => ({
  type: types.API_IMPORT_FILE_LOAD,
  file,
  fileType,
});

//  Export terminal Action
export const exportFileAction = (fileType) => ({
  type: types.API_EXPORT_FILE_LOAD,
  fileType,
});

//delete geofence area
export const deleteGeofence1 = (payload) => ({
  type: types.API_DELETE_GEOFENCE1_LOAD,
  payload
})
export const successClear = () => ({
  type: 'CLEAR_SUCCESS'
})

//rating listing
export const getRatingListAction = (
  payload
) => ({
  type: types.API_GET_RATING_LIST_LOAD,
  payload
});

// hide and delete ratings

export const hideDeleteRatingApi = (payload) => {
  return (
    {
      type: types.API_HIDE_DELETE_RATINGS_LOAD,
      payload
    }
  )
}

// add and get wait time

export const getWaitTimeInitiate = (payload) => ({
  type: types.GET_WAIT_TIME_INITIATE,
  payload
})

export const getWaitTimeSuccess = (payload) => ({
  type: types.GET_WAIT_TIME_SUCCESS,
  payload
})

export const getWaitTimeFailure = (payload) => ({
  type: types.GET_WAIT_TIME_FAILURE,
  payload
})

export const addWaitTimeInitiate = (payload) => ({
  type: types.UPDATE_WAIT_TIME_INITIATE,
  payload
})

export const addWaitTimeSuccess = (payload) => ({
  type: types.UPDATE_WAIT_TIME_SUCCESS,
  payload
})

export const addWaitTimeFailure = (payload) => ({
  type: types.UPDATE_WAIT_TIME_FAILURE,
  payload
})