import React, { useState, useEffect, useRef } from "react";
// Styles
import "antd/dist/antd.css";
import "../dashboard.scss";
import { useStyles } from "../../styles";
// antd
import { Card } from "react-bootstrap";
import { Button } from "../../customComponents";
// Mui Components
import { Grid, Select } from "@material-ui/core";
// Custom components
import DashboardLayout from "../../layouts/dashboardLayout";
import { AppBar, } from "../../customComponents";
import { Tooltip } from 'antd';
// Constants
import { AppImages } from "../../themes/appImages";
import { appConstants } from "../../themes/constants";
import { saveAs } from 'file-saver'
import moment from 'moment'
// Redux
import { useSelector, useDispatch } from "react-redux";
import { handleNavigationStateAction } from "../../store/actions";
import { Tabs } from 'antd';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import BarChartjs from "../../customComponents/barChart";
import { AnalyticsDataAction } from "../../store/actions/dashboardAction";
import { AnalyticsRegionDataAction, emptyregionTerminalData, updateAnalyticsRegionDataAction, AnalyticsTerminalDataAction, updateAnalyticsTerminalDataAction, regionTerminalDataAction } from "../../store/actions/dashboardAction";
import { Popover } from 'antd';

const { TabPane } = Tabs;

export const Analytics = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const analyticsData = useSelector(state => state.dashboardReducer)
    const { regiondata, regionterminallistingData, region_terminal, newdata1, updateData, updateTerminalData, regionListing, regionterminalList, idTerminal, terminalWeeks } = analyticsData
    const apiData = useSelector(state => state.dashboardReducer.data)
    const newData = useSelector(state => state.dashboardReducer.months)
    const [templates, setTemplates] = useState("monthly")
    const [showMonths, setShowMonths] = useState(false)
    const [years, setYears] = useState(moment().year())
    const [months, setMonths] = useState(moment().format("M"))
    const [region, setRegion] = useState(null)
    const [terminal, setTerminal] = useState(null)
    const [regionTerminal, setRegionTerminal] = useState(null)
    const [tabValue, setTabValue] = useState("1")
    const [type, setType] = useState("Bar")
    const [type1, setType1] = useState("Bar")
    const [type2, setType2] = useState("Bar")
    const [type3, setType3] = useState("Bar")
    const [type4, setType4] = useState("Bar")
    const [type5, setType5] = useState("Bar")
    const [type6, setType6] = useState("Bar")
    const [type7, setType7] = useState("Bar")
    const [type8, setType8] = useState("Bar")

    useEffect(() => {
        document.title = appConstants.headerTitle.analytics;
        dispatch(handleNavigationStateAction(0, false));
        if (templates == "monthly") {
            dispatch(AnalyticsDataAction({ type: templates, year: years }))
        }
        else {
            dispatch(AnalyticsDataAction({ type: templates, month: months, year: years }))
        }
        dispatch(AnalyticsRegionDataAction())
    }, []);

    useEffect(() => {
        setRegion(regiondata)
        setRegionTerminal(regiondata)
    }, [regiondata])

    useEffect(() => {
        setRegionTerminal(region_terminal)
    }, [regionterminallistingData, region_terminal])

    useEffect(() => {
        setTerminal(idTerminal)
    }, [idTerminal])

    //selection of tab panes
    const onTabClicked = (key) => {
        console.log(key,"key")
        if (key == "1") {
            if (templates == "monthly") {
                dispatch(AnalyticsDataAction({ type: templates, year: years }))
            }
            else {
                dispatch(AnalyticsDataAction({ type: templates, month: months, year: years }))
            }
        }
        if (key == "2" && region) {
            if (templates == "monthly") {
                dispatch(updateAnalyticsRegionDataAction({ regionId: region, type: templates, year: years }))
            }
            else {
                dispatch(updateAnalyticsRegionDataAction({ regionId: region, type: templates, month: months, year: years }))
            }
        }
        if (key == "3") {

            dispatch(AnalyticsTerminalDataAction({ templates: templates, months: months, year: years }))
        }
    }

    // selection in dropdowns
    const selectOne = (e) => {
        setTemplates(e.target.value)
    }

    //list of weeks from api
    let res = []
    newdata1?.weeks?.map((item) => {
        res.push("week " + item)
    })

    let arraynew = []
    apiData?.weeks?.map((item) => {
        arraynew.push("week " + item)
    })

    let terminalarray = []

    if (terminalWeeks?.length > 0) {
        terminalWeeks?.map((item) => {
            terminalarray.push("week " + item)
        })
    }
    else {
        terminalarray = ["week 1", "week 2", "week 3", "week 4", "week 5"]
    }

    //list of years with moment
    let first = JSON.parse(moment().subtract('years', 3).format("YYYY"))
    let second = moment().add('years', 10).format('YYYY');
    let arr = [];
    for (let i = 2021; i <= second; i++) arr.push(i);

    var ctx = document.getElementById("canvas").getContext("2d")
    var gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(1, 'rgb(27,200,140)');
    gradient.addColorStop(0.3, 'rgb(25, 110, 161)');
    gradient.addColorStop(0, 'rgb(25, 110, 161)');

    //bar chart component
    function chartView(heading, id, apiData, label, type, fill, weeks) {
        return (
            <div className='chartView'>
                <BarChartjs
                    id={id}
                    apiData={apiData}
                    label={label}
                    type={type}
                    fill={fill}
                    backgroundcolor={gradient}
                    bordercolor={gradient}
                    labels1={
                        templates == "monthly" ?
                            ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
                            :
                            weeks
                    }
                />
                <h2 className="bar-heading">{heading}</h2>
            </div>
        )
    }

    // download view in png or jpg
    const saveCanvas = (key, value) => {
        const canvasSave = document.getElementById(`${key}`);
        canvasSave.toBlob(function (blob) {
            saveAs(blob, `chart.${value}`)
        })
    }

    //content of popover
    const content = (key) => {
        return (
            <div>
                <div>
                    <p className="pointer-arrow" onClick={() => saveCanvas(key, "jpg")}>{appConstants.downloadasJpg}</p>
                    <p className="pointer-arrow" onClick={() => saveCanvas(key, "png")}>{appConstants.downloadasPng}</p>
                </div>
            </div>
        )
    }

    //tab- general
    const generalView = () => {
        return (
            <Card className={classes.analyticsCard} >
                <Grid container>
                    <Grid className={classes.tableContainerRow} style={{ marginTop: "5px", padding: "20px" }}>
                        <div className="radiobutton" style={{ display: "flex", justifyContent: "space-between" }}>
                            <RadioGroup aria-label="template" name="template" value={templates} onChange={selectOne} row>
                                <FormControlLabel value="monthly" control={<Radio className="dropdown-radioButton" onClick={() => {
                                    dispatch(AnalyticsDataAction({ type: "monthly", year: years }))
                                    setShowMonths(false)
                                }
                                }
                                />}
                                    checked={templates == "monthly"}
                                    label={<div>
                                        Years
                                    </div>
                                    }
                                />
                                <FormControlLabel value="weekly" control={<Radio className="dropdown-radioButton" onClick={() => {
                                    dispatch(AnalyticsDataAction({ type: "weekly", month: months, year: years }))
                                    setShowMonths(true)
                                }
                                }
                                />}
                                    checked={templates == "weekly"}
                                    label={<div>
                                        Months
                                    </div>
                                    }
                                />
                            </RadioGroup>
                            <Button
                                title="Refresh"
                                width="108px"
                                onClick={() => {
                                    if (templates == "monthly") {
                                        dispatch(AnalyticsDataAction({ type: templates, year: years }))
                                    }
                                    else {
                                        dispatch(AnalyticsDataAction({ type: templates, month: months, year: years }))
                                    }
                                }}
                                marginRight="0px"
                                mr
                                mb
                            />
                        </div>
                        {
                            templates == "monthly" &&
                            <div className={classes.inputWrapper} style={{ width: "140px", }}>
                                <Select
                                    native
                                    className={classes.selectInput}
                                    value={years}
                                    onChange={(e) => {
                                        setYears(e.target.value)
                                        dispatch(AnalyticsDataAction({ type: templates, year: e.target.value }))
                                    }}
                                    inputProps={{
                                        name: "year",
                                        id: "year",
                                    }}
                                >
                                    {arr?.map(
                                        (data, index) => {
                                            return (
                                                <option value={data}>
                                                    {data}
                                                </option>
                                            );
                                        }
                                    )}
                                </Select>
                            </div>
                        }
                        {showMonths == true &&
                            <div className={classes.inputWrapper} style={{ display: "flex", }}>
                                <Select
                                    native
                                    className={classes.selectInput}
                                    value={years}
                                    style={{ marginRight: "20px" }}
                                    onChange={(e) => {
                                        setYears(e.target.value)
                                        dispatch(AnalyticsDataAction({ type: templates, month: months, year: e.target.value }))
                                    }}
                                    inputProps={{
                                        name: "year",
                                        id: "year",
                                    }}
                                >
                                    {arr?.map(
                                        (data, index) => {
                                            return (
                                                <option value={data}>
                                                    {data}
                                                </option>
                                            );
                                        }
                                    )}
                                </Select>
                                <Select
                                    native
                                    className={classes.selectInput}
                                    value={months}
                                    onChange={(e) => {
                                        setMonths(e.target.value)
                                        dispatch(AnalyticsDataAction({ type: templates, month: e.target.value, year: years }))
                                    }}
                                    inputProps={{
                                        name: "months",
                                        id: "months",
                                    }}
                                >
                                    {newData?.map(
                                        (data, index) => {
                                            return (
                                                <option value={data.id}>
                                                    {data.name}
                                                </option>
                                            );
                                        }
                                    )}
                                </Select>
                            </div>
                        }
                        <div className="download-popover">
                            <Select
                                style={{ marginRight: "20px" }}
                                native
                                className={classes.selectInput}
                                value={type}
                                onChange={(e) => {
                                    setType(e.target.value)
                                }}
                                inputProps={{
                                    name: "type",
                                    id: "type",
                                }}
                            >
                                <option value="Bar">{appConstants.barchart}</option>
                                <option value="Line">{appConstants.linechart}</option>
                            </Select>
                            <Tooltip title={"Download"}>
                                <Popover placement="bottomLeft" content={content("stackD")} trigger="click">
                                    <img src={AppImages.dropDown} width={"30px"} height={"30px"} ></img>
                                </Popover>
                            </Tooltip>
                        </div>
                        {
                            type == "Bar" ?
                                chartView("Active Users", "stackD", apiData?.noOfActiveUsers, 'Active Users', "", false, arraynew)
                                :
                                chartView("Active Users", "stackD", apiData?.noOfActiveUsers, 'Active Users', "line", true, arraynew)
                        }
                        <div className="download-popover">
                            <Select
                                style={{ marginRight: "20px" }}
                                native
                                className={classes.selectInput}
                                value={type1}
                                onChange={(e) => {
                                    setType1(e.target.value)
                                }}
                                inputProps={{
                                    name: "type",
                                    id: "type",
                                }}
                            >
                                <option value="Bar">{appConstants.barchart}</option>
                                <option value="Line">{appConstants.linechart}</option>
                            </Select>
                            <Tooltip title={"Download"}>
                                <Popover placement="bottomLeft" content={content("stackD1")} trigger="click">
                                    <img src={AppImages.dropDown} width={"30px"} height={"30px"} ></img>
                                </Popover>
                            </Tooltip>
                        </div>
                        {type1 == "Bar" ?
                            chartView("Registered Users", "stackD1", apiData?.noOfRegisterUsers, 'Registered Users', "", false, arraynew)
                            :
                            chartView("Registered Users", "stackD1", apiData?.noOfRegisterUsers, 'Registered Users', "line", true, arraynew)
                        }
                        <div className="download-popover">
                            <Select
                                style={{ marginRight: "20px" }}
                                native
                                className={classes.selectInput}
                                value={type2}
                                onChange={(e) => {
                                    setType2(e.target.value)
                                }}
                                inputProps={{
                                    name: "type",
                                    id: "type",
                                }}
                            >
                                <option value="Bar">{appConstants.barchart}</option>
                                <option value="Line">{appConstants.linechart}</option>
                            </Select>
                            <Tooltip title={"Download"}>
                                <Popover placement="bottomLeft" content={content("stackD2")} trigger="click">
                                    <img src={AppImages.dropDown} width={"30px"} height={"30px"} ></img>
                                </Popover>
                            </Tooltip>
                        </div>
                        {type2 == "Bar" ?
                            chartView("No. of Posts", "stackD2", apiData?.noOfPosts, 'No. of Posts', "", false, arraynew)
                            :
                            chartView("No. of Posts", "stackD2", apiData?.noOfPosts, 'No. of Posts', "line", true, arraynew)
                        }
                        <div className="download-popover">
                            <Select
                                style={{ marginRight: "20px" }}
                                native
                                className={classes.selectInput}
                                value={type3}
                                onChange={(e) => {
                                    setType3(e.target.value)
                                }}
                                inputProps={{
                                    name: "type",
                                    id: "type",
                                }}
                            >
                                <option value="Bar">{appConstants.barchart}</option>
                                <option value="Line">{appConstants.linechart}</option>
                            </Select>
                            <Tooltip title={"Download"}>
                                <Popover placement="bottomLeft" content={content("stackD3")} trigger="click">
                                    <img src={AppImages.dropDown} width={"30px"} height={"30px"} ></img>
                                </Popover>
                            </Tooltip>
                        </div>
                        {
                            type3 == "Bar" ?
                                chartView("No. of Comments", "stackD3", apiData?.noOfComments, 'No. of Comments', "", false, arraynew)
                                :
                                chartView("No. of Comments", "stackD3", apiData?.noOfComments, 'No. of Comments', "line", true, arraynew)
                        }
                        <div className="download-popover">
                            <Select
                                style={{ marginRight: "20px" }}
                                native
                                className={classes.selectInput}
                                value={type4}
                                onChange={(e) => {
                                    setType4(e.target.value)
                                }}
                                inputProps={{
                                    name: "type",
                                    id: "type",
                                }}
                            >
                                <option value="Bar">{appConstants.barchart}</option>
                                <option value="Line">{appConstants.linechart}</option>
                            </Select>
                            <Tooltip title={"Download"}>
                                <Popover placement="bottomLeft" content={content("stackD4")} trigger="click">
                                    <img src={AppImages.dropDown} width={"30px"} height={"30px"} ></img>
                                </Popover>
                            </Tooltip>
                        </div>
                        {
                            type4 == "Bar" ?
                                chartView("No. of Timelines", "stackD4", apiData?.noOfTimelines, 'No. of Timelines', "", false, arraynew)
                                :
                                chartView("No. of Timelines", "stackD4", apiData?.noOfTimelines, 'No. of Timelines', "line", true, arraynew)
                        }
                        <div className="download-popover">
                            <Select
                                style={{ marginRight: "20px" }}
                                native
                                className={classes.selectInput}
                                value={type5}
                                onChange={(e) => {
                                    setType5(e.target.value)
                                }}
                                inputProps={{
                                    name: "type",
                                    id: "type",
                                }}
                            >
                                <option value="Bar">{appConstants.barchart}</option>
                                <option value="Line">{appConstants.linechart}</option>
                            </Select>
                            <Tooltip title={"Download"}>
                                <Popover placement="bottomLeft" content={content("stackD5")} trigger="click">
                                    <img src={AppImages.dropDown} width={"30px"} height={"30px"} ></img>
                                </Popover>
                            </Tooltip>
                        </div>
                        {type5 == "Bar" ?
                            chartView("No. of Likes", "stackD5", apiData?.noOfLikes, 'No. of Likes', "", false, arraynew)
                            :
                            chartView("No. of Likes", "stackD5", apiData?.noOfLikes, 'No. of Likes', "line", true, arraynew)
                        }
                    </Grid>
                </Grid>
            </Card >
        )
    }

    //tab-region
    const regionView = () => {
        return (
            <Card className={classes.analyticsCard} >
                <Grid container>
                    <Grid className={classes.tableContainerRow} style={{ marginTop: "5px", padding: "20px" }}>
                        <div className="radiobutton" style={{ display: "flex", justifyContent: "space-between" }}>
                            <RadioGroup aria-label="template" name="template" value={templates} onChange={selectOne} row>
                                <FormControlLabel value="monthly" control={<Radio className="dropdown-radioButton" onClick={() => {
                                    setShowMonths(false)
                                    {
                                        region &&
                                            dispatch(updateAnalyticsRegionDataAction({ regionId: region, type: "monthly", year: years }))
                                    }
                                }
                                }
                                />}
                                    checked={templates == "monthly"}
                                    label={<div>
                                        Years
                                    </div>
                                    }
                                />
                                <FormControlLabel value="weekly" control={<Radio className="dropdown-radioButton" onClick={() => {
                                    setShowMonths(true)
                                    {
                                        region &&
                                            dispatch(updateAnalyticsRegionDataAction({ regionId: region, type: "weekly", month: months, year: years }))
                                    }
                                }
                                }
                                />}
                                    checked={templates == "weekly"}
                                    label={<div>
                                        Months
                                    </div>
                                    }
                                />
                            </RadioGroup>
                            <Button
                                title="Refresh"
                                width="108px"
                                onClick={() => {
                                    if (region) {
                                        if (templates == "monthly") {
                                            dispatch(updateAnalyticsRegionDataAction({ regionId: region, type: templates, year: years }))
                                        }
                                        else {
                                            dispatch(updateAnalyticsRegionDataAction({ regionId: region, type: templates, month: months, year: years }))
                                        }
                                    }
                                }}
                                marginRight="0px"
                                mr
                                mb
                            />
                        </div>
                        <div className="dropdowns" style={{ display: "flex", justifyContent: "space-between" }}>
                            {
                                templates == "monthly" &&
                                <div className={classes.inputWrapper} style={{ width: "140px", }}>
                                    <Select
                                        native
                                        className={classes.selectInput}
                                        value={years}
                                        onChange={(e) => {
                                            setYears(e.target.value)
                                            {
                                                region &&
                                                    dispatch(updateAnalyticsRegionDataAction({ regionId: region, type: templates, year: e.target.value }))
                                            }
                                        }}
                                        inputProps={{
                                            name: "year",
                                            id: "year",
                                        }}
                                    >
                                        {arr?.map(
                                            (data, index) => {
                                                return (
                                                    <option value={data}>
                                                        {data}
                                                    </option>
                                                );
                                            }
                                        )}
                                    </Select>
                                </div>
                            }
                            {showMonths == true &&
                                <div className={classes.inputWrapper} style={{ display: "flex", }}>
                                    <Select
                                        style={{ marginRight: "20px" }}
                                        native
                                        className={classes.selectInput}
                                        value={years}
                                        onChange={(e) => {
                                            setYears(e.target.value)
                                            {
                                                region &&
                                                    dispatch(updateAnalyticsRegionDataAction({ regionId: region, type: templates, month: months, year: e.target.value }))
                                            }
                                        }}
                                        inputProps={{
                                            name: "year",
                                            id: "year",
                                        }}
                                    >
                                        {arr?.map(
                                            (data, index) => {
                                                return (
                                                    <option value={data}>
                                                        {data}
                                                    </option>
                                                );
                                            }
                                        )}
                                    </Select>
                                    <Select
                                        native
                                        className={classes.selectInput}
                                        value={months}
                                        onChange={(e) => {
                                            setMonths(e.target.value)
                                            {
                                                region &&
                                                    dispatch(updateAnalyticsRegionDataAction({ regionId: region, type: templates, month: e.target.value, year: years }))
                                            }
                                        }}
                                        inputProps={{
                                            name: "months",
                                            id: "months",
                                        }}
                                    >
                                        {newData?.map(
                                            (data, index) => {
                                                return (
                                                    <option value={data.id}>
                                                        {data.name}
                                                    </option>
                                                );
                                            }
                                        )}
                                    </Select>
                                </div>
                            }
                            <div className="region-listing">
                                <div className={classes.inputWrapper} style={{ width: "140px" }}>
                                    <Select
                                        native
                                        className={classes.selectInput}
                                        value={region}
                                        onChange={(e) => {
                                            setRegion(e.target.value)

                                            {
                                                templates == "monthly" ?
                                                    dispatch(updateAnalyticsRegionDataAction({ regionId: e.target.value, type: templates, year: years }))
                                                    :
                                                    dispatch(updateAnalyticsRegionDataAction({ regionId: e.target.value, type: templates, month: months, year: years }))
                                            }
                                        }}
                                        inputProps={{
                                            name: "region",
                                            id: "region",
                                        }}
                                    >
                                        {
                                            regionListing?.length <= 0 &&
                                            <option value="">
                                                Select Region
                                            </option>
                                        }
                                        {regionListing && regionListing?.map(
                                            (terminalArea, index) => {
                                                return (
                                                    <option value={terminalArea._id}>
                                                        {terminalArea.region_name}
                                                    </option>
                                                );
                                            }
                                        )}
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div className="download-popover">
                            <Select
                                style={{ marginRight: "20px", marginTop: "7px" }}
                                native
                                className={classes.selectInput}
                                value={type6}
                                onChange={(e) => {
                                    setType6(e.target.value)

                                }}
                                inputProps={{
                                    name: "type6",
                                    id: "type6",
                                }}
                            >
                                <option value="Bar">{appConstants.barchart}</option>
                                <option value="Line">{appConstants.linechart}</option>
                            </Select>
                            <Tooltip title={"Download"}>
                                <Popover placement="bottomLeft" content={content("stackD6")} trigger="click">
                                    <img src={AppImages.dropDown} width={"30px"} height={"30px"} ></img>
                                </Popover>
                            </Tooltip>
                        </div>
                        {
                            type6 == "Bar" ?
                                chartView("No. of Users", "stackD6", updateData, 'No. of Users', "", false, res)
                                :
                                chartView("No. of Users", "stackD6", updateData, 'No. of Users', "line", true, res)
                        }
                    </Grid>
                </Grid>
            </Card>
        )
    }

    //tab - terminal
    const terminalView = () => {
        return (
            <Card className={classes.analyticsCard}>
                <Grid container>
                    <Grid className={classes.tableContainerRow} style={{ marginTop: "5px", padding: "20px" }}>
                        <div className="radiobutton" style={{ display: "flex", justifyContent: "space-between" }}>
                            <RadioGroup aria-label="template" name="template" value={templates} onChange={selectOne} row>
                                <FormControlLabel value="monthly" control={<Radio className="dropdown-radioButton" onClick={() => {
                                    setShowMonths(false)
                                    {
                                        terminal &&
                                            dispatch(updateAnalyticsTerminalDataAction({ terminalId: terminal, type: "monthly", year: years }))
                                    }
                                }
                                }
                                />}
                                    checked={templates == "monthly"}
                                    label={<div>
                                        Years
                                    </div>
                                    }
                                />
                                <FormControlLabel value="weekly" control={<Radio className="dropdown-radioButton" onClick={() => {
                                    setShowMonths(true)
                                    {
                                        terminal &&
                                            dispatch(updateAnalyticsTerminalDataAction({ terminalId: terminal, type: "weekly", month: months, year: years }))
                                    }
                                }
                                }
                                />}
                                    checked={templates == "weekly"} label={"Months"}
                                />
                            </RadioGroup>
                            <Button
                                title="Refresh"
                                width="108px"
                                onClick={() => {
                                    if (terminal) {
                                        if (templates == "monthly") {
                                            dispatch(updateAnalyticsTerminalDataAction({ terminalId: terminal, type: templates, year: years }))
                                        }
                                        else {
                                            dispatch(updateAnalyticsTerminalDataAction({ terminalId: terminal, type: templates, month: months, year: years }))
                                        }
                                    }
                                }}
                                marginRight="0px"
                                mr
                                mb
                            />
                        </div>
                        <div className="hover-dropdowns" style={{ display: "flex", justifyContent: "space-between" }}>
                            <div className="dropdowns-top" style={{ display: "flex", justifyContent: "flex-start" }}>
                                {
                                    templates == "monthly" &&
                                    <div className={classes.inputWrapper} style={{ width: "140px" }}>
                                        <Select
                                            native
                                            className={classes.selectInput}
                                            value={years}
                                            onChange={(e) => {
                                                setYears(e.target.value)
                                                {
                                                    terminal &&
                                                        dispatch(updateAnalyticsTerminalDataAction({ terminalId: terminal, type: templates, year: e.target.value }))
                                                }
                                            }}
                                            inputProps={{
                                                name: "year",
                                                id: "year",
                                            }}
                                        >
                                            {arr?.map(
                                                (data, index) => {
                                                    return (
                                                        <option value={data}>
                                                            {data}
                                                        </option>
                                                    );
                                                }
                                            )}
                                        </Select>
                                    </div>
                                }
                                {showMonths == true &&
                                    <div className={classes.inputWrapper} style={{ display: "flex" }}>
                                        <Select
                                            native
                                            style={{ marginRight: "20px" }}
                                            className={classes.selectInput}
                                            value={years}
                                            onChange={(e) => {
                                                setYears(e.target.value)
                                                {
                                                    terminal &&
                                                        dispatch(updateAnalyticsTerminalDataAction({ terminalId: terminal, type: templates, month: months, year: e.target.value }))
                                                }
                                            }}
                                            inputProps={{
                                                name: "year",
                                                id: "year",
                                            }}
                                        >
                                            {arr?.map(
                                                (data, index) => {
                                                    return (
                                                        <option value={data}>
                                                            {data}
                                                        </option>
                                                    );
                                                }
                                            )}
                                        </Select>
                                        <Select
                                            native
                                            className={classes.selectInput}
                                            value={months}
                                            onChange={(e) => {
                                                setMonths(e.target.value)
                                                {
                                                    terminal &&
                                                        dispatch(updateAnalyticsTerminalDataAction({ terminalId: terminal, type: templates, month: e.target.value, year: years }))
                                                }
                                            }}
                                            inputProps={{
                                                name: "months",
                                                id: "months",
                                            }}
                                        >
                                            {newData?.map(
                                                (data, index) => {
                                                    return (
                                                        <option value={data.id}>
                                                            {data.name}
                                                        </option>
                                                    );
                                                }
                                            )}
                                        </Select>
                                    </div>
                                }
                            </div>
                            <div className="dropdowns-bottom" style={{ display: "flex", justifyContent: "flex-end" }}>
                                <div className={classes.inputWrapper} style={{ width: "140px", marginRight: "20px" }}>
                                    <Select
                                        native
                                        className={classes.selectInput}
                                        value={regionTerminal}
                                        onChange={(e) => {
                                            setRegionTerminal(e.target.value)
                                            dispatch(emptyregionTerminalData())
                                            dispatch(regionTerminalDataAction({ regionId: e.target.value, templates: templates, months: months }))
                                        }}
                                        inputProps={{
                                            name: "regionTerminal",
                                            id: "regionTerminal",
                                        }}
                                    >
                                        {
                                            regionterminallistingData?.length <= 0 &&
                                            <option value="">
                                                Select Region
                                            </option>
                                        }
                                        {regionterminallistingData?.length > 0 && regionterminallistingData?.map(
                                            (terminalArea, index) => {
                                                return (
                                                    <option value={terminalArea._id}>
                                                        {terminalArea.region_name}
                                                    </option>
                                                );
                                            }
                                        )}
                                    </Select>
                                </div>
                                <div className={classes.inputWrapper} style={{ width: "145px" }}>
                                    <Select
                                        native
                                        className={classes.selectInput}
                                        value={terminal}
                                        onChange={(e) => {
                                            setTerminal(e.target.value)
                                            {
                                                templates == "monthly" ?
                                                    dispatch(updateAnalyticsTerminalDataAction({ terminalId: e.target.value, type: templates, year: years }))
                                                    :
                                                    dispatch(updateAnalyticsTerminalDataAction({ terminalId: e.target.value, type: templates, month: months, year: years }))
                                            }
                                        }}
                                        inputProps={{
                                            name: "terminal",
                                            id: "terminal",
                                        }}
                                    >
                                        {
                                            regionterminalList?.length <= 0 &&
                                            <option value="">
                                                Select Terminal
                                            </option>
                                        }
                                        {regionterminalList && regionterminalList?.map(
                                            (terminalArea, index) => {
                                                return (
                                                    <option value={terminalArea._id}>
                                                        {terminalArea.terminal_name}
                                                    </option>
                                                );
                                            }
                                        )}
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div className="download-popover">
                            <Select
                                style={{ marginRight: "20px", marginTop: "7px" }}
                                native
                                className={classes.selectInput}
                                value={type7}
                                onChange={(e) => {
                                    setType7(e.target.value)
                                }}
                                inputProps={{
                                    name: "type7",
                                    id: "type7",
                                }}
                            >
                                <option value="Bar">{appConstants.barchart}</option>
                                <option value="Line">{appConstants.linechart}</option>
                            </Select>
                            <Tooltip title={"Download"}>
                                <Popover placement="bottomLeft" content={content("stackD7")} trigger="click">
                                    <img src={AppImages.dropDown} width={"30px"} height={"30px"} ></img>
                                </Popover>
                            </Tooltip>
                        </div>
                        {
                            type7 == "Bar" ?
                                chartView("Average Waiting Time(mins)", "stackD7", updateTerminalData?.avgWaitTime, 'Average Waiting Time', "", false, terminalarray)
                                :
                                chartView("Average Waiting Time(mins)", "stackD7", updateTerminalData?.avgWaitTime, 'Average Waiting Time', "line", true, terminalarray)
                        }
                        <div className="download-popover">
                            <Select
                                style={{ marginRight: "20px" }}
                                native
                                className={classes.selectInput}
                                value={type8}
                                onChange={(e) => {
                                    setType8(e.target.value)
                                }}
                                inputProps={{
                                    name: "type8",
                                    id: "type8",
                                }}
                            >
                                <option value="Bar">{appConstants.barchart}</option>
                                <option value="Line">{appConstants.linechart}</option>
                            </Select>
                            <Tooltip title={"Download"}>
                                <Popover placement="bottomLeft" content={content("stackD8")} trigger="click">
                                    <img src={AppImages.dropDown} width={"30px"} height={"30px"} ></img>
                                </Popover>
                            </Tooltip>
                        </div>
                        {
                            type8 == "Bar" ?
                                chartView("No. of users inside a terminal", "stackD8", updateTerminalData?.usersInTerminal, 'No. of users inside a terminal', "", false, terminalarray)
                                :
                                chartView("No. of users inside a terminal", "stackD8", updateTerminalData?.usersInTerminal, 'No. of users inside a terminal', "line", true, terminalarray)
                        }
                    </Grid>
                </Grid>
            </Card >
        )
    }

    const onTabChange = (key) => {
        setTabValue(key)
    }

    return (
        <DashboardLayout>
            <AppBar breadcrumbs={[{ route: '/analytics', name: "Analytics" }]} />
            <Tabs defaultActiveKey={tabValue} onTabClick={(key) => onTabClicked(key)} onChange={onTabChange}>
                <TabPane tab="General" key="1" disabled={tabValue == "1"}>
                    {generalView()}
                </TabPane>
                <TabPane tab="Region" key="2" disabled={tabValue == "2"}>
                    {regionView()}
                </TabPane>
                <TabPane tab="Terminal" key="3" disabled={tabValue == "3"}>
                    {terminalView()}
                </TabPane>
            </Tabs>
        </DashboardLayout>
    );
};
