import React, { useState, useEffect } from 'react';
// Navigation
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom'
// Styles
import 'antd/dist/antd.css'
import '../dashboard.scss'
// antd
import { Card, } from 'react-bootstrap';

// Mui Components
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
// Custom components
import DashboardLayout from '../../layouts/dashboardLayout'
import { AppBar, Button, PaginationBar, Navbar, NavbarSearch, ConfirmationModal, Table } from '../../customComponents'
// Constants
import { appConstants } from '../../themes/constants'
import localStorage from "../../utils/localStorage";
// utils methods
import { useStyles } from '../../styles'
// Redux
import { useSelector, useDispatch } from 'react-redux'
import { handleNavigationStateAction, importFileAction, exportFileAction, } from '../../store/actions'
import { getStateListAction, deleteStateAction, exportListFileAction } from "../../store/actions/stateListingActions"
// Global constants


export const StateListing = () => {
    const styleProps = { searchable: true }
    // Hooks declarations
    const classes = useStyles(styleProps);
    const history = useHistory();
    const location = useLocation()
    const dispatch = useDispatch()
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const stateListingData = useSelector(state => state.stateListingReducer)

    // Global state initialization
    const { stateListing, statePaging, isLoading, deletedTerminal } = stateListingData

    // local state initialization
    const [search, setSearch] = useState("")
    const [offset, setOffset] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sortBy, setSortBy] = useState("")
    const [order, setOrder] = useState(null)
    const [confirm, setConfirm] = useState(false)
    const [stateToDelete, setStateToDelete] = useState(null)
    const [disableButton, setDisableButton] = useState()
    let permissionsData = localStorage?.getPermissions()
    let employeeIndex = permissionsData?.findIndex((x) => x.name == "state")
    // +++++++++++

    // Method to fetch listing
    const fetchDataListing = (search, offset, limit, sortBy, order) => {
        dispatch(getStateListAction(search, offset, limit, sortBy, order))
    }
    //lifecycle hooks
    let siderIndex = localStorage.getLayoutArr()
    let siderNestedIndex = localStorage.getNestedLayoutArr()
    useEffect(() => {
        document.title = appConstants.headerTitle.stateListing;
        if (permissionsData) {
            let index = siderIndex?.findIndex((x) => x.name == "Terminal Management")
            let nestedIndex = siderNestedIndex?.findIndex((x) => x.key == "state")
            dispatch(handleNavigationStateAction(index, false))
            setTimeout(() => {
                dispatch(handleNavigationStateAction(nestedIndex, true))
            }, 1000);
        }
        else {
            dispatch(handleNavigationStateAction(0, true))
        }
        fetchDataListing(search, offset, limit, sortBy, order)
    }, [])


    //Callback method to handle sorting 
    const handleChange = async (pagination, filters, sorter) => {
        //To fetch listing in ascending order
        if (sorter.order == "ascend") {
            setOrder(1)
            setSortBy(sorter.columnKey)
            fetchDataListing(search, offset, limit, sorter.columnKey, 1)
        } //To fetch listing in descending order
        else if (sorter.order === "descend") {
            setOrder(-1)
            setSortBy(sorter.columnKey)
            fetchDataListing(search, offset, limit, sorter.columnKey, -1)
        } //To fetch listing in normal order
        else {
            setOrder(null)
            setSortBy("")
            fetchDataListing(search, offset, limit, "", null)
        }
    }

    // Callback method to handle page chage
    const handlePaginationChange = (e, page) => {
        const offsetTo = (page - 1) * limit
        setOffset(offsetTo)
        fetchDataListing(search, offsetTo, limit, sortBy, order)
    }

    // Callback method to handle searching key
    const handleSearching = async (e) => {
        var tempText = e.target.value
        if (e.target.value.charAt(0) === " ") {
            tempText = e.target.value.replace(/^ +/gm, '');
        }
        setSearch(tempText)
        fetchDataListing(tempText, 0, limit, "", null)

    }
    // +++++++++++

    const columns = [
        {
            title: appConstants.sr,
            dataIndex: '',
            key: 'sr',
            render: (text, record, index) => (index + 1) + (statePaging?.page?.nextPage - limit),
            ellipsis: false,
            width: 100,
        },
        {
            title: 'State Name',
            dataIndex: 'state_name',
            key: 'state_name',
            render: (state_name) => state_name,
            ellipsis: false,
            sorter: true,
        },
        {
            title: 'Short Name',
            dataIndex: 'short_name',
            key: 'short_name',
            render: (short_name) => short_name,
            ellipsis: false,
            sorter: true,
        },
        {
            title: appConstants.action,
            dataIndex: '',
            key: 'x',
            width: 100,
            align: 'center',
            render: (text, record, index) => {
                return (
                    <Grid className={classes.buttons}>
                        <Button disabled={(permissionsData) && permissionsData[employeeIndex]?.edit == false} title={appConstants.edit} onClick={() => history.push({
                            pathname: "/edit-state-listing",
                            state: { stateName: record.state_name, shortName: record.short_name, stateId: record._id }
                        })} />
                        <Button title={appConstants.delete}
                            disabled={(permissionsData) && permissionsData[employeeIndex]?.delete == false}
                            onClick={() => {
                                setConfirm(true)
                                setStateToDelete(record._id)
                                setDisableButton(false)

                            }} />
                    </Grid>
                )
            },
        },
    ];

    return (
        <DashboardLayout>
            <ConfirmationModal title="Alert" text={appConstants.confirmDeleteState} visible={confirm}
                disable={disableButton}
                onCancel={() => {
                    setConfirm(false)
                }}
                onDeleteConfirmed={() => {
                    dispatch(deleteStateAction(stateToDelete))
                    setConfirm(false)
                    setDisableButton(true)
                }}
            />
            <AppBar breadcrumbs={[{ route: '/state-listing', name: "State Listing" }]} />
            <Grid container className={classes.buttonBarWrapper} >
                <Button title="Add State" disabled={(permissionsData) && permissionsData[employeeIndex]?.add == false} onClick={() => history.push('/add-listing')} mr mb />
                <Button title="Export State" onClick={() => {
                    dispatch(exportListFileAction())
                }} mr mb />
                <Button title="Import State" disabled={(permissionsData) && permissionsData[employeeIndex]?.edit == false} onClick={() => history.push('/import-state-listing')} mr mb />
            </Grid>
            <Navbar title="State Listing" searchable value={search} onChange={(e) => handleSearching(e)} />
            <Card className={classes.card}>
                {matches &&
                    <Box className={classes.searchBox}>
                        <NavbarSearch value={search} onValueChange={(e) => handleSearching(e)} />
                    </Box>
                }
                <Grid container>
                    <Grid className={classes.tableContainerRow}>
                        <Table
                            loading={isLoading}
                            columns={columns}
                            dataSource={stateListing}
                            onChange={handleChange}
                            searching={search.length > 0}
                        />
                    </Grid>
                </Grid>
                <PaginationBar
                    totalCount={statePaging?.page?.totalCount}
                    count={Math.ceil(statePaging?.page?.totalCount / limit)}
                    currentPage={statePaging?.page?.currentPage}
                    nextPage={statePaging?.page?.nextPage}
                    onChange={(e, page) => handlePaginationChange(e, page)}
                    prevPage={statePaging?.page?.nextPage - limit}
                />
            </Card>
        </DashboardLayout>
    );
}
