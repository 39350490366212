import React, { useState, useEffect, useRef } from 'react';
// Navigation
import { useHistory, useLocation } from 'react-router-dom'
// Styles
import 'antd/dist/antd.css'
import '../dashboard.scss'
// antd
import { Tooltip } from 'antd';
import { Card, Table as BSTable } from 'react-bootstrap';
// fontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/free-solid-svg-icons'
// Mui ComponentsfaUpload
import { Grid, useTheme, Typography, FormHelperText } from '@material-ui/core';
// Custom components
import DashboardLayout from '../../layouts/dashboardLayout'
import { Navbar, AppBar, Button } from '../../customComponents'
// Constants
import { AppImages } from '../../themes/appImages'
import { Colors } from '../../themes/colors'
import { appConstants, ValidationConstants } from '../../themes/constants'
// utils methods
import cl from '../../utils/cl'
import { useStyles } from '../../styles'
// Redux
import { useSelector, useDispatch } from 'react-redux'
import { importFileAction } from '../../store/actions'
// Global constants


export const ImportTerminalArea = () => {
    // Hooks declarations
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const location = useLocation()
    const dispatch = useDispatch()
    const [file, setFile] = useState('')
    const [fileName, setFileName] = useState('')
    const [fileUrl, setFileUrl] = useState('')
    const [errors, setErrors] = useState(null)
    const state = useSelector(state => state.terminalManagementReducer)
    // Lifecycle Hooks  
    useEffect(() => {
        document.title = appConstants.headerTitle.importTerminalArea;
    }, [])

    // Remove selected file on error state updation
    useEffect(() => {
        setFile('')
        setFileName('')
        setFileUrl(null)
    }, [state.errorState])

    const handleFileChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            let fileName = event.target.files[0]?.name?.split(".")
            if (fileName[1] === "csv" || fileName[1] === "CSV") {
                setFileName(event.target.files[0]?.name)
                setFileUrl(event.target.files[0])
                let reader = new FileReader();
                reader.onload = (e) => {
                    setFile(event.target.result)
                };
                reader.readAsDataURL(event.target.files[0]);
            }
            else {
                setErrors(ValidationConstants.file.invalid)
                setFileName("")
            }
        }
    }



    const handleSubmit = () => {
        if (!fileUrl) {
            setErrors(ValidationConstants.file.empty)
        } else {
            setErrors(null)
            dispatch(importFileAction(fileUrl, "terminalArea"))
        }
    }

    return (
        <DashboardLayout>
            <AppBar breadcrumbs={[{ route: '/terminal-area-list', name: "Region Listing" }, { route: '/import-terminal-area', name: "Import Region" }]} />
            <Navbar title="Import Region" />
            <Card className={classes.card}>
                <Grid container>
                    <Grid className={classes.tableContainerRow}>
                        <form>
                            <BSTable striped bordered >
                                <tbody>
                                    <tr>
                                        <td className={classes.rowKey}>
                                            Upload File
                                        </td>
                                        <td className={classes.rowValue}>
                                            <div className={classes.inputWrapper}>
                                                <Tooltip title={appConstants.tooltip.uploadFile} >
                                                    <input
                                                        accept=".csv"
                                                        // style={{ display: 'none' }}
                                                        id="raised-button-file"
                                                        value={file}
                                                        hidden
                                                        onChange={(e) => handleFileChange(e)}
                                                        type="file"
                                                    />
                                                    <label htmlFor="raised-button-file" >
                                                        <div className={classes.uploadButton}>
                                                            <FontAwesomeIcon icon={faUpload}
                                                                style={{
                                                                    height: 18, width: 18
                                                                }}
                                                            />
                                                        </div>
                                                    </label>
                                                </Tooltip>
                                                {fileName.length > 0 ? <Typography className={classes.fileSuccess}>{fileName}</Typography> : true}
                                                {fileName.length <= 0 && errors !== null ? <FormHelperText error id="component-error-text">{errors}</FormHelperText> : true}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={classes.rowKey}></td>
                                        <td className={classes.rowValue}>
                                            <Button title="Upload" onClick={() => handleSubmit()} />
                                        </td>
                                    </tr>
                                </tbody>
                            </BSTable>
                        </form>
                    </Grid>
                </Grid>
            </Card>
        </DashboardLayout>
    );
}
