import React, { useState, useEffect, useRef } from "react";
// Navigation
import { useHistory, useLocation } from "react-router-dom";
// Styles
import "antd/dist/antd.css";
import "../dashboard.scss";
// antd
import { Tooltip, Spin, Input } from "antd";

import { Card, Table as BSTable } from "react-bootstrap";
import { uploadImageOnS3 } from "../../utils/helpers";
// fontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faShare } from "@fortawesome/free-solid-svg-icons";
// Mui Components
// import { Grid, useTheme, useMediaQuery, Box, FormHelperText } from '@material-ui/core';
// Custom components
import DashboardLayout from "../../layouts/dashboardLayout";
import {
  AppBar,
  Button,
  PaginationBar,
  Navbar,
  NavbarSearch,
  ConfirmationModal,
  Table,
  InputField
} from "../../customComponents";
import * as Yup from 'yup';
import { useFormik } from 'formik';
// Constants
import { AppImages } from "../../themes/appImages";
import { Colors } from "../../themes/colors";
import { appConstants, ValidationConstants } from "../../themes/constants";
// utils methods
import { useStyles } from "../../styles";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  handleNavigationStateAction,
  getTerminalListAction,
  deleteTerminalAction,
  exportFileAction,
  importFileAction,
  getTerminalAreaListAction,
} from "../../store/actions";
import {
  validateImageRatio,
  validateImageRatioOneByOne,
} from "../../utils/helpers";
import { toast } from "react-toastify";
import {
  Grid,
  Select,
  useTheme,
  FormHelperText,
  Collapse,
  Fade,
} from "@material-ui/core";
import {
  convertFromRaw,
  ContentState,
  convertToRaw,
  Editor,
  convertFromHTML,
  EditorState,
  RichUtils,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import "draft-js/dist/Draft.css";
import RichEditorExample from "../../customComponents/textEditor";
import { deleteImage, editNewsAction, loaderStart } from "../../store/actions/newsManagementActions";
import htmlToDraft from "html-to-draftjs";
import FullScreenDialog from "../../customComponents/previewModal";
import TextEditor from "../../customComponents/ckEditor";

const validationSchema = Yup.object().shape({
  region: Yup.string()
    .required("Please enter region"),
    heading: Yup.string().required("Please enter title.").min(2,"News Heading should be at least 2 characters long."),
  news: Yup.string().required("Please enter news."),

})

export const EditNews = () => {
  const styleProps = { searchable: true };
  const location = useLocation();

  const news = location?.state?.state?.news;

  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty()
  );
  const [headingEditorState, setHeadingEditorState] = React.useState(() =>
    EditorState.createEmpty()
  );
  const [image, setImage] = useState(appConstants.baseURL + news?.media);
  const [imageName, setImageName] = useState(news?.media);
  const [imageUrl, setImageUrl] = useState(news?.media);
  const [errors, setErrors] = useState(null);
  const [terminalerrors, setTerminalErrors] = useState(null);
  const [newserrors, setNewsErrors] = useState("");
  const [newsHeadingerrors, setNewsHeadingerrors] = useState("");
  const [terminalTouched, setTerminalTouched] = useState(null);
  const [newsTouched, setNewsTouched] = useState(null);
  const [headingNewsTouched, setHeadingNewsTouched] = useState(null);
  const [errorName, setErrorName] = useState(null);
  const [terminalArea, setTerminalArea] = useState(news?.regionId);
  const [terminalName, setTerminalName] = useState("");
  const [previewModal, setPreviewModal] = useState(false);
  const [uploadedImages, setUploadedImages] = useState([])
  const [headingPreview, setHeadingPreview] = useState(false);
  const [desPreview, setDesPreview] = useState(false);
  const [headingData, setHeadingData] = useState(news?.title)
  const[loadImage,setLoadImage]=useState(false)
  const [descriptionData, setDescriptionData] = useState(news?.description)
  const [elements, setElements] = useState([])
  const [maxChar, setMaxChar] = useState(0)
  const state = useSelector((state) => state.newsManagementReducer);

  const { isTableDataLoading } = useSelector((state) => state.newsManagementReducer)
  const [onClickBackButton, setOnClickBackButton] = useState(false)

  const editorRef = useRef();
  const { isLoading } = state;


  // Lifecycle Hooks
  useEffect(() => {
    document.title = appConstants.headerTitle.editNews;
    dispatch(getTerminalAreaListAction("", null, null, "", ""));

  }, []);

  // Hooks declarations
  const classes = useStyles(styleProps);
  // const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  // const matches = useMediaQuery(theme.breakpoints.down('xs'));

  const { terminalAreaListing } = useSelector(
    (state) => state.terminalAreaManagementReducer
  );

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      validateImageRatioOneByOne(event.target.files[0])
        .then(() => {
          setImageUrl(event.target.files[0]);
          let splitName = event.target.files[0]?.name.split(".");
          setImageName(splitName[0]);
          let reader = new FileReader();
          reader.onload = (e) => {
            setImage(e.target.result);
          };
          reader.readAsDataURL(event.target.files[0]);
        })
        .catch(() =>
          toast.error(ValidationConstants.image.invalidRatioOneByOne)
        );
    }
  };

  const handleClick = () => {

    if (terminalArea.length == 0) {
      setTerminalTouched(true);
      setTerminalErrors(ValidationConstants.terminalArea.empty);
    }
  };

  const initialValues = {
    region: news?.regionId,
    news:news?.title,
    heading:news?.description
  }
  
  const onSubmit = (values) => {
    if (maxChar > 500) {
      toast.error('News should be maximum 500 characters long.')
      return false;
    }
    dispatch(deleteImage(elements))
    dispatch(editNewsAction(values.region, imageUrl, values.heading, values.news,news._id));
  }

  
  const handlePreview = () => {
    setPreviewModal(true);
  }



  const handleCallback = (data) => {
    setElements(data)
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit,
  });

  const handleCallback1 = (data) => {
  
    setLoadImage(data)
}

const deleteCount = (e) => {
  console.log(e,"ashdjasfkaji")
  if(e==false){
  let b = JSON.parse(localStorage.getItem("increment"))
    localStorage.setItem("increment", b - 1)
  }
}

const increment1 = () => {
  let b = JSON.parse(localStorage.getItem("increment"))
  if (b == null) {
    localStorage.setItem("increment", 1)
  }
  else {
    localStorage.setItem("increment", b + 1)
  }
  // localStorage.setItem("increment",b)
}


  return (
    <Spin
      size="large"
      spinning={isLoading}
      wrapperClassName={classes.tableSpinner}
      className={classes.antSpin}
    >
      <DashboardLayout>

        {previewModal && (
          <FullScreenDialog
            // title="Alert"
            // text={appConstants.confirmDeleteNews}
            visible={previewModal}
            markup={editorState.getCurrentContent()}
            heading={headingEditorState.getCurrentContent()}
            terminalArea={terminalArea}
            imageUrl={image}
            headingData={formik?.values?.news}
            descriptionData={descriptionData}
            regionName={news?.region}
            onCancel={() => {
              setPreviewModal(false);
            }}
          />
        )}
        <AppBar
          breadcrumbs={[
            { route: "/news-terminal", name: appConstants.newsManagement },
            { route: "/edit-news", name: "Edit News Details" },
          ]}
        />
        <Grid container className={classes.buttonBarWrapper}></Grid>
        <Navbar title={appConstants.editNews} />
        <Card className={classes.card}>
          <Grid container>
            <Grid className={classes.tableContainerRow}>
              <form onSubmit={formik.handleSubmit} noValidate>
                <BSTable striped bordered>
                  <tbody>


                    <tr>
                      <td className={classes.rowKey}>Region</td>
                      <td className={classes.rowValue}>
                        <div className={classes.inputWrapper}>
                          <Select
                            native
                            className={classes.selectInput}
                            value={formik.values.region}
                            onChange={formik.handleChange}
                            inputProps={{
                              name: "region",
                              id: "region",
                            }}
                          >
                            <option value="">Select Region</option>
                            {terminalAreaListing.map((terminalArea, index) => {
                              return (
                                <option value={terminalArea._id}>
                                  {terminalArea.region_name}
                                </option>
                              );
                            })}
                          </Select>
                          {formik.errors.region && (
                            <FormHelperText error id="component-error-text">
                              {formik.errors.region}
                            </FormHelperText>
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className={classes.rowKey}>Title</td>
                      <td className={classes.rowValue}>
                        <div className={classes.inputWrapper}>
                          <InputField
                            id="heading"
                            placeholder="Title"
                            name="heading"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            type="heading"
                            value={formik.values.heading.trimLeft()}
                            maxLength={50}
                          />
                          {formik.errors.heading && <FormHelperText error id="component-error-text">{formik.errors.heading}</FormHelperText>}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className={classes.rowKey}>News</td>
                      <td className={classes.rowValue}>
                        <div className={classes.editorWrapper} style={{maxWidth:"1000px"}}>
                          <TextEditor
                          loaderStart={(type) => dispatch(loaderStart(type))}
                          imageCount={(e) => {
                            increment1()
                          }}
                            id="heading"
                            value={formik.values.news}
                            onChange={(e, editor) => {
                              const data = editor?.getData()
                              formik.setFieldValue("news", data)
                            }} 
                            placeholder="Enter heading here..."
                            getData={(data) => handleCallback(data)}
                            loaderimages={(data) => handleCallback1(data)}
                            passChar={(t) => setMaxChar(t)}
                            deleteCalled={(e) => deleteCount(e)}
                          />
                          {formik.errors.news && (
                            <FormHelperText error id="component-error-text">
                              {formik.errors.news}
                            </FormHelperText>
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className={classes.rowKey}></td>
                      <td className={classes.rowValue}>
                      {isTableDataLoading == false ?
                          <Button
                            type="submit"
                            title="Update"
                            disabled={isTableDataLoading == true}
                         
                          />
                          :
                            <Spin
                            className="new_spin"
                            
                             size="large"
                             spinning={true}
                            //  wrapperClassName={classes.tableSpinner}
                            //  className={classes.antSpin}
                            >
                            </Spin>
                          
                        }
                        <Button
                          id='buttonnewww'
                          title="Preview"
                          // disabled={
                          //   !imageUrl ||
                          //   !terminalArea ||
                          //   desPreview ||
                          //   headingPreview
                          //     ? true
                          //     : false
                          // }
                          onClick={() => {
                            handlePreview();
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </BSTable>
              </form>
            </Grid>
          </Grid>
        </Card>
      </DashboardLayout>
    </Spin>
  );
};
