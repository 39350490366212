import { apiConstants as types } from '../../themes/constants'
import { updatePagination } from '../../utils/helpers'
import localStorage from '../../utils/localStorage'
const initialState = {
    isLoading: false,
    isTableDataLoading: false,
    result: {},
    deletedTerminal: null,
    stateListing: [],
    statePaging: {},
    stateDetails: {},
    errorState: null,
    currentPage: 1,
    timezones: [
        { name: "America/Adak", offset: 600 },
        { name: "America/Anchorage", offset: 540 },
        { name: "America/Boise", offset: 420 },
        { name: "America/Chicago", offset: 360 },
        { name: "America/Denver", offset: 420 },
        { name: "America/Detroit", offset: 300 },
        { name: "America/Indiana/Indianapolis", offset: 300 },
        { name: "America/Indiana/Knox", offset: 360 },
        { name: "America/Indiana/Marengo", offset: 300 },
        { name: "America/Indiana/Petersburg", offset: 300 },
        { name: "America/Indiana/Tell_City", offset: 360 },
        { name: "America/Indiana/Vevay", offset: 300 },
        { name: "America/Indiana/Vincennes", offset: 300 },
        { name: "America/Indiana/Winamac", offset: 300 },
        { name: "America/Juneau", offset: 540 },
        { name: "America/Kentucky/Louisville", offset: 300 },
        { name: "America/Kentucky/Monticello", offset: 300 },
        { name: "America/Los_Angeles", offset: 480 },
        { name: "America/Menominee", offset: 360 },
        { name: "America/Metlakatla", offset: 540 },
        { name: "America/New_York", offset: 300 },
        { name: "America/Nome", offset: 540 },
        { name: "America/North_Dakota/Beulah", offset: 360 },
        { name: "America/North_Dakota/Center", offset: 360 },
        { name: "America/North_Dakota/New_Salem", offset: 360 },
        { name: "America/Phoenix", offset: 420 },
        { name: "America/Sitka", offset: 540 },
        { name: "America/Yakutat", offset: 540 },
        { name: "Pacific/Honolulu", offset: 600 },
    ],
    timezoneList: []
}

export const stateListingReducer = (state = initialState, action) => {
    // console.log(action, "action")
    switch (action.type) {

        // State List Management Cases
        case types.CLEAR_STATE:
            if (action.key === "clearTerminalAreaDetailsState") {
                return {
                    ...state, stateDetails: {},
                }
            } else {
                return { ...state }
            }
        // Get user list
        case types.API_GET_STATE_LIST_LOAD:

            return { ...state, isLoading: true, errorState: null, }
        case types.API_GET_STATE_LIST_SUCCESS:

            return { ...state, isLoading: false, stateListing: action.result.listStates, statePaging: action.result.paging, currentPage: action.result?.paging?.page?.currentPage }

        // Add terminal area
        case types.API_ADD_STATE_LIST_LOAD:
            return { ...state, isLoading: true, errorState: null, }
        case types.API_ADD_STATE_LIST_SUCCESS:
            return { ...state, isLoading: false }

        // Edit terminal area
        case types.API_GET_STATE_LIST_DETAILS_LOAD:
            return { ...state, isTableDataLoading: true, errorState: null, }
        case types.API_STATE_LIST_DETAILS_SUCCESS:
            return { ...state, isTableDataLoading: false, stateDetails: action.result.state_details }

        // Edit terminal area
        case types.API_EDIT_STATE_LIST_LOAD:
            return { ...state, isLoading: true, errorState: null, }
        case types.API_EDIT_STATE_LIST_SUCCESS:
            return { ...state, isLoading: false }

        // Delete terminal area
        case types.API_DELETE_STATE_LIST_LOAD:
            return { ...state, isLoading: true, errorState: null, }
        case types.API_DELETE_STATE_LIST_SUCCESS:
            // Delete item from list
            let indexToDelete = state.stateListing.findIndex(value => value._id === action.stateId)
            state.stateListing.splice(indexToDelete, 1)

            return { ...state, isLoading: false, stateListing: [...state.stateListing], deletedTerminal: action.stateId }

        // Export file area
        case types.API_EXPORT_LISTING_FILE_LOAD:
            return { ...state, isLoading: true, errorState: null, }
        case types.API_EXPORT_LISTING_FILE_SUCCESS:
            return { ...state, isLoading: false }

        // Import file area
        case types.API_IMPORT_LIST_FILE_LOAD:
            return { ...state, isLoading: true, errorState: null, }
        case types.API_IMPORT_LIST_FILE_SUCCESS:
            return { ...state, isLoading: false }

        // get timezone list
        case types.API_TIMEZONE_LIST_LOAD:
            return { ...state, isTableDataLoading: true, }
        case types.API_TIMEZONE_LIST_SUCCESS:
            console.log(action.result, "actionaction")
            return { ...state, isTableDataLoading: false, timezoneList: action.result }

        //Terminal area management failed cases
        case types.API_STATE_LIST_MANAGEMENT_FAILED:
            
            console.log('r', action.errorState)
            return {
                ...state, isLoading: false, isTableDataLoading: false, stateListing: [],
                statePaging: {}, errorState: action.errorState,
            }
        case types.API_STATE_LIST_MANAGEMENT_ERROR:
            console.log('r', action.errorState)
            return {
                ...state, isLoading: false, isTableDataLoading: false, stateListing: [],
                statePaging: {}, errorState: action.errorState,
            }

        //Default case
        default:
            return { ...state }

    }
}