import React, { useEffect, useState, useLayoutEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { AppImages } from "../themes/appImages";
import { CaretDownOutlined, CaretLeftOutlined, CaretUpOutlined } from '@ant-design/icons'
import moment from "moment";
import Slider from "react-slick";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Legend, Tooltip as CustomTooltip } from "recharts";
import { appConstants } from "../themes/constants";
// import { convertToRaw } from "draft-js";
// import draftToHtml from "draftjs-to-html";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: "relative",
    },
    title: {
        // marginLeft: theme.spacing(2),
        // flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const images = [
    {
        image: AppImages.image1,
        logo: AppImages.logo1,
    },
    {
        image: AppImages.image2,
        logo: AppImages.logo2,
    },
    {
        image: AppImages.image3,
        logo: AppImages.logo3,
    },
    {
        image: AppImages.image1,
        logo: AppImages.logo4,
    },
    {
        image: AppImages.image2,
        logo: AppImages.logo5,
    },
    {
        image: AppImages.image3,
        logo: AppImages.logo6,
    },
];

var settings = {
    //   dots: true,
    infinite: true,
    speed: 3000,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    swipeToSlide: true,

    // autoplay: true,
    // autoplaySpeed: 5000,
    cssEase: "ease-out",
    // draggable: true,
    // easing: "bounce",
    // nextArrow: <CarouselArrow direction="right" />,
    // prevArrow: <CarouselArrow direction="left" />,
    responsive: [
        {
            breakpoint: 1420,
            settings: {
                slidesToShow: 6,
                slidesToScroll: 1,
                initialSlide: 0,
                infinite: true,
                speed: 3000,
                // easing: "bounce",
                // autoplay: true,
                // autoplaySpeed: 5000,
                swipeToSlide: true,
                cssEase: "ease-out",
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 6,
                slidesToScroll: 1,
                initialSlide: 0,
                infinite: true,
                speed: 3000,
                // easing: "bounce",
                // autoplay: true,
                // autoplaySpeed: 5000,
                swipeToSlide: true,
                cssEase: "ease-out",
            },
        },
    ],
};

export default function TerminalBoardWebsite(props) {
    const classes = useStyles();
    const { visible, onCancel, imageUrl, markup, heading, cardBackground, regionPickerColor, graphCurrentColor,
        fontPickerColor, typeOfBackground, backgroundImageWebsite, weatherColor, regionName, allNews, graphAllBars,
        backgroundVideo, fontFamily, selectCardBackground, backgroundColor, typeOfBackgroundColor } = props;
    const h = window.innerHeight;
    const w = window.innerWidth;
    const [expand, setExpand] = useState(!false);
    const [backgroundImage, setBackgroundImage] = useState(null)
    const [counter, setCounter] = useState(0)
    const [graphWidth, setGraphWidth] = useState(0)
    const [videoHeight, setVideoHeight] = useState(0)
    const [videoWidth, setVideoWidth] = useState(0)
    //   const rawContentState = convertToRaw(markup);
    //   const headingState = convertToRaw(heading);
    //   const markupHtml = draftToHtml(rawContentState);
    //   const headingHtml = draftToHtml(headingState);

    useEffect(() => {
        setBackgroundImage(backgroundImageWebsite)
    }, [])

    const handleCancel = () => {
        onCancel();
    };
    let navbarTogglerClass = `collapse navbar-collapse  justify-content-end`;
    let color1 = cardBackground.cardBackgroundLinear1Color
    let color2 = cardBackground.cardBackgroundLinear2Color
    let backgroundLinerColor1 = backgroundColor.backgroundLinear1Color
    let backgroundLinerColor2 = backgroundColor.backgroundLinear2Color

    const customTooltip = ({ active, payload, label }) => {
        if (active) {
            return (
                <div className="custom-tooltip">
                    {/* <p className="label">{`${payload[0].value}m${payload[0].value >= 40 ? ' Busier than usual': ' Usually not too busy'} `}</p> */}
                    <p className="label">{`${payload[0].value}m`}</p>
                    {/* <p className="desc">{`${label} : ${payload[0].value}${payload[0].value >= 40 ? ' Usually so busy': ' Usually not too busy'} `}</p> */}
                </div>
            );
        }

        return null;
    };

    useLayoutEffect(() => {
        widthGraph()
    }, [w, h])

    const widthGraph = () => {
        setGraphWidth(
            w > 1500
                ? 260
                : w > 1400
                    ? 240
                    : w > 1300
                        ? 200
                        : w > 1200
                            ? 210
                            : w > 1100
                                ? 180
                                : w > 1000
                                    ? 165
                                    : w > 900
                                        ? 140
                                        : w > 769
                                            ? 140
                                            : 260
        )

    }

    const chartContainer = (data) => {
        const dataArray = [
            { name: "12a", uv: 20 },
            { name: "1a", uv: 20 },
            { name: "2a", uv: 20 },
            { name: "3a", uv: 20 },
            { name: "4a", uv: 20 },
        ];

        return (
            <BarChart
                height={70}
                width={graphWidth}
                data={dataArray}
                margin={{
                    top: 5,
                    right: 5,
                    left: -55,
                    bottom: 5
                }}
            >
                {/* <CartesianGrid strokeDasharray="2 2" /> */}
                <XAxis dataKey="name" stroke="#fff" domain={[0, dataArray]} />
                <YAxis stroke="#fff" type="number" domain={[0, dataArray]} display="none" />
                {/* <CustomTooltip  cursor={{ fill: 'transparent', }} /> */}
                <Legend />
                <Bar
                    radius={[10, 10, 10, 10]}
                    barSize={15}
                    dataKey="uv"
                    fill={graphAllBars}
                >
                    {
                        dataArray.map((entry, index) => (
                            <Cell className='custom_cell' fill={index === 1 ? graphCurrentColor : graphAllBars} />
                        ))
                    }
                </Bar>
            </BarChart>
        )
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setCounter(counter + 1);
            if (counter == allNews?.length - 1) {
                setCounter(0)
            }
        }, 10000);
        return () => {
            clearTimeout(timer);
        };
    }, [counter]);

    return (
        <div>
            <Dialog
                fullScreen
                open={visible}
                onClose={handleCancel}
                TransitionComponent={Transition}
            >
                <header className={`fixed-top clearHeader header-view sticky `}>
                    <div className="container-fluid adjust">
                        <nav className="navbar navbar-expand-lg new_navbar">
                            <div
                                style={{ justifyContent: "space-between", display: "contents" }}
                            >
                                <img src={AppImages.logoWebsite} className="imgLog" />
                                <>
                                    <button
                                        className="navbar-toggler"
                                        type="button"
                                        data-toggle="collapse"
                                        data-target="#collapsibleNavbar"
                                        aria-expanded={true}
                                        style={{ color: "#fff" }}
                                        onClick={() => null}
                                    >
                                        <IconButton
                                            edge="start"
                                            color="inherit"
                                            onClick={handleCancel}
                                            aria-label="close"
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </button>
                                    <div
                                        className={navbarTogglerClass}
                                        style={{ color: "#fff" }}
                                        id="collapsibleNavbar"
                                    >
                                        <IconButton
                                            edge="start"
                                            color="inherit"
                                            onClick={handleCancel}
                                            aria-label="close"
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </div>
                                </>
                            </div>
                        </nav>
                    </div>
                </header>
                <section
                    className={`sectionTopTerminalBoardArea 
                    `}

                    style={{
                        backgroundImage: typeOfBackground == "image" ? `url(${backgroundImage})` :
                            typeOfBackground == "video" ? null : `linear-gradient(to top, ${backgroundLinerColor1}, ${backgroundLinerColor2})`
                        ,
                        height: "100vh",
                        width: "100%",
                        backgroundColor: (typeOfBackground == "color" && typeOfBackgroundColor == "singleColor") && backgroundColor,
                        // background: (typeOfBackground == "color"&& typeOfBackgroundColor == "linearGradient") && `linear-gradient(to top, ${backgroundLinerColor1}, ${backgroundLinerColor2})`
                    }}
                >
                    {typeOfBackground == "video" &&
                        <video
                            id='video_height'
                            onLoadedMetadata={(e) => {
                                const vid = document.getElementById("video_height");
                                const height = vid.videoHeight;
                                const width = vid.videoWidth;
                                setVideoHeight(height)
                                setVideoWidth(width)
                                console.log(height, width, "width")
                            }}
                            className="video-home"
                            aria-expanded
                            // height="100%"
                            // width="100%"
                            style={{
                                width: "100vw",
                                height: "100vh",
                                position: "fixed",
                                top: 0,
                                left: 0,
                                objectFit: videoHeight > videoWidth ? 'contain' : 'inherit',
                                // width: "100%",
                            }}
                            autoPlay
                            loop={true}
                            muted
                            playsInline
                        >
                            <source src={backgroundVideo} type="video/mp4" />
                        </video>}
                    <div
                        className="container-fluid adjust screenActive"
                    // style={{ marginTop: height * 0.01 }}
                    >
                        <div className="terminalAreaHeader">
                            <p
                                // className="terminalAreaName"
                                style={{
                                    flexDirection: "row",
                                    display: "flex",
                                    color: regionPickerColor,
                                    fontSize: "5vh",
                                    fontWeight: "500",
                                    fontFamily: fontFamily == "sans-serif" ?
                                        "Montserrat-SemiBold,sans-serif,Arial" : fontFamily,
                                    zIndex: 1,
                                    width: "51%"
                                    // alignItems: "center",
                                    // justifyContent: "center",
                                }}
                            >
                                {regionName ? regionName : "PORT OF NY|NJ"}
                                <div className="arrow-down"
                                    style={{
                                        marginTop: "-8px",
                                        zIndex: 1000, marginLeft: "12%", width: "40%",
                                    }}>
                                    <CaretDownOutlined
                                        style={{ color: regionPickerColor ? regionPickerColor : "#3387ed", fontSize: "6vh" }} ></CaretDownOutlined>
                                </div>
                            </p>
                            <div className="terminalAreaHeaderSub">

                                <p className="terminalAreaTime"
                                    style={{
                                        fontFamily: fontFamily == "sans-serif" ?
                                            "Montserrat-SemiBold,sans-serif,Arial" : fontFamily
                                        , color: weatherColor, zIndex: 1
                                    }}>
                                    {moment().format(" HH:mm A")}
                                </p>
                                <div className="terminalAreaHeaderTempToday">
                                    <div>
                                        <p
                                            className="terminalAreaTimeToday"
                                            style={{
                                                minWidth: "50px",
                                                fontFamily: fontFamily == "sans-serif" ?
                                                    "Montserrat-SemiBold,sans-serif,Arial" : fontFamily,
                                                color: weatherColor, position: "relative"
                                            }}
                                        >
                                            Today
                                        </p>
                                    </div>
                                    <div className="tempDiv">
                                        <img
                                            src={AppImages.sun}
                                            style={{
                                                width: "40px",
                                                height: "40px",
                                                // borderRadius: "3vw",
                                                marginRight: "10px",
                                                zIndex: 1
                                            }}
                                            className="sun"
                                        />
                                        <pre className="terminalAreaTimeToday"
                                            style={{
                                                color: weatherColor, zIndex: 1, fontFamily: fontFamily == "sans-serif" ?
                                                    "Montserrat-SemiBold,sans-serif,Arial" : fontFamily,
                                            }}>
                                            25<sup>°</sup>
                                        </pre>
                                    </div>
                                </div>
                                <div className="terminalAreaHeaderTempToday">
                                    <div>
                                        <p className="terminalAreaTimeToday"
                                            style={{
                                                fontFamily: fontFamily == "sans-serif" ?
                                                    "Montserrat-SemiBold,sans-serif,Arial" : fontFamily,
                                                color: weatherColor, position: "relative"
                                            }}>Tonight</p>
                                    </div>
                                    <div className="tempDiv" style={{ marginLeft: -w * 0.022 }}>
                                        <div>
                                            <img
                                                src={AppImages.moon}
                                                style={{
                                                    width: "40px",
                                                    height: "40px",
                                                    // borderRadius: "3vw",
                                                    marginRight: "10px",
                                                    position: "relative",
                                                }}
                                                className="moon"
                                            />
                                        </div>
                                        <div>
                                            <pre className="terminalAreaTimeToday"
                                                style={{
                                                    color: weatherColor, position: "relative", fontFamily: fontFamily == "sans-serif" ?
                                                        "Montserrat-SemiBold,sans-serif,Arial" : fontFamily,
                                                }}>
                                                25<sup>°</sup>
                                            </pre>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid adjust screenActive">
                        <div
                            className="row news"
                            style={{
                                marginTop: "0.5vw",
                                alignItems: "center",
                                justifyContent: "center",
                                display: "flex",
                                overflow: 'hidden',
                                height: '46vh',
                            }}
                        >
                            {/* <div className="col-sm-3">
                                {allNews[counter]?.media?.length > 0 &&
                                    <img
                                        src={appConstants.baseURL + allNews[counter]?.media}
                                        style={{
                                            display: !expand ? "none" : "flex",
                                            borderRadius: "1vw",
                                            height: "12vw",
                                            width: "12vw",
                                        }}
                                        className="animate-flicker terminalAreaImage"
                                    />}
                            </div> */}
                            <div className="col-sm">
                                <div className="text-img">
                                    {expand && (
                                        <>
                                        <div className="newsTextHeading animate-flicker"
                                        >
                                            <div
                                                className="ck-content"
                                                dangerouslySetInnerHTML={{
                                                    __html: allNews[counter]?.title,
                                                }}
                                                style={{
                                                    // backgroundColor: "red",
                                                    // color: "red",
                                                    // display: !expand ? "none" : "flex",
                                                    overflow: "hidden",
                                                }}
                                            />
                                            </div>
                                            {/* <div
                                                className="newsText animate-flicker"
                                                dangerouslySetInnerHTML={{
                                                    __html: allNews[counter]?.description,
                                                }}
                                                style={{
                                                    // backgroundColor: "red",
                                                    // color: "red",
                                                    // display: !expand ? "none" : "flex",
                                                    overflow: "hidden",
                                                }}
                                            /> */}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="screenActive"
                        style={{
                            marginTop: "10px",
                            position: "absolute",
                            left: 0,
                            margin: "0 auto",
                            bottom: "1vw",
                            width: "100%",
                        }}
                    >
                        {images?.length > 0 && (
                            <Slider {...settings} className="terminalBoard">
                                {images.map((img, index) => (
                                    <div key={index}>
                                        {h ? (
                                            <div
                                                style={{
                                                    marginLeft: 7,
                                                    marginRight: 7,
                                                    borderRadius: w * 0.015,
                                                    backgroundColor: selectCardBackground == "singleColor" && cardBackground,
                                                    backgroundImage: selectCardBackground == "linearGradient" &&
                                                        // `linear-gradient(#e66465, #9198e5)`
                                                        `linear-gradient(to bottom, ${color1}, ${color2})`
                                                    // width: "13vw",
                                                }}
                                            // className="terminalMain"
                                            >
                                                <div
                                                    style={{
                                                        // padding: height * 0.01,
                                                        alignItems: "center",
                                                        // justifyContent: "center",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            // marginBottom: "1vw",
                                                            marginTop: "1.5vw",
                                                        }}
                                                    >
                                                        <img
                                                            src={img.logo}
                                                            className="terminalImageLogo"
                                                            style={{
                                                                // height: w * 0.04,
                                                                height: "4vw",
                                                                width: "8vw",
                                                                objectFit: "contain",
                                                                // marginBottom: "1vw",
                                                                // resize: "center",
                                                                // height: height * 0.07,
                                                                // width: height * 0.15,
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    style={
                                                        {
                                                            // background: "red",
                                                            // marginBottom: "2vw",
                                                            // marginTop: "0.5vw",
                                                        }
                                                    }
                                                ></div>
                                                <div
                                                    style={{
                                                        height: "100%",
                                                        display: "flex",
                                                        justifyContent: 'center',
                                                        alignItems: "center",
                                                        // borderTop: "2px solid white",
                                                        // borderBottom: "2px solid white",
                                                        marginBottom: "5px",
                                                        width: "100%"
                                                        // overflow: 'hidden',
                                                        // transition: '0.7s'
                                                    }}
                                                >
                                                    {chartContainer(img)}
                                                </div>
                                                <div
                                                    style={{
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        // marginTop: w * 0.015,
                                                    }}
                                                >
                                                    <p
                                                        // className="terminalName"
                                                        style={{
                                                            fontSize: "1.2vw",
                                                            lineHeight: "2.3vw",
                                                            paddingLeft: h * 0.005,
                                                            paddingRight: h * 0.005,
                                                            color: fontPickerColor,
                                                            fontFamily: fontFamily == "sans-serif" ?
                                                                "Montserrat-SemiBold,sans-serif,Arial" : fontFamily
                                                            // paddingBottom: "1vw",
                                                        }}
                                                    >
                                                        Avg. Wait Time
                                                    </p>
                                                    <div
                                                        style={{
                                                            // background: "red",
                                                            display: "flex",
                                                            // marginBottom: "1vw",
                                                            alignItems: "flex-end",
                                                            justifyContent: "flex-end",
                                                            // height: "4vw",
                                                        }}
                                                    >
                                                        {/* <img src={AppImages.clock} width="18px" height="18px"
                                                        style={{marginBottom: "0.3vw",marginRight:"3px"}}
                                                        ></img> */}
                                                        <span
                                                            //   className="terminalName"
                                                            style={{
                                                                fontSize: "1.5vw",
                                                                // lineHeight: "4vw",
                                                                paddingLeft: h * 0.005,
                                                                paddingRight: h * 0.005,
                                                                marginBottom: "-0.1vw",
                                                                color: fontPickerColor,
                                                                fontFamily: fontFamily == "sans-serif" ?
                                                                    "Montserrat-SemiBold,sans-serif,Arial" : fontFamily
                                                            }}
                                                        >
                                                            {20}
                                                        </span>
                                                        <span
                                                            //   className="terminalName"
                                                            style={{
                                                                fontSize: "1.2vw",
                                                                // lineHeight: "4vw",
                                                                paddingLeft: h * 0.005,
                                                                paddingRight: h * 0.005,
                                                                color: fontPickerColor,
                                                                fontFamily: fontFamily == "sans-serif" ?
                                                                    "Montserrat-SemiBold,sans-serif,Arial" : fontFamily
                                                                // paddingBottom: "1vw",
                                                            }}
                                                        >
                                                            Mins
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <span>132135</span>
                                        )}
                                    </div>
                                ))}
                            </Slider>
                        )}
                    </div>
                </section>
            </Dialog>
        </div>
    );
}
