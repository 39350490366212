import React, { useState, useEffect, useRef } from 'react';
// Navigation
import { useHistory, useLocation } from 'react-router-dom'
// Styles
import 'antd/dist/antd.css'
import '../dashboard.scss'
// Third party Components
// antd
import { Image, Spin, Tooltip } from 'antd';
import { Card, Table as BSTable, } from 'react-bootstrap';
// Mui Components
import { Grid, Select, FormHelperText, Input, TextField } from '@material-ui/core';
// Custom components
import DashboardLayout from '../../layouts/dashboardLayout'
import { InputField, Navbar, AppBar, Button } from '../../customComponents'
import { Select as AntdSelect } from 'antd';
import { toast } from "react-toastify";
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
// Constants
import { AppImages } from '../../themes/appImages'
import { ValidationConstants, appConstants } from '../../themes/constants'
import { handleNavigationStateAction, } from '../../store/actions'
// utils methods
import { useStyles } from '../../styles'
// Redux
import { useSelector, useDispatch } from 'react-redux'
import localStorage from "../../utils/localStorage";
import { getBotUsersListAction, addPostAction, getUsersListAction } from '../../store/actions/postManagementAction';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export const AddPost = () => {
    // Hooks declarations
    const inputRef = useRef(null);
    const { ref: materialRef } = usePlacesWidget({
        apiKey: "AIzaSyAId9HPoVrtc6rDn9O-tAWERRJEelhkARc",
        // onPlaceSelected: (place) => console.log(place),
        // inputAutocompleteValue: "country",
        // options: {
        //     componentRestrictions: { country },
        // },
    });
    const postListingData = useSelector(state => state.PostManagementReducer)

    // Global state initialization
    const { isLoading, botUsersList, isTableDataLoading, usersList } = postListingData
    const classes = useStyles();
    const history = useHistory();
    const videoRef = useRef();
    const [image, setImage] = useState("")
    const [imageUrl, setImageUrl] = useState([]);
    const [searchValue, setSearchValue] = useState();
    const [taggedFriends, setTaggedFriends] = useState([]);
    const [addVideo, setAddVideo] = useState(null)
    const [videoFile, setVideoFile] = useState(null)
    const [addimage, setAddImage] = useState([])
    const [selectUser, setSelectUser] = useState("")
    const [description, setDescription] = useState("")
    const [postLocation, setPostLocation] = useState(null)
    const location = useLocation()
    const dispatch = useDispatch()

    let siderIndex = localStorage.getLayoutArr()
    let index = siderIndex?.findIndex((x) => x.key == "postManagement")
    let permissionsData = localStorage?.getPermissions()
    let employeeIndex = permissionsData?.findIndex((x) => x.name == "postManagement")

    useEffect(() => {
        setSelectUser(location?.state?.userId)
    }, [])

    useEffect(() => {
        document.title = appConstants.headerTitle.AddPost;

        if (permissionsData) {
            dispatch(handleNavigationStateAction(index, false))
        }
        else {
            dispatch(handleNavigationStateAction(3, false))
        }
        dispatch(getBotUsersListAction())
        dispatch(getUsersListAction(location?.state?.userId, 'tag'))
    }, [])

    const imageValidation = (value) => {
        let fileTypes = ["jpg", "jpeg", "png"]
        return (fileTypes.indexOf(value) > -1)
    }

    const handleImageChange = (event) => {

        if (event.target.files && event.target.files[0]) {
            let uploadFile = event.target.files[0]
            let splitName = uploadFile.name.split(".").pop().toLowerCase()
            let isSuccess = imageValidation(splitName)
            if (isSuccess) {
                let reader = new FileReader();
                reader.onload = (e) => {
                    let allImage = [...addimage]
                    allImage.push({ url: e.target.result })
                    setAddImage(allImage)
                    let imgArr = [...imageUrl]
                    let obj = {
                        uri: e.target.result,
                        file: event.target.files[0]
                    }
                    imgArr.push(obj)
                    setImageUrl(imgArr)
                };
                reader.readAsDataURL(event.target.files[0]);
                return
            }
            else {
                toast.error("Only .jpg, .jpeg and .png format images are allowed.")
            }
        }
    };

    const handleUserSearch = (value) => {
        if (value.length < 100) {
            setSearchValue(value);
            // dispatch(getUserListAction(value))
        }
    }
    const handleChange1 = (value) => {
        setTaggedFriends(value);
        // setSelectedTerminalError(value.length > 0 ? false : true)
    }

    const videoValidation = (value) => {
        let fileTypes = ["mp4", "mpg"]
        return (fileTypes.indexOf(value) > -1)
    }

    const handleChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            let uploadFile = event.target.files[0]
            let splitName = uploadFile.name.split(".").pop().toLowerCase()
            let isSuccess = videoValidation(splitName)
            if (isSuccess) {
                let reader = new FileReader();
                reader.onload = (e) => {
                    var media = new Audio(reader.result);
                    media.onloadedmetadata = function () {
                        if (media.duration > 30.526667) {
                            toast.error(appConstants.videoLimit)
                        }
                        else {
                            setAddVideo(e.target.result)
                            setVideoFile(event.target.files[0])
                        }
                    };
                };
                reader.readAsDataURL(event.target.files[0]);
                return
            }
            else {
                toast.error(appConstants.videoAllowed)
            }

        }
    };

    const handleSubmit = () => {
        if (description.trim().length == 0 && imageUrl.length == 0 && videoFile == null) {
            toast.error(appConstants.selectOneValidation)
        }
        else {
            dispatch(addPostAction({ selectUser: location?.state?.userId, description: description.trim(), image: imageUrl, video: videoFile, tagged_friends: taggedFriends, post_location: postLocation, }))
        }
    }


    const deleteImage = (indexx) => {
        let arr = [...addimage];
        arr = arr.filter((x, index) => index !== indexx)
        setAddImage(arr);
        setImageUrl(arr)
    }

    const deleteVideo = () => {
        setAddVideo("")
        setVideoFile(null)
    }

    return (
        <Spin
            size="large"
            spinning={isLoading}
            wrapperClassName={classes.tableSpinner}
            className={classes.antSpin}
        >
            <DashboardLayout>
                <AppBar breadcrumbs={[{ route: '/postListing', name: appConstants.postManagement },
                { route: '/add-post', name: appConstants.addPost, state: location?.state?.userId }]} />
                <Navbar title={appConstants.addPost} />
                <Card className={classes.card}>
                    <Grid container>
                        <Grid className={classes.tableContainerRow}>
                            <form >
                                <BSTable striped bordered className={classes.bsTableHeight}>
                                    {
                                        isTableDataLoading ? <thead className={classes.bsHeaderTable}>
                                            <Spin className={classes.tableDataSpinner} />
                                        </thead>
                                            :
                                            <tbody>
                                                <tr>
                                                    <td className={classes.rowKey}>Description</td>
                                                    <td className={classes.rowValue}>
                                                        <div className={classes.inputWrapper}>
                                                            <textarea
                                                                style={{ resize: "none" }}
                                                                className="textAreaNew"
                                                                placeholder="Description"
                                                                name="description"
                                                                type="description"
                                                                value={description.trimLeft()}
                                                                onChange={(e) => setDescription(e.target.value)}
                                                            />
                                                            {/* {formik.errors.email && <FormHelperText error id="component-error-text">{formik.errors.email}</FormHelperText>} */}
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={classes.rowKey}>
                                                        Images
                                                    </td>
                                                    <td className={classes.rowValue}>
                                                        <div className={classes.inputWrapper} style={{ display: "-webkit-box", marginBottom: "-10px", marginLeft: "4px" }}>
                                                            {
                                                                addimage.concat(1).map((item, index) => {
                                                                    return (
                                                                        <>
                                                                            {item?.url ? (
                                                                                <div style={{ marginRight: "5%" }}>
                                                                                    <Image
                                                                                        src={item?.url}
                                                                                        className={classes.terminalImage}
                                                                                        style={{ borderRadius: "50px", border: "2px solid lightgrey" }}
                                                                                    />
                                                                                    <div className='cross-icon' >
                                                                                        <img src={AppImages.close}
                                                                                            style={{ cursor: "pointer" }} width="12px" height="12px"
                                                                                            onClick={() => deleteImage(index)}></img>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                                :
                                                                                addimage.length < 4 &&
                                                                                <>
                                                                                    <input
                                                                                        accept=".png,.jpg,.jpeg"
                                                                                        className={classes.uploaderInput}
                                                                                        id="icon-button-file"
                                                                                        type="file"
                                                                                        // multiple
                                                                                        onChange={(e) => { handleImageChange(e) }}
                                                                                    />
                                                                                    <label
                                                                                        htmlFor="icon-button-file"
                                                                                        className={classes.imageManagementUploaderlabel}
                                                                                    >
                                                                                        <Tooltip
                                                                                            title={image ? appConstants.tooltip.changeImage : appConstants.tooltip.uploadImage}
                                                                                            placement="right"
                                                                                        >
                                                                                            <img
                                                                                                src={AppImages.addPhoto}
                                                                                                className={classes.terminalImage}
                                                                                            // onChange={(e) => {
                                                                                            //     handleImageChange(e)

                                                                                            // }}

                                                                                            />

                                                                                        </Tooltip>
                                                                                    </label>
                                                                                </>}
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={classes.rowKey}>
                                                        Video
                                                    </td>
                                                    <td className={classes.rowValue}>
                                                        <div className={classes.inputWrapper} style={{ display: "-webkit-box", marginBottom: "-10px", marginLeft: "4px" }}>
                                                            {addVideo ?
                                                                <div style={{ marginRight: "5%" }}>
                                                                    <div className='video-class' >
                                                                        <img src={AppImages.close}
                                                                            style={{
                                                                                position: "absolute",

                                                                            }}
                                                                            width="12px" height="12px" onClick={() => deleteVideo()}></img>
                                                                    </div>
                                                                    <video
                                                                        controls
                                                                        style={{
                                                                            width: "100%",
                                                                            objectFit: "contain",
                                                                            height: "200px"
                                                                        }}
                                                                    >
                                                                        <source src={addVideo}
                                                                            className={classes.terminalImage}
                                                                            type="video/mp4"
                                                                        ></source>
                                                                        <source src={addVideo}
                                                                            className={classes.terminalImage}
                                                                            type="video/mpeg"
                                                                        ></source>
                                                                    </video>

                                                                </div>
                                                                :
                                                                <>
                                                                    <input
                                                                        style={{ zIndex: "999999" }}
                                                                        accept="video/mp4,video/x-m4v,video/*,video/mov,video/mpeg"
                                                                        className={classes.uploaderInput}
                                                                        id="upload"
                                                                        type="file"
                                                                        onChange={(e) => {

                                                                            handleChange(e)
                                                                        }}

                                                                        ref={videoRef}
                                                                    />
                                                                    <label
                                                                        id="upload"
                                                                        className={classes.imageManagementUploaderlabel}
                                                                    >
                                                                        <Tooltip
                                                                            title={image ? appConstants.tooltip.changeVideo : appConstants.tooltip.uploadVideo}
                                                                            placement="right"
                                                                        >
                                                                            <img
                                                                                onClick={() => videoRef.current.click()}
                                                                                src={AppImages.addVideo}
                                                                                className={classes.terminalImage}

                                                                            />

                                                                        </Tooltip>
                                                                    </label>
                                                                </>}

                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={classes.rowKey}>
                                                        Tag Friends
                                                    </td>
                                                    <td className={classes.rowValue}>
                                                        <div className={classes.inputWrapper} style={{ marginTop: "10px" }}>
                                                            <div className="select-users" >
                                                                <AntdSelect
                                                                    mode="multiple"
                                                                    allowClear
                                                                    style={{
                                                                        width: '100%',
                                                                        cursor: 'default'
                                                                    }}
                                                                    placeholder="Search Friends"
                                                                    optionFilterProp='children'
                                                                    showSearch
                                                                    value={taggedFriends}

                                                                    filterSort={(optionA, optionB) => {
                                                                        optionA.value.toLowerCase().localeCompare(optionB.value.toLowerCase())
                                                                    }
                                                                    }
                                                                    onSearch={handleUserSearch}
                                                                    onChange={handleChange1}

                                                                >
                                                                    {usersList?.map(
                                                                        (data, index) => {
                                                                            return (
                                                                                <option value={data?._id}>
                                                                                    {data?.userName}
                                                                                </option>
                                                                            );
                                                                        }
                                                                    )}
                                                                </AntdSelect>

                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={classes.rowKey}>
                                                        Check In
                                                    </td>
                                                    <td className={classes.rowValue}>
                                                        <div className={classes.inputWrapper}>

                                                            <Autocomplete
                                                                className='post-locations'
                                                                apiKey="AIzaSyAId9HPoVrtc6rDn9O-tAWERRJEelhkARc"

                                                                value={postLocation}
                                                                onChange={(evt) => {
                                                                    setPostLocation(evt.target.value);
                                                                }}
                                                                onPlaceSelected={(place) => {
                                                                    setPostLocation(place.formatted_address)
                                                                }}
                                                            />

                                                        </div>

                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={classes.rowKey}></td>
                                                    <td className={classes.rowValue}>
                                                        <Button title="Add" onClick={() => handleSubmit()} />
                                                    </td>
                                                </tr>
                                            </tbody>
                                    }
                                </BSTable>
                            </form>
                            {/* //     )}
                            // </Formik> */}
                        </Grid>
                    </Grid>
                </Card>
            </DashboardLayout>
        </Spin>
    );
}
