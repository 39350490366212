import { apiConstants as types } from '../../themes/constants'

// Get post list Action
export const getPostListAction = (payload) => {
    console.log(payload, "asldakldjlkakdj")
    return {
        type: types.API_GET_POST_LIST_LOAD,
        payload
    }
}

// Get bot users Action
export const getBotUsersListAction = (payload) => {
    console.log(payload, "payload")
    return {
        type: types.API_GET_BOT_USERS_LIST_LOAD,
        payload
    }
}

// add post Action
export const addPostAction = (payload) => {
    return {
        type: types.API_ADD_POST_LIST_LOAD,
        payload
    }
}

// add post Action
export const getUsersListAction = (payload = null, listType = null) => {
    return {
        type: types.API_GET_USERS_LIST_LOAD,
        payload: payload,
        listType: listType
    }
}

// post details Action
export const getPostDetailsAction = (payload) => {
    return {
        type: types.API_GET_POST_LIST_DETAILS_LOAD,
        payload
    }
}

// like post Action
export const likePostAction = (payload) => {
    return {
        type: types.API_LIKE_POST_LOAD,
        payload
    }
}

// add user Action
export const addUserAction = (payload) => {
    return {
        type: types.API_ADD_BOT_USERS_LOAD,
        payload
    }
}

//delete post Action
export const deletePostAction = (payload) => {
    console.log(payload, "action")
    return {
        type: types.API_DELETE_POST_LIST_LOAD,
        payload
    }
}

// share post Action
export const sharePostAction = (payload) => {
    return {
        type: types.API_SHARE_POST_LOAD,
        payload
    }
}

// comment post Action
export const commentOnPostAction = (payload) => {
    return {
        type: types.API_COMMENT_ON_POST_LOAD,
        payload
    }
}

// like post Action
export const likeCommentAction = (payload) => {
    return {
        type: types.API_LIKE_COMMENT_LOAD,
        payload
    }
}

// delete comment Action
export const deleteCommentAction = (payload, mainCommentIndex) => {
    return {
        type: types.API_DELETE_COMMENT_LIST_LOAD,
        payload: payload,
        mainCommentIndex: mainCommentIndex
    }
}

// edit comment Action
export const editCommentAction = (payload) => {
    return {
        type: types.API_EDIT_COMMENT_LOAD,
        payload
    }
}
// edit post Action
export const editPostManagementAction = (payload) => {
    return {
        type: types.API_EDIT_POST_MANAGEMENT_LOAD,
        payload
    }
}

// Delete post Image
export const deletePostImageAction = (payload) => {
    return {
        type: types.API_EDIT_POST_DELETE_IMAGE_LOAD,
        payload
    }
}

// Clear Post Data
export const updatePostData = (payload, key) => {
    return {
        type: types.UPDATE_POST_DATA,
        payload,
        key
    }
}
// Clear Post Data
export const setBotUserID = (id) => {
    return {
        type: "SET_BOT_USER_ID",
        id
    }
}

// Clear Post details 
export const clearPostDetails = () => {
    return {
        type: "CLEAR_BOT_DETAILS",
    }
}
