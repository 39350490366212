import React, { useEffect, useState } from 'react'
// styles
import { useStyles } from '../../styles'
// Third party front-end libs
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card';
import Container from '@material-ui/core/Container';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar'
import CircularProgress from '@material-ui/core/CircularProgress'
// Third party component libs
import { useHistory, useParams, useLocation } from 'react-router-dom'
// Custom components
import TimelineLayout from '../../layouts/timelineLayout'
// Themes
import { AppImages } from '../../themes/appImages'
import { appConstants } from '../../themes/constants'
// Redux
import { useSelector, useDispatch } from 'react-redux'
import { handleClearStateAction, timelineDetailsAction } from '../../store/actions'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import moment from 'moment'

import { Colors } from '../../themes/colors'
import {
    isMobile
} from "react-device-detect";
import { Paper } from '@material-ui/core';
export const TimelineScreen = () => {

    const classes = useStyles()
    const history = useHistory()
    const location = useLocation()
    const dispatch = useDispatch()
    const params = useParams()
    const { timelineId } = params
    const { timelineDetails, isLoading, timelineErrorState, isTimeLineSuccess } = useSelector(state => state.terminalReportPostReducer)
    const [openAlert, setOpenAlert] = useState(isMobile)
    const [showCarousel, setShowCarousel] = useState(isMobile ? false : true)
    const [linker, setLinker] = useState('')
    const [timelineMediaArray, setTimelineMediaArray] = useState([])
    const [hrs, setHrs] = useState(0)
    const [mins, setMins] = useState(0)
    const [secs, setSecs] = useState(0)
    const [avgHrs, setAvgHrs] = useState(0)
    const [avgMins, setAvgMins] = useState(0)
    const [avgSecs, setAvgSecs] = useState(0)


    // Get query param from location
    const useQuery = () => {
        return new URLSearchParams(location.search);
    }

    let query = useQuery();

    // lifecycle hooks
    useEffect(() => {

        (function (b, r, a, n, c, h, _, s, d, k) {

            if (!b[n] || !b[n]._q) {

                for (; s < _.length;) c(h, _[s++]);

                d = r.createElement(a);

                d.async = 1;

                d.src = "https://cdn.branch.io/branch-latest.min.js";

                k = r.getElementsByTagName(a)[0];

                k.parentNode.insertBefore(d, k);

                b[n] = h;

            }

        })(

            window,

            document,

            "script",

            "branch",

            function (b, r) {

                b[r] = function () {

                    b._q.push([r, arguments]);

                };

            },

            { _q: [], _v: 1 },

            "addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking".split(

                " "

            ),

            0

        );

        // live key key_live_gdR80bsDCx2U3NAv3Fj7UhdczEn48iXz
        // tst key key_test_bc2mLomn1ztOcWOpT2izacjeEBg4d5U2
        // eslint-disable-next-line no-undef
        branch.init('key_live_fg6nRbib2CwY4PuFnK45kjibtAmpj710', function (err, data) {

            //api call

        });
        // eslint-disable-next-line no-undef
        branch.link({
            tags: ['FR8', 'fr8'],
            stage: 'new user',
            data: {
                mydata: timelineId,
                user_id: timelineId,
                // '$desktop_url': 'https://play.google.com/store/apps/details?id=com.whatsapp',
                '$ios_url': 'https://apps.apple.com/in/app/the-fr8-app/id1562162277',
                // '$ipad_url': 'https://play.google.com/store/apps/details?id=com.whatsapp',
                '$android_url': 'https://play.google.com/store/apps/details?id=com.whatsapp',
                '$og_app_id': '12345',
                '$og_title': 'FR8',
                '$og_description': 'Open in FR8 app',
                // '$og_url':'https://play.google.com/store/apps/details?id=com.whatsapp',
                // "$deeplink_path":'https://play.google.com/store/apps/details?id=com.whatsapp',
                "$always_deeplink": true,
            }
        }, function (err, link) {

            setLinker(link)
        });
        document.title = appConstants.headerTitle.timeline
        dispatch(handleClearStateAction("clearSidebarIndicesState"))
        dispatch(timelineDetailsAction(timelineId, query.get("type")))
    }, [])

    // make list to show in carouel after check if they are not protected
    useEffect(() => {
        timelineDetails?.routePostMedia?.map((media, index, array) => {
            let isMediaProtected = checkMediaProtected(media, index, array);
            if (!isMediaProtected) {
                timelineMediaArray.push(media)
            }
        })

        const getTime = () => {
            let hrs;
            let mins;
            let secs;

            hrs = Math.floor(timelineDetails?.minute / 3600)
            mins = Math.floor((timelineDetails?.minute - (hrs * 3600)) / 60)
            secs = Math.floor(timelineDetails?.minute % 60)
            setHrs(hrs)
            setMins(mins)
            setSecs(secs)
        }

        const getAvgTime = () => {
            let hrs;
            let mins;
            let secs;

            hrs = Math.floor(timelineDetails?.terminal?.avg_total_stopage_time_in_minutes / 60)
            mins = Math.floor((timelineDetails?.terminal?.avg_total_stopage_time_in_minutes - (hrs * 60)))
            secs = Math.floor(((timelineDetails?.terminal?.avg_total_stopage_time_in_minutes * 60) % 60))
            setAvgHrs(hrs)
            setAvgMins(mins)
            setAvgSecs(secs)
        }
        getAvgTime()
        getTime()

    }, [timelineDetails?.routePostMedia])

    // handle close button click of modal 
    const handleClose = () => {
        setShowCarousel(true)
        setOpenAlert(false)
    }

    //to check if the media is protected or not 
    const checkMediaProtected = (media, index, array) => timelineDetails?.receipt_private === true ? media.type === "interchange_file" ? true : false : false;

    // render dialoge box to ask if open in browser or app
    const renderDialog = () => {
        return (
            <Dialog
                open={openAlert}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ textAlign: 'center', padding: '0 24px' }} id="draggable-dialog-title">
                    <img src={AppImages?.logo} className={classes.logo} />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {appConstants.timelineAlert}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button size="small" onClick={handleClose} color="primary">
                        {appConstants.cancel}
                    </Button>
                    <a href={linker} style={{ textDecoration: 'none' }}>
                        <Button size="small" color="primary">
                            {appConstants.openInApp}
                        </Button>
                    </a>
                </DialogActions>
            </Dialog>
        )
    }

    // render dialoge box with error 
    const renderErrorDialogBox = () => {
        return (
            <Grid container justify="center" alignItems="center" >
                <Grid item xs={12} sm={8} md={8} lg={8} xs={6}>
                    <Card className={classes.timlinePostCard} style={{ marginTop: '25vh', marginBottom: '60vh' }}>
                        <CardContent>
                            <Typography variant="subtitle1" color="textSecondary" component="p" style={{ textAlign: 'center' }}>
                                Timeline post not available.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        )
    }

    //render card header with empty data untill data fetched 
    const renderEmptyCardHeader = () => {
        return (
            <CardHeader
                avatar={
                    <Avatar
                        alt="profile img"
                        className={classes.avatar}
                    />
                }
                titleTypographyProps={{
                    variant: 'h6',
                }}
                subheaderTypographyProps={{
                    variant: 'subtitle1',
                }}
            />
        )
    }

    // render card Header with avatar and title
    const renderCardHeader = () => {
        return (
            <CardHeader
                avatar={
                    <Avatar
                        // alt="profile img"
                        src={
                            timelineDetails?.media_type === null ?
                                appConstants.baseURL + timelineDetails?.user_id?.profile :
                                appConstants.baseURL + timelineDetails?.user?.profile
                        }
                        className={classes.avatar}
                    />
                }
                titleTypographyProps={{
                    variant: 'h6',
                }}
                subheaderTypographyProps={{
                    variant: 'body2',
                }}
                title={timelineDetails?.shared_by ?
                    timelineDetails?.shared_by?.name :
                    timelineDetails?.media_type === null ? timelineDetails?.user_id?.name : timelineDetails?.user?.name}
                // subheader={ moment(timelineDetails?.timeLineDetails?.created_at).format("dddd, MMMM Do YYYY")}
                subheader={timelineDetails?.terminal?.terminal_name === undefined ? moment(timelineDetails?.timeLineDetails?.created_at).format("dddd, MMMM Do YYYY") : <p style={{ margin: 0 }}>{timelineDetails?.terminal?.terminal_name}<br />{moment(timelineDetails?.timeLineDetails?.created_at).format("dddd, MMMM Do YYYY")}</p>}
            />
        )
    }

    // render Card Media with empty data containing loader untill data fetched
    const renderEmptyCardMedia = () => {
        return (
            <CardMedia
                style={{
                    height: 400,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                <CircularProgress color={'primary'} />
            </CardMedia>
        )
    }

    let overlayMapData = [
        {
            background: 'red',
            title: 'Mi',
            data: timelineDetails?.distance ? ((timelineDetails?.distance / 1000) * 0.621371).toFixed(2) : "0",
        },
        {
            background: 'green',
            title: 'Time',
            data: `${hrs > 0 ? `${hrs} Hrs` : ""} ${mins > 0 ? `${mins} mins` : "0 min"}`,
        },
        {
            background: Colors.primary,
            title: 'Avg wait',
            data: `${avgHrs > 0 ? `${avgHrs} Hrs` : ""} ${avgMins > 0 ? `${avgMins} mins` : "0 min"}`,
        },
    ]

    // render map overlay with data in map pic 
    const renderDataOverlayOnMap = () => {
        return (
            <Grid spacing={2} className={classes.mapOverlay}>
                {overlayMapData.map((overlayData, index) => {
                    return (
                        <Grid key={index.toString()} item className={classes.mapOverlayItem} spacing={1} >
                            <Grid item>
                                <Box style={{ width: 15, height: 15, backgroundColor: overlayData.background, borderRadius: 4, marginTop: 4 }} />
                            </Grid>
                            <Grid item direnction="column" style={{ marginLeft: 10, textAlign: 'left' }}>
                                <Typography variant="body1" style={{ fontWeight: '700', color: 'white' }}>
                                    {overlayData.title}
                                </Typography>
                                <Typography variant="body1" style={{ fontWeight: '700', color: 'white' }}>
                                    {overlayData.data}
                                </Typography>
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
        )
    }


    // render card media included carousel after data fetched
    const renderCardMediaWithData = () => {
        return (
            <CardMedia
                title={timelineDetails?.media_type === null ?
                    timelineDetails?.timeLineDetails?.description :
                    timelineDetails?.description}
                className={classes.media}
            >
                {timelineDetails?.media_type === null ?
                    // timelineDetails?.timeLineDetails?.media_type
                    <Carousel
                        showThumbs={false}
                        showStatus={false}
                        showArrows
                        autoPlay
                        infiniteLoop
                        useKeyboardArrows>
                        {timelineMediaArray.map((media, index, arr) => {
                            return (<div className={classes.carouselDiv}>
                                {media?.type == "video" ? (
                                    <video className={classes.carouselImage} controls >
                                        {/* poster={appConstants.baseURL+media?.thumbnail} */}
                                        <source src={appConstants.baseURL + media?.media} type="video/mp4" />
                                    </video>
                                ) : (
                                    <img src={appConstants.baseURL + media?.media} className={classes.carouselImage} />
                                )
                                }
                            </div>)
                        })}
                        {
                            <div className={classes.carouselDiv}>
                                {renderDataOverlayOnMap()}
                                <img src={appConstants.baseURL + timelineDetails?.image} className={classes.carouselImage} />
                            </div>
                        }
                    </Carousel> :
                    // poster={appConstants.baseURL+timelineDetails?.thumbnail_image}
                    <div className={classes.carouselDiv}>
                        {timelineDetails?.media_type === "video" ? (
                            <video className={classes.carouselImage} controls >
                                <source src={appConstants.baseURL + timelineDetails?.video} type="video/mp4" />
                            </video>
                        ) : (
                            <img src={appConstants.baseURL + timelineDetails?.video} className={classes.carouselImage} />
                        )
                        }
                    </div>
                }
            </CardMedia>
        )
    }

    return (
        <TimelineLayout>
            {isMobile && linker ? (
                isTimeLineSuccess ?
                    renderDialog()
                    :
                    renderErrorDialogBox()
            ) : true}
            {showCarousel && (
                <Container className={classes.cardGrid} style={{ maxWidth: '100%', marginBottom: 'auto' }}>
                    {/* End hero unit */}
                    {timelineErrorState.length > 0 ?
                        renderErrorDialogBox()
                        :
                        <Grid container justify="center" alignItems="center">
                            <Grid item xs={12} sm={6} md={6}>
                                <Card className={classes.timlinePostCard}>
                                    {isLoading ?
                                        renderEmptyCardHeader() :
                                        renderCardHeader()
                                    }
                                    <CardContent style={{ overflowWrap: 'break-word' }}>
                                        {timelineDetails?.shared_by && <Typography variant="caption" color={'primary'} component="p">
                                            {timelineDetails?.media_type === null ?
                                                timelineDetails?.shared_by?.name :
                                                timelineDetails?.shared_by?.name
                                            } has shared {timelineDetails?.media_type === null ? timelineDetails?.user_id?.name : timelineDetails?.user?.name}'s post.
                                        </Typography>}
                                        <Typography variant="subtitle1" color="textSecondary" component="p" style={{ lineHeight: '25px' }}>
                                            {timelineDetails?.media_type === null ?
                                                timelineDetails?.timeLineDetails?.description === "" ? "N/A" : timelineDetails?.timeLineDetails?.description :
                                                timelineDetails?.description === "" ? "N/A" : timelineDetails?.description
                                            }
                                        </Typography>
                                    </CardContent>
                                    {isLoading ?
                                        renderEmptyCardMedia() :
                                        renderCardMediaWithData()
                                    }
                                    <CardContent>
                                        <Typography variant="subtitle1" color="textSecondary" component="p" />
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>}
                </Container>
            )}
        </TimelineLayout>
    )
}