import React, { useState, useEffect, useRef } from 'react';
// Navigation
import { useHistory, useLocation } from 'react-router-dom'
// Styles
import 'antd/dist/antd.css'
import '../dashboard.scss'
// antd
import { Spin } from 'antd';
import { Card, Table as BSTable, } from 'react-bootstrap';
// Mui Components
import { Grid, useTheme, } from '@material-ui/core';
// Custom components
import DashboardLayout from '../../layouts/dashboardLayout'
import { AppBar, InputField, Navbar, Button } from '../../customComponents'
// Constants
import { appConstants } from '../../themes/constants'
// utils methods
import { useStyles } from '../../styles'
// Redux
import { useSelector, useDispatch } from 'react-redux'
import { getReportedUsersDetailsAction } from '../../store/actions/userManagementActions'
import moment from 'moment'

export const ReportedUserDetails = (props) => {
    // Hooks declarations
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const location = useLocation()
    const dispatch = useDispatch()

    const { reportedUserDetails, isTableDataLoading } = useSelector(state => state.userManagementReducer)

    // Lifecycle Hooks
    useEffect(() => {
        document.title = appConstants.headerTitle.reportedUsersDetails;
        const user_id = props.location.state.timelineID
        if (user_id) {
            dispatch(getReportedUsersDetailsAction(user_id))
        } else {
            history.push('/users')
        }
    }, [])

    return (
        <DashboardLayout>
            <AppBar breadcrumbs={[{ route: '/users', name: appConstants.userManagement },
            {
                route: "/view-user-details",
                name: appConstants.userDetails,
                state: { userId: location?.state?.userId }
            },
            {
                route: "/reported-users",
                name: "Reported By",
                state: { userId: location?.state?.userId },
            },
            {
                route: "/reported-users-details",
                name: "Reported Reason Details",
                state: { timelineID: location?.state?.timelineID },
            },

            ]} />
            <Navbar title="Reported Reason Details" />
            <Card className={classes.card}>
                <Grid container>
                    <Grid className={classes.tableContainerRow}>
                        <BSTable striped bordered className={classes.terminalTable}>
                            {
                                isTableDataLoading ? <thead className={classes.bsHeaderTable}>
                                    <Spin className={classes.tableDataSpinner} />
                                </thead>
                                    :
                                    <tbody>
                                        <tr>
                                            <td className={classes.rowKey}>
                                                User Reported
                                            </td>
                                            <td className={classes.rowValue}>
                                                <div className={classes.inputWrapper}>
                                                    <InputField
                                                        placeholder="User Reported"
                                                        name="Report To"
                                                        // onChange={handleChange}
                                                        type="Report To"
                                                        value={reportedUserDetails?.report_to}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={classes.rowKey}>
                                                Reported By
                                            </td>
                                            <td className={classes.rowValue}>
                                                <div className={classes.inputWrapper}>
                                                    <InputField
                                                        placeholder="Reported By"
                                                        name="reportedby"
                                                        // onChange={handleChange}
                                                        type="reportedby"
                                                        value={reportedUserDetails?.report_by}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={classes.rowKey}>
                                                Reported Date
                                            </td>
                                            <td className={classes.rowValue}>
                                                <div className={classes.inputWrapper}>
                                                    <InputField
                                                        placeholder="Created At"
                                                        name="Created At"
                                                        // onChange={handleChange}
                                                        type="Created At"
                                                        value={moment(reportedUserDetails?.created_at).format("YYYY-MM-DD")}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={classes.rowKey}>
                                                Reason Of Report
                                            </td>
                                            <td className={classes.rowValue}>
                                                <div className={classes.inputWrapper}>
                                                    <textarea
                                                        style={{ height: "14rem", resize: "none" }}
                                                        className="textAreaNew"
                                                        placeholder="Reason Of Report"
                                                        name="Message"
                                                        type="Message"
                                                        value={reportedUserDetails?.message}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                            }
                        </BSTable>
                    </Grid>
                </Grid>
            </Card>
        </DashboardLayout>
    );
}
