import React, { useState, useEffect, useRef } from 'react';
// Navigation
import { useHistory, useLocation } from 'react-router-dom'
// Styles
import 'antd/dist/antd.css'
import '../dashboard.scss'
// antd
import { Spin } from 'antd';
import { Card, Table as BSTable, } from 'react-bootstrap';
// Mui Components
import { Grid, useTheme, } from '@material-ui/core';
import { Modal } from "antd";
// Custom components
import DashboardLayout from '../../layouts/dashboardLayout'
import { AppBar, InputField, Navbar, Button, ConfirmationModal } from '../../customComponents'
// Constants
import { AppImages } from '../../themes/appImages'
import { Colors } from '../../themes/colors'
import { appConstants } from '../../themes/constants'
// utils methods
import cl from '../../utils/cl'
import { useStyles } from '../../styles'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons'
// Redux
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment';
import { getPostDetailsAction, commentOnPostAction, likeCommentAction, deleteCommentAction, editCommentAction } from '../../store/actions/postManagementAction';
import { getReactionType } from '../../utils/helpers';
// import { useLongPress } from 'use-long-press';
import useLongPress from "./useLongPress";
import { toast } from 'react-toastify';

export const CommentScreen = () => {
    // Hooks declarations
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const location = useLocation()
    const dispatch = useDispatch()
    const [commentText, setCommentText] = useState("")
    const [replyText, setReplyText] = useState("")
    const [mainCommentIndex, setMainCommentIndex] = useState(null)
    const [mainCommentIndex1, setMainCommentIndex1] = useState(0)
    const [openModal, setOpenModal] = useState(false);
    const [openSubCommentModal, setOpenSubOpenModal] = useState(false);
    const [replyComment, setReplyComment] = useState(false);
    const [editComment, setEditComment] = useState(false);
    const [editCommentData, setEditCommentData] = useState(null);
    const [editCommentText, setEditCommentText] = useState("");
    const [showComments, setShowComments] = useState([])
    const [indexForImageLike, setIndexForImageLike] = useState(0)
    const [subCommentIndex, setSubCommentIndex] = useState(0)
    const [confirm, setConfirm] = useState(false)
    const [commentToDelete, setCommentToDelete] = useState(null)
    const [confirm1, setConfirm1] = useState(false)
    const [commentToDelete1, setCommentToDelete1] = useState(null)
    const [imageLike, setImageLike] = useState(AppImages.NotLike)
    const postListingData = useSelector(state => state.PostManagementReducer)
    const { postDetails, isLoading, isTableDataLoading } = postListingData
    let subcommentId = null;
    // Lifecycle Hooks
    useEffect(() => {
        document.title = "Comments";
        dispatch(getPostDetailsAction({
            post_id: location?.state?.postId,
            user_id: location?.state?.botUserId
        }))
    }, [])

    useEffect(() => {
        // let index = postDetails?.comments[indexForImageLike]?.reactionType
        setShowComments(postDetails?.comments)
        // setImageLike(getReactionType(index))
    }, [postDetails])

    useEffect(() => {
        setEditCommentText(editCommentData?.description)
    }, [editCommentData])

    const closeModal = () => {
        setOpenModal(false)
    }

    const closeSubCommentModal = () => {
        setOpenSubOpenModal(false)
    }

    const closeReplyModal = () => {
        setReplyComment(false)
    }

    const editReplyModal = () => {
        setEditComment(false)
    }

    const emojis = [
        {
            name: AppImages.thumbs_up,
            type: 'like'
        },
        {
            name: AppImages.thinking_face,
            type: 'thinking'
        },

        {
            name: AppImages.grinning_face,
            type: 'grinningSmile'
        },
        {
            name: AppImages.thumbs_down,
            type: 'dislike'
        },
        {
            name: AppImages.rollingEyes,
            type: 'confuse'
        },
        {
            name: AppImages.angry,
            type: 'angry'
        },
    ]

    const sendCommentOnPost = () => {
        if (commentText.trim().length > 0) {
            dispatch(commentOnPostAction(
                { post_id: postDetails?._id, description: commentText.trim(), user_id: location?.state?.botUserId }))
            setCommentText("")
        }
        else {
            toast.error("Please enter the comment.")
        }

    }

    const onLongPress = () => {
        setOpenModal(true)
    };

    const onClick = () => {
        if (postDetails?.comments[indexForImageLike]?.reactionType !== null) {
            setImageLike(AppImages.NotLike)
            dispatch(likeCommentAction({
                post_id: postDetails?._id, likeType: null, userId: location?.state?.botUserId,
                user_id: location?.state?.botUserId,
                commentId: mainCommentIndex, likeComment: "comment", is_like: 0
            }))
        } else {
            setImageLike(emojis[0].name)
            dispatch(likeCommentAction({
                post_id: postDetails?._id, likeType: emojis[0].type, userId: location?.state?.botUserId,
                user_id: location?.state?.botUserId,
                commentId: mainCommentIndex, likeComment: "comment", is_like: 1
            }))
        }

    }

    const onClickSubComment = () => {
        if (postDetails?.comments[indexForImageLike]?.subComments[subCommentIndex]?.reactionType !== null) {
            setImageLike(AppImages.NotLike)
            dispatch(likeCommentAction({
                post_id: postDetails?._id, likeType: null, userId: location?.state?.botUserId,
                user_id: location?.state?.botUserId,
                commentId: mainCommentIndex, likeComment: "subcomment", indexOfComment: indexForImageLike, is_like: 0
            }))
        } else {
            setImageLike(emojis[0].name)
            dispatch(likeCommentAction({
                post_id: postDetails?._id, likeType: emojis[0].type, userId: location?.state?.botUserId,
                user_id: location?.state?.botUserId,
                commentId: mainCommentIndex, likeComment: "subcomment", is_like: 1, indexOfComment: indexForImageLike,
                is_like: 1
            }))
        }
    }

    const defaultOptions = {
        shouldPreventDefault: true,
        delay: 500,
    };

    const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);
    const longPressEventSubComment = useLongPress(onLongPress, onClickSubComment, defaultOptions);


    return (
        <DashboardLayout>
            <ConfirmationModal title="Alert" text={appConstants.confirmCommentPost} visible={confirm}
                // disable={disableButton}
                onCancel={() => {
                    setConfirm(false)
                }}
                onDeleteConfirmed={() => {
                    // dispatch(deletePostAction(postToDelete))
                    setConfirm(false)
                    dispatch(deleteCommentAction(commentToDelete))
                    // setDisableButton(true)
                }}
            />
            <ConfirmationModal title="Alert" text={appConstants.confirmCommentPost} visible={confirm1}
                // disable={disableButton}
                onCancel={() => {
                    setConfirm1(false)
                }}
                onDeleteConfirmed={() => {
                    // dispatch(deletePostAction(postToDelete))
                    setConfirm1(false)
                    dispatch(deleteCommentAction(commentToDelete1.id, commentToDelete1.index))
                    // setDisableButton(true)
                }}
            />
            {/* <AppBar breadcrumbs={[{ route: '/postListing', name: appConstants.postManagement }, { route: '/post-details', name: appConstants.postDetails, state: location?.state?.userId }]} /> */}
            <AppBar breadcrumbs={[{ route: '/postListing', name: appConstants.postManagement },
            {
                route: "/post-details", name: appConstants.postDetails,
                state: { postId: location?.state?.postId, botUserId: location?.state?.botUserId }
            },
            {
                route: '/comment-screen', name: "Comments",
                state: { botUserId: location?.state?.botUserId, postId: location?.state?.postId, }
            }]} />
            <Button
                title="Refresh"
                width="108px"
                onClick={() =>
                    dispatch(getPostDetailsAction(
                        {
                            post_id: location?.state?.postId,
                            user_id: location?.state?.botUserId
                        }))}
                marginRight="0px"
                mr
                mb
            />
            <Navbar title="Comments" />
            <Modal
                className='post-modal'
                onCancel={() => closeModal()} visible={openModal} footer={null} centered maskClosable={false}>
                <div>
                    {
                        emojis.map((item, index) => {
                            return (
                                <img src={item.name} height={"55px"} width={"55px"} onClick={() => {
                                    setOpenModal(false)
                                    setImageLike(item.name)
                                    // let myCom = [...showComments]
                                    // myCom[mainCommentIndex].like = item.name
                                    // setShowComments(myCom)
                                    dispatch(likeCommentAction({
                                        post_id: postDetails?._id, likeType: item.type, userId: location?.state?.botUserId,
                                        user_id: location?.state?.botUserId,
                                        commentId: mainCommentIndex, likeComment: "comment", is_like: 1
                                    }))
                                }}></img>
                            )
                        })
                    }
                </div>
            </Modal>
            <Modal
                className='post-modal'
                onCancel={() => closeSubCommentModal()} visible={openSubCommentModal} footer={null} centered maskClosable={false}>
                <div>
                    {
                        emojis.map((item, index) => {
                            return (
                                <img src={item.name} height={"55px"} width={"55px"} onClick={() => {
                                    setOpenSubOpenModal(false)
                                    setImageLike(item.name)
                                    dispatch(likeCommentAction({
                                        post_id: postDetails?._id, likeType: item.type, userId: location?.state?.botUserId,
                                        user_id: location?.state?.botUserId,
                                        commentId: mainCommentIndex, likeComment: "subcomment", indexOfComment: indexForImageLike,
                                        is_like: 1
                                    }))
                                    // let myCom = [...showSubComments]
                                    // myCom[mainCommentIndex1].like = item.name
                                    // setShowSubComments(myCom)
                                }}></img>
                            )
                        })
                    }
                </div>
            </Modal>
            <Modal
                className='post-modal'
                onCancel={() => {
                    closeReplyModal()
                    setReplyText("")
                }} visible={replyComment} footer={null} centered maskClosable={false}>
                <div>
                    <div className="reply-modal">
                        <span className='reply_text'>Reply</span>
                    </div>
                    <div className='reply_edit_modal' >
                        <textarea
                            placeholder="Enter your comment"
                            name="comment"
                            onChange={(e) => setReplyText(e.target.value)}
                            value={replyText.trimLeft()}
                            style={{ resize: "none" }}
                            className="textAreaNew1"
                        />
                    </div>

                    <div
                        className='submit_text'
                    >
                        <Button
                            title="Submit"
                            className="mr-3"
                            width="108px"
                            onClick={() => {
                                setReplyText("")
                                if (replyText.trim().length > 0) {
                                    setReplyComment(false)
                                    dispatch(commentOnPostAction(
                                        { post_id: postDetails?._id, description: replyText.trim(), user_id: location?.state?.botUserId, commentId: mainCommentIndex }))
                                }
                                else {
                                    toast.error("Please enter the comment.")
                                }
                            }}
                        />
                    </div>
                </div>
            </Modal>
            <Modal
                className='post-modal'
                onCancel={() => editReplyModal()} visible={editComment} footer={null} centered maskClosable={false}>
                <div>
                    <div className="reply-modal">
                        <span className='reply_text'>Edit Comment</span>
                    </div>

                    <div className='reply_edit_modal'>
                        <textarea
                            placeholder="Enter your comment"
                            name="editCommentText"
                            onChange={(e) => setEditCommentText(e.target.value)}
                            value={editCommentText?.trimLeft()}
                            style={{ resize: "none" }}
                            className="textAreaNew1"
                        />
                    </div>

                    <div
                        className='submit_text'

                    >
                        <Button
                            title="Submit"
                            className="mr-3"
                            width="108px"
                            onClick={() => {
                                setEditComment(false)
                                if (editCommentText.trim().length > 0) {
                                    dispatch(editCommentAction(
                                        { post_id: postDetails?._id, description: editCommentText?.trim(), userId: location?.state?.botUserId, user_id: location?.state?.botUserId, commentId: mainCommentIndex }))
                                }

                            }}
                        />
                    </div>
                </div>
            </Modal>

            <Card className={classes.card}>
                <Grid container>
                    <Grid className={classes.tableContainerRow}>
                        <BSTable striped bordered className={classes.bsTableHeight} style={{ border: '0px' }}>
                            {
                                isTableDataLoading ? <thead style={{ border: '0px' }} className={classes.bsHeaderTable}>
                                    <Spin className={classes.tableDataSpinner} />
                                </thead>
                                    :
                                    <tbody style={{ border: "0px" }}>
                                        <div className='comment-screen' style={{ height: '100px' }}>
                                            <div style={{ paddingBottom: "21%" }}>
                                                <div className='align_comment_screen'>
                                                    <h4>{postDetails?.user?.userName}</h4>
                                                </div>
                                                {showComments?.length > 0 ?
                                                    showComments?.map((item, indexM) => {
                                                        return (
                                                            <>
                                                                <div className='comments-screen1'>
                                                                    <div className='comment-box'>
                                                                        <div className='align_comment_screen'>
                                                                            <div className='user-image'>
                                                                                {
                                                                                    item?.user?.profile ?
                                                                                        <img className='user_image1' src={appConstants.baseURL + item?.user?.profile}
                                                                                            height={"50px"} width={"50px"}
                                                                                        >
                                                                                        </img>
                                                                                        :
                                                                                        <img className='user_image1' src={AppImages.dummyProfilePic}
                                                                                            height={"50px"} width={"50px"}
                                                                                        >
                                                                                        </img>
                                                                                }

                                                                            </div>
                                                                            <div >
                                                                                <div className='reply-button1'>{item?.user?.userName}</div>
                                                                                <div className='reply-button2' style={{ breakAfter: 'auto', whiteSpace: 'break-spaces' }}>{item?.description}</div>
                                                                                <div className='reply-button3'>{moment(item?.created_at).format("hh:mm A L")}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ marginTop: "2px" }}>
                                                                            {item?.comment_creator == location?.state?.botUserId &&
                                                                                <>
                                                                                    <FontAwesomeIcon
                                                                                        icon={faEdit}
                                                                                        style={{ marginRight: "10px", cursor: "pointer" }}
                                                                                        onClick={() => {
                                                                                            setEditComment(true)
                                                                                            setEditCommentData(item)
                                                                                            setMainCommentIndex(item?._id)
                                                                                        }}
                                                                                    />
                                                                                    <FontAwesomeIcon
                                                                                        style={{ cursor: "pointer" }}
                                                                                        icon={faTrash}

                                                                                        onClick={() => {
                                                                                            setConfirm(true)
                                                                                            setCommentToDelete(item?._id)
                                                                                        }
                                                                                        }

                                                                                    />
                                                                                </>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className='like-reply'>
                                                                        <img {...longPressEvent} onMouseEnter={() => {
                                                                            setIndexForImageLike(indexM)
                                                                            setMainCommentIndex(item?._id)
                                                                        }} src={getReactionType(item?.reactionType?.type)} height={"30px"} width={"30px"}
                                                                            className='reactions_class'

                                                                            onClick={() => {
                                                                                // setOpenModal(true)
                                                                                // setMainCommentIndex(item?._id)
                                                                                // setIndexForImageLike(indexM)
                                                                            }}></img>
                                                                        <div className='align_comment_screen'>
                                                                            <img src={AppImages.thumbs_up} className='thumbs_up_image'></img>
                                                                            <div className='no_of_likes'>{item?.likes}</div>
                                                                            <div className='grey_line'></div>
                                                                            <div className='reply-button'
                                                                                onClick={() => {
                                                                                    setReplyComment(true)
                                                                                    setMainCommentIndex(item?._id)
                                                                                }
                                                                                }>Reply</div>
                                                                        </div>

                                                                    </div>

                                                                </div>
                                                                {
                                                                    item?.subComments?.map((item1, index) => {
                                                                        return (
                                                                            <div className='comments-screen1'>
                                                                                <div className='subcomment-box'>
                                                                                    <div className='align_comment_screen'>
                                                                                        <div className='user-image'>
                                                                                            {
                                                                                                item?.user?.profile ?
                                                                                                    <img className='user_image1' src={appConstants.baseURL + item?.user?.profile}
                                                                                                        height={"50px"} width={"50px"}
                                                                                                    >
                                                                                                    </img>
                                                                                                    :
                                                                                                    <img className='user_image1' src={AppImages.dummyProfilePic}
                                                                                                        height={"50px"} width={"50px"}
                                                                                                    >
                                                                                                    </img>

                                                                                            }

                                                                                        </div>
                                                                                        <div >
                                                                                            <div className='reply-button1'>{item1?.user?.userName}</div>
                                                                                            <div className='reply-button4' style={{ breakAfter: 'auto', whiteSpace: 'break-spaces' }}>
                                                                                                {item1?.description}</div>
                                                                                            <div className='reply-button3'>{moment(item1?.created_at).format("hh:mm A L")}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginTop: "2px" }}>
                                                                                        {item1?.user?._id == location?.state?.botUserId &&
                                                                                            <>
                                                                                                <FontAwesomeIcon
                                                                                                    icon={faEdit}
                                                                                                    style={{ marginRight: "10px", cursor: "pointer" }}
                                                                                                    onClick={() => {
                                                                                                        setEditComment(true)
                                                                                                        setEditCommentData(item1)
                                                                                                        setMainCommentIndex(item1?._id)
                                                                                                    }}
                                                                                                />
                                                                                                <FontAwesomeIcon
                                                                                                    icon={faTrash}
                                                                                                    style={{ cursor: "pointer" }}
                                                                                                    onClick={() => {
                                                                                                        setConfirm1(true)
                                                                                                        setCommentToDelete1({ id: item1?._id, index: indexM })
                                                                                                    }
                                                                                                    }

                                                                                                />
                                                                                            </>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className='like-reply-subcomments'>
                                                                                    <img {...longPressEventSubComment}
                                                                                        src={getReactionType(item1?.reactionType?.type)} height={"30px"} width={"30px"}
                                                                                        className='reactions_class'
                                                                                        onMouseEnter={() => {
                                                                                            setIndexForImageLike(indexM)
                                                                                            setMainCommentIndex(item1?._id)
                                                                                            subcommentId = item1._id
                                                                                            setSubCommentIndex(index)
                                                                                        }}

                                                                                        onClick={() => {
                                                                                            // setIndexForImageLike(indexM)

                                                                                            // // setOpenSubOpenModal(true)
                                                                                            // setMainCommentIndex(item1?._id)
                                                                                            // subcommentId = item1._id
                                                                                            // setMainCommentIndex1(index)
                                                                                        }}
                                                                                    ></img>
                                                                                    <div className='align_comment_screen'>
                                                                                        <img src={AppImages.thumbs_up} className='thumbs_up_image'></img>
                                                                                        <div className='no_of_likes'>{item1?.likes}</div>
                                                                                        <div className='grey_line'></div>
                                                                                        <div className='reply-button'
                                                                                            onClick={() => {
                                                                                                setReplyComment(true)
                                                                                                setMainCommentIndex(item?._id)
                                                                                            }}
                                                                                        > Reply</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </>
                                                        )
                                                    })
                                                    :
                                                    <div className='no-comments'>
                                                        <p>No comments yet.</p>
                                                        <p>Be the first to comment.</p>
                                                    </div>
                                                }
                                            </div>

                                        </div>
                                        <div
                                            className='commenting_area'
                                        >
                                            <div style={{ width: "100%", }}>
                                                <textarea
                                                    value={commentText?.trimLeft()}
                                                    onChange={(e) => setCommentText(e.target.value)}
                                                    placeholder='Enter your comment'
                                                    style={{ resize: "none" }}
                                                    className="textAreaNew1"

                                                />
                                            </div>
                                            <Button title='Send'
                                                marginRight="0px"
                                                // onClick={() => addComment()}
                                                onClick={() => sendCommentOnPost()}
                                            ></Button>
                                        </div>
                                    </tbody>
                            }
                        </BSTable>
                    </Grid>
                </Grid>
            </Card>
        </DashboardLayout >
    );
}
