import { takeLatest, call, put } from 'redux-saga/effects'
import { message } from 'antd'
import { apiConstants as types, appMessages } from '../../themes/constants'
import axios from '../axios'
import cl from '../../utils/cl'
import history from '../../utils/history'
import { toast } from 'react-toastify';

function* stateFailedSaga(result) {

    yield put({
        type: types.API_STATE_LIST_MANAGEMENT_FAILED,
        errorState: result?.error,
    })
    toast.error(result?.error)
}

function* stateErrorSaga(result) {
    yield put({
        type: types.API_STATE_LIST_MANAGEMENT_ERROR,
        errorState: result?.error,
    })
    if (result.status === 2) {
        toast.error(appMessages.messageStatus500)
    } else {
        toast.error(result?.error)
    }
}


function* getStateListSaga(action) {

    const { search, offset, limit, sortBy, order } = action;
    try {
        const result = yield call(axios.getStateList, search, offset, limit, sortBy, order)
        console.log(action, "action", result)
        if (result.status === 1) {
            cl('result inside get terminal area list saga', result)
            yield put({
                type: types.API_GET_STATE_LIST_SUCCESS,
                result: result.result.data.data,
            })
        }
        else {
            yield call(stateFailedSaga, result)
        }
    }
    catch (error) {
        yield call(stateErrorSaga, error)
    }
}

function* addStateSaga(action) {
    const { stateName, shortName, timezone } = action;
    console.log(stateName, shortName, timezone)
    try {
        const result = yield call(axios.addList, stateName, shortName, timezone)
        if (result.status === 1) {
            cl('result inside add terminal area saga', result)
            yield put({
                type: types.API_ADD_STATE_LIST_SUCCESS,
                result: result.result.data.data,
            })
            toast.success(result?.result?.data?.message)
            history.push('/state-listing')
        }
        else {
            yield call(stateFailedSaga, result)
        }
    }
    catch (error) {
        yield call(stateErrorSaga, error)
    }
}

function* getStateDetailsSaga(action) {
    const { stateId } = action;
    try {
        const result = yield call(axios.getStateDetails, stateId)
        if (result.status === 1) {
            cl('result inside get terminal Details saga', result)
            yield put({
                type: types.API_STATE_LIST_DETAILS_SUCCESS,
                result: result.result.data.data,
            })
        }
        else {
            yield call(stateFailedSaga, result)
        }
    }
    catch (error) {
        yield call(stateErrorSaga, error)
    }
}

function* editStateSaga(action) {
    const { stateId, stateName, shortName, timezone } = action;

    try {
        const result = yield call(axios.editStateListing, stateId, stateName, shortName, timezone)
        if (result.status === 1) {
            cl('result inside edit terminal area saga', result)
            yield put({
                type: types.API_EDIT_STATE_LIST_SUCCESS,
                result: result.result.data.data,
            })
            toast.success(result?.result?.data?.message)
            history.push('/state-listing')
        }
        else {
            yield call(stateFailedSaga, result)
        }
    }
    catch (error) {
        yield call(stateErrorSaga, error)
    }
}

function* deleteStateSaga(action) {
    const { stateId } = action;
    try {
        const result = yield call(axios.deleteStateList, stateId)
        if (result.status === 1) {
            cl('result inside delete terminal area saga', result)
            yield put({
                type: types.API_DELETE_STATE_LIST_SUCCESS,
                stateId: stateId,
                result: result.result.data.data,
            })

            toast.success(result?.result?.data?.message)
        }
        else {
            yield call(stateFailedSaga, result)
        }
    }
    catch (error) {
        yield call(stateErrorSaga, error)
    }
}
function* exportListingSaga(action) {
    const { fileType } = action;
    try {
        const result = yield call(axios.exportListFile,);
        if (result.status === 1) {
            cl("result inside export Terminal saga", result);
            yield put({
                type: types.API_EXPORT_LISTING_FILE_SUCCESS,
                result: result.result.data.data,
            });

            toast.success("State list exported successfully.");
        } else {
            yield call(stateFailedSaga, result);
        }
    } catch (error) {
        yield call(stateErrorSaga, error);
    }
}

function* importListingSaga(action) {
    const { file, fileType } = action;
    try {
        const result = yield call(axios.importListFile, file, fileType);
        if (result.status === 1) {
            cl("result inside Import Terminal saga", result);
            yield put({
                type: types.API_IMPORT_LIST_FILE_SUCCESS,
                result: result.result.data.data,
            });
            toast.success("State list imported successfully.");
            history.push("/state-listing");
        } else {
            yield call(stateFailedSaga, result);
        }
    } catch (error) {
        yield call(stateErrorSaga, error);
    }
}

function* getTimezoneListSaga(action) {
    console.log(action)
    try {
        const result = yield call(axios.getTimezoneList,);
        if (result.status === 1) {
            cl("result inside export Terminal saga", result);
            yield put({
                type: types.API_TIMEZONE_LIST_SUCCESS,
                result: result.result.data.data,
            });

        } else {
            yield call(stateFailedSaga, result);
        }
    } catch (error) {
        yield call(stateErrorSaga, error);
    }
}

export default function* rootStateListingSaga() {
    yield takeLatest(types.API_GET_STATE_LIST_LOAD, getStateListSaga)
    yield takeLatest(types.API_GET_STATE_LIST_DETAILS_LOAD, getStateDetailsSaga)
    yield takeLatest(types.API_ADD_STATE_LIST_LOAD, addStateSaga)
    yield takeLatest(types.API_EDIT_STATE_LIST_LOAD, editStateSaga)
    yield takeLatest(types.API_DELETE_STATE_LIST_LOAD, deleteStateSaga)
    yield takeLatest(types.API_EXPORT_LISTING_FILE_LOAD, exportListingSaga)
    yield takeLatest(types.API_IMPORT_LIST_FILE_LOAD, importListingSaga)
    yield takeLatest(types.API_TIMEZONE_LIST_LOAD, getTimezoneListSaga)
}