import React, { useState, useEffect, useRef } from "react";
// Navigation
import { useHistory, useLocation } from "react-router-dom";
// Styles
import "antd/dist/antd.css";
import "../dashboard.scss";
// antd
import { Spin, Tooltip } from "antd";
import { Card, Table as BSTable } from "react-bootstrap";
// Mui Components
import { Grid, useTheme } from "@material-ui/core";
// Custom components
import DashboardLayout from "../../layouts/dashboardLayout";
import { AppBar, InputField, Navbar, Button } from "../../customComponents";
// Constants
import { AppImages } from "../../themes/appImages";
import { appConstants, ValidationConstants } from "../../themes/constants";
// utils methods
import cl from "../../utils/cl";
import { useStyles } from "../../styles";
// Redux
import { useSelector, useDispatch } from "react-redux";
import Slider from "react-slick";
import TimelineMap from "../../customComponents/timelineMap";
import { getTimelineDetailsAction } from "../../store/actions/userManagementActions";
import moment from "moment";

export const ViewTimelineDetails = () => {
  var settings = {
    //   dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    easing: "bounce",
    responsive: [
      {
        breakpoint: 1420,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: true,
          speed: 1500,
          easing: "bounce",
          autoplay: true,
          autoplaySpeed: 3000,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: true,
          speed: 1500,
          easing: "bounce",
          autoplay: true,
          autoplaySpeed: 3000,
        },
      },
    ],
  };
  var settings1 = {
    //   dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    easing: "bounce",
    responsive: [
      {
        breakpoint: 1420,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: true,
          speed: 1500,
          easing: "bounce",
          autoplay: true,
          autoplaySpeed: 3000,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: true,
          speed: 1500,
          easing: "bounce",
          autoplay: true,
          autoplaySpeed: 3000,
        },
      },
    ],
  };

  // Hooks declarations
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [showPolygonData, setShowPolygonData] = useState([]);
  const [coordinateData, setCoordinateData] = useState([]);
  const { isTableDataLoading } = useSelector(
    (state) => state.userManagementReducer
  );
  const userDetails = useSelector(
    (state) => state.userManagementReducer.timelineDetails
  );

  // Lifecycle Hooks
  useEffect(() => {
    document.title = appConstants.headerTitle.viewTimelineDetails;
    if (location?.state?.timelineID) {
      dispatch(getTimelineDetailsAction(location?.state?.timelineID));
    } else {
      history.push("/users");
    }
  }, []);

  let initialState = {
    points: [],
    coords: [],
    showPoints: [],
    passPoints: [],
  };

  const [polygonState, pointsDispatch] = React.useReducer((state, action) => {
    switch (action.type) {
      case "newPoint":
        return {
          ...state,
          points: [...state.points, action.newPoint],
          coords: [...state.coords, action.newCoords],
          showPoints: [
            ...state.showPoints,
            `(${action.lat.toFixed(8) + "," + action.long.toFixed(8)})`,
          ],
          passPoints: [...state.passPoints, action.addNewCoords],
        };
      case "undoPoint":
        return {
          ...state,
          points: action.newPoint,
          coords: action.newCoords,
          showPoints: action.showPoints,
          passPoints: action.passPoints,
        };
      case "clearPoint":
        return {
          ...state,
          points: [],
          coords: [],
          showPoints: [],
          passPoints: [],
        };
      default:
        return { ...state };
    }
  }, initialState);

  const onGeofenceClicked = async (mapProps, map, e, radius5) => {
    let lat = e.latLng.lat();
    let long = e.latLng.lng();

    if (showPolygonData.length === 0) {
      pointsDispatch({
        type: "newPoint",
        newPoint: { lat: e.latLng.lat(), lng: e.latLng.lng() },
        newCoords: [e.latLng.lat(), e.latLng.lng()],
        addNewCoords: { latitude: e.latLng.lat(), longitude: e.latLng.lng() },
        lat: lat,
        long: long,
      });
    }
  };

  useEffect(() => {
    if (userDetails?.coordinates) {
      let coordinateArray =
        userDetails?.coordinates.length > 0
          ? JSON.parse(userDetails?.coordinates)
          : [];
      setCoordinateData(coordinateArray);
    }
  }, [userDetails]);

  return (
    <DashboardLayout>
      <AppBar
        breadcrumbs={[
          // { route: '/users', name: appConstants.userManagement },
          {
            route: "/view-user-details",
            name: appConstants.userDetails,
            state: { userId: location?.state?.userId },
          },
          ,
          {
            route: "/view-timeline",
            name: "View Timeline",
            state: { userId: location?.state?.userId },
          },
          {
            route: "/view-timeline-details",
            name: "View Timeline Details",
            state: { timelineID: location?.state?.timelineID },
          },
        ]}
      />
      <Navbar title="View Timeline Details" />
      <Card className={classes.card}>
        <Grid container>
          <Grid className={classes.tableContainerRow}>
            <BSTable striped bordered className={classes.bsTableHeight}>
              {isTableDataLoading ? (
                <thead className={classes.bsHeaderTable}>
                  <Spin className={classes.tableDataSpinner} />
                </thead>
              ) : (
                <tbody>
                  <tr>
                    <td className={classes.rowKey}>{"Terminal Name"}</td>
                    <td className={classes.rowValue}>
                      <div className={classes.inputWrapper}>
                        <InputField
                          placeholder="Terminal Name"
                          name="terminalName"
                          value={
                            userDetails?.type == "manual"
                              ? userDetails?.name
                              : userDetails?.terminal_id?.terminal_name
                          }
                          disabled={true}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className={classes.rowKey}>
                      {appConstants.terminalType}
                    </td>
                    <td className={classes.rowValue}>
                      <div className={classes.inputWrapper}>
                        <InputField
                          placeholder="Type"
                          name="Type"
                          value={
                            userDetails?.type == "manual"
                              ? "Manual"
                              : "Automatic"
                          }
                          disabled={true}
                        />
                      </div>
                    </td>
                  </tr>
                  {userDetails?.type == "manual" && (
                    <tr>
                      <td className={classes.rowKey}>
                        {appConstants.category}
                      </td>
                      <td className={classes.rowValue}>
                        <div className={classes.inputWrapper}>
                          <InputField
                            placeholder="category"
                            name="category"
                            value={
                              userDetails?.category
                                ? userDetails?.category
                                : "N/A"
                            }
                            disabled={true}
                          />
                        </div>
                      </td>
                    </tr>
                  )}
                  {userDetails?.type == "manual" && (
                    <tr>
                      <td className={classes.rowKey}>{appConstants.address}</td>
                      <td className={classes.rowValue}>
                        <div className={classes.inputWrapper}>
                          <InputField
                            placeholder="address"
                            name="address"
                            value={
                              userDetails?.address
                                ? userDetails?.address
                                : "N/A"
                            }
                            disabled={true}
                          />
                        </div>
                      </td>
                    </tr>
                  )}
                  {userDetails?.type == "manual" && (
                    <tr>
                      <td className={classes.rowKey}>
                        {appConstants.phoneNumber}
                      </td>
                      <td className={classes.rowValue}>
                        <div className={classes.inputWrapper}>
                          <InputField
                            placeholder="Type"
                            name="Type"
                            value={
                              userDetails?.phone_number
                                ? userDetails?.phone_number
                                : "N/A"
                            }
                            disabled={true}
                          />
                        </div>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td className={classes.rowKey}>
                      {appConstants.distanceMi}
                    </td>
                    <td className={classes.rowValue}>
                      <div className={classes.inputWrapper}>
                        <InputField
                          placeholder="Distance"
                          name="Distance"
                          value={(
                            (userDetails?.distance / 1000) *
                            0.621371
                          ).toFixed(2)}
                          disabled={true}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className={classes.rowKey}>Date and Time</td>
                    <td className={classes.rowValue}>
                      <div className={classes.inputWrapper}>
                        <InputField
                          placeholder="Date and Time"
                          name="dateAndTime"
                          // onChange={handleChange}
                          // moment().utcOffset(appconstants.appConstants.timeZoneUsa).format("hh:mm A")
                          value={moment(userDetails?.created_at).format(
                            "YYYY-MM-DD HH:mm"
                          )}
                          disabled={true}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className={classes.rowKey}>In Time</td>
                    <td className={classes.rowValue}>
                      <div className={classes.inputWrapper}>
                        <InputField
                          placeholder="In Time"
                          name="inTime"
                          // onChange={handleChange}
                          // type="email"
                          value={moment(userDetails?.start_time).format(
                            "HH:mm"
                          )}
                          disabled={true}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className={classes.rowKey}>Out Time</td>
                    <td className={classes.rowValue}>
                      <div className={classes.inputWrapper}>
                        <InputField
                          placeholder="Out Time"
                          name="outTime"
                          // onChange={handleChange}
                          // type="number"
                          value={moment(userDetails?.end_time).format("HH:mm")}
                          disabled={true}
                        />
                      </div>
                    </td>
                  </tr>
                  {userDetails?.type == "manual" &&
                    userDetails?.medias?.length > 0 && (
                      <tr>
                        <td className={classes.rowKey}>Manual Timeline Media</td>
                        <td
                          className={classes.rowValue}
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <div
                            className={classes.inputWrapper}
                            style={{ width: "60vw", height: 400 }}
                          >
                            <Slider {...settings1}>
                              {userDetails?.medias?.map((data, index) => {
                                if (data.is_video) {
                                  return (
                                    <div key={index.toString()}>
                                      <div class="item">
                                        <video
                                          className={classes.carouselImage}
                                          controls
                                          style={{
                                            width: "100%",
                                            objectFit: "contain",
                                          }}
                                        >
                                          <source
                                            src={
                                              data?.url
                                            }
                                            type="video/mp4"
                                          />
                                        </video>
                                      </div>
                                    </div>
                                  )
                                } else {
                                  return (
                                    <div key={index.toString()}>
                                      <div class="item">
                                        <img
                                          id="myImg"
                                          src={appConstants.baseURL +
                                            data?.url
                                          }
                                          className={classes.carouselImage}
                                          alt="carosal"
                                        />
                                      </div>
                                    </div>
                                  );
                                }
                              })}
                            </Slider>
                          </div>
                        </td>
                      </tr>
                    )}
                  {userDetails?.route_post_media?.length > 0 && (
                    <tr>
                      <td className={classes.rowKey}>Timeline Images</td>
                      <td
                        className={classes.rowValue}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div
                          className={classes.inputWrapper}
                          style={{ width: "60vw", height: 400 }}
                        >
                          <Slider {...settings}>
                            {userDetails?.route_post_media?.map(
                              (data, index) => {
                                return (
                                  <div key={index.toString()}>
                                    <div class="item">
                                      <img
                                        id="myImg"
                                        src={appConstants.baseURL +
                                          data?.media
                                        }
                                        className={classes.carouselImage}
                                        alt="carosal"
                                      />
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </Slider>
                        </div>
                      </td>
                    </tr>
                  )}

                  <tr>
                    <td className={classes.rowKey}>Timeline Map</td>
                    <td className={classes.rowValue}>
                      <div
                        className={classes.inputWrapper}
                        style={{ width: "100%", height: 500 }}
                      >
                        <div
                          className="timelineMap"
                          style={{
                            width: "100%",
                            background: "orange",
                            marginTop: 28,
                            marginBottom: 10,
                          }}
                        >
                          <TimelineMap
                            // setMapSettings={null}
                            polygonState={polygonState}
                            onMapClicked={onGeofenceClicked}
                            zoom={16}
                            center={{
                              lat: coordinateData.length > 0 ? coordinateData[0].latitude : [],
                              lng: coordinateData.length > 0 ? coordinateData[0].longitude : [],
                            }}
                            marker={{
                              lat: coordinateData.length > 0 ? coordinateData[0].latitude : [],
                              lng: coordinateData.length > 0 ? coordinateData[0].longitude : [],
                            }}
                            // terminalDetails={null}
                            setMapSettings={null}
                            value={null}
                            isEditing={false}
                            mapSettings={true}
                            terminalDetails={userDetails?.coordinates}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
            </BSTable>
          </Grid>
        </Grid>
      </Card>
    </DashboardLayout>
  );
};
