import { apiConstants as types } from '../../themes/constants'

const initialState = {
    isLoading: false,
    isTableDataLoading: false,
    result: {},
    errorState: null,
    dashboardnewdata:
    {
        noOfActiveUsers: 0, 
        noOfComments: 0,
        noOfLikes: 0,
        noOfPosts: 0,
        noOfRegisterUsers: 0,
        noOfTimelines: 0,
    },
    months:
        [
            {
                id: 1,
                name: "January"
            },
            {
                id: 2,
                name: "February"
            },
            {
                id: 3,
                name: "March"
            },
            {
                id: 4,
                name: "April"
            },
            {
                id: 5,
                name: "May"
            },
            {
                id: 6,
                name: "June"
            },
            {
                id: 7,
                name: "July"
            },
            {
                id: 8,
                name: "August"
            },
            {
                id: 9,
                name: "September"
            },
            {
                id: 10,
                name: "October"
            },
            {
                id: 11,
                name: "November"
            },
            {
                id: 12,
                name: "December"
            },
        ],
    regiondata: null,
    terminaldata: {},
    regionListing: [],
    updateData: {},
    terminalData: {},
    updateTerminalData: {},
    regionterminalList: [],
    idTerminal: null,
    data: {},
    newdata1: {},
    terminalWeeks: {},
    region_terminal: null,
    regionterminallistingData: []
}

export const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {

        // Clear dashboard Case 
        case types.CLEAR_STATE:
            if (action.key === "clearDashboardState") {
                return {
                    ...state,
                }
            } else {
                return { ...state }
            }

        // dashboard list
        case types.API_DASHBOARD_LOAD:
            return { ...state, isLoading: true, errorState: null, }
        case types.API_DASHBOARD_SUCCESS:
            return { ...state, isLoading: false, dashboardnewdata: action.result }

        //analytics general list
        case types.API_ANALYTICS_LOAD:
            return { ...state, isLoading: true, errorState: null }
        case types.API_ANALYTICS_SUCCESS:
            return { ...state, isLoading: false, data: action.result }

        //analytics region list
        case types.API_ANALYTICS_REGION_LOAD:
            return { ...state, isLoading: true, errorState: null }
        case types.API_ANALYTICS_REGION_SUCCESS:
            return { ...state, isLoading: false, newdata1: action.result, updateData: action.result.enterExitCount, regionListing: action.regionresult.list_terminals, regiondata: action.regionresult.list_terminals[0]._id }

        //update analytics region list
        case types.API_UPDATE_ANALYTICS_REGION_LOAD:
            return { ...state, isLoading: true, errorState: null }
        case types.API_UPDATE_ANALYTICS_REGION_SUCCESS:
            return { ...state, isLoading: false, newdata1: action.result, updateData: action.result.enterExitCount }

        case types.API_ANALYTICS_TERMINAL_SUCCESSChange:
            return {
                ...state,
                isLoading: false,
                updateTerminalData: action.result,
                idTerminal: action?.regionterminalresult[0]._id,
                regionterminalList: action?.regionterminalresult
            }

        //analytics terminal list
        case types.API_ANALYTICS_TERMINAL_LOAD:
            return { ...state, isLoading: true, errorState: null, }
        case types.API_ANALYTICS_TERMINAL_SUCCESS:
            console.log('action.regionresult',action.regionresult);
            return {
                ...state, isLoading: false, updateTerminalData: action.result,
                regionterminalList: action.regionterminalresult,
                idTerminal: action.regionterminalresult[0]._id,
                terminalWeeks: action.result.weeks,
                region_terminal: action.regionresult.list_terminals[0]._id,
                regionterminallistingData: action.regionresult.list_terminals
            }

        //update analytics terminal list
        case types.API_UPDATE_ANALYTICS_TERMINAL_LOAD:
            return { ...state, isLoading: true, errorState: null }
        case types.API_UPDATE_ANALYTICS_TERMINAL_SUCCESS:
            return { ...state, isLoading: false, updateTerminalData: action.result, terminalWeeks: action.result.weeks }

        //region terminal list
        case types.API_REGION_TERMINAL_LOAD:
            return { ...state, isLoading: true, errorState: null }
        case types.API_REGION_TERMINAL_SUCCESS:
            return {

                ...state, isLoading: false, regionterminalList: action.result,
                idTerminal: action.id,
                regionterminallistingData: action?.regionresult?.list_terminals?action?.regionresult?.list_terminals:state.regionterminallistingData

            }

        //empty region terminal list
        case types.EMPTY_REGION_TERMINAL_DATA_LOAD:
            return { ...state, regionterminalList: [], updateTerminalData: {}, isLoading: true, errorState: null }

        //dashboard failed cases
        case types.API_DASHBOARD_FAILED:
            return {
                ...state, isLoading: false, isTableDataLoading: false, result: {},
                errorState: action.errorState,
            }

        case types.API_DASHBOARD_ERROR:
            return {
                ...state, isLoading: false, isTableDataLoading: false, result: {},
                errorState: action.errorState,
            }

        //Default case
        default:
            return { ...state }
    }
}