import "antd/dist/antd.css";
import "../dashboard.scss";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";
import clsx from "clsx";
import { Spin, Tooltip } from "antd";
import { Card, Table as BSTable } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { Grid, Select, FormHelperText, Collapse, } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { toast } from "react-toastify";
import { useStyles } from '../../styles'
import DashboardLayout from "../../layouts/dashboardLayout";
import { InputField, Navbar, AppBar, Button, ConfirmationModal } from "../../customComponents";
import { AppImages } from "../../themes/appImages";
import { appConstants, ValidationConstants } from "../../themes/constants";
import { validateImageRatio, getCenterAndZoomForBackend } from "../../utils/helpers";
import { addTerminalAction, getTerminalAreaListAction, } from "../../store/actions";
import GoogleMap from "../../customComponents/googleMap";
import GeofenceGoogleMap from "../../customComponents/geofenceGoogleMap";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Modal } from "antd";
import { makeStyles } from '@material-ui/core/styles'

const AddTerminal = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const geolib = require('geolib');
  const { confirm } = Modal;
  const { isLoading } = useSelector((state) => state.terminalManagementReducer);
  const [mapDetails, setMapDetails] = useState(null)
  const [isError, setIsError] = useState(false)
  const [imageUrl, setImageUrl] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [mapLogoUrl, setMapLogoUrl] = useState(null);
  const [mapLogoFile, setMapLogoFile] = useState(null);
  const [openTimePicker, setOpenTimePicker] = useState(false);
  const [closeTimePicker, setCloseTimePicker] = useState(false);
  const [selectedOpenTime, setSelectedOpenTime] = React.useState(null);
  const [selectedCloseTime, setSelectedCloseTime] = React.useState(null);
  const [openTimeError, setOpenTimeError] = useState(null);
  const [closeTimeError, setCloseTimeError] = useState(null);
  const [fullTime, setFullTime] = useState(false);
  const [discription, setDiscription] = useState("")
  const [polygonData, setpolygonData] = useState([])
  const [showPolygonData, setShowPolygonData] = useState([])
  const [terminalBoardLogoUrl, setTerminalBoardLogoUrl] = useState(null);
  const [terminalBoardLogoFile, setTerminalBoardLogoFile] = useState(null);
  const [circleZoom, setCircleZoom] = useState(18);
  const [visibleMarker, setVisibleMarker] = useState(false)
  const [enabled, setEnabled] = useState(false)
  const [allMarkers, setAllMarkers] = useState([])
  const [closeMap, setCloseMap] = useState(false)
  const [geofenceName, setGeofenceName] = useState("")
  const [geofenceErrors, setGeofenceErrors] = useState(null);
  const [radiusErrors, setRadiusErrors] = useState(null)
  const [radius, setRadius] = useState("50")
  const [confirm1, setConfirm1] = useState(false)
  const [disableButton, setDisableButton] = useState()
  const [deletefence, setDeletefence] = useState(0)
  const [templates, setTemplates] = useState("file")
  const [openExtraTimePicker, setOpenExtraTimePicker] = useState(false);
  const [closeExtraTimePicker, setCloseExtraTimePicker] = useState(false);
  const [showTerminal, setShowTerminal] = useState(true);
  const [phnNumberValue, setPhnNumberValue] = useState("")
  const [terminalIndex, setTerminalIndex] = useState(0)
  const [aboutUs, setAboutUs] = useState("")
  const [terminalUrlArray, setTerminalUrlArray] = useState([{
    value: "",
    isError: null
  }])
  const [phnNumberArray, setPhoneNumberArray] = useState([{
    value: "",
    errorValue: null,
    name: "",
    nameError: null
  }])
  const [extraTime, setExtraTime] = useState(true)
  const [extraTimeArray, setExtraTimeArray] = useState([
    {
      reason: "",
      openTime: null,
      closeTime: null,
      reasonError: null,
      openTimeerror: null,
      closeTimeError: null
    }
  ])
  const [waitingType, setWaitingType] = useState('automatic')

  const { terminalAreaListing } = useSelector(
    (state) => state.terminalAreaManagementReducer
  );

  let initialState = {
    points: [],
    coords: [],
    showPoints: [],
    passPoints: []
  }

  const [polygonState, pointsDispatch] = React.useReducer((state, action) => {
    switch (action.type) {
      case 'newPoint':
        return {
          ...state,
          points: [...state.points, action.newPoint],
          coords: [...state.coords, action.newCoords],
          showPoints: [...state.showPoints, `(${action.lat.toFixed(8) + "," + action.long.toFixed(8)})`],
          passPoints: [...state.passPoints, action.addNewCoords]
        }
      case 'undoPoint':
        return {
          ...state,
          points: action.newPoint,
          coords: action.newCoords,
          showPoints: action.showPoints,
          passPoints: action.passPoints
        }
      case 'clearPoint':
        return {
          ...state,
          points: [],
          coords: [],
          showPoints: [],
          passPoints: []
        }
      default:
        return { ...state }
    }
  }, initialState)

  const [firstPoint, firstPointDispatch] = React.useReducer((state, action) => {
    switch (action.type) {
      case 'firstPoint':
        return true
      case 'clearPointAll':
        return false
      default:
        return state
    }
  }, false)

  const onMapClicked = async (mapProps, map, e, radius5) => {
    latitude = e.latLng.lat()
    longitude = e.latLng.lng()
    // radius = radius5
    let lat = e.latLng.lat()
    let long = e.latLng.lng()

    if (showPolygonData.length === 0) {
      pointsDispatch({
        type: 'newPoint',
        newPoint: { lat: e.latLng.lat(), lng: e.latLng.lng() },
        newCoords: [e.latLng.lat(), e.latLng.lng()],
        addNewCoords: { latitude: e.latLng.lat(), longitude: e.latLng.lng() },
        lat: lat,
        long: long,
      })
    }
    setLatitude(latitude)
    setLongitude(longitude)
    setRadius(radius)
  };

  const onGeofenceClicked = async (mapProps, map, e, radius5,) => {
    latitude = e.latLng.lat()
    longitude = e.latLng.lng()
    // radius = radius5
    let lat = e.latLng.lat()
    let long = e.latLng.lng()
    let mapDetails1 = {
      lat: latitude,
      lng: longitude,
    }
    if (showPolygonData.length === 0) {
      pointsDispatch({
        type: 'newPoint',
        newPoint: { lat: e.latLng.lat(), lng: e.latLng.lng() },
        newCoords: [e.latLng.lat(), e.latLng.lng()],
        addNewCoords: { latitude: e.latLng.lat(), longitude: e.latLng.lng() },
        lat: lat,
        long: long,
      })
    }
    setMapDetails(mapDetails1);
    setLatitude(latitude)
    setLongitude(longitude)
    setVisibleMarker(true)
    setRadius(radius)
  };

  const onUndoClick = () => {
    pointsDispatch({
      type: 'undoPoint',
      newPoint: polygonState.points.splice(0, polygonState.points.length - 1),
      newCoords: polygonState.coords.splice(0, polygonState.coords.length - 1),
      showPoints: polygonState.showPoints.splice(0, polygonState.showPoints.length - 1),
      passPoints: polygonState.passPoints.splice(0, polygonState.passPoints.length - 1),
    })
    firstPointDispatch({ type: 'clearPointAll' })
    setShowPolygonData([])
    setpolygonData([])
    setAllMarkers([])
    setEnabled(false)
  };

  const onClearClicked = async () => {
    pointsDispatch({ type: 'clearPoint' })
    firstPointDispatch({ type: 'clearPointAll' })
    setShowPolygonData([])
    setpolygonData([])
    setAllMarkers([])
    setEnabled(false)
  };

  const getSelectedTime = (openTime, closeTime, key) => {
    let newCloseTime = null
    let diff = moment(closeTime).diff(openTime, "minutes")
    if (diff > 0 && diff < 1440) {
      return moment(closeTime).format("YYYY-MM-DDTHH:mm:ss+00:00")

    }
    else if (diff < 0) {
      newCloseTime = moment(closeTime).add(1, "day")
      return moment(newCloseTime).format("YYYY-MM-DDTHH:mm:ss+00:00")
    }
    else if (diff >= 1440) {
      newCloseTime = moment(closeTime).subtract(1, "day")
      return moment(newCloseTime).format("YYYY-MM-DDTHH:mm:ss+00:00")

    }
    // return closeTime

  }

  const onCircleClicked = (props) => {
    if (props.center === polygonState.points[0]) {
      const lat = polygonState.passPoints[0].latitude
      const lng = polygonState.passPoints[0].longitude
      pointsDispatch({
        type: 'newPoint',
        newPoint: { lat: lat, lng: lng },
        newCoords: [lat, lng],
        addNewCoords: { latitude: lat, longitude: lng },
        lat: lat,
        long: lng,
      })
      firstPointDispatch({
        type: 'firstPoint',
      })
      setpolygonData([...polygonState.passPoints, polygonState.passPoints[0]])
      setShowPolygonData([...polygonState.showPoints, polygonState.showPoints[0]])
    }
  }

  const onSaveClicked = () => {
    var isPolygon = geolib?.isPointInPolygon({ lat: Number(mapDetails?.lat)?.toFixed(8), lng: Number(mapDetails?.lng)?.toFixed(8) }, polygonState?.passPoints)
    const regexp = /^\S*$/;
    if ((geofenceName == undefined || geofenceName == null || geofenceName?.length == 0)) {
      setGeofenceErrors("Please enter name.")
      return false;
    } else if (geofenceName?.length < 3) {
      setGeofenceErrors("Name should be minimum 3 characters long.")
      return false;
    }
    if ((radius == undefined || radius == null || radius?.length == 0)) {
      setRadiusErrors("Please enter radius.")

    } else if (radius == 0) {
      setRadiusErrors("Radius should be greater than 0.")
    }

    else {
      if (isPolygon) {
        let obj = {
          lat: mapDetails?.lat ? mapDetails?.lat : latitude,
          lng: mapDetails?.lng ? mapDetails?.lng : longitude,
          name: geofenceName.trim(),
          radius: parseFloat(radius)
        }
        let arr = [...allMarkers];
        setEnabled(false)
        arr.push(obj);
        setAllMarkers(arr);
        setCloseMap(!closeMap)
      }
      else {
        toast.error("Geofence pin must be inside the polygon.", { toastId: "est" })
      }
      return false;
    }
  }

  const geofenceButton = () => {
    if (enabled == true) {
      toast.error("Geofence map is already open.", { toastId: "est" })
    }
    else {
      setEnabled(true)
      setCloseMap(false)
      setGeofenceName()
      setRadius()
    }
  }

  //Constants
  const values = {
    terminalArea: "",
    terminalName: "",
    locationCategory: "",
    terminalCountry: "",
    terminalAddress: "",
    terminalLatitude: "",
    terminalLongitude: "",
    terminalCity: "",
    terminalState: "",
    zipCode: "",
    terminalRadius: "50",
    extraInformation: "",
    terminalUrl: ""
    // about: ""
  };

  const validationSchema = Yup.object().shape({
    terminalName: Yup.string()
      .test(
        "trim",
        ValidationConstants.terminalName.empty,
        (value) => value?.trim()?.length > 0
      )
      .max(50, ValidationConstants.terminalName.long)
      .min(2, ValidationConstants.terminalName.short)
      .required(ValidationConstants.terminalName.empty)
    ,
    terminalCountry: Yup.string()
      .test(
        "trim",
        ValidationConstants.terminalCountry.empty,
        (value) => value?.trim()?.length > 0
      )
      .min(2, ValidationConstants.terminalCountry.short)
      .max(20, ValidationConstants.terminalCountry.long)
      .required(ValidationConstants.terminalCountry.empty)
    ,
    terminalAddress: Yup.string().test(
      "trim",
      ValidationConstants.terminalAddress.empty,
      (value) => value?.trim()?.length > 0
    ).min(2, ValidationConstants.terminalAddress.short)
      .max(255)
      .required(ValidationConstants.terminalAddress.empty)
    ,
    terminalCity: Yup.string().test(
      "trim",
      ValidationConstants.terminalCity.empty,
      (value) => value?.trim()?.length > 0
    ).min(2, ValidationConstants.terminalCity.short)
      .max(20, ValidationConstants.terminalCity.long)
      .required(ValidationConstants.terminalCity.empty)
    ,
    terminalState: Yup.string()
      .test(
        "trim",
        ValidationConstants.terminalState.empty,
        (value) => value?.trim()?.length > 0
      )
      .min(2, ValidationConstants.terminalState.short)
      .max(20, ValidationConstants.terminalState.long)
      .required(ValidationConstants.terminalState.empty)
    ,
    zipCode: Yup.string()
      .matches("^[a-zA-Z0-9_]*$", "Please enter valid zip code.")
      .test(
        "trim",
        ValidationConstants.zipCode.empty,
        (value) => value?.trim()?.length > 0
      )
      .min(5, ValidationConstants.zipCode.short)
      .max(12, ValidationConstants.zipCode.long)
      .required(ValidationConstants.zipCode.empty)
    ,
    terminalLatitude: Yup.string()
      .test(
        "trim",
        ValidationConstants.terminalLatitude.empty,
        (value) => value?.trim()?.length > 0
      )
      .required(ValidationConstants.terminalLatitude.empty)
      .matches(
        /^(\+|-)?(?:90(?:(?:\.0{1,8})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,8})?))$/,
        ValidationConstants.terminalLatitude.invalid
      )
    ,
    terminalLongitude: Yup.string()
      .test(
        "trim",
        ValidationConstants.terminalLongitude.empty,
        (value) => value?.trim()?.length > 0
      )
      .matches(
        /^(\+|-)?(?:180(?:(?:\.0{1,8})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,8})?))$/,
        ValidationConstants.terminalLongitude.invalid
      )
      .required(ValidationConstants.terminalLongitude.empty)
    ,
    terminalRadius: Yup.string()
      .test(
        "trim",
        ValidationConstants.terminalRadius.empty,
        (value) => value?.trim()?.length > 0
      )
      .matches(/^[0-9]+$/, ValidationConstants.terminalRadius.invalid)
      .required(ValidationConstants.terminalRadius.empty)
    ,
    terminalArea: Yup.string().required(ValidationConstants.terminalArea.empty),
    locationCategory: Yup.string().required(
      ValidationConstants.locationCategory.empty
    ),
    terminalUrl: Yup.string().matches(/(https?:\/\/)?[\w\-~]+(\.[\w\-~]+)+(\/[\w\-~]*)*(#[\w\-]*)?(\?.*)?/, "Please enter valid url."),
    // about: Yup.string()
    //   .test(
    //     "trim",
    //     ValidationConstants.aboutUs.empty,
    //     (value) => value?.trim()?.length > 0
    //   )
    //   .max(500, ValidationConstants.aboutUs.long)
    //   .min(2, ValidationConstants.aboutUs.short)
    //   .required(ValidationConstants.aboutUs.empty)
    // terminalUrl: Yup.string().matches(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g, "Please enter valid url.")
  });

  var [latitude, setLatitude] = useState(values?.terminalLatitude)
  var [longitude, setLongitude] = useState(values?.terminalLongitude)

  useEffect(() => {
    document.title = appConstants.headerTitle.addTerminal;
    dispatch(getTerminalAreaListAction("", null, null, "", ""));
  }, []);

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImageFile(event.target.files[0]);
      validateImageRatio(event.target.files[0])
        .then(() => {
          let reader = new FileReader();
          reader.onload = (e) => {
            setImageUrl(e.target.result);
          };
          reader.readAsDataURL(event.target.files[0]);
        })
        .catch(() => toast.error(ValidationConstants.image.invalidRatio));
    }
  };

  const handleMapLogoChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      validateImageRatio(event.target.files[0])
        .then(() => {
          setMapLogoFile(event.target.files[0]);
          let reader = new FileReader();
          reader.onload = (e) => {
            setMapLogoUrl(e.target.result);
          };
          reader.readAsDataURL(event.target.files[0]);
        })
        .catch(() => toast.error(ValidationConstants.image.invalidRatio));
    }
  };

  const handleTerminalBoardLogoChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      validateImageRatio(event.target.files[0])
        .then(() => {
          setTerminalBoardLogoFile(event.target.files[0]);
          let reader = new FileReader();
          reader.onload = (e) => {
            setTerminalBoardLogoUrl(e.target.result);
          };
          reader.readAsDataURL(event.target.files[0]);
        })
        .catch(() => toast.error(ValidationConstants.image.invalidRatio));
    }
  };

  const handleFormSubmit = (values, { setSubmitting }) => {
    let errorp = false;
    let errorExtraTime = false;
    const {
      terminalArea,
      terminalName,
      locationCategory,
      terminalCountry,
      terminalAddress,
      terminalLatitude,
      terminalLongitude,
      terminalCity,
      terminalState,
      zipCode,
      terminalRadius,
      extraInformation,
    } = values;

    let d = [...extraTimeArray];
    let fil = d.filter((i, k) => i.reasonError != null || i.closeTimeError != null || i.openTimeerror != null)
    if (fil.length > 0) {
      return false;
    }
    let phoneNumbers = []
    phnNumberArray.map((x, index) => {
      let b = phnNumberArray.some((y, indexx) => y.value.trim() == x.value.trim() && index !== indexx)
      if (b == true) {
        toast.error(appConstants.duplicateNumber, { toastId: "est" })
        errorp = true
        return false
      }
      if (x.value !== '') {
        if (!x.errorValue && !x.nameError) {
          phoneNumbers.push({ phone: x.value, name: x.name })
        }
        else {
          setIsError(true)
        }
      }
    })
    if (phoneNumbers.length == 0 || phnNumberArray.length != phoneNumbers.length) {
      phnNumberValidations()
    }
    if (!fullTime) {
      if (selectedOpenTime == null) {
        setOpenTimeError(ValidationConstants.terminalOpenTime.empty);
      }
      else if (selectedCloseTime == null) {
        setCloseTimeError(ValidationConstants.terminalCloseTime.empty);
      }
      else if (openTimeError == null && closeTimeError == null) {
        if (fullTime == true) {
          selectedOpenTime(null);
          selectedCloseTime(null);
        }
        setOpenTimeError(null);
        setCloseTimeError(null);
        setSubmitting(false);
        if (showPolygonData.length == 0) {
          toast.error("Please create terminal polygon on map", { toastId: "est" });
        } else {
          let data = getCenterAndZoomForBackend(polygonData)
          let newExtraTime = []
          extraTimeArray.map((i, k) => {
            let b = d.some((y, indexx) => {
              let time1 = moment(i.openTime).format("hh:mm")
              let time2 = moment(y.openTime).format("hh:mm")
              return (time1 == time2 && k !== indexx)
            })
            // if (b == true) {
            //   toast.error("Open time and close time should be unique.", { toastId: "est" })
            //   errorExtraTime = true;
            //   return false;
            // }
            if (!i.reasonError && !i.closeTimeError && !i.openTimeerror) {
              if (i.reason.length > 0 && i.closeTime !== null && i.openTime !== null) {
                newExtraTime.push({ reason: i.reason.trim(), openTime: i.openTime, closeTime: i.closeTime })
              }
            }
          })
          if (templates == "url") {
            let urls = [];
            terminalUrlArray.map((x) => {
              if (x.value !== '') {
                if (!x.isError) {
                  urls.push(x.value);
                }
                else {
                  setIsError(true)
                }
              }
            })
            if (urls.length == 0 || terminalUrlArray.length != urls.length) {
              urlValidations()
            }
            // if (phoneNumbers.length == 0 || phnNumberArray.length != phoneNumbers.length) {
            //   phnNumberValidations()
            // }
            if ((terminalUrlArray.length == urls.length) || (phnNumberArray.length == phoneNumbers.length)) {
              if (errorp == false) {
                dispatch(
                  addTerminalAction(
                    imageFile,
                    urls,
                    terminalArea,
                    terminalName.trim(),
                    locationCategory,
                    terminalCountry.trim(),
                    terminalAddress.trim(),
                    terminalLatitude.trim(),
                    terminalLongitude.trim(),
                    terminalCity.trim(),
                    terminalState.trim(),
                    zipCode.trim(),
                    terminalRadius.trim(),
                    selectedOpenTime
                      ?
                      // getSelectedTime(selectedOpenTime,selectedCloseTime,"openTime")
                      // getSelectedOpenTime(selectedOpenTime,selectedCloseTime,"openTime")
                      moment(selectedOpenTime).format("YYYY-MM-DDTHH:mm:ss+00:00")
                      : selectedOpenTime,
                    selectedCloseTime

                      ?
                      getSelectedTime(selectedOpenTime, selectedCloseTime, "openTime")
                      // moment(selectedCloseTime).format("YYYY-MM-DDTHH:mm:ss+00:00")
                      : selectedCloseTime,
                    fullTime,
                    mapLogoFile,
                    terminalBoardLogoFile,
                    discription,
                    polygonData,
                    allMarkers,
                    data?.zoomLevel,
                    templates,
                    phoneNumbers,
                    fullTime ? [] : newExtraTime,
                    aboutUs,
                    showTerminal
                  )
                );
              }
            }
          }
          else if (phnNumberArray.length == phoneNumbers.length) {
            if (errorp == false) {
              dispatch(
                addTerminalAction(
                  imageFile,
                  [],
                  terminalArea,
                  terminalName.trim(),
                  locationCategory,
                  terminalCountry.trim(),
                  terminalAddress.trim(),
                  terminalLatitude.trim(),
                  terminalLongitude.trim(),
                  terminalCity.trim(),
                  terminalState.trim(),
                  zipCode.trim(),
                  terminalRadius.trim(),
                  selectedOpenTime
                    ? moment(selectedOpenTime).format("YYYY-MM-DDTHH:mm:ss+00:00")
                    : selectedOpenTime,
                  selectedCloseTime
                    ?
                    getSelectedTime(selectedOpenTime, selectedCloseTime, "openTime")
                    // moment(selectedCloseTime).format("YYYY-MM-DDTHH:mm:ss+00:00")
                    : selectedCloseTime,
                  fullTime,
                  mapLogoFile,
                  terminalBoardLogoFile,
                  discription,
                  polygonData,
                  allMarkers,
                  data?.zoomLevel,
                  templates,
                  phoneNumbers,
                  fullTime ? [] : newExtraTime,
                  aboutUs,
                  showTerminal
                )
              );
            }
          }
        }
      }
    }
    else {
      setOpenTimeError(null);
      setCloseTimeError(null);
      setSubmitting(false);
      if (showPolygonData.length == 0) {
        toast.error("Please create terminal polygon on map", { toastId: "est" });
      }
      else {
        let data = getCenterAndZoomForBackend(polygonData)
        let phoneNumbers = []
        let newExtraTime = []
        phnNumberArray.map((x) => {
          if (x.value !== '') {
            if (!x.errorValue && !x.nameError) {
              phoneNumbers.push({ phone: x.value, name: x.name })
            }
            else {
              setIsError(true)
            }
          }
        })
        extraTimeArray.map((i, k) => {
          let b = d.some((y, indexx) => {
            let time1 = moment(i.openTime).format("hh:mm")
            let time2 = moment(y.openTime).format("hh:mm")
            return (time1 == time2 && k !== indexx)
          })
          // if (b == true) {
          //   toast.error("Open time and close time should be unique.", { toastId: "est" })
          //   errorExtraTime = true;
          //   return false;
          // }
          if (!i.reasonError && !i.closeTimeError && !i.openTimeerror) {
            if (i.reason.length > 0 && i.closeTime !== null && i.openTime !== null) {
              newExtraTime.push({ reason: i.reason.trim(), openTime: i.openTime, closeTime: i.closeTime })
            }
          }
        })

        if (templates == "url") {
          let urls = [];
          let seterror = false
          terminalUrlArray.map((x) => {
            if (x.value !== '') {
              if (!x.isError) {
                urls.push(x.value);
              }
              else {
                seterror = true
                setIsError(true)
              }
            }
          })
          if (urls.length == 0 || terminalUrlArray.length != urls.length) {
            urlValidations()
          }
          if (phoneNumbers.length == 0 || phnNumberArray.length != phoneNumbers.length) {

            phnNumberValidations()
          }
          if ((terminalUrlArray.length == urls.length) || (phnNumberArray.length == phoneNumbers.length)) {
            if (errorp == false) {
              dispatch(
                addTerminalAction(
                  imageFile,
                  urls,
                  terminalArea,
                  terminalName.trim(),
                  locationCategory,
                  terminalCountry.trim(),
                  terminalAddress.trim(),
                  terminalLatitude.trim(),
                  terminalLongitude.trim(),
                  terminalCity.trim(),
                  terminalState.trim(),
                  zipCode.trim(),
                  terminalRadius.trim(),
                  selectedOpenTime
                    ? moment(selectedOpenTime).format("YYYY-MM-DDTHH:mm:ss+00:00")
                    : selectedOpenTime,
                  selectedCloseTime
                    ?
                    getSelectedTime(selectedOpenTime, selectedCloseTime, "openTime")
                    // moment(selectedCloseTime).format("YYYY-MM-DDTHH:mm:ss+00:00")
                    : selectedCloseTime,
                  fullTime,
                  mapLogoFile,
                  terminalBoardLogoFile,
                  discription,
                  polygonData,
                  allMarkers,
                  data?.zoomLevel,
                  templates,
                  phoneNumbers,
                  fullTime ? [] : newExtraTime,
                  aboutUs,
                  showTerminal
                )
              );
            }
          }
        }
        else if (phnNumberArray.length == phoneNumbers.length) {
          if (errorp == false) {
            dispatch(
              addTerminalAction(
                imageFile,
                [],
                terminalArea,
                terminalName.trim(),
                locationCategory,
                terminalCountry.trim(),
                terminalAddress.trim(),
                terminalLatitude.trim(),
                terminalLongitude.trim(),
                terminalCity.trim(),
                terminalState.trim(),
                zipCode.trim(),
                terminalRadius.trim(),
                selectedOpenTime
                  ? moment(selectedOpenTime).format("YYYY-MM-DDTHH:mm:ss+00:00")
                  : selectedOpenTime,
                selectedCloseTime
                  ?
                  getSelectedTime(selectedOpenTime, selectedCloseTime, "openTime")
                  // moment(selectedCloseTime).format("YYYY-MM-DDTHH:mm:ss+00:00")
                  : selectedCloseTime,
                fullTime,
                mapLogoFile,
                terminalBoardLogoFile,
                discription,
                polygonData,
                allMarkers,
                data?.zoomLevel,
                templates,
                phoneNumbers,
                fullTime ? [] : newExtraTime,
                aboutUs,
                showTerminal
              )
            );
          }
        }

      }
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const checklength = (value) => {

    for (let i in value) {
      if (value[i].length > 25) {
        return true
      }
    }
    return false
  }

  const onchangeData = (value) => {
    toast.dismiss()
    let data = ""
    if (value?.length > 0) {
      data = value
      let newArr = data.split("\n")
      if (newArr.length > 4) {
        toast.error("Maximum four lines are allowed for description", { toastId: 9852 || "est" })
        return
      }
      else if (checklength(newArr)) {
        toast.error("Maximum 25 characters are allowed in a single line. You can write 4 lines for the description.", { toastId: 9855 || "est" })
        return
      }
      else {
        setDiscription(value)
      }
    }
    else {
      setDiscription(value)
    }
  }

  const numberValue = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    if (value) {
      setRadius(value);
      setRadiusErrors("")
    }
    else {
      setRadius(value);
      setRadiusErrors("Please enter radius.")
    }

  };

  const mapClose = () => {
    setCloseMap(!closeMap)
    setEnabled(false)
  }

  const deleteGeofence = (index) => {
    setDeletefence(index)
  }

  const deleteButton = () => {
    let arr = [...allMarkers];
    arr = arr.filter((x, index) => index !== deletefence)
    setAllMarkers(arr);
  };

  const selectOne = (e) => {
    setTemplates(e.target.value)
  }

  const addterminalUrl = () => {
    let obj = {
      value: "",
      isError: null
    }
    let arr = [...terminalUrlArray];
    arr.push(obj);
    setTerminalUrlArray(arr);
    setIsError(false)
  }

  const addPhoneNumber = () => {
    let arr = [...phnNumberArray]
    for (let i in arr) {
      if (arr[i]?.name?.trim().length < 1) {
        arr[i].nameError = 'Please enter name.'
      }
      else if (arr[i]?.value?.length < 1) {
        arr[i].errorValue = 'Please enter phone number.'
      }
      else {
        console.log(arr, "dsfji2e")
      }
    }
    setPhoneNumberArray(arr)
    let d = arr.filter((i, k) => i.name?.trim().length < 1 || i.value?.length < 8)
    if (d.length > 0) {
      console.log('error exists');
    }
    else {
      let obj = {
        value: "",
        isError: null,
        name: "",
        nameError: null
      }
      arr.push(obj);
      setPhoneNumberArray(arr)
      return false
    }
  }

  const phnNumberValidations = (type) => {

    let regex = /^[0-9]*$/
    let arr = [...phnNumberArray];
    for (let i in arr) {
      // if(type=="phone"){

      if (arr[i]?.name?.length < 1) {
        arr[i].nameError = "Please enter name."
      }
      else if (arr[i]?.name?.length < 2) {
        arr[i].nameError = "Name should be at least 2 characters long."
      }
      else {
        arr[i].nameError = null;
      }
      if (arr[i]?.value?.length < 1) {
        arr[i].errorValue = 'Please enter phone number.'
      }
      else if (!regex.test(arr[i]?.value)) {
        arr[i].errorValue = 'Please enter valid phone number.'
      }
      else if (arr[i]?.value?.length < 8) {
        arr[i].errorValue = ValidationConstants.phoneNumber.short
      }
      else if (arr[i]?.value == 0) {
        arr[i].errorValue = 'Please enter valid phone number.'
      }
      else {
        arr[i].errorValue = null
      }
      // }
      //  else if(type=="name"){

      //  }

    }
    setPhoneNumberArray(arr)
    return false
  }


  const addExtraTime = () => {
    let arr = [...extraTimeArray]
    for (let i in arr) {
      if (arr[i]?.reason?.length < 1) {
        arr[i].reasonError = 'Please enter reason.'
      }
      else if (arr[i]?.openTime == null) {
        arr[i].openTimeerror = 'Please select open time.'
      }
      else if ((arr[i]?.closeTime == null)) {
        arr[i].closeTimeError = 'Please select close time.'
      }
      else if (moment(arr[i]?.openTime).format("YYYY-MM-DD HH:mm") >= moment(arr[i]?.closeTime).format("YYYY-MM-DD HH:mm")) {

        arr[i].closeTimeError = 'Close time should be 1 minute greater than open time.'
      }
      else {
        console.log(arr, "dsfji2e")
      }
    }
    setExtraTimeArray(arr)
    let d = arr.filter((i, k) => i.reason?.length < 1 || i.openTime == null || i.closeTime == null || moment(i?.openTime).format("YYYY-MM-DD HH:mm") >= moment(i?.closeTime).format("YYYY-MM-DD HH:mm"))
    if (d.length > 0) {
      console.log('error exists');
    }
    else {
      let obj = {
        reason: "",
        openTime: null,
        closeTime: null
      }
      arr.push(obj);
      setExtraTimeArray(arr)
      return false
    }
  }

  const cancelTerminalUrl = (indexx) => {
    let arr = [...terminalUrlArray];
    arr = arr.filter((x, index) => index !== indexx)
    arr.map(x => {
      if (x.isError) {
        setIsError(true)
      }
    })
    setTerminalUrlArray(arr);
  }

  const cancelPhoneNumber = (indexx) => {
    let arr = [...phnNumberArray];
    arr = arr.filter((x, index) => index !== indexx)
    // arr.map(x => {
    //   if (x.isError) {
    //     setIsError(true)
    //   }
    // })
    setPhoneNumberArray(arr);
  }

  const urlValidations = () => {
    var regex = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i
    var imageRegex = /(http[s]?:\/\/.*\.(?:png|jpg|jpeg))$/
    let arr = [...terminalUrlArray];
    for (let i in arr) {
      if (arr[i]?.value?.length < 1) {
        arr[i].isError = 'Please enter URL.'
        // setIsError(true)
      }
      else if (!regex.test(arr[i]?.value)) {
        arr[i].isError = 'Please enter valid URL.'
        // setIsError(true)
      }
      // else if (!imageRegex.test(arr[i]?.value)) {
      //   arr[i].isError = 'Only .jpg, .jpeg, .png format image URL allowed.'
      //   // setIsError(true)
      // }
      else {
        // setIsError(false)
        arr[i].isError = null
      }
    }
    setTerminalUrlArray(arr)
    return false
  }


  const onChangeTerminalUrl = (value, index) => {
    if (value !== ' ') {
      let arr = [...terminalUrlArray];
      arr[index].value = value.replace(/\s/g, '');
      setTerminalUrlArray(arr);
    }
    urlValidations()
  }

  const onChangePhoneNumber = (value, index, type) => {
    let arr = [...phnNumberArray];
    let regex = /^[0-9]*$/
    if (type == "phone") {
      if (value !== ' ') {
        arr[index].value = value.replace(/\s/g, '');
      }
    }
    else if (type == "name") {
      arr[index].name = value
    }
    for (let i in arr) {
      if (arr[i]?.name?.trim()?.length > 2) {
        arr[i].nameError = null
      }
      if (type == "phone") {
        if (arr[i]?.value?.length < 1) {
          arr[i].errorValue = 'Please enter phone number.'
        }
        if (!regex.test(arr[i]?.value)) {
          arr[i].errorValue = 'Please enter valid phone number.'
        }
        if (arr[i]?.value?.length < 8) {
          arr[i].errorValue = ValidationConstants.phoneNumber.short
        }
        if (arr[i]?.value == 0) {
          arr[i].errorValue = 'Please enter valid phone number.'
        }
        else if (arr[i]?.value?.length > 8) {
          arr[i].errorValue = null
        }
      }
    }
    setPhoneNumberArray(arr);
    // phnNumberValidations()
  }

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  const extraTimeValidation = () => {
    let arr = [...extraTimeArray];
    for (let i in arr) {
      if (arr[i]?.reason?.length < 1) {
        arr[i].reasonError = 'Please enter reason.'
      }
      else if (arr[i]?.reason?.trim()?.length < 2) {
        arr[i].reasonError = 'Reason name should be at least 2 characters long.'
      }

      else {
        arr[i].reasonError = null
      }
      setExtraTimeArray(arr)
      // return false
    }
  }

  const extraTimeValidation1 = () => {
    let arr = [...extraTimeArray];
    for (let i in arr) {

      if (arr[i]?.openTime == null) {
        arr[i].openTimeerror = 'Please select open time.'
        return false;
      }
      if (arr[i]?.openTime !== null) {
        arr[i].openTimeerror = null
      }

      setExtraTimeArray(arr)
      // return false
    }
  }

  const extraTimeValidation2 = () => {
    let arr = [...extraTimeArray];
    for (let i in arr) {
      if (arr[i]?.closeTime == null) {
        arr[i].closeTimeError = 'Please select close time.'
      }
      else if (moment(arr[i]?.openTime).format("YYYY-MM-DD HH:mm") >= moment(arr[i]?.closeTime).format("YYYY-MM-DD HH:mm")) {

        arr[i].closeTimeError = 'Close time should be 1 minute greater than open time.'
      }
      // else if(moment(arr[i]?.openTime).format("YYYY-MM-DD hh:mm") !== moment(arr[i]?.closeTime).format("YYYY-MM-DD hh:mm")) {
      //   console.log("remove====>>",moment(arr[i]?.openTime).format("YYYY-MM-DD hh:mm"),
      //   moment(arr[i]?.closeTime).format("YYYY-MM-DD hh:mm")
      //   )
      //   arr[i].closeTimeError = null
      // }
      else {

        arr[i].closeTimeError = null
      }
      setExtraTimeArray(arr)
      // return false
    }
  }

  const handleChangeExtraTime = (indexx, value, text) => {

    let arr = [...extraTimeArray]
    if (value == "reason") {
      arr[indexx].reason = text;
    }
    if (value == "openTime") {
      arr[terminalIndex].openTime = text;
    }
    if (value == "closeTime") {
      arr[terminalIndex].closeTime = text;
    }
    for (let i in arr) {
      if (arr[i]?.reason?.trim()?.length > 2) {
        arr[i].reasonError = null
      }
      if (moment(arr[i]?.openTime).format("YYYY-MM-DD HH:mm") !== moment(arr[i]?.closeTime).format("YYYY-MM-DD HH:mm")) {

        arr[i].closeTimeError = null
      }
    }
    setExtraTimeArray(arr)
    // extraTimeValidation()
  }

  const cancelExtraTime = (indexx) => {
    let arr = [...extraTimeArray];
    arr = arr.filter((x, index) => index !== indexx)
    setExtraTimeArray(arr);
  }

  return (
    <Spin
      size="large"
      spinning={isLoading}
      wrapperClassName={classes.tableSpinner}
      className={classes.antSpin}
    >
      <DashboardLayout>
        <ConfirmationModal
          title="Alert"
          text={appConstants.confirmGeofence}
          visible={confirm1}
          disable={disableButton}
          onCancel={() => {
            setDeletefence(null)
            setConfirm1(false)
          }}
          onDeleteConfirmed={() => {
            deleteButton(deletefence)
            setConfirm1(false)
          }}
        />
        <AppBar
          breadcrumbs={[
            { route: "/terminals", name: "Terminal Listing" },
            { route: "/add-terminal", name: "Add Terminal" },
          ]}
        />
        <Navbar title="Add Terminal" href="/User Management" />
        <Card className={classes.card}>
          <Grid container>
            <Grid className={classes.tableContainerRow}>
              <Formik
                enableReinitialize
                initialValues={values}
                validationSchema={validationSchema}
                onSubmit={handleFormSubmit}
              >
                {({
                  values,
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <BSTable striped bordered className={classes.terminalTable}>
                      <tbody>
                        <tr>
                          <td className={classes.rowKey}>Image Type</td>
                          <td className={classes.rowValue}>
                            <div className={classes.inputWrapper}>
                              <RadioGroup aria-label="template" name="template" value={templates} onChange={selectOne}>
                                <FormControlLabel value="file" control={<Radio />}
                                  checked={templates == "file"}
                                  label="Terminal Image"
                                />
                                <FormControlLabel value="url" control={<Radio />}
                                  checked={templates == "url"}
                                  label="Terminal Image URL"
                                />
                              </RadioGroup>
                            </div>
                          </td>
                        </tr>
                        {
                          templates == "url" ?
                            <>
                              <tr>
                                <td className={classes.rowKey}>Terminal Image URL</td>
                                <td className={classes.rowValue}>
                                  <div className={classes.inputWrapper}>
                                    {
                                      terminalUrlArray.map((item, index) => {
                                        return (
                                          <div className={classes.inputfieldsNew} style={{ flexDirection: 'row' }}>
                                            <div className={classes.textBoxes} style={{
                                              display: 'flex', width: index > 0 && "105%",
                                              marginTop: index == 0 && "14px"
                                            }}>
                                              <InputField
                                                placeholder="Enter Terminal Image URL"
                                                name="terminalUrl"
                                                onBlur={handleBlur}
                                                onChange={(e) => onChangeTerminalUrl(e.target.value, index)}
                                                type="name"
                                                value={item?.value}
                                                onKeyDown={handleKeyDown}
                                              />
                                              {
                                                index > 0 &&
                                                <div style={{ flexDirection: 'column', alignSelf: 'flex-end' }} onClick={() => cancelTerminalUrl(index)}>
                                                  <img
                                                    src={AppImages.crossButton}
                                                    style={{ cursor: "pointer", marginTop: "-20px" }}
                                                    height={18}
                                                    width={18}
                                                  />
                                                </div>
                                              }
                                            </div>
                                            {item.isError != null &&
                                              <div className="url-Error">{item.isError}</div>}
                                          </div>
                                        )
                                      })
                                    }
                                    {
                                      terminalUrlArray.length < 8 &&
                                      <h6
                                        style={{ cursor: "pointer", width: "126px" }}
                                        onClick={() => addterminalUrl()}
                                      >
                                        + Add Image URL
                                      </h6>
                                    }
                                  </div>
                                </td>
                              </tr>
                            </>
                            :
                            <tr>
                              <td className={classes.rowKey}>Terminal Image</td>
                              <td className={classes.rowValue}>
                                <div className={classes.inputWrapper}>
                                  <input
                                    accept=".png,.jpg,.jpeg"
                                    className={classes.uploaderInput}
                                    id="icon-button-file"
                                    type="file"
                                    onChange={handleImageChange}
                                  />
                                  <label
                                    htmlFor="icon-button-file"
                                    className={classes.imageUploaderlabel}
                                  >
                                    <Tooltip
                                      title={
                                        appConstants.tooltip.uploadTerminalImage
                                      }
                                      placement="right"
                                    >
                                      {imageUrl ? (
                                        <img
                                          src={imageUrl}
                                          className={classes.terminalImage}
                                        />
                                      ) : (
                                        <img
                                          src={AppImages.addPhoto}
                                          className={classes.terminalImage}
                                        />
                                      )}
                                    </Tooltip>
                                  </label>
                                </div>
                              </td>
                            </tr>
                        }
                        <tr>
                          <td className={classes.rowKey}>Region</td>
                          <td className={classes.rowValue}>
                            <div className={classes.inputWrapper}>
                              <Select
                                native
                                className={classes.selectInput}
                                value={values.terminalArea}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                inputProps={{
                                  name: "terminalArea",
                                  id: "terminalArea",
                                }}
                              >
                                <option value="">Select Region</option>
                                {terminalAreaListing.map(
                                  (terminalArea, index) => {
                                    return (

                                      <option value={terminalArea._id}>
                                        {terminalArea.region_name}
                                      </option>


                                    );
                                  }
                                )}
                              </Select>
                              {touched.terminalArea && errors.terminalArea && (
                                <FormHelperText error id="component-error-text">
                                  {errors.terminalArea}
                                </FormHelperText>
                              )}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className={classes.rowKey}>Terminal Name</td>
                          <td className={classes.rowValue}>
                            <div className={classes.inputWrapper}>
                              <InputField
                                placeholder="Enter Terminal Name"
                                name="terminalName"
                                error={Boolean(
                                  touched.terminalName && errors.terminalName
                                )}
                                helperText={
                                  touched.terminalName && errors.terminalName
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}

                                type="name"
                                value={values?.terminalName?.trimLeft()}
                                maxLength={50}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className={classes.rowKey}>Location Category</td>
                          <td className={classes.rowValue}>
                            <div className={classes.inputWrapper}>
                              <Select
                                native
                                className={classes.selectInput}
                                value={values.locationCategory}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                inputProps={{
                                  name: "locationCategory",
                                  id: "locationCategory",
                                }}
                              >
                                <option value="">
                                  Select Location Category
                                </option>
                                <option value="Chassis Depot">
                                  Chassis Depot
                                </option>
                                <option value="Empty Depot">Empty Depot</option>
                                <option value="Rail Terminal">
                                  Rail Terminal
                                </option>
                                <option value="Port Terminal">
                                  Port Terminal
                                </option>
                                <option value="Warehouse">Warehouse</option>
                                <option value="Equipment Depot">Equipment Depot</option>
                              </Select>
                              {touched.locationCategory &&
                                errors.locationCategory && (
                                  <FormHelperText
                                    error
                                    id="component-error-text"
                                  >
                                    {errors.locationCategory}
                                  </FormHelperText>
                                )}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className={classes.rowKey}>Terminal Country</td>
                          <td className={classes.rowValue}>
                            <div className={classes.inputWrapper}>
                              <InputField
                                placeholder="Enter Terminal Country"
                                name="terminalCountry"
                                error={Boolean(
                                  touched.terminalCountry &&
                                  errors.terminalCountry
                                )}
                                helperText={
                                  touched.terminalCountry &&
                                  errors.terminalCountry
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="name"
                                value={values?.terminalCountry?.trimLeft()}
                                maxLength={20}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className={classes.rowKey}>Terminal Address</td>
                          <td className={classes.rowValue}>
                            <div className={classes.inputWrapper}>
                              <InputField
                                placeholder="Enter Terminal Address"
                                name="terminalAddress"
                                error={Boolean(
                                  touched.terminalAddress &&
                                  errors.terminalAddress
                                )}
                                helperText={
                                  touched.terminalAddress &&
                                  errors.terminalAddress
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="name"
                                value={values?.terminalAddress?.trimLeft()}
                                maxLength={255}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className={classes.rowKey}>Terminal Latitude</td>
                          <td className={classes.rowValue}>
                            <div className={classes.inputWrapper}>
                              <InputField
                                placeholder="Enter Terminal Latitude"
                                name="terminalLatitude"
                                error={Boolean(
                                  touched.terminalLatitude &&
                                  errors.terminalLatitude
                                )}
                                autoComplete="off"
                                helperText={
                                  touched.terminalLatitude &&
                                  errors.terminalLatitude
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="name"
                                value={values?.terminalLatitude}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className={classes.rowKey}>Terminal Longitude</td>
                          <td className={classes.rowValue}>
                            <div className={classes.inputWrapper}>
                              <InputField
                                placeholder="Enter Terminal Longitude"
                                name="terminalLongitude"
                                error={Boolean(
                                  touched.terminalLongitude &&
                                  errors.terminalLongitude
                                )}
                                autoComplete="off"
                                helperText={
                                  touched.terminalLongitude &&
                                  errors.terminalLongitude
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="name"
                                value={values?.terminalLongitude}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className={classes.rowKey}>Terminal City</td>
                          <td className={classes.rowValue}>
                            <div className={classes.inputWrapper}>
                              <InputField
                                // disabled={fullTime}
                                placeholder="Enter Terminal City"
                                name="terminalCity"
                                error={Boolean(
                                  touched.terminalCity && errors.terminalCity
                                )}
                                helperText={
                                  touched.terminalCity && errors.terminalCity
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="name"
                                value={values?.terminalCity?.trimLeft()}
                                maxLength={20}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className={classes.rowKey}>State/Province</td>
                          <td className={classes.rowValue}>
                            <div className={classes.inputWrapper}>
                              <InputField
                                // disabled={fullTime}
                                placeholder="Enter State/Province"
                                name="terminalState"
                                error={Boolean(
                                  touched.terminalState && errors.terminalState
                                )}
                                helperText={
                                  touched.terminalState && errors.terminalState
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="name"
                                value={values?.terminalState?.trimLeft()}
                                maxLength={20}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className={classes.rowKey}>Zip Code</td>
                          <td className={classes.rowValue}>
                            <div className={classes.inputWrapper}>
                              <InputField
                                placeholder="Enter Zip Code"
                                name="zipCode"
                                error={Boolean(
                                  touched.zipCode && errors.zipCode
                                )}
                                helperText={touched.zipCode && errors.zipCode}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="name"
                                value={values?.zipCode?.trimLeft()}
                                maxLength={12}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className={classes.rowKey}>Terminal Radius</td>
                          <td className={classes.rowValue}>
                            <div className={classes.inputWrapper}>
                              <InputField
                                placeholder="Enter Terminal Radius"
                                name="terminalRadius"
                                error={Boolean(
                                  touched.terminalRadius &&
                                  errors.terminalRadius
                                )}
                                helperText={
                                  touched.terminalRadius &&
                                  errors.terminalRadius
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="name"
                                value={values.terminalRadius}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className={classes.rowKey}>Phone Number</td>
                          <td className={classes.rowValue}>
                            {
                              phnNumberArray.map((item, index) => {
                                return (
                                  <div style={{ display: "flex", flexDirection: "row", }}>
                                    <div style={{ display: "flex", }}>
                                      <div style={{ display: "flex", width: index > 0 ? "432px" : "432px" }}>
                                        <div style={{
                                          width: "45%",
                                          marginBottom: "10px",
                                          flexDirection: "column", marginRight: "5%"
                                        }}>
                                          <InputField
                                            // disabled={fullTime}
                                            placeholder="Enter Name"
                                            name="name"
                                            onChange={(e) => {
                                              onChangePhoneNumber(e.target.value, index, "name")
                                              // setPhnNumberValue(e.target.value)
                                            }}
                                            onBlur={() => phnNumberValidations("name")}
                                            onFocus={() => phnNumberValidations("name")}
                                            type="name"
                                            value={item?.name.trimLeft()}
                                            maxLength={30}
                                          />
                                          {item.nameError != null &&
                                            <div className="url-Error">{item.nameError}</div>}
                                        </div>
                                        <div style={{ width: "45%", marginBottom: "10px", flexDirection: "column" }}>
                                          <InputField
                                            // disabled={fullTime}
                                            placeholder="Enter Phone Number"
                                            name="value"
                                            onChange={(e) => {
                                              onChangePhoneNumber(e.target.value, index, "phone")
                                              // setPhnNumberValue(e.target.value)
                                            }}
                                            onBlur={() => phnNumberValidations("phone")}
                                            onFocus={() => phnNumberValidations("phone")}
                                            type="name"
                                            value={item?.value}
                                            maxLength={15}
                                          />
                                          {item.errorValue != null &&
                                            <div className="url-Error">{item.errorValue}</div>}
                                        </div>
                                        {
                                          index > 0 &&
                                          <div style={{ marginLeft: "6px", marginTop: "7px" }}
                                            onClick={() => cancelPhoneNumber(index)}>
                                            <img
                                              src={AppImages.crossButton}
                                              style={{ cursor: "pointer", }}
                                              height={18}
                                              width={18}
                                            />
                                          </div>
                                        }
                                      </div>


                                    </div>
                                  </div>
                                )
                              })
                            }
                            {
                              phnNumberArray.length < 10 &&
                              <h6
                                style={{ cursor: "pointer", width: "156px" }}
                                onClick={() => addPhoneNumber()}
                              >
                                + Add Phone Number
                              </h6>
                            }

                          </td>
                        </tr>
                        <tr>
                          <td className={classes.rowKey}>Full Day Terminal</td>
                          <td className={classes.rowValue}>
                            <div className={classes.inputWrapper}>
                              <Checkbox
                                color="primary"
                                value={fullTime}
                                onChange={() => {
                                  setFullTime(!fullTime)
                                  // setExtraTimeArray([{ reason: "", closeTime: null, openTime: null }])
                                }
                                }
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </BSTable>
                    <Collapse in={!fullTime} unmountOnExit>
                      <BSTable bordered className={classes.terminalTable}>
                        <tbody>
                          <tr
                            className={classes.timingRow}
                            style={{ transitionDuration: "0.8s !important" }}
                          >
                            <td className={classes.rowKey}>
                              Terminal Open Time
                            </td>
                            <td className={classes.rowValue}>
                              <div className={classes.inputWrapper} >
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <TimePicker
                                    disabled={fullTime}
                                    ampm={true}
                                    placeholder="Select Open Time"
                                    name="terminalOpenTime"

                                    value={selectedOpenTime}
                                    onAccept={(time) => {
                                      setOpenTimeError(null);
                                      setOpenTimePicker(false);
                                    }}
                                    onClose={() => setOpenTimePicker(false)}
                                    initialFocusedDate={
                                      ValidationConstants.terminalOpenTime
                                        .invalid
                                    }
                                    onBlur={handleBlur}
                                    onChange={(time) =>
                                      setSelectedOpenTime(time)
                                    }
                                    className={clsx([
                                      classes.selectInput,
                                      classes.timePicker,
                                      "cursor"
                                    ])}
                                    open={openTimePicker}
                                    onOpen={() => setOpenTimePicker(true)}
                                  />
                                </MuiPickersUtilsProvider>
                                <FontAwesomeIcon
                                  icon={faClock}
                                  className={classes.timeIcon}
                                  onClick={() => setOpenTimePicker(true)}
                                />
                                {openTimeError && (
                                  <FormHelperText
                                    error
                                    id="component-error-text"
                                  >
                                    {openTimeError}
                                  </FormHelperText>
                                )}
                              </div>
                            </td>
                          </tr>
                          <tr className={classes.timingRow}>
                            <td className={classes.rowKey}>
                              Terminal Close Time
                            </td>
                            <td className={classes.rowValue}>
                              <div className={classes.inputWrapper} >
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <TimePicker
                                    disabled={fullTime}
                                    ampm={true}
                                    placeholder="Select Close Time"
                                    name="terminalCloseTime"
                                    // openTo="minutes"
                                    value={selectedCloseTime}
                                    onBlur={handleBlur}
                                    onAccept={(time) => {
                                      setCloseTimeError(null);
                                      setCloseTimePicker(false);
                                    }}
                                    onClose={() => setCloseTimePicker(false)}
                                    initialFocusedDate={ValidationConstants.terminalCloseTime.invalid}
                                    onChange={(time) =>
                                      setSelectedCloseTime(time)
                                    }
                                    className={clsx([
                                      classes.selectInput,
                                      classes.timePicker,
                                      "cursor"
                                    ])}
                                    open={closeTimePicker}
                                    onOpen={() => setCloseTimePicker(true)}
                                  />
                                </MuiPickersUtilsProvider>
                                <FontAwesomeIcon
                                  icon={faClock}
                                  className={classes.timeIcon}
                                  onClick={() => setCloseTimePicker(true)}
                                />
                                {closeTimeError && (
                                  <FormHelperText error id="component-error-text" >
                                    {closeTimeError}
                                  </FormHelperText>
                                )}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </BSTable>
                    </Collapse>
                    {(fullTime == false && (selectedOpenTime && selectedCloseTime)) &&
                      <BSTable bordered className={classes.terminalTable}>
                        <tbody>
                          <tr>
                            <td className={classes.rowKey}>Add Extra Time</td>
                            <td className={classes.rowValue}>
                              {
                                extraTimeArray.map((item, index) => {
                                  return (
                                    <div style={{ display: "flex", flexDirection: "row", }}>
                                      <div style={{ display: "flex", }}>
                                        <div style={{ display: "flex", width: "540px", justifyContent: "space-between" }}>
                                          <div style={{ width: "30%", marginBottom: "10px", flexDirection: "column" }}>
                                            <InputField
                                              placeholder="Enter Reason"
                                              name="Reason"
                                              onChange={(e) => handleChangeExtraTime(index, "reason", e.target.value)}
                                              type="Reason"
                                              value={item.reason}
                                              maxLength={20}
                                              onBlur={extraTimeValidation}
                                              onFocus={extraTimeValidation}
                                            // value={values.terminalRadius}
                                            />
                                            {item.reasonError != null &&
                                              <div className="url-Error">{item.reasonError}</div>}
                                          </div>
                                          <div style={{ width: "30%", flexDirection: "column" }}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                              <TimePicker

                                                ampm={true}
                                                placeholder="Select Open Time"
                                                name="openTime"
                                                // openTo="minutes"
                                                value={item.openTime}
                                                onAccept={(time) => {
                                                  setOpenTimeError(null);
                                                  setOpenExtraTimePicker(false);
                                                }}
                                                onClose={() => setOpenExtraTimePicker(false)}
                                                initialFocusedDate={
                                                  ValidationConstants.terminalOpenTime
                                                    .invalidonBlur = { handleBlur }
                                                }
                                                onBlur={extraTimeValidation1}
                                                onFocus={extraTimeValidation1}
                                                onChange={(e) => {
                                                  setTerminalIndex(index)
                                                  handleChangeExtraTime(index, "openTime", e)
                                                }}
                                                // onChange={(time) =>
                                                //   setOpenExtraTime(time)
                                                // }
                                                className={clsx([
                                                  classes.selectInput,
                                                  classes.timePicker,
                                                  "cursor"
                                                ])}
                                                open={openExtraTimePicker}
                                                onOpen={() => {
                                                  setTerminalIndex(index)
                                                  setOpenExtraTimePicker(true)
                                                }}
                                              />
                                            </MuiPickersUtilsProvider>
                                            {/* <FontAwesomeIcon
                                            icon={faClock}
                                            className={classes.timeIcon}
                                            onClick={() => setOpenExtraTimePicker(true)}
                                          /> */}
                                            {item.openTimeerror != null &&
                                              <div className="url-Error">{item.openTimeerror}</div>}
                                          </div>
                                          <div style={{ width: "30%", flexDirection: "column" }}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                              <TimePicker
                                                ampm={true}
                                                placeholder="Select Close Time"
                                                name="closeTime"
                                                // openTo="minutes"
                                                value={item.closeTime}
                                                onChange={(e) => {
                                                  setTerminalIndex(index)
                                                  handleChangeExtraTime(index, "closeTime", e)
                                                }}
                                                onBlur={extraTimeValidation2}
                                                onFocus={extraTimeValidation2}
                                                onAccept={(time) => {
                                                  setCloseTimeError(null);
                                                  setCloseExtraTimePicker(false);
                                                }}
                                                onClose={() => setCloseExtraTimePicker(false)}
                                                initialFocusedDate={ValidationConstants.terminalCloseTime.invalid}
                                                // onChange={(time) =>
                                                //   setCloseExtraTime(time)
                                                // }
                                                className={clsx([
                                                  classes.selectInput,
                                                  classes.timePicker,
                                                  "cursor"
                                                ])}
                                                open={closeExtraTimePicker}
                                                onOpen={() => {
                                                  setTerminalIndex(index)
                                                  setCloseExtraTimePicker(true)
                                                }}
                                              />
                                            </MuiPickersUtilsProvider>
                                            {/* <FontAwesomeIcon
                                            icon={faClock}
                                            className={classes.timeIcon}
                                            onClick={() => setCloseExtraTimePicker(true)}
                                          /> */}
                                            {item.closeTimeError != null &&
                                              <div className="url-Error">{item.closeTimeError}</div>}
                                          </div>
                                        </div>
                                      </div>
                                      {
                                        <div style={{ marginLeft: "6px", marginTop: "7px" }}
                                          onClick={() => cancelExtraTime(index)}>
                                          <img
                                            src={AppImages.crossButton}
                                            style={{
                                              cursor: "pointer",

                                            }}
                                            height={18}
                                            width={18}
                                          />
                                        </div>
                                      }
                                    </div>
                                  )
                                })
                              }
                              {
                                extraTimeArray.length < 12 &&
                                <div>
                                  <h6
                                    style={{ cursor: "pointer", width: "126px" }}
                                    onClick={() => addExtraTime()}
                                  >
                                    + Add Extra Time
                                  </h6>
                                  <p className="extra_time">(Note:-please add Already open Extra Time then you can add new Extra time.)</p>
                                </div>
                              }
                              {/* <div className={classes.inputWrapper}>
                              <Button
                                title="Add Extra Time"
                                value={extraTime}
                                onClick={() => setExtraTime(!extraTime)}
                              />
                            </div> */}
                            </td>
                          </tr>
                        </tbody>
                      </BSTable>}
                    <Collapse in={!extraTime} unmountOnExit>
                      <BSTable bordered className={classes.terminalTable}>
                        <tbody>
                          <tr>
                            <td className={classes.rowKey}></td>
                            <td className={classes.rowValue}>

                            </td>
                          </tr>
                        </tbody>
                      </BSTable>
                    </Collapse>
                    <BSTable striped bordered className={classes.terminalTable}>
                      <tbody>
                        <tr>
                          <td className={classes.rowKey}>About Us</td>
                          <td className={classes.rowValue}>
                            <div className={classes.inputWrapper}>
                              <div className={classes.inputWrapper}>
                                <InputField
                                  textArea={true}
                                  id="description"
                                  placeholder="Enter About Us"
                                  // error={Boolean(
                                  //   touched.about && errors.about
                                  // )}
                                  // helperText={
                                  //   touched.about && errors.about
                                  // }
                                  // onBlur={handleBlur}
                                  onChange={(e) => setAboutUs(e.target.value)}
                                  name="about"
                                  type="name"
                                  maxLength={500}
                                  value={aboutUs?.trimLeft()}
                                />
                              </div>

                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className={classes.rowKey}>Show terminal</td>
                          <td className={classes.rowValue}>
                            <div className={classes.inputWrapper}>
                              <Checkbox
                                color="primary"
                                name="showTerminal"
                                value={showTerminal}
                                checked={showTerminal}
                                onChange={() => {
                                  setShowTerminal(!showTerminal)
                                }
                                }
                              />
                            </div>
                          </td>
                        </tr>
                        {/* <tr>
                          <td className={classes.rowKey}>Description</td>
                          <td className={classes.rowValue}>
                            <div className={classes.inputWrapper}>
                              <textarea
                                className="textAreaNew"
                                placeholder="Enter Description"
                                onChange={(e) => onchangeData(e.target.value)}
                                type="description"
                                value={discription}
                                name="description"
                              />
                            </div>
                          </td>
                        </tr> */}
                        <tr>
                          <td className={classes.rowKey}>Map Logo</td>
                          <td className={classes.rowValue}>
                            <div className={classes.inputWrapper}>
                              <input
                                accept=".png,.jpg,.jpeg"
                                className={classes.uploaderInput}
                                id="terminal-map-logo"
                                type="file"
                                onChange={handleMapLogoChange}
                              />
                              <label
                                htmlFor="terminal-map-logo"
                                className={classes.imageUploaderlabel}
                              >
                                <Tooltip
                                  title={appConstants.tooltip.uploadMapLogo}
                                  placement="right"
                                >
                                  {mapLogoUrl ? (
                                    <img
                                      src={mapLogoUrl}
                                      className={classes.terminalImage}
                                    />
                                  ) : (
                                    <img
                                      src={AppImages.pin}
                                      className={classes.terminalImage}
                                    />
                                  )}
                                </Tooltip>
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className={classes.rowKey}>
                            Terminal Board Logo
                            <br />
                            <small
                              style={{
                                color: "#555555",
                                textAlign: "center",
                                width: "100%",
                              }}
                            >
                              (Transparent Logo)
                            </small>
                          </td>
                          <td className={classes.rowValue}>
                            <div className={classes.inputWrapper}>
                              <input
                                accept=".png,.jpg,.jpeg"
                                className={classes.uploaderInput}
                                id="terminal-board-logo"
                                type="file"
                                onChange={handleTerminalBoardLogoChange}
                              />
                              <label
                                htmlFor="terminal-board-logo"
                                className={classes.imageUploaderlabel}
                              >
                                <Tooltip
                                  title={
                                    appConstants.tooltip.uploadTerminalBoardLogo
                                  }
                                  placement="right"
                                >
                                  {terminalBoardLogoUrl ? (
                                    <img
                                      src={terminalBoardLogoUrl}
                                      className={classes.terminalImage}
                                    />
                                  ) : (
                                    <img
                                      src={AppImages.pin}
                                      className={classes.terminalImage}
                                    />
                                  )}
                                </Tooltip>
                              </label>
                            </div>
                          </td>
                        </tr>
                        {showPolygonData.length > 0 &&
                          <tr>
                            <td className={classes.rowKey}>
                              Terminal Polygon
                              <br />
                              <small
                                style={{
                                  color: "#555555",
                                  textAlign: "center",
                                  width: "100%",
                                }}
                              >
                                (latitude, longitude)
                              </small>
                            </td>
                            <td className={classes.rowValue}>
                              <div className={classes.inputWrapper}>
                                <textarea
                                  className="textAreaNew"
                                  disabled={true}
                                  placeholder="Enter Description"
                                  type="showPolygonData"
                                  value={showPolygonData}
                                  name="showPolygonData"
                                />
                              </div>
                            </td>
                          </tr>
                        }
                        {(values?.terminalLatitude && values?.terminalLongitude) ?
                          <>
                            {allMarkers.length > 0 &&
                              <tr>
                                <td className={classes.rowKey}>Geofence Points</td>
                                {
                                  allMarkers.map((item, index) => {
                                    return (
                                      <>
                                        <div className={classes.inputfields} style={{ flexDirection: 'row' }}>
                                          <div className={classes.textBoxes} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <div style={{ flexDirection: 'column', width: '15%' }}>
                                              {index == 0 &&
                                                <div>
                                                  <h6>Name</h6>
                                                </div>
                                              }
                                              <InputField
                                                placeholder="Enter Name"
                                                value={item?.name}
                                                disabled={true}
                                              />
                                            </div>
                                            <div style={{ flexDirection: 'column', width: '15%' }}>
                                              {index == 0 &&
                                                <h6>Latitude</h6>
                                              }
                                              <InputField
                                                placeholder="Enter Latitude"
                                                value={item?.lat?.toFixed(6)}
                                                disabled={true}
                                              />
                                            </div><div style={{ flexDirection: 'column', width: '15%' }}>
                                              {index == 0 &&
                                                <div >
                                                  <h6>Longitude</h6>
                                                </div>
                                              }
                                              <InputField
                                                placeholder="Enter Longitude"
                                                value={item?.lng?.toFixed(6)}
                                                disabled={true}
                                              />
                                            </div>
                                            <div style={{ flexDirection: 'column', width: '15%' }}>
                                              {index == 0 &&
                                                <div >
                                                  <h6>Radius</h6>
                                                </div>
                                              }
                                              <InputField
                                                placeholder="Enter Radius"
                                                value={item?.radius}
                                                type="number"
                                                disabled={true}
                                              />
                                            </div>
                                            <div style={{ flexDirection: 'column', width: 100, alignSelf: 'flex-end' }}>
                                              <Button
                                                title="Delete"
                                                mrt
                                                onClick={() => {
                                                  deleteGeofence(index)
                                                  setConfirm1(true)
                                                }
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )
                                  })
                                }
                              </tr>
                            }
                            <tr>
                              <td className={classes.rowKey}>Polygon Area</td>
                              <td className={classes.rowValue}>
                                <Button
                                  disabled={allMarkers.length >= 4 ? true : (firstPoint) ? false : true}
                                  title="Add Geofence"
                                  onClick={() => geofenceButton()}
                                  mr
                                />
                                <div className={classes.inputWrapper} style={{ width: "100%", height: 500 }} >
                                  <div style={{ width: "100%", background: 'orange', marginTop: 10 }} >
                                    <GoogleMap
                                      setMapSettings={null}
                                      onMapClicked={onMapClicked}
                                      setCircleZoom={setCircleZoom}
                                      onCircleClicked={onCircleClicked}
                                      onClearClicked={onClearClicked}
                                      zoom={16}
                                      polygonState={polygonState}
                                      circleZoom={circleZoom}
                                      firstPoint={firstPoint}
                                      radius={Number(radius)}
                                      markersArray={allMarkers}
                                      center={{ lat: Number(values?.terminalLatitude), lng: Number(values?.terminalLongitude) }}
                                      marker={{ lat: Number(values?.terminalLatitude), lng: Number(values?.terminalLongitude) }}
                                      centerPoint={null}
                                      terminalDetails={allMarkers}
                                      // setMapSettings={null}
                                      value={null}
                                      isEditing={false}
                                      mapSettings={true}
                                    />
                                  </div>
                                  <div style={{ justifyContent: "flex-start", width: "70%", position: 'absolute', bottom: 0, alignSelf: 'center' }}>
                                    <h6>(Note: Click on clear button will clear the polygon area and geofences.)</h6>
                                  </div>
                                  <div style={{ display: 'flex', flexDirection: "row", height: "100%", width: "100%" }}>

                                    <div style={{ display: "flex", justifyContent: 'flex-end', position: 'absolute', width: "30%", bottom: 0, right: 0 }} >
                                      <Button disabled={polygonState.points.length > 1 ? false : true} title="Undo" ml onClick={() => onUndoClick()} />
                                      <Button disabled={polygonState.points.length > 0 ? false : true} red mrt title="Clear" ml onClick={() => onClearClicked()} />
                                    </div>
                                  </div>

                                </div>

                              </td>
                            </tr>
                          </>
                          :
                          null
                        }
                      </tbody>
                    </BSTable>
                    <Collapse in={!closeMap} unmountOnExit>
                      <BSTable bordered className={classes.terminalTable}>
                        <tbody>
                          {(enabled === true) ?
                            <tr>
                              <td className={classes.rowKey}>Geofence Area</td>
                              <td className={classes.rowValue}>
                                <div className={classes.inputfieldsNew} style={{ flexDirection: 'row' }}>
                                  <div className={classes.textBoxes} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    {/* <div className={classes.textBoxes}> */}
                                    <div style={{ flexDirection: 'column', width: '15%' }}>
                                      <InputField
                                        placeholder="Enter Name"
                                        onChange={(value) =>
                                        // geofenceValidation()
                                        {
                                          setGeofenceName(value.target.value);
                                          setGeofenceErrors("");
                                        }
                                        }
                                        type="name"
                                        value={geofenceName?.trimLeft()}
                                        maxLength={50}

                                      />
                                      {geofenceErrors && (
                                        <div style={{ width: '20vw' }}>
                                          <FormHelperText
                                            error id="component-error-text" >
                                            {geofenceErrors}
                                          </FormHelperText>
                                        </div>
                                      )}
                                    </div>
                                    <div style={{ flexDirection: 'column', width: '15%' }}>
                                      <InputField
                                        className="textBoxes"
                                        placeholder="Latitude"
                                        onChange={handleChange}
                                        type="name"
                                        value={Number(latitude)?.toFixed(6)}
                                        disabled={true}
                                      />
                                    </div>
                                    <div style={{ flexDirection: 'column', width: '15%' }}>
                                      <InputField
                                        className="text-boxes"
                                        placeholder="Longitude"
                                        onChange={handleChange}
                                        type="name"
                                        value={Number(longitude)?.toFixed(6)}
                                        disabled={true}
                                      />
                                    </div>
                                    <div style={{ flexDirection: 'column', width: '15%' }}>
                                      <InputField
                                        className="text-boxes"
                                        name="radius"
                                        placeholder="Radius"
                                        // onChange={(e) => {
                                        //   setRadius(e.target.value);
                                        //   setRadiusErrors("");
                                        // }}
                                        type="name"
                                        value={radius}
                                        onChange={numberValue}
                                        maxLength={10}
                                      />
                                      {radiusErrors && (
                                        <div style={{ width: '20vw' }}>
                                          <FormHelperText error id="component-error-text"
                                          >
                                            {radiusErrors}
                                          </FormHelperText>
                                        </div>
                                      )}
                                    </div>
                                    <Button
                                      mrt
                                      title="Save"
                                      onClick={onSaveClicked}
                                      value={closeMap}
                                    />
                                  </div>
                                </div>
                                <div className={classes.inputWrapper} style={{ width: "100%", height: 500, marginTop: "-11px" }} >
                                  <div style={{ width: "100%", background: 'orange', marginTop: 10 }} >

                                    <GeofenceGoogleMap
                                      setMapSettings={null}
                                      polygonState={polygonState}
                                      onMapClicked={onGeofenceClicked}
                                      zoom={16}
                                      radius={Number(radius)}
                                      center={{ lat: parseFloat(latitude), lng: parseFloat(longitude) }}
                                      marker={{ lat: parseFloat(latitude), lng: parseFloat(longitude) }}
                                      terminalDetails={null}
                                      // setMapSettings={null}
                                      value={null}
                                      isEditing={false}
                                      mapSettings={true}
                                    />
                                  </div>
                                  <div style={{ display: 'flex', justifyContent: 'flex-end', width: "100%", position: 'absolute', bottom: 0 }} >
                                    <Button mrt title="Close" ml onClick={() => mapClose()} />
                                  </div>
                                </div>
                              </td>
                            </tr>
                            : null
                          }
                        </tbody>
                      </BSTable>
                    </Collapse>
                    <BSTable bordered className={classes.terminalTable}>
                      <tbody>
                        <tr>
                          <td className={classes.rowKey}></td>
                          <td className={classes.rowValue}>
                            <Button title="Add" type="submit" mr />
                          </td>
                        </tr>
                      </tbody>
                    </BSTable>
                  </form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Card>
      </DashboardLayout>
    </Spin >
  );
};

export default AddTerminal