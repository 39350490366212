import React, { useState, useEffect, useRef, useCallback } from "react";
import clsx from "clsx";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// Navigation
import { useHistory, useLocation } from "react-router-dom";
// Styles
import "antd/dist/antd.css";
import { useStyles } from "./styles";
// fontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUsers,
  faTrophy,
  faLocationArrow,
  faSignOutAlt,
  faFileAlt,
  faImages,
  faBroadcastTower,
  faNewspaper,
  faUsersCog,
  faAtlas,
  faFlag,
  faAddressCard,
  faUserFriends,
  faParking
} from "@fortawesome/free-solid-svg-icons";
import { faCompass } from "@fortawesome/free-regular-svg-icons";
// Mui Components
import withWidth, { isWidthDown, isWidthUp } from "@material-ui/core/withWidth";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Drawer,
  CssBaseline,
  List,
  Typography,
  Divider,
  ListItem,
  Collapse,
} from "@material-ui/core";
import ArrowDropUpRoundedIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDown";
import {tabs} from "./allTabs"
// Custom components

// Constants
import { AppImages } from "../themes/appImages";
import { Colors } from "../themes/colors";
import localStorage from "../utils/localStorage";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  handleNavigationStateAction,
  handleSidebarDrawerToggleStateAction,
} from "../store/actions";


const DashboardLayout = (props) => {
  // Hooks declarations
  const matches = useMediaQuery("(min-hight:400px)");
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const tabItemRef = useRef(null);
  const dispatch = useDispatch();
  const commonState = useSelector((state) => state.commonReducer);
  const userName = localStorage.getUserName()
  // Global state initialization
  const {
    sidebarRouteIndex,
    sidebarNestedRouteIndex,
    sidebarDrawerToggleState,
  } = commonState;

  // local state initialization
  const [toggleMenu, setToggleMenu] = useState(false);

  //Images destructuring
  const { logo } = AppImages;

  // sidebar tabs for dashboard

  const nestedTabs = [
    {
      icon: faFlag,
      name: "State Listing",
      path: "/state-listing",
      key: "state"
    },
    {
      icon: faLocationArrow,
      name: "Region Listing",
      path: "/terminal-area-list",
      key: "region"
    },
    {
      icon: faCompass,
      name: "Terminal Listing",
      path: "/terminals",
      key: "terminal"
    },
  ];

  
  // Lifecycle Hooks
  useEffect(() => {
    if (isWidthUp("md", props.width)) {
      dispatch(handleSidebarDrawerToggleStateAction(true));
    } else if (isWidthDown("md", props.width)) {
      dispatch(handleSidebarDrawerToggleStateAction(false));
    }
  }, [props.width]);

  useEffect(() => {
    const routeIndex = localStorage.getSidebarItemIndex();
    const nestedRouteIndex = localStorage.getSidebarNestedItemIndex();
    
    if (nestedRouteIndex) {
      dispatch(handleNavigationStateAction(4, false));
      dispatch(handleNavigationStateAction(nestedRouteIndex, true));
    } else {
      dispatch(handleNavigationStateAction(routeIndex || 0, false));
    }
  }, []);

  const handleTabClick = (event, name, index, path, isTerminalTab) => {
    if (name == "Logout") {
      localStorage.removeToken();
      localStorage.removeUserId();
      localStorage.removePermissions(); 
      localStorage.removeUserName();
      localStorage.removeLayoutArr();
      localStorage.removeNestedLayoutArr()
      window.location.reload();
    }
    if (!isTerminalTab) {
      dispatch(handleNavigationStateAction(index, false));
      setToggleMenu(false);
      history.push(path);
    } else {
      // dispatch(handleNavigationStateAction(null, true))
      handleToggleMenuTab();
    }
  };

  //Callback methods
  const handleToggleMenuTab = () => {
    setToggleMenu(!toggleMenu);
  };

  let permissionsData = localStorage?.getPermissions()
  let updatedState
  if (permissionsData) {
    updatedState = [...permissionsData]
  }

  // sidebar selection
  let k = []
  let terminalTabs = []
  let terminalMainArray = []
  let t = tabs?.filter((e) => {
    updatedState?.map((x) => {
      let r
      if (x.name == 'state' || x.name == 'region' || x.name == 'terminal') {
        r = (e.terminalArray?.includes(x.name))
        if (r) {
          if (x.add == true || x.block == true || x.delete == true || x.edit == true || x.view == true) {
            nestedTabs.filter((nt) => {
              let value = nt?.key == x.name
              if (value) {
                if (x.add == true || x.block == true || x.delete == true || x.edit == true || x.view == true) {
                  if (terminalTabs.length > 0) {
                    terminalTabs.filter((filteValue) => {
                      let r = nt.key == filteValue.key
                      if (!r) {
                        var valueArr = terminalTabs.map(function (item) { return item.key });
                        if (!valueArr.includes(nt.key)) {
                          nt.isNested = true
                          terminalTabs.push(nt)
                        }
                      }
                    })
                  }
                  else {
                    nt.isNested = true
                    terminalTabs.push(nt)
                  }
                }
              }
            })
          }
        }
      }
      else {
        console.log(e?.key, "e key", x.name, "namename", e?.key?.match(x.name))
        r = e?.key == x.name
      }
      if (r) {
        if (x.add == true || x.block == true || x.delete == true || x.edit == true || x.view == true) {
          if (x.name == 'state' || x.name == 'region' || x.name == 'terminal') {
            if (terminalMainArray.length == 0) {
              terminalMainArray.push(e)
              k.push(e)
            }
          }
          else {
            k.push(e)
          }
        }
      }
    })
    var valueArr1 = k?.map(function (item) { return item.key });
    if (!valueArr1.includes(e.key)) {
      if (e.key == "logout" || e.key == "dashboard") {
        k.push(e)
        localStorage.setLayoutArr(k)
        localStorage.setNestedLayoutArr(terminalTabs)
      }
    }
  })

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        className={classes.drawer}
        variant={!isWidthUp("md", props.width) ? "temporary" : "persistent"}
        // anchor="left"
        open={sidebarDrawerToggleState}
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor={!isWidthUp("md", props.width) ? "right" : "left"}
        onClose={() =>
          sidebarDrawerToggleState
            ? dispatch(handleSidebarDrawerToggleStateAction(false))
            : dispatch(handleSidebarDrawerToggleStateAction(true))
        }
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <div className={classes.drawerLogo} style={{wordBreak:"break-all"}}>
          <img
            src={logo}
            className={classes.logo}
            onClick={() => {
              dispatch(handleNavigationStateAction(0, false));
              history.push("/dashboard");
            }}
          />
          <Typography variant="h6" className={classes.drawerTitle} id="title">
            {userName ? userName : "ADMIN"}
          </Typography>
        </div>
        <Divider variant="middle" />
        <List classes={{ padding: classes.listPadding }}>
          <div className={classes.drawerList}>
            {
              permissionsData ?
                k?.map(({ icon, name, path, key, no }, index) => {
                  let isTerminalTab = name == "Terminal Management" ? true : false;
                  let isSelectedtab = sidebarRouteIndex == index ? true : false;
                  let iconTheme = {
                    color: isSelectedtab
                      ? !isTerminalTab
                        ? Colors.white
                        : Colors.white
                      : Colors.title,
                    fontSize: 18,
                    position: "absolute",
                    right: 5,
                    top: 25,
                  };

                  return (
                    <React.Fragment key={name}>
                      <ListItem
                        onClick={(event) =>
                          handleTabClick(event, name, index, path, isTerminalTab)
                        }
                      >
                        {
                          permissionsData ?

                            <div
                              className={
                                isSelectedtab
                                  ? classes.drawerBtnSelected
                                  : classes.drawerBtn
                              }
                            >
                              <div className={classes.drawerIconContainer}>
                                <FontAwesomeIcon
                                  icon={icon}
                                  color={isSelectedtab ? Colors.white : Colors.greyIcon}
                                  className={clsx([
                                    classes.tabIcon,
                                    // isSelectedtab && classes.seletcedTabIcon,
                                  ])}
                                />
                              </div>
                              <p
                                className={
                                  isSelectedtab
                                    ? classes.drawerBtnTextSelected
                                    : classes.drawerBtnText
                                }
                              >
                                {name}
                              </p>
                              {isTerminalTab ? (
                                toggleMenu ? (
                                  <ArrowDropUpRoundedIcon style={iconTheme} />
                                ) : (
                                  <ArrowDropDownRoundedIcon style={iconTheme} />
                                )
                              ) : (
                                true
                              )}
                            </div>

                            :
                            <div
                              className={
                                isSelectedtab
                                  ? classes.drawerBtnSelected
                                  : classes.drawerBtn
                              }
                            >
                              <div className={classes.drawerIconContainer}>
                                <FontAwesomeIcon
                                  icon={icon}
                                  color={isSelectedtab ? Colors.white : Colors.greyIcon}
                                  className={clsx([
                                    classes.tabIcon,
                                    // isSelectedtab && classes.seletcedTabIcon,
                                  ])}
                                />
                              </div>
                              <p
                                className={
                                  isSelectedtab
                                    ? classes.drawerBtnTextSelected
                                    : classes.drawerBtnText
                                }
                              >

                                {name

                                }
                              </p>
                              {isTerminalTab ? (
                                toggleMenu ? (
                                  <ArrowDropUpRoundedIcon style={iconTheme} />
                                ) : (
                                  <ArrowDropDownRoundedIcon style={iconTheme} />
                                )
                              ) : (
                                true
                              )}
                            </div>
                        }
                      </ListItem>
                      {isTerminalTab ? (
                        <Collapse
                          in={toggleMenu}
                          timeout="auto"
                          unmountOnExit
                          classes={{ wrapper: classes.nestedMenuWrapper }}
                        >
                          <List component="div" disablePadding>
                            <div>
                              {terminalTabs.map(({ icon, name, path, key }, id) => {
                                let isNestedSelectedTab =
                                  sidebarNestedRouteIndex == id ? true : false;

                                return (
                                  <React.Fragment key={name}>
                                    <ListItem>
                                      <div
                                        className={
                                          isNestedSelectedTab
                                            ? classes.drawerNestedBtnSelected
                                            : classes.drawerNestedBtn
                                        }
                                        onClick={() => {
                                          dispatch(
                                            handleNavigationStateAction(
                                              index,
                                              false
                                            )
                                          );
                                          dispatch(
                                            handleNavigationStateAction(id, true)
                                          );
                                          history.push(path);
                                        }}
                                      >
                                        {
                                          // updatedState ?
                                          //   (updatedState[index]?.isNested == true) ?
                                          <div
                                            className={classes.drawerIconContainer}
                                          >
                                            <FontAwesomeIcon
                                              icon={icon}
                                              color={
                                                isNestedSelectedTab
                                                  ? Colors.white
                                                  : Colors.greyIcon
                                              }
                                              className={clsx([
                                                classes.icon,
                                                isNestedSelectedTab &&
                                                classes.subSeletcedTabIcon,
                                              ])}
                                            />
                                          </div>
                                          //   :
                                          //   null
                                          // :
                                          // <div
                                          //   className={classes.drawerIconContainer}
                                          // >
                                          //   <FontAwesomeIcon
                                          //     icon={icon}
                                          //     color={
                                          //       isNestedSelectedTab
                                          //         ? Colors.white
                                          //         : Colors.greyIcon
                                          //     }
                                          //     className={clsx([
                                          //       classes.icon,
                                          //       isNestedSelectedTab &&
                                          //       classes.subSeletcedTabIcon,
                                          //     ])}
                                          //   />
                                          // </div>
                                        }
                                        <p
                                          className={
                                            isNestedSelectedTab
                                              ? classes.drawerBtnTextSelected
                                              : classes.drawerBtnText
                                          }
                                        >
                                          {

                                            name

                                          }
                                        </p>
                                      </div>

                                    </ListItem>
                                  </React.Fragment>
                                );
                              })}
                            </div>
                          </List>
                        </Collapse>
                      ) : (
                        true
                      )}
                    </React.Fragment>
                  );
                })
                :
                tabs?.map(({ icon, name, path, key, no }, index) => {
                  let isTerminalTab = name == "Terminal Management" ? true : false;
                  let isSelectedtab = sidebarRouteIndex == index ? true : false;
                  let iconTheme = {
                    color: isSelectedtab
                      ? !isTerminalTab
                        ? Colors.white
                        : Colors.white
                      : Colors.title,
                    fontSize: 18,
                    position: "absolute",
                    right: 5,
                    top: 25,
                  };
                  return (
                    <React.Fragment key={name}>
                      <ListItem
                        onClick={(event) =>
                          handleTabClick(event, name, index, path, isTerminalTab)
                        }
                      >
                        {
                          <div
                            className={
                              isSelectedtab
                                ? classes.drawerBtnSelected
                                : classes.drawerBtn
                            }
                          >
                            <div className={classes.drawerIconContainer}>
                              <FontAwesomeIcon
                                icon={icon}
                                color={isSelectedtab ? Colors.white : Colors.greyIcon}
                                className={clsx([
                                  classes.tabIcon,
                                  // isSelectedtab && classes.seletcedTabIcon,
                                ])}
                              />
                            </div>
                            <p
                              className={
                                isSelectedtab
                                  ? classes.drawerBtnTextSelected
                                  : classes.drawerBtnText
                              }
                            >
                              {name}
                            </p>
                            {isTerminalTab ? (
                              toggleMenu ? (
                                <ArrowDropUpRoundedIcon style={iconTheme} />
                              ) : (
                                <ArrowDropDownRoundedIcon style={iconTheme} />
                              )
                            ) : (
                              true
                            )}
                          </div>
                        }
                      </ListItem>
                      {isTerminalTab ? (
                        <Collapse
                          in={toggleMenu}
                          timeout="auto"
                          unmountOnExit
                          classes={{ wrapper: classes.nestedMenuWrapper }}
                        >
                          <List component="div" disablePadding>
                            <div>
                              {nestedTabs.map(({ icon, name, path }, id) => {
                                let isNestedSelectedTab =
                                  sidebarNestedRouteIndex == id ? true : false;
                                return (
                                  <React.Fragment key={name}>
                                    <ListItem>
                                      <div
                                        className={
                                          isNestedSelectedTab
                                            ? classes.drawerNestedBtnSelected
                                            : classes.drawerNestedBtn
                                        }
                                        onClick={() => {
                                          dispatch(
                                            handleNavigationStateAction(
                                              index,
                                              false
                                            )
                                          );
                                          dispatch(
                                            handleNavigationStateAction(id, true)
                                          );
                                          history.push(path);
                                        }}
                                      >
                                        {
                                          // updatedState ?
                                          //   (updatedState[index]?.isNested == true) ?
                                          <div
                                            className={classes.drawerIconContainer}
                                          >
                                            <FontAwesomeIcon
                                              icon={icon}
                                              color={
                                                isNestedSelectedTab
                                                  ? Colors.white
                                                  : Colors.greyIcon
                                              }
                                              className={clsx([
                                                classes.icon,
                                                isNestedSelectedTab &&
                                                classes.subSeletcedTabIcon,
                                              ])}
                                            />
                                          </div>
                                          //   :
                                          //   null
                                          // :
                                          // <div
                                          //   className={classes.drawerIconContainer}
                                          // >
                                          //   <FontAwesomeIcon
                                          //     icon={icon}
                                          //     color={
                                          //       isNestedSelectedTab
                                          //         ? Colors.white
                                          //         : Colors.greyIcon
                                          //     }
                                          //     className={clsx([
                                          //       classes.icon,
                                          //       isNestedSelectedTab &&
                                          //       classes.subSeletcedTabIcon,
                                          //     ])}
                                          //   />
                                          // </div>
                                        }
                                        <p
                                          className={
                                            isNestedSelectedTab
                                              ? classes.drawerBtnTextSelected
                                              : classes.drawerBtnText
                                          }
                                        >
                                          {updatedState ?
                                            // updatedState[index]?.add == true ||
                                            //   updatedState[index]?.view == true ||
                                            //   updatedState[index]?.edit == true ||
                                            //   updatedState[index]?.delete == true ||
                                            //   updatedState[index]?.block == true || updatedState[index]?.isNested == true ?
                                            (updatedState[index]?.isNested == true) ?
                                              name
                                              :
                                              null
                                            :
                                            name
                                          }
                                        </p>
                                      </div>

                                    </ListItem>
                                  </React.Fragment>
                                );
                              })}
                            </div>
                          </List>
                        </Collapse>
                      ) : (
                        true
                      )}
                    </React.Fragment>
                  );
                })
            }
          </div>
        </List>
        <div className={classes.drawerBackground} style={{ zIndex: -7 }} />
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarDrawerToggleState,
        })}
      >
        {props.children}
      </main>
    </div>
  );
};

export default withWidth()(DashboardLayout);
