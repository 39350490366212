import { takeLatest, call, put } from "redux-saga/effects";
import { message } from "antd";
import {
  apiConstants as types,
  ValidationConstants,
  appMessages,
} from "../../themes/constants";
import axios from "../axios";
import cl from "../../utils/cl";
import history from "../../utils/history";
import { toast } from "react-toastify";
import {
  addWaitTimeSuccess,
  addWaitTimeFailure,
  getWaitTimeSuccess,
  getWaitTimeFailure
} from "../actions/terminalManagementActions"

function* terminalManagementFailedSaga(result) {
  cl("result inside the terminal management Failed", result);
  yield put({
    type: types.API_TERMINAL_MANAGEMENT_FAILED,
    errorState: result?.error,
  });
  toast.error(result?.error);
}

function* terminalManagementErrorSaga(result) {
  cl("result inside the terminal management Error", result);
  yield put({
    type: types.API_TERMINAL_MANAGEMENT_ERROR,
    errorState: result?.error,
  });
  if (result.status === 2) {
    toast.error(appMessages.messageStatus500);
  } else {
    toast.error(result?.error);
  }
}

function* getTermianlListSaga(action) {
  const { search, offset, limit, sortBy, order } = action;
  try {
    const result = yield call(
      axios.getTerminalList,
      search,
      offset,
      limit,
      sortBy,
      order
    );
    if (result.status === 1) {
      cl("result inside get terminal list saga", result);
      yield put({
        type: types.API_GET_TERMINAL_LIST_SUCCESS,
        result: result.result.data.data,
      });
    } else {
      yield call(terminalManagementFailedSaga, result);
    }
  } catch (error) {
    yield call(terminalManagementErrorSaga, error);
  }
}

function* addTerminalSaga(action) {
  const {
    terminalImage,
    terminalUrlArray,
    terminalArea,
    terminalName,
    locationCategory,
    terminalCountry,
    terminalAddress,
    terminalLatitude,
    terminalLongitude,
    terminalCity,
    terminalState,
    zipCode,
    terminalRadius,
    terminalOpenTime,
    terminalCloseTime,
    fullTime,
    mapLogoFile,
    terminalBoardLogoFile,
    discription,
    showPolygonData,
    geoFence,
    zoom,
    templates,
    phoneNumbers,
    extraTimeArray,
    about,
    showTerminal
  } = action;
  try {
    const result = yield call(
      axios.addTerminal,
      terminalImage,
      terminalUrlArray,
      terminalArea,
      terminalName,
      locationCategory,
      terminalCountry,
      terminalAddress,
      terminalLatitude,
      terminalLongitude,
      terminalCity,
      terminalState,
      zipCode,
      terminalRadius,
      terminalOpenTime,
      terminalCloseTime,
      fullTime,
      mapLogoFile,
      terminalBoardLogoFile,
      discription,
      showPolygonData,
      geoFence,
      zoom,
      templates,
      phoneNumbers,
      extraTimeArray,
      about,
      showTerminal
    );
    if (result.status === 1) {
      cl("result inside add terminal saga", result);
      yield put({
        type: types.API_ADD_TERMINAL_SUCCESS,
        result: result.result.data.data,
      });
      toast.success(result?.result?.data?.message);
      history.push("/terminals");
    } else {
      yield call(terminalManagementFailedSaga, result);
    }
  } catch (error) {
    yield call(terminalManagementErrorSaga, error);
  }
}

function* getTerminalDetailsSaga(action) {
  const { terminalId } = action;
  try {
    const result = yield call(axios.getTerminalDetails, terminalId);
    if (result.status === 1) {
      cl("result inside get terminal Details saga", result);
      yield put({
        type: types.API_GET_TERMINAL_DETAILS_SUCCESS,
        result: result.result.data.data,
      });
    } else {
      yield call(terminalManagementFailedSaga, result);
    }
  } catch (error) {
    yield call(terminalManagementErrorSaga, error);
  }
}

function* editTerminalSaga(action) {
  const {
    terminalId,
    terminalImage,
    terminalUrlArray,
    terminalArea,
    terminalName,
    locationCategory,
    terminalCountry,
    terminalAddress,
    terminalLatitude,
    terminalLongitude,
    terminalCity,
    terminalState,
    zipCode,
    terminalRadius,
    terminalOpenTime,
    terminalCloseTime,
    fullTime,
    mapLogoFile,
    terminalPolygon,
    terminalBoardLogoFile,
    discription,
    geoFence,
    zoom,
    templates,
    phnNumberArray,
    extraTimeArray,
    about,
    showTerminal
  } = action;
  try {
    const result = yield call(
      axios.editTerminal,
      terminalId,
      terminalImage,
      terminalUrlArray,
      terminalArea,
      terminalName,
      locationCategory,
      terminalCountry,
      terminalAddress,
      terminalLatitude,
      terminalLongitude,
      terminalCity,
      terminalState,
      zipCode,
      terminalRadius,
      terminalOpenTime,
      terminalCloseTime,
      fullTime,
      mapLogoFile,
      terminalPolygon,
      terminalBoardLogoFile,
      discription,
      geoFence,
      zoom,
      templates,
      phnNumberArray,
      extraTimeArray,
      about,
      showTerminal
    );
    if (result.status === 1) {
      cl("result inside edit terminal  saga", result);
      yield put({
        type: types.API_EDIT_TERMINAL_SUCCESS,
        result: result.result.data.data,
      });
      toast.success(result?.result?.data?.message);
      history.push("/terminals");
    } else {
      yield call(terminalManagementFailedSaga, result);
    }
  } catch (error) {
    yield call(terminalManagementErrorSaga, error);
  }
}

function* deleteTerminalSaga(action) {
  const { terminalId } = action;
  try {
    const result = yield call(axios.deleteTerminal, terminalId);
    if (result.status === 1) {
      cl("result inside delete terminal saga", result);
      yield put({
        type: types.API_DELETE_TERMINAL_SUCCESS,
        terminalId: terminalId,
        result: result.result.data.data,
      });
      toast.success(result?.result?.data?.message);
    } else {
      yield call(terminalManagementFailedSaga, result);
    }
  } catch (error) {
    yield call(terminalManagementErrorSaga, error);
  }
}

function* importTerminalSaga(action) {
  const { file, fileType } = action;
  console.log(file,fileType,"fileTypefileType")
  try {
    const result = yield call(axios.importFile, file, fileType);
    if (result.status === 1) {
      cl("result inside Import Terminal saga", result);
      yield put({
        type: types.API_IMPORT_FILE_SUCCESS,
        result: result.result.data.data,
      });
      if (action.fileType == "terminalArea") {
        toast.success("Region list imported successfully.");
        history.push("/terminal-area-list");
      } else {
        toast.success("Terminal list imported successfully.");
        history.push("/terminals");
      }
    } else {
      yield call(terminalManagementFailedSaga, result);
    }
  } catch (error) {
    yield call(terminalManagementErrorSaga, error);
  }
}

function* exportTerminalSaga(action) {
  const { fileType } = action;
  try {
    const result = yield call(axios.exportFile, fileType);
    if (result.status === 1) {
      cl("result inside export Terminal saga", result);
      yield put({
        type: types.API_EXPORT_FILE_SUCCESS,
        result: result.result.data.data,
      });
      if (action.fileType == "terminalArea") {
        toast.success("Region list exported successfully.");
      } else {
        toast.success("Terminal list exported successfully.");
      }
    } else {
      yield call(terminalManagementFailedSaga, result);
    }
  } catch (error) {
    yield call(terminalManagementErrorSaga, error);
  }
}

function* getResultListSaga(action) {
  // const { search, offset, limit, sortBy, order, terminalId } = action;
  try {
    const result = yield call(
      axios.getResultList,
      action.payload
    );
    if (result.status === 1) {
      yield put({
        type: types.API_GET_RATING_LIST_SUCCESS,
        result: result.result.data.data,
      });
    } else {
      yield call(terminalManagementFailedSaga, result);
    }
  } catch (error) {
    yield call(terminalManagementErrorSaga, error);
  }
}

function* getHideDeleteRatingStatus(action) {
  try {
    const result = yield call(axios.getRatingStatus, action.payload);
    if (result.status === 1) {
      const resultSuccess = yield call(axios.getResultList, action.payload) 
      if (resultSuccess.status === 1) {
        yield put({
          type: types.API_GET_RATING_LIST_SUCCESS,
          result: resultSuccess.result.data.data,
        })
      }
      // yield put({
      //   type: types.API_HIDE_DELETE_RATINGS_SUCCESS,
      //   result: result.result.data.data,
      //   status: action.payload.status,
      //   index: action.payload.index
      // });
      toast.success(result?.result?.data?.message);
    } else {
      yield call(terminalManagementFailedSaga, result);
    }
  } catch (error) {
    yield call(terminalManagementErrorSaga, error);
  }
}

function* getTerminalWaitTime(action) {
  const { payload } = action;
  try {
    const result = yield call(axios.getTerminalWaitTime, payload);
    if (result.status === 1) {
      yield put(getWaitTimeSuccess(result.result.data.data));
      // toast.success(result?.result?.data?.message);
    } else {
      yield call(getWaitTimeFailure, result);
    }
  } catch (error) {
    yield call(getWaitTimeFailure, error);
  }
}

function* addTerminalWaitTime(action) {
  const { payload } = action;
  try {
    const result = yield call(axios.updateTerminalWaitTime, payload);
    if (result.status === 1) {
      yield put(addWaitTimeSuccess(result.data));
      history.push("/terminals");
      toast.success(result?.result?.data?.message);
    } else {
      yield call(addWaitTimeFailure, result);
    }
  } catch (error) {
    yield call(addWaitTimeFailure, error);
  }
}


export default function* rootTerminalMAnagementSaga() {
  yield takeLatest(types.API_GET_TERMINAL_LIST_LOAD, getTermianlListSaga);
  yield takeLatest(types.API_ADD_TERMINAL_LOAD, addTerminalSaga);
  yield takeLatest(types.API_GET_TERMINAL_DETAILS_LOAD, getTerminalDetailsSaga);
  yield takeLatest(types.API_EDIT_TERMINAL_LOAD, editTerminalSaga);
  yield takeLatest(types.API_DELETE_TERMINAL_LOAD, deleteTerminalSaga);
  yield takeLatest(types.API_IMPORT_FILE_LOAD, importTerminalSaga);
  yield takeLatest(types.API_EXPORT_FILE_LOAD, exportTerminalSaga);
  yield takeLatest(types.API_GET_RATING_LIST_LOAD, getResultListSaga);
  yield takeLatest(types.API_HIDE_DELETE_RATINGS_LOAD, getHideDeleteRatingStatus);
  yield takeLatest(types.UPDATE_WAIT_TIME_INITIATE, addTerminalWaitTime);
  yield takeLatest(types.GET_WAIT_TIME_INITIATE, getTerminalWaitTime);
}
