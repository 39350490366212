export const AppImages = {
  truckBg: require("../assets/images/truckBg.png").default,
  truckBgWhite: require("../assets/images/truckBgWhite.png").default,
  logo: require("../assets/images/logo.png").default,
  dummyProfilePic: require("../assets/images/dummy_terminal.png").default,
  pin: require("../assets/images/pin.png").default,
  usersSvg: require("../assets/icons/images.svg").default,
  terminalSvg: require("../assets/icons/delivery.svg").default,
  rightArrowSvg: require("../assets/icons/rightArrow.svg").default,
  time: require("../assets/icons/time.svg").default,
  // addPhoto: require("../assets/icons/photo.svg").default,
  addPhoto: require("../assets/images/Camera.svg").default,
  addVideo: require("../assets/images/Video.svg").default,
  brocken: require("../assets/icons/brocken.svg").default,
  customBrocken: require("../assets/icons/customBrockenPng.png").default,
  notFound: require("../assets/icons/404.svg").default,

  //new image assets for preview website
  logoWebsite: require("../assets/images/logoWebsite.png").default,
  sun: require("../assets/images/sun.png").default,
  moon: require("../assets/images/moon.png").default,
  image1: require("../assets/images/image1.png").default,
  image2: require("../assets/images/image2.png").default,
  image3: require("../assets/images/image3.png").default,
  logo1: require("../assets/images/logo11.png").default,
  logo2: require("../assets/images/logo22.png").default,
  logo3: require("../assets/images/logo3.png").default,
  logo4: require("../assets/images/logo4.png").default,
  logo5: require("../assets/images/logo5.png").default,
  logo6: require("../assets/images/logo6.png").default,
  template1: require("../assets/images/template1.png").default,
  template2: require("../assets/images/template2.png").default,
  crossButton: require("../assets/images/redcross.png").default,
  redButton: require("../assets/images/map-pin-red.png").default,
  greenButton: require("../assets/images/map-pin-green.png").default,
  activeUser: require("../assets/images/active user.png").default,
  comment: require("../assets/images/comments.png").default,
  like: require("../assets/images/like (2).png").default,
  posts: require("../assets/images/post.png").default,
  timeline: require("../assets/images/timeline.png").default,
  user: require("../assets/images/user (6).png").default,
  dropDown: require("../assets/images/download (1).png").default,
  rollingEyes: require("../assets/images/face_with_rolling_eyes.png").default,
  angry: require("../assets/images/face_with_steam_from_nose.png").default,
  grinning_face: require("../assets/images/grinning_face_with_smiling_eyes_old.png").default,
  smile: require("../assets/images/grinning_face_with_smiling_eyes.png").default,
  thinking_face: require("../assets/images/thinking_face.png").default,
  thumbs_down: require("../assets/images/thumbs_down.png").default,
  thumbs_up: require("../assets/images/thumbs_up.png").default,
  NotLike: require("../assets/images/NotLike.png").default,
  crossIcon: require("../assets/images/downloadsadsas.png").default,
  close: require("../assets/images/close.png").default,
  clock: require("../assets/images/clock.png").default,
};
