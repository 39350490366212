import React from "react";
import clsx from "clsx";
// antd
import { Button as AntButton } from "antd";
// Styles
import { useStyles } from "./styles";

const Button = (props) => {
  // Hooks declarations
  const classes = useStyles();
  // Props destructuring
  const { onClick,key, type, title, mr,id, mb, grey, red, disabled, size, width, mrt, apply, marginRight } = props;

  return (
    <AntButton
    key={key}
    id={id}
      disabled={disabled}
      htmlType={type}
      type="primary"
      size="large"
      style={{ height: size ? size : 38, width: width ? width : "auto", marginRight: marginRight }}
      className={clsx(classes.button, [
        mr && classes.mr,
        mb && classes.mb,
        grey && classes.grey,
        red && classes.red,
        mrt && classes.mrt,
        apply && classes.apply,
      ])}
      onClick={onClick}
    >
      {title}
    </AntButton>
  );
};

export default Button;
