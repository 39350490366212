import { apiConstants as types } from '../../themes/constants'
import { updatePagination } from '../../utils/helpers'
import localStorage from '../../utils/localStorage'
const initialState = {
    isLoading: false,
    isTableDataLoading: false,
    result: {},
    deletedTerminal: null,
    employeeListing: [],
    employeePaging: {},
    employeeDetails: {},
    errorState: null,
    currentPage: 1,
    roles: [],
    errorState: null,
}

export const EmployeeManagementReducer = (state = initialState, action) => {
    // console.log(action, "action")
    switch (action.type) {

        // Get employee list
        case types.API_GET_EMPLOYEE_LIST_LOAD:
            return { ...state, isLoading: true, errorState: null, }
        case types.API_GET_EMPLOYEE_LIST_SUCCESS:
            return { ...state, isLoading: false, employeeListing: action.result.list, employeePaging: action.result.pagination, currentPage: action.result?.paging?.page?.currentPage }

        // Get roles list
        case types.API_GET_ROLES_LIST_LOAD:
            return { ...state, isLoading: true, errorState: null, }
        case types.API_GET_ROLES_LIST_SUCCESS:
            return { ...state, isLoading: false, roles: action.result }

        //  Add employee
        case types.API_ADD_EMPLOYEE_LIST_LOAD:
            return { ...state, isLoading: true, errorState: null, }
        case types.API_ADD_EMPLOYEE_LIST_SUCCESS:
            return { ...state, isLoading: false }

        // employee details
        case types.API_GET_EMPLOYEE_LIST_DETAILS_LOAD:
            return { ...state, isTableDataLoading: true, errorState: null, }
        case types.API_EMPLOYEE_LIST_DETAILS_SUCCESS:
            return { ...state, isTableDataLoading: false, employeeDetails: action.result }

        // generate password
        case types.API_GENERATE_PASSWORD_LOAD:
            return { ...state, isTableDataLoading: true, errorState: null, }
        case types.API_GENERATE_PASSWORD_SUCCESS:
            return { ...state, isTableDataLoading: false, }

        // Edit terminal area
        case types.API_EDIT_EMPLOYEE_LIST_LOAD:
            return { ...state, isLoading: true, errorState: null, }
        case types.API_EDIT_EMPLOYEE_LIST_SUCCESS:
            return { ...state, isLoading: false }

        //  block employee
        case types.API_BLOCK_EMPLOYEE_LIST_LOAD:
            return { ...state, isLoading: true }
        case types.API_BLOCK_EMPLOYEE_LIST_SUCCESS:
            let employeesList = state.employeeListing;
            let userBlockedIndex = employeesList.findIndex(user => user._id === action.result.user_id)
            employeesList[userBlockedIndex].blocked = employeesList[userBlockedIndex].blocked == true ? false : true;

            state.employeeListing.list = employeesList
            return { ...state, isLoading: false, employeeListing: state.employeeListing }

        // Get event management list
        case types.API_GET_EVENT_LIST_LOAD:
            return { ...state, isLoading: true, errorState: null, }
        case types.API_GET_EVENT_LIST_SUCCESS:
            return { ...state, isLoading: false, eventListing: action.result.list, eventPaging: action.result.pagination, currentPage: action.result?.paging?.page?.currentPage }


        //employee management failed cases
        case types.API_EMPLOYEE_LIST_MANAGEMENT_FAILED:

            return {
                ...state, isLoading: false, isTableDataLoading: false,
                errorState: action.errorState,
            }
        case types.API_EMPLOYEE_LIST_MANAGEMENT_ERROR:

            return {
                ...state, isLoading: false, isTableDataLoading: false,
                errorState: action.errorState,
            }

        //Default case
        default:
            return { ...state }

    }
}