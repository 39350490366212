
import React from 'react';
import clsx from 'clsx';
import {AppImages} from '../themes/appImages'
import {appConstants,appMessages} from '../themes/constants'
// Styles
import {useStyles} from './styles'
// Mui Components
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from './button'
import history from '../utils/history'


const BrockenLink =  (props) => {
    const {status,reason,reasonDetails,goto,buttonTitle,onClick,isError} = props
    // Hooks declarations
    const classes = useStyles();

  return (
        <Grid container justify="center" alignItems="center" className={isError ?"ErrorFailView":"wrongPageView"}>
            <Grid item className={classes.brockenContent} >
              <div className="button-four">
                <Button title={buttonTitle} onClick={()=>  { onClick?onClick(): history.push(goto)}} size={32} />
              </div>
            </Grid>
        </Grid>
  )
}

export default BrockenLink


