import React, { useState, useEffect, useRef } from 'react';
// Navigation
import { useHistory, useLocation } from 'react-router-dom'
// Styles
import 'antd/dist/antd.css'
import '../dashboard.scss'
// antd
import { Spin } from 'antd';
import { Card, Table as BSTable, } from 'react-bootstrap';
// Mui Components
import { Grid, useTheme, } from '@material-ui/core';
// Custom components
import DashboardLayout from '../../layouts/dashboardLayout'
import { AppBar, InputField, Navbar, Button } from '../../customComponents'
// Constants
import { AppImages } from '../../themes/appImages'
import { Colors } from '../../themes/colors'
import { appConstants } from '../../themes/constants'
// utils methods
import cl from '../../utils/cl'
import { useStyles } from '../../styles'
// Redux
import { useSelector, useDispatch } from 'react-redux'
import { getUserDetailsAction } from '../../store/actions'

export const UserDetails = () => {
    // Hooks declarations
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const location = useLocation()
    const dispatch = useDispatch()
    const [image, setImage] = useState(null)
    const { userDetails, isTableDataLoading, isLoading } = useSelector(state => state.userManagementReducer)

    // Lifecycle Hooks
    useEffect(() => {
        document.title = appConstants.headerTitle.userDetails;
        if (location?.state?.userId) {
            dispatch(getUserDetailsAction(location?.state?.userId))
        } else {
            history.push('/users')
        }
    }, [])

    useEffect(() => {
        setImage(appConstants.baseURL + userDetails?.profile)
    }, [userDetails])

    //Callback methods

    return (
        <Spin
            size="large"
            spinning={isLoading}
            wrapperClassName={classes.tableSpinner}
            className={classes.antSpin}
        >
            <DashboardLayout>
                <AppBar breadcrumbs={[{ route: '/users', name: appConstants.userManagement },
                { route: "/view-user-details", name: appConstants.userDetails, state: { userId: location?.state?.userId } }]} />
                <Navbar title={appConstants.userDetails} />
                <Card className={classes.card}>
                    <Grid container>
                        <Grid className={classes.tableContainerRow}>
                            <BSTable striped bordered className={classes.bsTableHeight}>
                                {
                                    isTableDataLoading ? <thead className={classes.bsHeaderTable}>
                                        <Spin className={classes.tableDataSpinner} />
                                    </thead>
                                        :
                                        <tbody>
                                            {userDetails?.type == "bot" &&
                                                <tr>
                                                    <td className={classes.rowKey}>{appConstants.image}</td>
                                                    <td className={classes.rowValue}>
                                                        <div className={classes.inputWrapper}>
                                                            <label
                                                                htmlFor="icon-button-file"
                                                                className={classes.imageManagementUploaderlabel}
                                                            >

                                                                {image ?
                                                                    <img
                                                                        // src={appConstants.baseURL + news.media}
                                                                        src={image}
                                                                        className={classes.terminalImage}
                                                                        style={{ cursor: "default", borderRadius: "50px", border: "2px solid lightgrey" }}
                                                                    />
                                                                    :
                                                                    <img src={AppImages.addPhoto}></img>
                                                                }

                                                            </label>
                                                        </div>
                                                    </td>
                                                </tr>}
                                            <tr>
                                                <td className={classes.rowKey}>
                                                    Type
                                                </td>
                                                <td className={classes.rowValue}>
                                                    <div className={classes.inputWrapper}>
                                                        <InputField
                                                            placeholder={"Type"}
                                                            name="type"
                                                            // onChange={handleChange}
                                                            type="type"
                                                            value={userDetails?.type == "bot" ? "Bot" :userDetails?.type == "dummy" ?"Guest": "User"}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={classes.rowKey}>
                                                    {'Name'}
                                                </td>
                                                <td className={classes.rowValue}>
                                                    <div className={classes.inputWrapper}>
                                                        <InputField
                                                            placeholder={"Name"}
                                                            name="name"
                                                            // onChange={handleChange}
                                                            type="name"
                                                            value={userDetails?.name ? userDetails?.name : "N/A"}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                            
                                            
                                            <tr>
                                                <td className={classes.rowKey}>
                                                    {appConstants.firstName}
                                                </td>
                                                <td className={classes.rowValue}>
                                                    <div className={classes.inputWrapper}>
                                                        <InputField
                                                            placeholder={appConstants.firstName}
                                                            name="firstName"
                                                            // onChange={handleChange}
                                                            type="firstName"
                                                            value={userDetails?.firstName ? userDetails?.firstName : "N/A"}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                            
                                            <tr>
                                                <td className={classes.rowKey}>
                                                    {appConstants.lastName}
                                                </td>
                                                <td className={classes.rowValue}>
                                                    <div className={classes.inputWrapper}>
                                                        <InputField
                                                            placeholder={appConstants.lastName}
                                                            name="lastName"
                                                            // onChange={handleChange}
                                                            type="lastName"
                                                            value={userDetails?.lastName ? userDetails?.lastName : "N/A"}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={classes.rowKey}>
                                                    Username
                                                </td>
                                                <td className={classes.rowValue}>
                                                    <div className={classes.inputWrapper}>
                                                        <InputField
                                                            placeholder="Username"
                                                            name="userName"
                                                            // onChange={handleChange}
                                                            type="userName"
                                                            value={userDetails?.userName ? userDetails?.userName : "N/A"}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                            {userDetails?.type == "user" &&
                                                <> <tr>
                                                    <td className={classes.rowKey}>{appConstants.emailAddress}</td>
                                                    <td className={classes.rowValue}>
                                                        <div className={classes.inputWrapper}>
                                                            <InputField
                                                                placeholder={appConstants.emailAddress}
                                                                name="email"
                                                                // onChange={handleChange}
                                                                type="email"
                                                                value={userDetails?.email ? userDetails?.email : "N/A"}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                                    <tr>
                                                        <td className={classes.rowKey}>{appConstants.phoneNumber}</td>
                                                        <td className={classes.rowValue}>
                                                            <div className={classes.inputWrapper}>
                                                                <InputField
                                                                    placeholder={appConstants.phoneNumber}
                                                                    name="phoneNumber"
                                                                    // onChange={handleChange}
                                                                    // type="number"
                                                                    value={userDetails?.phone_number ? userDetails?.phone_number : "N/A"}
                                                                    disabled={true}
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className={classes.rowKey}>{appConstants.userType}</td>
                                                        <td className={classes.rowValue}>
                                                            <div className={classes.inputWrapper}>
                                                                <InputField
                                                                    placeholder={appConstants.userType}
                                                                    name="userType"
                                                                    // onChange={handleChange}
                                                                    // type="email"
                                                                    value={userDetails?.user_type ? userDetails?.user_type : "N/A"}
                                                                    disabled={true}
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>

                                                </>
                                            }
                                            <tr>
                                                <td className={classes.rowKey}>{appConstants.reportCount}</td>
                                                <td className={classes.rowValue}>
                                                    <div className={classes.inputWrapper}>
                                                        <InputField
                                                            placeholder={appConstants.reportCount}
                                                            name="reportCount"
                                                            // onChange={handleChange}
                                                            // type="number"
                                                            value={userDetails?.reportCount ? userDetails?.reportCount : "0"}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={classes.rowKey}>Device Type</td>
                                                <td className={classes.rowValue}>
                                                    <div className={classes.inputWrapper}>
                                                        <InputField
                                                            placeholder="Device Type"
                                                            name="Device Type"
                                                            // onChange={handleChange}
                                                            // type="number"
                                                            value={userDetails?.device_type ? userDetails?.device_type == "ios" ? "IOS" : "Android" : "N/A"}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={classes.rowKey}>App Version</td>
                                                <td className={classes.rowValue}>
                                                    <div className={classes.inputWrapper}>
                                                        <InputField
                                                            placeholder="App Version"
                                                            name="App Version"
                                                            // onChange={handleChange}
                                                            // type="number"
                                                            value={userDetails?.app_version ? userDetails?.app_version : "N/A"}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={classes.rowKey}></td>
                                                <td className={classes.rowValue}>
                                                    <Grid className={classes.buttons}>
                                                        {(userDetails?.type !== "bot" && userDetails?.deleted_at == null) &&
                                                            <> <Button title={appConstants.viewSavedLocations} onClick={() => history.push({
                                                                pathname: "/view-saved-locations",
                                                                state: {  // location state
                                                                    userId: userDetails._id,
                                                                },
                                                            })} />
                                                                <Button title="View Timeline" onClick={() => history.push({
                                                                    pathname: "/view-timeline",
                                                                    state: {  // location state
                                                                        userId: userDetails._id,
                                                                    },

                                                                })} />
                                                            </>}
                                                        {(userDetails?.reportCount > 0 && userDetails?.type !== "bot") &&
                                                            <Button title="Reported By" onClick={() => history.push({
                                                                pathname: "/reported-users",
                                                                state: {  // location state
                                                                    userId: userDetails._id,
                                                                },

                                                            })} />
                                                        }
                                                    </Grid>
                                                </td>
                                            </tr>
                                        </tbody>
                                }
                            </BSTable>
                        </Grid>
                    </Grid>
                </Card>
            </DashboardLayout>
        </Spin>
    );
}
