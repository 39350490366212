import { takeLatest, call, put } from 'redux-saga/effects'
import { message } from 'antd'
import { apiConstants as types, appMessages } from '../../themes/constants'
import axios from '../axios'
import cl from '../../utils/cl'
import history from '../../utils/history'
import { toast } from 'react-toastify';

function* postFailedSaga(result) {

    yield put({
        type: types.API_POST_LIST_MANAGEMENT_FAILED,
        errorState: result?.error,
    })
    toast.error(result?.error)
}

function* postErrorSaga(result) {
    console.log(result)
    yield put({
        type: types.API_POST_LIST_MANAGEMENT_ERROR,
        errorState: result?.error,
    })
    if (result.status === 2) {
        toast.error(appMessages.messageStatus500)
    } else {
        toast.error(result?.error)
    }
}


function* getPostListSaga(action) {
    try {
        const result = yield call(axios.getPostList, action.payload)

        if (result.status === 1) {
            yield put({
                type: types.API_GET_POST_LIST_SUCCESS,
                result: result.result.data.data,
            })
        }
        else {
            yield call(postFailedSaga, result)
        }
    }
    catch (error) {
        yield call(postErrorSaga, error)
    }
}

function* getBotUsersListSaga(action) {
    try {
        const result = yield call(axios.getBotUsersList, action.payload)
        console.log(result, "result")
        if (result.status === 1) {
            yield put({
                type: types.API_GET_BOT_USERS_LIST_SUCCESS,
                result: result.result.data.data,
            })
        }
        else {
            yield call(postFailedSaga, result)
        }
    }
    catch (error) {
        yield call(postErrorSaga, error)
    }
}

function* addPostSaga(action) {
    try {
        const result = yield call(axios.addPost, action.payload)
        console.log(result, "result")
        if (result.status === 1) {
            yield put({
                type: types.API_ADD_POST_LIST_SUCCESS,
                result: result.result.data.data,
            })
            toast.success(result?.result?.data?.message);
            history.push("/postListing");
        }
        else {
            yield call(postFailedSaga, result)
        }
    }
    catch (error) {
        yield call(postErrorSaga, error)
    }
}

function* getUsersListSaga(action) {
    try {
        const result = yield call(axios.getUsersList, action.payload, action.listType)
        console.log(result, "result")
        if (result.status === 1) {
            yield put({
                type: types.API_GET_USERS_LIST_SUCCESS,
                result: result.result.data.data,
            })
        }
        else {
            yield call(postFailedSaga, result)
        }
    }
    catch (error) {
        yield call(postErrorSaga, error)
    }
}

function* getPostDetailsSaga(action) {
    try {
        const result = yield call(axios.getPostDetails, action.payload)
        console.log(result, "result")
        if (result.status === 1) {
            yield put({
                type: types.API_GET_POST_LIST_DETAILS_SUCCESS,
                result: result.result.data.data,
            })
        }
        else {
            yield call(postFailedSaga, result)
            history.push('/postListing')
        }
    }
    catch (error) {
        yield call(postErrorSaga, error)
        history.push('/postListing')

    }
}

function* addBotUserSaga(action) {
    try {
        const result = yield call(axios.addBotUser, action.payload)
        console.log(result, "result")
        if (result.status === 1) {
            yield put({
                type: types.API_ADD_BOT_USERS_SUCCESS,
                result: result.result.data.data,
            })
            toast.success(result?.result?.data?.message);

        }
        else {
            yield call(postFailedSaga, result)
        }
    }
    catch (error) {
        yield call(postErrorSaga, error)
    }
}

function* deletePostSaga(action) {
    console.log(action, "saga")
    try {
        const result = yield call(axios.deletePostList, action.payload)
        if (result.status === 1) {
            const resultSuccess = yield call(axios.getPostList, action.payload)
            if (resultSuccess.status === 1) {
                yield put({
                    type: types.API_GET_POST_LIST_SUCCESS,
                    result: resultSuccess.result.data.data,
                })
            }
            // yield put({
            //     type: types.API_DELETE_POST_LIST_SUCCESS,
            //     postId: action.payload,
            //     result: result.result.data.data,
            // })

            toast.success(result?.result?.data?.message)
        }
        else {
            yield call(postFailedSaga, result)
        }
    }
    catch (error) {
        yield call(postErrorSaga, error)
    }
}

function* sharePostSaga(action) {
    try {
        const result = yield call(axios.sharePost, action.payload)
        if (result.status === 1) {
            const sharedPostList = yield call(axios.getPostList, action.payload)
            if (sharedPostList.status === 1) {
                yield put({
                    type: types.API_GET_POST_LIST_SUCCESS,
                    result: sharedPostList.result.data.data,
                })
            }
            // yield put({
            //     type: types.API_SHARE_POST_SUCCESS,
            //     result: result.result.data.data,
            // })
            toast.success(result?.result?.data?.message, { toastId: result?.status || "abc" })
        }
        else {
            yield call(postFailedSaga, result)
        }
    }
    catch (error) {
        yield call(postErrorSaga, error)
    }
}

function* likePostSaga(action) {
    console.log(action, "saga")
    try {
        const result = yield call(axios.likePost, action.payload)
        if (result.status === 1) {
            const commentList = yield call(axios.getPostDetails, action.payload)
            if (commentList.status === 1) {
                yield put({
                    type: types.API_GET_POST_LIST_DETAILS_SUCCESS,
                    result: commentList.result.data.data,
                })
            }
            // cl('result inside delete terminal area saga', result)
            // yield put({
            //     type: types.API_LIKE_POST_SUCCESS,
            //     result: result.result.data.data,
            // })

            toast.success(result?.result?.data?.message)
        }
        else {
            yield call(postFailedSaga, result)
        }
    }
    catch (error) {
        yield call(postErrorSaga, error)
    }
}

function* commentOnPostSaga(action) {
    try {
        const result = yield call(axios.commentOnPost, action.payload)
        if (result.status === 1) {
            const commentList = yield call(axios.getPostDetails, action.payload)
            if (commentList.status === 1) {
                yield put({
                    type: types.API_GET_POST_LIST_DETAILS_SUCCESS,
                    result: commentList.result.data.data,
                })
            }
            // yield put({
            //     type: types.API_COMMENT_ON_POST_SUCCESS,
            //     result: result.result.data.data,
            // })
            toast.success(result?.result?.data?.message)
        }
        else {
            yield call(postFailedSaga, result)
        }
    }
    catch (error) {
        yield call(postErrorSaga, error)
    }
}

function* likeCommentSaga(action) {
    console.log(action, "saga")
    try {
        const result = yield call(axios.likeComment, action.payload)
        if (result.status === 1) {
            const commentList = yield call(axios.getPostDetails, action.payload)
            if (commentList.status === 1) {
                yield put({
                    type: types.API_GET_POST_LIST_DETAILS_SUCCESS,
                    result: commentList.result.data.data,
                })
            }
            yield put({
                type: types.API_LIKE_COMMENT_SUCCESS,
                result: result.result.data.data,
                commentId: action.payload.commentId,
                likeType: action.payload.likeType,
                likeComment: action.payload.likeComment,
                indexOfComment: action.payload.indexOfComment
            })

            toast.success(result?.result?.data?.message)
        }
        else {
            yield call(postFailedSaga, result)
        }
    }
    catch (error) {
        yield call(postErrorSaga, error)
    }
}

function* deleteCommentSaga(action) {
    console.log(action, "saga")
    try {
        const result = yield call(axios.deleteCommentList, action.payload)
        if (result.status === 1) {
            cl('result inside delete terminal area saga', result)
            yield put({
                type: types.API_DELETE_COMMENT_LIST_SUCCESS,
                commentId: action.payload,
                result: result.result.data.data,
                mainCommentIndex: action.mainCommentIndex,

            })
            toast.success(result?.result?.data?.message)
        }
        else {
            yield call(postFailedSaga, result)
        }
    }
    catch (error) {
        yield call(postErrorSaga, error)
    }
}

function* editCommentSaga(action) {
    try {
        const result = yield call(axios.editComment, action.payload)
        console.log(result, "result")
        if (result.status === 1) {
            const commentList = yield call(axios.getPostDetails, action.payload)
            if (commentList.status === 1) {
                yield put({
                    type: types.API_GET_POST_LIST_DETAILS_SUCCESS,
                    result: commentList.result.data.data,
                })
            }
            // yield put({
            //     type: types.API_EDIT_COMMENT_SUCCESS,
            //     result: result.result.data.data,
            // })
            toast.success(result?.result?.data?.message);
        }
        else {
            yield call(postFailedSaga, result)
        }
    }
    catch (error) {
        yield call(postErrorSaga, error)
    }
}

//// Edit Post Managemet saga
function* editPostManagementSaga(action) {
    try {
        const result = yield call(axios.editPost, action.payload)
        console.log(result, "editPostManagementSaga")
        if (result.status === 1) {
            yield put({
                type: types.API_EDIT_POST_MANAGEMENT_SUCCESS,
                result: result.result.data.data,
            })
            toast.success(result?.result?.data?.message);
            history.push("/postListing");
        }
        else {
            yield call(postFailedSaga, result)
        }
    }
    catch (error) {
        yield call(postErrorSaga, error)
    }
}

//// Delete Post Managemet saga
// function* deletePostManagementSaga(action) {
//     try {
//         const result = yield call(axios.deletePostImage, action.payload)
//         console.log(result, "editPostManagementSaga")
//         if (result.status === 1) {
//             yield put({
//                 type: types.API_EDIT_POST_DELETE_IMAGE_SUCCESS,
//                 result: result.result.data.data,
//             })
//         }
//         else {
//             yield call(postFailedSaga, result)
//         }
//     }
//     catch (error) {
//         yield call(postErrorSaga, error)
//     }
// }


export default function* rootPostManagementSaga() {
    yield takeLatest(types.API_GET_POST_LIST_LOAD, getPostListSaga)
    yield takeLatest(types.API_GET_BOT_USERS_LIST_LOAD, getBotUsersListSaga)
    yield takeLatest(types.API_ADD_POST_LIST_LOAD, addPostSaga)
    yield takeLatest(types.API_GET_USERS_LIST_LOAD, getUsersListSaga)
    yield takeLatest(types.API_GET_POST_LIST_DETAILS_LOAD, getPostDetailsSaga)
    yield takeLatest(types.API_ADD_BOT_USERS_LOAD, addBotUserSaga)
    yield takeLatest(types.API_DELETE_POST_LIST_LOAD, deletePostSaga)
    yield takeLatest(types.API_SHARE_POST_LOAD, sharePostSaga)
    yield takeLatest(types.API_LIKE_POST_LOAD, likePostSaga)
    yield takeLatest(types.API_COMMENT_ON_POST_LOAD, commentOnPostSaga)
    yield takeLatest(types.API_LIKE_COMMENT_LOAD, likeCommentSaga)
    yield takeLatest(types.API_DELETE_COMMENT_LIST_LOAD, deleteCommentSaga)
    yield takeLatest(types.API_EDIT_COMMENT_LOAD, editCommentSaga)
    yield takeLatest(types.API_EDIT_POST_MANAGEMENT_LOAD, editPostManagementSaga)
    // yield takeLatest(types.API_EDIT_POST_DELETE_IMAGE_LOAD, deletePostManagementSaga)
}