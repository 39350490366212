import React from "react";
import { Map, Polyline, Polygon, Circle, GoogleApiWrapper, Marker } from 'google-maps-react';
import { getCenterAndZoom, getCenterAndZoom1 } from "../utils/helpers";
import { toast } from "react-toastify";

var localLatLong = null
const GeofenceGoogleMap = (props) => {
    const { mapSettings, google, center, zoom, onMapClicked, radius, polygonState } = props
    const [localMarker, setLocalMarker] = React.useState();
    const [zoomLevel, setZoomLevel] = React.useState(null);


    React.useEffect(() => {
        return () => { localLatLong = null }
    }, []);


    React.useEffect(() => {
        if (polygonState) {
            const tempData = getCenterAndZoom1(JSON.stringify(polygonState?.points))
            const tempRadius = Math.pow(2, ((tempData.zoomLevel > 6 ? 20 : 19.6) - tempData.zoomLevel))

            //   setCircleZoom(tempRadius)
            //   setMapSettings({
            //     centerLocation: tempData.centerLocation,
            //     zoomLevel: tempData.zoomLevel
            //   })
            setZoomLevel(tempData.zoomLevel)
        }
    }, [polygonState.points]);

    return (
        <>
            {mapSettings &&
                <Map
                    initialCenter={center}
                    className={'map'}
                    google={google}
                    styles={mapStyle}
                    style={{ width: "100%", height: "90%" }}
                    zoom={zoomLevel == null ? 16 : zoomLevel}
                    fullscreenControl={true}
                    streetViewControl={false}
                    onClick={(mapProps, map, e) => onMapClicked(mapProps, map, e, radius)}
                    mapTypeControlOptions={{ mapTypeIds: ["roadmap", "satellite"] }}
                    zoomControl={true}
                    minZoom={9}
                >
                    <Marker
                        name={''}
                        position={center}
                    />
                    <Circle
                        radius={radius}
                        center={center}
                        onMouseover={() => console.log('mouseover')}
                        onClick={() => console.log('click')}
                        onMouseout={() => console.log('mouseout')}
                        strokeColor='transparent'
                        strokeOpacity={0}
                        strokeWeight={5}
                        fillColor='#289edc'
                    />
                    {polygonState.points.length > 0
                        && <Polyline
                            path={polygonState.points}
                            strokeColor="#FF0000"
                            strokeOpacity={1}
                            strokeWeight={2}
                        />}

                </Map>
            }
        </>
    );
};

export default GoogleApiWrapper({
    apiKey: 'AIzaSyAId9HPoVrtc6rDn9O-tAWERRJEelhkARc'
})(GeofenceGoogleMap)

const mapStyle = [
    {
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#0f1214"
            }
        ]
    },
    {
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#757575"
            }
        ]
    },
    {
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#212121"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#757575"
            }
        ]
    },
    {
        "featureType": "administrative.country",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#9e9e9e"
            }
        ]
    },
    {
        "featureType": "administrative.land_parcel",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative.locality",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#bdbdbd"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#757575"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#181818"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#616161"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#1b1b1b"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#2c2c2c"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#8a8a8a"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#373737"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#3c3c3c"
            }
        ]
    },
    {
        "featureType": "road.highway.controlled_access",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#4e4e4e"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#616161"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#757575"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#0e1917"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#3d3d3d"
            }
        ]
    },

    {
        "featureType": "landscape.natural",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000000"
            }
        ]
    },
]
