export const employeeArray =
    [
        {
            key: "Dashboard",
            name: "dashboard",
            view: false,
            edit: false,
            delete: false,
            block: false,
            add: false,
            editDisabled: true,
            deleteDisabled: true,
            blockDisabled: true,
            addDisabled: true
        },
        {
            key: "User Management",
            name: "user",
            view: false,
            edit: false,
            delete: false,
            block: false,
            add: false,
            deleteDisabled: true,
            addDisabled: true
        },
        {
            key: "Employee Management",
            name: "employee",
            view: false,
            edit: false,
            delete: false,
            block: false,
            add: false,
            deleteDisabled: true,
        },
        {
            key: "Post Management",
            name: "postManagement",
            view: false,
            edit: false,
            delete: false,
            block: false,
            add: false,
            blockDisabled: true,
        },
        {
            key: "State Listing",
            name: "state",
            view: false,
            edit: false,
            delete: false,
            block: false,
            add: false,
            viewDisabled: true,
            blockDisabled: true,
            isNested: true
        },
        {
            key: "Region Listing",
            name: "region",
            view: false,
            edit: false,
            delete: false,
            block: false,
            add: false,
            viewDisabled: true,
            blockDisabled: true,
            isNested: true
        },
        {
            key: "Terminal Listing",
            name: "terminal",
            view: false,
            edit: false,
            delete: false,
            block: false,
            add: false,
            viewDisabled: true,
            blockDisabled: true,
            isNested: true
        },
        {
            key: "Image Management",
            name: "image",
            view: false,
            edit: false,
            delete: false,
            block: false,
            add: false,
            viewDisabled: true,
            blockDisabled: true,
            editDisabled: true,
        },
        {
            key: "User Event Management",
            name: "location",
            view: false,
            edit: false,
            delete: false,
            block: false,
            add: false,
            blockDisabled: true,
            editDisabled: true,
            deleteDisabled: true,
            addDisabled: true
        },
        {
            key: "News Management",
            name: "news",
            view: false,
            edit: false,
            delete: false,
            block: false,
            add: false,
            blockDisabled: true,
        },
        {
            key:"Terminal Board",
            name: "terminalBoard",
            view: false,
            edit: false,
            delete: false,
            block: false,
            add: false,
            blockDisabled: true,
            deleteDisabled: true,
            addDisabled:true,
            viewDisabled:true
        },
        {
            key: "Notification Management",
            name: "notification",
            view: false,
            edit: false,
            delete: false,
            block: false,
            add: false,
            blockDisabled: true,
            deleteDisabled: true
        },
        {
            key: "Report Post",
            name: "post",
            view: false,
            edit: false,
            delete: false,
            block: false,
            add: false,
            blockDisabled: true,
            editDisabled: true,
            deleteDisabled: true,
            addDisabled: true
        },
    ]
