// Validation Constant Strings
export const ValidationConstants = {
  email: {
    empty: "Please enter email address.",
    long: "The email address may not be greater than 255 characters.",
    invalid: "Please enter valid email address.",
  },
  password: {
    empty: "Please enter password.",
    short: "Password should be at least 6 characters long.",
    long: "Password must contain less than 255 characters.",
    noSpace: "Space is not allowed in password.",
    noSpaceFirstName: "Space is not allowed in first name.",
    noSpaceLastName: "Space is not allowed in last name.",
    emptyOld: "Please enter old password.",
    emptyNew: "Please enter new password.",
    emptyConfirm: "Please enter confirm password.",
    unmathcedConfirm: "New password and confirm password must be same.",
    shortNew: "New password should be at least 6 characters long.",
    longNew: "New password must contain less than 255 characters",
  },
  username: {
    empty: "Please enter username.",
    short: "Username should be at least 3 characters long.",
    long: "Username should not be greater than 20 characters.",
    noSpace: "Space is not allowed in username.",
  },
  firstname: {
    empty: "Please enter first name.",
    short: "First name should be at least 3 characters long.",
    long: "First name should not be greater than 20 characters.",
    noSpace: "Space is not allowed in first name.",
  },
  name: {
    empty: "Please enter name.",
    short: "Name should be at least 3 characters long.",
    long: "Name should not be greater than 20 characters.",
    noSpace: "Space is not allowed in first name.",
  },
  lastname: {
    empty: "Please enter last name.",
    short: "Last name should be at least 3 characters long.",
    long: "Last name should not be greater than 20 characters.",
    noSpace: "Space is not allowed in last name.",
  },
  userName: {
    empty: "Please enter username.",
    short: "Username should be at least 3 characters long.",
    long: "Username should not be greater than 10 characters.",
    validUserName: "Only letters (a-z), numbers (0-9), periods (.) and underscore (_) are allowed.",
    space: "Space is not allowed.",
    startWithDot: "Username should not be start with periods (.)",
    notOnlyNumber: "Username cannot contain only numbers.",
    endWithDot: "Username should not be end with periods (.)",
    twoDots: "Username should not have more the one period (.) in a row.",
  },
  terminalAreaName: {
    empty: "Please enter region name.",
    short: "Region name should be at least 2 characters.",
    long: "Region name should not be greater than 30 characters.",
  },
  stateName: {
    empty: "Please enter state name.",
    short: "State name should be at least 2 characters.",
    long: "State name should not be greater than 30 characters.",
  },
  shortName: {
    empty: "Please enter short name.",
    short: "Short name should be at least 2 characters.",
    long: "Short name should not be greater than 2 characters.",
  },
  phoneNumber: {
    empty: "Please enter phone number.",
    invalid: "Please enter valid phone number.",
    long: "Phone number must be between 8 to 15 digits.",
    short: "Phone number must be between 8 to 15 digits.",
  },
  userType: {
    empty: "Please enter role type.",
    access: "Please select at least one access.",
    short: "Role type should be at least 3 characters.",
    long: "Role type should not be greater than 30 characters.",
    noSpace: "Space is not allowed in role type.",
  },
  terminalName: {
    short: "Terminal name should be at least 2 characters long.",
    long: "Terminal name should not be greater than 50 characters.",
    empty: "Please enter terminal name.",
  },
  terminalCountry: {
    long: "Terminal country name should not be greater than 20 characters.",
    empty: "Please enter terminal country name.",
    short: "Terminal country should be at least 2 characters long.",

  },
  terminalAddress: {
    short: "Terminal Address should be at least 2 characters long.",
    empty: "Please enter terminal address.",

  },
  terminalCity: {
    long: "Terminal city name should not be greater than 20 characters.",
    short: "Terminal City name should be at least 2 characters long.",
    empty: "Please enter terminal city name.",
  },
  terminalState: {
    long: "State/Province name should not be greater than 20 characters.",
    short: "State/Province name should be at least 2 characters long.",
    empty: "Please enter state/province name.",
  },
  zipCode: {
    short: "Zip code must contain at least 5 characters.",
    long: "Zip code should not be greater than 12 characters.",
    empty: "Please enter zip code.",
  },
  terminalOpenTime: {
    empty: "Please enter terminal open time.",
    invalid: "Please enter valid terminal open time",
  },
  terminalCloseTime: {
    empty: "Please enter terminal close time.",
    invalid: "Please enter valid terminal close time",
    diff: "Time difference should be greater than 60 mins.",
    correctPolygon: "Please enter data in correct format for terminal polygon.",
  },
  terminalLatitude: {
    invalid: "Please enter valid terminal latitude.",
    empty: "Please enter terminal latitude.",
  },
  terminalLongitude: {
    invalid: "Please enter valid terminal longitude.",
    empty: "Please enter terminal longitude.",
  },
  terminalRadius: {
    invalid: "Please enter valid terminal radius.",
    empty: "Please enter terminal radius.",
  },
  terminalArea: {
    empty: "Please select region.",
  },
  locationCategory: {
    empty: "Please select location category.",
  },
  terminalImage: {
    empty: "Please select terminal logo.",
  },
  file: {
    empty: "Please upload file.",
    invalid: "Please upload .csv file.",
  },
  image: {
    empty: "Please upload image.",
    invalidRatio: "Please upload image of 2:1 ratio.",
    invalidRatioOneByOne: "Please upload image of 1:1 ratio.",
  },
  imageName: {
    empty: "Image name should not be empty.",
    invalid: "Please enter valid image name.",
  },
  title: {
    long: "Title should not be greater than 50 characters.",
    short: "Title should be at least 2 characters long.",
    empty: "Please enter title.",
  },
  description: {
    long: "Description should not be greater than 1000 characters.",
    short: "Description should be at least 2 characters long.",
    empty: "Please enter description.",
  },
  latitude: {
    invalid: "Please enter valid latitude.",
    empty: "Please enter latitude.",
  },
  longitude: {
    invalid: "Please enter valid longitude.",
    empty: "Please enter longitude.",
  },
  radius: {
    invalid: "Please enter valid radius.",
    empty: "Please enter radius.",
  },
  user: {
    empty: "Please select user."
  },
  timezone: {
    empty: "Please select timezone."
  },
  aboutUs: {
    long: "About us should not be greater than 50 characters.",
    short: "About us should be at least 2 characters long.",
    empty: "Please enter about us.",
  },
  invalid: {
    email: {
      invalidEmail: "Please enter valid email address.",
      invalidEmailorPassword: "Invalid email address or password.",
      unRegisteredEmail: "Please enter registered email address.",
    },
    password: {
      shortPassword: "Password should be at least 6 characters long.",
      longPassword: "Password must contain less than 255 characters",
      unmathcedConfirm: "Password and confirm password must be same.",
    },
    name: {
      shortName: "Name should be at least 2 characters long.",
    },
    contact: {
      incorrectContactLength: "Phone number should be between 8 to 15 digits.",
      incorrectContact: "Please enter valid phone number.",
    },

  },
  empty: {
    emptyEmail: "Please enter email address.",
    emptyOldPassword: "Please enter old password.",
    emptyNewPassword: "Please enter new password.",
    emptyPassword: "Please enter password.",
    emptyConfirm: "Please enter confirm password.",
    emptyContact: "Please enter phone number.",
    emptyName: "Please enter name.",
    subject: "Please enter subject.",
    message: "Please enter message.",
  },
  success: {
    registerSuccess: "You have been registered successfully.",
    verifyRegisteration:
      "You have been registered successfully! Please verify your email address to login into the website.",
    forgotSuccess:
      "Forgot password link has been sent to your registered email address. ",
    updateSuccess: "User details has been updated successfully. ",
    blockSuccess: "User has been blocked successfully.",
    unBlockSuccess: "User has been unblocked successfully.",
    userAddedSuccess: "User has been added successfully.",
    deleteSuccess: "User has been delete successfully.",
    blockedEmail: "Your account has been blocked by admin.",
  },
};

export const appMessages = {
  offline: "Your internet connection appears to be offline. Please try again.",
  // 401 message
  messageStatus401: "The user is not authorized to make the request.",
  messageStatus500: "Something went wrong.",
  resetLinkExpired: "Your password reset link has expired",
  clickBelow: "Click below to generate a new one.",
  wrongPage: "Sorry, we couldn't' find this page",
  tryAgain: "Try again searching or go to",
};

// Application constants
export const appConstants = {
  distanceMi: "Distance (Mi)",
  terminalType: "Terminal Type",
  category: "Category",
  address: "Address",
  firstName: "First Name",
  lastName: "Last Name",
  emailAddress: "Email Address",
  password: "Password",
  newPassword: "New Password",
  confirmPassword: "Confirm Password",
  submit: "Submit",
  resetPassword: "Reset Password",
  // Timeline
  timelineAlert: "Do you want to open the post in application?",
  openInApp: "Open In App",
  cancel: "Cancel",
  // Login Page
  adminLogin: "Admin Login",
  forgotLink: "Forgot password?",
  login: "Login",
  // Forgot Password
  forgotPassword: "Forgot Password",
  sendEmail: "Send reset password email",
  filter: "Filter",
  startDate: "Start Date",
  endDate: "End Date",
  role: "Role",
  duplicateNumber: "Duplicate phone numbers are not allowed.",
  headerTitle: {
    login: "FR8 | Login",
    timeline: "FR8 | Timeline Post",
    forgotPassword: "FR8 | Forgot Password",
    resetPassword: "FR8 | Reset Password",
    dashboard: "FR8 | Dashboard",
    userManagement: "FR8 | User Management",
    userEventManagement: "FR8 | User Event Management",
    userDetails: "FR8 | User Details",
    editUserDetails: "FR8 | Edit User Details",
    savedLocations: "FR8 | Saved Locations",
    terminalAreaListing: "FR8 | Region Listing",
    addTerminalArea: "FR8 | Add Region",
    editTerminalArea: "FR8 | Edit Region",
    importTerminalArea: "FR8 | Import Region",
    terminalListing: "FR8 | Terminal Listing",
    ratingDetails: "FR8 | Rating Details",
    addTerminal: "FR8 | Add Terminal",
    editTerminal: "FR8 | Edit Terminal",
    importTerminal: "FR8 | Import Terminal",
    managePost: "FR8 | Manage Post",
    postDetails: "FR8 | Post Details",
    reportPost: "FR8 | Report Post",
    imageListing: "FR8 | Image Management",
    addImage: "FR8 | Add Image",
    addNews: "FR8 | Add News",
    editNews: "FR8 | Edit News Details",
    viewNews: "FR8 | News Details",
    terminalNewManagement: "FR8 | News Management",
    stateListing: "FR8 | State Listing",
    addStateListing: "FR8 | Add State",
    editStateListing: "FR8 | Edit State",
    importState: "FR8 | Import State",
    pushNotifications: "FR8 | Push Notification",
    geofence: "FR8 | Geofence Region Management",
    addGeofence: "FR8 | Add Geofence Region",
    geofenceDetails: "FR8 | Geofence Region Details",
    editGeofence: "FR8 | Edit Geofence Region",
    employeeList: "FR8 | Employee Management",
    editEmployee: "FR8 | Edit Employee Details",
    AddEmployee: "FR8 | Add Employee",
    employeeDetails: "FR8 | Employee Details",
    viewTimeline: "FR8 | View Timeline",
    viewTimelineDetails: "FR8 | View Timeline Details",
    reportedUsers: "FR8 | Reported By",
    reportedUsersDetails: "FR8 | Reported Reason Details",
    analytics: "FR8 | Analytics",
    eventManagement: "FR8 | Event Management",
    postListing: "FR8 | Post Management",
    AddPost: "FR8 | Add Post",
    EdittPost: "FR8 | Edit Post",
    detailsPost: "FR8 | Post Details",
    terminalBoard: "FR8 | Terminal Board",
    editTerminalBoard: "FR8 | Edit Terminal Board",
    editDefaultTheme: "FR8 | Edit Default Theme",
    manageWaitTime: "FR8 | Manage Wait Time",
  },
  tooltip: {
    uploadTerminalImage: "Click here to upload terminal image.",
    changeTerminalImage: "Click here to change terminal image.",
    uploadMapLogo: "Click here to upload map logo.",
    uploadTerminalBoardLogo: "Click here to upload terminal board logo.",
    changeTerminalBoardLogo: "Click here to change terminal board logo.",
    changeMapLogo: "Click here to change map logo.",
    uploadImage: "Click here to upload image.",
    changeImage: "Click here to change image.",
    uploadVideo: "Click here to upload video.",
    changeVideo: "Click here to change video.",
    uploadFile: "Click here to upload file.",
    changeFile: "Click here to change file.",
  },
  // Dashboard
  terminalManagement: "Terminal Management",
  gotToForogot: "Forgot Password",
  home: "Home",
  // ReportPost
  date: "Date",
  time: "Time",
  reportPost: "Report Post",
  alert: "Alert",
  postDetails: "Post Details",
  videoImage: "Video/Image",
  postDescription: "Post Description",
  thumbsUpCount: "Thumbs Up Count",
  thumbsDownCount: "Thumbs Down Count",
  reportDescription: "Report Description",
  revoke: "Revoke",
  dashboard: "Dashboard",
  registeredUsers: "Registered Users",
  activeUsers: "Active Users",
  totalTimeline: "No. Of Timeline Created",
  totalPost: "No. Of Posts Created",
  totalCooments: "No. Of Comments",
  totalLikes: "No. Of Likes",
  // User Management
  userManagement: "User Management",
  editUserDetails: "Edit User Details",
  userDetails: "User Details",
  sr: "Sr. No.",
  username: "Username",
  reportCount: "Report Count",
  phoneNumber: "Phone Number",
  userType: "User Type",
  selectType: "Select Type",
  driver: "Driver",
  ownerOperator: "Owner Operator",
  airCargoCarrier: "Air Cargo Carrier",
  company: "Company",
  exporter: "Exporter",
  freightBroker: "Freight Broker",
  importer: "Importer",
  shipper: "Shipper",
  trucking: "Trucking",
  warehouse: "Warehouse",
  terminalId: "Terminal ID",
  terminalName: "Terminal Name",
  latitude: "Latitude",
  longitude: "Longitude",
  type: "Type",
  dateAndTime: "Date And Time",
  action: "Action",
  view: "View",
  edit: "Edit",
  delete: "Delete",
  block: "Block",
  unblock: "Unblock",
  update: "Update",
  savedLocations: "Saved Locations",
  viewSavedLocations: "View Saved Locations",
  enter: "Enter",
  exit: "Exit",
  tagFriends: "Tag Friends",
  hide: "Hide",
  unHide: "Unhide",
  // Image management
  imageManagement: "Image Management",
  addImage: "Add Image",
  addNews: "Add News",
  editNews: "Edit News",
  imageName: "Image Name",
  uploadImage: "Upload Image",
  image: "Image",
  // Terminal Area Name
  terminalAreaName: "Region Name",
  enterTerminalAreaName: "Enter Region Name",
  confirmDeletePost: "Are you sure, you want to delete this post?",
  confirmCommentPost: "Are you sure, you want to delete this comment?",
  confirmDeleteImage: "Are you sure, you want to delete this image?",
  confirmDeleteTerminal: "Are you sure, you want to delete this terminal?",
  confirmDeleteNews: "Are you sure, you want to delete this news?",
  confirmDeleteTerminalArea: "Are you sure, you want to delete terminal area?",
  deleteRegionArea: "Are you sure, you want to delete this region?",
  confirmDeleteState: "Are you sure, you want to delete this state?",
  confirmGeofence: "Are you sure, you want to delete this geofence?",
  generatePassword: "Are you sure, you want to generate password?",
  confirmDeletePost: "Are you sure, you want to delete this post?",
  confirmDeleteRating: "Are you sure, you want to delete this rating?",
  confirmHideRating: "Are you sure, you want to hide this rating?",
  imageValidation: "Only .jpg, .jpeg, .png and .svg format images are allowed.",
  videoAllowed: "Only .mp4 format is allowed.",
  selectOneValidation: "Please select at least one field from description, image or video.",
  videoLimit: "Video limit should be maximum 30 seconds.",
  defaultTheme: "Are you sure, you want to apply default theme to all regions?",
  // baseURL: "http://192.168.3.174:6001/", //local
  // baseURL: "http://192.168.3.173:7000/", //local
  // baseURL: "http://192.168.3.176:4000/", //local
  // baseURL: "http://BAckend-ASG-771965392.us-east-2.elb.amazonaws.com/", //local
  // baseURL: "https://s3.us-east-2.amazonaws.com/fr8.ai-website-dev-content/", //local
  baseURL: "https://d2d4ia5b61zo7b.cloudfront.net/",
  baseURL1: "https://s3.us-east-2.amazonaws.com/fr8.ai-website-dev-content", //local
  // baseURL: "http://1.6.98.142:4000/", //public server
  // baseURL : "http://18.205.207.176:3000/", //superlive
  // baseURL: "https://api.fr8.ai/", //superlive with domain
  // baseURL:"http://18.217.24.120:4000/", // stagging server

  //   Secrete key: 89Ychh8quxNhM7CE7JfK2bY4FPdNwB/qYgPGOS+J
  // Access Key: AKIA4WINVFQ3BSUKN2UC

  // Terminal Report Post
  terminalListing: "Terminal Listing",
  managePost: "Manage Post",
  radarManagement: "User Event Management",
  newsManagement: "News Management",
  notificationManagement: "Notification Management",
  userID: "User ID",
  terminalID: "Terminal ID",
  terminalName: "Terminal Name",
  createdAt: "Created At",
  terminalIdFilter: "Enter Terminal ID",
  userIdFilter: "Enter User ID",

  //employee management
  employeeManagement: "Employee Management",
  addEmployee: "Add Employee",
  employeeDetails: "Employee Details",
  editEmployee: "Edit Employee Details",

  //analytics page
  downloadasJpg: "Download as .jpg",
  downloadasPng: "Download as .png",
  barchart: "Bar Chart",
  linechart: "Line Chart",

  //post management
  postManagement: "Post Management",
  addPost: "Add Post",
  postDetails: "Post Details",
  editPost: "Edit Post Details",

};

// Api Action type constants
export const apiConstants = {
  // Clear state
  CLEAR_STATE: "CLEAR_STATE",
  HANDLE_SIDEBAR_NAVIGATION_STATE: "HANDLE_SIDEBAR_NAVIGATION_STATE",
  HANDLE_SIDEBAR_DRAWER_TOGGLE_STATE: "HANDLE_SIDEBAR_DRAWER_TOGGLE_STATE",

  // ************ AUTHENTICATION ************
  // Authentications types
  API_AUTHENTICATION_FAILED: "API_AUTHENTICATION_FAILED",
  API_AUTHENTICATION_ERROR: "API_AUTHENTICATION_ERROR",

  // Login
  API_LOGIN_LOAD: "API_LOGIN_LOAD",
  API_LOGIN_SUCCESS: "API_LOGIN_SUCCESS",

  // Forgot Password
  API_FORGOT_PASSWORD_LOAD: "API_FORGOT_PASSWORD_LOAD",
  API_FORGOT_PASSWORD_SUCCESS: "API_FORGOT_PASSWORD_SUCCESS",

  // Reset Password
  API_RESET_PASSWORD_LOAD: "API_RESET_PASSWORD_LOAD",
  API_RESET_PASSWORD_SUCCESS: "API_RESET_PASSWORD_SUCCESS",

  // Reset Password
  API_CHECK_RESET_PASSWORD_LOAD: "API_CHECK_RESET_PASSWORD_LOAD",
  API_CHECK_RESET_PASSWORD_SUCCESS: "API_CHECK_RESET_PASSWORD_SUCCESS",

  // ************ USER MANAGEMENT ************
  // User Management
  API_USER_MANAGEMENT_FAILED: "API_USER_MANAGEMENT_FAILED",
  API_USER_MANAGEMENT_ERROR: "API_USER_MANAGEMENT_ERROR",

  //Get User List
  API_GET_USER_LIST_LOAD: "API_GET_USER_LIST_LOAD",
  API_GET_USER_LIST_SUCCESS: "API_GET_USER_LIST_SUCCESS",

  //Get User Details
  API_GET_USER_DETAILS_LOAD: "API_GET_USER_DETAILS_LOAD",
  API_GET_USER_DETAILS_SUCCESS: "API_GET_USER_DETAILS_SUCCESS",

  //Edit User Details
  API_EDIT_USER_DETAILS_LOAD: "API_EDIT_USER_DETAILS_LOAD",
  API_EDIT_USER_DETAILS_SUCCESS: "API_EDIT_USER_DETAILS_SUCCESS",

  //Block User
  API_BLOCK_USER_LOAD: "API_BLOCK_USER_LOAD",
  API_BLOCK_USER_SUCCESS: "API_BLOCK_USER_SUCCESS",

  //reported users
  API_REPORTED_USERS_LIST_LOAD: "API_REPORTED_USERS_LIST_LOAD",
  API_REPORTED_USERS_LIST_SUCCESS: "API_REPORTED_USERS_LIST_SUCCESS",

  //reported users details
  API_REPORTED_USERS_DETAILS_LOAD: "API_REPORTED_USERS_DETAILS_LOAD",
  API_REPORTED_USERS_DETAILS_SUCCESS: "API_REPORTED_USERS_DETAILS_SUCCESS",

  //export reported user
  API_EXPORT_REPORTED_USER_LOAD: " API_EXPORT_REPORTED_USER_LOAD",
  API_EXPORT_REPORTED_USER_SUCCESS: "API_EXPORT_REPORTED_USER_SUCCESS",

  //timezone list
  API_TIMEZONE_LIST_LOAD: "API_TIMEZONE_LIST_LOAD",
  API_TIMEZONE_LIST_SUCCESS: "API_TIMEZONE_LIST_SUCCESS",

  // ************ TERMINAL AREA MANAGEMENT ************
  // Terminal Area Management Fail
  API_TERMINAL_AREA_MANAGEMENT_FAILED: "API_TERMINAL_AREA_MANAGEMENT_FAILED",
  API_TERMINAL_AREA_MANAGEMENT_ERROR: "API_TERMINAL_AREA_MANAGEMENT_ERROR",

  //Get Terminal Area List
  API_GET_TERMINAL_AREA_LIST_LOAD: "API_GET_TERMINAL_AREA_LIST_LOAD",
  API_GET_TERMINAL_AREA_LIST_SUCCESS: "API_GET_TERMINAL_AREA_LIST_SUCCESS",

  //Get Terminal Area Details
  API_GET_TERMINAL_AREA_DETAILS_LOAD: "API_GET_TERMINAL_AREA_DETAILS_LOAD",
  API_GET_TERMINAL_AREA_DETAILS_SUCCESS:
    "API_GET_TERMINAL_AREA_DETAILS_SUCCESS",
  HANDLE_CLEAR_MAP_CENTER_POINT: "HANDLE_CLEAR_MAP_CENTER_POINT",

  //Add Terminal Area
  API_ADD_TERMINAL_AREA_LOAD: "API_ADD_TERMINAL_AREA_LOAD",
  API_ADD_TERMINAL_AREA_SUCCESS: "API_ADD_TERMINAL_AREA_SUCCESS",

  //Edit Terminal Area
  API_EDIT_TERMINAL_AREA_LOAD: "API_EDIT_TERMINAL_AREA_LOAD",
  API_EDIT_TERMINAL_AREA_SUCCESS: "API_EDIT_TERMINAL_AREA_SUCCESS",

  //Delete Terminal Area
  API_DELETE_TERMINAL_AREA_LOAD: "API_DELETE_TERMINAL_AREA_LOAD",
  API_DELETE_TERMINAL_AREA_SUCCESS: "API_DELETE_TERMINAL_AREA_SUCCESS",

  // ************ TERMINAL MANAGEMENT ************

  // Terminal Management Fail
  API_TERMINAL_MANAGEMENT_FAILED: "API_TERMINAL_MANAGEMENT_FAILED",
  API_TERMINAL_MANAGEMENT_ERROR: "API_TERMINAL_MANAGEMENT_ERROR",

  //Get Teminal List
  API_GET_TERMINAL_LIST_LOAD: "API_GET_TERMINAL_LIST_LOAD",
  API_GET_TERMINAL_LIST_SUCCESS: "API_GET_TERMINAL_LIST_SUCCESS",

  //Add Teminal
  API_ADD_TERMINAL_LOAD: "API_ADD_TERMINAL_LOAD",
  API_ADD_TERMINAL_SUCCESS: "API_ADD_TERMINAL_SUCCESS",

  //Get Teminal Details
  API_GET_TERMINAL_DETAILS_LOAD: "API_GET_TERMINAL_DETAILS_LOAD",
  API_GET_TERMINAL_DETAILS_SUCCESS: "API_GET_TERMINAL_DETAILS_SUCCESS",

  //Edit Teminal
  API_EDIT_TERMINAL_LOAD: "API_EDIT_TERMINAL_LOAD",
  API_EDIT_TERMINAL_SUCCESS: "API_EDIT_TERMINAL_SUCCESS",

  //Delete Teminal
  API_DELETE_TERMINAL_LOAD: "API_DELETE_TERMINAL_LOAD",
  API_DELETE_TERMINAL_SUCCESS: "API_DELETE_TERMINAL_SUCCESS",

  //delete geofence
  API_DELETE_GEOFENCE1_LOAD: "API_DELETE_GEOFENCE1_LOAD",
  API_LOAD_GEOFENCE1_SUCCESS: "API_LOAD_GEOFENCE1_SUCCESS",

  //Get Teminal List
  API_GET_RATING_LIST_LOAD: "API_GET_RATING_LIST_LOAD",
  API_GET_RATING_LIST_SUCCESS: "API_GET_RATING_LIST_SUCCESS",

  //Get Teminal List
  API_HIDE_DELETE_RATINGS_LOAD: "API_HIDE_DELETE_RATINGS_LOAD",
  API_HIDE_DELETE_RATINGS_SUCCESS: "API_HIDE_DELETE_RATINGS_SUCCESS",

  // ************ IMPORT / EXPORT FILE************

  //Import Teminal
  API_IMPORT_FILE_LOAD: "API_IMPORT_FILE_LOAD",
  API_IMPORT_FILE_SUCCESS: "API_IMPORT_FILE_SUCCESS",

  //Export Terminal files
  API_EXPORT_FILE_LOAD: "API_EXPORT_FILE_LOAD",
  API_EXPORT_FILE_SUCCESS: "API_EXPORT_FILE_SUCCESS",

  //Export State files
  API_EXPORT_LISTING_FILE_LOAD: "API_EXPORT_LISTING_FILE_LOAD",
  API_EXPORT_LISTING_FILE_SUCCESS: "API_EXPORT_LISTING_FILE_SUCCESS",

  // Import state files
  API_IMPORT_LIST_FILE_LOAD: "API_IMPORT_LIST_FILE_LOAD",
  API_IMPORT_LIST_FILE_SUCCESS: "API_IMPORT_LIST_FILE_SUCCESS",

  //Export User
  API_EXPORT_USER_FILE_LOAD: "API_EXPORT_USER_FILE_LOAD",
  API_EXPORT_USER_FILE_SUCCESS: "API_EXPORT_USER_FILE_SUCCESS",

  // Import User
  API_IMPORT_USER_FILE_LOAD: "API_IMPORT_USER_FILE_LOAD",
  API_IMPORT_USER_FILE_SUCCESS: "API_IMPORT_USER_FILE_SUCCESS",

  // ************ REPORT POST MANAGEMENT ************

  // Report Post Fail
  API_REPORT_POST_FAILED: "API_REPORT_POST_FAILED",
  API_REPORT_POST_ERROR: "API_REPORT_POST_ERROR",

  //Get Report Post List
  API_GET_REPORT_POST_LIST_LOAD: "API_GET_REPORT_POST_LIST_LOAD",
  API_GET_REPORT_POST_LIST_SUCCESS: "API_GET_REPORT_POST_LIST_SUCCESS",

  //Get Report Post Details
  API_GET_REPORT_POST_DETAILS_LOAD: "API_GET_REPORT_POST_DETAILS_LOAD",
  API_GET_REPORT_POST_DETAILS_SUCCESS: "API_GET_REPORT_POST_DETAILS_SUCCESS",

  //Delete Report Post
  API_DELETE_REPORT_POST_LOAD: "API_DELETE_REPORT_POST_LOAD",
  API_DELETE_REPORT_POST_SUCCESS: "API_DELETE_REPORT_POST_SUCCESS",

  //Revoke Report Post
  API_REVOKE_REPORT_POST_LOAD: "API_REVOKE_REPORT_POST_LOAD",
  API_REVOKE_REPORT_POST_SUCCESS: "API_REVOKE_REPORT_POST_SUCCESS",

  // ************ TERMINAL REPORT POST MANAGEMENT ************

  // Terminal Report Post Fail
  API_TERMINAL_REPORT_POST_FAILED: "API_TERMINAL_REPORT_POST_FAILED",
  API_TERMINAL_REPORT_POST_ERROR: "API_TERMINAL_REPORT_POST_ERROR",

  //Get Terminal Report Post List
  API_GET_TERMINAL_REPORT_POST_LIST_LOAD:
    "API_GET_TERMINAL_REPORT_POST_LIST_LOAD",
  API_GET_TERMINAL_REPORT_POST_LIST_SUCCESS:
    "API_GET_TERMINAL_REPORT_POST_LIST_SUCCESS",

  //Get Terminal Report Post Details
  API_GET_TERMINAL_REPORT_POST_DETAILS_LOAD:
    "API_GET_TERMINAL_REPORT_POST_DETAILS_LOAD",
  API_GET_TERMINAL_REPORT_POST_DETAILS_SUCCESS:
    "API_GET_TERMINAL_REPORT_POST_DETAILS_SUCCESS",

  //Delete Terminal Report Post
  API_DELETE_TERMINAL_REPORT_POST_LOAD: "API_DELETE_TERMINAL_REPORT_POST_LOAD",
  API_DELETE_TERMINAL_REPORT_POST_SUCCESS:
    "API_DELETE_TERMINAL_REPORT_POST_SUCCESS",

  // ************ IMAGE MANAGEMENT ************

  // Image Fail
  API_IMAGE_MANAGEMENT_FAILED: "API_IMAGE_MANAGEMENT_FAILED",
  API_IMAGE_MANAGEMENT_ERROR: "API_IMAGE_MANAGEMENT_ERROR",

  //Get Image List
  API_GET_IMAGE_LIST_LOAD: "API_GET_IMAGE_LIST_LOAD",
  API_GET_IMAGE_LIST_SUCCESS: "API_GET_IMAGE_LIST_SUCCESS",

  //Get Image Details
  API_ADD_IMAGE_LOAD: "API_ADD_IMAGE_LOAD",
  API_ADD_IMAGE_SUCCESS: "API_ADD_IMAGE_SUCCESS",

  //Delete Image
  API_DELETE_IMAGE_LOAD: "API_DELETE_IMAGE_LOAD",
  API_DELETE_IMAGE_SUCCESS: "API_DELETE_IMAGE_SUCCESS",

  // ************ SAVE USER LOCATION ************

  // Terminal Report Post Fail
  API_SAVED_USER_LOCATIONS_FAILED: "API_SAVED_USER_LOCATIONS_FAILED",
  API_SAVED_USER_LOCATIONS_ERROR: "API_SAVED_USER_LOCATIONS_ERROR",

  //Get Terminal Report Post List
  API_SAVED_USER_LOCATIONS_LOAD: "API_SAVED_USER_LOCATIONS_LOAD",
  API_SAVED_USER_LOCATIONS_SUCCESS: "API_SAVED_USER_LOCATIONS_SUCCESS",

  // ************ TIMELINE DETAILS ************

  //Get Timeline Details
  API_TIMELINE_DETAILS_LOAD: "API_TIMELINE_DETAILS_LOAD",
  API_TIMELINE_DETAILS_SUCCESS: "API_TIMELINE_DETAILS_SUCCESS",

  // ************VIEW TIMELINE DETAILS ************

  API_VIEW_TIMELINE_DETAILS_LOAD: "API_VIEW_TIMELINE_DETAILS_LOAD",
  API_VIEW_TIMELINE_DETAILS_SUCCESS: "API_VIEW_TIMELINE_DETAILS_SUCCESS",

  //get timeline details
  API_GET_TIMELINE_DETAILS_LOAD: "API_GET_TIMELINE_DETAILS_LOAD",
  API_GET_TIMELINE_DETAILS_SUCCESS: "API_GET_TIMELINE_DETAILS_SUCCESS",

  // View Timeline Fail
  API_VIEW_TIMELINE_FAILED: "API_VIEW_TIMELINE_FAILED",
  API_VIEW_TIMELINE_ERROR: "API_VIEW_TIMELINE_ERROR",


  // ************ GEOFENCING ************
  // Geofencing Fail
  API_GEOFENCING_FAILED: "API_GEOFENCING_FAILED",
  API_GEOFENCING_ERROR: "API_GEOFENCING_ERROR",

  //Get Geofencing Details
  API_POST_GEOFENCING_LOAD: "API_POST_GEOFENCING_LOAD",
  API_POST_GEOFENCING_SUCCESS: "API_POST_GEOFENCING_SUCCESS",

  //radar management

  //radar management fail
  API_RADAR_MANAGEMENT_FAILED: "API_RADAR_MANAGEMENT_FAILED",
  API_RADAR_MANAGEMENT_ERROR: "API_RADAR_MANAGEMENT_ERROR",

  //get radar entry/exit list
  API_RADAR_ENTRY_EXIT_LOAD: "API_RADAR_ENTRY_EXIT_LOAD",
  API_RADAR_ENTRY_EXIT_SUCCESS: "API_RADAR_ENTRY_EXIT_SUCCESS",

  //get radar export list
  API_RADAR_EXPORT_LOAD: "API_RADAR_EXPORT_LOAD",
  API_RADAR_EXPORT_SUCCESS: "API_RADAR_EXPORT_SUCCESS",
  //news management
  API_NEWS_MANAGEMENT_FAILED: "API_NEWS_MANAGEMENT_FAILED",
  API_NEWS_MANAGEMENT_ERROR: "API_NEWS_MANAGEMENT_ERROR",
  //get news
  API_GET_NEWS_LIST_LOAD: "API_GET_NEWS_LIST_LOAD",
  API_GET_NEWS_LIST_SUCCESS: "API_GET_NEWS_LIST_SUCCESS",
  //add news
  API_ADD_NEWS_LOAD: "API_ADD_NEWS_LOAD",
  API_ADD_NEWS_SUCCESS: "API_ADD_NEWS_SUCCESS",
  //edit news
  API_EDIT_NEWS_LOAD: "API_EDIT_NEWS_LOAD",
  API_EDIT_NEWS_SUCCESS: "API_EDIT_NEWS_SUCCESS",
  //delete news
  API_DELETE_NEWS_LOAD: "API_DELETE_NEWS_LOAD",
  API_DELETE_NEWS_SUCCESS: "API_DELETE_NEWS_SUCCESS",

  //delete news
  API_DELETE_IMAGE_NEWS_LOAD: "API_DELETE_IMAGE_NEWS_LOAD",
  API_DELETE_IMAGE_NEWS_SUCCESS: "API_DELETE_IMAGE_NEWS_SUCCESS",

  // ************ STATE MANAGEMENT ************
  // State Listing Fail
  API_STATE_LIST_MANAGEMENT_FAILED: "API_STATE_LIST_MANAGEMENT_FAILED",
  API_STATE_LIST_MANAGEMENT_ERROR: "API_STATE_LIST_MANAGEMENT_ERROR",

  //Get State List
  API_GET_STATE_LIST_LOAD: "API_GET_STATE_LIST_LOAD",
  API_GET_STATE_LIST_SUCCESS: "API_GET_STATE_LIST_SUCCESS",

  //Get State Details
  API_GET_STATE_LIST_DETAILS_LOAD: "API_GET_STATE_LIST_DETAILS_LOAD",
  API_STATE_LIST_DETAILS_SUCCESS:
    "API_GET_STATE_LIST_DETAILS_SUCCESS",

  //Add State
  API_ADD_STATE_LIST_LOAD: "API_ADD_STATE_LIST_LOAD",
  API_ADD_STATE_LIST_SUCCESS: "API_ADD_STATE_LIST_SUCCESS",

  //Edit State
  API_EDIT_STATE_LIST_LOAD: "API_EDIT_STATE_LIST_LOAD",
  API_EDIT_STATE_LIST_SUCCESS: "API_EDIT_STATE_LIST_SUCCESS",

  //Delete State
  API_DELETE_STATE_LIST_LOAD: "API_DELETE_STATE_LIST_LOAD",
  API_DELETE_STATE_LIST_SUCCESS: "API_DELETE_STATE_LIST_SUCCESS",

  // ************ PUSH NOTIFICATIONS ************
  API_PUSH_NOTIFICATIONS_LOAD: "API_PUSH_NOTIFICATIONS_LOAD",
  API_PUSH_NOTIFICATIONS_SUCCESS: "API_PUSH_NOTIFICATIONS_SUCCESS",

  API_PUSH_NOTIFICATIONS_LIST_LOAD: "API_PUSH_NOTIFICATIONS_LIST_LOAD",
  API_PUSH_NOTIFICATIONS_LIST_SUCCESS: "API_PUSH_NOTIFICATIONS_LIST_SUCCESS",

  API_PUSH_NOTIFICATIONS_DETAIL_LOAD: "API_PUSH_NOTIFICATIONS_DETAIL_LOAD",
  API_PUSH_NOTIFICATIONS_DETAIL_SUCCESS: "API_PUSH_NOTIFICATIONS_DETAIL_SUCCESS",

  API_PUSH_NOTIFICATIONS_FAILED: "API_PUSH_NOTIFICATIONS_FAILED",
  API_PUSH_NOTIFICATIONS_ERROR: "API_PUSH_NOTIFICATIONS_ERROR",

  API_PUSH_NOTIFICATIONS_NEW_LIST_LOAD: "API_PUSH_NOTIFICATIONS_NEW_LIST_LOAD",
  API_PUSH_NOTIFICATIONS_NEW_LIST_SUCCESS: "API_PUSH_NOTIFICATIONS_NEW_LIST_SUCCESS",

  API_PUSH_NOTIFICATIONS_NEW_DETAILS_LOAD: "API_PUSH_NOTIFICATIONS_NEW_DETAILS_LOAD",
  API_PUSH_NOTIFICATIONS_NEW_DETAILS_SUCCESS: "API_PUSH_NOTIFICATIONS_NEW_DETAILS_SUCCESS",

  API_PUSH_NOTIFICATIONS_EDIT_LOAD: "API_PUSH_NOTIFICATIONS_EDIT_LOAD",
  API_PUSH_NOTIFICATIONS_EDIT_SUCCESS: "API_PUSH_NOTIFICATIONS_EDIT_SUCCESS",


  // ************ DASHBOARD ************

  // DASHBOARD Fail
  API_DASHBOARD_FAILED: "API_DASHBOARD_FAILED",
  API_DASHBOARD_ERROR: "API_DASHBOARD_ERROR",

  //DASHBOARD List
  API_DASHBOARD_LOAD: "API_DASHBOARD_LOAD",
  API_DASHBOARD_SUCCESS: "API_DASHBOARD_SUCCESS",

  //analytics general data
  API_ANALYTICS_LOAD: "API_ANALYTICS_LOAD",
  API_ANALYTICS_SUCCESS: "API_ANALYTICS_SUCCESS",

  //show analytics region data
  API_ANALYTICS_REGION_LOAD: "API_ANALYTICS_REGION_LOAD",
  API_ANALYTICS_REGION_SUCCESS: "API_ANALYTICS_REGION_SUCCESS",

  //update analytics region data
  API_UPDATE_ANALYTICS_REGION_LOAD: "API_UPDATE_ANALYTICS_REGION_LOAD",
  API_UPDATE_ANALYTICS_REGION_SUCCESS: " API_UPDATE_ANALYTICS_REGION_SUCCESS",

  //analytics terminal data
  API_ANALYTICS_TERMINAL_LOAD: "API_ANALYTICS_TERMINAL_LOAD",
  API_ANALYTICS_TERMINAL_SUCCESS: "API_ANALYTICS_TERMINAL_SUCCESS",

  //update analytics terminal data
  API_UPDATE_ANALYTICS_TERMINAL_LOAD: "API_UPDATE_ANALYTICS_TERMINAL_LOAD",
  API_UPDATE_ANALYTICS_TERMINAL_SUCCESS: "API_UPDATE_ANALYTICS_TERMINAL_SUCCESS",

  //region terminal data
  API_REGION_TERMINAL_LOAD: "API_REGION_TERMINAL_LOAD",
  API_REGION_TERMINAL_SUCCESS: "API_REGION_TERMINAL_SUCCESS",

  //region terminal data show
  API_REGION_TERMINAL_SHOW_LOAD: "API_REGION_TERMINAL_SHOW_LOAD",
  API_REGION_TERMINAL_SHOW_SUCCESS: "API_REGION_TERMINAL_SHOW_SUCCESS",

  //empty region terminal data 
  EMPTY_REGION_TERMINAL_DATA_LOAD: "EMPTY_REGION_TERMINAL_DATA_LOAD",

  API_ANALYTICS_TERMINAL_SUCCESSChange: "API_ANALYTICS_TERMINAL_SUCCESSChange",

  // ************ EMPLOYEE MANAGEMENT ************
  // employee Listing Fail
  API_EMPLOYEE_LIST_MANAGEMENT_FAILED: "API_EMPLOYEE_LIST_MANAGEMENT_FAILED",
  API_EMPLOYEE_LIST_MANAGEMENT_ERROR: "API_EMPLOYEE_LIST_MANAGEMENT_ERROR",

  //Get EMPLOYEE List
  API_GET_EMPLOYEE_LIST_LOAD: "API_GET_EMPLOYEE_LIST_LOAD",
  API_GET_EMPLOYEE_LIST_SUCCESS: "API_GET_EMPLOYEE_LIST_SUCCESS",

  //Get EMPLOYEE Details
  API_GET_EMPLOYEE_LIST_DETAILS_LOAD: "API_GET_EMPLOYEE_LIST_DETAILS_LOAD",
  API_EMPLOYEE_LIST_DETAILS_SUCCESS: "API_EMPLOYEE_LIST_DETAILS_SUCCESS",

  //Add EMPLOYEE
  API_ADD_EMPLOYEE_LIST_LOAD: "API_ADD_EMPLOYEE_LIST_LOAD",
  API_ADD_EMPLOYEE_LIST_SUCCESS: "API_ADD_EMPLOYEE_LIST_SUCCESS",

  //Edit EMPLOYEE
  API_EDIT_EMPLOYEE_LIST_LOAD: "API_EDIT_EMPLOYEE_LIST_LOAD",
  API_EDIT_EMPLOYEE_LIST_SUCCESS: "API_EDIT_EMPLOYEE_LIST_SUCCESS",

  //Delete EMPLOYEE
  API_BLOCK_EMPLOYEE_LIST_LOAD: "API_BLOCK_EMPLOYEE_LIST_LOAD",
  API_BLOCK_EMPLOYEE_LIST_SUCCESS: "API_BLOCK_EMPLOYEE_LIST_SUCCESS",

  //Get Roles
  API_GET_ROLES_LIST_LOAD: "API_GET_ROLES_LIST_LOAD",
  API_GET_ROLES_LIST_SUCCESS: "API_GET_ROLES_LIST_SUCCESS",

  //geenerate password
  API_GENERATE_PASSWORD_LOAD: "API_GENERATE_PASSWORD_LOAD",
  API_GENERATE_PASSWORD_SUCCESS: "API_GENERATE_PASSWORD_SUCCESS",

  //Get event List
  API_GET_EVENT_LIST_LOAD: "API_GET_EVENT_LIST_LOAD",
  API_GET_EVENT_LIST_SUCCESS: "API_GET_EVENT_LIST_SUCCESS",

  // ************ POST MANAGEMENT ************
  // Post Listing Fail
  API_POST_LIST_MANAGEMENT_FAILED: "API_POST_LIST_MANAGEMENT_FAILED",
  API_POST_LIST_MANAGEMENT_ERROR: "API_POST_LIST_MANAGEMENT_ERROR",

  //Get Post List
  API_GET_POST_LIST_LOAD: "API_GET_POST_LIST_LOAD",
  API_GET_POST_LIST_SUCCESS: "API_GET_POST_LIST_SUCCESS",

  //Get Bot users List
  API_GET_BOT_USERS_LIST_LOAD: "API_GET_BOT_USERS_LIST_LOAD",
  API_GET_BOT_USERS_LIST_SUCCESS: "API_GET_BOT_USERS_LIST_SUCCESS",

  //add Bot users 
  API_ADD_BOT_USERS_LOAD: "API_ADD_BOT_USERS_LOAD",
  API_ADD_BOT_USERS_SUCCESS: "API_ADD_BOT_USERS_SUCCESS",

  //Get Post Details
  API_GET_POST_LIST_DETAILS_LOAD: "API_GET_POST_LIST_DETAILS_LOAD",
  API_GET_POST_LIST_DETAILS_SUCCESS: "API_GET_POST_LIST_DETAILS_SUCCESS",

  //Add Post
  API_ADD_POST_LIST_LOAD: "API_ADD_POST_LIST_LOAD",
  API_ADD_POST_LIST_SUCCESS: "API_ADD_POST_LIST_SUCCESS",

  //Edit Post
  API_EDIT_POST_LIST_LOAD: "API_EDIT_POST_LIST_LOAD",
  API_EDIT_POST_LIST_SUCCESS: "API_EDIT_POST_LIST_SUCCESS",

  //Delete Post
  API_DELETE_POST_LIST_LOAD: "API_DELETE_POST_LIST_LOAD",
  API_DELETE_POST_LIST_SUCCESS: "API_DELETE_POST_LIST_SUCCESS",

  //Delete comment
  API_DELETE_COMMENT_LIST_LOAD: "API_DELETE_COMMENT_LIST_LOAD",
  API_DELETE_COMMENT_LIST_SUCCESS: "API_DELETE_COMMENT_LIST_SUCCESS",

  //get users list
  API_GET_USERS_LIST_LOAD: "API_GET_USERS_LIST_LOAD",
  API_GET_USERS_LIST_SUCCESS: "API_GET_USERS_LIST_SUCCESS",

  //share post list
  API_SHARE_POST_LOAD: "API_SHARE_POST_LOAD",
  API_SHARE_POST_SUCCESS: "API_SHARE_POST_SUCCESS",

  //like post
  API_LIKE_POST_LOAD: "API_LIKE_POST_LOAD",
  API_LIKE_POST_SUCCESS: "API_LIKE_POST_SUCCESS",

  //comment post
  API_COMMENT_ON_POST_LOAD: " API_COMMENT_ON_POST_LOAD",
  API_COMMENT_ON_POST_SUCCESS: "API_COMMENT_ON_POST_SUCCESS",

  //like post
  API_LIKE_COMMENT_LOAD: "API_LIKE_COMMENT_LOAD",
  API_LIKE_COMMENT_SUCCESS: "API_LIKE_COMMENT_SUCCESS",

  //edit comment
  API_EDIT_COMMENT_LOAD: "API_EDIT_COMMENT_LOAD",
  API_EDIT_COMMENT_SUCCESS: "API_EDIT_COMMENT_SUCCESS",

  // ************ TERMINAL BOARD ************

  //edit terminal board
  API_EDIT_TERMINAL_BOARD_LOAD: "API_EDIT_TERMINAL_BOARD_LOAD",
  API_EDIT_TERMINAL_BOARD_SUCCESS: "API_EDIT_TERMINAL_BOARD_SUCCESS",

  //default theme
  API_APPLY_DEFAULT_THEME_LOAD: "API_APPLY_DEFAULT_THEME_LOAD",
  API_APPLY_DEFAULT_THEME_SUCCESS: "API_APPLY_DEFAULT_THEME_SUCCESS",

  //default theme edit
  API_EDIT_APPLY_DEFAULT_THEME_LOAD: "API_EDIT_APPLY_DEFAULT_THEME_LOAD",
  API_EDIT_APPLY_DEFAULT_THEME_SUCCESS: "API_EDIT_APPLY_DEFAULT_THEME_SUCCESS",

  //default theme details
  API_DEFAULT_THEME_DETAILS_LOAD: "API_DEFAULT_THEME_DETAILS_LOAD",
  API_DEFAULT_THEME_DETAILS_SUCCESS: "API_DEFAULT_THEME_DETAILS_SUCCESS",

  //failed cases
  API_EDIT_TERMINAL_BOARD_FAILED: "API_EDIT_TERMINAL_BOARD_FAILED",
  API_EDIT_TERMINAL_BOARD_ERROR: "API_EDIT_TERMINAL_BOARD_ERROR",

  // ************ GEOFENCE REGION MANAGEMENT ************

  // geofence region Fail
  API_GEOFENCE_REGION_MANAGEMENT_FAILED: "API_GEOFENCE_REGION_MANAGEMENT_FAILED",
  API_GEOFENCE_REGION_MANAGEMENT_ERROR: "API_GEOFENCE_REGION_MANAGEMENT_ERROR",

  //Get geofence region list
  API_GEOFENCE_REGION_MANAGEMENT_LOAD: "API_GEOFENCE_REGION_MANAGEMENT_LOAD",
  API_GEOFENCE_REGION_MANAGEMENT_SUCCESS: "API_GEOFENCE_REGION_MANAGEMENT_SUCCESS",

  //Get geofence region Details
  API_GET_GEOFENCE_REGION_MANAGEMENT_LOAD: "API_GET_GEOFENCE_REGION_MANAGEMENT_LOAD",
  API_GEOFENCE_REGION_MANAGEMENT_DETAILS_SUCCESS:
    "API_GEOFENCE_REGION_MANAGEMENT_DETAILS_SUCCESS",

  //Add geofence region
  API_ADD_GEOFENCE_REGION_MANAGEMENT_LOAD: "API_ADD_GEOFENCE_REGION_MANAGEMENT_LOAD",
  API_ADD_GEOFENCE_REGION_MANAGEMENT_SUCCESS: "API_ADD_GEOFENCE_REGION_MANAGEMENT_SUCCESS",

  //Edit geofence region
  API_EDIT_GEOFENCE_REGION_MANAGEMENT_LOAD: "API_EDIT_GEOFENCE_REGION_MANAGEMENT_LOAD",
  API_EDIT_GEOFENCE_REGION_MANAGEMENT_SUCCESS: "API_EDIT_GEOFENCE_REGION_MANAGEMENT_SUCCESS",

  //Delete geofence region
  API_DELETE_GEOFENCE_REGION_MANAGEMENT_LOAD: "API_DELETE_GEOFENCE_REGION_MANAGEMENT_LOAD",
  API_DELETE_GEOFENCE_REGION_MANAGEMENT_SUCCESS: "API_DELETE_GEOFENCE_REGION_MANAGEMENT_SUCCESS",

  //Edit Post Management
  API_EDIT_POST_MANAGEMENT_LOAD: "API_EDIT_POST_MANAGEMENT_LOAD",
  API_EDIT_POST_MANAGEMENT_SUCCESS: "API_EDIT_POST_MANAGEMENT_SUCCESS",

  //Edit Post Management
  API_EDIT_POST_DELETE_IMAGE_LOAD: "API_EDIT_POST_DELETE_IMAGE_LOAD",
  API_EDIT_POST_DELETE_IMAGE_SUCCESS: "API_EDIT_POST_DELETE_IMAGE_SUCCESS",

  UPDATE_POST_DATA: "UPDATE_POST_DATA",

  GET_WAIT_TIME_INITIATE: "GET_WAIT_TIME_INITIATE",
  GET_WAIT_TIME_SUCCESS: "GET_WAIT_TIME_SUCCESS",
  GET_WAIT_TIME_FAILURE: "GET_WAIT_TIME_FAILURE",

  UPDATE_WAIT_TIME_INITIATE: "UPDATE_WAIT_TIME_INITIATE",
  UPDATE_WAIT_TIME_SUCCESS: "UPDATE_WAIT_TIME_SUCCESS",
  UPDATE_WAIT_TIME_FAILURE: "UPDATE_WAIT_TIME_FAILURE"

};

export const S3_BUCKET = "fr8.ai-website-dev-content"
export const REGION = "us-east-2"
export const ACCESS_KEY_ID = "AKIA4WINVFQ3BSUKN2UC"
export const SECRET_ACCESS_KEY = "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"
