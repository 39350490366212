import { apiConstants as types } from '../../themes/constants'

// Get terminal area Action
export const getTerminalAreaListAction = (search, offset, limit, sortBy, order) => ({
    type: types.API_GET_TERMINAL_AREA_LIST_LOAD,
    search, offset, limit, sortBy, order
})

// Add terminal area Action
export const addTerminalAreaAction = (terminalAreaName,stateName) => ({
    type: types.API_ADD_TERMINAL_AREA_LOAD,
    terminalAreaName,
    stateName
})

// Get terminal area Action
export const getTerminalAreaDetailsAction = (terminalAreaId) => ({
    type: types.API_GET_TERMINAL_AREA_DETAILS_LOAD,
    terminalAreaId,
})

// Edit terminal area Action
export const editTerminalAreaAction = (terminalAreaId,terminalAreaName,stateName) => ({
    type: types.API_EDIT_TERMINAL_AREA_LOAD,
    terminalAreaId,
    terminalAreaName,
    stateName
})

//  Delete terminal area Action
export const deleteTerminalAreaAction = (terminalAreaId) => ({
    type: types.API_DELETE_TERMINAL_AREA_LOAD,
    terminalAreaId,
})

// Clear terminal board details
export const clearTerminalBoardDetails = () => {
    return {    
        type: "CLEAR_TERMINAL_BOARD_DETAILS",
    }
}
