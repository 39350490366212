import React, { Component, Fragment, useRef } from 'react'
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { appConstants } from '../themes/constants';
import { Spin } from 'antd';
import { toast } from 'react-toastify';

let elements = [];
var loadingStart = false;
let arr = [];
let called=false;

function dataPass(loaderImages) {
    loaderImages(loadingStart)
}

var _this

class TextEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: Math.random(),
            setData: '',
            copyEditor: null,
            images: [],
            isLoading: false,
            charCount: 0,
            
        }
        _this = this
    }

    render() {
        const { value, onChange, placeholder, getData, className, id, onError, passChar,deleteCalled } = this.props

        function delete2(editor) {
            editor.model.on('deleteContent', () => {
                const changes = editor.model.document.differ.getChanges();
                for (const change of changes) {
                    if (change.type == 'remove' && change.name == 'imageBlock') {
                        deleteCalled(called)
                        let deletedImage = change.attributes.get("src")
                        elements.push(deletedImage)
                        console.log('The image has been removed.');
                    }
                };
            });
        }

        const custom_config = {
            extraPlugins: [MyCustomUploadAdapterPlugin, delete2, SpecialCharactersEmoji,],
            placeholder: "Enter News",
            toolbar: ['heading',
                '|',
                'alignment',
                'bold',
                'italic',
                'link',
                'bulletedList',
                'numberedList',
                '|',
                'outdent',
                'indent',
                '|',
                'imageUpload',
                'blockQuote',
                'insertTable',
                'mediaEmbed',
                'undo',
                'redo',
                'fontBackgroundColor',
                'fontColor',
                'fontSize',
                'fontFamily',
                'highlight',
                'horizontalLine',
                'imageInsert',
                'specialCharacters',
                'restrictedEditingException',
                'strikethrough',
                'underline',],
            fontSize: {
                options: [
                    8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36
                ]
            },
            image: {
                toolbar: [
                    'imageStyle:block',
                    'imageStyle:side',
                    'imageStyle:inline',
                    'imageStyle:alignLeft',
                    'imageStyle:alignRight',
                ]
            },
            alignment: {
                toolbar: [
                    'alignment:right',
                    'alignment:left',
                    'alignment:center',
                    'alignment:justify'
                ]
            },
            wordCount: {
                showParagraphs: false,
                displayCharacters: true,
                displayWords: true,
                // countSpacesAsChars: false,
                countHTML: true,
                maxWordCount: 10,
                onUpdate: stats => {
                    this.setState({ charCount: stats.characters })
                    // Prints the current content statistics.
                    // console.log(`Characters: ${stats.characters}\nWords: ${stats.words}`);
                }
            },

            fontFamily: {
                options: [
                    'Serif', 'Sans-serif', 'Cursive', 'Monospace', 'Times New Roman', 'Chilanka', 'Free Mono', 'Georgia',
                    'Garamond', 'Keraleeyam'
                ]
            },
            removePlugins: ['MediaEmbed', 'Link', 'Table', 'BlockQuote',
                'List', 'IndentBlock', 'StandardEditingMode', 'Indent', 'ImageInsert',
                'AutoLink', 'LinkImage', 'Underline', 'HtmlEmbed', 'Strikethrough']
        }

        onTrigger(this.props.getData)
        dataPass(this.props.loaderimages)
        passChar(this.state.charCount)

        return (
            <div className="formView">
                <div className="content-view pt-4">
                    <div style={{ marginBottom: ".8rem" }}>
                        <div className='toolbar_editor'>
                            <CKEditor
                                name='body'
                                id={id}
                                editor={Editor}
                                config={custom_config}
                                data={value}
                                onChange={onChange}
                                onReady={editor => {
                                    this.setState({ copyEditor: editor })
                                }}
                                onError={onError}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        const elements = [];
        editor.model.document.on('change', () => {
            const selectedBlocks = editor.model.document.selection.getSelectedBlocks();
            if (selectedBlocks) {
                for (const block of selectedBlocks) {
                    if (block.name == 'imageBlock' && !elements.includes(block)) {
                        elements.push(block);
                    };
                };
            }
        });
        loader.ckProps = _this?.props
        console.log('uploading done', _this)
        return new MyUploadAdapter(loader)
    }
}

function onTrigger(getData) {
    getData(elements)
}

function SpecialCharactersEmoji(editor) {
    editor.plugins.get('SpecialCharacters').addItems('Emoji', [
        { title: 'smiley face', character: '😊' },
        { title: 'rocket', character: '🚀' },
        { title: 'wind blowing face', character: '🌬️' },
        { title: 'floppy disk', character: '💾' },
        { title: 'heart', character: '❤️' },
        { title: 'Slightly smiling face', character: '🙂' },
        { title: 'Smiling face', character: '😀' },
        { title: 'Smiling face with big eyes', character: '😃' },
        { title: 'Smiling face with smiling eyes', character: '😄' },
        { title: 'Beaming face with smiling eyes', character: '😁' },
        { title: 'Smiling face with tears', character: '😅' },
        { title: 'Rolling on the floor laughing', character: '🤣' },
        { title: 'Lauging with tears', character: '😂' },
        { title: 'Upside down face', character: '🙃' },
        { title: 'Winking face', character: '😉' },
        { title: 'Smiling face with halo', character: '😇' },
        { title: 'Smiling face with sunglasses', character: '😎' },
        { title: 'Nerdy face', character: '🤓' },
        { title: 'Face with monocle', character: '🧐' },
        { title: 'Partying face', character: '🥳' },
        { title: 'Smiling face with hearts', character: '🥰' },
        { title: 'Smiling face with heart eyes', character: '😍' },
        { title: 'Star-struck', character: '🤩' },
        { title: 'Face blowing kiss', character: '😘' },
    ]);
}

class MyUploadAdapter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            images: [],
            isLoading: true
        }
        this.loader = props;
        this.url = `https://dev-api.fr8.ai/api/v7/user/uploadFile`;
    }

    upload() {
        return new Promise((resolve, reject) => {
            this._initRequest();
            this._initListeners(resolve, reject);
            this._sendRequest();
        });
    }

    abort() {
        if (this.xhr) {
            this.xhr.abort();
        }
    }

    _initRequest() {
        const xhr = this.xhr = new XMLHttpRequest();
        xhr.open('POST', this.url, true);
        xhr.responseType = 'json';
        xhr.setRequestHeader('Access-Control-Allow-Origin', '*')
    }

    _initListeners(resolve, reject) {
        const xhr = this.xhr;
        const loader = this.loader;
        const genericErrorText = window.alert = function(message) {
            toast.error("Image is not uploaded to the server. Please try again.")
          }
        xhr.addEventListener( 'error', () => reject(genericErrorText));
        xhr.addEventListener('abort', () => reject())
        xhr.addEventListener('load', () => {
            this.loader.ckProps.loaderStart(false)
            this.loader.ckProps.imageCount(false)
            const response = xhr.response;
            const responseImage = xhr.response.data.key
            arr.push(responseImage);
            this.setState({ images: arr, isLoading: false })
            loadingStart = false
            if ( !response || response.error ) {
                return reject( response && response.error ? response.error.message : '' );
            }
            resolve({
                default: appConstants.baseURL + response.data.key,
            })
            
        });
        if (xhr.upload) {
            xhr.upload.addEventListener('progress', evt => {
                this.loader.ckProps.loaderStart(true)
                // this?.props?.loaderStart(true)
                if (evt.lengthComputable) {
                    loader.uploadTotal = evt.total;
                    loader.uploaded = evt.loaded;
                    loadingStart = true
                    this.setState({ isLoading: true })
                }
            });
        }
    }

    // Prepares the data and sends the request.
    _sendRequest() {
        const data = new FormData();
        this.loader.file.then(result => {
            data.append('upload', result);
            this.xhr.send(data);
        }
        )
    }
}

export default TextEditor;


