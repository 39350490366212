import React, { useState, useEffect, useRef } from 'react';
// Navigation
import { useHistory, useLocation } from 'react-router-dom'
// Styles
import 'antd/dist/antd.css'
import '../dashboard.scss'
// antd
import { Button as AntButton, Modal } from 'antd';
import { Card } from 'react-bootstrap';
import localStorage from "../../utils/localStorage";
// fontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShare } from '@fortawesome/free-solid-svg-icons'
// Mui Components
import { Grid, useTheme, useMediaQuery, Box } from '@material-ui/core';
// Custom components
import DashboardLayout from '../../layouts/dashboardLayout'
import { InputField, AppBar, Button, PaginationBar, Navbar, NavbarSearch, ConfirmationModal, Table } from '../../customComponents'
// Constants
import { AppImages } from '../../themes/appImages'
import { Colors } from '../../themes/colors'
import { appConstants } from '../../themes/constants'
// utils methods
import cl from '../../utils/cl'
import { useStyles } from '../../styles'
// Redux
import { useSelector, useDispatch } from 'react-redux'
import { handleNavigationStateAction, } from '../../store/actions'
import { getRatingListAction, hideDeleteRatingApi } from '../../store/actions/terminalManagementActions';
import moment from 'moment'

export const RatingDetails = () => {
    const styleProps = { searchable: true }
    // Hooks declarations
    const classes = useStyles(styleProps);
    const theme = useTheme();
    const history = useHistory();
    const location = useLocation()
    const dispatch = useDispatch()
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const ratingListData = useSelector(state => state.terminalManagementReducer)
    let siderIndex = localStorage.getLayoutArr()
    // Global state initialization
    let permissionsData = localStorage?.getPermissions()
    let employeeIndex = permissionsData?.findIndex((x) => x.name == "terminal")
    const { ratingListing, ratingPaging, isLoading, } = ratingListData

    // local state initialization
    const [search, setSearch] = useState("")
    const [offset, setOffset] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sortBy, setSortBy] = useState("")
    const [order, setOrder] = useState(null)
    const [RatingToDelete, setRatingToDelete] = useState(null)
    const [disableButton, setDisableButton] = useState()
    const [confirm, setConfirm] = useState(false)
    const [viewReviews, setViewReviews] = useState(false)
    const [reviewData, setReviewData] = useState("")
    const [hideModal, setHideModal] = useState(false)
    const [saveRatingeData, setSaveRatingData] = useState("")
    // Method to fetch listing
    var terminalId = location?.state?.terminalId
    const fetchDataListing = (search, offset, limit, sortBy, order) => {
        dispatch(getRatingListAction({ search, offset, limit, sortBy, order, terminalId }))
    }
    let siderNestedIndex = localStorage.getNestedLayoutArr()
    //lifecycle hooks
    useEffect(() => {
        document.title = appConstants.headerTitle.ratingDetails;
        if (permissionsData) {
            let index = siderIndex?.findIndex((x) => x.name == "Terminal Management")
            let nestedIndex = siderNestedIndex?.findIndex((x) => x.key == "terminal")
            dispatch(handleNavigationStateAction(index, false))
            setTimeout(() => {
                dispatch(handleNavigationStateAction(nestedIndex, true))
            }, 1000);
        }
        else {
            dispatch(handleNavigationStateAction(2, true))
        }
        fetchDataListing(search, offset, limit, sortBy, order)
    }, [])

    useEffect(() => {

    }, [reviewData])
    //Callback method to handle sorting 
    const handleChange = async (pagination, filters, sorter) => {
        //To fetch listing in ascending order
        if (sorter.order == "ascend") {
            setOrder(1)
            setSortBy(sorter.columnKey)
            fetchDataListing(search, offset, limit, sorter.columnKey, 1)
        } //To fetch listing in descending order
        else if (sorter.order === "descend") {
            setOrder(-1)
            setSortBy(sorter.columnKey)
            fetchDataListing(search, offset, limit, sorter.columnKey, -1)
        } //To fetch listing in normal order
        else {
            setOrder(null)
            setSortBy("")
            fetchDataListing(search, offset, limit, "", null)
        }
    }

    // Callback method to handle page chage
    const handlePaginationChange = (e, page) => {
        const offsetTo = (page - 1) * limit
        setOffset(offsetTo)
        fetchDataListing(search, offsetTo, limit, sortBy, order)
    }

    // Callback method to handle searching key
    const handleSearching = async (e) => {
        var tempText = e.target.value
        if (e.target.value.charAt(0) === " ") {
            tempText = e.target.value.replace(/^ +/gm, '');

        }
        setSearch(tempText)
        fetchDataListing(tempText, 0, limit, "", null)
    }
    // +++++++++++

    const columns = [
        {
            title: appConstants.sr,
            dataIndex: '',
            key: 'sr',
            render: (text, record, index) => (index + 1) + (ratingPaging?.page?.nextPage - limit),
            ellipsis: false,
            width: 100,
        },
        {
            title: 'Username',
            dataIndex: 'userName',
            key: 'userName',
            render: (firstName, record) => <div>{record?.user?.userName}</div>,
            ellipsis: false,
            sorter: true,
        },
        {
            title: 'Rating',
            dataIndex: 'rating',
            key: 'rating',
            render: (rating) => rating,
            ellipsis: false,
            sorter: true,
        },
        {
            title: 'Review',
            dataIndex: 'review',
            key: 'review',
            render: (review) => <div style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "350px"
            }}> {review ? review : "N/A"}</div>,
            ellipsis: false,
            sorter: true,
        },
        {
            key: "updated_at",
            title: "Created At",
            dataIndex: "updated_at",
            render: (created_at, record) => {
                return (
                    <div>
                        {moment(record?.updated_at).format('YYYY-MM-DD hh:mm A')}
                    </div>
                )
            }
        },
        {
            title: appConstants.action,
            dataIndex: '',
            key: 'x',
            width: 100,
            align: 'center',
            render: (record) => {
                return (
                    <Grid className={classes.buttons}>
                        <Button title={appConstants.view}
                            onClick={() => {
                                setViewReviews(true)
                                setReviewData(record)
                            }}
                        />
                        <Button title={record?.is_hide == true ? appConstants.unHide : appConstants.hide}
                            onClick={() => {
                                setHideModal(true)
                                setSaveRatingData(record)
                                // dispatch(hideDeleteRatingApi({
                                //     type: "hide",
                                //     status: record?.is_hide == false ? true : false,
                                //     id: record?._id,
                                //     terminalId: terminalId
                                // }))
                            }}
                        />
                        <Button
                            title={appConstants.delete}
                            onClick={() => {
                                setRatingToDelete(record._id)
                                setConfirm(true)
                                setDisableButton(false)
                            }}
                        />
                    </Grid>
                )
            },
        },
    ];

    return (
        <DashboardLayout>
            <ConfirmationModal
                title="Alert"
                text={appConstants.confirmDeleteRating}
                visible={confirm}
                disable={disableButton}
                onCancel={() => {
                    setRatingToDelete(null)
                    setConfirm(false)
                }}
                onDeleteConfirmed={() => {
                    dispatch(hideDeleteRatingApi({
                        type: "delete",
                        status: null,
                        id: RatingToDelete,
                        terminalId: terminalId
                    }))
                    setConfirm(false)
                    setDisableButton(true)
                }}
            />
            <ConfirmationModal
                title="Alert"
                text={`Are you sure, you want to ${saveRatingeData?.is_hide == true ? "unhide" : "hide"} this rating?`}
                visible={hideModal}
                button1Text={saveRatingeData?.is_hide == true ? appConstants.unHide : appConstants.hide}
                // disable={disableButton}
                onCancel={() => {
                    setHideModal(false)
                }}
                onDeleteConfirmed={() => {
                    dispatch(hideDeleteRatingApi({
                        type: "hide",
                        status: saveRatingeData?.is_hide == false ? true : false,
                        id: saveRatingeData?._id,
                        terminalId: terminalId
                    }))
                    setHideModal(false)
                    // setDisableButton(true)
                }}

            />
            <Modal
                onCancel={() => {
                    setViewReviews(false)
                }}
                visible={viewReviews} footer={null} centered maskClosable={false}>
                <div>
                    <div className="reply-modal">
                        <span style={{ color: "#000", fontSize: 20 }}>Rate & Review</span>
                    </div>
                    <div style={{ alignSelf: "center", }}>
                        <span style={{ color: "#000", fontSize: 14 }}>
                            Rating
                        </span>
                        <InputField
                            id="rating"
                            placeholder={"rating"}
                            name="rating"
                            disabled
                            value={reviewData?.rating}
                        />
                    </div>
                    <div style={{ alignSelf: "center", }}>
                        <span style={{ color: "#000", fontSize: 14 }}>
                            Review
                        </span>
                       {reviewData?.review !==null?
                       <textarea
                            placeholder="Enter your review"
                            name="review"
                            value={reviewData?.review}
                            disabled
                            style={{ height: "12rem", resize: "none", width: "94%" }}
                            className="textAreaNew1"
                        />
                    :
                    <span style={{display:"flex",justifyContent:"center",fontWeight:500}}>No Review Found.</span>
                    }
                    </div>
                </div>
            </Modal>
            <AppBar breadcrumbs={[{ route: '/terminals', name: "Terminal Listing" },
            {
                route: "/edit-terminal", name: "Edit Terminal",
                state: { terminalId: location?.state?.terminalId }
            },
            {
                route: '/rating-details', name: "Rating Details",
                state: { terminalId: location?.state?.terminalId, }
            }]} />
            <Navbar title="Rating Details" searchable value={search} onChange={(e) => handleSearching(e)} />
            <Card className={classes.card}>
                {matches &&
                    <Box className={classes.searchBox}>
                        <NavbarSearch value={search} onChange={(e) => handleSearching(e)}
                        />
                    </Box>
                }
                <Grid container>
                    <Grid smUp={12} className={classes.tableContainerRow}>
                        <Table
                            loading={isLoading}
                            columns={columns}
                            dataSource={ratingListing}
                            onChange={handleChange}
                            searching={search.length > 0}
                        />
                    </Grid>
                </Grid>
                <PaginationBar
                    totalCount={ratingPaging?.page?.totalCount}
                    count={Math.ceil(ratingPaging?.page?.totalCount / limit)}
                    currentPage={ratingPaging?.page?.currentPage}
                    nextPage={ratingPaging?.page?.nextPage}
                    onChange={(e, page) => handlePaginationChange(e, page)}
                    prevPage={ratingPaging?.page?.nextPage - limit}
                />
            </Card>
        </DashboardLayout>
    );
}
