import "antd/dist/antd.css";
import "../dashboard.scss";
import React, { useState, useEffect } from "react";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import clsx from "clsx";
import * as Yup from "yup";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import { Tooltip, Spin } from "antd";
import { Card, Table as BSTable } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { Grid, Select, FormHelperText, Collapse, Checkbox } from "@material-ui/core";
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { toast } from "react-toastify";
import GoogleMap from "../../customComponents/googleMap";
import DashboardLayout from "../../layouts/dashboardLayout";
import { InputField, Navbar, AppBar, Button, ConfirmationModal } from "../../customComponents";
import { AppImages } from "../../themes/appImages";
import { appConstants, ValidationConstants } from "../../themes/constants";
import { validateImageRatio, convertPolygonValuesToMap, getCenterAndZoomForBackend } from "../../utils/helpers";
import { useStyles } from "../../styles";
import { editTerminalAction, handleClearStateAction, getTerminalDetailsAction, getTerminalAreaListAction, } from "../../store/actions";
import { clearMapCenterPointAction, deleteGeofence1, successClear } from "../../store/actions/terminalManagementActions";
import GeofenceGoogleMap from "../../customComponents/geofenceGoogleMap";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const EditTerminal = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const geolib = require('geolib');
  const dispatch = useDispatch();

  const { isTableDataLoading, isLoading, success, terminalDetails } = useSelector(
    (state) => state.terminalManagementReducer
  );
  const centerPoint = useSelector(
    (state) => state.terminalManagementReducer.centerPoint
  );

  const { terminalAreaListing } = useSelector(
    (state) => state.terminalAreaManagementReducer
  );

  const [terminalArea, setTerminalArea] = useState("");
  const [terminalName, setTerminalName] = useState("");
  const [locationCategory, setLocationCategory] = useState("");
  const [terminalCountry, setTerminalCountry] = useState("");
  const [terminalAddress, setTerminalAddress] = useState("");
  const [terminalLatitude, setTerminalLatitude] = useState("");
  const [terminalLongitude, setTerminalLongitude] = useState("");
  const [terminalLongitude1, setTerminalLongitude1] = useState("");
  const [terminalCity, setTerminalCity] = useState("");
  const [terminalState, setTerminalState] = useState("");
  const [zipCode, setZipcode] = useState("");
  const [terminalRadius, setTerminalRadius] = useState("");
  const [terminalPolygon, setTerminalPolygon] = useState([]);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [mapLogoUrl, setMapLogoUrl] = useState(null);
  const [mapLogoFile, setMapLogoFile] = useState(null);
  const [openTimePicker, setOpenTimePicker] = useState(false);
  const [closeTimePicker, setCloseTimePicker] = useState(false);
  const [selectedOpenTime, setSelectedOpenTime] = React.useState(null);
  const [selectedCloseTime, setSelectedCloseTime] = React.useState(null);
  const [openTimeError, setOpenTimeError] = useState(null);
  const [closeTimeError, setCloseTimeError] = useState(null);
  const [fullTime, setFullTime] = useState(false);
  const [terminalBoardLogoUrl, setTerminalBoardLogoUrl] = useState(null);
  const [terminalBoardLogoFile, setTerminalBoardLogoFile] = useState(null);
  const [discription, setDiscription] = useState("")
  const [polygonData, setpolygonData] = useState([])
  const [mapSettings, setMapSettings] = useState(null)
  const [isCleard, setIsCleard] = useState(false)
  const [circleZoom, setCircleZoom] = useState(64);
  const [isAllDataFetched, setAllDataFetched] = useState(false);
  const [enabled, setEnabled] = useState(false)
  const [mapDetails, setMapDetails] = useState(null)
  const [visibleMarker, setVisibleMarker] = useState(false)
  const [closeMap, setCloseMap] = useState(false)
  const [geofenceName, setGeofenceName] = useState("")
  const [geofenceErrors, setGeofenceErrors] = useState(null);
  const [radiusErrors, setRadiusErrors] = useState(null)
  const [radius, setRadius] = useState("50")
  var [deleteItem, setDeleteItem] = useState(terminalDetails.geoFence)
  const [deletefence, setDeletefence] = useState(0)
  const [confirm1, setConfirm1] = useState(false)
  var [latitude, setLatitude] = useState("")
  var [longitude, setLongitude] = useState("")
  var [allMarkers, setAllMarkers] = useState([])
  const [templates, setTemplates] = useState(null)
  const [terminalUrlArray, setTerminalUrlArray] = useState([])
  const [phnNumberArray, setPhoneNumberArray] = useState([])
  const [isError, setIsError] = useState(false)
  const [extraTime, setExtraTime] = useState(true)
  const [openExtraTimePicker, setOpenExtraTimePicker] = useState(false);
  const [closeExtraTimePicker, setCloseExtraTimePicker] = useState(false);
  const [showTerminal, setShowTerminal] = useState(true);
  const [about, setAbout] = useState("")
  const [terminalIndex, setTerminalIndex] = useState(0)
  const [extraTimeArray, setExtraTimeArray] = useState([])

  let initialState = {
    points: [],
    coords: [],
    showPoints: [],
    passPoints: []
  }

  const [firstPoint, firstPointDispatch] = React.useReducer((state, action) => {
    switch (action.type) {
      case 'firstPoint':
        return true
      case 'clearPointAll':
        return false
      default:
        return state
    }
  }, false)


  const getSelectedTime = (openTime, closeTime, key) => {
    let newCloseTime = null
    let diff = moment(closeTime).diff(openTime, "minutes")
    if (diff > 0 && diff < 1440) {
      return moment(closeTime).format("YYYY-MM-DDTHH:mm:ss+00:00")

    }
    else if (diff < 0) {
      newCloseTime = moment(closeTime).add(1, "day")
      return moment(newCloseTime).format("YYYY-MM-DDTHH:mm:ss+00:00")
    }
    else if (diff >= 1440) {
      newCloseTime = moment(closeTime).subtract(1, "day")
      return moment(newCloseTime).format("YYYY-MM-DDTHH:mm:ss+00:00")

    }
    // return closeTime
  }

  const [polygonState, pointsDispatch] = React.useReducer((state, action) => {
    switch (action.type) {
      case 'newPoint':
        return {
          ...state,
          points: [...state.points, action.newPoint],
          coords: [...state.coords, action.newCoords],
          showPoints: [...state.showPoints, `(${action.lat.toFixed(8) + "," + action.long.toFixed(8)})`],
          passPoints: [...state.passPoints, action.addNewCoords]
        }
      case 'undoPoint':
        return {
          ...state,
          points: action.newPoint,
          coords: action.newCoords,
          showPoints: action.showPoints,
          passPoints: action.passPoints
        }
      case 'clearPoint':
        return {
          ...state,
          points: [],
          coords: [],
          showPoints: [],
          passPoints: []
        }
      default:
        return { ...state }
    }
  }, initialState)

  const values = {
    terminalArea,
    terminalName,
    locationCategory,
    terminalCountry,
    terminalAddress,
    terminalLatitude,
    terminalLongitude,
    terminalCity,
    terminalState,
    zipCode,
    terminalRadius,
    terminalPolygon,
    extraInformation: "",
    // about
  };


  useEffect(() => {
  }, [values.terminalLongitude])

  const validationSchema = Yup.object().shape({
    terminalName: Yup.string().test(
      "trim",
      ValidationConstants.terminalName.empty,
      (value) => value?.trim()?.length > 0
    ).min(2, ValidationConstants.terminalName.short)
      .max(50, ValidationConstants.terminalName.long)
      .required(ValidationConstants.terminalName.empty)
    ,
    terminalCountry: Yup.string().test(
      "trim",
      ValidationConstants.terminalCountry.empty,
      (value) => value?.trim()?.length > 0
    ).min(2, ValidationConstants.terminalCountry.short)
      .max(20, ValidationConstants.terminalCountry.long)
      .required(ValidationConstants.terminalCountry.empty)
    ,
    terminalAddress: Yup.string().test(
      "trim",
      ValidationConstants.terminalAddress.empty,
      (value) => value?.trim()?.length > 0
    ).min(2, ValidationConstants.terminalAddress.short)
      .max(255)
      .required(ValidationConstants.terminalAddress.empty)
    ,
    terminalCity: Yup.string()
      .test(
        "trim",
        ValidationConstants.terminalCity.empty,
        (value) => value?.trim()?.length > 0
      )
      .min(2, ValidationConstants.terminalCity.short)
      .max(20, ValidationConstants.terminalCity.long)
      .required(ValidationConstants.terminalCity.empty)
    ,
    terminalState: Yup.string()
      .test(
        "trim",
        ValidationConstants.terminalState.empty,
        (value) => value?.trim()?.length > 0
      )
      .min(2, ValidationConstants.terminalState.short)
      .max(20, ValidationConstants.terminalState.long)
      .required(ValidationConstants.terminalState.empty)
    ,
    zipCode: Yup.string()
      // .matches(/^[a-zA-Z0-9_]*$/, "Please enter valid zip code.")
      .test(
        "trim",
        ValidationConstants.zipCode.empty,
        (value) => value?.trim()?.length > 0
      )
      .min(5, ValidationConstants.zipCode.short)
      .max(12, ValidationConstants.zipCode.long)
      .required(ValidationConstants.zipCode.empty)
    ,
    terminalLatitude: Yup.string()
      .matches(
        /^-?(?:90(?:(?:\.0{1,8})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,8})?))$/,
        ValidationConstants.terminalLatitude.invalid
      )
      .required(ValidationConstants.terminalLatitude.empty)
      .test(
        "trim",
        ValidationConstants.terminalLatitude.empty,
        (value) => value?.trim()?.length > 0
      ),
    terminalLongitude: Yup.string()
      .matches(
        /^(\+|-)?(?:180(?:(?:\.0{1,8})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,8})?))$/,
        ValidationConstants.terminalLongitude.invalid
      )
      .required(ValidationConstants.terminalLongitude.empty)
      .test(
        "trim",
        ValidationConstants.terminalLongitude.empty,
        (value) => value?.trim()?.length > 0
      ),
    terminalRadius: Yup.string()
      .matches(/^[0-9]+$/, ValidationConstants.terminalRadius.invalid)
      .required(ValidationConstants.terminalRadius.empty)
      .test(
        "trim",
        ValidationConstants.terminalRadius.empty,
        (value) => value?.trim()?.length > 0
      ),
    terminalArea: Yup.string().required(ValidationConstants.terminalArea.empty),
    locationCategory: Yup.string().required(
      ValidationConstants.locationCategory.empty
    ),
    terminalCity: Yup.string(),
    // about: Yup.string()
    // .required(ValidationConstants.aboutUs.empty)
    //   .test(
    //     "trim",
    //     ValidationConstants.aboutUs.empty,
    //     (value) => value?.trim()?.length > 0
    //   )
    //   .max(500, ValidationConstants.aboutUs.long)
    //   .min(2, ValidationConstants.aboutUs.short).nullable()
      
  });

  const onMapClicked = async (mapProps, map, e) => {
    latitude = e.latLng.lat()
    longitude = e.latLng.lng()
    let lat = e.latLng.lat()
    let long = e.latLng.lng()
    let mapDetails1 = {
      lat: latitude,
      lng: longitude,
    }
    if (terminalPolygon.length === 0) {
      pointsDispatch({
        type: 'newPoint',
        newPoint: { lat: e.latLng.lat(), lng: e.latLng.lng() },
        newCoords: [e.latLng.lat(), e.latLng.lng()],
        addNewCoords: { latitude: e.latLng.lat(), longitude: e.latLng.lng() },
        lat: lat,
        long: long,
      })
    }
    setMapDetails(mapDetails1);
    setLatitude(latitude)
    setLongitude(longitude)
  };

  const onGeofenceClicked = async (mapProps, map, e) => {

    latitude = e.latLng.lat()
    longitude = e.latLng.lng()
    let lat = e.latLng.lat()
    let long = e.latLng.lng()
    let mapDetails1 = {
      lat: latitude,
      lng: longitude,
    }
    if (terminalPolygon.length === 0) {
      pointsDispatch({
        type: 'newPoint',
        newPoint: { lat: e.latLng.lat(), lng: e.latLng.lng() },
        newCoords: [e.latLng.lat(), e.latLng.lng()],
        addNewCoords: { latitude: e.latLng.lat(), longitude: e.latLng.lng() },
        lat: lat,
        long: long,
      })
    }
    setMapDetails(mapDetails1);
    setLatitude(latitude)
    setLongitude(longitude)
    setVisibleMarker(true)
    setRadius(radius)
  }

  const onUndoClick = (values) => {
    setTerminalArea(values?.terminalArea);
    setTerminalName(values?.terminalName);
    setLocationCategory(values?.locationCategory);
    setTerminalCountry(values?.terminalCountry);
    setTerminalAddress(values?.terminalAddress);
    setTerminalLatitude(values?.terminalLatitude);
    // setTerminalLongitude(values?.terminalLongitude);
    setTerminalLongitude(terminalLongitude1);
    setTerminalCity(values?.terminalCity);
    setTerminalState(values?.terminalState);
    setZipcode(values?.zipCode);
    setTerminalRadius(values?.terminalRadius);
    setTerminalLatitude(values?.terminalLatitude)
    // setTerminalLongitude(values?.terminalLongitude)
    setTerminalLongitude(terminalLongitude1)
    pointsDispatch({
      type: 'undoPoint',
      newPoint: polygonState.points.splice(0, polygonState.points.length - 1),
      newCoords: polygonState.coords.splice(0, polygonState.coords.length - 1),
      showPoints: polygonState.showPoints.splice(0, polygonState.showPoints.length - 1),
      passPoints: polygonState.passPoints.splice(0, polygonState.passPoints.length - 1),
    })
    firstPointDispatch({ type: 'clearPointAll' })
    setTerminalPolygon([])
    setAllMarkers([])
    setpolygonData([])
    setEnabled(false)
  };

  const onClearClicked = async (values) => {
    pointsDispatch({
      type: 'clearPoint',
    })
    firstPointDispatch({
      type: 'clearPointAll',
    })

    setpolygonData([])
    setTerminalPolygon([])
    setAllMarkers([])
    setTerminalArea(values?.terminalArea);
    setTerminalName(values?.terminalName);
    setLocationCategory(values?.locationCategory);
    setTerminalCountry(values?.terminalCountry);
    setTerminalAddress(values?.terminalAddress);
    setTerminalLatitude(values?.terminalLatitude);
    // setTerminalLongitude(values?.terminalLongitude);
    setTerminalLongitude(terminalLongitude1);
    setTerminalCity(values?.terminalCity);
    setTerminalState(values?.terminalState);
    setZipcode(values?.zipCode);
    setTerminalRadius(values?.terminalRadius);
    setTerminalLatitude(values?.terminalLatitude)
    // setTerminalLongitude(values?.terminalLongitude)
    setTerminalLongitude(terminalLongitude1)
    setIsCleard(true)
    dispatch(deleteGeofence1([]))

    // setAllMarkers([])
    setEnabled(false)
  };

  const onCircleClicked = (props, values) => {
    if (props.center === polygonState.points[0]) {
      const lat = polygonState.passPoints[0].latitude
      const lng = polygonState.passPoints[0].longitude

      pointsDispatch({
        type: 'newPoint',
        newPoint: { lat: lat, lng: lng },
        newCoords: [lat, lng],
        addNewCoords: { latitude: lat, longitude: lng },
        lat: lat,
        long: lng,
      })

      firstPointDispatch({
        type: 'firstPoint',
      })
      setTerminalArea(values?.terminalArea);
      setTerminalName(values?.terminalName);
      setLocationCategory(values?.locationCategory);
      setTerminalCountry(values?.terminalCountry);
      setTerminalAddress(values?.terminalAddress);
      setTerminalLatitude(values?.terminalLatitude);
      // setTerminalLongitude(values?.terminalLongitude);
      setTerminalLongitude(terminalLongitude);
      setTerminalCity(values?.terminalCity);
      setTerminalState(values?.terminalState);
      setZipcode(values?.zipCode);
      setTerminalRadius(values?.terminalRadius);
      setTerminalLatitude(values?.terminalLatitude)
      // setTerminalLongitude(values?.terminalLongitude)
      setTerminalLongitude(terminalLongitude1)
      setpolygonData([...polygonState.passPoints, polygonState.passPoints[0]])
      setTerminalPolygon([...polygonState.showPoints, polygonState.showPoints[0]])
    }
  }
  // Lifecycle Hooks
  useEffect(() => {
    document.title = appConstants.headerTitle.editTerminal;
    let onEffect = async () => {
      await dispatch(handleClearStateAction("clearTerminalDetailsState"));
      await dispatch(getTerminalAreaListAction("", null, null, "", ""));
      if (location?.state?.terminalId) {
        await dispatch(getTerminalDetailsAction(location?.state?.terminalId));
      } else {
        history.push("/terminals");
      }
    };
    onEffect();
    return () => {
      setMapSettings(null)
      setAllDataFetched(false)
      dispatch(clearMapCenterPointAction())
    }
  }, []);

  useEffect(() => {
    setTerminalLongitude1(terminalDetails?.longitude)

    let polygonValueToShow =
      terminalDetails?.polygon_area === "undefined" ||
        terminalDetails?.polygon_area === undefined ||
        terminalDetails?.polygon_area === null ||
        terminalDetails?.polygon_area === "null"
        ? []
        : convertPolygonValuesToMap(terminalDetails?.polygon_area);
    if (terminalDetails?.geoFence === "undefined" ||
      terminalDetails?.geoFence === undefined ||
      terminalDetails?.geoFence === null ||
      terminalDetails?.geoFence === "null") {
      setAllMarkers([])
    }
    if (terminalDetails?.terminal_location !== undefined) {
      let mapDetails1 = {
        lat: terminalDetails.latitude,
        lng: terminalDetails.longitude,
      }
      let newArrayImages = [...terminalUrlArray]

      if (terminalDetails?.terminal_url?.length == 0) {
        newArrayImages.push({
          value: "",
          isError: null
        })
      }

      terminalDetails?.terminal_url?.map((x, index) => {
        newArrayImages.push({
          value: x,
          isError: null
        })
      })
      let phoneNumberArray = [...phnNumberArray]
      // if (terminalDetails?.phone?.length == 0) {
      //   phoneNumberArray.push({
      //     value: "",
      //     errorValue: null
      //   })
      // }

      terminalDetails?.phone?.map((x, index) => {
        phoneNumberArray.push({
          value: x.phone,
          errorValue: null,
          name: x.name,
          nameError: null
        })
      })


      // if (terminalDetails?.extraTime?.length == 0) {

      //   newExtraTimeArray.push({
      //     reason: "",
      //     openTime: null,
      //     closeTime: null,
      //     reasonError: null,
      //     openTimeerror: null,
      //     closeTimeError: null
      //   })
      // }
      let newExtraTimeArray = extraTimeArray ? [...extraTimeArray] : []
      if (terminalDetails?.extraTime?.length > 0) {
        terminalDetails?.extraTime?.map((x, index) => {
          newExtraTimeArray.push({
            reason: x.reason,
            openTime: x.openTime,
            closeTime: x.closeTime,
            reasonError: null,
            openTimeerror: null,
            closeTimeError: null
          })
        })
      }

      setMapDetails(mapDetails1)
      setLatitude(terminalDetails?.latitude)
      setLongitude(terminalDetails?.longitude)
      setTemplates(terminalDetails?.terminal_image_type)
      setTerminalUrlArray(newArrayImages)
      setPhoneNumberArray(phoneNumberArray)
      setExtraTimeArray(newExtraTimeArray ? newExtraTimeArray : [])
      setAbout(terminalDetails?.aboutUs)
      terminalDetails?.terminal_logo
        ? setImageUrl(appConstants.baseURL+ terminalDetails?.terminal_logo)
        : setImageUrl(null);
      terminalDetails?.terminal_logo
        ? setImageFile(terminalDetails?.terminal_logo)
        : setImageFile(null);
      terminalDetails?.map_logo
        ? setMapLogoUrl(appConstants.baseURL+ terminalDetails?.map_logo)
        : setMapLogoUrl(null);
      terminalDetails?.map_logo
        ? setMapLogoFile(terminalDetails?.map_logo)
        : setMapLogoFile(null);
      terminalDetails?.terminal_board_logo
        ? setTerminalBoardLogoUrl(
          appConstants.baseURL + terminalDetails?.terminal_board_logo
        )
        : setTerminalBoardLogoUrl(null);
      terminalDetails?.terminal_board_logo
        ? setTerminalBoardLogoFile(terminalDetails?.terminal_board_logo)
        : setTerminalBoardLogoFile(null);
      setFullTime(terminalDetails?.is24Available || false);
      setTerminalArea(terminalDetails?.region_id?._id);
      setTerminalName(terminalDetails?.terminal_name);
      setLocationCategory(terminalDetails?.terminal_category);
      setTerminalCountry(terminalDetails?.country);
      setTerminalAddress(terminalDetails?.terminal_location);
      setTerminalLatitude(terminalDetails.latitude);
      setTerminalLongitude(terminalDetails.longitude);
      setTerminalCity(terminalDetails.city);
      setTerminalState(terminalDetails.state_province);
      setZipcode(terminalDetails.zipcode);
      setTerminalRadius(terminalDetails.radius);
      setShowTerminal(terminalDetails?.isShow || false);
      setTerminalPolygon(polygonValueToShow);
      setpolygonData(polygonValueToShow.length > 0 ? JSON.parse(terminalDetails?.polygon_area) : []);
      setSelectedOpenTime(
        terminalDetails.open_time ?
          moment(terminalDetails.open_time).utc().format("YYYY-MM-DDTHH:mm:ss")
          :
          null
      );
      setSelectedCloseTime(
        terminalDetails.close_time ?
          moment(terminalDetails.close_time).utc().format("YYYY-MM-DDTHH:mm:ss")
          :
          null
      );
      setDiscription(terminalDetails.terminal_description ? terminalDetails.terminal_description : "")
      setLongitude(terminalDetails.longitude)
      setLatitude(terminalDetails.latitude)
      if (polygonValueToShow.length > 0) {
        pointsDispatch({
          type: 'clearPoint',
        })
        firstPointDispatch({
          type: 'clearPointAll',
        })
        var result = ""
        var latlong = []
        var lat = 0.0
        var lng = 0.0

        for (var item of polygonValueToShow) {
          result = item.substring(1, item.length - 1);
          latlong = result.split(",")
          lat = parseFloat(latlong[0])
          lng = parseFloat(latlong[1])

          pointsDispatch({
            type: 'newPoint',
            newPoint: { lat: lat, lng: lng },
            newCoords: [lat, lng],
            addNewCoords: { latitude: lat, longitude: lng },
            lat: lat,
            long: lng,
          })
          firstPointDispatch({
            type: 'firstPoint',
          })
        }
      }
      else {
        pointsDispatch({
          type: 'clearPoint',
        })
        firstPointDispatch({
          type: 'clearPointAll',
        })
      }
      setAllDataFetched(true)
      setAllMarkers(terminalDetails.geoFence)
      setDeleteItem(terminalDetails.geoFence)
      setExtraTimeArray(terminalDetails?.extraTime)
    }
  }, [terminalDetails]);

  useEffect(() => {
    setTerminalUrlArray([])
    setPhoneNumberArray([])
    dispatch(successClear())
  }, [success])

  //Callback methods
  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImageFile(event.target.files[0]);
      validateImageRatio(event.target.files[0])
        .then(() => {
          let reader = new FileReader();
          reader.onload = (e) => {
            setImageUrl(e.target.result);
          };
          reader.readAsDataURL(event.target.files[0]);
        })
        .catch(() => toast.error(ValidationConstants.image.invalidRatio));
    }
  };

  const handleMapLogoChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      validateImageRatio(event.target.files[0])
        .then(() => {
          setMapLogoFile(event.target.files[0]);
          let reader = new FileReader();
          reader.onload = (e) => {
            setMapLogoUrl(e.target.result);
          };
          reader.readAsDataURL(event.target.files[0]);
        })
        .catch(() => toast.error(ValidationConstants.image.invalidRatio));
    }
  };

  const handleTerminalBoardLogoChange = (event) => {
   
    if (event.target.files && event.target.files[0]) {
      validateImageRatio(event.target.files[0])
        .then(() => {
          setTerminalBoardLogoFile(event.target.files[0]);
         
          let reader = new FileReader();
          reader.onload = (e) => {
            setTerminalBoardLogoUrl(e.target.result);
          };
          reader.readAsDataURL(event.target.files[0]);
        })
        .catch(() => toast.error(ValidationConstants.image.invalidRatio));
    }
  };

  const handleFormSubmit = (values, { setSubmitting }) => {
    let errorp = false;
    // let errorExtraTime = false;
    if (values.terminalPolygon.length > 0) {
      const {
        terminalArea,
        terminalName,
        locationCategory,
        terminalCountry,
        terminalAddress,
        terminalLatitude,
        terminalLongitude,
        terminalCity,
        terminalState,
        zipCode,
        terminalRadius,
        terminalPolygon,
      } = values;

      let termianlPolygonValue = polygonData.length > 0 ? polygonData : []
      let d = [...extraTimeArray];
      let fil = d.filter((i, k) => i.reasonError != null || i.closeTimeError != null || i.openTimeerror != null)
      if (fil.length > 0) {
        return false;
      }
      let phoneNumbers = []
      phnNumberArray.map((x, index) => {
        if((x.name&&x.value!==undefined)){
        var b = phnNumberArray.some((y, indexx) => y.value.trim() == x.value.trim() && index !== indexx)
        if (b == true) {
          toast.error(appConstants.duplicateNumber, { toastId: "est" })
          errorp = true
          return false
        }
      }
        if (x.value !== '') {
          if (!x.errorValue && !x.nameError) {
            phoneNumbers.push({phone:x.value,name:x.name})
          }
          else {
            setIsError(true)
          }
        }
      })
      if (phoneNumbers.length == 0 || phnNumberArray.length != phoneNumbers.length) {
        phnNumberValidations()
      }
      if (!fullTime) {
        if (selectedOpenTime == null) {
          setOpenTimeError(ValidationConstants.terminalOpenTime.empty);
        } else if (selectedCloseTime == null) {
          setCloseTimeError(ValidationConstants.terminalCloseTime.empty);
        }
        else if (openTimeError == null && closeTimeError == null) {
          if (fullTime == true) {
            selectedOpenTime(null);
            selectedCloseTime(null);
          }
          setOpenTimeError(null);
          setCloseTimeError(null);
          setSubmitting(false);
          if (termianlPolygonValue.length == 0) {
            toast.error("Please create terminal polygon on map", { toastId: "est" });
          } else {
            let data = getCenterAndZoomForBackend(termianlPolygonValue)
            let newExtraTime = []
            extraTimeArray.map((i, k) => {
              let b = d.some((y, indexx) => {
                let time1 = moment(i.openTime).format("hh:mm")
                let time2 = moment(y.openTime).format("hh:mm")
                return (time1 == time2 && k !== indexx)
              })
              // if (b == true) {
              //   toast.error("Open time and close time should be unique.", { toastId: "est" })
              //   errorExtraTime = true;
              //   return false;
              // }
              if (!i.reasonError && !i.closeTimeError && !i.openTimeerror) {
                if (i.reason.length > 0 && i.closeTime !== null && i.openTime !== null) {
                  newExtraTime.push({ reason: i.reason?.trim(), openTime: i.openTime, closeTime: i.closeTime })
                }

              }

            })
            if (templates == "url") {
              let urls = [];
              terminalUrlArray.map((x) => {
                if (x.value != "") {
                  if (!x.isError) {
                    urls.push(x.value);
                  }
                  else {
                    setIsError(true)
                  }
                }
              })
              if (urls.length == 0 || terminalUrlArray.length != urls.length) {
                urlValidations()
              }
              // if (phoneNumbers.length == 0 || phnNumberArray.length != phoneNumbers.length) {
              //   phnNumberValidations()
              // }
              if ((terminalUrlArray.length == urls.length) && (phnNumberArray.length == phoneNumbers.length)) {
                // getSelectedTime(selectedOpenTime,selectedCloseTime,"closeTime")
                if (errorp == false ) {
                  dispatch(
                    editTerminalAction(
                      location?.state?.terminalId,
                      imageFile,
                      urls,
                      terminalArea,
                      terminalName.trim(),
                      locationCategory,
                      terminalCountry.trim(),
                      terminalAddress.trim(),
                      terminalLatitude,
                      // terminalLongitude,
                      terminalLongitude1,
                      terminalCity.trim(),
                      terminalState.trim(),
                      zipCode.trim(),
                      terminalRadius.trim(),
                      selectedOpenTime
                        ? moment(selectedOpenTime).format("YYYY-MM-DDTHH:mm:ss+00:00")
                        : selectedOpenTime,
                      selectedCloseTime
                        ?
                        //  moment(selectedCloseTime).format(
                        //   "YYYY-MM-DDTHH:mm:ss+00:00"
                        // )
                        getSelectedTime(selectedOpenTime, selectedCloseTime, "closeTime")
                        : selectedCloseTime,
                      fullTime,
                      mapLogoFile,
                      JSON.stringify(termianlPolygonValue),
                      terminalBoardLogoFile,
                      discription,
                      allMarkers,
                      data?.zoomLevel,
                      templates,
                      phoneNumbers,
                      fullTime ? [] : newExtraTime,
                      about?about.trim():"",
                      showTerminal
                    )
                  );
                }
              }
            }
            // if (phoneNumbers.length == 0 || phnNumberArray.length != phoneNumbers.length) {
            //   phnNumberValidations()
            // }
            else if (phnNumberArray.length == phoneNumbers.length) {
              // getSelectedTime(selectedOpenTime,selectedCloseTime,"closeTime")
              if (errorp == false ) {
                dispatch(
                  editTerminalAction(
                    location?.state?.terminalId,
                    imageFile,
                    [],
                    terminalArea,
                    terminalName.trim(),
                    locationCategory,
                    terminalCountry.trim(),
                    terminalAddress.trim(),
                    terminalLatitude,
                    // terminalLongitude,
                    terminalLongitude1,
                    terminalCity.trim(),
                    terminalState.trim(),
                    zipCode.trim(),
                    terminalRadius.trim(),
                    selectedOpenTime
                      ? moment(selectedOpenTime).format("YYYY-MM-DDTHH:mm:ss+00:00")
                      : selectedOpenTime,
                    selectedCloseTime
                      ?
                      getSelectedTime(selectedOpenTime, selectedCloseTime, "closeTime")
                      // moment(selectedCloseTime).format(
                      //   "YYYY-MM-DDTHH:mm:ss+00:00"
                      // )
                      : selectedCloseTime,
                    fullTime,
                    mapLogoFile,
                    JSON.stringify(termianlPolygonValue),
                    terminalBoardLogoFile,
                    discription,
                    allMarkers,
                    data?.zoomLevel,
                    templates,
                    phoneNumbers,
                    fullTime ? [] : newExtraTime,
                    about?about.trim():"",
                    showTerminal
                  )
                )
              }
            }
          }
        }
      } else {
        setOpenTimeError(null);
        setCloseTimeError(null);
        setSubmitting(false);
        if (termianlPolygonValue.length == 0) {
          toast.error("Please create terminal polygon on map", { toastId: "est" });
        }
        else {
          let data = getCenterAndZoomForBackend(termianlPolygonValue)
          let phoneNumbers = []
          let newExtraTime = []
          phnNumberArray.map((x, index) => {
            let b = phnNumberArray.some((y, indexx) => y.value == x.value && index !== indexx)
            if (b == true) {
              toast.error(appConstants.duplicateNumber, { toastId: "est" })
              errorp = true
              return false
            }
            if (x.value !== '') {
              if (!x.errorValue && !x.nameError) {
                phoneNumbers.push({phone:x.value,name:x.name})
              }
              else {
                setIsError(true)
              }
            }
          })
          extraTimeArray.map((i, k) => {
            if (!i.reasonError && !i.closeTimeError && !i.openTimeerror) {
              if (i.reason.length > 0 && i.closeTime !== null && i.openTime !== null) {
                newExtraTime.push({ reason: i.reason?.trim(), openTime: i.openTime, closeTime: i.closeTime })
              }
            }
          })
          if (templates == "url") {
            let urls = [];
            terminalUrlArray.map((x) => {
              if (x.value != "") {
                if (!x.isError) {
                  urls.push(x.value);

                }
                else {
                  setIsError(true)
                }
              }
            })

            if (urls.length == 0 || terminalUrlArray.length != urls.length) {
              urlValidations()
            }
            if (phoneNumbers.length == 0 || phnNumberArray.length != phoneNumbers.length) {
             
              phnNumberValidations()
            }
            if ((terminalUrlArray.length == urls.length) && (phnNumberArray.length == phoneNumbers.length)) {
              if (errorp == false) {
                dispatch(
                  editTerminalAction(
                    location?.state?.terminalId,
                    imageFile,
                    urls,
                    terminalArea,
                    terminalName.trim(),
                    locationCategory,
                    terminalCountry.trim(),
                    terminalAddress.trim(),
                    terminalLatitude,
                    // terminalLongitude,
                    terminalLongitude1,
                    terminalCity.trim(),
                    terminalState.trim(),
                    zipCode.trim(),
                    terminalRadius.trim(),
                    selectedOpenTime
                      ? moment(selectedOpenTime).format("YYYY-MM-DDTHH:mm:ss+00:00")
                      : selectedOpenTime,
                    selectedCloseTime
                      ?
                      getSelectedTime(selectedOpenTime, selectedCloseTime, "closeTime")
                      // moment(selectedCloseTime).format("YYYY-MM-DDTHH:mm:ss+00:00")
                      : selectedCloseTime,
                    fullTime,
                    mapLogoFile,
                    JSON.stringify(termianlPolygonValue),
                    terminalBoardLogoFile,
                    discription,
                    allMarkers,
                    data?.zoomLevel,
                    templates,
                    phoneNumbers,
                    fullTime ? [] : newExtraTime,
                    about?about.trim():"",
                    showTerminal
                  )
                );
              }
            }
          }
          else if (phnNumberArray.length == phoneNumbers.length) {
            if (errorp == false) {
              dispatch(
                editTerminalAction(
                  location?.state?.terminalId,
                  imageFile,
                  [],
                  terminalArea,
                  terminalName.trim(),
                  locationCategory,
                  terminalCountry.trim(),
                  terminalAddress.trim(),
                  terminalLatitude,
                  // terminalLongitude,
                  terminalLongitude1,
                  terminalCity.trim(),
                  terminalState.trim(),
                  zipCode.trim(),
                  terminalRadius.trim(),
                  selectedOpenTime
                    ? moment(selectedOpenTime).format("YYYY-MM-DDTHH:mm:ss+00:00")
                    : selectedOpenTime,
                  selectedCloseTime
                    ?
                    getSelectedTime(selectedOpenTime, selectedCloseTime, "closeTime")
                    // moment(selectedCloseTime).format("YYYY-MM-DDTHH:mm:ss+00:00")
                    : selectedCloseTime,
                  fullTime,
                  mapLogoFile,
                  JSON.stringify(termianlPolygonValue),
                  terminalBoardLogoFile,
                  discription,
                  allMarkers,
                  data?.zoomLevel,
                  templates,
                  phoneNumbers,
                  fullTime ? [] : newExtraTime,
                  about?about.trim():"",
                  showTerminal
                )
              );
            }
          }
        }
      }
    } else {
      toast.error("Please create terminal polygon on map", { toastId: "est" });
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const checklength = (value) => {
    for (let i in value) {
      if (value[i].length > 25) {
        return true
      }
    }
    return false
  }

  const onchangeData = (value) => {
    let data = ""
    if (value?.length > 0) {
      data = value
      let newArr = data.split("\n")
      if (newArr.length > 4) {
        toast.error("Maximum four lines are allowed for description", { toastId: 9852 || "est" })
        return
      }
      else if (checklength(newArr)) {
        toast.error("Maximum 25 characters are allowed in a single line. You can write 4 lines for the description.", { toastId: 9855 || "est" })
        return
      }
      else {
        setDiscription(value)
      }
    }
    else {
      setDiscription(value)
    }
  }

  const geofenceButton = () => {
    if (enabled == true) {
      toast.error("Geofence map is already open.", { toastId: "est" })
    }
    else {
      setEnabled(true)
      setCloseMap(false)
      setGeofenceName()
      setRadius()
    }
  }

  const onSaveClicked = () => {
    var isPolygon = geolib?.isPointInPolygon({ lat: Number(mapDetails?.lat)?.toFixed(8), lng: Number(mapDetails?.lng)?.toFixed(8) }, polygonState?.passPoints);
    const regexp = /^\S*$/;
    if ((geofenceName == undefined || geofenceName == null || geofenceName?.length == 0)) {
      setGeofenceErrors("Please enter name.")
      return false;
    } else if (geofenceName?.length < 3) {
      setGeofenceErrors("Name should be minimum 3 characters long.")
      return false;
    }
    // else if (!regexp.test(geofenceName)) {
    //   setGeofenceErrors("Space is not allowed.")
    //   return false;

    // }

    if ((radius == undefined || radius == null || radius?.length == 0)) {
      setRadiusErrors("Please enter radius.")
    } else if (radius == 0) {
      setRadiusErrors("Radius should be greater than 0.")
    }
    else {
      if (isPolygon) {
        let obj = {
          lat: mapDetails?.lat,
          lng: mapDetails?.lng,
          name: geofenceName.trim(),
          radius: parseFloat(radius)
        }
        let arr = terminalDetails?.geoFence;
        arr.push(obj);
        setEnabled(false)
        setAllMarkers(arr)
        setCloseMap(!closeMap)
        setTimeout(() => {
          console.log(allMarkers, 'allmarkers');
        }, 1000);
      }
      else {
        toast.error("Geofence pin must be inside the polygon.", { toastId: "est" })
      }
      return false;
    }
  }

  const deleteGeofence = (index) => {
    setDeletefence(index)
  }

  const deleteButton = () => {
    let delete_Item = terminalDetails.geoFence;
    delete_Item = delete_Item.filter((x, index) => index !== deletefence)
    setAllMarkers(delete_Item)
    dispatch(deleteGeofence1(delete_Item))

  }

  const numberValue = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    if (value) {
      setRadius(value);
      setRadiusErrors("")
    }
    else {
      setRadius(value);
      setRadiusErrors("Please enter radius.")
    }

  };

  const mapClose = () => {
    setCloseMap(!closeMap)
    setEnabled(false)
  }

  const selectOne = (e) => {
    setTemplates(e.target.value)
  }

  const addterminalUrl = () => {
    let obj = {
      value: "",
      isError: null
    }
    let arr = [...terminalUrlArray];
    arr.push(obj);
    setTerminalUrlArray(arr)
  }

  const cancelTerminalUrl = (indexx) => {
    let arr = [...terminalUrlArray];
    arr = arr.filter((x, index) => index !== indexx)
    setTerminalUrlArray(arr);
  }

  const cancelPhoneNumber = (indexx) => {
    let arr = [...phnNumberArray];
    arr = arr.filter((x, index) => index !== indexx)
    setPhoneNumberArray(arr);
  }

  const urlValidations = () => {
    var regex = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i
    var imageRegex = /(http[s]?:\/\/.*\.(?:png|jpg|jpeg))$/
    let arr = [...terminalUrlArray];
    for (let i in arr) {
      if (arr[i]?.value?.length < 1) {
        arr[i].isError = 'Please enter URL.'
        // setIsError(true)
      }
      else if (!regex.test(arr[i]?.value)) {
        arr[i].isError = 'Please enter valid URL.'
        // setIsError(true)
      }
      // else if (!imageRegex.test(arr[i]?.value)) {
      //   arr[i].isError = 'Only .jpg, .jpeg, .png format image URL allowed.'
      //   // setIsError(true)
      // }
      else {
        arr[i].isError = null
        // setIsError(false)
      }
    }
    setTerminalUrlArray(arr)
    return false
  }

  const onChangeTerminalUrl = (value, index) => {
    if (value !== ' ') {
      let arrayDefault = [...terminalUrlArray]
      arrayDefault.map((x, index2) => {
        if (index == index) {
          arrayDefault[index].value = value.replace(/\s/g, '')
        }
      })
      setTerminalUrlArray(arrayDefault)
    }
    urlValidations()
  }

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  const addPhoneNumber = () => {
    let arr = [...phnNumberArray]
    for (let i in arr) {
      if (arr[i]?.name?.length < 1) {
        arr[i].nameError = 'Please enter name.'
      }
      else if (arr[i]?.value?.length <1) {
        arr[i].errorValue = 'Please enter phone number.'
      }
      else {
        console.log(arr, "dsfji2e")
      }
    }
    setPhoneNumberArray(arr)
    let d = arr.filter((i, k) => i.name?.trim().length < 1 || i.value?.length < 8 )
    if (d.length > 0) {
      console.log('error exists');
    }
    else {
      let obj = {
        value: "",
        isError: null,
        name:"",
        nameError:null
      }
      arr.push(obj);
      setPhoneNumberArray(arr)
      return false
    }
  }
  const extraTimeValidation = () => {
    let arr = [...extraTimeArray];
    for (let i in arr) {
      if (arr[i]?.reason?.length < 1) {
        arr[i].reasonError = 'Please enter reason.'
      }
      else if (arr[i]?.reason?.trim()?.length < 2) {
        arr[i].reasonError = 'Reason name should be at least 2 characters long.'
      }

      else {
        arr[i].reasonError = null
      }

      setExtraTimeArray(arr)
      // return false
    }
  }

  const extraTimeValidation1 = () => {
    let arr = [...extraTimeArray];
    for (let i in arr) {

      if (arr[i]?.openTime == null) {
        arr[i].openTimeerror = 'Please select open time.'
        return false;
      }
      if (arr[i]?.openTime !== null) {
        arr[i].openTimeerror = null
      }

      setExtraTimeArray(arr)
      // return false
    }
  }

  const extraTimeValidation2 = () => {
    let arr = [...extraTimeArray];
    for (let i in arr) {
      if (arr[i]?.closeTime == null) {
        arr[i].closeTimeError = 'Please select close time.'
      }
      else if (moment(arr[i]?.openTime).format("YYYY-MM-DD HH:mm") >= moment(arr[i]?.closeTime).format("YYYY-MM-DD HH:mm")) {
      
        arr[i].closeTimeError = 'Close time should be 1 minute greater than open time.'
      }
      // else if(moment(arr[i]?.openTime).format("YYYY-MM-DD hh:mm") !== moment(arr[i]?.closeTime).format("YYYY-MM-DD hh:mm")) {
      //   console.log("remove====>>",moment(arr[i]?.openTime).format("YYYY-MM-DD hh:mm"),
      //   moment(arr[i]?.closeTime).format("YYYY-MM-DD hh:mm")
      //   )
      //   arr[i].closeTimeError = null
      // }
      else {
     
        arr[i].closeTimeError = null
      }
      setExtraTimeArray(arr)
      // return false
    }
  }

  const handleChangeExtraTime = (indexx, value, text) => {

    let arr = [...extraTimeArray]
    if (value == "reason") {
      arr[indexx].reason = text;
    }
    if (value == "openTime") {
      arr[terminalIndex].openTime = text;
    }
    if (value == "closeTime") {
      arr[terminalIndex].closeTime = text;
    }
    for (let i in arr) {
      if (arr[i]?.reason?.trim()?.length > 2) {
        arr[i].reasonError = null
      }
      if (moment(arr[i]?.openTime).format("YYYY-MM-DD HH:mm") !== moment(arr[i]?.closeTime).format("YYYY-MM-DD HH:mm")) {
        
        arr[i].closeTimeError = null
      }
    }
    setExtraTimeArray(arr)
    // extraTimeValidation()
  }

  const cancelExtraTime = (indexx) => {
    let arr = [...extraTimeArray];
    arr = arr.filter((x, index) => index !== indexx)
    setExtraTimeArray(arr);
  }

  const addExtraTime = () => {
    let arr = [...extraTimeArray]
    for (let i in arr) {
      if (arr[i]?.reason?.length < 1) {
        arr[i].reasonError = 'Please enter reason.'
      }
      else if (arr[i]?.openTime == null) {
        arr[i].openTimeerror = 'Please select open time.'
      }
      else if ((arr[i]?.closeTime == null)) {
        arr[i].closeTimeError = 'Please select close time.'
      }
      else if (moment(arr[i]?.openTime).format("YYYY-MM-DD HH:mm") >= moment(arr[i]?.closeTime).format("YYYY-MM-DD HH:mm")) {
       
        arr[i].closeTimeError = 'Close time should be 1 minute greater than open time.'
      }
      else {
        console.log(arr, "dsfji2e")
      }
    }
    setExtraTimeArray(arr)
    let d = arr.filter((i, k) => i.reason?.length < 1 || i.openTime == null || i.closeTime == null || moment(i?.openTime).format("YYYY-MM-DD HH:mm") >= moment(i?.closeTime).format("YYYY-MM-DD HH:mm"))
    if (d.length > 0) {
      console.log('error exists');
    }
    else {
      let obj = {
        reason: "",
        openTime: null,
        closeTime: null
      }
      arr.push(obj);
      setExtraTimeArray(arr)
      return false
    }
  }
  
  const phnNumberValidations = (type) => {
  
    let regex = /^[0-9]*$/
    let arr = [...phnNumberArray];
    for (let i in arr) {
      // if(type=="phone"){
        if (arr[i]?.value?.length < 1) {
          arr[i].errorValue = 'Please enter phone number.'
        }
        else if (!regex.test(arr[i]?.value)) {
          arr[i].errorValue = 'Please enter valid phone number.'
        }
        else if (arr[i]?.value?.length < 8) {
          arr[i].errorValue = ValidationConstants.phoneNumber.short
        }
        else if (arr[i]?.value == 0) {
          arr[i].errorValue = 'Please enter valid phone number.'
        }
        else {
          arr[i].errorValue = null
        }
      // }
    //  else if(type=="name"){
      if(arr[i]?.name?.length<1){
        arr[i].nameError="Please enter name."
      }
      else if(arr[i]?.name?.length<2){
        arr[i].nameError="Name should be at least 2 characters long."
      }
      else{
        arr[i].nameError=null;
      }
     }
     
    // }
    setPhoneNumberArray(arr)
    return false
  }

  const onChangePhoneNumber = (value, index, type) => {
    let arr = [...phnNumberArray];
    let regex = /^[0-9]*$/
    if (type == "phone") {
      if (value !== ' ') {
        arr[index].value = value.replace(/\s/g, '');
      }
    }
    else if (type == "name") {
      arr[index].name = value
    }
    for (let i in arr) {
      if (arr[i]?.name?.trim()?.length > 2) {
        arr[i].nameError = null
      }
      if (type == "phone") {
        if (arr[i]?.value?.length < 1) {
          arr[i].errorValue = 'Please enter phone number.'
        }
        if (!regex.test(arr[i]?.value)) {
          arr[i].errorValue = 'Please enter valid phone number.'
        }
        if (arr[i]?.value?.length < 8) {
          arr[i].errorValue = ValidationConstants.phoneNumber.short
        }
        if (arr[i]?.value == 0) {
          arr[i].errorValue = 'Please enter valid phone number.'
        }
        else if (arr[i]?.value?.length > 8) {
          arr[i].errorValue = null
        }
      }
    }
    setPhoneNumberArray(arr);
    // phnNumberValidations()
  }
  
  return (
    <Spin
      size="large"
      spinning={isLoading}
      wrapperClassName={classes.tableSpinner}
      className={classes.antSpin}
    >
      <DashboardLayout>
        <ConfirmationModal
          title="Alert"
          text={appConstants.confirmGeofence}
          visible={confirm1}
          onCancel={() => {
            setDeletefence(null)
            setConfirm1(false)
          }}
          onDeleteConfirmed={() => {
            deleteButton(deletefence)
            setConfirm1(false)
          }}
        />
        <AppBar breadcrumbs={[{ route: "/terminals", name: "Terminal Listing" }, { route: "/edit-terminal", name: "Edit Terminal" }]} />
        <Navbar title="Edit Terminal" />
        <Card className={classes.card}>
          <Grid container>
            <Grid className={classes.tableContainerRow}>
              {isAllDataFetched ?
                <Formik
                  enableReinitialize
                  initialValues={values}
                  validationSchema={validationSchema}
                  onSubmit={handleFormSubmit}
                >
                  {({
                    values,
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      {isTableDataLoading ? (
                        <BSTable
                          striped
                          bordered
                          className={classes.bsTableHeight}
                        >
                          <thead className={classes.bsHeaderTable}>
                            <Spin className={classes.tableDataSpinner} />
                          </thead>
                        </BSTable>
                      ) : (
                        <>
                          <BSTable
                            striped
                            bordered
                            className={clsx([
                              classes.bsTableHeight,
                              classes.terminalTable,
                            ])}
                          >
                            <tbody>
                              <tr>
                                <td className={classes.rowKey}>Image Type</td>
                                <td className={classes.rowValue}>
                                  <div className={classes.inputWrapper}>
                                    <RadioGroup aria-label="template" name="template" value={templates} onChange={selectOne}>
                                      <FormControlLabel value="file" control={<Radio />}
                                        checked={templates == "file"}
                                        label="Terminal Image"
                                      />
                                      <FormControlLabel value="url" control={<Radio />}
                                        checked={templates == "url"}
                                        label="Terminal Image URL"
                                      />
                                    </RadioGroup>
                                  </div>
                                </td>
                              </tr>
                              {
                                templates == "url" ?
                                  <tr>
                                    <td className={classes.rowKey}>Terminal Image URL</td>
                                    <td className={classes.rowValue}>
                                      <div className={classes.inputWrapper}>
                                        {
                                          terminalUrlArray.map((item, index) => {
                                            return (
                                              <div className={classes.inputfieldsNew} style={{ flexDirection: 'row' }}>
                                                <div className={classes.textBoxes} style={{ display: 'flex', width: index > 0 && "105%", marginTop: index == 0 && "14px" }}>
                                                  <InputField
                                                    placeholder="Enter Terminal Image URL"
                                                    name="terminalUrl"
                                                    onBlur={handleBlur}
                                                    onChange={(e) => onChangeTerminalUrl(e.target.value, index)}
                                                    type="name"
                                                    value={terminalUrlArray[index].value}
                                                    onKeyDown={handleKeyDown}
                                                  />
                                                  {
                                                    index > 0 &&
                                                    <div style={{ flexDirection: 'column', alignSelf: 'flex-end' }} onClick={() => cancelTerminalUrl(index)}>
                                                      <img
                                                        src={AppImages.crossButton}
                                                        style={{ cursor: "pointer", marginTop: "-20px" }}
                                                        height={18}
                                                        width={18}
                                                      />
                                                    </div>
                                                  }
                                                </div>
                                                {item.isError != null &&
                                                  <div className="url-Error">{item.isError}</div>}
                                              </div>
                                            )
                                          })
                                        }
                                        {
                                          terminalUrlArray.length < 8 &&
                                          <h6
                                            style={{ cursor: "pointer", width: "126px" }}
                                            onClick={() => addterminalUrl()}
                                          >
                                            + Add Image URL
                                          </h6>
                                        }
                                      </div>
                                    </td>
                                  </tr>
                                  :
                                  <tr>
                                    <td className={classes.rowKey}>Terminal Image</td>
                                    <td className={classes.rowValue}>
                                      <div className={classes.inputWrapper}>
                                        <input
                                          accept=".png,.jpg,.jpeg"
                                          className={classes.uploaderInput}
                                          id="icon-button-file"
                                          type="file"
                                          onChange={handleImageChange}
                                        />
                                        <label
                                          htmlFor="icon-button-file"
                                          className={classes.imageUploaderlabel}
                                        >
                                          <Tooltip
                                            title={
                                              appConstants.tooltip.changeTerminalImage
                                            }
                                            placement="right"
                                          >
                                            {!imageUrl ? (
                                              <div>
                                                <img
                                                  src={AppImages.addPhoto}
                                                  className={classes.terminalImage}
                                                />
                                              </div>
                                            ) : (
                                              <img
                                                src={imageUrl}
                                                className={classes.terminalImage}
                                              />
                                            )}
                                          </Tooltip>
                                        </label>
                                      </div>
                                    </td>
                                  </tr>
                              }
                              <tr>
                                <td className={classes.rowKey}>Region</td>
                                <td className={classes.rowValue}>
                                  <div className={classes.inputWrapper}>
                                    <Select
                                      native
                                      className={classes.selectInput}
                                      defaultValue={values.terminalArea}

                                      value={values?.terminalArea?.trimLeft()}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      inputProps={{
                                        name: "terminalArea",
                                        id: "terminalArea",
                                      }}
                                    // IconComponent={() => (
                                    //   <FontAwesomeIcon
                                    //     icon={faChevronDown}
                                    //     className={classes.dropdownIcon}
                                    //   />
                                    // )}
                                    >
                                      <option value="">
                                        Select Region
                                      </option>
                                      {terminalAreaListing?.map(
                                        (terminalArea, index) => {
                                          return (
                                            <option value={terminalArea._id}>
                                              {terminalArea.region_name}
                                            </option>
                                          );
                                        }
                                      )}
                                    </Select>
                                    {touched.terminalArea && errors.terminalArea && (
                                      <FormHelperText
                                        error
                                        id="component-error-text"
                                      >
                                        {errors.terminalArea}
                                      </FormHelperText>
                                    )}
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className={classes.rowKey}>Terminal Name</td>
                                <td className={classes.rowValue}>
                                  <div className={classes.inputWrapper}>
                                    <InputField
                                      placeholder="Enter Terminal Name"
                                      name="terminalName"
                                      error={Boolean(
                                        touched.terminalName &&
                                        errors.terminalName
                                      )}
                                      helperText={
                                        touched.terminalName &&
                                        errors.terminalName
                                      }
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      type="name"
                                      value={values?.terminalName?.trimLeft()}
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className={classes.rowKey}>
                                  Location Category
                                </td>
                                <td className={classes.rowValue}>
                                  <div className={classes.inputWrapper}>
                                    <Select
                                      native
                                      className={classes.selectInput}
                                      value={values.locationCategory}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      inputProps={{
                                        name: "locationCategory",
                                        id: "locationCategory",
                                      }}
                                    // IconComponent={() => (
                                    //   <FontAwesomeIcon
                                    //     icon={faChevronDown}
                                    //     className={classes.dropdownIcon}
                                    //   />
                                    // )}
                                    >
                                      <option value="">
                                        Select Location Category
                                      </option>
                                      <option value="Chassis Depot">
                                        Chassis Depot
                                      </option>
                                      <option value="Empty Depot">
                                        Empty Depot
                                      </option>
                                      <option value="Rail Terminal">
                                        Rail Terminal
                                      </option>
                                      <option value="Port Terminal">
                                        Port Terminal
                                      </option>
                                      <option value="Warehouse">Warehouse</option>
                                      <option value="Equipment Depot">Equipment Depot</option>
                                    </Select>
                                    {touched.locationCategory &&
                                      errors.locationCategory && (
                                        <FormHelperText
                                          error
                                          id="component-error-text"
                                        >
                                          {errors.locationCategory}
                                        </FormHelperText>
                                      )}
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className={classes.rowKey}>
                                  Terminal Country
                                </td>
                                <td className={classes.rowValue}>
                                  <div className={classes.inputWrapper}>
                                    <InputField
                                      placeholder="Enter Terminal Country"
                                      name="terminalCountry"
                                      error={Boolean(
                                        touched.terminalCountry &&
                                        errors.terminalCountry
                                      )}
                                      helperText={
                                        touched.terminalCountry &&
                                        errors.terminalCountry
                                      }
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      type="name"
                                      value={values?.terminalCountry?.trimLeft()}
                                      maxLength={20}
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className={classes.rowKey}>
                                  Terminal Address
                                </td>
                                <td className={classes.rowValue}>
                                  <div className={classes.inputWrapper}>
                                    <InputField
                                      placeholder="Enter Terminal Address"
                                      name="terminalAddress"
                                      error={Boolean(
                                        touched.terminalAddress &&
                                        errors.terminalAddress
                                      )}
                                      helperText={
                                        touched.terminalAddress &&
                                        errors.terminalAddress
                                      }
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      type="name"
                                      value={values?.terminalAddress?.trimLeft()}
                                      maxLength={255}
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className={classes.rowKey}>
                                  Terminal Latitude
                                </td>
                                <td className={classes.rowValue}>
                                  <div className={classes.inputWrapper}>
                                    <InputField
                                      placeholder="Enter Terminal Latitude"
                                      name="terminalLatitude"
                                      error={Boolean(
                                        touched.terminalLatitude &&
                                        errors.terminalLatitude
                                      )}
                                      helperText={
                                        touched.terminalLatitude &&
                                        errors.terminalLatitude
                                      }
                                      autoComplete="off"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      type="name"
                                      value={values?.terminalLatitude}
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className={classes.rowKey}>
                                  Terminal Longitude
                                </td>
                                <td className={classes.rowValue}>
                                  <div className={classes.inputWrapper}>
                                    <InputField
                                      placeholder="Enter Terminal Longitude"
                                      name="terminalLongitude"
                                      error={Boolean(
                                        touched.terminalLongitude &&
                                        errors.terminalLongitude
                                      )}
                                      helperText={
                                        touched.terminalLongitude &&
                                        errors.terminalLongitude
                                      }
                                      onBlur={handleBlur}
                                      autoComplete="off"
                                      // onChange={handleChange}
                                      onChange={(e) => {
                                        setTerminalLongitude1(e.target.value)
                                        handleChange(e)
                                      }}
                                      type="name"
                                      // value={values?.terminalLongitude}
                                      value={terminalLongitude1}
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className={classes.rowKey}>Terminal City</td>
                                <td className={classes.rowValue}>
                                  <div className={classes.inputWrapper}>
                                    <InputField
                                      placeholder="Enter Terminal City"
                                      name="terminalCity"
                                      error={Boolean(
                                        touched.terminalCity &&
                                        errors.terminalCity
                                      )}
                                      helperText={
                                        touched.terminalCity &&
                                        errors.terminalCity
                                      }
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      type="name"
                                      value={values?.terminalCity?.trimLeft()}
                                      maxLength={20}
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className={classes.rowKey}>State/Province</td>
                                <td className={classes.rowValue}>
                                  <div className={classes.inputWrapper}>
                                    <InputField
                                      placeholder="Enter State/Province"
                                      name="terminalState"
                                      error={Boolean(
                                        touched.terminalState &&
                                        errors.terminalState
                                      )}
                                      helperText={
                                        touched.terminalState &&
                                        errors.terminalState
                                      }
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      type="name"
                                      value={values?.terminalState?.trimLeft()}
                                      maxLength={20}
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className={classes.rowKey}>Zip Code</td>
                                <td className={classes.rowValue}>
                                  <div className={classes.inputWrapper}>
                                    <InputField
                                      placeholder="Enter Zip Code"
                                      name="zipCode"
                                      error={Boolean(
                                        touched.zipCode && errors.zipCode
                                      )}
                                      helperText={
                                        touched.zipCode && errors.zipCode
                                      }
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      type="name"
                                      value={values?.zipCode?.trimLeft()}
                                      maxLength={12}
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className={classes.rowKey}>
                                  Terminal Radius
                                </td>
                                <td className={classes.rowValue}>
                                  <div className={classes.inputWrapper}>
                                    <InputField
                                      placeholder="Enter Terminal Radius"
                                      name="terminalRadius"
                                      error={Boolean(
                                        touched.terminalRadius &&
                                        errors.terminalRadius
                                      )}
                                      helperText={
                                        touched.terminalRadius &&
                                        errors.terminalRadius
                                      }
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      type="name"
                                      value={values.terminalRadius}
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className={classes.rowKey}>Phone Number</td>
                                <td className={classes.rowValue}>
                                  <div className={classes.inputWrapper} >
                                    {
                                      phnNumberArray?.map((item, index) => {
                                        return (
                                          <div style={{ display: "flex", flexDirection: "row", }}>
                                          <div style={{ display: "flex", }}>
                                            <div style={{ display: "flex",width:"432px" }}>
                                              <div style={{
                                                width: "45%",
                                                marginBottom: "10px",
                                                flexDirection: "column", marginRight: "5%"
                                              }}>
                                                <InputField
                                                  // disabled={fullTime}
                                                  placeholder="Enter Name"
                                                  name="name"
                                                  onChange={(e) => {
                                                    onChangePhoneNumber(e.target.value, index,"name")
                                                    // setPhnNumberValue(e.target.value)
                                                  }}
                                                  onBlur={()=>phnNumberValidations("name")}
                                                  onFocus={()=>phnNumberValidations("name")}
                                                  type="name"
                                                  value={item?.name?.trimLeft()}
                                                  maxLength={30}
                                                />
                                                {item.nameError != null &&
                                                  <div className="url-Error">{item.nameError}</div>}
                                              </div>
                                              <div style={{ width: "45%", marginBottom: "10px", flexDirection: "column" }}>
                                                <InputField
                                                  // disabled={fullTime}
                                                  placeholder="Enter Phone Number"
                                                  name="value"
                                                  onChange={(e) => {
                                                    onChangePhoneNumber(e.target.value, index,"phone")
                                                    // setPhnNumberValue(e.target.value)
                                                  }}
                                                  onBlur={()=>phnNumberValidations("phone")}
                                                  onFocus={()=>phnNumberValidations("phone")}
                                                  type="name"
                                                  value={item?.value}
                                                  maxLength={15}
                                                />
                                                {item.errorValue != null &&
                                                  <div className="url-Error">{item.errorValue}</div>}
                                              </div>
                                              {
                                                index > 0 &&
                                                <div style={{marginLeft: "6px", marginTop: "7px" }}
                                                  onClick={() => cancelPhoneNumber(index)}>
                                                  <img
                                                    src={AppImages.crossButton}
                                                    style={{ cursor: "pointer", }}
                                                    height={18}
                                                    width={18}
                                                  />
                                                </div>
                                              }
                                            </div>
      
      
                                          </div>
                                        </div>
                                        )
                                      })
                                    }
                                    {
                                      phnNumberArray.length < 10 &&
                                      <h6
                                        style={{ cursor: "pointer", width: "156px" }}
                                        onClick={() => addPhoneNumber()}
                                      >
                                        + Add Phone Number
                                      </h6>
                                    }
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className={classes.rowKey}>
                                  Full Day Terminal
                                </td>
                                <td className={classes.rowValue}>
                                  <div className={classes.inputWrapper}>
                                    <Checkbox
                                      name="is24Availability"
                                      color="primary"
                                      checked={fullTime}
                                      onChange={() => {
                                        setFullTime(!fullTime)
                                        // setExtraTimeArray([])
                                      }
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </BSTable>
                          <Collapse in={!fullTime} unmountOnExit>
                            <BSTable
                              bordered
                              className={clsx([
                                classes.terminalTable,
                                classes.collapsedTable,
                              ])}
                            >
                              <tbody>
                                <tr
                                  className={classes.timingRow}
                                  style={{
                                    transitionDuration: "0.8s !important",
                                  }}
                                >
                                  <td className={classes.rowKey}>
                                    Terminal Open Time
                                  </td>
                                  <td className={classes.rowValue}>
                                    <div className={classes.inputWrapper}>
                                      <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                      >
                                        <TimePicker
                                          disabled={fullTime}
                                          ampm={true}
                                          placeholder="Select Open Time"
                                          name="terminalOpenTime"
                                          // openTo="minutes"
                                          value={selectedOpenTime}
                                          onAccept={(time) => {
                                            setOpenTimeError(null);
                                            setOpenTimePicker(false);
                                          }}
                                          error={null}
                                          helperText={null}
                                          onClose={() => setOpenTimePicker(false)}
                                          initialFocusedDate={
                                            ValidationConstants.terminalOpenTime
                                              .invalid
                                          }
                                          onBlur={handleBlur}
                                          onChange={(time) =>
                                            setSelectedOpenTime(time)
                                          }
                                          className={clsx([
                                            classes.selectInput,
                                            classes.timePicker,
                                            "cursor"
                                          ])}
                                          open={openTimePicker}
                                          onOpen={() => setOpenTimePicker(true)}
                                        />
                                      </MuiPickersUtilsProvider>
                                      <FontAwesomeIcon
                                        icon={faClock}
                                        className={classes.timeIcon}
                                        onClick={() => setOpenTimePicker(true)}
                                      />
                                      {openTimeError && (
                                        <FormHelperText
                                          error
                                          id="component-error-text"
                                        >
                                          {openTimeError}
                                        </FormHelperText>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                                <tr className={classes.timingRow}>
                                  <td className={classes.rowKey}>
                                    Terminal Close Time
                                  </td>
                                  <td className={classes.rowValue}>
                                    <div className={classes.inputWrapper}>
                                      <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                      >
                                        <TimePicker
                                          disabled={fullTime}
                                          ampm={true}
                                          placeholder="Select Close Time"
                                          name="terminalCloseTime"
                                          // openTo="minutes"
                                          value={selectedCloseTime}
                                          onBlur={handleBlur}
                                          onAccept={(time) => {
                                            setCloseTimeError(null);
                                            setCloseTimePicker(false);
                                          }}
                                          error={null}
                                          helperText={null}
                                          onClose={() =>
                                            setCloseTimePicker(false)
                                          }
                                          initialFocusedDate={
                                            ValidationConstants.terminalCloseTime
                                              .invalid
                                          }
                                          onChange={(time) =>
                                            setSelectedCloseTime(time)
                                          }
                                          className={clsx([
                                            classes.selectInput,
                                            classes.timePicker,
                                            "cursor"
                                          ])}
                                          open={closeTimePicker}
                                          onOpen={() => setCloseTimePicker(true)}
                                        />
                                      </MuiPickersUtilsProvider>
                                      <FontAwesomeIcon
                                        icon={faClock}
                                        className={classes.timeIcon}
                                        onClick={() => setCloseTimePicker(true)}
                                      />
                                      {closeTimeError && (
                                        <FormHelperText
                                          error
                                          id="component-error-text"
                                        >
                                          {closeTimeError}
                                        </FormHelperText>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </BSTable>
                          </Collapse>
                          {(fullTime == false && (selectedOpenTime && selectedCloseTime)) &&
                            <BSTable bordered className={classes.terminalTable}>
                              <tbody>
                                <tr>
                                  <td className={classes.rowKey}>Add Extra Time</td>
                                  <td className={classes.rowValue}>
                                    {extraTimeArray?.length > 0 &&
                                      extraTimeArray?.map((item, index) => {
                                        return (
                                          <div style={{ display: "flex", flexDirection: "row", }}>
                                            <div style={{ display: "flex", }}>
                                              <div style={{ display: "flex",width:"540px",justifyContent: "space-between" }}>
                                                <div style={{ width: "30%", marginBottom: "10px", flexDirection: "column" }}>
                                                  <InputField
                                                    placeholder="Enter Reason"
                                                    name="Reason"
                                                    onChange={(e) => handleChangeExtraTime(index, "reason", e.target.value)}
                                                    type="Reason"
                                                    value={item?.reason}
                                                    maxLength={20}
                                                    onBlur={extraTimeValidation}
                                                    onFocus={extraTimeValidation}
                                                  // value={values.terminalRadius}
                                                  />
                                                  {item.reasonError != null &&
                                                    <div className="url-Error">{item.reasonError}</div>}
                                                </div>
                                                <div style={{ width: "30%", flexDirection: "column" }}>
                                                  <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                                    <TimePicker
                                                      ampm={true}
                                                      placeholder="Select Open Time"
                                                      name="openTime"
                                                      // openTo="minutes"
                                                      value={item.openTime}
                                                      onAccept={(time) => {
                                                        setOpenTimeError(null);
                                                        setOpenExtraTimePicker(false);
                                                      }}
                                                      onClose={() => setOpenExtraTimePicker(false)}
                                                      initialFocusedDate={
                                                        ValidationConstants.terminalOpenTime
                                                          .invalid
                                                      }
                                                      onBlur={extraTimeValidation1}
                                                      onFocus={extraTimeValidation1}
                                                      onChange={(e) => {
                                                        setTerminalIndex(index)
                                                        handleChangeExtraTime(index, "openTime", e)
                                                      }}
                                                      // onChange={(time) =>
                                                      //   setOpenExtraTime(time)
                                                      // }
                                                      className={clsx([
                                                        classes.selectInput,
                                                        classes.timePicker,
                                                        "cursor"
                                                      ])}
                                                      open={openExtraTimePicker}
                                                      onOpen={() => {
                                                        setTerminalIndex(index)
                                                        setOpenExtraTimePicker(true)
                                                      }}
                                                    />
                                                  </MuiPickersUtilsProvider>
                                                  {/* <FontAwesomeIcon
                                                  icon={faClock}
                                                  className={classes.timeIcon}
                                                  onClick={() => setOpenExtraTimePicker(true)}
                                                /> */}
                                                  {item.openTimeerror != null &&
                                                    <div className="url-Error">{item.openTimeerror}</div>}
                                                </div>
                                                <div style={{ width: "30%", flexDirection: "column" }}>
                                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <TimePicker
                                                      ampm={true}
                                                      placeholder="Select Close Time"
                                                      name="closeTime"
                                                      // openTo="minutes"
                                                      value={item.closeTime}
                                                      onChange={(e) => {
                                                        setTerminalIndex(index)
                                                        handleChangeExtraTime(index, "closeTime", e)
                                                      }}
                                                      onBlur={extraTimeValidation2}
                                                onFocus={extraTimeValidation2}
                                                      onAccept={(time) => {
                                                        setCloseTimeError(null);
                                                        setCloseExtraTimePicker(false);
                                                      }}
                                                      onClose={() => setCloseExtraTimePicker(false)}
                                                      initialFocusedDate={ValidationConstants.terminalCloseTime.invalid}
                                                      // onChange={(time) =>
                                                      //   setCloseExtraTime(time)
                                                      // }
                                                      className={clsx([
                                                        classes.selectInput,
                                                        classes.timePicker,
                                                        "cursor"
                                                      ])}
                                                      open={closeExtraTimePicker}
                                                      onOpen={() => {
                                                        setTerminalIndex(index)
                                                        setCloseExtraTimePicker(true)
                                                      }}
                                                    />
                                                  </MuiPickersUtilsProvider>
                                                  {/* <FontAwesomeIcon
                                                  icon={faClock}
                                                  className={classes.timeIcon}
                                                  onClick={() => setCloseExtraTimePicker(true)}
                                                /> */}
                                                  {item.closeTimeError != null &&
                                                    <div className="url-Error">{item.closeTimeError}</div>}
                                                </div>
                                              </div>
                                            </div>
                                            {
                                              <div style={{ marginLeft: "6px", marginTop: "7px" }}
                                                onClick={() => cancelExtraTime(index)}>
                                                <img
                                                  src={AppImages.crossButton}
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                  height={18}
                                                  width={18}
                                                />
                                              </div>
                                            }
                                          </div>
                                        )
                                      })
                                    }
                                    {extraTimeArray &&
                                      extraTimeArray?.length < 12 &&
                                      <div>
                                        <h6
                                          style={{ cursor: "pointer", width: "126px" }}
                                          onClick={() => addExtraTime()}
                                        >
                                          + Add Extra Time
                                        </h6>
                                        <p className="extra_time">(
                                          Note:-please add Already open Extra Time then you can add new Extra time.)</p>
                                      </div>
                                    }
                                  </td>
                                </tr>
                              </tbody>
                            </BSTable>}
                          <Collapse in={!extraTime} unmountOnExit>
                            <BSTable bordered className={classes.terminalTable}>
                              <tbody>
                                <tr>
                                  <td className={classes.rowKey}></td>
                                  <td className={classes.rowValue}>
                                  </td>
                                </tr>
                              </tbody>
                            </BSTable>
                          </Collapse>
                          <BSTable
                            striped
                            bordered
                            className={classes.terminalTable}
                          >
                            <tbody>
                              <tr>
                                <td className={classes.rowKey}>About Us</td>
                                <td className={classes.rowValue}>
                                  <div className={classes.inputWrapper}>
                                    <InputField
                                      textArea={true}
                                      id="description"
                                      placeholder="Enter About Us"
                                      name="about"
                                      // error={Boolean(
                                      //   touched.about && errors.about
                                      // )}
                                      // helperText={
                                      //   touched.about && errors.about
                                      // }
                                      // onBlur={handleBlur}
                                      onChange={(e)=>setAbout(e.target.value)}
                                      type="name"
                                      value={about}
                                      maxLength={500}
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className={classes.rowKey}>Show terminal</td>
                                <td className={classes.rowValue}>
                                  <div className={classes.inputWrapper}>
                                    <Checkbox
                                      name="isShow"
                                      color="primary"
                                      // value={showTerminal}
                                      checked={showTerminal}
                                      onChange={() => {
                                        setShowTerminal(!showTerminal)
                                      }
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>
                              {/* <tr>
                                <td className={classes.rowKey}>Description</td>
                                <td className={classes.rowValue}>
                                  <div className={classes.inputWrapper}>
                                    <textarea
                                      className="textAreaNew"
                                      placeholder="Enter Description"
                                      onChange={(e) => onchangeData(e.target.value)}
                                      type="description"
                                      value={discription}
                                      name="description"
                                    />
                                  </div>
                                </td>
                              </tr> */}
                              <tr>
                                <td className={classes.rowKey}>Rating</td>
                                <td className={classes.rowValue}>
                                  <div className={classes.inputWrapper}>
                                    <span className="rating_details">
                                      {terminalDetails?.averageRating == 0 ? "N/A" : terminalDetails?.averageRating}
                                    </span>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className={classes.rowKey}>Map Logo</td>
                                <td className={classes.rowValue}>
                                  <div className={classes.inputWrapper}>
                                    <input
                                      accept=".png,.jpg,.jpeg"
                                      className={classes.uploaderInput}
                                      id="terminal-map-logo"
                                      type="file"
                                      onChange={handleMapLogoChange}
                                    />
                                    <label
                                      htmlFor="terminal-map-logo"
                                      className={classes.imageUploaderlabel}
                                    >
                                      <Tooltip
                                        title={appConstants.tooltip.changeMapLogo}
                                        placement="right"
                                      >
                                        {mapLogoUrl ? (
                                          <img
                                            src={mapLogoUrl}
                                            className={classes.terminalImage}
                                          />
                                        ) : (
                                          <img
                                            src={AppImages.pin}
                                            className={classes.terminalImage}
                                          />
                                        )}
                                      </Tooltip>
                                    </label>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className={classes.rowKey}>
                                  Terminal Board Logo
                                  <br />
                                  <small
                                    style={{
                                      color: "#555555",
                                      textAlign: "center",
                                      width: "100%",
                                    }}
                                  >
                                    (Transparent Logo)
                                  </small>
                                </td>
                                <td className={classes.rowValue}>
                                  <div className={classes.inputWrapper}>
                                    <input
                                      accept=".png,.jpg,.jpeg"
                                      className={classes.uploaderInput}
                                      id="terminal-board-logo"
                                      type="file"
                                      onChange={handleTerminalBoardLogoChange}
                                    />
                                    <label
                                      htmlFor="terminal-board-logo"
                                      className={classes.imageUploaderlabel}
                                    >
                                      <Tooltip
                                        title={
                                          appConstants.tooltip
                                            .changeTerminalBoardLogo
                                        }
                                        placement="right"
                                      >
                                        {terminalBoardLogoUrl ? (
                                          <img
                                            src={terminalBoardLogoUrl}
                                            className={classes.terminalImage}
                                          />
                                        ) : (
                                          <img
                                            src={AppImages.pin}
                                            className={classes.terminalImage}
                                          />
                                        )}
                                      </Tooltip>
                                    </label>
                                  </div>
                                </td>
                              </tr>
                              {terminalPolygon.length > 0 ?
                                <tr>
                                  <td className={classes.rowKey}>
                                    Terminal Polygon
                                    <br />
                                    <small
                                      style={{
                                        color: "#555555",
                                        textAlign: "center",
                                        width: "100%",
                                      }}
                                    >
                                      (latitude, longitude)
                                    </small>
                                  </td>
                                  <td className={classes.rowValue}>
                                    <div className={classes.inputWrapper}>
                                      <InputField
                                        placeholder="Enter Terminal Polygon"
                                        name="terminalPolygon"
                                        disabled={true}
                                        error={Boolean(
                                          touched.terminalPolygon &&
                                          errors.terminalPolygon
                                        )}
                                        type="name"
                                        value={values.terminalPolygon}
                                        textArea={true}
                                      />
                                    </div>
                                  </td>
                                </tr>
                                : null}
                              {(values?.terminalLatitude && terminalLongitude1) ?
                                <>
                                  {terminalDetails?.geoFence?.length > 0 &&
                                    <tr>
                                      <td className={classes.rowKey}>Geofence Points</td>
                                      {
                                        terminalDetails?.geoFence?.map((item, index) => {
                                          return (
                                            <>
                                              <div className={classes.inputfields} style={{ flexDirection: 'row' }}>
                                                {/* <div className={classes.textBoxes}> */}
                                                <div className={classes.textBoxes} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                  <div style={{ flexDirection: 'column', width: '15%' }}>
                                                    {index == 0 &&
                                                      <div>
                                                        <h6>Name</h6>

                                                      </div>
                                                    }
                                                    <InputField
                                                      placeholder="Enter Name"
                                                      value={item?.name}
                                                      disabled={true}
                                                    />
                                                  </div>
                                                  <div style={{ flexDirection: 'column', width: '15%' }}>
                                                    {index == 0 &&
                                                      <h6>Latitude</h6>
                                                    }
                                                    <InputField
                                                      placeholder="Enter Latitude"
                                                      value={item?.lat?.toFixed(8)}
                                                      disabled={true}
                                                    />
                                                  </div>
                                                  <div style={{ flexDirection: 'column', width: '15%' }}>
                                                    {index == 0 &&
                                                      <div >
                                                        <h6>Longitude</h6>
                                                      </div>
                                                    }
                                                    <InputField
                                                      placeholder="Enter Longitude"
                                                      value={item?.lng?.toFixed(8)}
                                                      disabled={true}
                                                    />
                                                  </div>
                                                  <div style={{ flexDirection: 'column', width: '15%' }}>
                                                    {index == 0 &&
                                                      <div >
                                                        <h6>Radius</h6>
                                                      </div>
                                                    }
                                                    <InputField
                                                      // type="number"
                                                      placeholder="Enter Radius"
                                                      value={item?.radius}
                                                      disabled={true}
                                                    />
                                                  </div>
                                                  <div style={{ flexDirection: 'column', width: 100, alignSelf: 'flex-end' }}>
                                                    <Button
                                                      title="Delete"
                                                      mrt
                                                      onClick={() => {
                                                        deleteGeofence(index)
                                                        setConfirm1(true)
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              </div>

                                            </>
                                          )
                                        })
                                      }

                                    </tr>
                                  }
                                  <tr>
                                    <td className={classes.rowKey}>Polygon Area</td>
                                    <td className={classes.rowValue}>
                                      <Button
                                        disabled={terminalDetails?.geoFence?.length >= 4 ? true : (firstPoint) ? false : true}
                                        title="Add Geofence"
                                        onClick={() => geofenceButton()}
                                        mr
                                      />
                                      <div className={classes.inputWrapper} style={{ width: "100%", height: 500 }} >
                                        <div style={{ width: "100%", background: 'orange', marginTop: 10, marginBottom: 10 }}>

                                          <GoogleMap
                                            setMapSettings={setMapSettings}
                                            onMapClicked={onMapClicked}
                                            setCircleZoom={setCircleZoom}
                                            onCircleClicked={onCircleClicked}
                                            onClearClicked={onClearClicked}
                                            zoom={mapSettings?.zoomLevel}
                                            polygonState={polygonState}
                                            circleZoom={circleZoom}
                                            firstPoint={firstPoint}
                                            centerPoint={centerPoint}
                                            terminalDetails={terminalDetails.geoFence} 
                                            isEditing={true}
                                            mapSettings={mapSettings}
                                            radius={Number(radius)}
                                            markersArray={allMarkers}
                                            center={{ lat: parseFloat(values?.terminalLatitude), lng: parseFloat(terminalLongitude1) }}
                                            marker={{ lat: parseFloat(values?.terminalLatitude), lng: parseFloat(terminalLongitude1) }}
                                            value={values}
                                          />
                                        </div>
                                        <div style={{ justifyContent: "flex-start", width: "70%", position: 'absolute', bottom: 0, alignSelf: 'center' }}>
                                          <h6>(Note: Click on clear button will clear the polygon area and geofences.)</h6>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: "row", height: "100%", width: "100%" }}>

                                          <div style={{ display: "flex", justifyContent: 'flex-end', position: 'absolute', width: "30%", bottom: 0, right: 0 }} >
                                            <Button disabled={(isCleard && polygonState.points.length > 1) ? false : true} title="Undo" ml onClick={() => onUndoClick(values)} />
                                            <Button disabled={polygonState.points.length > 0 ? false : true} red mrt title="Clear" ml onClick={() => onClearClicked(values)} />
                                          </div>
                                        </div>
                                      </div>

                                    </td>
                                  </tr>
                                </>
                                : null}
                            </tbody>
                          </BSTable>
                          <Collapse in={!closeMap} unmountOnExit>
                            <BSTable bordered className={classes.terminalTable}>
                              <tbody>
                                {(enabled === true) ?
                                  <tr>
                                    <td className={classes.rowKey}>Geofence Area</td>
                                    <td className={classes.rowValue}>
                                      <div className={classes.inputfieldsNew} style={{ flexDirection: 'row' }}>
                                        <div className={classes.textBoxes} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                          <div style={{ flexDirection: 'column', width: '15%' }}>
                                            <InputField
                                              placeholder="Enter Name"
                                              onChange={(value) => {
                                                setGeofenceName(value.target.value);
                                                setGeofenceErrors("");
                                              }}
                                              type="name"
                                              value={geofenceName?.trimLeft()}
                                            />
                                            {geofenceErrors && (
                                              <div style={{ width: '20vw' }}>
                                                <FormHelperText
                                                  error id="component-error-text" >
                                                  {geofenceErrors}
                                                </FormHelperText>
                                              </div>
                                            )}
                                          </div>
                                          <div style={{ flexDirection: 'column', width: '15%' }}>
                                            <InputField
                                              className="textBoxes"
                                              placeholder="Latitude"
                                              onChange={handleChange}
                                              type="name"
                                              value={latitude?.toFixed(6)}
                                              disabled={true}
                                            />
                                          </div >
                                          <div style={{ flexDirection: 'column', width: '15%' }}>
                                            <InputField
                                              className="text-boxes"
                                              placeholder="Longitude"
                                              onChange={handleChange}
                                              type="name"
                                              value={longitude?.toFixed(6)}
                                              disabled={true}
                                            />
                                          </div>
                                          <div style={{ flexDirection: 'column', width: '15%' }}>
                                            <InputField
                                              className="text-boxes"
                                              name="radius"
                                              placeholder="Radius"
                                              // onChange={(e) => {
                                              //   setRadius(e.target.value);
                                              //   setRadiusErrors("");
                                              // }}
                                              type="name"
                                              value={radius}
                                              onChange={numberValue}
                                              maxLength={10}
                                            />
                                            {radiusErrors && (
                                              <div style={{ width: '20vw' }}>
                                                <FormHelperText error id="component-error-text"
                                                >
                                                  {radiusErrors}

                                                </FormHelperText>
                                              </div>
                                            )}
                                          </div>
                                          <Button
                                            mrt
                                            title="Save"
                                            onClick={onSaveClicked}
                                            value={closeMap}
                                          />
                                        </div>
                                      </div>

                                      <div className={classes.inputWrapper} style={{ width: "100%", height: 500, }} >
                                        {/* <div style={{ width: "100%",, marginTop: 10 }} > */}
                                        <GeofenceGoogleMap
                                          setMapSettings={null}
                                          onMapClicked={onGeofenceClicked}
                                          polygonState={polygonState}
                                          zoom={16}
                                          radius={Number(radius)}
                                          center={{ lat: parseFloat(latitude), lng: parseFloat(longitude) }}
                                          marker={{ lat: parseFloat(latitude), lng: parseFloat(longitude) }}
                                          terminalDetails={null}
                                          // setMapSettings={null}
                                          value={null}
                                          isEditing={false}
                                          mapSettings={true}
                                        />
                                        {/* </div> */}
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', width: "100%", position: 'absolute', bottom: 0 }} >
                                          <Button mrt title="Close" ml onClick={() => mapClose()} />
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  : null
                                }
                              </tbody>
                            </BSTable>
                          </Collapse>
                          <BSTable bordered className={classes.terminalTable}>
                            <tbody>
                              <tr>
                                <td className={classes.rowKey}></td>
                                <td className={classes.rowValue}>
                                  <Button title="Update" type="submit" mr />
                                  <Button title="Rating Details"
                                    onClick={() => {
                                      history.push({ pathname: "/rating-details", state: { terminalId: location?.state?.terminalId } })
                                    }}
                                    mr />
                                </td>
                              </tr>
                            </tbody>
                          </BSTable>

                        </>
                      )}
                    </form>
                  )}
                </Formik>
                : null}
            </Grid>
          </Grid>
        </Card>
      </DashboardLayout>
    </Spin >
  );
};

export default EditTerminal;
