import { apiConstants as types } from '../../themes/constants'
import localStorage from '../../utils/localStorage'
const initialState = {
    isLoading: false,
    isTableDataLoading:false,
    result: {},
    deleteNews:null,
    allNews: [],
    newsPaging:{},
    errorState:null,
    currentPage:1,
    counter:true
}

export const newsManagementReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CkIMAGELOAD':
            return { ...state, isTableDataLoading: action.load }

            case 'IMAGE_COUNT':
            return { ...state, counter: action.count }
        // Get user list
        case types.API_GET_NEWS_LIST_LOAD:
            return { ...state, isTableDataLoading: true ,errorState:null,}
        case types.API_GET_NEWS_LIST_SUCCESS:
            return {
                ...state,
                isTableDataLoading: false, 
                allNews: action.result.all_news,
                newsPaging: action.result.paging,
                currentPage: action.result?.paging?.page?.currentPage
            }

        // Add terminal News
        case types.API_ADD_NEWS_LOAD:
            return { ...state, isLoading: true ,errorState:null,isTableDataLoading: true}
        case types.API_ADD_NEWS_SUCCESS:
            return { ...state, isLoading: false,isTableDataLoading: false}

            // Edit terminal News
        case types.API_EDIT_NEWS_LOAD:
            return { ...state, isLoading: true ,errorState:null,}
        case types.API_EDIT_NEWS_SUCCESS:
            return { ...state, isLoading: false}

        // Delete terminal area
        case types.API_DELETE_NEWS_LOAD:
            return { ...state, isTableDataLoading: true ,errorState:null,}
        case types.API_DELETE_NEWS_SUCCESS:
            let indexToDelete = state.allNews.findIndex(value=>value._id === action.id)
            state.allNews.splice(indexToDelete,1)
            return { ...state, isTableDataLoading: false, allNews: [...state.allNews], deleteNews:action.id}

        // ADD IMAGE
        case types.API_DELETE_IMAGE_NEWS_LOAD:
            return { ...state, isLoading: true ,errorState:null,}
        case types.API_DELETE_IMAGE_NEWS_SUCCESS:
            return { ...state, isLoading: false}
        
            //Terminal area management failed cases
        case types.API_NEWS_MANAGEMENT_FAILED:
            return { ...state, isLoading: false,isTableDataLoading:false ,allNews: [],
            newsPaging:{},errorState:action.errorState,}
        case types.API_NEWS_MANAGEMENT_ERROR:
            return { ...state, isLoading: false ,isTableDataLoading:false,allNews: [],
            newsPaging:{},errorState:action.errorState,}

        //Default case
        default:
            return { ...state }

    }
}