import { all, fork } from "redux-saga/effects";
import rootAuthenticationSaga from "./authenticationSaga";
import rootUserManagementSaga from "./userManagementSaga";
import rootSavedUserLocationsSaga from "./savedUserLocationSaga";
import rootTerminalAreaManagementSaga from "./terminalAreaManagementSaga";
import rootTerminalManagementSaga from "./terminalManagementSaga";
import rootReportPostSaga from "./reportPostSaga";
import rootTerminalReportPostSaga from "./terminalReportPostSaga";
import rootImageManagementSaga from "./imageManagementSaga";
import rootRadarManagementSaga from "./radarManagementSaga";
import rootNewsManagementSaga from "./newsManagementSaga";
import rootStateListingSaga from "./stateListingSaga";
import rootPushNotificationSaga from "./pushNotificationSaga";
import rootViewTimelineSaga from "./viewTimelineSaga";
import rootDashboardSaga from "./dashboardSaga";
import rootEmployeeManagementSaga from "./employeeMangementSaga";
import rootPostManagementSaga from "./postManagementSaga";
import rootTerminalBoardSaga from "./terminalBoardSaga";

export default function* rootSaga() {
  yield all([
    fork(rootAuthenticationSaga),
    fork(rootUserManagementSaga),
    fork(rootSavedUserLocationsSaga),
    fork(rootTerminalAreaManagementSaga),
    fork(rootTerminalManagementSaga),
    fork(rootImageManagementSaga),
    fork(rootReportPostSaga),
    fork(rootTerminalReportPostSaga),
    fork(rootRadarManagementSaga),
    fork(rootNewsManagementSaga),
    fork(rootStateListingSaga),
    fork(rootPushNotificationSaga),
    fork(rootViewTimelineSaga),
    fork(rootDashboardSaga),
    fork(rootEmployeeManagementSaga),
    fork(rootPostManagementSaga),
    fork(rootTerminalBoardSaga),
  ]);
}
