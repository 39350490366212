import { apiConstants as types } from '../../themes/constants'
import localStorage from '../../utils/localStorage'
const initialState = {
    isLoading: false,
    result: {},
    errorState: null,
    isExpired: null,
    terminalBoardDetails:{}
}

export const terminalBoardReducer = (state = initialState, action) => {
    switch (action.type) {

      
        // edit termminal board
        case types.API_EDIT_TERMINAL_BOARD_LOAD:
            return { ...state, isLoading: true, errorState: null }
        case types.API_EDIT_TERMINAL_BOARD_SUCCESS:
            return { ...state, isLoading: false, result: action.result }

             // default theme
        case types.API_APPLY_DEFAULT_THEME_LOAD:
            return { ...state, isLoading: true, errorState: null }
        case types.API_APPLY_DEFAULT_THEME_SUCCESS:
            return { ...state, isLoading: false, result: action.result }

            //  default theme details
        case types.API_DEFAULT_THEME_DETAILS_LOAD:
            return { ...state, isLoading: true, errorState: null }
        case types.API_DEFAULT_THEME_DETAILS_SUCCESS:
            return { ...state, isLoading: false, terminalBoardDetails: action.result }      

             // edit default theme
        case types.API_EDIT_APPLY_DEFAULT_THEME_LOAD:
            return { ...state, isLoading: true, errorState: null }
        case types.API_EDIT_APPLY_DEFAULT_THEME_SUCCESS:
            return { ...state, isLoading: false, result: action.result }

             

        // Failed Case
        case types.API_EDIT_TERMINAL_BOARD_FAILED:
            return { ...state, isLoading: false }
        case types.API_EDIT_TERMINAL_BOARD_ERROR:
            return { ...state, isLoading: false }

           

        //Default case
        default:
            return { ...state }

    }
}