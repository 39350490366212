import localStorage from "../utils/localStorage";
let permissionsData = localStorage?.getPermissions()
let allRoutes = []

export function RoutesAccess(key) {
    let employeeIndex = permissionsData?.findIndex((x) => x.name == key)
    let roles = permissionsData[employeeIndex]?.view == true || permissionsData[employeeIndex]?.add == true || permissionsData[employeeIndex]?.edit == true || permissionsData[employeeIndex]?.block == true || permissionsData[employeeIndex]?.delete == true
    return roles
};

export function routesArray(key) {
    let employeeIndex = permissionsData?.findIndex((x) => x.name == key)
    let roles = permissionsData[employeeIndex]?.view == true || permissionsData[employeeIndex]?.add == true || permissionsData[employeeIndex]?.edit == true || permissionsData[employeeIndex]?.block == true || permissionsData[employeeIndex]?.delete == true

}
