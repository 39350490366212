import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { AppImages } from "../themes/appImages";
import moment from "moment";
import Slider from "react-slick";
import { convertToRaw } from "draft-js";
import { CaretDownOutlined, CaretLeftOutlined, CaretUpOutlined } from '@ant-design/icons'
import draftToHtml from "draftjs-to-html";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    // marginLeft: theme.spacing(2),
    // flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const images = [
  {
    image: AppImages.image1,
    logo: AppImages.logo1,
  },
  {
    image: AppImages.image2,
    logo: AppImages.logo2,
  },
  {
    image: AppImages.image3,
    logo: AppImages.logo3,
  },
  {
    image: AppImages.image1,
    logo: AppImages.logo4,
  },
  {
    image: AppImages.image2,
    logo: AppImages.logo5,
  },
  {
    image: AppImages.image3,
    logo: AppImages.logo6,
  },
];

var settings = {
  //   dots: true,
  infinite: true,
  speed: 3000,
  slidesToShow: 6,
  slidesToScroll: 1,
  initialSlide: 0,
  arrows: true,
  swipeToSlide: true,

  // autoplay: true,
  // autoplaySpeed: 5000,
  cssEase: "ease-out",
  // draggable: true,
  // easing: "bounce",
  // nextArrow: <CarouselArrow direction="right" />,
  // prevArrow: <CarouselArrow direction="left" />,
  responsive: [
    {
      breakpoint: 1420,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 1,
        initialSlide: 0,
        infinite: true,
        speed: 3000,
        // easing: "bounce",
        // autoplay: true,
        // autoplaySpeed: 5000,
        swipeToSlide: true,
        cssEase: "ease-out",
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 1,
        initialSlide: 0,
        infinite: true,
        speed: 3000,
        // easing: "bounce",
        // autoplay: true,
        // autoplaySpeed: 5000,
        swipeToSlide: true,
        cssEase: "ease-out",
      },
    },
  ],
};

export default function FullScreenDialog(props) {
  const classes = useStyles();
  const { visible, onCancel, imageUrl, markup, heading, descriptionData, headingData, regionName } = props;
  const h = window.innerHeight;
  const w = window.innerWidth;
  const [expand, setExpand] = useState(!false);
  // const rawContentState = convertToRaw(markup);
  // const headingState = convertToRaw(heading);
  // const markupHtml = draftToHtml(rawContentState);
  // const headingHtml = draftToHtml(headingState);

  const handleCancel = () => {
    onCancel();
  };

  let navbarTogglerClass = `collapse navbar-collapse  justify-content-end`;

  return (
    <div>
      <Dialog
        fullScreen
        open={visible}
        onClose={handleCancel}
        TransitionComponent={Transition}
      >
        <header className={`fixed-top clearHeader header-view sticky `}>
          <div className="container-fluid adjust">
            <nav className="navbar navbar-expand-lg new_navbar">
              <div
                style={{ justifyContent: "space-between", display: "contents" }}
              >
                <img src={AppImages.logoWebsite} className="imgLog" />
                <>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapsibleNavbar"
                    aria-expanded={true}
                    style={{ color: "#fff" }}
                    onClick={() => null}
                  >
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={handleCancel}
                      aria-label="close"
                    >
                      <CloseIcon />
                    </IconButton>
                  </button>
                  <div
                    className={navbarTogglerClass}
                    style={{ color: "#fff" }}
                    id="collapsibleNavbar"
                  >
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={handleCancel}
                      aria-label="close"
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </>
              </div>
            </nav>
          </div>
        </header>
        <section
          className={`sectionTopTerminalBoardArea 
        `}
        >
          <div
            className="container-fluid adjust screenActive"
          // style={{ marginTop: height * 0.01 }}
          >
            <div className="terminalAreaHeader">
              <p
                className="terminalAreaName"
                style={{
                  flexDirection: "row",
                  display: "flex",
                  fontSize: "5vh",
                  fontWeight: "500",
                  zIndex: 1,
                  width: "51%"
                  // alignItems: "center",
                  // justifyContent: "center",
                }}
              >
                {"PORT OF NY|NJ"}
                <div className="arrow-down"
                  style={{
                    marginTop: "-8px",
                    zIndex: 1000, marginLeft: "12%", width: "40%",
                  }}>
                  <CaretDownOutlined
                    style={{ color: "#3387ed", fontSize: "6vh" }}></CaretDownOutlined>
                </div>
              </p>
              <div className="terminalAreaHeaderSub">
                <p className="terminalAreaTime">
                  {moment().format(" HH:mm A")}
                </p>
                <div className="terminalAreaHeaderTempToday">
                  <div>
                    <p
                      className="terminalAreaTimeToday"
                      style={{ minWidth: "50px" }}
                    >
                      Today
                    </p>
                  </div>
                  <div className="tempDiv">
                    <img
                      src={AppImages.sun}
                      style={{
                        width: "40px",
                        height: "40px",
                        // borderRadius: "3vw",
                        marginRight: "10px",
                      }}
                      className="sun"
                    />
                    <pre className="terminalAreaTimeToday">
                      25<sup>°</sup>
                    </pre>
                  </div>
                </div>
                <div className="terminalAreaHeaderTempToday">
                  <div>
                    <p className="terminalAreaTimeToday">Tonight</p>
                  </div>
                  <div className="tempDiv" style={{ marginLeft: -w * 0.022 }}>
                    <div>
                      <img
                        src={AppImages.moon}
                        style={{
                          width: "40px",
                          height: "40px",
                          // borderRadius: "3vw",
                          marginRight: "10px",
                        }}
                        className="moon"
                      />
                    </div>
                    <div>
                      <pre className="terminalAreaTimeToday">
                        25<sup>°</sup>
                      </pre>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid adjust screenActive">
            <div
              className="row news"
              style={{
                marginTop: "0.5vw",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                overflow: 'hidden',
                height: '46vh',

              }}
            >
              {/* <div className="col-sm-3">
                <img
                  src={imageUrl}
                  style={{
                    display: !expand ? "none" : "flex",
                    borderRadius: "1vw",
                    height: "16vw",
                    width: "16vw",
                  }}
                  className="animate-flicker terminalAreaImage"
                /> */}
          
            <div className="col-sm">
              <div className="text-img">
                {expand && (
                  <>
                    <div className="newsTextHeading animate-flicker"
                    >
                      <div
                        className="ck-content"
                        dangerouslySetInnerHTML={{
                          __html: headingData,
                        }}
                        style={{
                          overflow: "hidden"
                          // backgroundColor: "red",
                          // color: "red",
                          // display: !expand ? "none" : "flex",
                          // overflow: "hidden",
                        }}
                      />
                    </div>
                    {/* <div className="newsText animate-flicker">
                      <div
                        className="ck-content"
                        dangerouslySetInnerHTML={{
                          __html: descriptionData,
                        }}
                        style={{
                          // backgroundColor: "red",
                          // color: "red",
                          // display: !expand ? "none" : "flex",
                          overflow: "hidden",
                        }}
                      />
                    </div> */}
                  </>
                )}
              </div>
            </div>
            </div>
            {/* </div> */}
          </div>
          <div
            className="screenActive"
            style={{
              marginTop: "10px",
              position: "absolute",
              left: 0,
              margin: "0 auto",
              bottom: "1vw",
              width: "100%",
            }}
          >
            {images.length > 0 && (
              <Slider {...settings} className="terminalBoard">
                {images.map((img, index) => (
                  <div key={index}>
                    {h ? (
                      <div
                        style={{
                          marginLeft: 7,
                          marginRight: 7,
                          borderRadius: w * 0.015,
                          // width: "13vw",
                        }}
                        className="terminalMain"
                      >
                        <div
                          style={{
                            // padding: height * 0.01,
                            alignItems: "center",
                            // justifyContent: "center",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              // marginBottom: "1vw",
                              marginTop: "1.5vw",
                            }}
                          >
                            <img
                              src={img.logo}
                              className="terminalImageLogo"
                              style={{
                                // height: w * 0.04,
                                height: "4vw",
                                width: "8vw",
                                objectFit: "contain",
                                marginBottom: "1vw",
                                // resize: "center",
                                // height: height * 0.07,
                                // width: height * 0.15,
                              }}
                            />
                          </div>
                        </div>
                        <div
                          style={
                            {
                              // background: "red",
                              // marginBottom: "2vw",
                              // marginTop: "0.5vw",
                            }
                          }
                        ></div>
                        <div
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            flexDirection: "column",
                            // marginTop: w * 0.015,
                          }}
                        >
                          <p
                            className="terminalName"
                            style={{
                              fontSize: "1.3vw",
                              lineHeight: "2.3vw",
                              paddingLeft: h * 0.005,
                              paddingRight: h * 0.005,
                              // paddingBottom: "1vw",
                            }}
                          >
                            Avg. Wait Time
                          </p>
                          <div
                            style={{
                              // background: "red",
                              display: "flex",
                              marginBottom: "1vw",
                              alignItems: "flex-end",
                              justifyContent: "flex-end",
                              // height: "4vw",
                            }}
                          >
                            <span
                              className="terminalName"
                              style={{
                                fontSize: "3vw",
                                // lineHeight: "4vw",
                                paddingLeft: h * 0.005,
                                paddingRight: h * 0.005,
                                marginBottom: "-0.6vw",
                              }}
                            >
                              {20}
                            </span>
                            <span
                              className="terminalName"
                              style={{
                                fontSize: "1.5vw",
                                // lineHeight: "4vw",
                                paddingLeft: h * 0.005,
                                paddingRight: h * 0.005,
                                // paddingBottom: "1vw",
                              }}
                            >
                              Min
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <span>132135</span>
                    )}
                  </div>
                ))}
              </Slider>
            )}
          </div>
        </section>
      </Dialog>
    </div>
  );
}
