import React, { useState, useEffect, useRef } from 'react';
// Navigation
import { useHistory, useLocation } from 'react-router-dom'
// Styles
import 'antd/dist/antd.css'
import '../dashboard.scss'
import { Card, } from 'react-bootstrap';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Select } from '@material-ui/core';
import { Modal } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
// Custom components
import DashboardLayout from '../../layouts/dashboardLayout'
import { AppBar, Button, PaginationBar, Navbar, NavbarSearch, Table } from '../../customComponents'
// Constants
import { appConstants } from '../../themes/constants'
// utils methods
import { useStyles } from '../../styles'
import localStorage from "../../utils/localStorage";
import { TimePicker, MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import DateFnsUtils from "@date-io/date-fns";
import moment from 'moment'
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
// Redux
import { useSelector, useDispatch } from 'react-redux'
import { handleNavigationStateAction, getUserListAction, blockUserAction, handleClearStateAction } from '../../store/actions'
import { exportUserAction } from "../../store/actions/userManagementActions"


var selectRole1 = ""
export const UserListing = () => {

    const styleProps = { searchable: true }
    // Hooks declarations
    const classes = useStyles(styleProps);
    const theme = useTheme();
    const history = useHistory();
    const location = useLocation()
    const dispatch = useDispatch()
    const userManagementState = useSelector(state => state.userManagementReducer)
    // Object destructuring
    const { isLoading, userListingResult, currentPage } = userManagementState
    const { paging, listUsers } = userListingResult
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    let permissionsData = localStorage?.getPermissions()
    let employeeIndex = permissionsData?.findIndex((x) => x.name == "user")
    // Global state initialization

    // local state initialization
    const [search, setSearch] = useState("")
    const [offset, setOffset] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sortBy, setSortBy] = useState("")
    const [order, setOrder] = useState(null)
    const [selectedOpenTime, setSelectedOpenTime] = React.useState(null);
    const [openTimePicker, setOpenTimePicker] = useState(false);
    const [filterShow, setFilterShow] = useState(false);
    const [selectedCloseTime, setSelectedCloseTime] = React.useState(null);
    const [closeTimePicker, setCloseTimePicker] = useState(false);
    const [deviceType, setDeviceType] = useState("")

    // Method to fetch listing
    const fetchDataListing = (search, offset, limit, sortBy, order,key) => {
        let startDate = selectedOpenTime ? key == "reset" ? null : moment(selectedOpenTime).format("YYYY-MM-DDT00:00:00") : null
        let endDate = selectedCloseTime ? key == "reset" ? null : moment(selectedCloseTime).format("YYYY-MM-DDTHH:mm:ss") : null
        let deviceType1=deviceType&&  key == "reset" ? null: deviceType
        dispatch(getUserListAction(search, offset, limit, sortBy, order, startDate, endDate, deviceType1))
    }
    
    let siderIndex = localStorage.getLayoutArr()
    //lifecycle hook
    useEffect(() => {
        document.title = appConstants.headerTitle.userManagement;
        if (permissionsData) {
            let index = siderIndex?.findIndex((x) => x.key == "user")
            dispatch(handleNavigationStateAction(index, false))
        }
        else {
            dispatch(handleNavigationStateAction(1, false))
        }
        fetchDataListing(search, offset, limit, sortBy, order)
    }, [])

    //Callback method to handle sorting 
    const handleChange = async (pagination, filters, sorter) => {
        //To fetch listing in ascending order
        if (sorter.order == "ascend") {
            setOrder(1)
            setSortBy(sorter.columnKey)
            fetchDataListing(search, offset, limit, sorter.columnKey, 1)
        } //To fetch listing in descending order
        else if (sorter.order === "descend") {
            setOrder(-1)
            setSortBy(sorter.columnKey)
            fetchDataListing(search, offset, limit, sorter.columnKey, -1)
        } //To fetch listing in normal order
        else {
            setOrder(null)
            setSortBy("")
            fetchDataListing(search, offset, limit, "", null)
        }
    }

    // Callback method to handle block unblock a user
    const handleBlock = (_id, blocked) => {
        dispatch(blockUserAction(_id, blocked))
    }

    // Callback method to handle page chage
    const handlePaginationChange = (e, page) => {
        const offsetTo = (page - 1) * limit
        setOffset(offsetTo)
        fetchDataListing(search, offsetTo, limit, sortBy, order)
    }

    // Callback method to handle searching key
    const handleSearching = async (e) => {
        var tempText = e.target.value
        if (e.target.value.charAt(0) === " ") {
            tempText = e.target.value.replace(/^ +/gm, '');
        }
        setSearch(tempText)
        fetchDataListing(tempText, 0, limit, "", null)
    }

    const columns = [
        {
            key: 'sr',
            title: appConstants.sr,
            dataIndex: '',
            render: (text, record, index) => (index + 1) + (paging?.page?.nextPage - limit),
            ellipsis: false,
            width: 100,
        },
        {
            key: 'userName',
            title: appConstants.username,
            dataIndex: 'userName',
            ellipsis: false,
            sorter: true,
            render: (text, record) => (
                <div>
                    {text ? text : "N/A"}
                </div>
            ),
        },

        {
            key: 'email',
            title: appConstants.emailAddress,
            dataIndex: 'email',
            ellipsis: false,

            render: (text, record) => (
                <div className={classes.emailCell}>
                    {text ? text : "N/A"}
                </div>
            ),
            sorter: true,

        },
        {
            key: 'report_count',
            title: appConstants.reportCount,
            dataIndex: 'report_count',
            ellipsis: false,
            sorter: true,
        },
        {
            key: 'phone_number',
            title: appConstants.phoneNumber,
            dataIndex: 'phone_number',
            ellipsis: false,
            sorter: true,
            render: (phone_number) => <div>{phone_number ? phone_number : "N/A"}</div>
        },
        {
            key: 'device_type',
            title: "Device Type",
            dataIndex: 'device_type',
            ellipsis: false,
            sorter: true,
            render: (device_type, record) => {
                return (
                    <div>
                        {record?.device_type ? record?.device_type == "ios" ? "IOS" : "Android" : 'N/A'}
                    </div>
                )
            }
        },
        {
            key: 'app_version',
            title: "App Version",
            dataIndex: 'app_version',
            ellipsis: false,
            sorter: true,
            render: (created_at, record) => {
                return (
                    <div>
                        {(record?.app_version) ? (record?.app_version) : "N/A"}
                    </div>
                )
            }
        },
        {
            key: 'type',
            title: "User Type",
            dataIndex: 'type',
            ellipsis: false,
            sorter: true,
            render: (type, record) => {
                return (
                    <div>
                        {type == "bot" ? "Bot" : type == "guest" ? "Guest" : "User"}
                    </div>
                )
            }
        },
        {
            sorter: true,
            key: "created_at",
            title: "Created At",
            dataIndex: "created_at",
            render: (created_at, record) => {
                return (
                    <div>
                        {moment(record?.created_at).format('YYYY-MM-DD hh:mm')}
                    </div>
                )
            }
        },
        {
            key: "deleted_at",
            title: "User Status",
            dataIndex: "deleted_at",
            render: (deleted_at, record) => {
                return (
                    <div>
                        {record?.deleted_at == null ? "Active" : "Deleted"}
                    </div>
                )
            }
        },
        {
            key: 'x',
            title: appConstants.action,
            dataIndex: '',
            align: 'center',
            render: (record) => {
                return (
                    <Grid className={classes.buttons}>
                        <Button disabled={(permissionsData) && permissionsData[employeeIndex].view == false} title={appConstants.view}
                            onClick={() => history.push({
                                pathname: '/view-user-details',
                                state: {  // location state
                                    userId: record._id,
                                },
                            })}
                        />
                       <Button disabled={(record?.deleted_at !== null)||(record?.type=='guest') || (permissionsData && permissionsData[employeeIndex].edit == false)} title={appConstants.edit}
                            onClick={() => {
                                dispatch(handleClearStateAction("clearUserDetailsState"))
                                history.push({
                                    pathname: '/edit-user',
                                    state: {  // location state
                                        userId: record._id,
                                    },
                                })
                            }
                            }
                        />
                        <Button disabled={(record?.deleted_at !== null) || (record?.type == "bot")||(record?.type=='guest') || (permissionsData && permissionsData[employeeIndex].block == false)} title={!record.blocked ? appConstants.block : appConstants.unblock}
                            onClick={() => handleBlock(record._id, !record.blocked)}
                            width="100px"
                        />
                    </Grid>
                )
            },
        },
    ];

    const values = {
        deviceType: deviceType
    };

    const validationSchema = Yup.object().shape({
        deviceType: Yup.string()
    });

    const handleFormSubmit = (values, { setSubmitting }) => {
        setDeviceType(values.deviceType)
        setFilterShow(false);
        setDeviceType(deviceType)
        selectRole1 = values.deviceType
        fetchDataListing(search, 0, limit, sortBy, order);
        // let startDate = selectedOpenTime ? moment(selectedOpenTime).format("YYYY-MM-DDT00:00:00") : null
        // let endDate = selectedCloseTime ? moment(selectedCloseTime).format("YYYY-MM-DDT23:59:59") : null
        // dispatch(getUserListAction(search, offset, limit, sortBy, order, startDate, endDate, deviceType))
    };

    const handleFormReset = (key) => {
        // setDeviceType(null)
        setFilterShow(false);
        fetchDataListing(search, 0, limit, sortBy, order, "reset");
        // let startDate = null
        // let endDate = null
        // let deviceType=null
        // dispatch(getUserListAction(search, offset, limit, sortBy, order, startDate, endDate, deviceType))
    };

    const closeModal = () => {
        setFilterShow(false)
    }

    return (
        <DashboardLayout>
            <AppBar breadcrumbs={[{ route: '/users', name: appConstants.userManagement }]} />
            <Grid container className={classes.buttonBarWrapper} >
                <Button title="Export User"
                    onClick={() => {
                        dispatch(exportUserAction())
                    }}
                    mr mb />
                <Button
                    title="Filter"
                    onClick={() => {
                        setFilterShow(true);
                        // dispatch(getRolesListAction())
                    }}
                    mr
                    mb
                />
            </Grid>
            <Modal
                onCancel={() => closeModal()} visible={filterShow} footer={null} centered maskClosable={false}>
                <Formik
                    enableReinitialize
                    initialValues={values}
                    validationSchema={validationSchema}
                    onSubmit={handleFormSubmit}
                >
                    {({
                        values,
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <div style={{ zIndex: 999999 }}>
                                <span style={{ color: "#000", fontSize: 20 }}>{appConstants.filter}</span>
                                <div
                                    style={{ alignSelf: "center", marginTop: "15px" }}
                                >
                                    <span style={{ color: "#000", fontSize: 14 }}>
                                        {appConstants.startDate}
                                    </span>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                        <DatePicker
                                            style={{ width: "100%" }}
                                            ampm={true}
                                            className='date'
                                            placeholder="Select Start Date"
                                            name="startDate"
                                            value={selectedOpenTime}
                                            onAccept={(time) => {
                                                setOpenTimePicker(false);
                                            }}
                                            disableFuture
                                            format="yyyy-MM-dd"
                                            onClose={() => setOpenTimePicker(false)}
                                            onChange={(time) =>
                                                setSelectedOpenTime(time)
                                            }
                                            open={openTimePicker}
                                            onOpen={() => setOpenTimePicker(true)}
                                            InputProps={{
                                                endAdornment: (
                                                    <FontAwesomeIcon
                                                        icon={faCalendar}
                                                        className={classes.dropdownIcon}
                                                    />
                                                )
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div
                                    style={{ alignSelf: "center", marginTop: "15px" }}
                                >
                                    <span style={{ color: "#000", fontSize: 14 }}>
                                        {appConstants.endDate}
                                    </span>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                        <DatePicker
                                            style={{ width: "100%" }}
                                            ampm={true}
                                            placeholder="Select End Date"
                                            name="endDate"
                                            className='date'
                                            minDate={moment(selectedOpenTime).add(0, "days")}
                                            value={selectedCloseTime}
                                            onAccept={(time) => {
                                                setCloseTimePicker(false);
                                            }}
                                            format="yyyy-MM-dd"
                                            onClose={() => setCloseTimePicker(false)}
                                            disableFuture
                                            onChange={(time) =>
                                                setSelectedCloseTime(time)
                                            }
                                            open={closeTimePicker}
                                            onOpen={() => setCloseTimePicker(true)}
                                            InputProps={{
                                                endAdornment: (
                                                    <FontAwesomeIcon
                                                        icon={faCalendar}
                                                        className={classes.dropdownIcon}
                                                    />
                                                )
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div
                                    style={{ alignSelf: "center", marginTop: "15px" }}
                                >
                                    <span style={{ color: "#000", fontSize: 14 }}>
                                        Device Type
                                    </span>
                                    <Select
                                        native
                                        id="deviceType"
                                        className={classes.selectInput}
                                        value={deviceType}
                                        onChange={(e) => setDeviceType(e.target.value)}
                                        inputProps={{
                                            name: 'deviceType',
                                            id: 'deviceType',
                                        }}
                                        IconComponent={() => <FontAwesomeIcon icon={faChevronDown} className={classes.dropdownIcon} />}
                                    >
                                        <option value="">{appConstants.selectType}</option>
                                        <option value="android">Android</option>
                                        <option value="ios">IOS</option>

                                        {/* {roles.length > 0 && roles?.map(
                                            (time, index) => {
                                                return (
                                                    <option value={time?._id}>
                                                        {time?.name}
                                                    </option>
                                                );
                                            }
                                        )} */}
                                    </Select>
                                </div>
                                <div style={{ marginTop: "20px" }}>
                                </div>
                                <div
                                    style={{
                                        alignItems: "center",
                                        justifyContent: "space-around",
                                        display: "flex",
                                        marginTop: "20px",
                                    }}
                                >
                                    <Button
                                        title="Remove"
                                        type="reset"
                                        className="mr-3"
                                        width="108px"
                                        onClick={() => {
                                            setSelectedCloseTime(null)
                                            setSelectedOpenTime(null)
                                            setDeviceType(null)
                                            setTimeout(() => {
                                                handleFormReset();
                                            }, 100);
                                        }}
                                    />
                                    <Button
                                        title="Apply"
                                        type="submit"
                                        className="mr-3"
                                        width="108px"
                                    />
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </Modal>
            <Navbar title={appConstants.userManagement} searchable value={search}
                onChange={(e) => handleSearching(e)} />
            <Card className={classes.card}>
                {matches &&
                    <Box className={classes.searchBox}>
                        <NavbarSearch value={search} onChange={e => handleSearching(e)} />
                    </Box>
                }
                <Grid container>
                    <Grid className={classes.tableContainerRow}>
                        <Table
                            rowKey={record => record.key}
                            loading={isLoading}
                            columns={columns}
                            dataSource={listUsers}
                            onChange={handleChange}
                            searching={search.length > 0}
                        />
                    </Grid>
                </Grid>
                <PaginationBar
                    totalCount={paging?.page?.totalCount}
                    count={Math.ceil(paging?.page?.totalCount / limit)}
                    currentPage={paging?.page?.currentPage}
                    nextPage={paging?.page?.nextPage}
                    onChange={(e, page) => handlePaginationChange(e, page)}
                    prevPage={paging?.page?.nextPage - limit}
                />
            </Card>
            {/* </Grid> */}
        </DashboardLayout>
    );
}
