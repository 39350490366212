import React, { useState, useEffect, useRef } from 'react';
// Navigation
import { useHistory, useLocation } from 'react-router-dom'
// Styles
import 'antd/dist/antd.css'
import '../dashboard.scss'
// antd
import { Image, Spin, Tooltip } from 'antd';
import { Card, Table as BSTable, } from 'react-bootstrap';
// Mui Components
import { Grid, useTheme, } from '@material-ui/core';
import { Modal } from "antd";
// Custom components
import DashboardLayout from '../../layouts/dashboardLayout'
import { AppBar, InputField, Navbar, Button } from '../../customComponents'
// Constants
import { AppImages } from '../../themes/appImages'
import { appConstants } from '../../themes/constants'
// utils methods
import cl from '../../utils/cl'
import { useStyles } from '../../styles'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getPostDetailsAction, likePostAction } from '../../store/actions/postManagementAction';
import {
    faComment
} from "@fortawesome/free-solid-svg-icons";
// Redux
import { useSelector, useDispatch } from 'react-redux'
import { getReactionType } from '../../utils/helpers';
// import { useLongPress } from 'use-long-press';
import useLongPress from "./useLongPress";

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export const PostDetails = () => {

    // Hooks declarations
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const location = useLocation()
    const dispatch = useDispatch()
    const [openModal, setOpenModal] = useState(false);
    const [imageLike, setImageLike] = useState(AppImages.thumbs_up)

    const postListingData = useSelector(state => state.PostManagementReducer)

    // Global state initialization
    const { postDetails, isLoading, isTableDataLoading } = postListingData

    // Lifecycle Hooks
    useEffect(() => {
        document.title = appConstants.headerTitle.detailsPost;
        // if (location?.state?.postId) {
        dispatch(getPostDetailsAction({ post_id: location?.state?.postId, user_id: location?.state?.botUserId }))
        // } else {
        //     history.push('/postListing')
        // }
    }, [])

    useEffect(() => {
        setImageLike(getReactionType(postDetails?.isLike?.type))
    }, [postDetails])

    const closeModal = () => {
        setOpenModal(false)
    }

    const emojis = [
        {
            name: AppImages.thumbs_up,
            type: 'like'
        },
        {
            name: AppImages.thinking_face,
            type: 'thinking'
        },

        {
            name: AppImages.grinning_face,
            type: 'grinningSmile'
        },
        {
            name: AppImages.thumbs_down,
            type: 'dislike'
        },
        {
            name: AppImages.rollingEyes,
            type: 'confuse'
        },
        {
            name: AppImages.angry,
            type: 'angry'
        },
    ]

    const onLongPress = () => {
        setOpenModal(true)
    };

    const onClick = () => {
        if (postDetails?.isLike !== null) {
            setImageLike(AppImages.NotLike)
            dispatch(likePostAction({
                postId: postDetails?._id, type: null, userId: location?.state?.botUserId,
                post_id: postDetails?._id,
                user_id: location?.state?.botUserId, is_like: 0
            }))
        } else {
            setImageLike(emojis[0].name)
            dispatch(likePostAction({
                postId: postDetails?._id, type: emojis[0].type, userId: location?.state?.botUserId,
                user_id: location?.state?.botUserId,
                post_id: postDetails?._id,
                is_like: 1
            }))
        }

    }

    const defaultOptions = {
        shouldPreventDefault: true,
        delay: 500,
    };

    const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);

    // const bind = useLongPress(() => {
    //     setOpenModal(true)
    // });


    return (
        <DashboardLayout>
            <AppBar breadcrumbs={[{ route: '/postListing', name: appConstants.postManagement }, { route: '/post-details', name: appConstants.postDetails, state: location?.state?.userId }]} />
            <Navbar title="Post Details" />
            <Modal
                className='post-modal'
                onCancel={() => closeModal()} visible={openModal} footer={null} centered maskClosable={true}>
                <div>
                    {
                        emojis.map((item, index) => {
                            return (
                                <img src={item.name} height={"55px"} width={"55px"} onClick={() => {
                                    setOpenModal(false)
                                    setImageLike(item.name)
                                    dispatch(likePostAction({
                                        postId: postDetails?._id,
                                        post_id: postDetails?._id,
                                        user_id: location?.state?.botUserId,
                                        type: item.type, userId: location?.state?.botUserId, is_like: 1
                                    }))
                                }}></img>
                            )
                        })
                    }

                </div>
            </Modal>
            <Card className={classes.card}>
                <Grid container>
                    <Grid className={classes.tableContainerRow}>
                        <BSTable striped bordered className={classes.bsTableHeight}>
                            {
                                isTableDataLoading ? <thead className={classes.bsHeaderTable}>
                                    <Spin className={classes.tableDataSpinner} />
                                </thead>
                                    :
                                    <tbody>
                                        {postDetails?.share &&
                                            <tr>
                                                <td className={classes.rowKey}>
                                                    Shared Post Description
                                                </td>
                                                <td className={classes.rowValue}>
                                                    <div className={classes.inputWrapper}>
                                                        <textarea
                                                            style={{ height: "14rem", resize: "none" }}
                                                            className="textAreaNew"
                                                            placeholder="description"
                                                            name="description"
                                                            type="description"
                                                            value={postDetails?.share?.description}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>}
                                        {!postDetails?.share &&
                                            <>  <tr>
                                                <td className={classes.rowKey}>
                                                    Description
                                                </td>
                                                <td className={classes.rowValue}>
                                                    <div className={classes.inputWrapper}>
                                                        <textarea
                                                            style={{ height: "14rem", resize: "none" }}
                                                            className="textAreaNew"
                                                            placeholder="description"
                                                            name="description"
                                                            type="description"
                                                            value={postDetails?.description}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                                {
                                                    postDetails?.images?.length > 0 &&
                                                    <tr>
                                                        <td className={classes.rowKey}>Images</td>
                                                        <td className={classes.rowValue}>
                                                            <div className={classes.inputWrapper}
                                                                style={{ display: "-webkit-box", marginLeft: "4px" }}>

                                                                {
                                                                    postDetails?.images?.map((item, index) => {
                                                                        return (
                                                                            <div style={{ marginRight: "5%" }}>
                                                                                <Image
                                                                                    // src={appConstants.baseURL + news.media}
                                                                                    src={appConstants.baseURL + item?.url}
                                                                                    className={classes.terminalImage}

                                                                                    style={{ borderRadius: "50px", border: "2px solid lightgrey" }}
                                                                                />
                                                                            </div>

                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    postDetails?.video &&
                                                    <tr>
                                                        <td className={classes.rowKey}>Video</td>
                                                        <td className={classes.rowValue}>
                                                            <div className={classes.inputWrapper} style={{ marginLeft: "4px" }}>

                                                                <video
                                                                    controls
                                                                    style={{
                                                                        width: "100%",
                                                                        objectFit: "contain",
                                                                        height: "200px"
                                                                    }}
                                                                >
                                                                    <source src={appConstants.baseURL + postDetails?.video}
                                                                        className={classes.terminalImage}
                                                                        type="video/mp4"
                                                                    ></source>
                                                                </video>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    postDetails?.tag_friends?.length > 0 &&
                                                    <tr>
                                                        <td className={classes.rowKey}>Tagged Friends</td>
                                                        <td className={classes.rowValue}>
                                                            <div className={classes.inputWrapper}>
                                                                {postDetails?.tag_friends?.map((i, j) => {
                                                                    return (
                                                                        <div className='location_details'>
                                                                            {i?.name}
                                                                        </div>
                                                                    )
                                                                })}
                                                                {/* <InputField
                                                            placeholder="Tagged Friends"
                                                            name="email"
                                                            // onChange={handleChange}
                                                            type="email"
                                                            value={postDetails?.tag_friends}
                                                            disabled={true}
                                                        /> */}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    postDetails?.post_location &&
                                                    <tr>
                                                        <td className={classes.rowKey}>Check In</td>
                                                        <td className={classes.rowValue}>
                                                            <div className={classes.inputWrapper} >
                                                                <div className='location_details'>{postDetails?.post_location}</div>
                                                                {/* <Tooltip
                                                            placement="right"
                                                            overlayStyle={{ zIndex: "999999" }}
                                                        >
                                                            <InputField
                                                                placeholder="Check in"
                                                                name="Check in"
                                                                // onChange={handleChange}
                                                                value={postDetails?.post_location}
                                                            // disabled={true}
                                                            />
                                                        </Tooltip> */}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                }
                                            </>}
                                        <tr>
                                            <td className={classes.rowKey}>Like & Comment</td>
                                            <td className={classes.rowValue}>
                                                {
                                                 location?.state?.botUserId ? 
                                                <div className={classes.inputWrapper}>
                                                    <img {...longPressEvent} src={imageLike} height={"55px"} width={"55px"}
                                                        style={{ marginRight: "20px", cursor: "pointer" }}
                                                    // onClick={() => setOpenModal(true)}
                                                    ></img>
                                                    <FontAwesomeIcon
                                                        icon={faComment}
                                                        style={{ width: "50px", height: "50px", marginBottom: "-5%", cursor: "pointer" }}
                                                        color={"#196EA1"}
                                                        onClick={() => history.push({
                                                            pathname: "/comment-screen",
                                                            state: {  // location state
                                                                userId: postDetails?._id,
                                                                botUserId: location?.state?.botUserId,
                                                                postId: location?.state?.postId,

                                                            },
                                                        })}
                                                    />
                                                </div>
                                                 :
                                                 <span>First create a bot user to like and comment post.</span>
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                            }
                        </BSTable>
                    </Grid>
                </Grid>
            </Card>
        </DashboardLayout>
    );
}
