import React, { useState, useEffect, useRef } from "react";
// Navigation
import { useHistory, useLocation } from "react-router-dom";
// Styles
import "antd/dist/antd.css";
import "../dashboard.scss";
import { Modal } from "antd";
import { Card } from "react-bootstrap";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
// Custom components
import DashboardLayout from "../../layouts/dashboardLayout";
import { TimePicker, MuiPickersUtilsProvider, DatePicker, } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import DateFnsUtils from "@date-io/date-fns";
import { createMuiTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import localStorage from "../../utils/localStorage";
// import moment from "moment";
import {
  AppBar,
  Button,
  PaginationBar,
  Navbar,
  NavbarSearch,
  Table,
  InputField,
} from "../../customComponents";
// Constants
import { appConstants } from "../../themes/constants";
// utils methods
import { useStyles } from "../../styles";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  handleNavigationStateAction,
  getUserListAction,
  blockUserAction,
  handleClearStateAction,
  getRadarEntryExitListAction,
} from "../../store/actions";
import moment from "moment";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";


const useStyles1 = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center"
  },
  datePicker: {
    '& .MuiDialog-scrollPaper': {
      display: "flex",
      justifyContent: "center"
    }
  }
}));

var userIdFilterArray = [];
var terminalIdFilterArray = [];
export const RadarEntry = () => {
  const styleProps = { searchable: true };
  // Hooks declarations
  const classes = useStyles(styleProps);
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const classes1 = useStyles1();
  const [filterShow, setFilterShow] = useState(false);
  const [userIdFilterState, setUserIdFilterState] = useState("");
  const [terminalIdFilterState, setTerminalIdFilterState] = useState("");
  const [openTimePicker, setOpenTimePicker] = useState(false);
  const [selectedOpenTime, setSelectedOpenTime] = React.useState(null);
  const [selectedCloseTime, setSelectedCloseTime] = React.useState(null);
  const [closeTimeError, setCloseTimeError] = useState(null);
  const [closeTimePicker, setCloseTimePicker] = useState(false);
  const permissionsData = localStorage?.getPermissions()
  const employeeIndex = permissionsData?.findIndex((x) => x.name == "location")
  const siderIndex = localStorage.getLayoutArr()
  const values = {
    userIdFilter: userIdFilterState,
    terminalIdFilter: terminalIdFilterState,
  };

  const validationSchema = Yup.object().shape({
    userIdFilter: Yup.string()
      // .max(30, ValidationConstants.terminalAreaName.long)
      // .required("Please enter ")
      // .test("trim", "min", (value) => value?.trim()?.length > 0)
      // .test("trim", "dsgd", (value) => value?.trim()?.length > 5),
      .matches(/^[A-Za-z0-9, ]*$/, "Special characters not allowed except ','")
      .matches(/^(\S+$)/, "Space is not allowed"),
    terminalIdFilter: Yup.string()
      .matches(/^[A-Za-z0-9, ]*$/, "Special characters not allowed except ','")
      .matches(/^(\S+$)/, "Space is not allowed"),
  });

  const radarManagementState = useSelector(
    (state) => state.radarManagementReducer
  );
  // Object destructuring
  const { isLoading, radarListingResult, currentPage } = radarManagementState;
  const { paging, list_saved_location } = radarListingResult;
  // const {paging} = userListingResult
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  // Global state initialization

  // local state initialization
  const [search, setSearch] = useState("");
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState("");
  const [order, setOrder] = useState(null);

  // Method to fetch listing
  const fetchDataListing = (search, offset, limit, sortBy, order, key) => {

    dispatch(
      getRadarEntryExitListAction(
        search,
        offset,
        limit,
        sortBy,
        order,
        userIdFilterArray,
        terminalIdFilterArray,
        selectedOpenTime ? key == "reset" ? null : moment(moment(selectedOpenTime).startOf('day')).utc().format("YYYY-MM-DDTHH:mm:ss") : null,
        selectedCloseTime ? key == "reset" ? null : moment(moment(selectedCloseTime).endOf('day')).utc().format("YYYY-MM-DDTHH:mm:ss") : null
      )
    );
  };

  //lifecycle hook
  useEffect(() => {
    userIdFilterArray = [];
    terminalIdFilterArray = [];
    document.title = appConstants.headerTitle.userManagement;
    if (permissionsData) {
      let index = siderIndex?.findIndex((x) => x.key == "location")
      dispatch(handleNavigationStateAction(index, false))
  }
  else {
    dispatch(handleNavigationStateAction(6, false));
  }
    fetchDataListing(search, offset, limit, sortBy, order);
  }, []);

  //Callback method to handle sorting
  const handleChange = async (pagination, filters, sorter) => {
    //To fetch listing in ascending order
    if (sorter.order == "ascend") {
      setOrder(1);
      setSortBy(sorter.columnKey);
      fetchDataListing(search, offset, limit, sorter.columnKey, 1);
    } //To fetch listing in descending order
    else if (sorter.order === "descend") {
      setOrder(-1);
      setSortBy(sorter.columnKey);
      fetchDataListing(search, offset, limit, sorter.columnKey, -1);
    } //To fetch listing in normal order
    else {
      setOrder(null);
      setSortBy("");
      fetchDataListing(search, offset, limit, "", null);
    }
  };

  // Callback method to handle page chage
  const handlePaginationChange = (e, page) => {
    const offsetTo = (page - 1) * limit;
    setOffset(offsetTo);
    fetchDataListing(search, offsetTo, limit, sortBy, order);
  };

  // Callback method to handle searching key
  const handleSearching = async (e) => {
    var tempText = e.target.value
    if (e.target.value.charAt(0) === " ") {
      tempText = e.target.value.replace(/^ +/gm, '');
    }
    setSearch(tempText)
    fetchDataListing(tempText, 0, limit, "", null)
  };

  const handleFormSubmit = (values, { setSubmitting }) => {
    setUserIdFilterState(values.userIdFilter);
    setTerminalIdFilterState(values.terminalIdFilter);
    setFilterShow(false);
    const userIdArray = values.userIdFilter.split(",");
    userIdFilterArray = values.userIdFilter.length > 0 ? userIdArray : [];
    const terminalIdArray = values.terminalIdFilter.split(",");
    terminalIdFilterArray =
      values.terminalIdFilter.length > 0 ? terminalIdArray : [];
    fetchDataListing(search, 0, limit, sortBy, order);
    // setSubmitting(false)
    // dispatch(addTerminalAreaAction(values.terminalAreaName.trim()))
  };

  const handleFormReset = () => {
    setUserIdFilterState("");
    setTerminalIdFilterState("");
    setFilterShow(false);
    userIdFilterArray = [];
    terminalIdFilterArray = [];
    fetchDataListing(search, 0, limit, sortBy, order, "reset");

    // setSubmitting(false)
    // dispatch(addTerminalAreaAction(values.terminalAreaName.trim()))
  };

  const columns = [
    {
      key: "sr",
      title: appConstants.sr,
      dataIndex: "",
      render: (text, record, index) =>
        index + 1 + (paging?.page?.nextPage - limit),
      ellipsis: false,
      width: 100,
    },
    {
      key: "userid",
      title: appConstants.userID,
      dataIndex: "userid",
      ellipsis: false,
      sorter: true,
    },
    {
      key: "username",
      title: appConstants.username,
      dataIndex: "userName",
      ellipsis: false,
      sorter: true,
    },
    {
      key: "terminal_id",
      title: appConstants.terminalID,
      dataIndex: "terminal_id",
      ellipsis: false,
      sorter: true,
    },
    {
      key: "terminal_name",
      title: appConstants.terminalName,
      dataIndex: "terminal_name",
      ellipsis: false,
      sorter: true,
    },

    {
      key: "timestamp",
      title: appConstants.createdAt,
      dataIndex: "timestamp",
      ellipsis: false,
      sorter: true,
      render: (timestamp) => {
        return <div>{moment(timestamp).format("YYYY-MM-DD HH:mm")}</div>;
      },
    },
    {
      key: "action",
      title: appConstants.action,
      dataIndex: "type",
      align: "center",
      render: (type) => (type == 1 ? appConstants.enter : appConstants.exit),
    },
  ];

  return (
    <DashboardLayout>
      <AppBar
        breadcrumbs={[{ route: "/radar", name: appConstants.radarManagement }]}
      />
      <Modal
        // onOk={handelOK}
        onCancel={() => setFilterShow(false)}
        visible={filterShow}
        // className="new-modal"
        footer={null}
      // width="40%"
      // style={{height: "450px", width: "1000px",alignItems:"center",justifyContent:"center"}}
      >
        <Formik
          enableReinitialize
          initialValues={values}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        // onReset={handleFormReset}
        >
          {({
            values,
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <div style={{ zIndex: 999999 }}>
                <span style={{ color: "#000", fontSize: 20 }}>Filters</span>
                <div
                  // className={classes.inputWrapper}
                  style={{ alignSelf: "center", marginTop: "15px" }}
                >
                  <span style={{ color: "#000", fontSize: 14 }}>User ID</span>
                  <InputField
                    id="userIdFilter"
                    placeholder={appConstants.userIdFilter}
                    name="userIdFilter"
                    error={Boolean(touched.userIdFilter && errors.userIdFilter)}
                    helperText={touched.userIdFilter && errors.userIdFilter}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="name"
                    value={values.userIdFilter}

                  // maxLength={30}
                  />
                </div>
                <div
                  // className={classes.inputWrapper}
                  style={{ alignSelf: "center", marginTop: "15px" }}
                >
                  <span style={{ color: "#000", fontSize: 14 }}>
                    Terminal ID
                  </span>
                  <InputField
                    id="terminalIdFilter"
                    placeholder={appConstants.terminalIdFilter}
                    name="terminalIdFilter"
                    error={Boolean(
                      touched.terminalIdFilter && errors.terminalIdFilter
                    )}
                    helperText={
                      touched.terminalIdFilter && errors.terminalIdFilter
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="name"
                    value={values.terminalIdFilter}
                  />
                </div>
                <div
                  // className={classes.inputWrapper}
                  style={{ alignSelf: "center", marginTop: "15px" }}
                >
                  <span style={{ color: "#000", fontSize: 14 }}>
                    Start Date
                  </span>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} classes={classes1.root}>
                    <DatePicker
                      style={{ width: "100%" }}
                      DialogProps={{ className: classes1.datePicker }}
                      ampm={true}
                      className='date'
                      placeholder="Select Start Date"
                      name="startDate"
                      value={selectedOpenTime}
                      maxDate={moment(selectedCloseTime).subtract(0, "days")}
                      onAccept={(time) => {
                        setOpenTimePicker(false);
                      }}
                      format="yyyy-MM-dd"
                      onClose={() => setOpenTimePicker(false)}
                      // minDate={moment(new Date).add(1)}
                      onChange={(time) =>
                        setSelectedOpenTime(time)
                      }
                      disableFuture
                      open={openTimePicker}
                      onOpen={() => setOpenTimePicker(true)}
                      InputProps={{
                        endAdornment: (
                          <FontAwesomeIcon
                            icon={faCalendar}
                            className={classes.dropdownIcon}
                          />
                        )
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div
                  // className={classes.inputWrapper}
                  style={{ alignSelf: "center", marginTop: "15px" }}
                >
                  <span style={{ color: "#000", fontSize: 14 }}>
                    End Date
                  </span>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} classes={classes1.root}>
                    <DatePicker
                      style={{ width: "100%" }}
                      DialogProps={{ className: classes1.datePicker }}
                      ampm={true}
                      placeholder="Select End Date"
                      name="endDate"
                      className='date'
                      minDate={moment(selectedOpenTime).add(0, "days")}
                      // openTo="minutes"
                      value={selectedCloseTime}
                      onAccept={(time) => {
                        setCloseTimePicker(false);
                      }}
                      format="yyyy-MM-dd"
                      onClose={() => setCloseTimePicker(false)}
                      disableFuture
                      onChange={(time) =>
                        setSelectedCloseTime(time)
                      }
                      open={closeTimePicker}
                      onOpen={() => setCloseTimePicker(true)}
                      InputProps={{
                        endAdornment: (
                          <FontAwesomeIcon
                            icon={faCalendar}
                            className={classes.dropdownIcon}
                          />
                        )
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <span style={{ color: "#e92a2a", fontSize: 10 }}>
                    * User ID & Terminal ID can be added multple using comma
                    separated (e.g. UserID1,UserID2)
                  </span>
                </div>

                <div
                  style={{
                    alignItems: "center",
                    justifyContent: "space-around",
                    display: "flex",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    title="Remove"
                    type="reset"
                    className="mr-3"
                    width="108px"
                    onClick={() => {
                      setFieldValue("userIdFilter", "");
                      setFieldValue("terminalIdFilter", "");
                      setSelectedCloseTime(null)
                      setSelectedOpenTime(null)
                      handleFormReset();
                    }}
                  />
                  <Button
                    title="Apply"
                    type="submit"
                    className="mr-3"
                    width="108px"
                  />
                </div>
              </div>
            </form>
          )}
        </Formik>
      </Modal>
      <Grid container className="mt-4">
        <Grid container className={classes.buttonBarWrapper}>
          <Button
            title="Refresh"
            onClick={() => {
              fetchDataListing(search, offset, limit, sortBy, order);
            }}
            width="108px"
            mr
            mb
          />
          <Button
            title="Filter"
            onClick={() => {
              setFilterShow(true);
            }}
            width="108px"
            // style={{}}
            mr
            mb
          />
          {/* <span>Filter</span> */}
        </Grid>
        <Navbar
          title={appConstants.radarManagement}
          searchable
          value={search}
          onChange={(e) => handleSearching(e)}
        />
        <Card className={classes.card}>
          {matches && (
            <Box className={classes.searchBox}>
              <NavbarSearch
                value={search}
                onChange={(e) => handleSearching(e)}
              />
            </Box>
          )}
          <Grid container>
            <Grid className={classes.tableContainerRow}>
              <Table
                rowKey={(record) => record.key}
                loading={isLoading}
                columns={columns}
                dataSource={list_saved_location}
                onChange={handleChange}
                searching={search.length > 0}
              />
            </Grid>
          </Grid>
          <PaginationBar
            totalCount={paging?.page?.totalCount}
            count={Math.ceil(paging?.page?.totalCount / limit)}
            currentPage={paging?.page?.currentPage}
            nextPage={paging?.page?.nextPage}
            onChange={(e, page) => handlePaginationChange(e, page)}
            prevPage={paging?.page?.nextPage - limit}
          />
        </Card>
      </Grid>
    </DashboardLayout>
  );
};
