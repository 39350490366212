import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import 'antd/dist/antd.css';
import '../dashboard.scss';
import { Card, Table as BSTable, Toast, } from 'react-bootstrap';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Spin } from 'antd';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, FormHelperText } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { Select } from "@material-ui/core";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DashboardLayout from '../../layouts/dashboardLayout';
import { InputField, AppBar, Button, Navbar, NavbarSearch, ConfirmationModal } from '../../customComponents';
import { appConstants, ValidationConstants } from '../../themes/constants';
import { useStyles } from '../../styles';
import { useSelector, useDispatch } from 'react-redux';
import { handleNavigationStateAction, getUserListAction } from '../../store/actions';
import { deleteStateAction } from "../../store/actions/stateListingActions";
import { pushNotificationAction } from "../../store/actions/pushNotificationAction";
import { getTerminalListAction } from "../../store/actions/terminalManagementActions";
import { getTerminalAreaListAction } from "../../store/actions/terminalAreaManagementActions";
import { Select as AntdSelect } from 'antd';
import localStorage from "../../utils/localStorage";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns'
import { toast } from 'react-toastify';

const { Option } = AntdSelect;
// Global constants

export const PushNotifications = () => {
    const styleProps = { searchable: true }
    const classes = useStyles(styleProps);
    const dispatch = useDispatch()
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const [search, setSearch] = useState("")
    const [confirm, setConfirm] = useState(false)
    const [stateToDelete, setStateToDelete] = useState(null)
    const [disableButton, setDisableButton] = useState()
    const [templates, setTemplates] = useState("allUsers")
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [isSelected3, SetIsSelected3] = useState(true)
    const [selectedUser, setSelectedUser] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [userFollowing, setUserFollowing] = useState(null)
    const [userInside, setUserInside] = useState(null)
    const [favouriteRegion, setFavouriteRegion] = useState(null)
    const [insideRegion, setInsideRegion] = useState(null)
    const [selectedTerminalError, setSelectedTerminalError] = useState(false)
    const [notificationType, setNotificationType] = useState("admin")
    const [region, setRegion] = useState(0)
    const [radioButton, setRadioButton] = useState("Now")
    const [openTimePicker, setOpenTimePicker] = useState(false)
    // const [selectedOpenTime, setSelectedOpenTime] = useState(moment().add(1, 'hours').format("YYYY-MM-DD HH:mm"));
    const [selectedOpenTime, setSelectedOpenTime] = useState(moment());
    const [scheduledError, setScheduledError] = useState(false)
    const terminalManagementstate = useSelector(state => state.terminalManagementReducer)
    const terminalAreaManagementState = useSelector(state => state.terminalAreaManagementReducer)
    const userDetailsState = useSelector(state => state.userManagementReducer)
    // const { isTableDataLoading } = useSelector(state => state.stateListingReducer)
    // const { isLoading,isTableDataLoading } = useSelector(state => state.pushNotificationReducer)
    let permissionsData = localStorage?.getPermissions()
    let siderIndex = localStorage.getLayoutArr()

    const validationSchema = Yup.object().shape({
        title: Yup.string().test('trim', ValidationConstants.title.empty, value => value?.trim()?.length > 0).min(2, ValidationConstants.title.short).max(50, ValidationConstants.title.long).required(ValidationConstants.title.empty),
        description: Yup.string().test('trim', ValidationConstants.description.empty, value => value?.trim()?.length > 0).min(2, ValidationConstants.description.short).max(1000, ValidationConstants.description.long).required(ValidationConstants.description.empty),
        notificationType: Yup.string().required("Please select notification type.")
    })

    const values = {
        title: title,
        description: description,
        terminalId: '',
        regionId: '',
        notificationType: "admin"
    }

    useEffect(() => {
        document.title = appConstants.headerTitle.pushNotifications;
        dispatch(getTerminalListAction("", null, null, "", ""))
        if (permissionsData) {
            let index = siderIndex?.findIndex((x) => x.key == "notification")
            dispatch(handleNavigationStateAction(index, false))
        }
        else {
            dispatch(handleNavigationStateAction(9, false))
        }
        dispatch(getTerminalAreaListAction("", null, null, "", ""))
        dispatch(getUserListAction("", null, null, "", ""));
    }, [])

    const handleSearching = async (e) => {
        var tempText = e.target.value
        if (e.target.value.charAt(0) === " ") {
            tempText = e.target.value.replace(/^ +/gm, '');
        }
        setSearch(tempText)
    }

    const handleFormSubmit = (values, { setSubmitting }, e) => {
        let currentTime = moment().add(1, 'hours').format("YYYY-MM-DD HH:mm")
        let selectedTime = moment(selectedOpenTime).format("YYYY-MM-DD HH:mm")
        if (radioButton == "Scheduled") {
            if (selectedTime >= currentTime) {
                if (selectedTerminalError == false) {
                    if (!isSelected3) {
                        dispatch(pushNotificationAction(values.title, values.description,radioButton, "customUsers", null, null, selectedUser, values.notificationType,
                            radioButton == "Now" ? null : moment(selectedOpenTime).utc().format("YYYY-MM-DDTHH:mm:ss")))
                    }
                    else if (isSelected3 && templates == "allUsers") {
                        dispatch(pushNotificationAction(values.title, values.description,radioButton, templates, null, null, null,
                            values.notificationType,
                            radioButton == "Now" ? null : moment(selectedOpenTime).utc().format("YYYY-MM-DDTHH:mm:ss")))
                    }
                    else if (isSelected3 && templates == "region") {
                        dispatch(pushNotificationAction(values.title, values.description,radioButton,
                            templates, null, insideRegion, null, values.notificationType,
                            radioButton == "Now" ? null : moment(selectedOpenTime).utc().format("YYYY-MM-DDTHH:mm:ss")))
                    }
                    else if (isSelected3 && templates == "usersFollowingTerminal") {
                        dispatch(pushNotificationAction(values.title, values.description,radioButton,
                            templates, userFollowing, null, null, values.notificationType,
                            radioButton == "Now" ? null : moment(selectedOpenTime).utc().format("YYYY-MM-DDTHH:mm:ss")))
                    }
                    else if (isSelected3 && templates == "usersInTerminal") {
                        dispatch(pushNotificationAction(values.title, values.description,radioButton,
                            templates, userInside, null, null, values.notificationType,
                            radioButton == "Now" ? null : moment(selectedOpenTime).utc().format("YYYY-MM-DDTHH:mm:ss")))
                    }
                    else if (isSelected3 && templates == "favouriteRegion") {
                        dispatch(pushNotificationAction(values.title, values.description,radioButton,
                            templates, null, favouriteRegion, null, values.notificationType,
                            radioButton == "Now" ? null : moment(selectedOpenTime).utc().format("YYYY-MM-DDTHH:mm:ss")))
                    }
                    setSubmitting(false)
                }
            }
            else {
                // toast.error("Time should be 1 hour greater than current time.")
                setScheduledError(true)
            }
        }
        else {
            if (selectedTerminalError == false) {
                if (!isSelected3) {
                    dispatch(pushNotificationAction(values.title, values.description,radioButton, "customUsers", null, null, selectedUser, values.notificationType,
                        radioButton == "Now" ? null : moment(selectedOpenTime).utc().format("YYYY-MM-DDTHH:mm:ss")))
                }
                else if (isSelected3 && templates == "allUsers") {
                    dispatch(pushNotificationAction(values.title, values.description,radioButton, templates, null, null, null,
                        values.notificationType,
                        radioButton == "Now" ? null : moment(selectedOpenTime).utc().format("YYYY-MM-DDTHH:mm:ss")))
                }
                else if (isSelected3 && templates == "region") {
                    dispatch(pushNotificationAction(values.title, values.description,radioButton,
                        templates, null, insideRegion, null, values.notificationType,
                        radioButton == "Now" ? null : moment(selectedOpenTime).utc().format("YYYY-MM-DDTHH:mm:ss")))
                }
                else if (isSelected3 && templates == "usersFollowingTerminal") {
                    dispatch(pushNotificationAction(values.title, values.description,radioButton,
                        templates, userFollowing, null, null, values.notificationType,
                        radioButton == "Now" ? null : moment(selectedOpenTime).utc().format("YYYY-MM-DDTHH:mm:ss")))
                }
                else if (isSelected3 && templates == "usersInTerminal") {
                    dispatch(pushNotificationAction(values.title, values.description,radioButton,
                        templates, userInside, null, null, values.notificationType,
                        radioButton == "Now" ? null : moment(selectedOpenTime).utc().format("YYYY-MM-DDTHH:mm:ss")))
                }
                else if (isSelected3 && templates == "favouriteRegion") {
                    dispatch(pushNotificationAction(values.title, values.description,radioButton,
                        templates, null, favouriteRegion, null, values.notificationType,
                        radioButton == "Now" ? null : moment(selectedOpenTime).utc().format("YYYY-MM-DDTHH:mm:ss")))
                }
                setSubmitting(false)
            }
        }
    }

    const SelectId = (id) => {
        setRegion(id)
    }
    const selectOne = (e) => {
        setTemplates(e.target.value)
    }

    const selectScheduledTime = (e) => {
        setRadioButton(e.target.value)
    }

    const handleChange1 = (value) => {
        setSelectedUser(value);
        setSelectedTerminalError(value.length > 0 ? false : true)
    }

    const handleUserSearch = (value) => {
        if (value.length < 100) {
            setSearchValue(value);
            dispatch(getUserListAction(value))
        }
    }

    const onSelectRadio = (type) => {
        setTemplates(type)
        setSelectedUser([])
        SetIsSelected3(true)
        setSelectedTerminalError(true)
        if (type == "allUsers") {
            setSelectedTerminalError(false)
            setUserFollowing(null)
            setUserInside(null)
            setInsideRegion(null)
            setFavouriteRegion(null)
        }
        if (type == "customUsers") {
            SetIsSelected3(false)
            setUserFollowing(null)
            setUserInside(null)
            setInsideRegion(null)
            setFavouriteRegion(null)
        }
        if (type == 'usersFollowingTerminal') {
            setUserInside(null)
            setInsideRegion(null)
            setFavouriteRegion(null)
        }
        if (type == 'usersInTerminal') {
            setInsideRegion(null)
            setUserFollowing(null)
            setFavouriteRegion(null)
        }
        if (type == 'region') {
            setUserFollowing(null)
            setUserInside(null)
            setFavouriteRegion(null)
        }
        if (type == 'favouriteRegion') {
            setUserFollowing(null)
            setUserInside(null)
            setInsideRegion(null)
        }
    }

    return (
        // <Spin
        //     size="large"
        //     // spinning={isLoading}
        //     wrapperClassName={classes.tableSpinner}
        //     className={classes.antSpin}
        // >
            <DashboardLayout>
                <ConfirmationModal title="Alert" text={appConstants.confirmDeleteState} visible={confirm}
                    disable={disableButton}
                    onCancel={() => {
                        setConfirm(false)
                    }}
                    onDeleteConfirmed={() => {
                        dispatch(deleteStateAction(stateToDelete))
                        setConfirm(false)
                        setDisableButton(true)
                    }}
                />
                <AppBar breadcrumbs={[{ route: '/notification-listing', name: "Notification Management" }, { route: '/push-notifications', name: "Push Notification" }]} />
                <Navbar title="Push Notification" />
                <Card className={classes.card}>
                    {matches &&
                        <Box className={classes.searchBox}>
                            <NavbarSearch value={search} onValueChange={(e) => handleSearching(e)} />
                        </Box>
                    }
                    <Grid container>
                        <Grid className={classes.tableContainerRow}>
                            <Formik
                                enableReinitialize
                                initialValues={values}
                                validationSchema={validationSchema}
                                onSubmit={handleFormSubmit}
                            >
                                {({
                                    values,
                                    errors,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                    isSubmitting,
                                    touched,
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <BSTable striped bordered style={{ minHeight: 125 }}>
                                            {
                                                // isTableDataLoading ? <thead className={classes.bsHeaderTable}>
                                                //     <Spin className={classes.tableDataSpinner} />
                                                // </thead>
                                                //     :
                                                    <tbody>
                                                        <tr>
                                                            <td className={classes.rowKey}>
                                                                Title
                                                            </td>
                                                            <td className={classes.rowValue}>
                                                                <div className={classes.inputWrapper} style={{ width: '100%' }}>
                                                                    <InputField
                                                                        id="title"
                                                                        placeholder="Enter Title"
                                                                        name="title"
                                                                        error={Boolean(touched.title && errors.title)}
                                                                        helperText={touched.title && errors.title}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        type="text"
                                                                        value={values?.title?.trimLeft()}
                                                                        maxLength={50}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className={classes.rowKey}>
                                                                Description
                                                            </td>
                                                            <td className={classes.rowValue}>
                                                                <div className={classes.inputWrapper} style={{ width: '100%' }}>
                                                                    <InputField
                                                                        textArea={true}
                                                                        id="description"
                                                                        placeholder="Enter Description"
                                                                        name="description"
                                                                        error={Boolean(touched.description && errors.description)}
                                                                        helperText={touched.description && errors.description}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        type="name"
                                                                        value={values?.description?.trimLeft()}
                                                                        maxLength={1000}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className={classes.rowKey}>Notifications Timing</td>
                                                            <td className={classes.rowValue}>
                                                                <div className={classes.inputWrapper}>
                                                                    <RadioGroup aria-label="template" name="radioButton" value={radioButton}
                                                                     onChange={selectScheduledTime}>
                                                                        <FormControlLabel value="Now" control={<Radio />}
                                                                            checked={radioButton == "Now"}
                                                                            label="Now"
                                                                        />
                                                                        <FormControlLabel value="Scheduled" control={<Radio />}
                                                                            checked={radioButton == "Scheduled"}
                                                                            label={<>
                                                                                Scheduled Notifications
                                                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                                    <DateTimePicker
                                                                                        style={{ width: "100%", backgroundColor: "#fff", }}
                                                                                        placeholder="Select Date"
                                                                                        name="startDate"
                                                                                        value={selectedOpenTime}
                                                                                        onAccept={(time) => {
                                                                                            setOpenTimePicker(false);
                                                                                        }}
                                                                                        format="yyyy-MM-dd HH:mm"
                                                                                        onClose={() => setOpenTimePicker(false)}
                                                                                        onChange={(time) => {
                                                                                            let futureTime = moment().add(1, 'hours').format("YYYY-MM-DD HH:mm")
                                                                                            // var startTime = moment(time, 'DD-MM-YYYY hh:mm');
                                                                                            // var endTime = moment(new Date(), 'DD-MM-YYYY hh:mm');
                                                                                            // var hoursDiff = startTime.diff(endTime, 'minutes');

                                                                                            // if (hoursDiff >= 525600) {
                                                                                            //     console.log("differencetrue")
                                                                                            // }
                                                                                            // else {
                                                                                            //     console.log("differencefalse")
                                                                                            // }
                                                                                            if (moment(time).format("YYYY-MM-DD HH:mm") >= futureTime) {
                                                                                                setSelectedOpenTime(time)
                                                                                                setScheduledError(false)
                                                                                            }
                                                                                            // if (hoursDiff >= 525600) {
                                                                                            //     toast.error("please enter valid time.")
                                                                                            //     // console.log("error")
                                                                                            // }
                                                                                            // else {
                                                                                            //     // setScheduledError(true)
                                                                                            //     setSelectedOpenTime(time)
                                                                                            // }
                                                                                            else {
                                                                                                setScheduledError(true)
                                                                                            }
                                                                                        }
                                                                                        }
                                                                                        open={openTimePicker}
                                                                                        disablePast
                                                                                        minDate={moment()}
                                                                                        disabled={radioButton == 'Scheduled' ? false : true}
                                                                                        onOpen={() => setOpenTimePicker(true)}
                                                                                        maxDate={moment().add(364, 'days').format("YYYY-MM-DD HH:mm")}
                                                                                        InputProps={{
                                                                                            endAdornment: (
                                                                                                <FontAwesomeIcon
                                                                                                    icon={faCalendar}
                                                                                                    className={classes.dropdownIcon}
                                                                                                />
                                                                                            )
                                                                                        }}
                                                                                    />
                                                                                </MuiPickersUtilsProvider>
                                                                            </>
                                                                            }
                                                                        />
                                                                        {(scheduledError && radioButton == "Scheduled")
                                                                            && <div className="radio-error" style={{ marginTop: "-2px" }}>
                                                                                Time should be 1 hour greater than current time.</div>}
                                                                    </RadioGroup>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className={classes.rowKey}>
                                                                Notify Type
                                                            </td>
                                                            <td className={classes.rowValue}>
                                                                <div className={classes.inputWrapper} >
                                                                    <Select
                                                                        native
                                                                        className={classes.selectInput}
                                                                        onBlur={handleBlur}
                                                                        inputProps={{
                                                                            name: "notificationType",
                                                                            id: "notificationType",
                                                                        }}
                                                                        value={values.notificationType}
                                                                        onChange={handleChange}
                                                                    >
                                                                        <option value="admin">Admin</option>
                                                                        <option value="locationSetting">Location Settings</option>
                                                                    </Select>
                                                                    {touched.notificationType && errors.notificationType && (
                                                                        <FormHelperText error id="component-error-text">
                                                                            {errors.notificationType}
                                                                        </FormHelperText>
                                                                    )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className={classes.rowKey}>
                                                                Select Type
                                                            </td>
                                                            <td className={classes.rowValue}>
                                                                <div className={classes.notificationsWrapper}>
                                                                    <RadioGroup aria-label="template" name="template" value={templates} onChange={selectOne}>
                                                                        <FormControlLabel value="allUsers" control={<Radio onClick={() => onSelectRadio('allUsers')} />}
                                                                            checked={templates == "allUsers"}
                                                                            label="All Users"
                                                                        />
                                                                        <FormControlLabel value="usersInTerminal" control={<Radio className="dropdown-radioButton" onClick={() => onSelectRadio('usersInTerminal')} />}
                                                                            checked={templates == "usersInTerminal"}
                                                                            label={<div className="select-users">
                                                                                Users who are inside a terminal
                                                                                <Select
                                                                                    native
                                                                                    className={classes.selectInput}
                                                                                    onBlur={handleBlur}
                                                                                    // onChange={handleChange}
                                                                                    disabled={templates == 'usersInTerminal' ? false : true}
                                                                                    inputProps={{
                                                                                        name: "terminalId",
                                                                                        id: "terminalId",
                                                                                    }}
                                                                                    value={userInside}
                                                                                    onChange={(value) => {
                                                                                        if (value.target.value == 'Select Terminal') {
                                                                                            setUserInside(null);
                                                                                            setSelectedTerminalError(true);
                                                                                            return false;
                                                                                        }
                                                                                        setUserInside(value.target.value);
                                                                                        setSelectedTerminalError(false);
                                                                                    }}
                                                                                >
                                                                                    <option value={null}>Select Terminal</option>
                                                                                    {templates == "usersInTerminal" && terminalManagementstate?.terminalListing.map(
                                                                                        (terminalArea, index) => {
                                                                                            return (
                                                                                                <option value={terminalArea._id} onClick={() => { SelectId(index) }}>
                                                                                                    {terminalArea.terminal_name}
                                                                                                </option>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </Select>

                                                                            </div>
                                                                            }
                                                                        />
                                                                        {(selectedTerminalError && templates == "usersInTerminal" && userInside == null) 
                                                                        && <div className="radio-error" >Please select terminal name.</div>}
                                                                        <FormControlLabel value={"usersFollowingTerminal"}
                                                                         control={<Radio className="dropdown-radioButton" onClick={() => onSelectRadio('usersFollowingTerminal')} />}
                                                                            checked={templates == "usersFollowingTerminal"}
                                                                            label={
                                                                                <div className="select-users">
                                                                                    Users who are following the terminal
                                                                                    <Select
                                                                                        native
                                                                                        className={classes.selectInput}
                                                                                        disabled={templates == 'usersFollowingTerminal' ? false : true}
                                                                                        onBlur={handleBlur}
                                                                                        // onChange={handleChange}
                                                                                        value={userFollowing}
                                                                                        onChange={(value) => {
                                                                                            if (value.target.value == 'Select Terminal') {
                                                                                                setUserFollowing(null);
                                                                                                setSelectedTerminalError(true);
                                                                                                return false;
                                                                                            }

                                                                                            setUserFollowing(value.target.value);
                                                                                            setSelectedTerminalError(false);
                                                                                        }}

                                                                                        inputProps={{
                                                                                            name: "terminalId",
                                                                                            id: "terminalId",

                                                                                        }}
                                                                                    >
                                                                                        <option value={null}>Select Terminal</option>
                                                                                        {templates == 'usersFollowingTerminal' && terminalManagementstate?.terminalListing.map(
                                                                                            (terminalArea, index) => {
                                                                                                return (
                                                                                                    <option value={terminalArea._id}>
                                                                                                        {terminalArea.terminal_name}
                                                                                                    </option>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </Select>

                                                                                </div>
                                                                            }
                                                                        />
                                                                        {(selectedTerminalError && templates == 'usersFollowingTerminal' && userFollowing == null) && <div className="radio-error" >Please select terminal name.</div>}
                                                                        <FormControlLabel value="region" control={<Radio className="dropdown-radioButton" onClick={() => onSelectRadio('region')} />}
                                                                            checked={templates == "region"}
                                                                            label={
                                                                                <div className="select-users">
                                                                                    Users who are inside the terminals of a region
                                                                                    <Select
                                                                                        native
                                                                                        className={classes.selectInput}
                                                                                        disabled={templates == 'region' ? false : true}
                                                                                        onBlur={handleBlur}
                                                                                        // onChange={handleChange}
                                                                                        value={insideRegion}
                                                                                        onChange={(value) => {
                                                                                            if (value.target.value == 'Select Region Name') {
                                                                                                setInsideRegion(null);
                                                                                                setSelectedTerminalError(true);
                                                                                                return false;
                                                                                            }
                                                                                            setInsideRegion(value.target.value);
                                                                                            setSelectedTerminalError(false);
                                                                                        }}
                                                                                        inputProps={{
                                                                                            name: "regionId",
                                                                                            id: "regionId",
                                                                                        }}
                                                                                    // IconComponent={() => (
                                                                                    //     <FontAwesomeIcon
                                                                                    //         icon={faChevronDown}
                                                                                    //         className={classes.dropdownIcon}
                                                                                    //     />
                                                                                    // )}
                                                                                    >
                                                                                        <option value={null}>Select Region Name</option>
                                                                                        {templates == "region" && terminalAreaManagementState?.terminalAreaListing.map(
                                                                                            (regionName, index) => {
                                                                                                return (
                                                                                                    <option value={regionName._id}>
                                                                                                        {regionName.region_name}
                                                                                                    </option>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </Select>

                                                                                </div>
                                                                            }
                                                                        />
                                                                        {(selectedTerminalError && templates == "region" && insideRegion == null) && <div className="radio-error" >Please select region name.</div>}
                                                                        <FormControlLabel value="favouriteRegion" control={<Radio className="dropdown-radioButton" onClick={() => onSelectRadio('favouriteRegion')} />}
                                                                            checked={templates == "favouriteRegion"}
                                                                            label={<div className="select-users">
                                                                                Users who are following favourite region
                                                                                <Select
                                                                                    native
                                                                                    className={classes.selectInput}
                                                                                    onBlur={handleBlur}
                                                                                    // onChange={handleChange}
                                                                                    disabled={templates == 'favouriteRegion' ? false : true}
                                                                                    inputProps={{
                                                                                        name: "regionId",
                                                                                        id: "regionId",
                                                                                    }}
                                                                                    value={favouriteRegion}
                                                                                    onChange={(value) => {
                                                                                        if (value.target.value == 'Select Region') {
                                                                                            setFavouriteRegion(null);
                                                                                            setSelectedTerminalError(true);
                                                                                            return false;
                                                                                        }
                                                                                        setFavouriteRegion(value.target.value);
                                                                                        setSelectedTerminalError(false);
                                                                                    }}
                                                                                >
                                                                                    <option value={null}>Select Region</option>
                                                                                    {templates == "favouriteRegion" && terminalAreaManagementState?.terminalAreaListing.map(
                                                                                        (regionName, index) => {
                                                                                            return (
                                                                                                <option value={regionName._id}>
                                                                                                    {regionName.region_name}
                                                                                                </option>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </Select>

                                                                            </div>
                                                                            }
                                                                        />
                                                                        {(selectedTerminalError && templates == "favouriteRegion" && favouriteRegion == null) &&
                                                                            <div className="radio-error" >Please select Region name.</div>}
                                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '-11px' }}>
                                                                            <Radio style={{ width: 42, height: 42 }} className="dropdown-radioButton" checked={!isSelected3} onClick={() => onSelectRadio('customUsers')} />
                                                                            <div className="select-users" style={{
                                                                                fontSize: '1rem',
                                                                                fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                                                                                fontWeight: '400',
                                                                                lineHeight: '1.5',
                                                                                width: '100%',
                                                                                letterSpacing: '0.00938em',
                                                                            }}>
                                                                                Selected users
                                                                                <AntdSelect
                                                                                    mode="multiple"

                                                                                    allowClear
                                                                                    style={{
                                                                                        width: '96%',
                                                                                        cursor: isSelected3 ? "not-allowed" : 'default'
                                                                                    }}
                                                                                    placeholder="Select user"
                                                                                    optionFilterProp='children'
                                                                                    showSearch
                                                                                    disabled={isSelected3}

                                                                                    value={isSelected3 === false ? selectedUser : []}
                                                                                    // value={templates === 'customUser'}
                                                                                    filterSort={(optionA, optionB) => {
                                                                                        optionA.value.toLowerCase().localeCompare(optionB.value.toLowerCase())
                                                                                    }
                                                                                    }
                                                                                    onSearch={handleUserSearch}
                                                                                    onChange={handleChange1}

                                                                                >
                                                                                    {!isSelected3 && userDetailsState?.userListingResult?.listUsers?.map(
                                                                                        (data, index) => {
                                                                                            return (
                                                                                                <option value={data?._id}>
                                                                                                    {data?.userName}
                                                                                                </option>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </AntdSelect>

                                                                            </div>
                                                                        </div>
                                                                        {(selectedTerminalError && !isSelected3 && selectedUser.length === 0)
                                                                            && <div className='radio-error1' >Please select at least one user.</div>}
                                                                    </RadioGroup>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className={classes.rowKey}></td>
                                                            <td className={classes.rowValue}>
                                                                <Button title="Send" type="submit" mr />
                                                            </td>

                                                        </tr>
                                                    </tbody>
                                            }
                                        </BSTable>
                                    </form>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>

                </Card>
            </DashboardLayout >
        // </Spin>
    );
}
