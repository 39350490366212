import React, { useState, useEffect, useRef } from 'react';
// Navigation
import { useHistory, useLocation } from 'react-router-dom'
// Styles
import 'antd/dist/antd.css'
import '../dashboard.scss'
// Third party Components
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Spin } from 'antd'
import { Card, Table as BSTable, } from 'react-bootstrap';
import { Grid, useTheme, Modal, CircularProgress, Select, FormHelperText, } from '@material-ui/core';
// Custom components
import DashboardLayout from '../../layouts/dashboardLayout'
import { InputField, Navbar, AppBar, Button } from '../../customComponents'
// Constants
import { appConstants, ValidationConstants } from '../../themes/constants'
// utils methods
import { useStyles } from '../../styles'
// Redux
import { useSelector, useDispatch } from 'react-redux'
import { addStateAction, getTimeZoneListAction } from "../../store/actions/stateListingActions"
import moment from "moment-timezone"
// Global constants


export const AddStateListing = () => {
    // Hooks declarations
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const location = useLocation()
    const dispatch = useDispatch()
    const stateListingData = useSelector(state => state.stateListingReducer)
    // local state initialization
    const { isLoading, } = stateListingData

    let timeZoneData = useSelector(state => state.stateListingReducer.timezoneList)

    //Constants
    const values = {
        stateName: "",
        shortName: "",
        timeZone: ""
    }

    const validationSchema = Yup.object().shape({
        stateName: Yup.string().test('trim', ValidationConstants.stateName.empty, value => value?.trim()?.length > 0).min(2, ValidationConstants.stateName.short).max(50, ValidationConstants.stateName.long).required(ValidationConstants.stateName.empty),
        shortName: Yup.string().max(2, ValidationConstants.shortName.long).required(ValidationConstants.shortName.empty),
        timeZone: Yup.string().required(ValidationConstants.timezone.empty),
    })

    // Lifecycle Hooks
    useEffect(() => {
        document.title = appConstants.headerTitle.addStateListing;
        dispatch(getTimeZoneListAction())
    }, [])

    //Callback methods
    const handleFormSubmit = (values, { setSubmitting }) => {
        setSubmitting(false)
        dispatch(addStateAction(values.stateName.trim(), values.shortName.toUpperCase(), values.timeZone))
    }
    const handleKeyDown = (e) => {
        if (e.key === " ") {
            e.preventDefault();
        }
    };



    return (
        <Spin
            size="large"
            spinning={isLoading}
            wrapperClassName={classes.tableSpinner}
            className={classes.antSpin}
        >
            <DashboardLayout>
                <AppBar breadcrumbs={[{ route: '/state-listing', name: "State Listing" }, { route: '/add-listing', name: "Add State" }]} />
                <Navbar title="Add State" />
                <Card className={classes.card}>
                    <Grid container>
                        <Grid className={classes.tableContainerRow}>
                            <Formik
                                enableReinitialize
                                initialValues={values}
                                validationSchema={validationSchema}
                                onSubmit={handleFormSubmit}
                            >
                                {({
                                    values,
                                    errors,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                    isSubmitting,
                                    touched,

                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <BSTable striped bordered>
                                            <tbody>
                                                <tr>
                                                    <td className={classes.rowKey}>
                                                        State Name
                                                    </td>
                                                    <td className={classes.rowValue}>
                                                        <div className={classes.inputWrapper}>
                                                            <InputField
                                                                id="stateName"
                                                                placeholder="Enter State Name"
                                                                name="stateName"
                                                                error={Boolean(touched.stateName && errors.stateName)}
                                                                helperText={touched.stateName && errors.stateName}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                type="name"
                                                                value={values?.stateName?.trimLeft()}
                                                                maxLength={50}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={classes.rowKey}>
                                                        Short Name
                                                    </td>
                                                    <td className={classes.rowValue}>
                                                        <div className={classes.inputWrapper}>
                                                            <InputField
                                                                id="shortName"
                                                                placeholder="Enter Short Name"
                                                                name="shortName"
                                                                error={Boolean(touched.shortName && errors.shortName)}
                                                                helperText={touched.shortName && errors.shortName}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                type="name"
                                                                value={values?.shortName?.toUpperCase()}
                                                                maxLength={2}
                                                                onKeyDown={handleKeyDown}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={classes.rowKey}>
                                                        Timezone
                                                    </td>
                                                    <td className={classes.rowValue}>
                                                        <div className={classes.inputWrapper}>
                                                            <Select
                                                                native
                                                                className={classes.selectInput}
                                                                value={values.timeZone}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                inputProps={{
                                                                    name: "timeZone",
                                                                    id: "timeZone",
                                                                }}
                                                            >
                                                                <option value="">
                                                                    Select Timezone
                                                                </option>
                                                                {timeZoneData?.map(
                                                                    (time, index) => {
                                                                        return (
                                                                            <option value={time?._id}>
                                                                                {time?.name}
                                                                            </option>
                                                                        );
                                                                    }
                                                                )}
                                                            </Select>
                                                            {touched.timeZone &&
                                                                errors.timeZone && (
                                                                    <FormHelperText
                                                                        error
                                                                        id="component-error-text"
                                                                    >
                                                                        {errors.timeZone}
                                                                    </FormHelperText>
                                                                )}
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={classes.rowKey}></td>
                                                    <td className={classes.rowValue}>
                                                        <Button title="Add" type="submit" className="mr-3" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </BSTable>
                                    </form>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                </Card>
            </DashboardLayout>
        </Spin>
    );
}
