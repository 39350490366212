import React, { useState, useEffect, useRef } from 'react';
// Navigation
import { useHistory, useLocation } from 'react-router-dom'
// Styles
import 'antd/dist/antd.css'
import '../dashboard.scss'
// antd
import { Spin } from 'antd';
import { Card, Table as BSTable, } from 'react-bootstrap';
// Mui Components
import { Grid, useTheme, } from '@material-ui/core';
// Custom components
import DashboardLayout from '../../layouts/dashboardLayout'
import { AppBar, InputField, Navbar, Button } from '../../customComponents'
// Constants
import { AppImages } from '../../themes/appImages'
import { Colors } from '../../themes/colors'
import { appConstants } from '../../themes/constants'
// utils methods
import cl from '../../utils/cl'
import { useStyles } from '../../styles'
// Redux
import { useSelector, useDispatch } from 'react-redux'
import { getUserDetailsAction } from '../../store/actions'
import { Checkbox } from '@material-ui/core';
import { employeeArray } from './employeeArray';
import { getEmployeeDetailsAction } from '../../store/actions/employeeManagementAction';
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export const EmployeeDetails = () => {

    // Hooks declarations
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const location = useLocation()
    const dispatch = useDispatch()
    const [employeeArray, setEmployeeArray] = useState([])
    const { userDetails, isTableDataLoading } = useSelector(state => state.userManagementReducer)
    const { employeeDetails, } = useSelector(state => state.EmployeeManagementReducer)
    console.log(employeeDetails, "*****8")
    // Lifecycle Hooks
    useEffect(() => {
        document.title = appConstants.headerTitle.employeeDetails;
        if (location?.state?.employeeId) {
            dispatch(getEmployeeDetailsAction(location?.state?.employeeId))
        } else {
            history.push('/employee')
        }
    }, [])

    useEffect(() => {
        setEmployeeArray(employeeDetails.permissions)
    }, [employeeDetails])

    // Consoles 

    //Callback methods

    const getname = (item) => {
        let itemName = item.name.charAt(0).toUpperCase() + item.name.slice(1)
        if (item.name == "dashboard")
            return "Dashboard"
        else if (item.name == "location")
            return "User Event Management"
        else if (item.name == "post")
            return "Report Post"
        else if (item.name == "state")
            return "State listing"
        else if (item.name == "region")
            return "Region listing"
        else if (item.name == "terminal")
            return "Terminal listing"
        else if (item.name == "postManagement")
            return "Post Management"
        else if (item.name == "terminalBoard")
            return "Terminal Board"
        else {
            return itemName + " Management"
        }

    }


    return (
        <DashboardLayout>
            <AppBar breadcrumbs={[{ route: '/employee', name: appConstants.employeeManagement }, { route: "/employee-details", name: appConstants.employeeDetails, state: { userId: location?.state?.userId } }]} />
            <Navbar title={appConstants.employeeDetails} />
            <Card className={classes.card}>
                <Grid container>
                    <Grid className={classes.tableContainerRow}>
                        <BSTable striped bordered className={classes.bsTableHeight}>
                            {
                                isTableDataLoading ? <thead className={classes.bsHeaderTable}>
                                    <Spin className={classes.tableDataSpinner} />
                                </thead>
                                    :
                                    <tbody>
                                        <tr>
                                            <td className={classes.rowKey}>{appConstants.image}</td>
                                            <td className={classes.rowValue}>
                                                <div className={classes.inputWrapper}>
                                                    <label
                                                        htmlFor="icon-button-file"
                                                        className={classes.imageManagementUploaderlabel}
                                                    >

                                                        <img
                                                            // src={appConstants.baseURL + news.media}
                                                            src={appConstants.baseURL + employeeDetails?.profile}
                                                            className={classes.terminalImage}
                                                            style={{ cursor: "default" }}
                                                        />

                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={classes.rowKey}>
                                                {appConstants.firstName}
                                            </td>
                                            <td className={classes.rowValue}>
                                                <div className={classes.inputWrapper}>
                                                    <InputField
                                                        placeholder={appConstants.firstName}
                                                        name="firstName"
                                                        // onChange={handleChange}
                                                        type="firstName"
                                                        value={employeeDetails?.firstName}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={classes.rowKey}>
                                                {appConstants.lastName}
                                            </td>
                                            <td className={classes.rowValue}>
                                                <div className={classes.inputWrapper}>
                                                    <InputField
                                                        placeholder={appConstants.lastName}
                                                        name="lastName"
                                                        // onChange={handleChange}
                                                        type="lastName"
                                                        value={employeeDetails?.lastName}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={classes.rowKey}>{appConstants.emailAddress}</td>
                                            <td className={classes.rowValue}>
                                                <div className={classes.inputWrapper}>
                                                    <InputField
                                                        placeholder={appConstants.emailAddress}
                                                        name="email"
                                                        // onChange={handleChange}
                                                        type="email"
                                                        value={employeeDetails?.email}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={classes.rowKey}>{appConstants.phoneNumber}</td>
                                            <td className={classes.rowValue}>
                                                <div className={classes.inputWrapper}>
                                                    <InputField
                                                        placeholder={appConstants.phoneNumber}
                                                        name="phoneNumber"
                                                        // onChange={handleChange}
                                                        type="number"
                                                        value={employeeDetails?.phone_number}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={classes.rowKey}>Role</td>
                                            <td className={classes.rowValue}>
                                                <div className={classes.inputWrapper}>
                                                    <InputField
                                                        placeholder="Role"
                                                        name="userType"
                                                        // onChange={handleChange}
                                                        // type="email"
                                                        value={employeeDetails?.role?.name}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={classes.rowKey}>Type of access</td>
                                            <tr>
                                                <td className={classes.rowKey}></td>
                                                <td className={classes.rowValue}>
                                                    <div className={classes.tableWrapper}>
                                                        <div className="line-view" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                            <h6>View</h6>
                                                            <h6 style={{ marginLeft: "3%" }}>Add</h6>
                                                            <h6 style={{ marginLeft: "3%" }}>Edit</h6>
                                                            <h6 style={{ marginLeft: "2%" }}>Delete</h6>
                                                            <h6>Block</h6>

                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            {employeeArray?.length > 0 &&
                                                employeeArray?.map((item, index) => {
                                                    return (
                                                        <tr>
                                                            <td className={classes.rowKey}>{getname(item)}</td>
                                                            <td className={classes.rowValue}>
                                                                <div className={classes.tableWrapper}>
                                                                    <div className="line-view" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                                        <Checkbox value={item.view} checked={item.view}   {...label} disabled />
                                                                        <Checkbox value={item.add} checked={item.add}   {...label} disabled />
                                                                        <Checkbox value={item.edit} checked={item.edit}  {...label} disabled />
                                                                        <Checkbox value={item.delete} checked={item.delete}   {...label} disabled />
                                                                        <Checkbox value={item.block} checked={item.block}   {...label} disabled />

                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }




                                        </tr>
                                    </tbody>
                            }
                        </BSTable>
                    </Grid>
                </Grid>
            </Card>
        </DashboardLayout>
    );
}
