import React, { useState, useEffect, useRef } from 'react';
import { SliderPicker, CompactPicker } from 'react-color'
import InputField from './inputField';

export const ColorPicker = (props) => {
    const { value, onChange, maxLength, color, colorChange,error,placeholder } = props;

    const handleKeyDown = (e) => {
        if (e.key === " ") {
            e.preventDefault();
        }
    };

    return (
        <>
            <div style={{ marginBottom: "5px" }}>
                <InputField
                    id="color"
                    placeholder={placeholder}
                    name="color"
                    onChange={onChange}
                    type="name"
                    value={value?.trim()}
                    onKeyDown={handleKeyDown}
                />
            </div>
            <CompactPicker
                color={color}
                onChange={colorChange}
            />
            {error !== null &&
                <div className="radio-error"
                    style={{ marginTop: "11px", marginLeft: "0px" }}
                >{error}</div>}
        </>
    )
}