import React from "react";
import { Map, Polyline, Polygon, Circle, GoogleApiWrapper, Marker } from 'google-maps-react';
import { getCenterAndZoom, getCenterAndZoom1 } from "../utils/helpers";
import { toast } from "react-toastify";

var localLatLong = null
const GoogleMap = (props) => {
  const { onClearClicked, geoFence, radius, value, mapSettings, centerPoint, terminalDetails, setMapSettings, isEditing, google, center, zoom, onMapClicked, setCircleZoom, polygonState, circleZoom, onCircleClicked, firstPoint, marker, marker1, markersArray, terminalDetailsDummy } = props
  const [localMarker, setLocalMarker] = React.useState();
  const [newMarker, setNewMarker] = React.useState(null)
  const [allMarkers, setAllMarkers] = React.useState([])

  React.useEffect(() => {
    return () => { localLatLong = null }
  }, []);

  React.useEffect(() => {
    if (markersArray?.length > 0) {
      setTimeout(() => {
        let newArr = []
        markersArray?.map((i, k) => {
          var coordinate = new window.google.maps.LatLng(i.lat, i.lng);
          var polygon = new window.google.maps.Polygon({ paths: polygonState.points });
          const isPointInPolygon = google?.maps?.geometry?.poly.containsLocation(coordinate, polygon)
          if (isPointInPolygon) {
            newArr.push(i);
          }
          else {
            toast.error("Geofence pin must be inside the polygon.", { toastId: "est" })
          }
        })
        setAllMarkers(newArr)
      }, 1000)
    }
    else {
      setAllMarkers([])
    }
  }, [markersArray])

  React.useEffect(() => {
    if (isEditing && centerPoint && polygonState) {
      const tempData = getCenterAndZoom1(JSON.stringify(polygonState?.points))
      const tempRadius = Math.pow(2, ((tempData.zoomLevel > 6 ? 20 : 19.6) - tempData.zoomLevel))
      setCircleZoom(tempRadius)
      setMapSettings({
        centerLocation: tempData.centerLocation,
        zoomLevel: tempData.zoomLevel
      })
    }
  }, []);

  React.useEffect(() => {
    setLocalMarker(marker)
    if (localLatLong !== null && (localLatLong?.lat !== marker.lat || localLatLong?.lng !== marker.lng) && polygonState.points.length > 0) {
      if (isEditing) {
        onClearClicked(value)
      }
      else {
        onClearClicked()
      }
    }
    localLatLong = marker
  }, [marker]);

  const onCircleClick = (props) => {

    var coordinate = new window.google.maps.LatLng(localMarker.lat, localMarker.lng);
    var polygon = new window.google.maps.Polygon({ paths: polygonState.points });
    const isPointInPolygon = google?.maps?.geometry?.poly.containsLocation(coordinate, polygon)
    if (isPointInPolygon) {
      if (isEditing) {
        onCircleClicked(props, value)
      }
      else {
        onCircleClicked(props)
      }
    }
    else {
      if (props.center === polygonState.points[0]) {
        toast.dismiss()
        toast.error("Location pin must be inside the polygon.", { toastId: "est" });
      }
    }
  }

  return (
    <>
      {mapSettings ?
        <Map google={google}
          style={{ width: "100%", height: "90%" }}
          className={'map'}
          styles={mapStyle}
          initialCenter={center}
          center={center}
          zoom={zoom}
          onClick={(mapProps, map, e) => onMapClicked(mapProps, map, e, radius)}
          fullscreenControl={true}
          onDblclick={(e) => console.log(e)}
          // mapTypeControl={true}
          // mapTypeId="hybrid"
          mapTypeControlOptions={{ mapTypeIds: ["roadmap", "satellite"] }}
          streetViewControl={false}
          zoomControl={true}
          minZoom={9}
          onZoomChanged={(mapProps, map, event) => {
            const tempZoom = map.getZoom()
            const tempRadius = Math.pow(2, ((tempZoom > 6 ? 20 : 19.6) - tempZoom))
            setCircleZoom(tempRadius)
          }}
        >
          <Marker
            name={''}
            position={localMarker}
          />
          {polygonState?.points?.map((cord, index) => <Circle
            radius={circleZoom}
            center={cord}
            onMouseover={() => console.log('mouseover')}
            onClick={onCircleClick}
            onMouseout={() => console.log('mouseout')}
            strokeColor='transparent'
            strokeOpacity={0}
            strokeWeight={5}
            fillColor='#00FF00'
            fillOpacity={0}
          />)}
          {polygonState?.points?.map((cord, index) => <Circle
            radius={circleZoom}
            center={cord}
            onMouseover={() => console.log('mouseover')}
            onClick={onCircleClick}
            onMouseout={() => console.log('mouseout')}
            strokeColor='transparent'
            strokeOpacity={0}
            strokeWeight={5}
            fillColor='#00FF00'
            fillOpacity={0.75}
          />)}
          {!firstPoint && <Polyline
            path={polygonState.points}
            strokeColor="#0000FF"
            strokeOpacity={1}
            strokeWeight={2}
          />}
          {firstPoint && <Polygon
            paths={polygonState.points}
            strokeColor="#d2d2d2"
            strokeOpacity={1}
            strokeWeight={1.5}
            fillColor="#d2d2d2"
            fillOpacity={0.3}
          />}
          {(terminalDetails?.length > 0) &&
            terminalDetails?.map((cords, index) => {
              return (
                <Marker
                  name={''}
                  position={{ lat: cords.lat, lng: cords.lng }}
                />
              )
            })
          }
          {(terminalDetails?.length > 0) &&
            terminalDetails?.map((cords, index) => {
              return (
                <Circle
                  radius={Number(cords.radius)}
                  center={{ lat: cords.lat, lng: cords.lng }}
                  onMouseover={() => console.log('mouseover')}
                  onClick={() => console.log('click')}
                  onMouseout={() => console.log('mouseout')}
                  strokeColor='transparent'
                  strokeOpacity={0}
                  strokeWeight={5}
                  fillColor='#289edc'
                />
              )
            })
          }
        </Map>
        : null}
    </>
  );
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyAId9HPoVrtc6rDn9O-tAWERRJEelhkARc'
  // apiKey: 'AIzaSyBOwf2XwXSuiBrVJL8TQjWhPFy6O9Z5Coo'
})(GoogleMap)

const mapStyle = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#0f1214"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#212121"
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "administrative.country",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative.locality",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#bdbdbd"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#181818"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#1b1b1b"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#2c2c2c"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#8a8a8a"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#373737"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#3c3c3c"
      }
    ]
  },
  {
    "featureType": "road.highway.controlled_access",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#4e4e4e"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#0e1917"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#3d3d3d"
      }
    ]
  },

  {
    "featureType": "landscape.natural",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#000000"
      }
    ]
  },
     
]
