export default {
    // *********************** AUTHENTICATION **************************
    // Set Auth Token
    setAuthToken(token) {
        localStorage.setItem("token", token);
    },

    // get Auth Token
    getAuthToken() {
        return localStorage.token
    },

    // Delete Token
    removeToken() {
        localStorage.removeItem("token");
    },
    // *********************** USER ID **************************
    // Set User ID
    setUserId(id) {
        localStorage.setItem("userId", id);
    },

    // get User ID
    getUserId() {
        return JSON.parse(localStorage.userId)
    },

    // Delete  User ID
    removeUserId() {
        localStorage.removeItem("userId");
    },

    // *********************** PERMISSIONS **************************

    // Set Permissions
    setPermissions(permissions) {
        localStorage.setItem("permissions", JSON.stringify(permissions));
    },

    removePermissions() {
        localStorage.removeItem("permissions");
    },

    // get Permissions
    getPermissions() {
        if (localStorage?.permissions) {
            return JSON?.parse(localStorage?.permissions)
        }

    },

    setLayoutArr(layOutArr) {
        localStorage.setItem("layOutArr", JSON.stringify(layOutArr));
    },

    getLayoutArr() {
        if (localStorage?.layOutArr) {
            return JSON?.parse(localStorage?.layOutArr)
        }

    },
    removeLayoutArr() {
        localStorage.removeItem("layOutArr")
    },
    setNestedLayoutArr(layOutNestedArr) {
        localStorage.setItem("layOutNestedArr", JSON.stringify(layOutNestedArr));
    },

    getNestedLayoutArr() {
        if (localStorage?.layOutNestedArr) {
            return JSON?.parse(localStorage?.layOutNestedArr)
        }

    },
    removeNestedLayoutArr() {
        localStorage.removeItem("layOutNestedArr")
    },

    // ************************ SIDEBAR ROUTE INDEX *************************
    //Set Sidebar Selected Item Route Index
    setSidebarItemIndex(index) {
        localStorage.setItem("sidebarRouteIndex", index);
    },

    // get Sidebar Selected Item Route Index
    getSidebarItemIndex() {
        return localStorage.sidebarRouteIndex
    },

    // Delete Sidebar Selected Item Route Index
    removeSidebarItemIndex() {
        localStorage.removeItem("sidebarRouteIndex");
    },

    //Set Sidebar Selected Item Route Index
    setSidebarNestedItemIndex(index) {
        localStorage.setItem("sidebarNestedRouteIndex", index);
    },

    // get Sidebar Selected Item Route Index
    getSidebarNestedItemIndex() {
        return localStorage.sidebarNestedRouteIndex
    },

    setBotUser(id) {
        localStorage.setItem("botUserId", id);
    },


    getBotUser() {
        console.log(localStorage?.botUserId, "localStorage?.botUserId)")
        if (localStorage?.botUserId) {
            return localStorage?.botUserId
        }

    },

    // Delete Sidebar Selected Item Route Index
    removeSidebarNestedItemIndex() {
        localStorage.removeItem("sidebarNestedRouteIndex");
    },
    // username
    setUserName(userName) {
        localStorage.setItem("userName", userName);
    },

    // get Auth Token
    getUserName() {
        return localStorage.userName
    },

    removeUserName() {
        localStorage.removeItem("userName");
    },

}




