import { apiConstants as types } from '../../themes/constants'
const initialState = {
    isLoading: false,
    isTableDataLoading: false,
    result: {},
    deletedTerminal: null,
    postListing: [],
    botUsersList: [],
    postPaging: {},
    postDetails: {},
    errorState: null,
    currentPage: 1,
    errorState: null,
    usersList: [],
    botUsers: {},
    botUSerId: null
}

export const PostManagementReducer = (state = initialState, action) => {
    switch (action.type) {

        // Get post list
        case types.API_GET_POST_LIST_LOAD:
            return { ...state, isLoading: true, errorState: null, }
        case types.API_GET_POST_LIST_SUCCESS:
            return { ...state, isLoading: false, postListing: action.result.list, postPaging: action.result.paging, currentPage: action.result?.paging?.page?.currentPage }

        // Get bot users list
        case types.API_GET_BOT_USERS_LIST_LOAD:
            return { ...state, isLoading: true, errorState: null, }
        case types.API_GET_BOT_USERS_LIST_SUCCESS:
            return { ...state, isLoading: false, botUsersList: action.result, }

        // add post
        case types.API_ADD_POST_LIST_LOAD:
            return { ...state, isLoading: true, errorState: null, }
        case types.API_ADD_POST_LIST_SUCCESS:
            return { ...state, isLoading: false, }

        // add bot user
        case types.API_ADD_BOT_USERS_LOAD:
            return { ...state, isLoading: true, errorState: null, }
        case types.API_ADD_BOT_USERS_SUCCESS:
            let listOfBotUsers = [...state.botUsersList]
            listOfBotUsers.push(action.result.data)
            return { ...state, isLoading: false, botUsersList: listOfBotUsers }

        // get users list
        case types.API_GET_USERS_LIST_LOAD:
            return { ...state, isLoading: true, errorState: null, }
        case types.API_GET_USERS_LIST_SUCCESS:
            return { ...state, isLoading: false, usersList: action.result.listUsers }


        // share post
        case types.API_SHARE_POST_LOAD:
            return { ...state, isTableDataLoading: true, errorState: null, }
        case types.API_SHARE_POST_SUCCESS:
            // let sharedPosts = [...state.postListing]
            // sharedPosts.push(action.result.data)
            return { ...state, isTableDataLoading: false, }

        // like post
        case types.API_LIKE_POST_LOAD:
            return { ...state, isTableDataLoading: false, errorState: null, }
        case types.API_LIKE_POST_SUCCESS:
            return { ...state, isTableDataLoading: false, }

        // comment on post
        case types.API_COMMENT_ON_POST_LOAD:
            return { ...state, isTableDataLoading: false, errorState: null }
        case types.API_COMMENT_ON_POST_SUCCESS:
            // let totalComments = state.postDetails
            // totalComments.comments.push(action.result.commentPost)
            return { ...state, isTableDataLoading: false, }

        // post details
        case types.API_GET_POST_LIST_DETAILS_LOAD:
            return { ...state, isTableDataLoading: true, errorState: null, }
        case types.API_GET_POST_LIST_DETAILS_SUCCESS:
            return { ...state, isTableDataLoading: false, postDetails: action.result }

        // like comment
        case types.API_LIKE_COMMENT_LOAD:
            return { ...state, isTableDataLoading: false, errorState: null, }
        case types.API_LIKE_COMMENT_SUCCESS:
            let allComments = [...state?.postDetails?.comments];
            if (action.likeComment == "comment") {
                let indexComm = allComments.findIndex((i, k) => i._id == action.commentId)
                if (indexComm > -1) {
                    if (allComments[indexComm].reactionType !== null) {
                        allComments[indexComm].reactionType = {}
                        allComments[indexComm].reactionType.type = action.likeType
                    }

                }
            }
            else {
                let indexComm1 = allComments[action.indexOfComment].subComments.findIndex((i, k) => i._id == action.commentId)
                if (indexComm1 > -1) {
                    if (allComments[action.indexOfComment].subComments[indexComm1].reactionType !== null) {
                        allComments[action.indexOfComment].subComments[indexComm1].reactionType = {}
                        allComments[action.indexOfComment].subComments[indexComm1].reactionType.type = action.likeType
                    }

                }
            }
            let d = state.postDetails;
            d.comments = allComments;
            return { ...state, isTableDataLoading: false, postDetails: d }

        // Delete post
        case types.API_DELETE_POST_LIST_LOAD:
            return { ...state, isLoading: true, errorState: null, }
        case types.API_DELETE_POST_LIST_SUCCESS:
            let indexToDelete = state.postListing.findIndex(value => value._id === action.postId)
            state.postListing.splice(indexToDelete, 1)
            return { ...state, isLoading: false, postListing: [...state.postListing], }

        // Delete comment
        case types.API_DELETE_COMMENT_LIST_LOAD:
            return { ...state, isLoading: true, errorState: null, }
        case types.API_DELETE_COMMENT_LIST_SUCCESS:
            console.log(state.postDetails, action.mainCommentIndex)
            let commentToDelete = state.postDetails.comments.findIndex(value => value._id === action.commentId)

            if (commentToDelete == -1) {
                let subCommentToDelete = state.postDetails.comments[action.mainCommentIndex].subComments.findIndex(value => value._id === action.commentId)
                if (subCommentToDelete !== -1) {
                    state.postDetails.comments[action.mainCommentIndex].subComments.splice(subCommentToDelete, 1)
                }
            }
            else {
                state.postDetails.comments.splice(commentToDelete, 1)
            }
            return { ...state, isLoading: false, postDetails: state.postDetails }

        // Edit comment
        case types.API_EDIT_COMMENT_LOAD:
            return { ...state, isLoading: true, errorState: null, }
        case types.API_EDIT_COMMENT_SUCCESS:
            return { ...state, isLoading: false }


        // // Edit post
        // case types.API_EDIT_EMPLOYEE_LIST_LOAD:
        //     return { ...state, isLoading: true, errorState: null, }
        // case types.API_EDIT_EMPLOYEE_LIST_SUCCESS:
        //     return { ...state, isLoading: false }

        //post management failed cases
        case types.API_POST_LIST_MANAGEMENT_FAILED:

            return {
                ...state, isLoading: false, isTableDataLoading: false,
                errorState: action.errorState,
            }
        case types.API_POST_LIST_MANAGEMENT_ERROR:

            return {
                ...state, isLoading: false, isTableDataLoading: false,
                errorState: action.errorState,
            }

        // Edit Post  Management
        case types.API_EDIT_POST_MANAGEMENT_LOAD:
            return { ...state, isLoading: true, errorState: null, }
        case types.API_EDIT_POST_MANAGEMENT_SUCCESS:
            return { ...state, isLoading: false }

        // Delete Post Images
        case types.API_EDIT_POST_DELETE_IMAGE_LOAD:
            return { ...state, isLoading: false, errorState: null, }
        case types.API_EDIT_POST_DELETE_IMAGE_SUCCESS:
            state.postDetails.images = action.payload
            return { ...state, isLoading: false }

        ////Update Data
        case types.UPDATE_POST_DATA:
            state[action.key] = action.payload
            return { ...state, isLoading: false }
        ////Update Data
        case "SET_BOT_USER_ID":

            return { ...state, isLoading: false, botUSerId: action.id }

        //clear post details
        case "CLEAR_BOT_DETAILS":
            return { ...state, isLoading: false, postDetails: {} }

        //Default case
        default:
            return { ...state }

    }
}