import { takeLatest, call, put } from 'redux-saga/effects'
import { message } from 'antd'
import { apiConstants as types, appMessages } from '../../themes/constants'
import axios from '../axios'
import cl from '../../utils/cl'
import history from '../../utils/history'
import { toast } from 'react-toastify';
function* viewTimelineFailed(result) {

    yield put({
        type: types.API_VIEW_TIMELINE_FAILED,
    })
    toast.error(result?.error)
}

function* viewTimelineError(result) {
    console.error('result inside the Saved User Lcations Error', result)
    yield put({
        type: types.API_VIEW_TIMELINE_ERROR,
    })
    if (result.status === 2) {
        toast.error(appMessages.messageStatus500)
    } else {
        toast.error(result?.error, { toastId: result?.status || "est" })
    }

}


function* viewTimelineSaga(action) {
    const { search, offset, limit, sortBy, order, userId } = action;
    try {
        const result = yield call(axios.viewTimelineFetch, action.payload)
        if (result.status === 1) {
            cl('result inside saved locations saga', result)
            yield put({
                type: types.API_VIEW_TIMELINE_DETAILS_SUCCESS,
                result: result.result.data.data,
            })
        }
        else {
            yield call(viewTimelineFailed, result)
        }
    }
    catch (error) {
        yield call(viewTimelineError, error)
    }
}

function* viewTimelineDetailsSaga(action) {
    const { search, offset, limit, sortBy, order, userId } = action;
    try {
        const result = yield call(axios.getTimelineDetailsFetch, action.userId)
        if (result.status === 1) {
            cl('result inside saved locations saga', result)
            yield put({
                type: types.API_GET_TIMELINE_DETAILS_SUCCESS,
                result: result.result.data.data,
            })
        }
        else {
            yield call(viewTimelineFailed, result)
        }
    }
    catch (error) {
        yield call(viewTimelineError, error)
    }
}

export default function* rootViewTimelineSaga() {
    yield takeLatest(types.API_VIEW_TIMELINE_DETAILS_LOAD, viewTimelineSaga)
    yield takeLatest(types.API_GET_TIMELINE_DETAILS_LOAD, viewTimelineDetailsSaga)
}