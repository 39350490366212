import React, { useState, useEffect, } from 'react';
// Navigation
import { useHistory, useLocation } from 'react-router-dom'
// Styles
import 'antd/dist/antd.css'
import '../dashboard.scss'
// Third party Components
import * as Yup from 'yup';
import { useFormik } from 'formik';
// antd
import { Spin, Tooltip } from 'antd';
import { Card, Table as BSTable, } from 'react-bootstrap';
// Mui Components
import { Grid, Select, FormHelperText } from '@material-ui/core';
// Custom components
import DashboardLayout from '../../layouts/dashboardLayout'
import { InputField, Navbar, AppBar, Button } from '../../customComponents'
// fontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import {
    validateImageRatioOneByOne,
} from "../../utils/helpers";
import { toast } from "react-toastify";
// Constants
import { AppImages } from '../../themes/appImages'
import { ValidationConstants, appConstants } from '../../themes/constants'
import { handleNavigationStateAction, } from '../../store/actions'
// utils methods
import { useStyles } from '../../styles'
// Redux
import { useSelector, useDispatch } from 'react-redux'
import { Checkbox } from '@material-ui/core';
import { employeeArray } from './employeeArray';
import { getRolesListAction, addEmployeeAction } from '../../store/actions/employeeManagementAction';
import localStorage from "../../utils/localStorage";
import { isInternetConnected } from '../../InternetConnection/InternetConnection';
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

// Global constants
const validationSchema = Yup.object().shape({
    firstName: Yup.string()
        .test('trim', ValidationConstants.firstname.empty, value => value?.trim()?.length > 0)
        .min(3, ValidationConstants.firstname.short)
        .max(20, ValidationConstants.firstname.long).required(ValidationConstants.firstname.empty),
    lastName: Yup.string()
        // .matches(/^[^ ][\w\W ]*[^ ]/, ValidationConstants.password.noSpaceLastName)
        .test('trim', ValidationConstants.lastname.empty, value => value?.trim()?.length > 0)
        .min(3, ValidationConstants.lastname.short).max(20, ValidationConstants.lastname.long).required(ValidationConstants.lastname.empty),
    email: Yup.string()
        .matches(/^[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(\.[a-zA-z]{2,10})+$/, "Please enter valid email address.")
        .email(ValidationConstants.email.invalid).max(255, ValidationConstants.email.long).required(ValidationConstants.email.empty),
    phoneNumber: Yup.string().matches(/^[0-9]+$/, ValidationConstants.phoneNumber.invalid).max(15, ValidationConstants.phoneNumber.long).min(8, ValidationConstants.phoneNumber.short).required(ValidationConstants.phoneNumber.empty),
    userType: Yup.string()
        .required(ValidationConstants.userType.empty)
        .min(3, ValidationConstants.userType.short)
        // .matches(/^[^ ][\w\W ]*[^ ]/, ValidationConstants.userType.noSpace)
        .matches(/^(?![0-9]*$)[a-zA-Z0-9\s]+$/, "Only alphanumeric values are allowed in role type.")
        .test('trim', ValidationConstants.userType.short, (value) => { return (value?.trimLeft().trimRight().length > 2) }),

    image: Yup.mixed().required("Please upload image."),
    // access: Yup.array().required(ValidationConstants.userType.access).min(1, ValidationConstants.userType.access)
})

export const AddEmployee = () => {
    // Hooks declarations
    const classes = useStyles();
    const history = useHistory();
    const [image, setImage] = useState("")
    const [imageUrl, setImageUrl] = useState("");
    const [checkedState, setCheckedState] = useState(employeeArray)
    const [errorState, setErrorState] = useState(false)
    const [errorTrue, setErrorTrue] = useState(false)
    const location = useLocation()
    const dispatch = useDispatch()

    const roleList = useSelector(state => state.EmployeeManagementReducer)
    // Object destructuring
    const { isTableDataLoading, roles } = roleList
    // local state initialization   
    const state = useSelector(state => state.EmployeeManagementReducer)
    const { isLoading } = state
    let siderIndex = localStorage.getLayoutArr()
    let index = siderIndex?.findIndex((x) => x.key == "employee")
    let permissionsData = localStorage?.getPermissions()
    let employeeIndex = permissionsData?.findIndex((x) => x.name == "employee")

    useEffect(() => {
        document.title = appConstants.headerTitle.AddEmployee;
        if (permissionsData) {

            dispatch(handleNavigationStateAction(index, false))
        }
        else {
            dispatch(handleNavigationStateAction(2, false))
        }
        // dispatch(getRolesListAction())
    }, [])

    const imageValidation = (value) => {
        let fileTypes = ["jpg", "jpeg", "svg", "png"]
       
        return (fileTypes.indexOf(value) > -1)
    }

    const handleImageChange = (event) => {
      
        setErrorTrue(true)
        if (event.target.files && event.target.files[0]) {
            let uploadFile = event.target.files[0]
            let splitName = uploadFile.name.split(".").pop().toLowerCase()
            let isSuccess = imageValidation(splitName)
            if (isSuccess) {
                validateImageRatioOneByOne(event.target.files[0])
                    .then(() => {
                        setImageUrl(event.target.files[0]);
                        // setImageName(splitName[0]);
                        let reader = new FileReader();
                        reader.onload = (e) => {
                            setImage(e.target.result);
                        };
                        reader.readAsDataURL(event.target.files[0]);
                        return
                    })
                    .catch(() => {
                        toast.error(ValidationConstants.image.invalidRatioOneByOne)
                        return false
                    }
                    );
            }
            else {
                toast.error("Only .jpg, .jpeg, .png and .svg format images are allowed.")
            }
        }
    };

    const checkIsValid = () => {
        let isTrue = []
        checkedState.map((x) => {
            if (x.view == false && x.edit == false && x.add == false && x.block == false && x.delete == false) {
                isTrue.push(false)

            }
            else {
                isTrue.push(true)
            }
        })
        return isTrue
    }

    const handleOnChange = (position, item1) => {
        setErrorTrue(true)
        let updatedCheckedState = []
        let arr = [...checkedState];
        arr.map((item, index) => (
            updatedCheckedState.push({
                view: (index === position && item1 == 'view') ? !item.view : item.view,
                edit: (index === position && item1 == 'edit') ? !item.edit : item.edit,
                delete: (index === position && item1 == 'delete') ? !item.delete : item.delete,
                block: (index === position && item1 == 'block') ? !item.block : item.block,
                add: (index === position && item1 == 'add') ? !item.add : item.add,
                name: item.name
            })
        )
        );
        setCheckedState(updatedCheckedState);
    }

    useEffect(() => {
        if (errorTrue == true) {
            if (checkIsValid().includes(true)) {
                setErrorState(false)
            }
            else {
                setErrorState(true)
            }
        }

    }, [checkedState, errorTrue])

    //Constants
    const initialValues = {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        userType: "",
        image: null,
        // access: []
    }

    //Callback methods
    const onSubmit = (values, { setSubmitting }) => {
        setErrorTrue(true)
        let countryCode = '+1';
        if ((checkedState[3].edit == true)) {
            checkedState[3].view = true
        }
        if ((checkedState[4].edit == true)) {
            checkedState[4].view = true
        }
        if ((checkedState[5].edit == true)) {
            checkedState[5].view = true
        }
        if (errorState == true) {
            return false;
        }
        else {
            isInternetConnected(history) &&
            dispatch(addEmployeeAction(values.firstName.trim(), values.lastName.trim(), values.email, values.phoneNumber, imageUrl, values.userType, checkedState))
        }

    }

    const handleKeyDown = (e) => {
        if (e.key === " ") {
            e.preventDefault();
        }
    };

    const validate = values => {
        const errors = {};
        // if (!values.email) {
        //     errors.email = 'Please enter email address.';
        // } else if (!/^[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*@[a-zA-z]+(\.[a-zA-z]{2,8})+$/.test(values.email)) {
        //     errors.email = ValidationConstants.email.invalid;
        // }
        if (!values.phoneNumber) {
            errors.phoneNumber = "Please enter phone number."
        }
        else if (values.phoneNumber == 0) {
            errors.phoneNumber = "Please enter valid phone number."
        }
        return errors;
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema,
        onSubmit,
        validate,
        // validateOnChange: false,
        // validateOnBlur: true,
    });

    return (
        <Spin
            size="large"
            spinning={isLoading}
            wrapperClassName={classes.tableSpinner}
            className={classes.antSpin}
        >
            <DashboardLayout>
                <AppBar breadcrumbs={[{ route: '/employee', name: appConstants.employeeManagement }, { route: '/add-employee', name: appConstants.addEmployee, state: location?.state?.userId }]} />
                <Navbar title={appConstants.addEmployee} />
                <Card className={classes.card}>
                    <Grid container>
                        <Grid className={classes.tableContainerRow}>
                            <form onSubmit={formik.handleSubmit} noValidate>
                                <BSTable striped bordered className={classes.bsTableHeight}>
                                    {
                                        isTableDataLoading ? <thead className={classes.bsHeaderTable}>
                                            <Spin className={classes.tableDataSpinner} />
                                        </thead>
                                            :
                                            <tbody>
                                                <tr>
                                                    <td className={classes.rowKey}>
                                                        {appConstants.uploadImage}
                                                    </td>
                                                    <td className={classes.rowValue}>
                                                        <div className={classes.inputWrapper}>
                                                            <input
                                                                accept=".png,.jpg,.jpeg"
                                                                className={classes.uploaderInput}
                                                                id="icon-button-file"
                                                                type="file"
                                                                onChange={(e) => {
                                                                    handleImageChange(e)
                                                                    if (e.target.files[0]) {
                                                                        formik.setFieldValue("image", e.target.files[0])
                                                                    }
                                                                }}
                                                                onClick={(event)=> { 
                                                                    event.target.value = null
                                                               }}
                                                            />
                                                            <label
                                                                htmlFor="icon-button-file"
                                                                className={classes.imageManagementUploaderlabel}
                                                            >
                                                                <Tooltip
                                                                    title={image ? appConstants.tooltip.changeImage : appConstants.tooltip.uploadImage}
                                                                    placement="right"
                                                                >
                                                                    {image ? (
                                                                        <img
                                                                            src={image}
                                                                            className={classes.terminalImage}
                                                                        />
                                                                    ) : (
                                                                        <img
                                                                            src={AppImages.addPhoto}
                                                                            className={classes.terminalImage}
                                                                            onChange={(e) => {
                                                                                handleImageChange(e)
                                                                                formik.setFieldValue("image", e.target.files[0])
                                                                            }}
                                                                         
                                                                        />
                                                                    )}
                                                                </Tooltip>
                                                            </label>
                                                            {formik.errors.image && <FormHelperText error id="component-error-text">{formik.errors.image}</FormHelperText>}

                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={classes.rowKey}>
                                                        {appConstants.firstName}
                                                    </td>
                                                    <td className={classes.rowValue}>
                                                        <div className={classes.inputWrapper}>
                                                            <InputField
                                                                id="firstName"
                                                                placeholder={appConstants.firstName}
                                                                name="firstName"
                                                                onBlur={formik.handleBlur}
                                                                onChange={formik.handleChange}
                                                                type="firstName"
                                                                value={formik.values.firstName.trimLeft()}
                                                                maxLength={20}
                                                            />
                                                            {formik.errors.firstName && <FormHelperText error id="component-error-text">{formik.errors.firstName}</FormHelperText>}
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={classes.rowKey}>
                                                        {appConstants.lastName}
                                                    </td>
                                                    <td className={classes.rowValue}>
                                                        <div className={classes.inputWrapper}>
                                                            <InputField
                                                                id="lastName"
                                                                placeholder={appConstants.lastName}
                                                                name="lastName"
                                                                onBlur={formik.handleBlur}
                                                                onChange={formik.handleChange}
                                                                type="lastName"
                                                                value={formik.values.lastName.trimLeft()}
                                                                maxLength={20}
                                                            />
                                                            {formik.errors.lastName && <FormHelperText error id="component-error-text">{formik.errors.lastName}</FormHelperText>}
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={classes.rowKey}>{appConstants.emailAddress}</td>
                                                    <td className={classes.rowValue}>
                                                        <div className={classes.inputWrapper}>
                                                            <InputField
                                                                id="email"

                                                                placeholder={appConstants.emailAddress}
                                                                name="email"
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                // type="email"
                                                                value={formik.values.email?.trimLeft()}
                                                                maxLength={255}
                                                                onKeyDown={handleKeyDown}

                                                            />
                                                            {formik.errors.email && <FormHelperText error id="component-error-text">{formik.errors.email}</FormHelperText>}
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={classes.rowKey}>{appConstants.phoneNumber}</td>
                                                    <td className={classes.rowValue}>
                                                        <div className={classes.inputWrapper}>
                                                            <InputField
                                                                id="phoneNumber"
                                                                name="phoneNumber"
                                                                placeholder={appConstants.phoneNumber}
                                                                onBlur={formik.handleBlur}
                                                                onChange={formik.handleChange}
                                                                // onBlur={handleBlur}
                                                                value={formik.values.phoneNumber}
                                                                maxLength={15}
                                                                onKeyDown={handleKeyDown}
                                                            // disabled
                                                            />
                                                            {formik.errors.phoneNumber && <FormHelperText error id="component-error-text">{formik.errors.phoneNumber}</FormHelperText>}
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={classes.rowKey}>Role Type</td>
                                                    <td className={classes.rowValue}>
                                                        <div className={classes.inputWrapper}>
                                                            <InputField
                                                                id="userType"
                                                                name="userType"
                                                                placeholder="Role Type"
                                                                onBlur={formik.handleBlur}
                                                                onChange={formik.handleChange}
                                                                value={formik.values.userType.trimLeft()}
                                                                maxLength={20}
                                                                autoComplete={false}
                                                            />
                                                            {formik.errors.userType && <FormHelperText error id="component-error-text">{formik.errors.userType}</FormHelperText>}
                                                            {/* <Select
                                                                native
                                                                id="userType"
                                                                className={classes.selectInput}
                                                                value={formik.values.userType}
                                                                // error={Boolean(touched.userType && errors.userType)}
                                                                onBlur={formik.handleBlur}
                                                                onChange={formik.handleChange}
                                                                inputProps={{
                                                                    name: 'userType',
                                                                    id: 'userType',
                                                                }}
                                                                IconComponent={() => <FontAwesomeIcon icon={faChevronDown} className={classes.dropdownIcon} />}
                                                            >
                                                                <option value="">{appConstants.selectType}</option>

                                                                {roles.length > 0 && roles?.map(
                                                                    (time, index) => {
                                                                        return (
                                                                            <option value={time?._id}>
                                                                                {time?.name}
                                                                            </option>
                                                                        );
                                                                    }
                                                                )}
                                                            </Select>
                                                            {formik.errors.userType && <FormHelperText error id="component-error-text">{formik.errors.userType}</FormHelperText>} */}
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className={classes.rowKey}>Type of access</td>
                                                    <tr>
                                                        <td className={classes.rowKey}></td>
                                                        <td className={classes.rowValue}>
                                                            <div className={classes.tableWrapper}>
                                                                <div className="line-view" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                                    <h6>View</h6>
                                                                    <h6 style={{ marginLeft: "3%" }}>Add</h6>
                                                                    <h6 style={{ marginLeft: "3%" }}>Edit</h6>
                                                                    <h6 style={{ marginLeft: "2%" }}>Delete</h6>
                                                                    <h6>Block</h6>

                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    {
                                                        employeeArray.map((item, index) => {
                                                            return (
                                                                <tr>
                                                                    <td className={classes.rowKey}>{item.key}</td>
                                                                    <td className={classes.rowValue}>
                                                                        <div className={classes.tableWrapper}>
                                                                            <div className="line-view" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

                                                                                <Checkbox key={item.key} value={formik.values.access} onChange={(e) => {
                                                                                    handleOnChange(index, 'view')
                                                                                    // formik.setFieldValue("access", checkedState)
                                                                                }}  {...label} disabled={item.viewDisabled == true} />
                                                                                <Checkbox key={item.key} value={formik.values.access} onChange={(e) => {
                                                                                    handleOnChange(index, 'add')
                                                                                    // formik.setFieldValue("access", checkedState)
                                                                                }} {...label} disabled={item.addDisabled == true} />
                                                                                <Checkbox key={item.key} value={formik.values.access} onChange={(e) => {
                                                                                    handleOnChange(index, "edit")
                                                                                    // formik.setFieldValue("access", checkedState)
                                                                                }} {...label} disabled={item.editDisabled == true} />
                                                                                <Checkbox key={item.key} value={formik.values.access} onChange={(e) => {
                                                                                    handleOnChange(index, 'delete')
                                                                                    // formik.setFieldValue("access", checkedState)
                                                                                }}
                                                                                    {...label} disabled={item.deleteDisabled == true} />
                                                                                <Checkbox key={item.key} value={formik.values.access} onChange={(e) => {
                                                                                    handleOnChange(index, 'block')
                                                                                    // formik.setFieldValue("access", checkedState)
                                                                                }} {...label} disabled={item.blockDisabled == true} />

                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    {errorState && <FormHelperText error id="component-error-text">Please select at least one access.</FormHelperText>}
                                                    {/* {formik.errors.access && <FormHelperText error id="component-error-text">{formik.errors.access}</FormHelperText>} */}
                                                </tr>

                                                <tr>
                                                    <td className={classes.rowKey}></td>
                                                    <td className={classes.rowValue}>
                                                        <Button type="submit" title={appConstants.submit} />
                                                    </td>
                                                </tr>
                                            </tbody>
                                    }
                                </BSTable>
                            </form>
                            {/* //     )}
                            // </Formik> */}
                        </Grid>
                    </Grid>
                </Card>
            </DashboardLayout>
        </Spin>
    );
}
