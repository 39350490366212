import { apiConstants as types } from '../../themes/constants'

export const terminalBoardAction=(payload)=>{
    return{
        type:types.API_EDIT_TERMINAL_BOARD_LOAD,
        payload
    } 
} 

export const defaultThemeApplyAction=(payload)=>{
    return{
        type:types.API_APPLY_DEFAULT_THEME_LOAD,
        payload
    } 
} 

export const editDefaultThemeAction=(payload)=>{
    return{
        type:types.API_EDIT_APPLY_DEFAULT_THEME_LOAD,
        payload
    } 
} 

export const defaultThemeDetailsAction=()=>{
    return{
        type:types.API_DEFAULT_THEME_DETAILS_LOAD,
    } 
} 

