import { takeLatest, call, put } from "redux-saga/effects";
import { apiConstants as types, appMessages } from "../../themes/constants";
import axios from "../axios";
import cl from "../../utils/cl";
import history from "../../utils/history";
import { toast } from "react-toastify";

function* pushNotificationFailedSaga(result) {
    yield put({
        type: types.API_PUSH_NOTIFICATIONS_FAILED,
        errorState: result?.error,
    });
    toast.error(result?.error);
}

function* pushNotificationErrorSaga(result) {
    yield put({
        type: types.API_PUSH_NOTIFICATIONS_ERROR,
        errorState: result?.error,
    });
    if (result.status === 2) {
        toast.error(appMessages.messageStatus500);
    } else {
        toast.error(result?.error, { toastId: result?.status || "est" });
    }
}

function* pushNotificationSaga(action) {
    const { title, description,radioButton, users, terminal_id, region_id, selectedUser, notificationType, selectedOpenTime } = action;
    console.log(title, description, users, terminal_id, region_id, selectedUser, notificationType, selectedOpenTime, "saga")
    try {
        const result = yield call(
            axios.postPushNotification,
            title,
            description,
            radioButton,
            users,
            terminal_id,
            region_id,
            selectedUser,
            notificationType,
            selectedOpenTime
        );
        if (result.status === 1) {
            cl("result inside push notification list saga", result);
            yield put({
                type: types.API_PUSH_NOTIFICATIONS_SUCCESS,
                result: result.result.data.data,
            });
            toast.success(result?.result?.data?.message);
            history.push("/notification-listing");
        } else {
            yield call(pushNotificationFailedSaga, result);
        }
    } catch (error) {
        yield call(pushNotificationErrorSaga, error);
    }
}

function* getNotificationListSaga(action) {
    try {
        const result = yield call(axios.getNotificationList, action.payload)
        if (result.status === 1) {
            cl('result inside get terminal area list saga', result)
            yield put({
                type: types.API_PUSH_NOTIFICATIONS_NEW_LIST_SUCCESS,
                result: result.result.data.data,
            })
        }
        else {
            yield call(pushNotificationFailedSaga, result)
        }
    }
    catch (error) {
        yield call(pushNotificationErrorSaga, error)
    }
}

function* getNotificationDetailsSaga(action) {
    try {
        const result = yield call(axios.getPushNotificationsDetails, action.id)
        if (result.status === 1) {
            cl('result inside get terminal area list saga', result)
            yield put({
                type: types.API_PUSH_NOTIFICATIONS_NEW_DETAILS_SUCCESS,
                result: result.result.data.data,
            })
        }
        else {
            yield call(pushNotificationFailedSaga, result)
        }
    }
    catch (error) {
        yield call(pushNotificationErrorSaga, error)
    }
}

function* editNotificationSaga(action) {
    const {notificationId, title, description,radioButton, users, terminal_id, region_id, selectedUser, notificationType, selectedOpenTime } = action;
    try {
        const result = yield call(axios.editPushNotification, notificationId, title,
            description,
            radioButton,
            users,
            terminal_id,
            region_id,
            selectedUser,
            notificationType,
            selectedOpenTime)
        if (result.status === 1) {
            cl('result inside get terminal area list saga', result)
            yield put({
                type: types.API_PUSH_NOTIFICATIONS_EDIT_SUCCESS,
                result: result.result.data.data,
            })
            toast.success(result?.result?.data?.message);
            history.push("/notification-listing");
        }
        else {
            yield call(pushNotificationFailedSaga, result)
        }
    }
    catch (error) {
        yield call(pushNotificationErrorSaga, error)
    }
}

export default function* rootPushNotificationSaga() {
    yield takeLatest(types.API_PUSH_NOTIFICATIONS_LOAD, pushNotificationSaga);
    yield takeLatest(types.API_PUSH_NOTIFICATIONS_NEW_LIST_LOAD, getNotificationListSaga)
    yield takeLatest(types.API_PUSH_NOTIFICATIONS_NEW_DETAILS_LOAD, getNotificationDetailsSaga)
    yield takeLatest(types.API_PUSH_NOTIFICATIONS_EDIT_LOAD,editNotificationSaga)
}