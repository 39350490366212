import React, { useState, useEffect, useRef } from 'react';
// Navigation
import { useHistory, useLocation } from 'react-router-dom'
// Styles
import 'antd/dist/antd.css'
import '../dashboard.scss'
// Third party Components
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Spin } from 'antd'
import { Card, Table as BSTable, } from 'react-bootstrap';
import { Grid, useTheme, Modal, CircularProgress } from '@material-ui/core';
// Custom components
import DashboardLayout from '../../layouts/dashboardLayout'
import { InputField, Navbar, AppBar, Button } from '../../customComponents'
// Constants
import { AppImages } from '../../themes/appImages'
import { Colors } from '../../themes/colors'
import { appConstants, ValidationConstants } from '../../themes/constants'
// utils methods
import cl from '../../utils/cl'
import { useStyles } from '../../styles'
// Redux
import { useSelector, useDispatch } from 'react-redux'
import { addTerminalAreaAction } from '../../store/actions'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
// Mui Components
import {
    Select,
    FormHelperText,
    Collapse,
    Checkbox,
} from "@material-ui/core";
// Global constants
import { getStateListAction } from "../../store/actions/stateListingActions"

export const AddTerminalArea = () => {
    // Hooks declarations
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const location = useLocation()
    const dispatch = useDispatch()
    const terminalAreaState = useSelector(state => state.terminalAreaManagementReducer)
    // local state initialization
    const { isLoading } = terminalAreaState
    //Constants
    const values = {
        terminalAreaName: "",
        stateName: ""
    }

    const validationSchema = Yup.object().shape({
        terminalAreaName: Yup.string().test('trim', ValidationConstants.terminalAreaName.empty, value => value?.trim()?.length > 0).min(2, ValidationConstants.terminalAreaName.short).max(50, ValidationConstants.terminalAreaName.long).required(ValidationConstants.terminalAreaName.empty),
        stateName: Yup.string().required(ValidationConstants.stateName.empty),
    })
    const { stateListing } = useSelector(
        (state) => state.stateListingReducer
    );

    // Lifecycle Hooks
    useEffect(() => {
        document.title = appConstants.headerTitle.addTerminalArea;
        dispatch(getStateListAction("", null, null, "", ""));
    }, [])

    //Callback methods
    const handleFormSubmit = (values, { setSubmitting }) => {
        setSubmitting(false)
        dispatch(addTerminalAreaAction(values.terminalAreaName.trim(), values.stateName))
    }

    return (
        <Spin
            size="large"
            spinning={isLoading}
            wrapperClassName={classes.tableSpinner}
            className={classes.antSpin}
        >
            <DashboardLayout>
                <AppBar breadcrumbs={[{ route: '/terminal-area-list', name: "Region Listing" }, { route: '/add-terminal-area', name: "Add Region" }]} />
                <Navbar title="Add Region" />
                <Card className={classes.card}>
                    <Grid container>
                        <Grid className={classes.tableContainerRow}>
                            <Formik
                                enableReinitialize
                                initialValues={values}
                                validationSchema={validationSchema}
                                onSubmit={handleFormSubmit}
                            >
                                {({
                                    values,
                                    errors,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                    isSubmitting,
                                    touched,
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <BSTable striped bordered>
                                            <tbody>
                                                <tr>
                                                    <td className={classes.rowKey}>State Name</td>
                                                    <td className={classes.rowValue}>
                                                        <div className={classes.inputWrapper}>
                                                            <Select
                                                                native
                                                                className={classes.selectInput}
                                                                defaultValue={values.stateName}
                                                                value={values.stateName}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                inputProps={{
                                                                    name: "stateName",
                                                                    id: "stateName",
                                                                }}
                                                            // IconComponent={() => (
                                                            //     <FontAwesomeIcon
                                                            //         icon={faChevronDown}
                                                            //         className={classes.dropdownIcon}

                                                            //     />

                                                            // )}
                                                            >
                                                                <option value="">
                                                                    Select State Name
                                                                </option>
                                                                {stateListing.map(
                                                                    (stateName, index) => {
                                                                        return (
                                                                            <option value={stateName._id}>
                                                                                {stateName.state_name}
                                                                            </option>
                                                                        );
                                                                    }
                                                                )}
                                                            </Select>
                                                            {touched.stateName && errors.stateName && (
                                                                <FormHelperText
                                                                    error
                                                                    id="component-error-text"
                                                                >
                                                                    {errors.stateName}
                                                                </FormHelperText>
                                                            )}
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={classes.rowKey}>
                                                        {appConstants.terminalAreaName}
                                                    </td>
                                                    <td className={classes.rowValue}>
                                                        <div className={classes.inputWrapper}>
                                                            <InputField
                                                                id="terminalAreaName"
                                                                placeholder={appConstants.enterTerminalAreaName}
                                                                name="terminalAreaName"
                                                                error={Boolean(touched.terminalAreaName && errors.terminalAreaName)}
                                                                helperText={touched.terminalAreaName && errors.terminalAreaName}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                type="name"
                                                                value={values?.terminalAreaName?.trimLeft()}
                                                                maxLength={50}
                                                            // onPaste={(e) => {
                                                            //     e.preventDefault();
                                                            //     return false;
                                                            // }}
                                                            // onContextMenu={(e) => e.preventDefault()}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={classes.rowKey}></td>
                                                    <td className={classes.rowValue}>
                                                        <Button title="Add" type="submit" className="mr-3" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </BSTable>
                                    </form>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                </Card>
            </DashboardLayout>
        </Spin>
    );
}
