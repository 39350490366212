import { takeLatest, call, put } from 'redux-saga/effects'
import { message } from 'antd'
import { apiConstants as types, appMessages } from '../../themes/constants'
import axios from '../axios'
import cl from '../../utils/cl'
import history from '../../utils/history'
import { toast } from 'react-toastify';

function* terminalFailedSaga(result) {

    yield put({
        type: types.API_EDIT_TERMINAL_BOARD_FAILED,
        errorState: result?.error,
    })
    toast.error(result?.error)
}

function* terminalErrorSaga(result) {
    console.log(result)
    yield put({
        type: types.API_EDIT_TERMINAL_BOARD_ERROR,
        errorState: result?.error,
    })
    if (result.status === 2) {
        toast.error(appMessages.messageStatus500)
    } else {
        toast.error(result?.error)
    }
}


function* editTerminalBoardSaga(action) {
    try {
        const result = yield call(axios.editTerminalBoard, action.payload)
        if (result.status === 1) {
            cl('result inside get terminal area list saga', result)
            yield put({ 
                type: types.API_EDIT_TERMINAL_BOARD_SUCCESS,
                result: result.result.data.data,
            })
            toast.success(result?.result?.data?.message)
            history.push('/terminal-board')
        }
        else {
            yield call(terminalFailedSaga, result)
        }
    }
    catch (error) {
        yield call(terminalErrorSaga, error)
    }
}

function* applyDefaultThemeSaga(action) {
    try {
        const result = yield call(axios.applyDefaultTheme,action.payload )
        if (result.status === 1) {
            cl('result inside get terminal area list saga', result)
            yield put({ 
                type: types.API_APPLY_DEFAULT_THEME_SUCCESS,
                result: result.result.data.data,
            })
            toast.success(result?.result?.data?.message)
        }
        else {
            yield call(terminalFailedSaga, result)
        }
    }
    catch (error) {
        yield call(terminalErrorSaga, error)
    }
}

function* editDefaultThemeSaga(action) {
    try {
        const result = yield call(axios.editDefaultTheme, action.payload)
        if (result.status === 1) {
            cl('result inside get terminal area list saga', result)
            yield put({ 
                type: types.API_EDIT_APPLY_DEFAULT_THEME_SUCCESS,
                result: result.result.data.data,
            })
            toast.success(result?.result?.data?.message)
            history.push('/terminal-board')
        }
        else {
            yield call(terminalFailedSaga, result)
        }
    }
    catch (error) {
        yield call(terminalErrorSaga, error)
    }
}

function* defaultThemeDetailsSaga(action) {
    try {
        const result = yield call(axios.defaultThemeDetails,)
        if (result.status === 1) {
            cl('result inside get terminal area list saga', result)
            yield put({ 
                type: types.API_DEFAULT_THEME_DETAILS_SUCCESS,
                result: result.result.data.data,
            })
        }
        else {
            yield call(terminalFailedSaga, result)
        }
    }
    catch (error) {
        yield call(terminalErrorSaga, error)
    }
}

export default function* rootTerminalBoardSaga() {
    yield takeLatest(types.API_EDIT_TERMINAL_BOARD_LOAD, editTerminalBoardSaga)
    yield takeLatest(types.API_APPLY_DEFAULT_THEME_LOAD, applyDefaultThemeSaga)
    yield takeLatest(types.API_EDIT_APPLY_DEFAULT_THEME_LOAD, editDefaultThemeSaga)
    yield takeLatest(types.API_DEFAULT_THEME_DETAILS_LOAD, defaultThemeDetailsSaga)
}