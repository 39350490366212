import React from "react";
import { Map, Polyline, Polygon, Circle, GoogleApiWrapper, Marker, DirectionsRenderer } from 'google-maps-react';
import { getCenterAndZoomForTimeline } from "../utils/helpers";
import { AppImages } from "../themes/appImages";
import { useStyles } from '../styles';
import "../components/dashboard.scss"

var localLatLong = null

const TimelineMap = (props) => {
    const classes = useStyles();
    const { mapSettings, google, isEditing, center, centerPoint, setCircleZoom, setMapSettings, zoom, onMapClicked, radius, polygonState, formattedOrigin, formattedDestination, terminalDetails } = props
    const [zoomLevel, setZoomLevel] = React.useState(null);
    const [modifiedArray, setModified] = React.useState([]);

    React.useEffect(() => {
        return () => { localLatLong = null }
    }, []);

    React.useEffect(() => {
        if (terminalDetails) {
            let arr = JSON.parse(terminalDetails)
            const tempData = getCenterAndZoomForTimeline(JSON.stringify(arr))
            setZoomLevel(tempData.zoomLevel)
        }
    }, []);

    // var ctx = document.getElementById("canvas").getContext("2d")
    // var gradient = ctx.createLinearGradient(0, 0, 0, 450);
    // gradient.addColorStop(1, 'rgba(25, 110, 161,1)');
    // gradient.addColorStop(0, 'rgba(27,200,140,1)');

    React.useEffect(() => {
        let arr = JSON.parse(terminalDetails)
        // arr = JSON.stringify(arr);
        let modified = [];
        arr.map((i, k) => {
            let obj = {
                lat: i.latitude,
                lng: i.longitude
            }
            modified.push(obj)
        })
        setModified(modified)
    }, [terminalDetails])

    const makeGradientColor = (color1, color2, percent) => {
        var newColor = {};
        const makeChannel = (a, b) => {
            return (a + Math.round((b - a) * (56 / 100)));
        }

        const makeColorPiece = (num) => {
            // console.log(num)
            var num1 = Math.min(num, 255);
            var num2 = Math.max(num1, 0);
            var str = num2.toString(16);
            if (str.length < 2) {
                str = "0" + str
            }
            return (str)
        }
        newColor.r = makeChannel(color1.r, color2.r);
        newColor.g = makeChannel(color1.g, color2.g);
        newColor.b = makeChannel(color1.b, color2.b);
        // console.log(newColor.r)
        newColor.cssColor = "#" +
            makeColorPiece(newColor.r) +
            makeColorPiece(newColor.g) +
            makeColorPiece(newColor.b)
        // console.log(newColor, "newColor",)
        return (newColor)
    }

    for (let i in modifiedArray) {
        var strokeColor = makeGradientColor({
            r: 37,
            g: 141,
            b: 197
        }, {
            r: 4,
            g: 226,
            b: 144
        },
            ((i / modifiedArray.length) * 100)
        );
    }

    return (
        <>
            {mapSettings &&
                <Map
                    initialCenter={center}
                    className={'map'}
                    google={google}
                    styles={mapStyle}
                    style={{ width: "100%", height: "90%" }}
                    zoom={zoomLevel == null ? 16 : zoomLevel}
                    fullscreenControl={true}
                    streetViewControl={false}
                    onClick={(mapProps, map, e) => onMapClicked(mapProps, map, e, radius)}
                    mapTypeControlOptions={{ mapTypeIds: ["roadmap", "satellite"] }}
                    zoomControl={true}
                    minZoom={9}
                >
                    {modifiedArray.length > 0 && <Marker
                        icon={AppImages.greenButton}
                        name={''}
                        position={modifiedArray[0]}
                    />}
                    {modifiedArray.length > 0 && <Marker
                        icon={AppImages.redButton}
                        name={''}
                        position={modifiedArray[modifiedArray.length - 1]}
                    />}
                    {modifiedArray.length > 0 &&
                        modifiedArray.map((x, i) => {
                            return (
                                <Polyline
                                    path={[modifiedArray[i], modifiedArray[i + 1]]}
                                    options={{
                                        // strokeColor: `rgb(37,141,${197 - i})`,
                                        strokeColor: "#2c9bd0",
                                        strokeOpacity: 0.9,
                                        strokeWeight: 7,
                                    }}
                                />
                            )
                        })
                    }
                    {/* {modifiedArray.length > 0 &&
                        modifiedArray.map((x, i) => {
                            return (
                                <Polyline
                                    path={[modifiedArray[i], modifiedArray[i + 1]]}
                                    options={{
                                        strokeColor: "rgb(37,141,197)",
                                        // strokeColor: modifiedArray.length / 3 <= i ? "rgb(37,141,197)" : "rgb(4,226,144)",
                                        strokeOpacity: 0.5,
                                        strokeWeight: 7,
                                    }}
                                />
                            )
                        })
                    } */}
                </Map>
            }
        </>
    );
};
// #1bc88c green color
// #2c9bd0 blue color
export default GoogleApiWrapper({
    apiKey: 'AIzaSyAId9HPoVrtc6rDn9O-tAWERRJEelhkARc'
})(TimelineMap)

const mapStyle = [
    {
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#0f1214"
            }
        ]
    },
    {
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#757575"
            }
        ]
    },
    {
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#212121"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#757575"
            }
        ]
    },
    {
        "featureType": "administrative.country",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#9e9e9e"
            }
        ]
    },
    {
        "featureType": "administrative.land_parcel",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative.locality",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#bdbdbd"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#757575"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#181818"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#616161"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#1b1b1b"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#2c2c2c"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#8a8a8a"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#373737"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#3c3c3c"
            }
        ]
    },
    {
        "featureType": "road.highway.controlled_access",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#4e4e4e"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#616161"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#757575"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#0e1917"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#3d3d3d"
            }
        ]
    },

    {
        "featureType": "landscape.natural",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000000"
            }
        ]
    },

]
