import React, { useState, useEffect } from 'react';
// Navigation
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom'
// Styles
import 'antd/dist/antd.css'
import '../dashboard.scss'
// antd
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { Card, } from 'react-bootstrap';
import { Image, Modal, Tooltip } from "antd";
import { toast } from "react-toastify";
// Mui Components
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Grid, Select, useTheme, FormHelperText } from '@material-ui/core';
import Box from '@material-ui/core/Box';
// Custom components
import DashboardLayout from '../../layouts/dashboardLayout'
import { InputField, AppBar, Button, PaginationBar, Navbar, NavbarSearch, ConfirmationModal, Table } from '../../customComponents'
// Constants
import { appConstants, ValidationConstants } from '../../themes/constants'
import localStorage from "../../utils/localStorage";
// utils methods
import { useStyles } from '../../styles'
// Redux
import { useSelector, useDispatch } from 'react-redux'
import { handleNavigationStateAction, } from '../../store/actions'
import { getPostListAction, getBotUsersListAction, addUserAction, deletePostAction, sharePostAction, clearPostDetails, setBotUserID } from '../../store/actions/postManagementAction';
import { AppImages } from '../../themes/appImages';

// Global constants
const validationSchema = Yup.object().shape({
    firstName: Yup.string()
    // .min(3, ValidationConstants.firstname.short)
        .max(20, ValidationConstants.firstname.long),
        // .required(ValidationConstants.firstname.empty)
        // .test('trim', ValidationConstants.firstname.empty, value => value?.trim()?.length > 0)
        // .test('trim', ValidationConstants.firstname.short, value => value?.trim()?.length > 2)
        
    // lastName: Yup.string()
    //     .min(3, ValidationConstants.lastname.short)
    //     .max(20, ValidationConstants.lastname.long)
    //     .required(ValidationConstants.lastname.empty)
    //     // .test('trim', ValidationConstants.lastname.empty, value => value?.trim()?.length > 0),
    //     .test('trim', ValidationConstants.lastname.short, value => value?.trim()?.length > 2),
    userName: Yup.string().matches(/^(\S+$)/, ValidationConstants.userName.space)
        .matches(/^(?![.])/, ValidationConstants.userName.startWithDot)
        .min(3, ValidationConstants.userName.short)
        .matches(/(?!^\d+$)^.+$/, ValidationConstants.userName.notOnlyNumber)
        .matches(/(?<![.])$/, ValidationConstants.userName.endWithDot)
        .matches(/^(?!.*[.]{2})/, ValidationConstants.userName.twoDots)
        .max(20, ValidationConstants.userName.long)
        .required(ValidationConstants.userName.empty)
        .matches(/^(?![.])(?!.*[.]{2})[a-zA-Z0-9._]+(?![.])$/, ValidationConstants.userName.validUserName),
    image: Yup.mixed().required("Please upload image."),
})

export const PostListing = () => {
    const styleProps = { searchable: true }
    // Hooks declarations
    const classes = useStyles(styleProps);
    const history = useHistory();
    const location = useLocation()
    const dispatch = useDispatch()
    const theme = useTheme()

    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const postListingData = useSelector(state => state.PostManagementReducer)

    // Global state initialization
    const { postListing, postPaging, isLoading, botUsersList, botUSerId } = postListingData

    // local state initialization
    const [search, setSearch] = useState("")
    const [offset, setOffset] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sortBy, setSortBy] = useState("")
    const [order, setOrder] = useState(null)
    const [confirm, setConfirm] = useState(false)
    const [postToDelete, setPostToDelete] = useState(null)
    const [disableButton, setDisableButton] = useState()
    const [addUserModal, setAddUserModal] = useState(false);
    const [sharePost, setSharePost] = useState(false);
    const [description, setDescription] = useState("");
    const [selectUser, setSelectUser] = useState(botUsersList[0]?._id);
    const [userName, setUserName] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [postState, setPostState] = useState("all")
    const [image, setImage] = useState("")
    const [imageUrl, setImageUrl] = useState("");
    const [sharePostData, setSharePostData] = useState("")
    let permissionsData = localStorage?.getPermissions()
    let siderIndex = localStorage.getLayoutArr()
    let index = siderIndex?.findIndex((x) => x.key == "postManagement")
    let employeeIndex = permissionsData?.findIndex((x) => x.name == "postManagement")
    // +++++++++++

    // Method to fetch listing
    const fetchDataListing = (search, offset, limit, sortBy, order, value) => {
        dispatch(getPostListAction({ search, offset, limit, sortBy, order, value: value ? value : postState }))
    }
    //lifecycle hooks

    useEffect(() => {
        dispatch(clearPostDetails())
        document.title = appConstants.headerTitle.postListing;
        if (permissionsData) {

            dispatch(handleNavigationStateAction(index, false))
        }
        else {
            dispatch(handleNavigationStateAction(3, false))
        }
        fetchDataListing(search, offset, limit, sortBy, order, postState)
        dispatch(getBotUsersListAction())
    }, [])

    useEffect(() => {

    }, [sharePostData])

    const setBot = async () => {
        let parse = botUSerId
        if (parse) {

            setSelectUser(parse)
        }
        else {
            setSelectUser(botUsersList[0]?._id)
            dispatch(setBotUserID(botUsersList[0]?._id))
            // localStorage.setBotUser(botUsersList[0]?._id)
        }
    }

    useEffect(() => {
        setBot()
    }, [botUsersList])

    //Callback method to handle sorting 
    const handleChange = async (pagination, filters, sorter, value) => {
        //To fetch listing in ascending order
        if (sorter.order == "ascend") {
            setOrder(1)
            setSortBy(sorter.columnKey)
            fetchDataListing(search, offset, limit, sorter.columnKey, 1, postState)
        } //To fetch listing in descending order
        else if (sorter.order === "descend") {
            setOrder(-1)
            setSortBy(sorter.columnKey)
            fetchDataListing(search, offset, limit, sorter.columnKey, -1, postState)
        } //To fetch listing in normal order
        else {
            setOrder(null)
            setSortBy("")
            fetchDataListing(search, offset, limit, "", null, postState)
        }
    }

    // Callback method to handle page chage
    const handlePaginationChange = (e, page, value) => {
        const offsetTo = (page - 1) * limit
        setOffset(offsetTo)
        fetchDataListing(search, offsetTo, limit, sortBy, order, value ? value : postState)
    }

    // Callback method to handle searching key
    const handleSearching = async (e) => {
        var tempText = e.target.value
        if (e.target.value.charAt(0) === " ") {
            tempText = e.target.value.replace(/^ +/gm, '');
        }
        setSearch(tempText)
        fetchDataListing(tempText, 0, limit, "", null, postState)

    }
    // +++++++++++
    const initialValues = {
        firstName: firstName,
        lastName: lastName,
        userName: userName,
        image: null
    }

    const columns = [
        {
            title: appConstants.sr,
            dataIndex: 'sr',
            key: 'sr',
            render: (text, record, index) => (index + 1) + (postPaging?.page?.nextPage - limit),
            ellipsis: false,
            width: 100,
        },
        {
            title: 'Username',
            dataIndex: 'user',
            key: 'user',
            ellipsis: false,
            sorter: true,
            render: (text, record,) => <div>{record?.user?.userName}</div>,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            ellipsis: false,
            sorter: true,
            render: (description, record) => <div
                style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "400px"
                }}
            >{record.description ? record.description : "N/A"}</div>,
        },

        {
            title: 'Image',
            dataIndex: 'images',
            key: 'images',
            ellipsis: false,
            sorter: false,
            render: (images, record) => <div className='image-center'>{
                images?.length > 0 ?
                    <img src={appConstants.baseURL + images[0]?.url}
                        className={classes.imageManagementLogo}
                    ></img>
                    :
                    "N/A"
            }</div>
        },
        {
            title: 'Video',
            dataIndex: 'thumbnail_image',
            key: 'thumbnail_image',
            ellipsis: false,
            sorter: false,
            width: 40,
            render: (video, record) => {

                return (
                    record?.video ?
                        // <video
                        //     style={{
                        //         width: "100%",
                        //         objectFit: "contain",
                        //         // height: "100px"
                        //     }}
                        // >
                        <img src={appConstants.baseURL + record?.thumbnail_image}
                            className={classes.terminalImage}
                            type="video/mp4"
                        ></img>
                        // </video>
                        :
                        "N/A"
                )
            }
        },
        {
            title: 'Bot Users',
            dataIndex: 'bot',
            key: 'bot',
            ellipsis: false,
            sorter: false,
            render: (text, record,) => <div>{record?.user?.type == "bot" ? "Yes" : "No"}</div>,
        },
        {
            title: 'Shared By',
            dataIndex: 'bot',
            key: 'bot',
            ellipsis: false,
            sorter: false,
            render: (text, record,) => <div>{record?.shared_by ? record?.shared_by?.userName : "N/A"}</div>,
        },
        {
            title: appConstants.action,
            dataIndex: '',
            key: 'x',
            width: 100,
            align: 'center',
            render: (text, record, index) => {
                return (
                    <Grid className={classes.buttons}>
                        <Button disabled={(record?.deleted_at !== null) || ((permissionsData) && permissionsData[employeeIndex]?.view == false)} title={appConstants.view}
                            onClick={() => history.push({
                                pathname: "/post-details",
                                state: { postId: record._id, userId: record.user._id, botUserId: selectUser }
                            })}
                        />
                        <Button
                            disabled={(selectUser !== (record?.shared_by ? record?.shared_by?._id : record?.user?._id) || record?.deleted_at !== null) || (permissionsData && permissionsData[employeeIndex]?.edit == false)} title={appConstants.edit}
                            onClick={() => history.push({
                                pathname: "/edit-post",
                                // state: { stateName: record.state_name, shortName: record.short_name, stateId: record._id },
                                state: { postData: record, botUserId: selectUser }
                            })}
                        />
                        <Button title={appConstants.delete}
                            disabled={(record.user.type == 'dummy') ? false : ((selectUser !== (record?.shared_by ? record?.shared_by?._id : record?.user?._id)) || record?.deleted_at !== null || ((permissionsData) && permissionsData[employeeIndex]?.delete == false)) }
                            // disabled={(permissionsData) && permissionsData[employeeIndex]?.delete == false}
                            onClick={() => {
                                setConfirm(true)
                                setPostToDelete(record._id)
                                setDisableButton(false)

                            }} />
                        <Button title="Share"
                            disabled={(record?.deleted_at !== null || record?.shared_by)}
                            onClick={() => {
                                setSharePost(true)
                                setSharePostData(record)
                            }} />
                    </Grid>
                )
            },
        },
    ];

    const imageValidation = (value) => {
        let fileTypes = ["jpg", "jpeg", "svg", "png"]
        return (fileTypes.indexOf(value) > -1)
    }

    const handleImageChange = (event) => {

        if (event.target.files && event.target.files[0]) {
            let uploadFile = event.target.files[0]
            let splitName = uploadFile.name.split(".").pop().toLowerCase()
            let isSuccess = imageValidation(splitName)
            if (isSuccess) {
                setImageUrl(event.target.files[0]);

                let reader = new FileReader();
                reader.onload = (e) => {
                    setImage(e.target.result);
                };
                reader.readAsDataURL(event.target.files[0]);
                return
            }
            else {
                toast.error(appConstants.imageValidation)
            }
        }
    };

    const onSubmit = (values, { setSubmitting }) => {
        // if (values.firstName.length > 0) {
        //     if (values.lastName.length > 0) {
        //         if (values.userName.length > 0) {
        setAddUserModal(false)
        dispatch(addUserAction({ userName: values.userName, firstName: values.firstName, lastName: values.lastName, imageUrl }))
        formik.resetForm()
        setImage("")
        //         }
        //     }
        // }
    };

    const closeModal = () => {
        setAddUserModal(false)
    }

    const closeShareModal = () => {
        setSharePost(false)
        setDescription("")
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema,
        onSubmit,
    });

    const onChangePost = (e) => {
        setPostState(e.target.value)
        setOffset(0)
        fetchDataListing(search, 0, limit, sortBy, order, e.target.value)
    }

    const sendPost = () => {
        setSharePost(false)
        setTimeout(() => {
            dispatch(sharePostAction({ post_id: sharePostData?._id, description: description.trim(), userId: selectUser }))
        }, 200);
        setDescription("")
    }
    const handleKeyDown = (e) => {
        if (e.key == " ") {
            e.preventDefault();
        }
    }

    return (
        <DashboardLayout>
            <ConfirmationModal title="Alert" text={appConstants.confirmDeletePost} visible={confirm}
                disable={disableButton}
                onCancel={() => {
                    setConfirm(false)
                }}
                onDeleteConfirmed={() => {
                    dispatch(deletePostAction(postToDelete))
                    setConfirm(false)
                    setDisableButton(true)
                }}
            />
            <AppBar breadcrumbs={[{ route: '/postListing', name: "Post Management" }]} />
            <Grid container className={classes.buttonBarWrapper} >
                <Button title="Add Post" disabled={botUsersList.length == 0 || (permissionsData && permissionsData[employeeIndex]?.add == false)}
                    onClick={() => history.push({
                        pathname: '/add-post',
                        state: { userId: selectUser }
                    })}
                    mr mb />
                <Button title="Add Bot User" disabled={permissionsData && permissionsData[employeeIndex]?.add == false} 
                onClick={() => setAddUserModal(true)} mr mb />
                <div className={classes.inputWrapper} style={{ width: "20%", marginRight: "1rem" }}>
                    <Select
                        native
                        style={{ backgroundColor: "#fff" }}
                        className={classes.selectInput}
                        value={selectUser}
                        onChange={(e) => {

                            setSelectUser(e.target.value)
                            dispatch(setBotUserID(e.target.value))
                            // localStorage.setBotUser(e.target.value)
                            // dispatch(getBotUsersListAction({ userId: e.target.value }))
                        }}
                        inputProps={{
                            name: "selectUser",
                            id: "selectUser",
                        }}
                    >
                        {
                            botUsersList.length <= 0 &&
                            <option value="">
                                Select Bot User
                            </option>
                        }
                        {
                            botUsersList?.map(
                                (data, index) => {
                                    return (
                                        <option value={data?._id}>
                                            {data?.userName}
                                        </option>
                                    );
                                }
                            )}
                    </Select>
                </div>
                <div className={classes.inputWrapper} style={{ width: "140px", }}>
                    <Select
                        native
                        style={{ backgroundColor: "#fff" }}
                        className={classes.selectInput}
                        value={postState}
                        onChange={(e) => onChangePost(e)}
                        inputProps={{
                            name: "postState",
                            id: "postState",
                        }}
                    >
                        <option value={"all"}>All</option>
                        <option value="active">Active</option>
                        <option value="deleted">Deleted</option>

                    </Select>
                </div>
            </Grid>
            <Modal
                onCancel={() => {
                    closeModal()
                    formik.resetForm();
                    setImage("")
                }} visible={addUserModal} footer={null} centered maskClosable={false}>
                <form onSubmit={formik.handleSubmit}>
                    <div style={{ zIndex: 999999 }}>
                        <div className="reply-modal">
                            <span style={{ color: "#000", fontSize: 20 }}>Add Bot User</span>
                        </div>
                        <div
                            style={{ alignSelf: "center", marginTop: "10px" }}
                        >
                            <div style={{
                                display: "flex", justifyContent: "center", flexDirection: "column",
                                alignItems: "center", zIndex: "9999",
                            }}>
                                {
                                    <>
                                        {
                                            <>
                                                <input
                                                    accept=".png,.jpg,.jpeg"
                                                    className={classes.uploaderInput}
                                                    id="icon-button-file"
                                                    type="file"

                                                    onChange={(e) => {
                                                        handleImageChange(e)
                                                        if (e.target.files[0]) {

                                                            formik.setFieldValue("image", e.target.files[0])
                                                        }

                                                    }}
                                                />
                                                <label
                                                    htmlFor="icon-button-file"
                                                    className={classes.imageManagementUploaderlabel}
                                                >
                                                    <Tooltip
                                                        title={image ? appConstants.tooltip.changeImage : appConstants.tooltip.uploadImage}
                                                        placement="right"
                                                        overlayStyle={{ zIndex: "9999999" }}
                                                    >

                                                        {image ? (
                                                            <img
                                                                src={image}
                                                                className={classes.terminalImage}
                                                                style={{ borderRadius: "50px", border: "2px solid lightgrey" }}
                                                            />
                                                        ) : (
                                                            <img
                                                                src={AppImages.addPhoto}
                                                                className={classes.terminalImage}

                                                            />
                                                        )}
                                                    </Tooltip>
                                                </label>
                                                {formik.errors.image && <FormHelperText error id="component-error-text" style={{ marginTop: "-8px" }}>{formik.errors.image}</FormHelperText>}
                                            </>}
                                    </>
                                }
                            </div>
                            <div style={{ alignSelf: "center", }}>
                                <span style={{ color: "#000", fontSize: 14 }}>
                                    Username
                                </span>
                                <InputField
                                    id="userName"
                                    placeholder={"Username"}
                                    name="userName"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    type="userName"
                                    value={formik.values.userName}
                                    onKeyDown={handleKeyDown}
                                    maxLength={20}
                                />
                                {formik.errors.userName && <FormHelperText error id="component-error-text">{formik.errors.userName}</FormHelperText>}
                            </div>
                            <div style={{ alignSelf: "center", marginTop: "10px" }}>
                                <span style={{ color: "#000", fontSize: 14 }}>
                                    Name
                                </span>
                                <InputField
                                    id="firstName"
                                    placeholder={"Name"}
                                    name="firstName"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    type="firstName"
                                    value={formik.values.firstName.trimLeft()}
                                    maxLength={20}
                                />
                                {formik.errors.firstName && <FormHelperText error id="component-error-text">{formik.errors.firstName}</FormHelperText>}
                            </div>
                            {/* <div style={{ alignSelf: "center", marginTop: "10px" }}>
                                <span style={{ color: "#000", fontSize: 14 }}>
                                    Last Name
                                </span>
                                <InputField
                                    id="lastName"
                                    placeholder={appConstants.lastName}
                                    name="lastName"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    type="lastName"
                                    value={formik.values.lastName.trimLeft()}
                                    maxLength={20}
                                />
                                {formik.errors.lastName && <FormHelperText error id="component-error-text">{formik.errors.lastName}</FormHelperText>}
                            </div> */}
                        </div>
                        <div
                            style={{
                                alignItems: "center",
                                justifyContent: "space-around",
                                display: "flex",
                                marginTop: "20px",
                            }}
                        >
                            <Button
                                title="Add"
                                type="submit"
                                className="mr-3"
                                width="108px"

                            />
                        </div>
                    </div>
                </form>
            </Modal>
            <Modal
                onCancel={() => closeShareModal()} visible={sharePost} footer={null} centered maskClosable={false}>
                <div className="reply-modal">
                    <span style={{ color: "#000", fontSize: 20 }}>Share Post</span><br></br>
                </div>
                <div style={{ color: "#000", fontSize: 14, marginTop: "15px" }}>
                    {sharePostData?.user?.userName}
                </div>
                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                    <textarea
                        id="description"
                        placeholder="Enter Description"
                        name="firstName"
                        value={description.trimLeft()}
                        onChange={(e) => setDescription(e.target.value)}
                        style={{ resize: "none" }}
                        className="textAreaNew1"
                    />
                </div>
                <div style={{ display: "flex" }}>
                    {
                        sharePostData?.images?.map((item, index) => {
                            return (

                                <Image
                                    onClick={() => closeShareModal(false)}
                                    src={appConstants.baseURL + item?.url}
                                    style={{ height: "90px", width: "90px", borderRadius: "45px", border: "2px solid lightgrey" }}></Image>
                            )
                        })
                    }  </div>

                {sharePostData?.video &&
                    <div style={{ marginTop: "10px" }}>
                        <video
                            key={sharePostData._id}
                            controls
                            style={{
                                width: "50%",
                                // objectFit: "contain",
                                height: "100px",

                            }}
                        >
                            <source
                                key={sharePostData._id}
                                src={appConstants.baseURL + sharePostData?.video}
                                className={classes.terminalImage}
                                type="video/mp4"
                            ></source>
                        </video>
                    </div>
                }
                <div
                    style={{
                        alignItems: "center",
                        justifyContent: "space-evenly",
                        display: "flex",
                        marginTop: "10px",
                    }}
                >
                    <Button
                        title="Cancel"
                        type="reset"
                        className="mr-3"
                        width="108px"
                        onClick={() => {
                            setSharePost(false)
                            setDescription("")
                        }}
                    />
                    <Button
                        title="Share"
                        className="mr-3"
                        width="108px"
                        onClick={() => sendPost()}
                    />
                </div>
            </Modal>
            <Navbar title="Post Management" searchable value={search} onChange={(e) => handleSearching(e)} />
            <Card className={classes.card}>
                {matches &&
                    <Box className={classes.searchBox}>
                        <NavbarSearch value={search} onValueChange={(e) => handleSearching(e)} />
                    </Box>
                }
                <Grid container>
                    <Grid className={classes.tableContainerRow}>
                        <Table
                            // loading={isLoading}
                            columns={columns}
                            dataSource={postListing}
                            onChange={handleChange}
                            searching={search.length > 0}
                        />
                    </Grid>
                </Grid>
                <PaginationBar
                    totalCount={postPaging?.page?.totalCount}
                    count={Math.ceil(postPaging?.page?.totalCount / limit)}
                    currentPage={postPaging?.page?.currentPage}
                    nextPage={postPaging?.page?.nextPage}
                    onChange={(e, page) => handlePaginationChange(e, page)}
                    prevPage={postPaging?.page?.nextPage - limit}
                />
            </Card>
        </DashboardLayout>
    );
}
