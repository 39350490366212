import localStorage from "./localStorage";
import { S3, AWS } from 'aws-sdk';
import { S3_BUCKET, REGION, ACCESS_KEY_ID, SECRET_ACCESS_KEY } from "../themes/constants";

const LN2 = 0.6931471805599453;
const WORLD_PX_HEIGHT = 256;
const WORLD_PX_WIDTH = 256;
const ZOOM_MAX = 21;


// Check image Ratio for 2:1
export const validateImageRatio = (file) => {
    return new Promise((resolve, reject) => {
        let image = new Image()
        image.src = URL.createObjectURL(file)
        image.onload = () => image.width / image.height == 2 ? resolve('true') : reject('false')
    })

}
export const validateImageRatioOneByOne = (file) => {
    return new Promise((resolve, reject) => {
        let image = new Image()
        image.src = URL.createObjectURL(file)
        image.onload = () => image.width / image.height == 1 ? resolve('true') : reject('false')
    })
}

const checkLat = (lat) => {

    if (lat.match(/^-?(?:90(?:(?:\.0{1,8})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,8})?))$/)) {
        return true
    }
    else {
        return false
    }

}
const checkLong = (long) => {
    console.log(long.match(/^(\+|-)?(?:180(?:(?:\.0{1,8})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,8})?))$/))
    if (long.match(/^(\+|-)?(?:180(?:(?:\.0{1,8})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,8})?))$/)) {
        return true
    }
    else {
        return false
    }
}


export const convertPolygonValues = (value) => {
    console.log("in the function", value)
    let termianlPolygonValue = `${value}`.toString()
    let termianlPolygonValueWithoutNewline = termianlPolygonValue && termianlPolygonValue.replace(/\n/g, ' ')
    let firstSplit = termianlPolygonValueWithoutNewline && termianlPolygonValueWithoutNewline.split(") ");
    console.log("the split", firstSplit)

    let polygonArray = []
    firstSplit.map((x) => {
        let commaSpilted = x.includes(", ") && x.split(', ')
        console.log(commaSpilted)
        let lat = commaSpilted && commaSpilted[0].split('(')
        let long = commaSpilted && commaSpilted[1].split(')')

        if (parseFloat(lat[1]) || parseFloat(long[0])) {
            //  if(checkLat(lat[1]) && checkLong(long[0])){
            let obj = {
                latitude: parseFloat(lat[1]), longitude: parseFloat(long[0]),
            }
            polygonArray.push(obj)
            // }
        }
    })
    return polygonArray

}

export const convertPolygonValuesToMap = (arr) => {
    // console.log(arr)
    let array = JSON.parse(arr)
    // console.log(array)
    if (typeof array === "object") {
        // console.log("its bobject", typeof array)
    }
    let str = [];
    for (var item of array) {
        let value = "(" + item.latitude.toFixed(8) + "," + item.longitude.toFixed(8) + ")"
        str.push(value);
    }

    // console.log(str)
    return str;
}


// backend zoom level
export const getCenterAndZoomForBackend = (polygon_area) => {
    var centerLocation = null
    var zoom = null
    if (polygon_area.length > 0) {
        const bounds = new window.google.maps.LatLngBounds();
        for (var item of polygon_area) {
            bounds.extend({ lat: item.latitude, lng: item.longitude });
        }
        centerLocation = { lat: bounds.getCenter().lat().toFixed(3), lng: bounds.getCenter().lng().toFixed(3) }
        zoom = getZoomLevel(bounds, 512, 200)
    }
    else {
        centerLocation = { lat: 25.774, lng: -80.190 }
        zoom = 14
    }
    return {
        centerLocation: centerLocation,
        zoomLevel: zoom
    }
}


export const getCenterAndZoom = (polygon_area) => {
    var centerLocation = null
    var zoom = null
    let polygonValueToShow = polygon_area === "undefined" || polygon_area === undefined || polygon_area === null || polygon_area === "null" ? []
        : JSON.parse(polygon_area);

    if (polygonValueToShow.length > 0) {
        const bounds = new window.google.maps.LatLngBounds();
        for (var item of polygonValueToShow) {
            bounds.extend({ lat: item.latitude, lng: item.longitude });
        }
        centerLocation = { lat: bounds.getCenter().lat().toFixed(3), lng: bounds.getCenter().lng().toFixed(3) }
        zoom = getZoomLevel(bounds, 256, 256)
    }
    else {
        centerLocation = { lat: 25.774, lng: -80.190 }
        zoom = 14
    }
    return {
        centerLocation: centerLocation,
        zoomLevel: zoom
    }
}

export const getCenterAndZoomForTimeline = (polygon_area) => {
    var centerLocation = null
    var zoom = null
    let polygonValueToShow = polygon_area === "undefined" || polygon_area === undefined || polygon_area === null || polygon_area === "null" ? []
        : JSON.parse(polygon_area);

    if (polygonValueToShow.length > 0) {
        const bounds = new window.google.maps.LatLngBounds();
        for (var item of polygonValueToShow) {
            bounds.extend({ lat: item.latitude, lng: item.longitude });
        }
        centerLocation = { lat: bounds.getCenter().lat().toFixed(3), lng: bounds.getCenter().lng().toFixed(3) }
        zoom = getZoomLevel(bounds, 256, 100)
    }
    else {
        centerLocation = { lat: 25.774, lng: -80.190 }
        zoom = 14
    }
    return {
        centerLocation: centerLocation,
        zoomLevel: zoom
    }
}

export const getCenterAndZoom1 = (polygon_area) => {
    var centerLocation = null
    var zoom = null
    let polygonValueToShow = polygon_area === "undefined" || polygon_area === undefined || polygon_area === null || polygon_area === "null" ? []
        : JSON.parse(polygon_area);

    if (polygonValueToShow.length > 0) {
        const bounds = new window.google.maps.LatLngBounds();
        for (var item of polygonValueToShow) {
            bounds.extend({ lat: item.lat, lng: item.lng });
        }
        centerLocation = { lat: bounds.getCenter().lat().toFixed(3), lng: bounds.getCenter().lng().toFixed(3) }
        zoom = getZoomLevel(bounds, 256, 256)
    }
    else {
        centerLocation = { lat: 25.774, lng: -80.190 }
        zoom = 14
    }
    return {
        centerLocation: centerLocation,
        zoomLevel: zoom
    }
}

const getZoomLevel = (bounds, mapWidthPx, mapHeightPx) => {
    var ne = bounds.getNorthEast();
    var sw = bounds.getSouthWest();
    var latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

    var lngDiff = ne.lng() - sw.lng();
    var lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

    var latZoom = zoom(mapHeightPx, WORLD_PX_HEIGHT, latFraction);
    var lngZoom = zoom(mapWidthPx, WORLD_PX_WIDTH, lngFraction);

    var result = Math.min(latZoom, lngZoom);
    return Math.min(result, ZOOM_MAX);
}

const latRad = (lat) => {
    var sin = Math.sin(lat * Math.PI / 180);
    var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
}

const zoom = (mapPx, worldPx, fraction) => {
    return Math.floor(Math.log(mapPx / worldPx / fraction) / LN2);
}

export function getPermissions(key) {
    let permissionsData = localStorage?.getPermissions()
    let employeeIndex = permissionsData?.findIndex((x) => x.name == key)
    // permissionsData[employeeIndex].view == false
    return employeeIndex
}

export function getReactionType(res) {
    console.log(res, "dfbdjsfnckdsn")
    if (res) {
        switch (res) {
            case 'like':
                return require("../assets/images/thumbs_up.png").default;
            case 'dislike':
                return require('../assets/images/thumbs_down.png').default;
            // case 'rollingEyes':
            //   return 3;
            case 'grinningSmile':
                return require('../assets/images/grinning_face_with_smiling_eyes.png').default;
            case 'angry':
                return require('../assets/images/face_with_steam_from_nose.png').default;
            case 'confuse':
                return require('../assets/images/face_with_rolling_eyes.png').default;
            // case 'beamingSmile':
            //   return 6;
            case 'thinking':
                return require('../assets/images/thinking_face.png').default;
            default:
                return require('../assets/images/thumbs_up.png').default;
        }
    }
    else {
        return require('../assets/images/NotLike.png').default;
    }
}

// aws 
///////UDID create//////////////
export const uuidv4_34 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

///// bucket/////////////////
export const s3bucket = new S3({
    accessKeyId: ACCESS_KEY_ID,
    secretAccessKey: SECRET_ACCESS_KEY,
    Bucket: S3_BUCKET,
    signatureVersion: 'v4',
    httpOptions: {}
});

//upload aws file
export const uploadImageOnS3 = async (file, fileReaded, udid, folder, oldFilesArray) => {
    // if (response) {
    console.log('file', file);
    const extent = file.name.split('.')
    const extentT = extent && extent[extent.length - 1];
    let contentType = file.type;
    let contentDeposition = 'inline;filename="' + file.name + '"';
    let reader = new FileReader()
    // const arrayBuffer = base64
    const params = {
        Bucket: S3_BUCKET,
        Key: `panel/${folder}/${udid}.${extentT}`,
        Body: fileReaded,
        ContentDisposition: contentDeposition,
        ContentType: contentType,
        oldFilesArray: oldFilesArray
    };
    return params
    // }

};


export const isColor=(strColor) =>{
    var s = new Option().style;
    s.color = strColor;
    return s.color == strColor;
}

// onchange color function

export const onChangeBackgroundColor = (e, value, error) => {
    let regex = /^#[0-9A-F]{6}$/i
    if(e.target.value.length<1){
        value(e.target.value);
        error("Please enter color code or color name.")
        return false
    }
     if (isColor(e.target.value.trim().toLowerCase()) == false && !regex.test(e.target.value.trim())) {
        error("Please enter valid color code or valid color name.")
    }
    else {
        error(null)
    }
    value(e.target.value);
    return error
}



