import { apiConstants as types } from '../../themes/constants'
import localStorage from '../../utils/localStorage'
const initialState = {
    isLoading: false,
    isTableDataLoading: false,
    userListingResult: {},
    savedUserLocationsResult: {},
    viewTimeline: {},
    userDetails: {},
    timelineDetails: {},
    currentPage: 1,
    reportedUsers: {},
    reportedUserDetails: {},
    timezoneList: {},
}

export const userManagementReducer = (state = initialState, action) => {
    switch (action.type) {
        // User Management Cases
        // Clear State Case 
        case types.CLEAR_STATE:
            if (action.key === "clearUserDetailsState") {
                return {
                    ...state, userDetails: {},
                }
            } else {
                return { ...state }
            }

        // Get user list
        case types.API_GET_USER_LIST_LOAD:
            return { ...state, isLoading: true, }
        case types.API_GET_USER_LIST_SUCCESS:
            return { ...state, isLoading: false, userListingResult: action.result, currentPage: action.result?.paging?.page?.currentPage }

        // Get user details
        case types.API_GET_USER_DETAILS_LOAD:
            return { ...state, isTableDataLoading: true,isLoading:true }
        case types.API_GET_USER_DETAILS_SUCCESS:
            return { ...state, isTableDataLoading: false,isLoading:false, userDetails: action.result.userDetails }

        // Edit user details
        case types.API_EDIT_USER_DETAILS_LOAD:
            return { ...state, isLoading: true }
        case types.API_EDIT_USER_DETAILS_SUCCESS:
            return { ...state, isLoading: false }

        // Block user details
        case types.API_BLOCK_USER_LOAD:
            return { ...state, isLoading: true }
        case types.API_BLOCK_USER_SUCCESS:
            console.log(action.result, "reducer***")
            // Find the index of blocked/unblocked user and 
            // change block to unblock or vice verse
            let userListing = state.userListingResult.listUsers;
            let userBlockedIndex = userListing.findIndex(user => user._id === action.result.user_id)
            userListing[userBlockedIndex].blocked = userListing[userBlockedIndex].blocked == true ? false : true;

            state.userListingResult.listUsers = userListing
            return { ...state, isLoading: false, userListingResult: state.userListingResult }

        //User Saved Locations
        case types.API_SAVED_USER_LOCATIONS_LOAD:
            return { ...state, isLoading: true }
        case types.API_SAVED_USER_LOCATIONS_SUCCESS:
            return { ...state, isLoading: false, savedUserLocationsResult: action.result }


        // view timeline
        case types.API_VIEW_TIMELINE_DETAILS_LOAD:
            return { ...state, isLoading: true }
        case types.API_VIEW_TIMELINE_DETAILS_SUCCESS:

            return { ...state, isLoading: false, viewTimeline: action.result }

        //timeline details
        case types.API_GET_TIMELINE_DETAILS_LOAD:
            return { ...state, isTableDataLoading: true, }
        case types.API_GET_TIMELINE_DETAILS_SUCCESS:

            return { ...state, isTableDataLoading: false, timelineDetails: action.result.routePostDetails }

        // Export user
        case types.API_EXPORT_USER_FILE_LOAD:
            return { ...state, isLoading: true, errorState: null, }
        case types.API_EXPORT_USER_FILE_SUCCESS:
            return { ...state, isLoading: false }

        // Import user
        case types.API_IMPORT_USER_FILE_LOAD:
            return { ...state, isLoading: true, errorState: null, }
        case types.API_IMPORT_USER_FILE_SUCCESS:
            return { ...state, isLoading: false }

        //reported users
        case types.API_REPORTED_USERS_LIST_LOAD:
            return { ...state, isLoading: true }
        case types.API_REPORTED_USERS_LIST_SUCCESS:

            return { ...state, isLoading: false, reportedUsers: action.result }

        // reported users details
        case types.API_REPORTED_USERS_DETAILS_LOAD:
            return { ...state, isTableDataLoading: true, }
        case types.API_REPORTED_USERS_DETAILS_SUCCESS:
            console.log(action.result)
            return { ...state, isTableDataLoading: false, reportedUserDetails: action.result }

        //export reported users
        case types.API_EXPORT_REPORTED_USER_LOAD:
            return { ...state, isLoading: true, errorState: null, }
        case types.API_EXPORT_REPORTED_USER_SUCCESS:
            return { ...state, isLoading: false }

        //User management failed cases
        case types.API_USER_MANAGEMENT_FAILED:
            // console.log(action.result.result.is_block, "reducer***")
            return { ...state, isLoading: false, isTableDataLoading: false }
        case types.API_USER_MANAGEMENT_ERROR:
            console.log(action, "reducer***")
            return { ...state, isLoading: false, isTableDataLoading: false, }

        //Default case
        default:
            return { ...state }

    }
}