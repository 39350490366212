import { takeLatest, call, put, delay } from 'redux-saga/effects'
import { apiConstants as types, appMessages } from '../../themes/constants'
import axios from '../axios'
import cl from '../../utils/cl'
import history from '../../utils/history'
import { toast } from 'react-toastify';
function* userManagementFailedSaga(result) {
    console.error('result inside the User management Failed', result)
    yield put({
        type: types.API_USER_MANAGEMENT_FAILED,
        result: result
    })
    console.log(result, "saga failed case")
    toast.error(result?.error)
}

function* userManagementErrorSaga(result) {
    console.error('result inside the User Management Error', result)
    yield put({
        type: types.API_USER_MANAGEMENT_ERROR,
    })
    if (result.status === 2) {
        toast.error(appMessages.messageStatus500)
    } else {
        console.log(result, "saga failed case")
        toast.error(result?.error, { toastId: result?.status || "est" })
    }
}

function* getUserListSaga(action) {
    const { search, offset, limit, sortBy, order,startDate,endDate,deviceType } = action;
    try {
        const result = yield call(axios.getUserList, search, offset, limit, sortBy, order,startDate,endDate,deviceType)
        if (result.status === 1) {
            cl('result inside get user list saga', result)
            yield put({
                type: types.API_GET_USER_LIST_SUCCESS,
                result: result.result.data.data,
            })
        }
        else {
            yield call(userManagementFailedSaga, result)
        }
    }
    catch (error) {
        yield call(userManagementErrorSaga, error)
    }
}

function* getUserDetailsSaga(action) {
    const { userId } = action;
    try {
        const result = yield call(axios.getUserDetails, userId)
        if (result.status === 1) {
            cl('result inside get User details saga', result)
            yield put({
                type: types.API_GET_USER_DETAILS_SUCCESS,
                result: result.result.data.data,
            })
        }
        else {
            yield call(userManagementFailedSaga, result)
        }
    }
    catch (error) {
        yield call(userManagementErrorSaga, error)
    }
}

function* editUserDetailsSaga(action) {
    const { userId, firstName, lastName, userName, email, phoneNumber, countryCode, userType,imageUrl } = action;
    try {
        const result = yield call(axios.editUserDetails, userId, firstName, lastName, userName, email, phoneNumber, countryCode, userType,imageUrl)
        if (result.status === 1) {
            cl('result inside edit user details saga', result)
            yield put({
                type: types.API_EDIT_USER_DETAILS_SUCCESS,
                result: result.result.data.data,
            })
            toast.success(result?.result?.data?.message)
            history.push('/users')
        }
        else {
            console.log(result, "saga failed case")
            yield call(userManagementFailedSaga, result)
        }
    }
    catch (error) {
        yield call(userManagementErrorSaga, error)
    }
}

function* blockUserSaga(action) {
    const { userId, isBlocked } = action;
    try {
        const result = yield call(axios.blockUser, userId, isBlocked)
        console.log(result, "result***")
        if (result.status === 1) {
            cl('result inside block user saga', result)
            yield put({
                type: types.API_BLOCK_USER_SUCCESS,
                result: result.result.data.data,
            })
            toast.success(result?.result?.data?.message, { toastId: result?.result?.data?.message })
        }
        else {
            console.log('resultresult', result);
            yield call(userManagementFailedSaga, result)
        }
    }
    catch (error) {
        console.log(error, "result***")
        yield call(userManagementErrorSaga, error)
    }
}

function* exportUserSaga(action) {
    const { fileType } = action;
    try {
        const result = yield call(axios.exportUserFile,);
        if (result.status === 1) {
            cl("result inside export Terminal saga", result);
            yield put({
                type: types.API_EXPORT_USER_FILE_SUCCESS,
                result: result.result.data.data,
            });

            toast.success("User list exported successfully.");
        } else {
            yield call(userManagementFailedSaga, result);
        }
    } catch (error) {
        yield call(userManagementErrorSaga, error);
    }
}

function* importUserSaga(action) {
    const { file, fileType } = action;
    try {
        const result = yield call(axios.importUserFile, file, fileType);
        if (result.status === 1) {
            cl("result inside Import Terminal saga", result);
            yield put({
                type: types.API_IMPORT_USER_FILE_SUCCESS,
                result: result.result.data.data,
            });
            toast.success("User list imported successfully.");
            history.push("/users");
        } else {
            yield call(userManagementFailedSaga, result);
        }
    } catch (error) {
        yield call(userManagementErrorSaga, error);
    }
}

function* getReportedUsersListSaga(action) {
    console.log(action.payload)
    // console.log(action)
    const { search, offset, limit, sortBy, order, userId } = action;
    // console.log(action.payload)
    try {
        const result = yield call(axios.getReportedUserList, action.payload)
        if (result.status === 1) {
            cl('result inside get user list saga', result)
            yield put({
                type: types.API_REPORTED_USERS_LIST_SUCCESS,
                result: result.result.data.data,
            })
        }
        else {
            yield call(userManagementFailedSaga, result)
        }
    }
    catch (error) {
        yield call(userManagementErrorSaga, error)
    }
}

function* getReportedUserDetailsSaga(action) {

    const { userId } = action;
    // console.log(userId)
    try {
        const result = yield call(axios.getReportedUserDetails, userId)
        if (result.status === 1) {
            cl('result inside get User details saga', result)
            yield put({
                type: types.API_REPORTED_USERS_DETAILS_SUCCESS,
                result: result.result.data.data,
            })
        }
        else {
            yield call(userManagementFailedSaga, result)
        }
    }
    catch (error) {
        yield call(userManagementErrorSaga, error)
    }
}

function* exportReportedUserSaga(action) {
    const { fileType } = action;
    try {
        const result = yield call(axios.exportReportedUserFile, fileType);
        if (result.status === 1) {
            cl("result inside export Terminal saga", result);
            yield put({
                type: types.API_EXPORT_REPORTED_USER_SUCCESS,
                result: result.result.data.data,
            });

            toast.success("Reported user list exported successfully.");
        } else {
            yield call(userManagementFailedSaga, result);
        }
    } catch (error) {
        yield call(userManagementErrorSaga, error);
    }
}



export default function* rootUserManagementSaga() {
    yield takeLatest(types.API_GET_USER_LIST_LOAD, getUserListSaga)
    yield takeLatest(types.API_GET_USER_DETAILS_LOAD, getUserDetailsSaga)
    yield takeLatest(types.API_EDIT_USER_DETAILS_LOAD, editUserDetailsSaga)
    yield takeLatest(types.API_BLOCK_USER_LOAD, blockUserSaga)
    yield takeLatest(types.API_EXPORT_USER_FILE_LOAD, exportUserSaga)
    yield takeLatest(types.API_IMPORT_USER_FILE_LOAD, importUserSaga)
    yield takeLatest(types.API_REPORTED_USERS_LIST_LOAD, getReportedUsersListSaga)
    yield takeLatest(types.API_REPORTED_USERS_DETAILS_LOAD, getReportedUserDetailsSaga)
    yield takeLatest(types.API_EXPORT_REPORTED_USER_LOAD, exportReportedUserSaga)

}