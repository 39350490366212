import React, { useState, useEffect, useRef } from "react";
// Navigation
import { useHistory, useLocation } from "react-router-dom";
import { Modal } from "antd";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
// Styles
import "antd/dist/antd.css";
import "../dashboard.scss";
import localStorage from "../../utils/localStorage";
// antd
import { Card } from "react-bootstrap";
// Mui Components
import { Grid, useTheme, useMediaQuery, Box, Select } from "@material-ui/core";
// Custom components
import DashboardLayout from "../../layouts/dashboardLayout";
import { handleNavigationStateAction } from "../../store/actions";
import {
  AppBar,
  Button,
  PaginationBar,
  Navbar,
  NavbarSearch,
  ConfirmationModal,
  Table,
  InputField
} from "../../customComponents";
// Constants
import { AppImages } from "../../themes/appImages";
import { appConstants } from "../../themes/constants";
import { useStyles } from "../../styles";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  getNewsListAction,
  deleteNews,
} from "../../store/actions/newsManagementActions";
import { pushNotificationListInitiate, getNotificationListAction } from "../../store/actions/pushNotificationAction";
import { TimePicker, MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";

var userIdFilterArray = [];
var terminalIdFilterArray = [];

export const NotificationListing = () => {
  const styleProps = { searchable: true };
  const [search, setSearch] = useState("");
  // Hooks declarations
  const classes = useStyles(styleProps);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState("");
  const [order, setOrder] = useState(null);
  // const state = useSelector((state) => state.newsManagementReducer);
  const [open, setOpen] = useState(false);
  const [newsIdToDelete, setnewsIdToDelete] = useState(null);
  const [disableButton, setDisableButton] = useState()
  const [filterShow, setFilterShow] = useState(false);
  const [userIdFilterState, setUserIdFilterState] = useState("");
  const [terminalIdFilterState, setTerminalIdFilterState] = useState("");
  const [selectedOpenTimeStore, setSelectedOpenTimeStore] = React.useState(null);
  const [selectedOpenTime, setSelectedOpenTime] = React.useState(null);
  const [selectedCloseTime, setSelectedCloseTime] = React.useState(null);
  const [openTimePicker, setOpenTimePicker] = useState(false);
  let permissionsData = localStorage?.getPermissions()
  let employeeIndex = permissionsData?.findIndex((x) => x.name == "notification")
  const notificationData = useSelector(state => state.pushNotificationReducer)
  const { notificationList, notificationPaging, isLoading, deletedTerminal } = notificationData

  let siderIndex = localStorage.getLayoutArr()
  useEffect(() => {
    if (permissionsData) {
      let index = siderIndex?.findIndex((x) => x.key == "notification")
      console.log(index, "index", siderIndex)
      dispatch(handleNavigationStateAction(index, false))
    }
    else {
      dispatch(handleNavigationStateAction(9, false))
    }
    userIdFilterArray = [];
    terminalIdFilterArray = [];
    document.title = 'FR8 | Notification Management';
    fetchDataListing(search, offset, limit, sortBy, order);
  }, []);

  useEffect(() => {
    if (deletedTerminal != null) {
      fetchDataListing(search, 0, limit, sortBy, order);
    }
  }, [deletedTerminal]);

  const values = {
    userIdFilter: userIdFilterState,
    terminalIdFilter: terminalIdFilterState,
  };

  const validationSchema = Yup.object().shape({
    userIdFilter: Yup.string()
      .matches(/^[A-Za-z0-9, ]*$/, "Special characters not allowed except ','")
      .matches(/^(\S+$)/, "Space is not allowed"),
    terminalIdFilter: Yup.string()
      .matches(/^[A-Za-z0-9, ]*$/, "Special characters not allowed except ','")
      .matches(/^(\S+$)/, "Space is not allowed"),
  });

  const handleFormSubmit = (values, { setSubmitting }) => {
    setUserIdFilterState(values.userIdFilter);
    setTerminalIdFilterState(values.terminalIdFilter);
    setFilterShow(false);
    setSelectedOpenTimeStore(selectedOpenTime)
    const userIdArray = values.userIdFilter.split(",");
    userIdFilterArray = values.userIdFilter.length > 0 ? userIdArray : [];
    const terminalIdArray = values.terminalIdFilter.split(",");
    terminalIdFilterArray =
      values.terminalIdFilter.length > 0 ? terminalIdArray : [];
    // fetchDataListing(search, 0, limit, sortBy, order,);
  };

  const handleFormReset = (key) => {
    if (key == 'reset') {
      setSelectedOpenTime(null)
      setSelectedOpenTimeStore(null)
    }
    setUserIdFilterState("");
    setTerminalIdFilterState("");
    setFilterShow(false);
    userIdFilterArray = [];
    terminalIdFilterArray = [];
    fetchDataListing(search, 0, limit, sortBy, order, key);
    // setSubmitting(false)
    // dispatch(addTerminalAreaAction(values.terminalAreaName.trim()))
  };

  const handlePaginationChange = (e, page) => {
    const offsetTo = (page - 1) * limit;
    setOffset(offsetTo);

    fetchDataListing(search, offsetTo, limit, sortBy, order);
  };

  // Callback method to handle searching key
  const handleSearching = async (e) => {
    var tempText = e.target.value
    if (e.target.value.charAt(0) === " ") {
      tempText = e.target.value.replace(/^ +/gm, '');
    }
    setSearch(tempText)
    fetchDataListing(tempText, 0, limit, "", null)
  };

  const fetchDataListing = (search, offset, limit, sortBy, order, key) => {
    dispatch(getNotificationListAction({
      search, offset, limit, sortBy, order,
      date: selectedOpenTime ? key == "reset" ? null : moment(selectedOpenTime).format("YYYY-MM-DDT00:00:00") : null,
    }));
  };

  //Callback method to handle sorting
  const handleChange = async (pagination, filters, sorter) => {
    //To fetch listing in ascending order
    if (sorter.order == "ascend") {
      setOrder(1);
      setSortBy(sorter.columnKey);

      fetchDataListing(search, offset, limit, sorter.columnKey, 1);
    } //To fetch listing in descending order
    else if (sorter.order === "descend") {
      setOrder(-1);
      setSortBy(sorter.columnKey);

      fetchDataListing(search, offset, limit, sorter.columnKey, -1);
    } //To fetch listing in normal order
    else {
      setOrder(null);
      setSortBy("");

      fetchDataListing(search, offset, limit, "", null);
    }
  };

  //column
  const columns = [
    {
      title: appConstants.sr,
      dataIndex: "sr",
      key: "sr",
      render: (text, record, index) => (index + 1) + (notificationPaging?.page?.nextPage - limit),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (title) => title,
      ellipsis: false,
      sorter: true,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "media",
      width: 500,
      sorter: true,
      render: (description, record, index) => {
        return (
          <div className="numberLinesText">
            {description}
          </div>
        )
      }

    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: true,

    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      sorter: true,
      render: (created_at, record) =>
        created_at ? moment(created_at).format("YYYY-MM-DD") : "",
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   sorter: true,

    // },
    {
      key: "x",
      title: appConstants.action,
      dataIndex: "",
      align: "center",
      render: (record) => {
        console.log(record.status, "record.status")
        return (
          <Grid className={classes.buttons}>
            <Button
              // disabled={false}
              title={appConstants.view}
              disabled={(permissionsData) && permissionsData[employeeIndex]?.view == false}
              // marginRight="0px"
              onClick={() => history.push({
                pathname: "/notification-view",
                state: {  // location state
                  notificationId: record._id,
                  type: "view"
                },
              })}
            />
            <Button
              // disabled={false}
              title={appConstants.edit}
              disabled={record.status == "Done" || (permissionsData) && permissionsData[employeeIndex]?.edit == false}
              // marginRight="0px"
              onClick={() => history.push({
                pathname: "/notification-view",
                state: {  // location state
                  notificationId: record._id,
                  type: "edit"
                },
              })}
            />
          </Grid>

        );
      },
    },
  ];

  const closeModal = () => {
    setFilterShow(false)

  }

  return (
    <DashboardLayout>
      <ConfirmationModal
        title="Alert"
        text={appConstants.confirmDeleteNews}
        visible={open}
        disable={disableButton}
        onCancel={() => {
          setnewsIdToDelete(null);
          setOpen(false);
        }}
        onDeleteConfirmed={() => {
          dispatch(deleteNews(newsIdToDelete));
          setOpen(false);
          setDisableButton(true)
        }}

      />
      <AppBar
        breadcrumbs={[
          { route: "/push-notifications", name: appConstants.notificationManagement },
        ]}
      />
      <Modal
        onCancel={() => closeModal()}
        visible={filterShow}
        footer={null}
        centered
        maskClosable={false}
      >
        <Formik
          enableReinitialize
          initialValues={values}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}

        >
          {({
            values,
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <div style={{ zIndex: 999999 }}>
                <span style={{ color: "#000", fontSize: 20 }}>Filters</span>
                <div
                  // className={classes.inputWrapper}
                  style={{ alignSelf: "center", marginTop: "15px" }}
                >
                  <span style={{ color: "#000", fontSize: 14 }}>Date</span>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      style={{ width: "100%" }}
                      ampm={true}
                      className='date'
                      placeholder="Select Date"
                      name="startDate"
                      value={selectedOpenTime}
                      minDate={moment('01-01-1980').add(1)}
                      onAccept={(time) => {
                        setOpenTimePicker(false);
                      }}
                      disableFuture
                      format="yyyy-MM-dd"
                      onClose={() => setOpenTimePicker(false)}
                      // minDate={moment(new Date).add(1)}
                      onChange={(time) =>
                        setSelectedOpenTime(time)
                      }
                      open={openTimePicker}
                      onOpen={() => setOpenTimePicker(true)}
                      InputProps={{
                        endAdornment: (
                          <FontAwesomeIcon
                            icon={faCalendar}
                            className={classes.dropdownIcon}
                          />
                        )
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>

                <div style={{ marginTop: "20px" }}>
                </div>

                <div
                  style={{
                    alignItems: "center",
                    justifyContent: "space-around",
                    display: "flex",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    title="Remove"
                    type="reset"
                    className="mr-3"
                    width="108px"
                    onClick={() => {
                      setFieldValue("userIdFilter", "");
                      setFieldValue("terminalIdFilter", "");
                      handleFormReset('reset');
                    }}
                  />
                  <Button
                    marginRight={"0px"}
                    title="Apply"
                    type="submit"
                    // className="mr-0"
                    width="108px"
                    onClick={() => {
                      setFieldValue("userIdFilter", "");
                      setFieldValue("terminalIdFilter", "");
                      handleFormReset('apply');
                    }}
                  />
                </div>
              </div>
            </form>
          )}
        </Formik>
      </Modal>
      <Grid container className={classes.buttonBarWrapper}>
        <Button
          title="Push Notification"
          disabled={(permissionsData) && permissionsData[employeeIndex]?.add == false}
          onClick={() => history.push("/push-notifications")}
          mr
          mb
        />
        <Button
          title="Filter"
          onClick={() => {
            setFilterShow(true);
            setSelectedOpenTime(selectedOpenTimeStore)
          }}
          width="108px"
          // style={{}}
          mr
          mb
        />
      </Grid>
      <Navbar
        title={appConstants.notificationManagement}
        searchable
        value={search}
        // value={}
        onChange={(e) => handleSearching(e)}
      />

      <Card className={classes.card}>
        {matches && (
          <Box className={classes.searchBox}>
            <NavbarSearch value={search} onChange={(e) => handleSearching(e)} />
          </Box>
        )}
        <Grid container>
          <Grid className={classes.tableContainerRow}>
            <Table
              // loading={isTableDataLoading}
              rowKey={(record) => record.key}
              columns={columns}
              dataSource={notificationList}
              onChange={handleChange}
              searching={search.length > 0}
            />
          </Grid>
        </Grid>
        <PaginationBar
          totalCount={notificationPaging?.page?.totalCount}
          count={Math.ceil(notificationPaging?.page?.totalCount / limit)}
          currentPage={notificationPaging?.page?.currentPage}
          nextPage={notificationPaging?.page?.nextPage}
          onChange={(e, page) => handlePaginationChange(e, page)}
          prevPage={notificationPaging?.page?.nextPage - limit}
        />
      </Card>
    </DashboardLayout>
  );
};
