import { apiConstants as types } from '../../themes/constants'
const initialState = {
    isLoading: false,
    isTableDataLoading: false,
    result: {},
    data: [],
    notificationList: [],
    notificationListLoader: false,
    notificationListSuccess: false,
    notificationDetail: {},
    notificationDetailLoader: false,
    notificationDetailSuccess: false,
    notificationPaging: {},
    deletedTerminal: null,
}

export const pushNotificationReducer = (state = initialState, action) => {
    // console.log(action, "action")
    switch (action.type) {

        case types.API_PUSH_NOTIFICATIONS_LOAD:
            return {
                ...state, isLoading: false, errorState: null,
            }
        case types.API_PUSH_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.result
            }

        case types.API_PUSH_NOTIFICATIONS_LIST_LOAD:
            return {
                ...state,
                notificationListLoader: true,
                notificationListSuccess: false
            }

        case types.API_PUSH_NOTIFICATIONS_LIST_SUCCESS:

            return {
                ...state,
                notificationListSuccess: true,
                notificationListLoader: false,
                notificationList: action.payload
            }

        // Get notification list
        case types.API_PUSH_NOTIFICATIONS_NEW_LIST_LOAD:
            return { ...state, isLoading: true, errorState: null, }
        case types.API_PUSH_NOTIFICATIONS_NEW_LIST_SUCCESS:
            return { ...state, isLoading: false, notificationList: action.result.list, notificationPaging: action.result?.paging, currentPage: action.result?.paging?.page?.currentPage }

        //notification details

        case types.API_PUSH_NOTIFICATIONS_NEW_DETAILS_LOAD:
            return { ...state, isLoading: true, errorState: null, }
        case types.API_PUSH_NOTIFICATIONS_NEW_DETAILS_SUCCESS:
            return { ...state, isLoading: false,  notificationDetail: action.result }

         //notification edit

         case types.API_PUSH_NOTIFICATIONS_EDIT_LOAD:
            return { ...state,  errorState: null, }
        case types.API_PUSH_NOTIFICATIONS_EDIT_SUCCESS:
            return { ...state,  isTableDataLoading: false, }
        
            case types.API_PUSH_NOTIFICATIONS_DETAIL_LOAD:
            return {
                ...state,
                notificationDetailLoader: true,
                notificationDetailSuccess: false
            }

        case types.API_PUSH_NOTIFICATIONS_DETAIL_SUCCESS:
            return {
                ...state,
                notificationDetailSuccess: true,
                notificationDetail: action.payload,
                notificationDetailLoader: false
            }

        case types.API_PUSH_NOTIFICATIONS_FAILED:
            return {
                ...state,
                isLoading: false,
                notificationListLoader: false,
                notificationListSuccess: false,
                notificationDetailLoader: false,
                notificationDetailSuccess: false
            }
        case types.API_PUSH_NOTIFICATIONS_ERROR:
            return {
                ...state,
                isLoading: false,
                notificationListLoader: false,
                notificationListSuccess: false,
                notificationDetailLoader: false,
                notificationDetailSuccess: false
            }

        default:
            return { ...state }

    }
}