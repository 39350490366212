import React, { useState, useEffect } from 'react';
// Styles
import 'antd/dist/antd.css'
import '../dashboard.scss'
// antd
import { Card, } from 'react-bootstrap';
import moment from 'moment'
// Mui Components
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
// Custom components
import DashboardLayout from '../../layouts/dashboardLayout'
import { AppBar, PaginationBar, Navbar, NavbarSearch, Table } from '../../customComponents'
// Constants
import { appConstants } from '../../themes/constants';
import localStorage from "../../utils/localStorage";
// utils methods
import { useStyles } from '../../styles'
// Redux
import { useSelector, useDispatch } from 'react-redux'
import { getEventListAction } from '../../store/actions/employeeManagementAction';
// Global constants


export const EventManagement = () => {
    const styleProps = { searchable: true }
    // Hooks declarations
    const classes = useStyles(styleProps);
    const dispatch = useDispatch()
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    // local state initialization
    const [search, setSearch] = useState("")
    const [offset, setOffset] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sortBy, setSortBy] = useState("")
    const [order, setOrder] = useState(null)
    const employeeManagementState = useSelector(state => state.EmployeeManagementReducer)

    const { eventListing, eventPaging, isLoading } = employeeManagementState

    // Method to fetch listing
    const fetchDataListing = (search, offset, limit, sortBy, order) => {
        dispatch(getEventListAction({
            search, offset, limit, sortBy, order,
        }))
    }
    //lifecycle hooks
    useEffect(() => {
        document.title = appConstants.headerTitle.eventManagement;
        fetchDataListing(search, offset, limit, sortBy, order)
    }, [])

    //Callback method to handle sorting 
    const handleChange = async (pagination, filters, sorter) => {
        //To fetch listing in ascending order
        if (sorter.order == "ascend") {
            setOrder(1)
            setSortBy(sorter.columnKey)
            fetchDataListing(search, offset, limit, sorter.columnKey, 1)
        } //To fetch listing in descending order
        else if (sorter.order === "descend") {
            setOrder(-1)
            setSortBy(sorter.columnKey)
            fetchDataListing(search, offset, limit, sorter.columnKey, -1)
        } //To fetch listing in normal order
        else {
            setOrder(null)
            setSortBy("")
            fetchDataListing(search, offset, limit, "", null)
        }
    }

    // Callback method to handle page chage
    const handlePaginationChange = (e, page) => {
        const offsetTo = (page - 1) * limit
        setOffset(offsetTo)
        fetchDataListing(search, offsetTo, limit, sortBy, order)
    }

    // Callback method to handle searching key
    const handleSearching = async (e) => {
        var tempText = e.target.value
        if (e.target.value.charAt(0) === " ") {
            tempText = e.target.value.replace(/^ +/gm, '');
        }
        setSearch(tempText)
        fetchDataListing(tempText, 0, limit, "", null)
    }
    // +++++++++++

    const columns = [
        {
            title: appConstants.sr,
            dataIndex: '',
            key: 'sr',
            render: (text, record, index) => (index + 1) + (eventPaging?.page?.nextPage - limit),
            ellipsis: false,
            width: 100,
        },
        {
            title: 'Updated Event',
            dataIndex: 'message',
            key: 'message',
            ellipsis: false,
            sorter: true,
            render: (message, record) => <div className='employee-list'>{message}</div>
        },
        {
            title: 'Updated By',
            dataIndex: 'actionBy',
            key: 'actionBy',
            render: (firstName, record) => <div>{record?.actionBy?.name}</div>,
            ellipsis: false,
            sorter: true,
        },
        {
            title: 'Updated On',
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: (firstName, record) => <div>{moment(record?.updated_at).format('YYYY-MM-DD hh:mm A')}</div>,
            ellipsis: false,
            sorter: false,
        },
    ];

    return (
        <DashboardLayout>
            <AppBar breadcrumbs={[{ route: '/employee', name: "Employee Management" }, { route: '/event-management', name: "Event Management" }]} />
            <Navbar title="Event Management" searchable value={search} onChange={(e) => handleSearching(e)} />
            <Card className={classes.card}>
                {matches &&
                    <Box className={classes.searchBox}>
                        <NavbarSearch value={search} onValueChange={(e) => handleSearching(e)} />
                    </Box>
                }
                <Grid container>
                    <Grid className={classes.tableContainerRow}>
                        <Table
                            loading={isLoading}
                            columns={columns}
                            dataSource={eventListing}
                            onChange={handleChange}
                            searching={search.length > 0}
                        />
                    </Grid>
                </Grid>
                <PaginationBar
                    totalCount={eventPaging?.page?.totalCount}
                    count={Math.ceil(eventPaging?.page?.totalCount / limit)}
                    currentPage={eventPaging?.page?.currentPage}
                    nextPage={eventPaging?.page?.nextPage}
                    onChange={(e, page) => handlePaginationChange(e, page)}
                    prevPage={eventPaging?.page?.nextPage - limit}
                />
            </Card>
        </DashboardLayout>
    );
}
