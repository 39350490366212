import { combineReducers } from "redux";
import { commonReducer } from "./commonReducer";
import { authenticationReducer } from "./authenticationReducer";
import { userManagementReducer } from "./userManagementReducer";
import { terminalAreaManagementReducer } from "./terminalAreaManagementReducer";
import { terminalManagementReducer } from "./terminalManagementReducer";
import { reportPostReducer } from "./reportPostReducer";
import { terminalReportPostReducer } from "./terminalReportPostReducer";
import { imageManagementReducer } from "./imageManagementReducer";
import { radarManagementReducer } from "./radarManagementReducer";
import { newsManagementReducer } from "./NewManagementReducer";
import { stateListingReducer } from "./stateListReducers";
import { pushNotificationReducer } from "./pushNotificationReducer";
import { dashboardReducer } from "./dashboardReducer";
import { EmployeeManagementReducer } from "./employeeManagementReducer";
import { PostManagementReducer } from "./postManagementReducer";
import { terminalBoardReducer } from "./terminalBoardReducer";

const rootReducer = combineReducers({
  commonReducer,
  authenticationReducer,
  userManagementReducer,
  terminalAreaManagementReducer,
  terminalManagementReducer,
  reportPostReducer,
  imageManagementReducer,
  terminalReportPostReducer,
  radarManagementReducer,
  newsManagementReducer,
  stateListingReducer,
  pushNotificationReducer,
  dashboardReducer,
  EmployeeManagementReducer,
  PostManagementReducer,
  terminalBoardReducer
});

export default rootReducer;
