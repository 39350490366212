import { apiConstants as types } from '../../themes/constants'

// Get terminal news Action
export const getNewsListAction = (search, offset, limit, sortBy, order,terminalId) => ({
    type: types.API_GET_NEWS_LIST_LOAD,
    search,
    offset,
    limit,
    sortBy,
    order,
    terminalId

})
export const loaderStart = (load) => ({
    type: "CkIMAGELOAD",
    load,
})

export const imageCount1 = (count) => ({
    type: "IMAGE_COUNT",
    count,
})
// Add terminal news Action
export const addNewsAction = (regionId, image, description, heading) => ({
    type: types.API_ADD_NEWS_LOAD,
    regionId,
    image,
    description,
    heading
})
export const editNewsAction = (regionId, image, description, heading, id) => ({
    type: types.API_EDIT_NEWS_LOAD,
    regionId,
    image,
    description,
    heading,
    id
})

//  Delete terminal area Action
export const deleteNews = (id) => (
    {
        type: types.API_DELETE_NEWS_LOAD,
        id,
    })

    //  Delete terminal area Action
export const deleteImage = (key) => (
    {
        type: types.API_DELETE_IMAGE_NEWS_LOAD,
        key,
    })