import axios from "axios";

const http = axios.create({
  // baseURL: "http://192.168.3.176:4000/api/v6.4", //local
  // baseURL: "http://1.6.98.141:2033/api/v8", //public
  // baseURL: "http://192.168.3.173:4000/api/v7",
  // baseURL: "http://192.168.3.175:4000/api/v6.4",
  // baseURL: "http://192.168.3.174:6002/api/v8",
  // baseURL: "http://BAckend-ASG-771965392.us-east-2.elb.amazonaws.com/api/v6.2"
  // baseURL: "https://dev-api.fr8.ai/api/v8"
  // baseURL : "http://18.217.24.120:4000/api/v5.4", //stagging
  baseURL: "https://api.fr8.ai/api/v8", //superlive with domain
  // baseURL : "http://1.6.98.142:4000/api/v2", //superlive with domain
});

http.interceptors.request.use(async (config) => {
  const token = localStorage.token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`; 
    config.headers["Content-Type"] = "application/json";
  }

  return config;
});

export default http;
