import React, { Component, } from "react";
import { EditorState, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

class RichEditorExample extends Component {

  constructor(props) {
    super(props);
    this.state = {
      file: '',
      visible: false,
      isVideo: false,
      uploadedImages: [],
      isImageAdded: false,
      editorState: EditorState.createEmpty(),
    };
    this.uploadImageCallBack = this.uploadImageCallBack.bind(this);
  }

  onEditorStateChange = (value) => {
    this.setState({ editorState: value });
  };

  getFileBase64 = (file, callback) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    // Since FileReader is asynchronous,
    // we need to pass data back.
    reader.onload = () => callback(reader.result);
    // TODO: catch an error
    reader.onerror = error => { };
  };

  uploadImageCallBack(file) {
    let uploadedImages = this.state.uploadedImages;

    const imageObject = {
      file: file,
      localSrc: URL.createObjectURL(file),
    }
    uploadedImages.push(imageObject);
    this.setState({ uploadedImages: uploadedImages, file: file })
    return new Promise(
      (resolve, reject) => {
        resolve({ data: { link: imageObject.localSrc } });
      }
    );
  }

  /// /////form content view
  contentView = () => {
    const { editorState } = this.state;
    return (
      <div className="content-view pt-4">
        <div style={{ marginBottom: ".8rem" }}>
          <Editor
            toolbar={{
              options: ["inline", "fontSize", "emoji", "textAlign", "history"],
              inline: {
                inDropdown: false,
                options: ["bold", "italic", "underline", "strikethrough"],
              },
              image: {
                urlEnabled: true,
                uploadEnabled: true,
                uploadCallback: this.props.uploadImageCallBack,
                alignmentEnabled: true,
                defaultSize: {
                  height: "200px",
                  width: "200px",
                },
                inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg'
              },
              textAlign: { inDropdown: false },
              fontSize: {
                // icon: fontSize,
                options: this.props.font,
                className: undefined,
                component: undefined,
                dropdownClassName: undefined,
              },
              link: { inDropdown: true },
              history: { inDropdown: false },
              emoji: { inDropdown: true },
            }
            }
            handlePastedText={() => false}
            toolbarHidden={this.props.toolbarHidden}
            readOnly={this.props.readOnly}
            onBlur={this.props.onBlur}
            ref={this.props.ref}
            editorStyle={{
              maxHeight: "400px",
              background: "#fff",
              padding: "5px 13px",
              minHeight: this.props.minHeight
              // maxLines: 5,
            }}
            placeholder={this.props.placeholder}
            editorState={this.props.editorState}
            toolbarClassName={
              this.props.background
                ? "rdw-editor-toolbar1"
                : "toolbarClassName"
            }
            wrapperClassName={!this.props.background ? "toolBar1" : "toolBar"}
            editorClassName={
              this.props.background ? "rdw-editor-wrapper1" : "editorClassName"
            }
            onEditorStateChange={this.props.onEditorStateChange}
          />
        </div>
      </div>
    );
  };

  /// /main render function
  render() {
    return <div className="formView">{this.contentView()}</div>;
  }
}

export default RichEditorExample;
