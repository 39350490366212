import { apiConstants as types } from '../../themes/constants'

export const pushNotificationAction = (title, description,radioButton, users, terminal_id, region_id, selectedUser, notificationType, selectedOpenTime) => {
    console.log(title, description, users, terminal_id, region_id, selectedUser, notificationType, selectedOpenTime, "action")
    return {
        type: types.API_PUSH_NOTIFICATIONS_LOAD,
        title,
        description,
        radioButton,
        users,
        terminal_id,
        region_id,
        selectedUser,
        notificationType,
        selectedOpenTime
    }
}

export const pushNotificationListInitiate = (data) => {
    console.log("data = = = ", data)
    return {
        type: types.API_PUSH_NOTIFICATIONS_LIST_LOAD,
        payload: data
    }
}

// export const pushNotificationListSuccess = (data) => {
//     return {
//         type: types.API_PUSH_NOTIFICATIONS_LIST_SUCCESS,
//         payload: data
//     }
// }

export const pushNotificationDetailInitiate = (data) => {
    return {
        type: types.API_PUSH_NOTIFICATIONS_DETAIL_LOAD,
        payload: data
    }
}

export const pushNotificationDetailSuccess = (data) => {
    return {
        type: types.API_PUSH_NOTIFICATIONS_DETAIL_SUCCESS,
        payload: data
    }
}

//notification listing action
export const getNotificationListAction = (payload) => {
    return {
        type: types.API_PUSH_NOTIFICATIONS_NEW_LIST_LOAD,
        payload
    }
}

//notification details action
export const notificationDetailsAction = (id) => {
    return {
        type: types.API_PUSH_NOTIFICATIONS_NEW_DETAILS_LOAD,
        id
    }
}

//notification edit action
export const notificationEditAction = (notificationId,title, description,radioButton, users, terminal_id, region_id, selectedUser, notificationType, selectedOpenTime,) => {
    return {
        type: types.API_PUSH_NOTIFICATIONS_EDIT_LOAD,
        notificationId,
        title,
        description,
        radioButton,
        users,
        terminal_id,
        region_id,
        selectedUser,
        notificationType,
        selectedOpenTime
    }
}

