import React, { useState, useEffect, useRef } from "react";
// Navigation
import { useHistory, useLocation } from "react-router-dom";
// Styles
import "antd/dist/antd.css";
import "../dashboard.scss";
// antd
import { Card } from "react-bootstrap";
import localStorage from "../../utils/localStorage";
// Mui Components
import { Grid, useTheme, useMediaQuery, Box } from "@material-ui/core";
// Custom components
import DashboardLayout, { setArray } from "../../layouts/dashboardLayout";
import { handleNavigationStateAction } from "../../store/actions";
import {
  AppBar,
  Button,
  PaginationBar,
  Navbar,
  NavbarSearch,
  ConfirmationModal,
  Table,
} from "../../customComponents";
// Constants
import { AppImages } from "../../themes/appImages";
import { appConstants } from "../../themes/constants";
import { useStyles } from "../../styles";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  getNewsListAction,
  deleteNews,
} from "../../store/actions/newsManagementActions";

export const TerminalNewsListing = () => {
  const styleProps = { searchable: true };
  const [search, setSearch] = useState("");
  // Hooks declarations
  const classes = useStyles(styleProps);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState("");
  const [order, setOrder] = useState(null);
  const state = useSelector((state) => state.newsManagementReducer);
  const [open, setOpen] = useState(false);
  const [newsIdToDelete, setnewsIdToDelete] = useState(null);
  const [disableButton, setDisableButton] = useState()
  let permissionsData = localStorage?.getPermissions()
  let siderIndex = localStorage.getLayoutArr()
  // let index = siderIndex?.findIndex((x) => x.key == "news")
  let employeeIndex = permissionsData?.findIndex((x) => x.name == "news")
  const { isTableDataLoading, allNews, newsPaging } = state;

  useEffect(() => {
    if (permissionsData) {
      // let arr = setArray()
      let index = siderIndex?.findIndex((x) => x.key == "news")
      console.log(index, "index", siderIndex)
      dispatch(handleNavigationStateAction(index, false));
    }
    else {
      dispatch(handleNavigationStateAction(7, false));
    }

    document.title = appConstants.headerTitle.terminalNewManagement;
    fetchDataListing("", offset, limit, "", null);
  }, []);


  useEffect(() => {
    if (state.deleteNews !== null) {
      fetchDataListing(search, 0, limit, sortBy, order)
    }
  }, [state.deleteNews]);

  useEffect(()=>{
    window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
  
},[newsPaging])

  const fetchDataListing = (search, offset, limit, sortBy, order) => {
    dispatch(getNewsListAction(search, offset, limit, sortBy, order, ""));
  };

  const handlePaginationChange = (e, page) => {
    const offsetTo = (page - 1) * limit;
    setOffset(offsetTo);

    fetchDataListing(search, offsetTo, limit, sortBy, order);
  };

  // Callback method to handle searching key
  const handleSearching = async (e) => {
    var tempText = e.target.value
    if (e.target.value.charAt(0) === " ") {
      tempText = e.target.value.replace(/^ +/gm, '');
    }
    setSearch(tempText)
    fetchDataListing(tempText, 0, limit, "", null)
  };

  //Callback method to handle sorting
  const handleChange = async (pagination, filters, sorter) => {
    //To fetch listing in ascending order
    if (sorter.order == "ascend") {
      setOrder(1);
      setSortBy(sorter.columnKey);
      fetchDataListing(search, offset, limit, sorter.columnKey, 1);
    } //To fetch listing in descending order
    else if (sorter.order === "descend") {
      setOrder(-1);
      setSortBy(sorter.columnKey);

      fetchDataListing(search, offset, limit, sorter.columnKey, -1);
    } //To fetch listing in normal order
    else {
      setOrder(null);
      setSortBy("");

      fetchDataListing(search, offset, limit, "", null);
    }
  };

  //column
  const columns = [
    {
      title: appConstants.sr,
      dataIndex: "",
      key: "sr",
      render: (text, record, index) =>
        index + 1 + (newsPaging?.page?.nextPage - limit),
      ellipsis: false,
      width: 100,
    },
    {
      title: "Region Name",
      dataIndex: "region",
      key: "region",
      render: (region) => region,

      ellipsis: false,
      sorter: true,
    },
    
    {
      title: "Title",
      dataIndex: "description",
      key: "description",
      sorter: true,
      // align: "center",
      // render: (title) => {
      //   return (
      //     <div className="ck-content">
      //       <div
      //         className="news_listing"
      //         style={{
      //           // width:"160px",
      //           display: "-webkit-box",
      //           // width:"400px",
      //           overflow: "hidden",
      //           // height:"85px"
      //         }}
      //         dangerouslySetInnerHTML={{ __html: title }}
      //       // className={classes.listingLogo}
      //       >
      //       </div>
      //     </div >
      //   );
      // },
      ellipsis: false,
      width: 300,
    },

    {
      key: "x",
      title: appConstants.action,
      dataIndex: "",
      align: "center",
      render: (record) => {
        return (
          <Grid className={classes.buttons} style={{display:"flex",justifyContent:"center"}}>
            <Button
              // disabled={false}
              title={appConstants.view}
              disabled={(permissionsData) && permissionsData[employeeIndex]?.view == false}
              onClick={() =>
                history.push("/news-view", {
                  state: {
                    // location state
                    news: record,
                  },
                })
              }
            />
            <Button
              // disabled={false}
              title={appConstants.edit}
              disabled={(permissionsData) && permissionsData[employeeIndex]?.edit == false}
              onClick={() => {
                history.push("edit-news", {
                  state: {
                    // location state
                    news: record,
                  },
                });
              }}
            />
            <Button
              // disabled={false}
              disabled={(permissionsData) && permissionsData[employeeIndex]?.delete == false}
              title={appConstants.delete}
              onClick={() => {
                setnewsIdToDelete(record._id);
                setOpen(true);
                setDisableButton(false)
                // dispatch(deleteNews(record._id))
              }}
            />
          </Grid>
        );
      },
    },
  ];

  return (
    <DashboardLayout>
      <ConfirmationModal
        title="Alert"
        text={appConstants.confirmDeleteNews}
        visible={open}
        disable={disableButton}
        onCancel={() => {
          setnewsIdToDelete(null);
          setOpen(false);
        }}
        onDeleteConfirmed={() => {
          dispatch(deleteNews(newsIdToDelete));
          setOpen(false);
          setDisableButton(true)
        }}
      />

      {/* breadcrumbs */}
      <AppBar
        breadcrumbs={[
          { route: "/news-terminal", name: appConstants.newsManagement },
        ]}
      />
      <Grid container className={classes.buttonBarWrapper}>
        <Button
          title="Add News"
          disabled={(permissionsData) && permissionsData[employeeIndex]?.add == false}
          onClick={() => history.push("/news-add")}
          mr
          mb
        />
      </Grid>
      <Navbar
        title={appConstants.newsManagement}
        searchable
        value={search}
        onChange={(e) => handleSearching(e)}
      />

      <Card className={classes.card}>
        {matches && (
          <Box className={classes.searchBox}>
            <NavbarSearch value={search} onChange={(e) => handleSearching(e)} />
          </Box>
        )}
        <Grid container>
          <Grid className={classes.tableContainerRow}>
            <Table
              loading={isTableDataLoading}
              rowKey={(record) => record.key}
              columns={columns}
              dataSource={allNews}
              onChange={handleChange}
              searching={search.length > 0}
            />
          </Grid>
        </Grid>
        <PaginationBar
          totalCount={newsPaging?.page?.totalCount}
          count={Math.ceil(newsPaging?.page?.totalCount / limit)}
          currentPage={newsPaging?.page?.currentPage}
          nextPage={newsPaging?.page?.nextPage}
          onChange={(e, page) => handlePaginationChange(e, page)}
          prevPage={newsPaging?.page?.nextPage - limit}
        />
      </Card>
    </DashboardLayout>
  );
};
