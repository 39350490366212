import React, { useState, useEffect, } from 'react';
// Navigation
import { useHistory, useLocation } from 'react-router-dom'
// Styles
import 'antd/dist/antd.css'
import '../dashboard.scss'
// Third party Components
import * as Yup from 'yup';
import { Formik } from 'formik';
// antd
import { Spin, Tooltip } from 'antd';
import { Card, Table as BSTable, } from 'react-bootstrap';
// Mui Components
import { Grid, Select, useTheme, FormHelperText } from '@material-ui/core';
// Custom components
import DashboardLayout from '../../layouts/dashboardLayout'
import { InputField, Navbar, AppBar, Button } from '../../customComponents';
// fontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
// Constants
import { ValidationConstants, appConstants, } from '../../themes/constants';
import { AppImages } from '../../themes/appImages';
import localStorage from "../../utils/localStorage";
// utils methods
import { useStyles } from '../../styles'
import { toast } from "react-toastify";
import {
    validateImageRatioOneByOne,
} from "../../utils/helpers";
// Redux
import { useSelector, useDispatch } from 'react-redux'
import { getUserDetailsAction, editUserDetailsAction, handleClearStateAction } from '../../store/actions'
// Global constants

export const EditUserDetails = () => {
    // Hooks declarations
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const location = useLocation()
    const dispatch = useDispatch()
    const [image, setImage] = useState("")
    const [imageUrl, setImageUrl] = useState("");
    const [errors1, setErrors1] = useState(null);
    const userManagementState = useSelector(state => state.userManagementReducer)
    let permissionsData = localStorage?.getPermissions()
    // Object destructuring
    const { isLoading, isTableDataLoading, userDetails } = userManagementState
    const { name,firstName, lastName, userName, email, phone_number, user_type,profile } = userDetails;
    // local state initialization   

    // Lifecycle Hooks
    useEffect(() => {
        document.title = appConstants.headerTitle.editUserDetails;
        let onEffect = async () => {
            await dispatch(handleClearStateAction("clearUserDetailsState"))
            if (location?.state?.userId) {
                await dispatch(getUserDetailsAction(location?.state?.userId))
            } else {
                history.push('/users')
            }
        }
        onEffect();
    }, [])

    useEffect(()=>{
        let url = (appConstants.baseURL + userDetails?.profile)
        setImage(url)
        setImageUrl(userDetails?.profile)
    },[userDetails])

    //Constants
    const values = {
        firstName: name?name:'',
        lastName: lastName?lastName:'',
        userName: userName?userName:'',
        email: email?email:'',
        phoneNumber: phone_number?phone_number:'',
        userType: user_type?user_type:'',
    }

    const validationSchema =
        userDetails?.type == "user" ?
            Yup.object().shape({
                firstName: Yup.string()
                // .min(3, ValidationConstants.firstname.short)
                    .max(20, ValidationConstants.name.long),
                    // .required(ValidationConstants.firstname.empty)
                    // .test('trim', ValidationConstants.firstname.empty, value => value?.trim()?.length > 0)
                    // .test('trim', ValidationConstants.firstname.short, value => value?.trim()?.length > 2).nullable(),
                // lastName: Yup.string()
                //     // .min(3, ValidationConstants.lastname.short)
                //     .max(20, ValidationConstants.lastname.long),
                    // .required(ValidationConstants.lastname.empty)
                    // .test('trim', ValidationConstants.lastname.empty, value => value?.trim()?.length > 0),
                    // .test('trim', ValidationConstants.lastname.short, value => value?.trim()?.length > 2).nullable(),
                // userName: Yup.string().min(3, ValidationConstants.userName.short).max(20, ValidationConstants.userName.long).required(ValidationConstants.userName.empty).matches(/^[a-zA-Z0-9._]+$/, ValidationConstants.userName.validUserName),
                userName: Yup.string().matches(/^(\S+$)/, ValidationConstants.userName.space)
                    .matches(/^(?![.])/, ValidationConstants.userName.startWithDot)
                    .min(3, ValidationConstants.userName.short)
                    .matches(/(?!^\d+$)^.+$/, ValidationConstants.userName.notOnlyNumber)
                    .matches(/(?<![.])$/, ValidationConstants.userName.endWithDot)
                    .matches(/^(?!.*[.]{2})/, ValidationConstants.userName.twoDots)
                    .max(20, ValidationConstants.userName.long)
                    .required(ValidationConstants.userName.empty)
                    .matches(/^(?![.])(?!.*[.]{2})[a-zA-Z0-9._]+(?![.])$/, ValidationConstants.userName.validUserName),
                email: Yup.string()
                    .matches(/^[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(\.[a-zA-z]{2,10})+$/, "Please enter valid email address.")
                    .email(ValidationConstants.email.invalid).max(255, ValidationConstants.email.long),
                phoneNumber: Yup.string().matches(/^[0-9]+$/, ValidationConstants.phoneNumber.invalid).max(15, ValidationConstants.phoneNumber.long).min(8, ValidationConstants.phoneNumber.short),
                userType: Yup.string()
            })
            :
            Yup.object().shape({
                firstName: Yup.string()
                // .min(3, ValidationConstants.firstname.short)
                    .max(20, ValidationConstants.name.long),
                // firstName: Yup.string().min(3, ValidationConstants.firstname.short).max(20, ValidationConstants.firstname.long).required(ValidationConstants.firstname.empty).test('trim', ValidationConstants.firstname.empty, value => value?.trim()?.length > 0),
                // lastName: Yup.string().min(3, ValidationConstants.lastname.short).max(20, ValidationConstants.lastname.long).required(ValidationConstants.lastname.empty).test('trim', ValidationConstants.lastname.empty, value => value?.trim()?.length > 0),
                // userName: Yup.string().min(3, ValidationConstants.userName.short).max(20, ValidationConstants.userName.long).required(ValidationConstants.userName.empty).matches(/^[a-zA-Z0-9._]+$/, ValidationConstants.userName.validUserName),
                userName: Yup.string().matches(/^(\S+$)/, ValidationConstants.userName.space)
                    .matches(/^(?![.])/, ValidationConstants.userName.startWithDot)
                    .min(3, ValidationConstants.userName.short)
                    .matches(/(?!^\d+$)^.+$/, ValidationConstants.userName.notOnlyNumber)
                    .matches(/(?<![.])$/, ValidationConstants.userName.endWithDot)
                    .matches(/^(?!.*[.]{2})/, ValidationConstants.userName.twoDots)
                    .max(20, ValidationConstants.userName.long)
                    .required(ValidationConstants.userName.empty)
                    .matches(/^(?![.])(?!.*[.]{2})[a-zA-Z0-9._]+(?![.])$/, ValidationConstants.userName.validUserName),

            })

    const imageValidation = (value) => {
        let fileTypes = ["jpg", "jpeg", "svg", "png"]
        console.log(value, "extension")
        return (fileTypes.indexOf(value) > -1)
    }

    const handleImageChange = (event) => {

        if (event.target.files && event.target.files[0]) {
            let uploadFile = event.target.files[0]
            let splitName = uploadFile.name.split(".").pop().toLowerCase()
            let isSuccess = imageValidation(splitName)
            if (isSuccess) {
                setImageUrl(event.target.files[0]);

                let reader = new FileReader();
                reader.onload = (e) => {
                    setImage(e.target.result);
                };
                reader.readAsDataURL(event.target.files[0]);
                return
            }
            else {
                toast.error(appConstants.imageValidation)
            }
        }
    };

    //Callback methods
    const onSubmit = (values, { setSubmitting }) => {
        let countryCode = '+1';
        setSubmitting(false)
        if(userDetails.type=="bot"){
            dispatch(editUserDetailsAction(location?.state?.userId, values.firstName.trim(), values.lastName.trim(), values.userName.toLowerCase(), "","", "", "",imageUrl))
        }
        else{
            dispatch(editUserDetailsAction(location?.state?.userId, values.firstName.trim(), values.lastName.trim(), values.userName.toLowerCase(), values.email, values.phoneNumber, countryCode, values.userType,""))
        }
    }

    const handleKeyDown = (e) => {
        if (e.key == " ") {
            e.preventDefault();
        }
    }

    return (
        <Spin
            size="large"
            spinning={isLoading}
            wrapperClassName={classes.tableSpinner}
            className={classes.antSpin}
        >
            <DashboardLayout>
                <AppBar breadcrumbs={[{ route: '/users', name: appConstants.userManagement }, { route: '/edit-user', name: appConstants.editUserDetails, state: location?.state?.userId }]} />
                <Navbar title={appConstants.editUserDetails} />
                <Card className={classes.card}>
                    <Grid container>
                        <Grid className={classes.tableContainerRow}>
                            <Formik
                                enableReinitialize
                                initialValues={values}
                                validationSchema={validationSchema}
                                onSubmit={onSubmit}
                            >
                                {({
                                    values,
                                    errors,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                    isSubmitting,
                                    touched,
                                }) => (
                                    <form onSubmit={handleSubmit} noValidate>
                                        <BSTable striped bordered className={classes.bsTableHeight}>
                                            {
                                                isTableDataLoading ? <thead className={classes.bsHeaderTable}>
                                                    <Spin className={classes.tableDataSpinner} />
                                                </thead>
                                                    :
                                                    <tbody>
                                                        {userDetails?.type == "bot" &&
                                                            <tr>
                                                                <td className={classes.rowKey}>
                                                                    {appConstants.uploadImage}
                                                                </td>
                                                                <td className={classes.rowValue}>
                                                                    <div className={classes.inputWrapper}>
                                                                        <input
                                                                            accept=".png,.jpg,.jpeg"
                                                                            className={classes.uploaderInput}
                                                                            id="icon-button-file"
                                                                            type="file"
                                                                            onChange={(e) => handleImageChange(e)}
                                                                        />
                                                                        <label
                                                                            htmlFor="icon-button-file" id="icon-button-file"
                                                                            className={classes.imageManagementUploaderlabel}
                                                                        >
                                                                            <Tooltip
                                                                                title={appConstants.tooltip.changeImage}
                                                                                placement="right"
                                                                            >
                                                                                {image ? (
                                                                                    <img
                                                                                        src={image}
                                                                                        className={classes.terminalImage}
                                                                                        onClick={(e) => handleImageChange(e)}
                                                                                        style={{borderRadius: "50px", border: "2px solid lightgrey"}}
                                                                                    />
                                                                                ) : (
                                                                                    <img
                                                                                        src={AppImages.addPhoto}
                                                                                        className={classes.terminalImage}
                                                                                    />
                                                                                )}
                                                                            </Tooltip>
                                                                        </label>
                                                                        {image?.length <= 0 && errors1 !== null ? (
                                                                            <FormHelperText
                                                                                style={{ marginBottom: 12 }}
                                                                                error
                                                                                id="component-error-text"
                                                                            >
                                                                                {errors1}
                                                                            </FormHelperText>
                                                                        ) : (
                                                                            true
                                                                        )}

                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        }
                                                        <tr>
                                                            <td className={classes.rowKey}>
                                                                Type
                                                            </td>
                                                            <td className={classes.rowValue}>
                                                                <div className={classes.inputWrapper}>
                                                                    <InputField
                                                                        placeholder={"Type"}
                                                                        name="type"
                                                                        // onChange={handleChange}
                                                                        type="type"
                                                                        value={userDetails?.type == "bot" ? "Bot" : "User"}
                                                                        disabled={true}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className={classes.rowKey}>
                                                                Name
                                                            </td>
                                                            <td className={classes.rowValue}>
                                                                <div className={classes.inputWrapper}>
                                                                    <InputField
                                                                        id="firstName"
                                                                        placeholder={"Name"}
                                                                        name="firstName"
                                                                        error={Boolean(touched.firstName && errors.firstName)}
                                                                        helperText={touched.firstName && errors.firstName}
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                        type="firstName"
                                                                        value={values.firstName}
                                                                        maxLength={20}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className={classes.rowKey}>
                                                                Username
                                                            </td>
                                                            <td className={classes.rowValue}>
                                                                <div className={classes.inputWrapper}>
                                                                    <InputField
                                                                        placeholder="Username"
                                                                        name="userName"
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                        error={Boolean(touched.userName && errors.userName)}
                                                                        helperText={touched.userName && errors.userName}
                                                                        type="userName"
                                                                        value={values?.userName?.toLowerCase()}
                                                                        maxLength={20}
                                                                        onKeyDown={handleKeyDown}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        {(userDetails?.type == "user" || userDetails?.type == "dummy") &&
                                                            <> <tr>
                                                                <td className={classes.rowKey}>{appConstants.emailAddress}</td>
                                                                <td className={classes.rowValue}>
                                                                    <div className={classes.inputWrapper}>
                                                                        <InputField
                                                                            id="email"
                                                                            error={Boolean(touched.email && errors.email)}
                                                                            helperText={touched.email && errors.email}
                                                                            placeholder={appConstants.emailAddress}
                                                                            name="email"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            type="email"
                                                                            value={values.email}
                                                                            maxLength={255}

                                                                        />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                                <tr>
                                                                    <td className={classes.rowKey}>{appConstants.phoneNumber}</td>
                                                                    <td className={classes.rowValue}>
                                                                        <div className={classes.inputWrapper}>
                                                                            <InputField
                                                                                id="phoneNumber"
                                                                                name="phoneNumber"
                                                                                placeholder={appConstants.phoneNumber}
                                                                                error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                                                                                helperText={touched.phoneNumber && errors.phoneNumber}
                                                                                onBlur={handleBlur}
                                                                                onChange={handleChange}
                                                                                value={values.phoneNumber}
                                                                                maxLength={15}
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        }
                                                        <tr>
                                                            <td className={classes.rowKey}></td>
                                                            <td className={classes.rowValue}>
                                                                <Button type="submit" title={appConstants.update} />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                            }
                                        </BSTable>
                                    </form>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                </Card>
            </DashboardLayout>
        </Spin>
    );
}
