import { apiConstants as types } from '../../themes/constants'

// Get state Action
export const getStateListAction = (search, offset, limit, sortBy, order) => ({
    type: types.API_GET_STATE_LIST_LOAD,
    search, offset, limit, sortBy, order
})

// Add state Action
export const addStateAction = (stateName, shortName, timezone) => ({
    type: types.API_ADD_STATE_LIST_LOAD,
    stateName,
    shortName,
    timezone
})

// Get state details Action
export const getStateDetailsAction = (stateId) => ({
    type: types.API_GET_STATE_LIST_DETAILS_LOAD,
    stateId,
})

// Edit state Action
export const editStateAction = (stateId, stateName, shortName, timezone) => ({
    type: types.API_EDIT_STATE_LIST_LOAD,
    stateId,
    stateName,
    shortName,
    timezone
})

//  Delete state Action
export const deleteStateAction = (stateId) => ({
    type: types.API_DELETE_STATE_LIST_LOAD,
    stateId,
})

//  Export terminal Action
export const exportListFileAction = (fileType) => ({
    type: types.API_EXPORT_LISTING_FILE_LOAD,
});

//  Import terminal Action
export const importListFileAction = (file, fileType) => ({
    type: types.API_IMPORT_LIST_FILE_LOAD,
    file,
    fileType,
});

//timezone list
export const getTimeZoneListAction = () => ({
    type: types.API_TIMEZONE_LIST_LOAD,
})

