import React, { useState, useEffect, useRef } from 'react';
// Navigation
import { useHistory, useLocation } from 'react-router-dom'
// Styles
import 'antd/dist/antd.css';
import '../dashboard.scss';
import { Card } from 'react-bootstrap';
// Mui Components
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// Custom components
import DashboardLayout from '../../layouts/dashboardLayout';
import { AppBar, PaginationBar, Navbar, Table, NavbarSearch, Button } from '../../customComponents';
// Constants
import { AppImages } from '../../themes/appImages';
import { appConstants } from '../../themes/constants';
// utils methods
import { useStyles } from '../../styles'
// Redux
import { useSelector, useDispatch } from 'react-redux'
import { viewTimelineAction, getReportedUsersAction, exportUserAction, exportReportedUserAction } from '../../store/actions/userManagementActions';
import moment from 'moment'

export const ReportedUsers = () => {
    // Hooks declarations
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const location = useLocation()
    const dispatch = useDispatch()
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    // local state initialization
    const [search, setSearch] = useState("")
    const [offset, setOffset] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sortBy, setSortBy] = useState("")
    const [order, setOrder] = useState(null)


    const userManagementState = useSelector(state => state.userManagementReducer)
    const { isLoading, reportedUsers } = userManagementState
    const { paging, data } = reportedUsers

    // Method to fetch listing
    var userId = location?.state?.userId

    const fetchDataListing = (search, offset, limit, sortBy, order) => {
        console.log(sortBy)
        if (location?.state?.userId) {
            dispatch(getReportedUsersAction({ search, offset, limit, sortBy, order, userId }))
        } else {
            history.push('/users')
        }
    }

    //lifecycle hook
    useEffect(() => {
        document.title = appConstants.headerTitle.reportedUsers;
        fetchDataListing(search, offset, limit, sortBy, order)
    }, [])

    //Callback method to handle sorting 
    const handleChange = async (pagination, filters, sorter) => {
        //To fetch listing in ascending order
        if (sorter.order == "ascend") {
            setOrder(1)
            setSortBy(sorter.columnKey)
            fetchDataListing(search, offset, limit, sorter.columnKey, 1)
        } //To fetch listing in descending order
        else if (sorter.order === "descend") {
            setOrder(-1)
            setSortBy(sorter.columnKey)
            fetchDataListing(search, offset, limit, sorter.columnKey, -1)
        } //To fetch listing in normal order
        else {
            setOrder(null)
            setSortBy("")
            fetchDataListing(search, offset, limit, "", null)
        }
    }

    // Callback method to handle page chage
    const handlePaginationChange = (e, page) => {
        const offsetTo = (page - 1) * limit
        setOffset(offsetTo)
        fetchDataListing(search, offsetTo, limit, sortBy, order)
    }

    // Callback method to handle searching key
    const handleSearching = async (e) => {
        var tempText = e.target.value
        if (e.target.value.charAt(0) === " ") {
            tempText = e.target.value.replace(/^ +/gm, '');
        }
        setSearch(tempText)
        fetchDataListing(tempText, 0, limit, "", null)
    }

    const columns = [
        {
            key: 'sr',
            title: appConstants.sr,
            dataIndex: '',
            render: (text, record, index) => (index + 1) + (paging?.page?.nextPage - limit),
            ellipsis: false,
            width: 100,
        },
        {
            title: "User Reported",
            dataIndex: "report_to",
            key: "report_to",
            render: (report_by, record, index) => {
                return (
                    <div>
                        {record?.report_to}
                    </div>
                )
            },
            ellipsis: false,
            sorter: false,
        },
        {
            title: "Reported By",
            dataIndex: "report_by",
            key: "report_by",
            render: (report_by, record, index) => {
                return (
                    <div>
                        {record?.report_by}
                    </div>
                )
            },
            ellipsis: false,
            sorter: true,
        },
        {
            title: "Reported Date",
            dataIndex: "created_at",
            key: "created_at",
            render: (report_by, record, index) => {
                return (
                    <div>
                        {moment(record?.created_at).format("YYYY-MM-DD")}
                    </div>
                )
            },
            ellipsis: false,
            sorter: true,
        },

        {
            key: 'x',
            title: appConstants.action,
            dataIndex: '',
            align: 'center',
            width: 200,
            render: (record) => {
                return (
                    <div style={{ textAlign: "center" }}>
                        <Button disabled={isLoading} title={appConstants.view}
                            marginRight="0px"
                            onClick={() => history.push({
                                pathname: '/reported-users-details',
                                state: {  // location state
                                    userId: location?.state?.userId,
                                    timelineID: record._id,
                                },
                            })}
                        />
                    </div>
                )
            },
        }
    ];

    return (
        <DashboardLayout>

            <AppBar breadcrumbs={[{ route: '/users', name: appConstants.userManagement },
            {
                route: "/view-user-details", name: appConstants.userDetails,
                state: { userId: location?.state?.userId }
            },
            {
                route: '/reported-users', name: "Reported By",
                state: { userId: location?.state?.userId }
            }]} />
            <Grid container className={classes.buttonBarWrapper} >
                <Button title="Export User"
                    onClick={() => {
                        dispatch(exportReportedUserAction(userId))
                    }}
                    mr mb />
            </Grid>
            <Navbar title="Reported By" searchable value={search} onChange={(e) => handleSearching(e)} />
            <Card className={classes.card}>
                {matches &&
                    <Box className={classes.searchBox}>
                        <NavbarSearch value={search} onChange={e => handleSearching(e)} />
                    </Box>
                }
                <Grid container>
                    <Grid className={classes.tableContainerRow}>
                        <Table
                            className={classes.tableWrapper}
                            loading={isLoading}
                            columns={columns}
                            dataSource={data}
                            onChange={handleChange}
                            searching={search.length > 0}
                        />
                    </Grid>
                </Grid>
                <PaginationBar
                    totalCount={paging?.page?.totalCount}
                    count={Math.ceil(paging?.page?.totalCount / limit)}
                    currentPage={paging?.page?.currentPage}
                    nextPage={paging?.page?.nextPage}
                    onChange={(e, page) => handlePaginationChange(e, page)}
                    prevPage={paging?.page?.nextPage - limit}
                />
            </Card>
        </DashboardLayout>
    );
}
