import React from 'react';
import { Bar, Line } from 'react-chartjs-2';
import ChartBar from 'chart.js/auto'
import "chartjs-plugin-datalabels";
import { useEffect } from 'react';

const BarChartjs = ({ data, label, isData, labels1, id, apiData, type, fill, hrs, backgroundcolor, bordercolor }) => {

    // const lineargradient=()=>{
    //     var ctx = document.getElementById(id).getContext("2d")
    //     var gradient = ctx.createLinearGradient(0, 0, 0, 400)
    //     gradient.addColorStop(0, 'rgba(229, 239, 255, 1)')
    //     gradient.addColorStop(1, '#FFFFFF')
    // }
    // window.onload=function(){
    //     lineargradient()
    // }

    return (
        <div style={{ position: 'relative' }}>
            {
                // !isData && <div style={{ width: '100%', height: window.innerWidth <= 600 ? 180 : window.innerWidth <= 900 ? 145 : 220, position: 'absolute', display: 'flex', justifyContent: window.innerWidth <= 600 ? 'flex-end' : 'center', alignItems: 'center' }}>No data available</div>
            }
            {
                type == "line" ?
                    <Line
                        id={id}
                        height={"200px"}
                        width={"500px"}
                        // className="bar-chart"
                        data={
                            {
                                labels: labels1,
                                datasets: [{
                                    label: label,
                                    data: apiData,
                                    backgroundColor: backgroundcolor,
                                    borderColor: bordercolor,
                                    // backgroundColor: [
                                    //     'rgba(25, 110, 161, 1)',
                                    // ],
                                    // borderColor: [
                                    //     'rgba(25, 110, 161, 1)',
                                    // ],
                                    borderWidth: 1,
                                    fill: fill,
                                }]
                            }}
                        // height={window.innerWidth <= 600 ? 320 : 170}
                        options={{
                            title: {
                                display: true,
                                text: label,
                                fontSize: 15,
                                padding: 25,
                            },
                            legend: {
                                display: false
                            },
                            scales: {
                                y: {
                                    suggestedMax: 10,
                                    grid: {
                                        display: false
                                    },
                                    min: 0
                                },
                                x: {
                                    grid: {
                                        display: false
                                    }
                                }
                            },
                            plugins: {
                                datalabels: {
                                    formatter: function (value, context) {
                                        return context.chart.data.percentageChange[context.dataIndex];
                                    },
                                    color: function (context) {
                                        if (context.chart.data.percentageData[context.dataIndex] >= 0) {
                                            return 'green'
                                        } else {
                                            return 'red'
                                        }
                                    },
                                    display: true,
                                    anchor: 'end',
                                    align: 'end'
                                }
                            }
                        }
                        }
                    />
                    :
                    <Bar
                        id={id}
                        height={"200px"}
                        width={"500px"}
                        // height={window.innerWidth <= 600 ? 320 : 170}
                        data={
                            {
                                labels: labels1,
                                datasets: [{
                                    label: label,
                                    data: apiData,
                                    backgroundColor: backgroundcolor,
                                    borderColor: bordercolor,
                                    // backgroundColor: [
                                    //     'rgba(25, 110, 161, 1)',
                                    // ],
                                    // borderColor: [
                                    //     'rgba(25, 110, 161, 1)',
                                    // ],
                                    borderWidth: 1,
                                    barThickness: 50,

                                }]
                            }}

                        options={{
                            title: {
                                display: true,
                                text: label,
                                fontSize: 15,
                                padding: 25,
                            },
                            legend: {
                                display: false
                            },
                            scales: {
                                y: {
                                    suggestedMax: 10,
                                    grid: {
                                        display: false,
                                    },
                                },
                                x: {
                                    grid: {
                                        display: false,

                                    }
                                }
                            },
                            plugins: {
                                tooltip: {
                                    callbacks: {
                                        afterBody: function (t, d) {
                                            return hrs
                                        }
                                    }
                                }
                                // datalabels: {
                                //     formatter: function (value, context) {
                                //         return context.chart.data.percentageChange[context.dataIndex];
                                //     },
                                //     color: function (context) {
                                //         if (context.chart.data.percentageData[context.dataIndex] >= 0) {
                                //             return 'green'
                                //         } else {
                                //             return 'red'
                                //         }
                                //     },
                                //     display: true,
                                //     anchor: 'end',
                                //     align: 'end'
                                // }
                            }
                        }
                        }
                    />
            }
        </div>
    )
}
export default BarChartjs;