// import { takeLatest, call, put } from 'redux-saga/effects'
// import { message } from 'antd'
// import { apiConstants as types, appMessages } from '../../themes/constants'
// import axios from '../axios'
// import cl from '../../utils/cl'
// import history from '../../utils/history'
// import { toast } from 'react-toastify';
// import moment from 'moment'

// function* dashboardFailedSaga(result) {
//     yield put({
//         type: types.API_DASHBOARD_FAILED,
//         errorState: result?.error,
//     })
//     toast.error(result?.error)
// }

// function* dashboardErrorSaga(result) {
//     yield put({
//         type: types.API_DASHBOARD_ERROR,
//         errorState: result?.error,
//     })
//     if (result.status === 2) {
//         toast.error(appMessages.messageStatus500)
//     } else {
//         toast.error(result?.error)
//     }
// }

// function* getDashboardSaga(action) {
//     try {
//         const result = yield call(axios.dashboardData, action.payload)
//         if (result.status === 1) {
//             cl('result inside get dashboard saga', result)
//             yield put({
//                 type: types.API_DASHBOARD_SUCCESS,
//                 result: result.result.data.data,
//             })
//         }
//         else {
//             yield call(dashboardFailedSaga, result)
//         }
//     }
//     catch (error) {
//         yield call(dashboardErrorSaga, error)
//     }
// }

// function* getAnalyticsSaga(action) {
//     try {
//         const result = yield call(axios.analyticsData, action.payload)
//         if (result.status === 1) {
//             cl('result inside get dashboard saga', result)
//             yield put({
//                 type: types.API_ANALYTICS_SUCCESS,
//                 result: result.result.data.data,
//             })
//         }
//         else {
//             yield call(dashboardFailedSaga, result)
//         }
//     }
//     catch (error) {
//         yield call(dashboardErrorSaga, error)
//     }
// }

// function* getRegionAnalyticsSaga(action) {
//     try {
//         const result = yield call(axios.getTerminalAreaList, "", null, null, "", "")
//         if (result.status === 1) {
//             let obj = {
//                 regionId: result.result.data.data.list_terminals[0]._id,
//                 type: "monthly",
//                 year: moment().year()
//             }
//             const regionresult = yield call(axios.regionAnalyticsData, obj)
//             if (regionresult.status === 1) {
//                 cl('result inside get terminal area list saga', result)

//                 yield put({
//                     type: types.API_ANALYTICS_REGION_SUCCESS,
//                     result: regionresult.result.data.data,
//                     regionresult: result.result.data.data,
//                 })
//             }
//             cl('result inside get dashboard saga', result)
//             yield put({
//                 type: types.API_GET_TERMINAL_AREA_LIST_SUCCESS,
//                 regionresult: result.result.data.data,
//             })
//         }
//         else {
//             yield call(dashboardFailedSaga, result)
//         }
//     }
//     catch (error) {
//         yield call(dashboardErrorSaga, error)
//     }
// }

// function* updateRegionAnalyticsSaga(action) {
//     try {
//         const result = yield call(axios.regionAnalyticsData, action.payload)
//         if (result.status === 1) {
//             cl('result inside get dashboard saga', result)
//             yield put({
//                 type: types.API_UPDATE_ANALYTICS_REGION_SUCCESS,
//                 result: result.result.data.data,
//             })
//         }
//         else {
//             yield call(dashboardFailedSaga, result)
//         }
//     }
//     catch (error) {
//         yield call(dashboardErrorSaga, error)
//     }
// }

// function* getTerminalAnalyticsSaga(action) {
//     try {
//         const result = yield call(axios.getTerminalAreaList, "", null, null, "", "")
//         if (result.status === 1) {
//             let obj1 = {
//                 regionId: result.result.data.data.list_terminals[0]._id
//             }
//             const regionterminalresult = yield call(axios.regionTerminalData, obj1)
//             if (regionterminalresult.status === 1
//                 // && regionterminalresult.result.data.data.length > 0
//             ) {
//                 cl('result inside get dashboard saga', regionterminalresult)
//                 yield put({
//                     type: types.API_REGION_TERMINAL_SUCCESS,
//                     result: regionterminalresult.result.data.data,
//                 })
//                 let obj
//                 action.payload.templates == 'weekly' ?
//                     obj = {
//                         terminalId: regionterminalresult.result.data.data[0]._id,
//                         type: action.payload.templates,
//                         year: action.payload.year ? action.payload.year : moment().year(),
//                         month: action.payload.months
//                     }
//                     :
//                     obj = {
//                         terminalId: regionterminalresult.result.data.data[0]._id,
//                         type: action.payload.templates,
//                         year: action.payload.year ? action.payload.year : moment().year(),
//                     }
//                 const terminalresult = yield call(axios.terminalAnalyticsData, obj)
//                 if (terminalresult.status === 1) {
//                     cl('result inside get terminal area list saga', result)
//                     yield put({
//                         type: types.API_ANALYTICS_TERMINAL_SUCCESS,
//                         result: terminalresult.result.data.data,
//                         terminalresult: terminalresult.result.data.data,
//                         regionterminalresult: regionterminalresult.result.data.data,
//                         regionresult: result.result.data.data
//                     })
//                 }
//                 cl('result inside get dashboard saga', result)
//                 yield put({
//                     type: types.API_GET_TERMINAL_LIST_SUCCESS,
//                     result: result.result.data.data,
//                 })
//             }
//         }
//         else {
//             yield call(dashboardFailedSaga, result)
//         }
//     }
//     catch (error) {
//         yield call(dashboardErrorSaga, error)
//     }
// }

// function* updateTerminalAnalyticsSaga(action) {
//     try {
//         const result = yield call(axios.terminalAnalyticsData, action.payload)
//         if (result.status === 1) {
//             cl('result inside get dashboard saga', result)
//             yield put({
//                 type: types.API_UPDATE_ANALYTICS_TERMINAL_SUCCESS,
//                 result: result.result.data.data,
//             })
//         }
//         else {
//             yield call(dashboardFailedSaga, result)
//         }
//     }
//     catch (error) {
//         yield call(dashboardErrorSaga, error)
//     }
// }

// function* regionTerminalSaga(action) {
//     try {
//         const result = yield call(axios.regionTerminalData, action.payload)
//         if (result.status === 1) {
//             if (result.result.data.data.length > 0) {
//                 let obj
//                 action.payload.templates == 'weekly' ?
//                     obj = {
//                         terminalId: result.result.data.data[0]._id,
//                         type: action.payload.templates,
//                         year: moment().year(),
//                         month: action.payload.months
//                     }
//                     :
//                     obj = {
//                         terminalId: result.result.data.data[0]._id,
//                         type: action.payload.templates,
//                         year: moment().year(),
//                     }
//                 const terminalresult = yield call(axios.terminalAnalyticsData, obj)
//                 if (terminalresult.status === 1) {
//                     cl('result inside get terminal area list saga', result)
//                     console.log(terminalresult.result.data.data, "newwwwww")
//                     yield put({
//                         type: types.API_ANALYTICS_TERMINAL_SUCCESS,
//                         // result: terminalresult.result.data.data,
//                         terminalresult: terminalresult.result.data.data,
//                         regionterminalresult: result.result.data.data,
//                     })
//                 }
//                 yield put({
//                     type: types.API_REGION_TERMINAL_SUCCESS,
//                     result: result.result.data.data,
//                     result: terminalresult.result.data.data,
//                     terminalresult: terminalresult.result.data.data,
//                     regionterminalresult: result.result.data.data,
//                 })
//             }
//         }
//         else {
//             yield call(dashboardFailedSaga, result)
//         }
//     }
//     catch (error) {
//         yield call(dashboardErrorSaga, error)
//     }
// }

// export default function* rootDashboardSaga() {
//     yield takeLatest(types.API_DASHBOARD_LOAD, getDashboardSaga)
//     yield takeLatest(types.API_ANALYTICS_LOAD, getAnalyticsSaga)
//     yield takeLatest(types.API_ANALYTICS_REGION_LOAD, getRegionAnalyticsSaga)
//     yield takeLatest(types.API_UPDATE_ANALYTICS_REGION_LOAD, updateRegionAnalyticsSaga)
//     yield takeLatest(types.API_ANALYTICS_TERMINAL_LOAD, getTerminalAnalyticsSaga)
//     yield takeLatest(types.API_UPDATE_ANALYTICS_TERMINAL_LOAD, updateTerminalAnalyticsSaga)
//     yield takeLatest(types.API_REGION_TERMINAL_LOAD, regionTerminalSaga)
// }

import { takeLatest, call, put } from 'redux-saga/effects'
import { message } from 'antd'
import { apiConstants as types, appMessages } from '../../themes/constants'
import axios from '../axios'
import cl from '../../utils/cl'
import history from '../../utils/history'
import { toast } from 'react-toastify';
import moment from 'moment'

function* dashboardFailedSaga(result) {
    yield put({
        type: types.API_DASHBOARD_FAILED,
        errorState: result?.error,
    })
    toast.error(result?.error)
}

function* dashboardErrorSaga(result) {
    yield put({
        type: types.API_DASHBOARD_ERROR,
        errorState: result?.error,
    })
    if (result.status === 2) {
        toast.error(appMessages.messageStatus500)
    } else {
        toast.error(result?.error)
    }
}

function* getDashboardSaga(action) {
    try {
        const result = yield call(axios.dashboardData, action.payload)
        if (result.status === 1) {
            cl('result inside get dashboard saga', result)
            yield put({
                type: types.API_DASHBOARD_SUCCESS,
                result: result.result.data.data,
            })
        }
        else {
            yield call(dashboardFailedSaga, result)
        }
    }
    catch (error) {
        yield call(dashboardErrorSaga, error)
    }
}

function* getAnalyticsSaga(action) {
    try {
        const result = yield call(axios.analyticsData, action.payload)
        if (result.status === 1) {
            cl('result inside get dashboard saga', result)
            yield put({
                type: types.API_ANALYTICS_SUCCESS,
                result: result.result.data.data,
            })
        }
        else {
            yield call(dashboardFailedSaga, result)
        }
    }
    catch (error) {
        yield call(dashboardErrorSaga, error)
    }
}

function* getRegionAnalyticsSaga(action) {
    try {
        const result = yield call(axios.getTerminalAreaList, "", null, null, "", "")
        if (result.status === 1) {
            let obj = {
                regionId: result.result.data.data.list_terminals[0]._id,
                type: "monthly",
                year: moment().year()
            }
            const regionresult = yield call(axios.regionAnalyticsData, obj)
            if (regionresult.status === 1) {
                cl('result inside get terminal area list saga', result)

                yield put({
                    type: types.API_ANALYTICS_REGION_SUCCESS,
                    result: regionresult.result.data.data,
                    regionresult: result.result.data.data,
                })
            }
            cl('result inside get dashboard saga', result)
            yield put({
                type: types.API_GET_TERMINAL_AREA_LIST_SUCCESS,
                regionresult: result.result.data.data,
            })
        }
        else {
            yield call(dashboardFailedSaga, result)
        }
    }
    catch (error) {
        yield call(dashboardErrorSaga, error)
    }
}

function* updateRegionAnalyticsSaga(action) {
    try {
        const result = yield call(axios.regionAnalyticsData, action.payload)
        if (result.status === 1) {
            cl('result inside get dashboard saga', result)
            yield put({
                type: types.API_UPDATE_ANALYTICS_REGION_SUCCESS,
                result: result.result.data.data,
            })
        }
        else {
            yield call(dashboardFailedSaga, result)
        }
    }
    catch (error) {
        yield call(dashboardErrorSaga, error)
    }
}

function* getTerminalAnalyticsSaga(action) {
    try {
        const result = yield call(axios.getTerminalAreaList, "", null, null, "", "")
      console.log('result terminal area',result);
      
        if (result.status === 1) {
            let obj1 = {
                regionId: result.result.data.data.list_terminals[0]._id
            }
            const regionterminalresult = yield call(axios.regionTerminalData, obj1)
      console.log('result terminal area1',regionterminalresult);
            
            if (regionterminalresult.status === 1) {

                cl('result inside get dashboard saga', regionterminalresult)
                yield put({
                    type: types.API_REGION_TERMINAL_SUCCESS,
                    result: regionterminalresult.result.data.data,
                    regionresult: result.result.data.data
                })
                
              
                let obj
                action.payload.templates == 'weekly' ?
                    obj = {
                        terminalId: regionterminalresult.result.data.data[0]?._id,
                        type: action.payload.templates,
                        year: action.payload.year ? action.payload.year : moment().year(),
                        month: action.payload.months
                    }
                    :
                    obj = {
                        terminalId: regionterminalresult.result.data.data[0]?._id,
                        type: action.payload.templates,
                        year: action.payload.year ? action.payload.year : moment().year(),
                    }
                    if(regionterminalresult.result.data.data.length>0){
                const terminalresult = yield call(axios.terminalAnalyticsData, obj)
               
               
                if (terminalresult.status === 1) {
                    console.log('result inside get terminal area list saga', result)
                    yield put({
                        type: types.API_ANALYTICS_TERMINAL_SUCCESS,
                        result: terminalresult.result.data.data,
                        terminalresult: terminalresult.result.data.data,
                        regionterminalresult: regionterminalresult.result.data.data,
                        regionresult: result.result.data.data
                    })
                }
                cl('result inside get dashboard saga', result)
                yield put({
                    type: types.API_GET_TERMINAL_LIST_SUCCESS,
                    result: result.result.data.data,
                })
            }
            }
        }
        else {
            yield call(dashboardFailedSaga, result)
        }
    }
    catch (error) {
        console.log('error',error);
        yield call(dashboardErrorSaga, error)
    }
}

function* updateTerminalAnalyticsSaga(action) {
    try {
        const result = yield call(axios.terminalAnalyticsData, action.payload)
        if (result.status === 1) {
            cl('result inside get dashboard saga', result)
            yield put({
                type: types.API_UPDATE_ANALYTICS_TERMINAL_SUCCESS,
                result: result.result.data.data,
            })
        }
        else {
            yield call(dashboardFailedSaga, result)
        }
    }
    catch (error) {
        yield call(dashboardErrorSaga, error)
    }
}

function* regionTerminalSaga(action) {
    try {
        const result = yield call(axios.regionTerminalData, action.payload)
        if (result.status === 1) {
            if (result.result.data.data.length > 0) {
                yield put({
                    type: types.API_REGION_TERMINAL_SUCCESS,
                    result: result.result.data.data,
                })
                console.log(result, 'action.templates', action);
                let obj = {}
                action.payload.templates == 'weekly' ?
                    obj = {
                        terminalId: result.result.data.data[0]._id,
                        type: action.payload.templates,
                        year: moment().year(),
                        month: action.payload.months
                    }
                    :
                    obj = {
                        terminalId: result.result.data.data[0]._id,
                        type: action.payload.templates,
                        year: moment().year(),
                    }
                const terminalresult = yield call(axios.terminalAnalyticsData, obj)
                if (terminalresult.status === 1) {
                    yield put({
                        type: types.API_ANALYTICS_TERMINAL_SUCCESSChange,
                        result: terminalresult.result.data.data,
                        terminalresult: terminalresult.result.data.data,
                        regionterminalresult: result.result.data.data,
                    })

                }

                yield put({
                    type: types.API_GET_TERMINAL_LIST_SUCCESS,
                    result: result.result.data.data,
                })
            }
            // else {
            //     toast.error("Please create the terminal first.")
            // }
        }
        else {
            yield call(dashboardFailedSaga, result)
        }
    }
    catch (error) {
        console.log('error',error);
        yield call(dashboardErrorSaga, error)
    }
}

export default function* rootDashboardSaga() {
    yield takeLatest(types.API_DASHBOARD_LOAD, getDashboardSaga)
    yield takeLatest(types.API_ANALYTICS_LOAD, getAnalyticsSaga)
    yield takeLatest(types.API_ANALYTICS_REGION_LOAD, getRegionAnalyticsSaga)
    yield takeLatest(types.API_UPDATE_ANALYTICS_REGION_LOAD, updateRegionAnalyticsSaga)
    yield takeLatest(types.API_ANALYTICS_TERMINAL_LOAD, getTerminalAnalyticsSaga)
    yield takeLatest(types.API_UPDATE_ANALYTICS_TERMINAL_LOAD, updateTerminalAnalyticsSaga)
    yield takeLatest(types.API_REGION_TERMINAL_LOAD, regionTerminalSaga)
}