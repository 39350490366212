import React, { useState, useEffect, useRef } from "react";
// Navigation
import { useHistory, useLocation } from "react-router-dom";
// Styles
import "antd/dist/antd.css";
import "../dashboard.scss";
import "../../stylesNew/styles.css";
// antd
import { Tooltip, Spin, Input } from "antd";
import { Card, Table as BSTable } from "react-bootstrap";
// fontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faShare } from "@fortawesome/free-solid-svg-icons";
// Mui Components
// import { Grid, useTheme, useMediaQuery, Box, FormHelperText } from '@material-ui/core';
// Custom components
import DashboardLayout from "../../layouts/dashboardLayout";
import {
  AppBar,
  Button,
  PaginationBar,
  Navbar,
  NavbarSearch,
  ConfirmationModal,
  Table,
  InputField
} from "../../customComponents";
// Constants
import { AppImages } from "../../themes/appImages";
import { Colors } from "../../themes/colors";
import { appConstants, ValidationConstants } from "../../themes/constants";
// utils methods
import cl from "../../utils/cl";
import { useStyles } from "../../styles";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  getTerminalAreaListAction,
} from "../../store/actions";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Grid,
  Select,
  useTheme,
  FormHelperText,
  Collapse,
  Fade,
} from "@material-ui/core";
import {
  convertFromRaw,
  convertToRaw,
  // Editor,
  convertFromHTML,
  EditorState,
  RichUtils,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import "draft-js/dist/Draft.css";
import RichEditorExample from "../../customComponents/textEditor";
import { addImage, addNewsAction, deleteImage, loaderStart, imageCount1 } from "../../store/actions/newsManagementActions";
import FullScreenDialog from "../../customComponents/previewModal";
import { uploadImageOnS3 } from "../../utils/helpers";
import TextEditor from "../../customComponents/ckEditor";
import { toast } from "react-toastify";

export const NewsAdd = () => {
  const styleProps = { searchable: true };
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty()
  );
  const [headingEditorState, setHeadingEditorState] = React.useState(() =>
    EditorState.createEmpty()
  );
  const [image, setImage] = useState("");
  const [imageName, setImageName] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [errors, setErrors] = useState(null);
  const [terminalerrors, setTerminalErrors] = useState(null);
  const [newserrors, setNewsErrors] = useState("");
  const [newsHeadingerrors, setNewsHeadingerrors] = useState("");
  const [terminalTouched, setTerminalTouched] = useState(null);
  const [newsTouched, setNewsTouched] = useState(null);
  const [headingNewsTouched, setHeadingNewsTouched] = useState(null);
  const [errorName, setErrorName] = useState(null);
  const [terminalArea, setTerminalArea] = useState("");
  const [terminalName, setTerminalName] = useState("");
  const [previewModal, setPreviewModal] = useState(false);
  const [news, setNews] = useState("")
  const [heading, setHeading] = useState("")
  const [region, setRegion] = useState("")
  const [descriptionData, setDescriptionData] = useState("")
  const [loadImage, setLoadImage] = useState(false)
  const [elements, setElements] = useState([])
  const [regionName, setRegionName] = useState(null)
  const [maxChar, setMaxChar] = useState(0)
  const [increment, setIncrement] = useState(0)
  const[editorError,setEditorError]=useState(false)
  const state = useSelector((state) => state.imageManagementReducer);

  const validationSchema = Yup.object().shape({
    region: Yup.string().required("Please enter region."),
    heading: Yup.string().required("Please enter title.").min(2, "Title should be at least 2 characters long."),
    news: Yup.string().required("Please enter news.").min(3, "News should be at least 2 characters long."),
  })

  useEffect(() => {
    let ddd = localStorage?.newReloadData
    let terminalArea1 = localStorage?.terminalArea
    let title1=localStorage?.editorTitle
    setNews(ddd)
    if(title1 !==undefined){
      setHeading(title1)
    }
    if(terminalArea1 !==undefined){
      setRegion(terminalArea1)
    }
    localStorage.removeItem('newReloadData')
    localStorage.removeItem('terminalArea')
    localStorage.removeItem('loader')
    localStorage.removeItem('increment')
    localStorage.removeItem('editorTitle')
    document.title = appConstants.headerTitle.addNews;
    dispatch(getTerminalAreaListAction("", null, null, "", ""));
  }, []);

  
  // Hooks declarations
  const classes = useStyles(styleProps);
  // const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const theme = useTheme();
  // const matches = useMediaQuery(theme.breakpoints.down('xs'));

  const { terminalAreaListing } = useSelector(
    (state) => state.terminalAreaManagementReducer
  );

  const { isLoading, isTableDataLoading,counter } = useSelector((state) => state.newsManagementReducer)

  const initialValues = {
    region: region,
    news: news,
    heading: heading
  }

  const onSubmit = (values) => {
    let b = JSON.parse(localStorage.getItem("increment"))
    if (maxChar > 500) {
      toast.error('News should be maximum 500 characters long.')
      return false;
    }
    if (b > 2) {
      toast.error("Only 2 images are allowed.")
      return false;
    }
    dispatch(deleteImage(elements))
    dispatch(addNewsAction(values.region, imageUrl, values.heading, values.news));
  }

  const handlePreview = () => {
    setPreviewModal(true);
  }

  const increment1 = () => {
    let b = JSON.parse(localStorage.getItem("increment"))
    if (b == null) {
      localStorage.setItem("increment", 1)
    }
    else {
      localStorage.setItem("increment", b + 1)
    }
    // localStorage.setItem("increment",b)
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit,
    // validateOnBlur:false,
    // validateOnChange:true
  });

  const handleCallback = (data) => {
    setElements(data)
  }

  const handleCallback1 = (data) => {
    setLoadImage(data)
  }

  const getName = (terminalArea) => {
    return terminalArea?.region_name
    // setRegionName(terminalArea?.region_name)
  }

  const deleteCount = (e) => {
    if(e==false){
    let b = JSON.parse(localStorage.getItem("increment"))
      localStorage.setItem("increment", b - 1)
    }
  }


  return (
    <Spin
      size="large"
      spinning={isLoading}
      wrapperClassName={classes.tableSpinner}
      className={classes.antSpin}
    >
      <DashboardLayout>
        {/* breadcrumbs */}
        {previewModal && (
          <FullScreenDialog
            // title="Alert"
            // text={appConstants.confirmDeleteNews}
            visible={previewModal}
            headingData={formik.values.news}
            descriptionData={descriptionData}
            markup={editorState.getCurrentContent()}
            heading={headingEditorState.getCurrentContent()}
            terminalArea={terminalArea}
            imageUrl={image}
            regionName={regionName}
            onCancel={() => {
              setPreviewModal(false);
            }}
          />
        )}
        <AppBar
          breadcrumbs={[
            { route: "/news-terminal", name: appConstants.newsManagement },
            { route: "/news-add", name: "Add News" },
          ]}
        />
        <Navbar title={appConstants.addNews} />
        <Card className={classes.card}>
          <Grid container>
            <Grid className={classes.tableContainerRow}>
              <form onSubmit={formik.handleSubmit} noValidate>
                <BSTable striped bordered style={{ minHeight: 125 }}>
                  {/* { */}
                  {/* // true ? <thead className={classes.bsHeaderTable}>
                    //   <Spin size="large"
                    //     spinning={true}
                    //     // wrapperClassName={classes.tableSpinner}
                    //     // className={classes.antSpin} 
                    //     />
                    // </thead>
                      // : */}
                  <tbody>
                    <tr>
                      <td className={classes.rowKey}>Region</td>
                      <td className={classes.rowValue}>
                        <div className={classes.inputWrapper}>
                          <Select
                            native
                            className={classes.selectInput}
                            value={region}
                            onChange={(e) => {
                              setRegion(e.target.value)
                              formik.setFieldValue("region", e.target.value)
                            }
                            }
                            inputProps={{
                              name: "region",
                              id: "region",
                            }}
                          >
                            <option value="">Select Region</option>
                            {terminalAreaListing.map((terminalArea, index) => {
                              return (
                                <option key={terminalArea.region_name}
                                  value={terminalArea._id} >
                                  {getName(terminalArea)}
                                </option>

                              );
                            })}
                          </Select>
                          {formik.errors.region && (
                            <FormHelperText style={{ marginTop: "0.5rem" }} error id="component-error-text">
                              {formik.errors.region}
                            </FormHelperText>
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className={classes.rowKey}>Title</td>
                      <td className={classes.rowValue}>
                        <div className={classes.inputWrapper}>
                          <InputField
                            id="heading"
                            placeholder="Title"
                            name="heading"
                            onBlur={formik.handleBlur}
                            onChange={(e)=>{
                              setHeading(e.target.value)
                            formik.setFieldValue("heading",e.target.value)
                            }}
                            // type="heading"
                            value={heading?.trimLeft()}
                            maxLength={50}
                          />
                          {formik.errors.heading && <FormHelperText error id="component-error-text">{formik.errors.heading}</FormHelperText>}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className={classes.rowKey}>News</td>
                      <td className={classes.rowValue}>
                        <div className={classes.editorWrapper} style={{maxWidth:"1000px"}}>
                          <TextEditor
                            loaderStart={(type) => {
                              dispatch(loaderStart(type))
                            }}
                            imageCount={(e) => {
                              increment1()
                            }}
                            onError={(e) => {
                              setEditorError(true)
                              localStorage.setItem('newReloadData', news)
                              localStorage.setItem('terminalArea', region)
                              localStorage.setItem('editorTitle', heading)
                              window.location.reload()
                            }}
                            // id="heading"
                            value={news}
                            onChange={(e, editor) => {
                              const data = editor?.getData()
                              formik.setFieldValue("news", data)
                              setNews(data)
                            }}
                            getData={(data) => handleCallback(data)}
                            loaderimages={(data) => handleCallback1(data)}
                            passChar={(t) => setMaxChar(t)}
                            deleteCalled={(e) => deleteCount(e)}
                          />
                          {formik.errors.news && (
                            <FormHelperText error id="component-error-text">
                              {formik.errors.news}
                            </FormHelperText>
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className={classes.rowKey}></td>
                      <td className={classes.rowValue}>
                        {isTableDataLoading == false ?
                          <Button
                            type="submit"
                            title="Add"
                            disabled={isTableDataLoading == true}
                          // onClick={() =>
                          //   handleSubmit()
                          // }
                          />
                          :
                            <Spin
                            className="new_spin"
                             size="large"
                             spinning={true}
                            //  wrapperClassName={classes.tableSpinner}
                            //  className={classes.antSpin}
                            >
                            </Spin>
                        }
                        <Button
                          title="Preview"
                          // disabled={
                          //   !imageUrl ||
                          //   !terminalArea ||
                          //   desPreview ||
                          //   headingPreview
                          //     ? true
                          //     : false
                          // }
                          onClick={() => {
                            handlePreview();
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                  {/* // } */}
                </BSTable>
              </form>
            </Grid>
          </Grid>
        </Card>
      </DashboardLayout>
    </Spin>
  );
};
