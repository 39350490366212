import React from "react";
import { appConstants, appMessages } from "../themes/constants";
import history from "../utils/history";
import BrockenLink from "./brockenLink";

export default class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false,error:null };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true,error:error };
    }
  
    componentDidCatch(error, errorInfo) {
      console.log(error, errorInfo,'error, errorInfo');
      return { hasError: true,error:error };
        // You can also log the error to an error reporting service
    //   logErrorToMyService(error, errorInfo);
    }
  
    render() {
        console.log('this.state.hasErrorthis.state.hasError',this.state.hasError);
      if (this.state.error) {
       return  <BrockenLink
       isError={true}
       status="404"
       reason={appMessages.wrongPage}
       reasonDetails={appMessages.tryAgain}
       buttonTitle={
         localStorage.token ? appConstants.home : appConstants.login
       }
       onClick={()=>{history.push("/") 
       this.setState({error:null,hasError:false})}}
       goto="/"
     />
        // You can render any custom fallback UI
        // return <h1>Something went wrong.</h1>;
      }
      

          return this.props.children; 
      
  
    }
  }