import React, { useState, useEffect, } from 'react';
// Navigation
import { useHistory, useLocation } from 'react-router-dom'
// Styles
import 'antd/dist/antd.css'
import '../dashboard.scss'
// Third party Components
import * as Yup from 'yup';
import { Formik } from 'formik';
// antd
import { Spin, Tooltip } from 'antd';
import { Card, Table as BSTable, } from 'react-bootstrap';
// Mui Components
import { Grid, Select, useTheme, FormHelperText } from '@material-ui/core';
// Custom components
import DashboardLayout from '../../layouts/dashboardLayout'
import { InputField, Navbar, AppBar, Button } from '../../customComponents'
// fontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
// Constants
import { AppImages } from '../../themes/appImages'
import { ValidationConstants, appConstants } from '../../themes/constants'
import { Checkbox } from '@material-ui/core';
import { employeeArray as employeesNameArray } from './employeeArray';
import { getEmployeeDetailsAction, getRolesListAction, employeeEditAction } from '../../store/actions/employeeManagementAction';
// utils methods
import cl from '../../utils/cl'
import { useStyles } from '../../styles'
import { toast } from "react-toastify";
// Redux
import { useSelector, useDispatch } from 'react-redux'
import localStorage from "../../utils/localStorage";
import {
    validateImageRatioOneByOne,
} from "../../utils/helpers";
import { isInternetConnected } from '../../InternetConnection/InternetConnection';
// Global constants
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
export const EditEmployee = () => {

    const { employeeDetails, } = useSelector(state => state.EmployeeManagementReducer)
    const { firstName, lastName, email, phone_number, role, } = employeeDetails;
    const roleList = useSelector(state => state.EmployeeManagementReducer)
    console.log(roleList, "roleList*****")
    // Object destructuring
    const { roles } = roleList
    const location = useLocation()
    const imagenew = location?.state?.employeeId
    console.log(imagenew)
    // Hooks declarations
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();

    const dispatch = useDispatch()
    const [employeeArray, setEmployeeArray] = useState([])
    const [image, setImage] = useState("")
    const [imageUrl, setImageUrl] = useState("");
    const [userType, setUserType] = useState("")
    const [errors1, setErrors1] = useState(null);
    const [errorState, setErrorState] = useState(false)
    const employeeManagementState = useSelector(state => state.EmployeeManagementReducer)
    // Object destructuring
    const { isLoading, isTableDataLoading, userDetails } = employeeManagementState
    // local state initialization   

    // Lifecycle Hooks
    useEffect(() => {
        document.title = appConstants.headerTitle.editEmployee;
        // dispatch(getRolesListAction())
        if (location?.state?.employeeId) {
            isInternetConnected(history) &&
            dispatch(getEmployeeDetailsAction(location?.state?.employeeId?._id))
        } else {
            history.push('/employee')
        }
    }, [])

    useEffect(() => {
        let url = (appConstants.baseURL + employeeDetails?.profile)
        setImage(url)
        setImageUrl(employeeDetails?.profile)
        employeeDetails?.permissions?.map((x, index) => {
            if (index == 0) {
                employeeDetails.permissions[index].editDisabled = true
                employeeDetails.permissions[index].deleteDisabled = true
                employeeDetails.permissions[index].blockDisabled = true
                employeeDetails.permissions[index].addDisabled = true
            }
            else if (index == 1) {
                employeeDetails.permissions[index].deleteDisabled = true
                employeeDetails.permissions[index].addDisabled = true
            }
            else if (index == 2) {
                employeeDetails.permissions[index].deleteDisabled = true
            }
            else if (index == 3) {
                employeeDetails.permissions[index].blockDisabled = true
            }
            else if (index == 4 || index == 5 || index == 6) {
                employeeDetails.permissions[index].viewDisabled = true
                employeeDetails.permissions[index].view = employeeDetails.permissions[index].viewDisabled == true ? false : true
                employeeDetails.permissions[index].blockDisabled = true
            }
            else if (index == 7) {
                employeeDetails.permissions[index].viewDisabled = true
                employeeDetails.permissions[index].blockDisabled = true
                employeeDetails.permissions[index].editDisabled = true
            }
            else if (index == 8) {
                employeeDetails.permissions[index].blockDisabled = true
                employeeDetails.permissions[index].editDisabled = true
                employeeDetails.permissions[index].deleteDisabled = true
                employeeDetails.permissions[index].addDisabled = true
            }
            else if (index == 9) {
                employeeDetails.permissions[index].blockDisabled = true
            }
            else if (index == 10) {
                employeeDetails.permissions[index].blockDisabled = true
                employeeDetails.permissions[index].deleteDisabled = true
                employeeDetails.permissions[index].addDisabled = true
                employeeDetails.permissions[index].viewDisabled = true
            }
            else if (index == 11) {
                employeeDetails.permissions[index].blockDisabled = true
                employeeDetails.permissions[index].deleteDisabled = true
            }
            else if (index == 12) {
                employeeDetails.permissions[index].blockDisabled = true
                employeeDetails.permissions[index].editDisabled = true
                employeeDetails.permissions[index].deleteDisabled = true
                employeeDetails.permissions[index].addDisabled = true
            }
        })
        setEmployeeArray(employeeDetails.permissions)
    }, [employeeDetails])

    const checkIsValid = () => {
        let isTrue = []
        employeeArray?.map((x) => {
            if (x.view == false && x.edit == false && x.add == false && x.block == false && x.delete == false) {
                isTrue.push(false)
            }
            else {
                isTrue.push(true)
            }
        })
        return isTrue
    }

    const handleOnChange = (position, item1) => {
        let updatedCheckedState = []
        employeeArray?.map((item, index) => (
            updatedCheckedState.push({
                view: (index === position && item1 == 'view') ? !item.view : item.view,
                edit: (index === position && item1 == 'edit') ? !item.edit : item.edit,
                delete: (index === position && item1 == 'delete') ? !item.delete : item.delete,
                block: (index === position && item1 == 'block') ? !item.block : item.block,
                add: (index === position && item1 == 'add') ? !item.add : item.add,
                name: item.name,
                editDisabled: (index == 0 || index == 7 || index == 8 || index == 12) ? true : false,
                deleteDisabled: (index == 0 || index == 1 || index == 2 || index == 8|| index == 10 || index == 11 || index == 12) ? true : false,
                blockDisabled: (index == 0 || index == 3 || index == 4 || index == 5 || index == 6 || index == 7 || index == 8 || index == 9|| index == 10 || index == 11 || index == 12) ? true : false,
                addDisabled: (index == 0 || index == 1 || index == 8|| index == 10 || index == 12) ? true : false,
                viewDisabled: (index == 4 || index == 5 || index == 6 || index == 7|| index == 10) ? true : false,

            })
        )
        );
        setEmployeeArray(updatedCheckedState);
    }

    React.useEffect(() => {
        if (checkIsValid().includes(true)) {
            setErrorState(false)
        }
        else {
            setErrorState(true)
        }
    }, [employeeArray])

    const imageValidation = (value) => {
        let fileTypes = ["jpg", "jpeg", "svg", "png"]
        console.log(value, "extension")
        return (fileTypes.indexOf(value) > -1)
    }

    const handleImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            let uploadFile = event.target.files[0]
            let splitName = uploadFile.name.split(".").pop().toLowerCase()
            let isSuccess = imageValidation(splitName)
            if (isSuccess) {
                validateImageRatioOneByOne(event.target.files[0])
                    .then(() => {
                        setImageUrl(event.target.files[0]);
                        // setImageName(splitName[0]);
                        let reader = new FileReader();
                        reader.onload = (e) => {
                            setImage(e.target.result);
                        };
                        reader.readAsDataURL(event.target.files[0]);
                        return
                    })
                    .catch(() => {
                        toast.error(ValidationConstants.image.invalidRatioOneByOne)
                        return false
                    }
                    );

            }
            else {
                toast.error("Only .jpg, .jpeg, .png and .svg format images are allowed.")
            }
        }
    };

    //Constants
    const values = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phoneNumber: phone_number,
        userType: role?.name,
        // access: employeeArray
    }

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().test('trim', ValidationConstants.firstname.empty, value => value?.trim()?.length > 0).min(3, ValidationConstants.firstname.short).max(20, ValidationConstants.firstname.long).required(ValidationConstants.firstname.empty),
        lastName: Yup.string().test('trim', ValidationConstants.lastname.empty, value => value?.trim()?.length > 0).min(3, ValidationConstants.lastname.short).max(20, ValidationConstants.lastname.long).required(ValidationConstants.lastname.empty),
        email: Yup.string().email(ValidationConstants.email.invalid).max(255, ValidationConstants.email.long).required(ValidationConstants.email.empty),
        phoneNumber: Yup.string().matches(/^[0-9]+$/, ValidationConstants.phoneNumber.invalid).max(15, ValidationConstants.phoneNumber.long).min(8, ValidationConstants.phoneNumber.short).required(ValidationConstants.phoneNumber.empty),
        userType: Yup.string()
            .required(ValidationConstants.userType.empty)
            .min(3, ValidationConstants.userType.short)
            // .matches(/^[^ ][\w\W ]*[^ ]/, ValidationConstants.userType.noSpace)
            .matches(/^(?![0-9]*$)[a-zA-Z0-9\s]+$/, "Only alphanumeric values are allowed in role type.")
            .test('trim', ValidationConstants.userType.short, (value) => { return (value?.trimLeft().trimRight().length > 2) }),
        // access: Yup.array().min(1, ValidationConstants.userType.access).required(ValidationConstants.userType.access)
    })

    const handleKeyDown = (e) => {
        if (e.key === " ") {
            e.preventDefault();
        }
    };

    //Callback methods
    const onSubmit = (values, { setSubmitting }) => {
        setSubmitting(false)
        let updatedArray = [...employeeArray]
        if ((updatedArray[4].edit == true)) {
            updatedArray[4].view = true
        }
        if ((updatedArray[5].edit == true)) {
            updatedArray[5].view = true
        }
        if ((updatedArray[6].edit == true)) {
            updatedArray[6].view = true
        }
        if (errorState == true) {
            return false;
        }
        else {
            isInternetConnected(history) &&
            dispatch(employeeEditAction(employeeDetails?._id, values.firstName.trim(), values.lastName.trim(), values.email, values.phoneNumber, imageUrl, values.userType, updatedArray))
        }
    }

    const getname = (item) => {
        let itemName = item.name.charAt(0).toUpperCase() + item.name.slice(1)
        if (item.name == "dashboard")
            return "Dashboard"
        else if (item.name == "location")
            return "User Event Management"
        else if (item.name == "post")
            return "Report Post"
        else if (item.name == "state")
            return "State Listing"
        else if (item.name == "region")
            return "Region Listing"
        else if (item.name == "terminal")
            return "Terminal Listing"
        else if (item.name == "postManagement")
            return "Post Management"
        else if (item.name == "terminalBoard")
            return "Terminal Board"
        else {
            return itemName + " Management"
        }
    }

    return (
        <Spin
            size="large"
            spinning={isLoading}
            wrapperClassName={classes.tableSpinner}
            className={classes.antSpin}
        >
            <DashboardLayout>
                <AppBar breadcrumbs={[{ route: '/employee', name: appConstants.employeeManagement }, { route: '/edit-employee', name: appConstants.editEmployee, state: location?.state?.userId }]} />
                <Navbar title={appConstants.editEmployee} />
                <Card className={classes.card}>
                    <Grid container>
                        <Grid className={classes.tableContainerRow}>
                            <Formik
                                enableReinitialize
                                initialValues={values}
                                validationSchema={validationSchema}
                                onSubmit={onSubmit}
                            >
                                {({
                                    values,
                                    errors,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                    isSubmitting,
                                    touched,
                                    setFieldValue,

                                }) => (
                                    <form onSubmit={handleSubmit} noValidate>
                                        <BSTable striped bordered className={classes.bsTableHeight}>
                                            {
                                                isTableDataLoading ? <thead className={classes.bsHeaderTable}>
                                                    <Spin className={classes.tableDataSpinner} />
                                                </thead>
                                                    :
                                                    <tbody>
                                                        <tr>
                                                            <td className={classes.rowKey}>
                                                                {appConstants.uploadImage}
                                                            </td>
                                                            <td className={classes.rowValue}>
                                                                <div className={classes.inputWrapper}>
                                                                    <input
                                                                        accept=".png,.jpg,.jpeg"
                                                                        className={classes.uploaderInput}
                                                                        id="icon-button-file"
                                                                        type="file"
                                                                        onChange={(e) => handleImageChange(e)}
                                                                    />
                                                                    <label
                                                                        htmlFor="icon-button-file" id="icon-button-file"
                                                                        className={classes.imageManagementUploaderlabel}
                                                                    >
                                                                        <Tooltip
                                                                            title={appConstants.tooltip.changeImage}
                                                                            placement="right"
                                                                        >
                                                                            {image ? (
                                                                                <img
                                                                                    src={image}
                                                                                    className={classes.terminalImage}
                                                                                    onClick={(e) => handleImageChange(e)}
                                                                                />
                                                                            ) : (
                                                                                <img
                                                                                    src={AppImages.addPhoto}
                                                                                    className={classes.terminalImage}
                                                                                />
                                                                            )}
                                                                        </Tooltip>
                                                                    </label>
                                                                    {image?.length <= 0 && errors1 !== null ? (
                                                                        <FormHelperText
                                                                            style={{ marginBottom: 12 }}
                                                                            error
                                                                            id="component-error-text"
                                                                        >
                                                                            {errors1}
                                                                        </FormHelperText>
                                                                    ) : (
                                                                        true
                                                                    )}

                                                                </div>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className={classes.rowKey}>
                                                                {appConstants.firstName}
                                                            </td>
                                                            <td className={classes.rowValue}>
                                                                <div className={classes.inputWrapper}>
                                                                    <InputField
                                                                        id="firstName"
                                                                        placeholder={appConstants.firstName}
                                                                        name="firstName"
                                                                        error={Boolean(touched.firstName && errors.firstName)}
                                                                        helperText={touched.firstName && errors.firstName}
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                        type="firstName"
                                                                        value={values?.firstName?.trimLeft()}
                                                                        maxLength={20}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className={classes.rowKey}>
                                                                {appConstants.lastName}
                                                            </td>
                                                            <td className={classes.rowValue}>
                                                                <div className={classes.inputWrapper}>
                                                                    <InputField
                                                                        id="lastName"
                                                                        placeholder={appConstants.lastName}
                                                                        name="lastName"
                                                                        error={Boolean(touched.lastName && errors.lastName)}
                                                                        helperText={touched.lastName && errors.lastName}
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                        type="lastName"
                                                                        value={values?.lastName?.trimLeft()}
                                                                        maxLength={20}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className={classes.rowKey}>{appConstants.emailAddress}</td>
                                                            <td className={classes.rowValue}>
                                                                <div className={classes.inputWrapper}>
                                                                    <InputField
                                                                        id="email"
                                                                        error={Boolean(touched.email && errors.email)}
                                                                        helperText={touched.email && errors.email}
                                                                        placeholder={appConstants.emailAddress}
                                                                        name="email"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        // type="email"
                                                                        value={values?.email?.trimLeft()}
                                                                        maxLength={255}
                                                                        onKeyDown={handleKeyDown}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className={classes.rowKey}>{appConstants.phoneNumber}</td>
                                                            <td className={classes.rowValue}>
                                                                <div className={classes.inputWrapper}>
                                                                    <InputField
                                                                        id="phoneNumber"
                                                                        name="phoneNumber"
                                                                        placeholder={appConstants.phoneNumber}
                                                                        error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                                                                        helperText={touched.phoneNumber && errors.phoneNumber}
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                        value={values?.phoneNumber}
                                                                        maxLength={15}
                                                                    // disabled
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className={classes.rowKey}>Role Type</td>
                                                            <td className={classes.rowValue}>
                                                                <div className={classes.inputWrapper}>
                                                                    <InputField
                                                                        id="userType"
                                                                        name="userType"
                                                                        placeholder={"Role Type"}
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                        value={values?.userType?.trimLeft()}
                                                                        maxLength={20}
                                                                        error={Boolean(touched.userType && errors.userType)}
                                                                        helperText={touched.userType && errors.userType}
                                                                    />

                                                                    {/* <Select
                                                                        native
                                                                        id="userType"
                                                                        className={classes.selectInput}
                                                                        defaultValue={values?.userType}
                                                                        value={values?.userType}
                                                                        error={Boolean(touched.userType && errors.userType)}
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                        inputProps={{
                                                                            name: 'userType',
                                                                            id: 'userType',
                                                                        }}
                                                                        IconComponent={() => <FontAwesomeIcon icon={faChevronDown} className={classes.dropdownIcon} />}
                                                                    >
                                                                        <option value="">{appConstants.selectType}</option>
                                                                        {roles.length > 0 && roles?.map(
                                                                            (time, index) => {
                                                                                return (
                                                                                    <option value={time?._id}>
                                                                                        {time?.name}
                                                                                    </option>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </Select>
                                                                    {errors.userType && <FormHelperText error id="component-error-text">{errors.userType}</FormHelperText>} */}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className={classes.rowKey}>Type of access</td>
                                                            <tr>
                                                                <td className={classes.rowKey}></td>
                                                                <td className={classes.rowValue}>
                                                                    <div className={classes.tableWrapper}>
                                                                        <div className="line-view" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                                            <h6>View</h6>
                                                                            <h6 style={{ marginLeft: "3%" }}>Add</h6>
                                                                            <h6 style={{ marginLeft: "3%" }}>Edit</h6>
                                                                            <h6 style={{ marginLeft: "2%" }}>Delete</h6>
                                                                            <h6>Block</h6>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            {employeeArray?.length > 0 &&
                                                                employeeArray?.map((item, index) => {
                                                                    return (
                                                                        <tr>
                                                                            <td className={classes.rowKey}>{getname(item)}</td>
                                                                            <td className={classes.rowValue}>
                                                                                <div className={classes.tableWrapper}>
                                                                                    <div className="line-view" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                                                        <Checkbox checked={item.view} value={values.access} onChange={(e) => {
                                                                                            handleOnChange(index, 'view')
                                                                                            setFieldValue("access", employeeArray)
                                                                                        }}   {...label} disabled={item.viewDisabled == true} />
                                                                                        <Checkbox checked={item.add} value={values.access} onChange={(e) => {
                                                                                            handleOnChange(index, 'add')
                                                                                            setFieldValue("access", employeeArray)
                                                                                        }}  {...label} disabled={item.addDisabled == true} />
                                                                                        <Checkbox checked={item.edit} value={values.access} onChange={(e) => {
                                                                                            handleOnChange(index, 'edit')
                                                                                            setFieldValue("access", employeeArray)
                                                                                        }}  {...label} disabled={item.editDisabled == true} />
                                                                                        <Checkbox checked={item.delete} value={values.access} onChange={(e) => {
                                                                                            handleOnChange(index, 'delete')
                                                                                            setFieldValue("access", employeeArray)
                                                                                        }}   {...label} disabled={item.deleteDisabled == true} />
                                                                                        <Checkbox checked={item.block} value={values.access} onChange={(e) => {
                                                                                            handleOnChange(index, 'block')
                                                                                            setFieldValue("access", employeeArray)
                                                                                        }}   {...label} disabled={item.blockDisabled == true} />
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                            {errorState && <FormHelperText error id="component-error-text">Please select at least one access.</FormHelperText>}
                                                        </tr>
                                                        <tr>
                                                            <td className={classes.rowKey}></td>
                                                            <td className={classes.rowValue}>
                                                                <Button type="submit" title={appConstants.update} />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                            }
                                        </BSTable>
                                    </form>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                </Card>
            </DashboardLayout>
        </Spin>
    );
}
