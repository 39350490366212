import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
// Styles
import "antd/dist/antd.css";
import "../dashboard.scss";
import { useStyles } from "../../styles";
// Mui Components
import Grid from "@material-ui/core/Grid";
import { TimePicker, MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
// Custom components
import DashboardLayout from "../../layouts/dashboardLayout";
import { AppBar, Navbar, Button } from "../../customComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles } from '@material-ui/core/styles'
import {
  faHome,
  faUsers,
  faTrophy,
  faLocationArrow,
  faSignOutAlt,
  faFileAlt,
  faImages,
  faNewspaper,
  faUsersCog,
  faFlag,
  faAddressCard,
  faUserFriends,
  faCompass,
  faParking,
  faCalendar
} from "@fortawesome/free-solid-svg-icons";
import { tabs } from "../../layouts/allTabs";
// Constants
import { AppImages } from "../../themes/appImages";
import { appConstants } from "../../themes/constants";
import localStorage from "../../utils/localStorage";
// utils methods
import moment from 'moment'
// Redux
import { useSelector, useDispatch } from "react-redux";
import { handleNavigationStateAction } from "../../store/actions";
import { DashboardDataAction } from "../../store/actions/dashboardAction";

const useStyles1 = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-scrollPaper': {
      justifyContent: "flex-end"
    }
  },
  scrollPaper: {
    justifyContent: "flex-end"
  }
  // datePicker: {
  //   '& .MuiDialog-scrollPaper': {
  //     justifyContent: "flex-end"
  //   }
  // }
}));

export const Dashboard = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const classes1 = useStyles1();
  const [selectedOpenTime, setSelectedOpenTime] = React.useState(moment());
  const [openTimePicker, setOpenTimePicker] = useState(false);

  const dashboardData = useSelector(state => state.dashboardReducer.dashboardnewdata)
  let permissionsData = localStorage?.getPermissions()
  let employeeIndex = permissionsData?.findIndex((x) => x.name == "dashboard")

  useEffect(() => {
    document.title = appConstants.headerTitle.dashboard;
    dispatch(handleNavigationStateAction(0, false));
    fetchDataListing()
  }, []);

  const nestedTabs = [
    {
      icon: faFlag,
      name: "State Listing",
      path: "/state-listing",
      key: "state"
    },
    {
      icon: faLocationArrow,
      name: "Region Listing",
      path: "/terminal-area-list",
      key: "region"
    },
    {
      icon: faCompass,
      name: "Terminal Listing",
      path: "/terminals",
      key: "terminal"
    },
  ];

  const fetchDataListing = () => {
    if (permissionsData) {
      if (permissionsData[0]?.view == true) {
        dispatch(DashboardDataAction({
          date: selectedOpenTime ? moment(selectedOpenTime).format("YYYY-MM-DDT00:00:00") : null,
        }))
      }
    }
    else {
      dispatch(DashboardDataAction({
        date: selectedOpenTime ? moment(selectedOpenTime).format("YYYY-MM-DDT00:00:00") : null,
      }))
    }
  }

  const dashboardDesign = [
    {
      name: appConstants.registeredUsers,
      logo: AppImages.user,
      data: dashboardData?.noOfRegisterUsers,
      class: "border-left-success"
    },
    {
      name: appConstants.activeUsers,
      logo: AppImages.activeUser,
      data: dashboardData?.noOfActiveUsers,
      class: "border-users"
    },
    {
      name: appConstants.totalTimeline,
      logo: AppImages.timeline,
      data: dashboardData?.noOfTimelines,
      class: "border-timeline"
    },
    {
      name: appConstants.totalPost,
      logo: AppImages.posts,
      data: dashboardData?.noOfPosts,
      class: "border-post"
    },
    {
      name: appConstants.totalCooments,
      logo: AppImages.comment,
      data: dashboardData?.noOfComments,
      class: "border-comments"
    },
    {
      name: appConstants.totalLikes,
      logo: AppImages.like,
      data: dashboardData?.noOfLikes,
      class: "border-like"
    },
  ]

  let updatedState
  if (permissionsData) {
    updatedState = [...permissionsData]
  }

  let k = []
  let t = tabs?.filter((e) => {
    updatedState?.map((x) => {
      let r
      if (x.name == 'state' || x.name == 'region' || x.name == 'terminal') {
        r = (e.terminalArray?.includes(x.name))
        if (r) {
          if (x.add == true || x.block == true || x.delete == true || x.edit == true || x.view == true) {
            nestedTabs.filter((nt) => {
              let value = nt?.key==x.name
              if (value) {
                if (x.add == true || x.block == true || x.delete == true || x.edit == true || x.view == true) {
                  k.push(nt)
                }
              }
            })
          }
        }
      }
      else {
        r = e?.key==x.name
      }
      if (r) {
        if (x.add == true || x.block == true || x.delete == true || x.edit == true || x.view == true) {
          if (x.name == 'state' || x.name == 'region' || x.name == 'terminal') {

          }
          else {
            k.push(e)
          }
        }
      }
    })
  })

  return (
    <DashboardLayout>
      <AppBar breadcrumbs={[{ route: "/", name: "dashboard" }]} />
      {permissionsData ? permissionsData[employeeIndex]?.view == true ?
        <>
          <Grid container className={classes.buttonBarWrapper} style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="" style={{ display: "flex", justifyContent: "flex-start" }}>
              <Button title="Analytics" disabled={(permissionsData) && permissionsData[0]?.view == false}
                onClick={() => {
                  history.push({
                    pathname: '/analytics',
                  })
                }}
                dis
                width="108px"
                mr
                mb
              />
              <Button
                title={"Refresh"}
                onClick={() => {
                  fetchDataListing()
                }
                }
                width="108px"
                mr
                mb
              />
              {/* } */}
            </div>
            <div className={classes.inputWrapper} >
              <MuiPickersUtilsProvider utils={DateFnsUtils} classes={classes1.root}>

                <DatePicker
                  style={{ width: "100%", backgroundColor: "#fff", }}
                  ampm={true}
                  PopoverProps={{
                    anchorOrigin: { horizontal: "right", vertical: "bottom" },
                    transformOrigin: { horizontal: "right", vertical: "bottom" }
                  }}
                  className={classes1.root}
                  placeholder="Select Date"
                  name="startDate"
                  value={selectedOpenTime}
                  onAccept={(time) => {
                    setOpenTimePicker(false);
                  }}
                  disableFuture
                  format="yyyy-MM-dd"
                  onClose={() => setOpenTimePicker(false)}
                  minDate={moment('01-01-2019').add(1)}
                  onChange={(time) => {
                    setSelectedOpenTime(time)
                    if (permissionsData) {
                      if (permissionsData[0]?.view == true) {
                        dispatch(DashboardDataAction({
                          date: moment(time).format("YYYY-MM-DDT00:00:00"),
                        }))
                      }
                    }
                    else {
                      dispatch(DashboardDataAction({
                        date: moment(time).format("YYYY-MM-DDT00:00:00"),
                      }))
                    }
                  }
                  }
                  open={openTimePicker}
                  onOpen={() => setOpenTimePicker(true)}
                  InputProps={{
                    endAdornment: (
                      <FontAwesomeIcon
                        icon={faCalendar}
                        className={classes.dropdownIcon}
                      />
                    )
                  }}
                // minDate
                />
              </MuiPickersUtilsProvider>
            </div>
          </Grid>
          <div class="row">
            {
              dashboardDesign.map((item, index) => {
                return (
                  <div class="col-xl-4 col-md-6 mb-4">
                    <div class={`card ${item.class} shadow h-100 py-2`}>
                      <div class="card-body">
                        <div class="row no-gutters align-items-center">
                          <img className="card-image" src={item.logo} width={"65px"}></img>
                          <div class="col-sm-7">
                            <div class="text-xs font-weight-bold text-primary text-uppercase mb-1"></div>
                            <div class="h5 mb-0 font-weight-bold text-gray-800">{item.name}</div>
                            <div class="h5 mb-1 font-weight-bold text-gray-800">{item.data}</div>
                          </div>
                          <div class="col-auto">
                            <i class="fas fa-user fa-2x text-gray-300"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </>
        :
        <div class="row">
          {
            k.map((item, index) => {
              return (
                <div class="col-xl-4 col-md-6 mb-4" onClick={() => history.push(item.path)}>
                  <div class={`card border-permission shadow h-100 py-2`}>
                    <div class="card-body">
                      <div class="row no-gutters align-items-center">
                        <div className="card-image">
                          <FontAwesomeIcon
                            icon={item.icon}
                            style={{ width: "45px", height: "45px" }}
                            color={"#196EA1"}
                          />
                        </div>
                        <div class="col-sm-7">
                          <div class="text-xs font-weight-bold text-primary text-uppercase mb-1"></div>
                          <div class="h5 mb-0 font-weight-bold text-gray-800" style={{ cursor: "pointer" }} >{item.name}</div>
                          <div class="h5 mb-1 font-weight-bold text-gray-800">{item.data}</div>
                        </div>
                        <div class="col-auto">
                          <i class="fas fa-user fa-2x text-gray-300"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
        :
        <>
          <Grid container className={classes.buttonBarWrapper} style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="" style={{ display: "flex", justifyContent: "flex-start" }}>
              <Button title="Analytics" disabled={(permissionsData) && permissionsData[0]?.view == false}
                onClick={() => {
                  history.push({
                    pathname: '/analytics',
                  })
                }}
                dis
                width="108px"
                mr
                mb
              />
              <Button
                title={"Refresh"}
                onClick={() => {
                  fetchDataListing()
                }
                }
                width="108px"
                mr
                mb
              />
              {/* } */}
            </div>
            <div className={classes.inputWrapper} >
              <MuiPickersUtilsProvider utils={DateFnsUtils} classes={classes1.root}>

                <DatePicker
                  className={classes1.root}
                  style={{ width: "100%", backgroundColor: "#fff" }}
                  ampm={true}
                  PopoverProps={{
                    anchorOrigin: { horizontal: "right", },
                    transformOrigin: { horizontal: "right", }
                  }}
                  placeholder="Select Date"
                  name="startDate"
                  value={selectedOpenTime}
                  onAccept={(time) => {
                    setOpenTimePicker(false);
                  }}
                  disableFuture
                  format="yyyy-MM-dd"
                  onClose={() => setOpenTimePicker(false)}
                  minDate={moment('01-01-2019').add(1)}
                  onChange={(time) => {
                    setSelectedOpenTime(time)
                    if (permissionsData) {
                      if (permissionsData[0]?.view == true) {
                        dispatch(DashboardDataAction({
                          date: moment(time).format("YYYY-MM-DDT00:00:00"),
                        }))
                      }
                    }
                    else {
                      dispatch(DashboardDataAction({
                        date: moment(time).format("YYYY-MM-DDT00:00:00"),
                      }))
                    }
                  }
                  }
                  open={openTimePicker}
                  onOpen={() => setOpenTimePicker(true)}
                  InputProps={{
                    endAdornment: (
                      <FontAwesomeIcon
                        icon={faCalendar}
                        className={classes.dropdownIcon}
                      />
                    )
                  }}
                // minDate
                />
              </MuiPickersUtilsProvider>
            </div>
          </Grid>
          <div class="row">
            {
              dashboardDesign.map((item, index) => {
                return (
                  <div class="col-xl-4 col-md-6 mb-4">
                    <div class={`card ${item.class} shadow h-100 py-2`}>
                      <div class="card-body">
                        <div class="row no-gutters align-items-center">
                          <img className="card-image" src={item.logo} width={"65px"}></img>
                          <div class="col-sm-7">
                            <div class="text-xs font-weight-bold text-primary text-uppercase mb-1"></div>
                            <div class="h5 mb-0 font-weight-bold text-gray-800">{item.name}</div>
                            <div class="h5 mb-1 font-weight-bold text-gray-800">{item.data}</div>
                          </div>
                          <div class="col-auto">
                            <i class="fas fa-user fa-2x text-gray-300"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </>
      }
    </DashboardLayout>
  );
};
