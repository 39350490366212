import { takeLatest, call, put } from "redux-saga/effects";
import { apiConstants as types, appMessages } from "../../themes/constants";
import axios from "../axios";
import cl from "../../utils/cl";
import history from "../../utils/history";
import { toast } from "react-toastify";
function* newsManagementFailedSaga(result) {
  yield put({
    type: types.API_NEWS_MANAGEMENT_FAILED,
    errorState: result?.error,
  });
  toast.error(result?.error);
}

function* newsManagementErrorSaga(result) {
  yield put({
    type: types.API_NEWS_MANAGEMENT_ERROR,
    errorState: result?.error,
  });
  if (result.status === 2) {
    toast.error(appMessages.messageStatus500);
  } else {
    toast.error(result?.error);
  }
}

function* getNewsListSaga(action) {
  const { search, offset, limit, sortBy, order,terminalId } = action;
  try {
    const result = yield call(
      axios.getNewsList,
      search,
      offset,
      limit,
      sortBy,
      order,
      terminalId
    );
    if (result.status === 1) {
      cl("result inside get Image list saga", result);
      yield put({
        type: types.API_GET_NEWS_LIST_SUCCESS,
        result: result.result.data.data,
      });
    } else {
      yield call(newsManagementFailedSaga, result);
    }
  } catch (error) {
    yield call(newsManagementErrorSaga, error);
  }
}
function* addNewsSaga(action) {
  const { regionId, image, description, heading } = action;
  try {
    const result = yield call(
      axios.addNews,
      regionId,
      image,
      description,
      heading
    );
    if (result.status === 1) {
      cl("result inside add news list saga", result);
      yield put({
        type: types.API_ADD_NEWS_SUCCESS,
        result: result.result.data.data,
      });
      toast.success(result?.result?.data?.message);
      history.push("/news-terminal");
    } else {
      yield call(newsManagementFailedSaga, result);
    }
  } catch (error) {
    yield call(newsManagementErrorSaga, error);
  }
}
function* editNewsSaga(action) {
  const { regionId, image, description, heading, id } = action;
  try {
    const result = yield call(
      axios.editNews,
      regionId,
      image,
      description,
      heading,
      id
    );
    if (result.status === 1) {
      cl("result inside EDIT Image list saga", result);
      yield put({
        type: types.API_EDIT_NEWS_SUCCESS,
        result: result.result.data.data,
      });
      toast.success(result?.result?.data?.message);
      history.push("/news-terminal");
    } else {
      yield call(newsManagementFailedSaga, result);
    }
  } catch (error) {
    yield call(newsManagementErrorSaga, error);
  }
}
function* deleteNewsSaga(action) {
  const { id } = action;
  try {
    const result = yield call(axios.deleteNews, id);
    if (result.status === 1) {
      yield put({
        type: types.API_DELETE_NEWS_SUCCESS,
        result: result.result.data.data,
        id: id,
      });
      toast.success(result?.result?.data?.message);
    } else {
      yield call(newsManagementFailedSaga, result);
    }
  } catch (error) {
    yield call(newsManagementErrorSaga, error);
  }
}

function* deleteImageSaga(action) {

  try {
    const result = yield call(
      axios.deleteImageFromEditor,
     action.key
    );
    if (result.status === 1) {
      cl("result inside EDIT Image list saga", result);
      yield put({
        type: types.API_DELETE_IMAGE_NEWS_SUCCESS,
        result: result.result.data.data,
      });
    } else {
      yield call(newsManagementFailedSaga, result);
    }
  } catch (error) {
    yield call(newsManagementErrorSaga, error);
  }
}

export default function* rootNewsManagementSaga() {
  yield takeLatest(types.API_GET_NEWS_LIST_LOAD, getNewsListSaga);
  yield takeLatest(types.API_ADD_NEWS_LOAD, addNewsSaga);
  yield takeLatest(types.API_EDIT_NEWS_LOAD, editNewsSaga);
  yield takeLatest(types.API_DELETE_NEWS_LOAD, deleteNewsSaga);
  yield takeLatest(types.API_DELETE_IMAGE_NEWS_LOAD, deleteImageSaga);
}
